import React from 'react';

import {
	Fade,
	List,
	Paper,
	Popper,
	MenuItem,
	ClickAwayListener,
} from '@mui/material';
import { FileUploadOutlined, SwitchAccount } from '@mui/icons-material';

export const MigrationMenu = ({
	isOpen,
	anchorEl,
	onClose,
	setMigrationStep,
}) => {
	const menuActions = [
		{
			label: 'Exportar estratégias',
			isVisible: true,
			icon: <SwitchAccount />,
			action: () => setMigrationStep('export-migration'),
		},
		{
			label: 'Importar estratégias',
			isVisible: true,
			icon: <FileUploadOutlined />,
			action: () => setMigrationStep('import-migration'),
		},
	];

	return (
		<>
			<Popper
				open={isOpen}
				anchorEl={anchorEl}
				placement="bottom"
				transition
			>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={onClose}>
						<Fade {...TransitionProps}>
							<Paper elevation={12} square={false}>
								<List>
									{menuActions
										.filter(item => item.isVisible)
										.map(item => (
											<MenuItem
												key={item.label}
												onClick={item.action}
												sx={{ display: 'flex', gap: 1 }}
											>
												{item.icon}
												{item.label}
											</MenuItem>
										))}
								</List>
							</Paper>
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
		</>
	);
};
