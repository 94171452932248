import light from 'themes/light';
import dark from 'themes/dark';
import orama from 'themes/orama';

const themes = {
	dark,
	light,
	orama,
};

export default themes;
