export function getResizedColumns(columns, columnsWithFlatWidth) {
	if (columnsWithFlatWidth) {
		columnsWithFlatWidth.forEach(column => {
			const columnToChange = columns.find(
				col => col.field === column.field
			);

			if (columnToChange) {
				columnToChange.width = column.width;
				delete columnToChange.flex;
			}
		});
	}

	return columns;
}
