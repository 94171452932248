import React from 'react';

import { Grid } from '@mui/material';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';

import { getFormattedDate } from 'utils/getParsedDate';
import { getTranslatedStrategyCode } from 'utils/tablesColumns/recurrence';

export const SavedOrderDialog = ({
	savedOrderDialog,
	handleClose,
	handleSaveOrder,
}) => {
	const { savedOrder, isOpen } = savedOrderDialog;

	const formattedDate = getFormattedDate(savedOrder.date, 'dd/MM/yyyy');

	return (
		<>
			<Dialog open={isOpen} onClose={handleClose}>
				<DialogTitle variant="h5">Atenção!</DialogTitle>

				<DialogContent>
					<Grid container flexDirection="column" gap={1}>
						<Typography component="div" fontSize="1.150rem" gap={1}>
							Você já possui uma boleta salva com o mesmo nome!
						</Typography>
						<Typography
							component="div"
							display="flex"
							flexDirection="column"
							gap={1}
						>
							<Typography>
								<strong>Nome: </strong> {savedOrder.name}
							</Typography>
							<Typography>
								<strong>Tipo: </strong>{' '}
								<span style={{ textTransform: 'uppercase' }}>
									{getTranslatedStrategyCode(
										savedOrder.strategyCode
									)}
								</span>
							</Typography>
							<Typography>
								<strong>Salva em: </strong> {formattedDate}{' '}
								{savedOrder.time}
							</Typography>
						</Typography>
						<Typography fontSize="1.150rem">
							Deseja substituí-la?
						</Typography>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Button
						color="error"
						variant="contained"
						onClick={handleClose}
					>
						Não
					</Button>

					<Button
						color="primary"
						variant="contained"
						onClick={handleSaveOrder}
					>
						Sim
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
