import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { apiAWS } from '../../../../services/api';

import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { Check, Close } from '@mui/icons-material';

import { ModalTemplate } from '../Template';
import { Buttons } from '../Buttons';
import { PasswordInput } from 'components/PasswordInput';

export const NewSignature = ({
	open,
	credentials,
	handleClose,
	initialState,
	setCredentials,
	transactionalToken,
	setSnackbarState
}) => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [isSignatureVisible, setIsSignatureVisible] = useState({
		newSignature: false,
		confirmSignature: false
	});

	function handleChangePasswordVisibility(field) {
		setIsSignatureVisible(state => ({ ...state, [field]: !isSignatureVisible[field] }));
	}

	const handleNewSignature = () => {
		if (credentials.signature.newSignature !== credentials.signature.confirmationSignature) {
			setSnackbarState(state => ({
				...state,
				isOpen: true,
				autoHide: 3000,
				severity: 'error',
				message: t('modules.settings.credentials.alerts.signaturesDontMatch')
			}));

			return;
		}

		const header = { headers: { 'x-authorization': transactionalToken } };

		const body = {
			password: credentials.signature.newSignature,
			service: 'reset_signature',
		};

		setLoading(true);

		apiAWS
			.post('/credentials/commands', body, header)
			.then(() => {
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					autoHide: 3000,
					severity: 'success',
					message: t('modules.settings.credentials.alerts.successSignatureChange')
				}));
				setCredentials(initialState);
			})
			.catch(error => {
				console.log(error);
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					autoHide: 3000,
					severity: 'error',
					message: t('modules.settings.credentials.alerts.errorSignatureChange')
				}));
			})
			.finally(() => handleClose());
	};


	const disabled = (credentials) => {
		if (
			credentials.signatureRegex.caracteres === true &&
			credentials.signatureRegex.numbers === true &&
			credentials.signature.newSignature !== '' &&
			credentials.signature.confirmationSignature !== '' &&
			credentials.signature.newSignature === credentials.signature.confirmationSignature
		) {
			return false;
		} else {
			return true;
		}
	};

	const regexCheck = (inputType) => {
		let typeJunction = `${inputType}Signature`;
		const regexSignature = [
			/^[0-9]{6,6}$/.test(credentials.signature[typeJunction]),
			/^[0-9]{1,}$/.test(credentials.signature[typeJunction]),
		];

		setCredentials({
			...credentials,
			signatureRegex: {
				...credentials.signatureRegex,
				caracteres: regexSignature[0],
				numbers: regexSignature[1],
			},
		});
	};

	const handlePasswordChange = (inputType, inputValue) => {
		setCredentials({
			...credentials,
			signature: {
				...credentials.signature,
				[`${inputType}Signature`]: inputValue,
			},
		});
	};

	return (
		<ModalTemplate
			open={open}
			title="Esqueci ou não tenho assinatura eletrônica"
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 1,
					px: 2,
					'@media screen and (min-width: 760px)': {
						maxWidth: '40rem'
					},
				}}
			>
				<Typography variant="subtitle1" sx={{ mb: 1 }}>
					Preencha abaixo a nova assinatura eletrônica.
				</Typography>

				<Grid
					container
					xs={12}
					spacing={1}
				>
					<Grid item xs={6}>
						<PasswordInput
							label="Nova assinatura"
							required
							withoutAutofill={true}
							value={credentials.signature.newSignature}
							inputProps={{ min: 6, max: 15 }}
							onKeyUp={() => regexCheck('new')}
							onFocus={() => regexCheck('new')}
							isPasswordVisible={isSignatureVisible.newSignature}
							changePasswordVisibility={() => handleChangePasswordVisibility('newSignature')}
							onChange={e => handlePasswordChange('new', e.target.value)}
						/>
					</Grid>

					<Grid item xs={6}>
						<PasswordInput
							label="Confirme a assinatura"
							required
							withoutAutofill={true}
							inputProps={{ min: 6, max: 15 }}
							isPasswordVisible={isSignatureVisible.confirmSignature}
							changePasswordVisibility={() => handleChangePasswordVisibility('confirmSignature')}
							onKeyUp={() => regexCheck('confirmation')}
							onFocus={() => regexCheck('confirmation')}
							value={credentials.signature.confirmationSignature}
							onChange={e => handlePasswordChange('confirmation', e.target.value)}
						/>
					</Grid>
				</Grid>

				<Typography variant="subtitle1">Regras da senha</Typography>

				<Box
					color={
						credentials.signatureRegex.caracteres
							? 'success.main'
							: 'error.main'
					}
					display="flex"
				>
					<Box pr={1}>{credentials.signatureRegex.caracteres ? <Check /> : <Close />}</Box>
					<Typography>Conter 6 caracteres</Typography>
				</Box>
				<Box
					color={credentials.signatureRegex.numbers ? 'success.main' : 'error.main'}
					display="flex"
				>
					<Box pr={1}>{credentials.signatureRegex.numbers ? <Check /> : <Close />}</Box>
					<Typography>Conter apenas números</Typography>
				</Box>
				<Box
					color={
						!credentials.signature.newSignature || !credentials.signature.confirmationSignature
							? 'error.main'
							: credentials.signature.newSignature === credentials.signature.confirmationSignature
								? 'success.main'
								: 'error.main'
					}
					display="flex"
				>
					<Box pr={1}>
						{!credentials.signature.newSignature || !credentials.signature.confirmationSignature ? (
							<Close />
						) : credentials.signature.newSignature === credentials.signature.confirmationSignature ? (
							<Check />
						) : (
							<Close />
						)}
					</Box>
					<Typography variant="subtitle1">A nova assinatura e sua confirmação devem coincidir</Typography>
				</Box>
				<Buttons
					closeClick={handleClose}
					submitColor={disabled(credentials) ? 'disabled' : 'success'}
					disabled={disabled(credentials)}
					submitClick={handleNewSignature}
				>
					{loading ? <CircularProgress size={20} /> : 'CONFIRMAR'}
				</Buttons>
			</Box>
		</ModalTemplate>
	);
};
