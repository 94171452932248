import React, { useState } from 'react';

import {
	Box,
	List,
	Paper,
	TextField
} from '@mui/material';

export const NewSelect = ({ children, value, maxHeight = 300, ...rest }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [isVisible, setIsVisible] = useState(false);
	const [width, setWidth] = useState(100);

	function handleChangeFocus(event) {
		setIsVisible(true);
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setWidth(document.activeElement.parentElement.parentElement.offsetWidth - 5);
	}

	function handleClose() {
		setTimeout(() => {
			setIsVisible(false);
			setAnchorEl(null);
		}, 300);
	}

	return (
		<>
			<TextField
				onFocus={handleChangeFocus}
				onBlur={handleClose}
				InputProps={{ readOnly: true }}
				InputLabelProps={{ shrink: true }}
				value={value}
				sx={{ position: 'relative' }}
				{...rest}
			/>

			{isVisible && (
				<Box
					sx={{
						position: 'absolute',
						zIndex: 2,
						maxHeight: maxHeight,
						overflowY: 'scroll',
					}}
				>
					<Paper elevation={12}>
						<List sx={{ width }}>
							{children}
						</List>
					</Paper>
				</Box>
			)}
		</>
	);
};
