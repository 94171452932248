import React, { useEffect, useState } from 'react';
import SockJsClient from 'react-stomp';
import { updateWebsocketConnection } from '../../store/modules/websocket/actions';
import { useDispatch } from 'react-redux';

const WebsocketClient = props => {
	const {
		url,
		topicType,
		subscriptionList,
		authToken,
		callbackOnMessage,
		callbackOnConnect,
		callbackOnDisconnect,
		callbackOnConnectFailure,
		isConnected,
	} = props;

	const [client, setClient] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (client) {
			if (!isConnected) {
				if (client.connected) {
					client.disconnect();
				}

				dispatch(updateWebsocketConnection(true));
			}
		}
	}, [isConnected, client]);

	return (
		<>
			<SockJsClient
				url={url}
				topics={subscriptionList}
				headers={{ 'x-authorization': `${authToken}` }}
				onMessage={(msg, topic) => {
					callbackOnMessage(msg, topic, topicType);
				}}
				onConnect={callbackOnConnect}
				onDisconnect={callbackOnDisconnect}
				onConnectFailure={callbackOnConnectFailure}
				autoReconnect
				ref={client => {
					setClient(client?.client);
				}}
			/>
		</>
	);
};

export default WebsocketClient;
