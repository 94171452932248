import { format } from 'date-fns';
import {
	VISION_ADD,
	VISION_CHANGE,
	VISION_ROW_ADD,
	VISION_UPDATE,
	VISION_REMOVE,
	VISION_ROW_REMOVE,
	VISIBILITY_SET,
	SET_DEFAULT_VISION,
	SET_VISIONS,
	VISION_CELL_COLOR_CHANGE,
	INTEREST_RATE_SET
} from './actionTypes';

const initialState = {
	visionList: [{
		id: new Date().getTime(), 
		name: 'Padrão', 
		index: 0,
		rows: [],
		createDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss') 
	}],
	activeVision: 0,
	lineColors: [],
	interestRate: 0.035,
};

const visionBase = {
	rows: []
};

const reducer = {
	[VISION_ADD]: (stt, payload) => {
		const { vision } = payload;
		return { ...stt, visionList: [...stt.visionList, ({...visionBase, ...vision, saved:false})], activeVision: vision.index };
	},
	[VISION_CHANGE]: (stt, payload) => {
		const { index } = payload;
		return { ...stt, activeVision: index };
	},
	[VISION_ROW_ADD]: (stt, payload) => {
		const { data } = payload;
		const updatedVision = { ...stt.visionList[stt.activeVision] };
		if (!updatedVision.rows)
			updatedVision.rows = [];
		updatedVision.rows.push(data);
		return { ...stt, visionList: stt.visionList.map((vision, i) => i === stt.activeVision ? updatedVision : vision) };
	},
	[VISION_UPDATE]: (stt, payload) => {
		const { index, data } = payload;
		const updatedVision = { ...stt.visionList[index], ...data };
    
		return { ...stt, visionList: stt.visionList.map((vision, i) => i === index ? updatedVision : vision) };
	},
	[SET_DEFAULT_VISION]: (stt, payload) => {
		const { defaultVision } = payload;
    
		return { ...stt, visionList: stt.visionList.map((vision, i) => i === 0 ? defaultVision : vision) };
	},
	[SET_VISIONS]: (stt, payload) => {
		const { visionList } = payload;
    
		return { ...stt, visionList };
	},
	[VISION_REMOVE]: (stt, payload) => {
		const { index } = payload;
		const allVisions = [...stt.visionList];
		allVisions.splice(index, 1);
    
		let activeVision = stt.activeVision < index ? stt.activeVision : stt.activeVision - 1;

		return { ...stt, visionList: allVisions, activeVision };
	},
	[VISIBILITY_SET]: (stt, payload) => {
		const { index } = payload;
		return { ...stt, activeStrategy: index};
	},
	[VISION_ROW_REMOVE]: (stt, payload) => {
		const { index } = payload;

		const updatedVision = { ...stt.visionList[stt.activeVision] };

		updatedVision.rows.splice(index, 1);
    
		return { ...stt, visionList: stt.visionList.map((vision, i) => i === stt.activeVision ? updatedVision : vision) };
	},
	[VISION_CELL_COLOR_CHANGE]: (stt, payload) => {
		const { index, col, color, currentTime } = payload;
    
		const oldLines = stt.lineColors ? [ ...stt.lineColors ] : [];

		if (!oldLines[index]){
			oldLines[index] = {};
		}

		if (!oldLines[index].columnColor || !Array.isArray(oldLines[index].columnColor)){
			oldLines[index].columnColor = [];
		}

		if (oldLines[index].columnColor.filter(colColor => colColor.column === col).length === 0){
			oldLines[index].columnColor.push({
				column: col
			});
		}
    
		const newColumColors = oldLines[index].columnColor.map(colColor => colColor.column === col ? {...colColor, color, currentTime } : colColor);
		oldLines[index].columnColor = newColumColors;

		return { ...stt, lineColors: oldLines};
	},
	[INTEREST_RATE_SET]: (stt, interestRateValue) => ({
		...stt,
		interestRate: interestRateValue,
	}),
};

export default function strategyFloatingReducer(state = initialState, { type, payload }) {
	if (typeof reducer[type] === 'function') {
		return reducer[type](state, payload);
	}
	return state;
};
