import React, { useState, useEffect, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	calcIvol,
	interpolatedVol,
	calcGreeks,
} from '@investflex/iflexquantjs';

import { Replay } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';

import { SimulatorDecimalInput } from './DecimalInput';
import { LightTooltip } from 'components/LightTooltip';

import { getPaper } from '../utils';
import { updatePosition } from 'store/modules/simulator/actions';

export const InputVolatility = memo(function InputVolatility({
	position,
	accessor,
}) {
	const dispatch = useDispatch();

	const paper = useSelector(getPaper);
	const ir = useSelector(state => state.simulator.ir);
	const timerUpdate = useSelector(state => state.simulator.timerUpdate);
	const activeStrategy = useSelector(state => state.simulator.activeStrategy);
	const stratShowTypeGreeks = useSelector(
		state => state.simulator.strategyList[activeStrategy].showTypeGreeks
	);
	const stratShowTypeExit = useSelector(
		state => state.simulator.strategyList[activeStrategy].showTypeExit
	);

	const [calcPending, setCalcPending] = useState(false);

	const {
		expired,
		strikePx,
		id,
		last_entryPx,
		daysToExpiration,
		optionType,
		entryPx,
		exitPx,
		theorPx,
	} = position;

	const { histVol30, histVol90, histVol180, histVol360, price } = paper;

	const volKey = {
		exitImpliedVol: 'exit',
		entryImpliedVol: 'entry',
		theorImpliedVol: 'theor',
	}[accessor];

	const greekName = {
		entryImpliedVol: 'entryGreeks',
		exitImpliedVol: 'exitGreeks',
		theorImpliedVol: 'theoreticalGreeks',
	}[accessor];

	const manualMode = useMemo(
		() => position[`${volKey}ManualMode`],
		[position[`${volKey}ManualMode`]]
	);

	const pxLocked = useMemo(
		() =>
			accessor === 'theorImpliedVol'
				? false
				: position[`${volKey}PxLocked`],
		[position[`${volKey}PxLocked`]]
	);

	const greeksDefaultNA = {
		delta: null,
		gamma: null,
		vega: null,
		theta: null,
		rho: null,
	};

	useEffect(() => {
		if (calcPending) {
			return;
		}

		setCalcPending(true);
	}, [
		price,
		ir,
		daysToExpiration,
		[`${volKey}Px`],
		manualMode,
		pxLocked,
		strikePx,
		optionType,
		stratShowTypeGreeks,
	]);

	useEffect(() => {
		if (!calcPending || expired || pxLocked || manualMode) {
			return;
		}

		handleUpdateValueLiveMode();
		setCalcPending(false);
	}, [timerUpdate, pxLocked, stratShowTypeGreeks, manualMode]);

	// atualiza o valor no modo automatico
	const handleUpdateValueLiveMode = () => {
		let newVolatility;

		if (!optionType) {
			return;
		}

		if (accessor === 'theorImpliedVol') {
			const histDaysVector = [30, 90, 180, 360];
			const histVolVector = [
				histVol30,
				histVol90,
				histVol180,
				histVol360,
			];

			newVolatility = parseFloat(
				(
					interpolatedVol(
						daysToExpiration,
						histDaysVector,
						histVolVector
					) * 100
				).toFixed(2) || 0
			);
		} else {
			const optPx =
				{
					entryImpliedVol: entryPx,
					exitImpliedVol: exitPx,
					theorImpliedVol: theorPx,
				}[accessor] || 0;

			if (!strikePx || !ir || !optPx || !price) {
				if (
					position[accessor] !== 0 ||
					greeksIsChange(greeksDefaultNA, position[greekName])
				)
					updateGreekAndVolValue(0.0, greeksDefaultNA);
				return;
			}

			newVolatility = calcVolatility(accessor, {
				strikePx,
				price: price,
				ir,
				daysToExpiration,
				optPx,
				optionType,
			});
		}

		//Calculate greeks
		if (
			pxLocked ||
			stratShowTypeGreeks + 'Greeks' !== greekName || //Se travado ou se as gregas selecionadas for diferente da exibida não calcula
			!price ||
			!newVolatility ||
			!ir ||
			!strikePx
		) {
			if (
				stratShowTypeGreeks + 'Greeks' === greekName &&
				greeksIsChange(greeksDefaultNA, position[greekName])
			) {
				updateGreekAndVolValue(newVolatility, greeksDefaultNA);
			} else if (newVolatility !== position[accessor]) {
				updateVolValue(newVolatility);
			}
		} else {
			const newGreeks = calcGreeks(
				price,
				strikePx,
				newVolatility / 100,
				daysToExpiration,
				ir,
				optionType
			);

			const oldGreeks = position[greekName];

			if (!greeksIsChange(newGreeks, oldGreeks)) {
				if (newVolatility !== position[accessor])
					updateVolValue(newVolatility);
				return;
			}

			updateGreekAndVolValue(newVolatility, newGreeks);
		}
	};

	function greeksIsChange(value, oldValue) {
		for (let key in value) {
			if (
				parseFloat(value[key]?.toFixed(2)) !==
				parseFloat(oldValue[key]?.toFixed(2))
			) {
				return true;
			}
		}

		return false;
	}

	function handleUpdateValueManualMode(manualVol) {
		if (
			pxLocked || //Se cadeado fechado não atualiza vol
			!price ||
			!manualVol ||
			!ir ||
			!strikePx ||
			!daysToExpiration ||
			!optionType
		) {
			updateVolValue(manualVol);
		} else {
			const newGreeks = calcGreeks(
				price,
				strikePx,
				manualVol / 100,
				daysToExpiration,
				ir,
				optionType
			);

			const oldGreeks = position[greekName];

			if (!greeksIsChange(newGreeks, oldGreeks)) {
				updateVolValue(manualVol);
				return;
			}

			updateGreekAndVolValue(manualVol, newGreeks);
		}
	}

	function calcVolatility(accessor, params) {
		const { strikePx, price, ir, daysToExpiration, optPx, optionType } =
			params;

		const vol = parseFloat(
			(
				calcIvol(
					price,
					strikePx,
					ir,
					daysToExpiration,
					optPx,
					optionType
				) * 100
			).toFixed(2) || 0
		);

		return {
			entryImpliedVol: vol,
			exitImpliedVol: vol,
		}[accessor];
	}

	function updateGreekAndVolValue(volValue, greeksValue) {
		const _volValue =
			Math.abs(parseFloat(volValue)) >= 999 ? null : parseFloat(volValue);

		dispatch(
			updatePosition(activeStrategy, id, {
				[greekName]: greeksValue,
				[accessor]: _volValue,
			})
		);
	}

	function updateVolValue(volValue) {
		const _volValue =
			Math.abs(parseFloat(volValue)) >= 999 ? null : parseFloat(volValue);

		if (position[accessor] === _volValue) return;
		dispatch(
			updatePosition(activeStrategy, id, {
				[accessor]: _volValue,
			})
		);
	}

	function handleSetLiveMode() {
		dispatch(
			updatePosition(activeStrategy, id, {
				[`${volKey}ManualMode`]: false,
			})
		);

		if (accessor === 'entryImpliedVol') {
			dispatch(
				updatePosition(activeStrategy, id, {
					entryPx: last_entryPx,
				})
			);
		} else if (accessor === 'exitImpliedVol') {
			// Gambiarra para quando troca o side. Se a entrada estiver fechada, quando inverte tem que pegar do lugar certo
			const pxExitSource = {
				top: 'last_exitPx',
				mid: 'last_midPx',
				last: 'last_tradePx',
			}[stratShowTypeExit];

			dispatch(
				updatePosition(activeStrategy, id, {
					exitPx: position[pxExitSource],
				})
			);
		}

		if (!expired && !pxLocked) {
			handleUpdateValueLiveMode();
		}
	}

	function handleChange(event) {
		if (!manualMode && event?.type === 'change') {
			dispatch(
				updatePosition(activeStrategy, id, {
					[`${volKey}ManualMode`]: true,
				})
			);
		}

		if (typeof event.value === 'undefined') {
			return;
		}

		handleUpdateValueManualMode(event.value);
	}

	return (
		<Box sx={{ display: 'flex', px: 0.5, justifyContent: 'flex-end' }}>
			{manualMode && !pxLocked && (
				<IconButton size="small" onClick={handleSetLiveMode}>
					<LightTooltip arrow title="Reverter">
						<Replay />
					</LightTooltip>
				</IconButton>
			)}

			<SimulatorDecimalInput
				width={40}
				minVal={0}
				maxVal={999.99}
				minDecScale={2}
				stepSize={0.01}
				disabled={expired || false}
				isManualMode={manualMode && !pxLocked}
				onChange={event => handleChange(event)}
				value={expired ? '-' : position[accessor] || '-'}
			/>
		</Box>
	);
});
