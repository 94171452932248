import React, { useState } from 'react';

import { Box, IconButton } from '@mui/material';
import {
	DoDisturb,
	PauseCircleOutline,
	PlayCircleFilledWhiteOutlined,
} from '@mui/icons-material';

import { LightTooltip } from 'components/LightTooltip';
import { ActionConfirmDialog } from './ActionConfirmDialog';

import { PRODUCT_TYPES } from 'utils/constants';

export const CurrentStrategiesToolbarActions = ({
	apiRef,
	isLoading,
	setIsLoading,
	setSnackbarState,
	activeProduct,
}) => {
	const [actionType, setActionType] = useState('');

	const isButtonsDisabled =
		apiRef.current.getAllRowIds().length === 0 ||
		isLoading ||
		activeProduct.code === PRODUCT_TYPES.FLEX_ANALYTIC;

	function handleOpenModal(actionType) {
		setActionType(actionType);
	}

	function handleCloseModal() {
		setActionType('');
	}

	return (
		<Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
			<IconButton
				color="inherit"
				disabled={isButtonsDisabled}
				onClick={() => handleOpenModal('cancel')}
			>
				<LightTooltip title="Cancelar todas">
					<DoDisturb />
				</LightTooltip>
			</IconButton>

			<IconButton
				color="inherit"
				disabled={isButtonsDisabled}
				onClick={() => handleOpenModal('suspend')}
			>
				<LightTooltip title="Suspender todas">
					<PauseCircleOutline />
				</LightTooltip>
			</IconButton>

			<IconButton
				color="inherit"
				disabled={isButtonsDisabled}
				onClick={() => handleOpenModal('restart')}
			>
				<LightTooltip title="Reiniciar todas">
					<PlayCircleFilledWhiteOutlined />
				</LightTooltip>
			</IconButton>

			<ActionConfirmDialog
				apiRef={apiRef}
				isLoading={isLoading}
				actionType={actionType}
				onClose={handleCloseModal}
				setIsLoading={setIsLoading}
				setSnackbarState={setSnackbarState}
			/>
		</Box>
	);
};
