import { RiskActionTypes } from './types';

export const setRiskRows = rows => ({
	type: RiskActionTypes.SET_RISK_ROWS,
	payload: { rows },
});

export const updateRiskUserPreferences = userPreferences => ({
	type: RiskActionTypes.UPDATE_RISK_USER_PREFERENCES,
	payload: { userPreferences },
});

export const updateRiskColumnsPreferences = columnsPreferences => ({
	type: RiskActionTypes.UPDATE_RISK_COLUMNS_PREFERENCES,
	payload: { columnsPreferences },
});

export const resetRiskState = () => ({
	type: RiskActionTypes.RESET_RISK_STATE,
});
