import styled from 'styled-components';

import { DataGridPro } from '@mui/x-data-grid-pro';

import { Grid } from '@mui/material';

export const TableContainer = styled(Grid)`
	width: 100%;
	height: 100%;
`;

export const StyledDataGridPro = styled(DataGridPro)`
	color: #ffffff !important;
	border: none !important;
	border-radius: 0 !important;
	font-size: 11px !important;

	.MuiDataGrid-cell {
		cursor: pointer;
	}

	.iconButton,
	.MuiDataGrid-sortIcon,
	.MuiDataGrid-columnSeparator--resizable:hover,
	.MuiDataGrid-menuIcon svg {
		color: #ffffff !important;
	}

	.MuiDataGrid-cell,
	.MuiDataGrid-columnHeaders {
		border-bottom: 1px solid #515151 !important;

		&:hover {
			cursor: pointer !important;
		}
	}

	.MuiDataGrid-footerContainer {
		border-top: 1px solid #515151 !important;
	}

	.MuiDataGrid-footerContainer {
		min-height: 22px !important;
		max-height: 22px !important;
	}
`;
