
export const STRATEGY_ADD = 'STRATEGY::ADD';
export const STRATEGY_REMOVE =  'STRATEGY::REMOVE';
export const VISIBILITY_SET =  'VISIBILITY::SET';
export const STRATEGY_NAME =  'STRATEGY_NAME::SET';
export const STRATEGY_UPDATE =  'STRATEGY_UPDATE::SET';
export const STRATEGY_INITIAL =  'STRATEGY_INITIAL::SET';


/**
 * Refactor action types
 */

const NEW_STRATEGY_UPDATE = 'NEW_STATEGY::UPDATE';

export default {
	NEW_STRATEGY_UPDATE
};
