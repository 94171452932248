import { apiAWS } from 'services/api';
import { store } from 'store';
import { saveExternalStorage } from 'store/modules/externalStorage/actions';

export function getOptionsTraderUserPreferences() {
	const { username } = store.getState().auth;

	apiAWS
		.get(`/userpreferences/${username}?service=options_trader`)
		.then(res => {
			const newState = {
				...res.data,
				portfolio: {
					strategies: [{ positions: [], totals: {} }],
				},
				jdata: [],
				selectedExpirations: [],
				expirations: [],
				recreateFromState: true,
			};

			store.dispatch(
				saveExternalStorage(
					{ state: { ...newState, recreateFromState: true } },
					'optionsChain'
				)
			);
		})
		.catch(error => console.error(error));
}
