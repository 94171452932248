import React from 'react';

import { IconButton, Modal, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import { Container, HeaderBox, ModalBody } from './styles';

// Componente base para os modais utilizados no AppHeader.
export const ModalTemplate = ({
	open,
	onClose,
	title,
	closeButton,
	children
}) => {
	return (
		<Modal open={open} onClose={onClose}>
			<Container>
				<HeaderBox boxShadow={3} p={1} display="flex">
					<HeaderBox textAlign="center" width="100%" pt={0.5}>
						<Typography variant="h6">{title}</Typography>
					</HeaderBox>
					{closeButton && (
						<HeaderBox>
							<IconButton aria-label="delete" size="small" onClick={closeButton}>
								<Close fontSize="large" />
							</IconButton>
						</HeaderBox>
					)}
				</HeaderBox>
				<ModalBody item xs={12}>
					{children}
				</ModalBody>
			</Container>
		</Modal>
	);
};
