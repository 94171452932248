export function getHour(){
	let date = new Date();
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	let hour = date.getHours();
	let minute = date.getMinutes();
	let second = date.getSeconds();
	let data;
	let horas;

	if(month <= 9){
		month=`0${month}`;
	}
	if(day < 10){
		day='0'+day;
	}
	data = `${day}/${month}/${year}`;

	if(hour < 10){
		hour='0'+hour;
	}
	if(minute < 10){
		minute='0'+minute;
	}
	if(second < 10){
		second='0'+second;
	}
	horas = `${hour}:${minute}:${second}`;
	return { data, horas };
}
