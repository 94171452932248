import React from 'react';
import TwapLeg from '../../MaterialParticipatory/MaterialTwap/TwapLeg';
import TwapExit from '../../MaterialParticipatory/MaterialTwap/TwapExit';

const TwapBodyBullet = props => {
	const { bullet, setPaperInfo, handleChange, suggestions, onSuggestionsFetchRequested, stepFunction } = props;

	return (
		<>
			<TwapLeg
				subUid={bullet.subUid}
				setPaperInfo={setPaperInfo}
				handleChange={handleChange}
				suggestions={suggestions}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				stepFunction={stepFunction}
			/>
			<TwapExit subUid={bullet.subUid} handleChange={handleChange} stepFunction={stepFunction} />
		</>
	);
};

export default TwapBodyBullet;
