import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { StyledDataGridPro } from 'components/Portfolio/styles';
import { NoRowsOverlay } from 'components/Portfolio/NoRowsOverlay';
import { ConsolidatedPositionsToolbar } from './ConsolidatedPositionsToolbar';

import { apiExec } from 'services/api';
import { formatPositions } from 'utils/positionsHelpers';
import { consolidatedPositionsColumns } from 'utils/tablesColumns/positions';
import { postConsolidatedPositionsPreferences } from 'services/userPreferences/consolidatedPositions';
import {
	setConsolidatedPositionsRows,
	updateConsolidatedPositionsUserPreferences,
	updateConsolidatedPositionsColumnsPreferences,
} from 'store/modules/consolidatedPositions/actions';

export const ConsolidatedPositions = ({ activeProduct }) => {
	const dispatch = useDispatch();
	const apiRef = useGridApiRef();

	const { username } = useSelector(state => state.auth);
	const { rows, tablePreferences } = useSelector(
		state => state.consolidatedPositions
	);

	useEffect(() => {
		const controller = new AbortController();

		function getConsolidatedPositions() {
			const queryParams = {
				params: {
					custody: 1,
					limit: 500,
					offset: 0,
				},
				signal: controller.signal,
			};

			apiExec
				.get('/positions', queryParams)
				.then(response => {
					const formattedPositions = formatPositions(
						response.data.records
					);

					if (formattedPositions.length > 0) {
						dispatch(
							setConsolidatedPositionsRows(formattedPositions)
						);
					}
				})
				.catch(error => {
					console.log(error);
				});
		}

		getConsolidatedPositions();

		const interval = setInterval(() => {
			getConsolidatedPositions();
		}, 5000);

		return () => {
			controller.abort();
			clearInterval(interval);
		};
	}, []);

	function onTableStateChange(event) {
		if (event.density.value !== tablePreferences.density) {
			const density = { density: event.density.value };

			dispatch(updateConsolidatedPositionsUserPreferences(density));
			postConsolidatedPositionsPreferences(username, density);
		}
	}

	function onColumnVisibilityModelChange(event) {
		const filteredKeys = Object.keys(event).filter(key => event[key]);

		dispatch(
			updateConsolidatedPositionsColumnsPreferences({
				columnVisibilityModel: { ...event },
				orderedFields: [...filteredKeys],
			})
		);

		postConsolidatedPositionsPreferences(username, {
			columnVisibilityModel: { ...event },
		});
	}

	function onColumnOrderChange() {
		const columnsOrder = apiRef.current
			.getAllColumns()
			.map(column => column.field);

		dispatch(
			updateConsolidatedPositionsColumnsPreferences({
				orderedFields: [...columnsOrder],
			})
		);

		postConsolidatedPositionsPreferences(username, {
			orderedFields: [...columnsOrder],
		});
	}

	function onFilterModelChange(event) {
		dispatch(
			updateConsolidatedPositionsUserPreferences({
				filter: { filterModel: { ...event } },
			})
		);

		postConsolidatedPositionsPreferences(username, {
			tableFilter: { filterModel: { ...event } },
		});
	}

	function onSortModelChange(event) {
		const sorting = { sorting: { sortModel: [...event] } };
		dispatch(updateConsolidatedPositionsUserPreferences(sorting));
		postConsolidatedPositionsPreferences(username, sorting);
	}

	return (
		<Grid
			container
			sx={{
				gap: 0.5,
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				p: '1.5rem 0 0.5rem',
			}}
		>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexGrow: 1,
				}}
			>
				<StyledDataGridPro
					rows={rows}
					apiRef={apiRef}
					columns={consolidatedPositionsColumns}
					components={{
						Toolbar: () =>
							ConsolidatedPositionsToolbar(apiRef, activeProduct),
						NoRowsOverlay: () =>
							NoRowsOverlay('Nenhuma posição ativa'),
					}}
					getRowClassName={params =>
						params.indexRelativeToCurrentPage % 2 === 0
							? 'even'
							: 'odd'
					}
					onStateChange={event => onTableStateChange(event)}
					onSortModelChange={event => onSortModelChange(event)}
					onColumnOrderChange={event => onColumnOrderChange(event)}
					onFilterModelChange={event => onFilterModelChange(event)}
					onColumnVisibilityModelChange={event =>
						onColumnVisibilityModelChange(event)
					}
					hideFooter
					initialState={{
						columns: tablePreferences?.columns,
						sorting: tablePreferences?.sorting,
						filter: tablePreferences?.filter,
					}}
				/>
			</Box>
		</Grid>
	);
};
