import React from 'react';
import { styled, Tooltip, tooltipClasses } from '@mui/material';

export const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: '0.875rem',
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.white,
	}
}));
