import React from 'react';

import { usePagination } from '@mui/lab';
import { Box, IconButton, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

import {
	gridPageCountSelector,
	gridPageSelector,
	useGridSelector,
} from '@mui/x-data-grid-pro';

export const RecurrenceCustomPagination = (apiRef, paginationInfo) => {
	const page = useGridSelector(apiRef, gridPageSelector);
	const pageCount = useGridSelector(apiRef, gridPageCountSelector);

	const { items } = usePagination({
		count: pageCount,
		page: page + 1,
		onChange: (_, value) => apiRef.current.setPage(value - 1),
	});

	const minPage =
		paginationInfo.num_records === 0
			? paginationInfo.num_records
			: paginationInfo.offset + 1;

	const maxPage =
		paginationInfo.num_records < 25
			? paginationInfo.offset + paginationInfo.num_records
			: paginationInfo.offset + 25;

	return (
		<Box
			sx={{
				display: ' flex',
				alignItems: 'center',
			}}
		>
			{paginationInfo && (
				<Typography sx={{ mr: 1 }}>
					{minPage} - {maxPage}
				</Typography>
			)}

			{items.map(({ type, ...item }) => (
				<>
					{type === 'previous' && (
						<IconButton {...item}>
							<ArrowBackIos />
						</IconButton>
					)}

					{type === 'next' && (
						<IconButton
							{...item}
							disabled={paginationInfo.num_records <= 25}
						>
							<ArrowForwardIos />
						</IconButton>
					)}
				</>
			))}
		</Box>
	);
};
