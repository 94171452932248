import React from 'react';
import { useHistory } from 'react-router-dom';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Box,
	Typography,
	IconButton,
	DialogTitle as MuiDialogTitle,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { withStyles } from '@material-ui/core/styles';

const AlertToken = ({ modalIsOpen, sendLink, alterEmail }) => {
	const history = useHistory();

	const styles = theme => ({
		root: {
			margin: 0,
			padding: theme.spacing(2),
		},
	});

	const DialogTitle = withStyles(styles)(props => {
		const { children, classes, ...other } = props;
		return (
			<MuiDialogTitle
				disableTypography
				className={classes.root}
				{...other}
			>
				<Typography variant="h6" style={{ display: 'flex' }}>
					{children}
				</Typography>
			</MuiDialogTitle>
		);
	});

	const sendLinkAlterModal = () => {
		sendLink();
	};

	return (
		<>
			<Dialog
				aria-labelledby="customized-dialog-title"
				open={modalIsOpen}
				fullWidth
				maxWidth="xs"
			>
				<Box p={2}>
					<DialogTitle id="customized-dialog-title">
						<Typography variant="h4">
							{alterEmail
								? `Enviamos um novo email de primeiro acesso para ${alterEmail}`
								: 'Seu link de criação de senha e assinatura eletrônica expirou.'}
						</Typography>
						{alterEmail ? (
							<IconButton
								style={{
									alignItems: 'end',
									padding: '0px',
									height: '15px',
								}}
								onClick={() => history.push('/login')}
							>
								<CloseIcon />
							</IconButton>
						) : (
							''
						)}
					</DialogTitle>
					<DialogContent style={{ padding: '8px 14px' }}>
						<DialogContentText id="alert-dialog-description">
							<Typography
								variant="h5"
								style={{ fontSize: '20px' }}
							>
								{alterEmail
									? 'Por favor, verifique suas caixas de email e spam.'
									: 'Para receber um novo link, clique no botão abaixo.'}
							</Typography>
						</DialogContentText>
					</DialogContent>
					<DialogActions style={{ justifyContent: 'center' }}>
						{alterEmail ? (
							<Button
								style={{ fontSize: '12px' }}
								onClick={() => history.push('/login')}
								color="primary"
								autoFocus
							>
								Fechar
							</Button>
						) : (
							<Button
								onClick={sendLinkAlterModal}
								color="primary"
								variant="contained"
								autoFocus
							>
								Novo link
							</Button>
						)}
					</DialogActions>
				</Box>
			</Dialog>
		</>
	);
};

export default AlertToken;
