import styled from 'styled-components';

import { DataGridPro } from '@mui/x-data-grid-pro';

export const StyledDataGridPro = styled(DataGridPro)`
	color: #ffffff !important;
	border: none !important;

	.iconButton,
	.MuiDataGrid-sortIcon,
	.MuiDataGrid-columnSeparator--resizable:hover,
	.MuiDataGrid-menuIcon svg {
		color: #ffffff !important;
	}

	.MuiDataGrid-cell,
	.MuiDataGrid-columnHeaders {
		border-bottom: 1px solid #515151 !important;

		&:hover {
			cursor: pointer !important;
		}
	}

	.MuiDataGrid-footerContainer {
		border-top: 1px solid #515151 !important;
	}

	.MuiButton-root,
	.MuiCheckbox-root {
		color: #ffffff !important;
	}
`;
