import { apiMarketData } from 'services/api';
import { store } from 'store';
import { additionalUpdate } from 'store/modules/bottomBullet/actions';

async function getLastQuotes(symbol) {
	if (symbol) {
		return await apiMarketData.get(
			`/exchanges/BVMF/instruments/${symbol}/quotes/last`
		);
	}

	return {};
}

export async function updateStrategyInfoWithLastQuotes(
	symbol,
	legIndex,
	bullet
) {
	const response = await getLastQuotes(symbol);

	if (legIndex !== 'band') {
		bullet.StrategyInfos[legIndex].lastPx = response.data?.lastPx;
		store.dispatch(additionalUpdate('StrategyInfos', bullet.StrategyInfos));
	}
}
