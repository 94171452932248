import React from 'react';

import { Check, Clear } from '@mui/icons-material';
import { List, ListItem, Typography } from '@mui/material';

import { passwordRegex } from 'utils/passwordRegex';

export const PasswordValidationList = ({ password, secondPassword, sx, isPasswordsEqual }) => {
	const regex = passwordRegex(password, secondPassword);

	return (
		<List
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 1,
				width: '100%',
				color: '#333',
				...sx,
			}}
		>
			<ListItem disablePadding>
				{isPasswordsEqual ? (
					<Check color="success" fontSize="small" />
				) : (
					<Clear color="error" fontSize="small" />
				)}
				<Typography variant="subtitle1" sx={{ ml: 1 }}>As duas senhas devem ser iguais.</Typography>
			</ListItem>

			<ListItem disablePadding>
				{regex[0] ? <Check color="success" fontSize="small" /> : <Clear color="error" fontSize="small" />}
				<Typography variant="subtitle1" sx={{ ml: 1 }}>Entre 6 e 12 caracteres</Typography>
			</ListItem>

			<ListItem disablePadding>
				{regex[1] ? <Check color="success" fontSize="small" /> : <Clear color="error" fontSize="small" />}
				<Typography variant="subtitle1" sx={{ ml: 1 }}>Letras maiúsculas e minúsculas</Typography>
			</ListItem>

			<ListItem disablePadding>
				{regex[2] ? <Check color="success" fontSize="small" /> : <Clear color="error" fontSize="small" />}
				<Typography variant="subtitle1" sx={{ ml: 1 }}>Conter pelo menos 1 número</Typography>
			</ListItem>

			<ListItem disablePadding>
				{!regex[5] ? <Check color="success" fontSize="small" /> : <Clear color="error" fontSize="small" />}
				<Typography variant="subtitle1" sx={{ ml: 1 }}>Não conter emojis {!regex[5] ? '😃' : '🙁'}</Typography>
			</ListItem>

			<ListItem disablePadding>
				{regex[3] ? <Check color="success" fontSize="small" /> : <Clear color="error" fontSize="small" />}
				<Typography variant="subtitle1" sx={{ ml: 1 }}>
					Ao menos um dos caracteres:
					<strong> @ # $ & !</strong>
				</Typography>
			</ListItem>
		</List>
	);
};
