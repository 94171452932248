import styled from 'styled-components';
import { Card, Dialog, Grid } from '@mui/material';
import {
	ClickableBox,
	GeneralButton,
} from 'components/MaterialComponents/MaterialStyles/style';

export const TitleBody = styled(Grid)`
	background-color: #303030;
	box-shadow: 0px 3px 6px #0000002e;
	:first-child {
		border-radius: 3px 3px 0px 0px;
	}
`;

export const ScanContainer = styled(Grid)`
	border-radius: 3px;
`;

export const ShadowGrid = styled(Grid)`
	padding: 4px !important;
	box-shadow: 3px 3px 6px #00000036;
	margin: 4px !important;
	border-radius: 3px;
	background-color: #424242;
`;

export const FilterGrid = styled(Grid)`
	padding: 0px !important;
	box-shadow: 3px 3px 6px #00000036;
	margin: 4px !important;
	border-radius: 3px;
	background-color: #424242;
`;

export const ChartContainer = styled.div`
	min-height: 280px;
	.svg-container {
		margin-right: 16px;
		min-height: 280px;
	}
`;
export const TitleClick = styled(ClickableBox)`
	max-width: 999px;
`;

export const StyledCard = styled(Card)`
	background: #303030 !important;
	height: 100%;
	transition: 0.2s;
	.MuiCardHeader-root {
		padding-left: 8px !important;
		height: 100%;
	}
	.MuiCardHeader-avatar {
		margin-right: 8px !important;
	}
	.MuiAvatar-root {
		@keyframes backgroundAnimation {
			0% {
				background-color: #757575;
			}
			50% {
				background-color: #8c8c8c;
			}
			100% {
				background-color: #757575;
			}
		}
		animation: backgroundAnimation 2s linear infinite;
		transform: ${props =>
		props.rotate !== undefined && `${props.rotate}(180deg)`};
		transition: 0.2s;
	}
	.MuiAvatar-root:hover {
		cursor: pointer;
		transition: 0.2s;
		transform: ${props =>
		props.rotate !== undefined && `${props.rotate}(180deg)`}
			scale(1.1);
	}
	.MuiAvatar-root:active {
		transition: 0.1s;
		transform: ${props =>
		props.rotate !== undefined && `${props.rotate}(180deg)`}
			scale(0.9);
		background-color: #757575 !important;
	}
`;

export const StyledGeneralButton = styled(GeneralButton)`
	padding: 2px 4px !important;
	min-width: 44px !important;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const StyledDialog = styled(Dialog)`
	color: black !important;
	.MuiDataGridColumnsPanel-container {
		display: flex !important;
		flex-flow: wrap !important;
		justify-content: flex-start !important;
	}
	.MuiDataGridColumnsPanel-column {
		display: flex !important;
		width: 25% !important;
		justify-content: space-between !important;
	}
	.MuiDataGridPanelHeader-root {
		padding-top: 0px !important;
	}
	/* .MuiDataGridPanelFooter-root {
        display: none !important;
    } */
`;
