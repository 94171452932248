const grdEdit = bullet => {
	const hash = {
		StrategyCode: bullet.content.StrategyCode,
		CustomParameters: {
			EntrancePrice: bullet.content.CustomParameters.EntrancePrice,
			IncrementPriceStepEntrance:
                bullet.content.CustomParameters.IncrementPriceStepEntrance,
			IncrementPriceStopGain:
                bullet.content.CustomParameters.IncrementPriceStopGain,
			NumberOfEntrances:
                bullet.content.CustomParameters.NumberOfEntrances,
			OrderQuantity: bullet.content.CustomParameters.OrderQuantity,
			PreservationPercentual:
                bullet.content.CustomParameters.PreservationPercentual,
			PreservationStartTrigger:
                bullet.content.CustomParameters.PreservationStartTrigger,
			PreservationStep: bullet.content.CustomParameters.PreservationStep,
			SendStopLossOnEnd:
                bullet.content.CustomParameters.SendStopLossOnEnd,
			StopByPrice1: bullet.content.CustomParameters.StopByPrice1,
			StopByPrice2: bullet.content.CustomParameters.StopByPrice2,
			StopGainGlobalFinance:
                bullet.content.CustomParameters.StopGainGlobalFinance,
			StopLossFinance: bullet.content.CustomParameters.StopLossFinance,
			UseStopGainGlobalFinance:
                bullet.content.CustomParameters.UseStopGainGlobalFinance,
		},
		EndTime: bullet.content.EndTime || bullet.values.EndTime,
		ClOrdID: bullet.content.ClOrdID,
	};
	if (bullet.content.CustomParameters.UseStartWhenCross === 'Y') {
		hash.CustomParameters.StartWhenCross =
            bullet.content.CustomParameters.StartWhenCross;
	} else {
		hash.CustomParameters.NumberOfMinPriceIncrementsToStart =
            bullet.content.CustomParameters.NumberOfMinPriceIncrementsToStart;
	}
	if (bullet.content.StrategyCode === 'grddin') {
		hash.CustomParameters.MaximumZeroTimeWindow =
            bullet.content.CustomParameters.MaximumZeroTimeWindow;
		hash.CustomParameters.RetroalimentType =
            bullet.content.CustomParameters.RetroalimentType;
		hash.CustomParameters.RetroalimentParam1 =
            bullet.content.CustomParameters.RetroalimentParam1;
		if (
			bullet.content.CustomParameters.RetroalimentType != 4 &&
            bullet.content.CustomParameters.RetroalimentType != 5
		) {
			hash.CustomParameters.RetroalimentTimeWindow =
                bullet.content.CustomParameters.RetroalimentTimeWindow;
		}
	}
	if (hash.CustomParameters.StopGainGlobalFinance === 0) {
		delete hash.CustomParameters.StopGainGlobalFinance;
	}
	if (!hash.CustomParameters.PreservationPercentual) {
		delete hash.CustomParameters.PreservationPercentual;
	}
	if (!hash.CustomParameters.PreservationStartTrigger) {
		delete hash.CustomParameters.PreservationStartTrigger;
	}
	if (!hash.CustomParameters.PreservationStep) {
		delete hash.CustomParameters.PreservationStep;
	}
	if (!hash.CustomParameters.StopByPrice1) {
		delete hash.CustomParameters.StopByPrice1;
	}
	if (!hash.CustomParameters.StopByPrice2) {
		delete hash.CustomParameters.StopByPrice2;
	}
	if (!hash.CustomParameters.StopGainGlobalFinance) {
		delete hash.CustomParameters.StopGainGlobalFinance;
	}
	if (!hash.CustomParameters.UseStopGainGlobalFinance) {
		delete hash.CustomParameters.UseStopGainGlobalFinance;
	}
	return hash;
};

export default grdEdit;
