import React from 'react';
import { useSelector } from 'react-redux';

import { GridToolbarDensitySelector } from '@mui/x-data-grid-pro';
import { StyledDataGridPro } from './styles';

import { RCT } from 'utils/bulletContext';
import { handleOrderEntry } from 'utils/handleOrderEntry';
import { recentColumns } from 'utils/tablesColumns/recent';

import { NoRowsOverlay } from 'components/Portfolio/NoRowsOverlay';

export const RecentTable = ({
	openBullet,
	setIsStrategyExpired,
	handleCloseList,
}) => {
	const recentOrders = useSelector(state => state.bottomBullet.recentOrders);

	return (
		<div style={{ height: 300, width: '100%' }}>
			<StyledDataGridPro
				components={{
					Header: GridToolbarDensitySelector,
					NoRowsOverlay: () => NoRowsOverlay('Nenhuma estratégia salva')
				}}
				onRowClick={infos => {
					handleOrderEntry(infos.row, RCT, openBullet, setIsStrategyExpired);
					handleCloseList();
				}}
				columns={recentColumns}
				rows={recentOrders}
				density="compact"
				initialState={{
					sorting: {
						sortModel: [
							{
								field: 'date',
								sort: 'desc',
							},
						]
					}
				}}
			/>
		</div>
	);
};
