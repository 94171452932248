const twapEdit = bullet => {
	const hash = {
		StrategyCode: bullet.content.StrategyCode,
		CustomParameters: {
			PriceLimit: +bullet.content.CustomParameters.PriceLimit,
			AgressionLevel:
                +bullet.content.CustomParameters.AgressionLevel || 0,
			CompleteToMarket:
                bullet.content.CustomParameters.CompleteToMarket || 'N',
		},
		StrategyLegs: [
			{
				LegOrdType: bullet.content.StrategyLegs[0].LegOrdType,
				LegMaxClipSize: bullet.content.StrategyLegs[0].LegMaxClipSize,
				LegRefID: bullet.content.StrategyLegs[0].LegRefID,
			},
		],
		EndTime: bullet.content.EndTime,
		ClOrdID: bullet.content.ClOrdID,
	};
	if (bullet.content.CustomParameters.TargetPercent) {
		hash.CustomParameters.TargetPercent = +bullet.content.CustomParameters
			.TargetPercent;
	}
	if(bullet.content.CustomParameters.ConsiderCrossOrders) {
		hash.CustomParameters.ConsiderCrossOrders = bullet.content.CustomParameters.ConsiderCrossOrders;
	}
	if(bullet.content.CustomParameters.UsingFinancialVolume === 'Y') {
		hash.CustomParameters.MaxFinancialVolume = bullet.content.CustomParameters.MaxFinancialVolume;
	}else {
		hash.StrategyLegs[0].LegQuantity = bullet.content.StrategyLegs[0].LegQuantity;
	}
	if (process.env.NODE_ENV === 'development') console.log('twap edit', hash);
	return hash;
};

export default twapEdit;
