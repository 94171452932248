import React from 'react';

import { Grid } from '@mui/material';

import { EstimatesTable } from './EstimatesTable';
import { DepletionEntryParams } from './DepletionEntryParams';
import { DepletionGradientStops } from './DepletionGradientStops';
import { DepletionGradientProfitPreservation } from './DepletionGradientProfitPreservation';

export const DepletionGradient = ({ setPaperInfo, bullet }) => {
	return (
		<Grid
			id="depletion-gradient-container"
			item
			container
			xs={12}
			spacing={1}
		>
			<Grid item container xs={6}>
				<DepletionEntryParams setPaperInfo={setPaperInfo} />
			</Grid>

			<Grid item container xs={3}>
				<Grid item container>
					<DepletionGradientStops bullet={bullet} />
				</Grid>

				<Grid item container sx={{ mt: 1 }}>
					<DepletionGradientProfitPreservation bullet={bullet} />
				</Grid>
			</Grid>

			<Grid item container xs={3}>
				<EstimatesTable
					customParameters={bullet.content.CustomParameters}
					strategyInfos={bullet.StrategyInfos}
					strategyLegs={bullet.content.StrategyLegs}
				/>
			</Grid>
		</Grid>
	);
};
