import styled from 'styled-components';
import { Box, Grid, Button, TextField } from '@mui/material';

export const Container = styled(Box)`
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	background-color: #303030;
	border-radius: 3px;
`;

export const BoxTittle = styled(Box)`
	background-color: #424242 !important;
	border-radius: 3px 3px 0px 0px;
`;

export const ModalBody = styled(Grid)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	padding: 10px;
	width: 100%;
`;

export const ButtonsContainer = styled(Box)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 8px 0;
`;

export const GeneralButton = styled(Button)`
	padding: 2px 8px !important;
	transform: 0.5s;
	color: ${props =>
		props.buttonColor === 'scanner'
			? '#303030 !important'
			: 'white !important'};

	background-color: ${props =>
		props.buttonColor === 'danger'
			? '#f44336a1 !important'
			: props.buttonColor === 'success'
				? '#206123a1 !important'
				: props.buttonColor === 'warning'
					? '#f57c00a1 !important'
					: props.buttonColor === 'info'
						? '#2196f3a1 !important'
						: props.buttonColor === 'scanner'
							? '#fffffff2 !important'
							: '#777777a1 !important'};

	:hover {
		transform: 0.5s;
		background-color: ${props =>
		props.buttonColor === 'danger'
			? '#f44336f2 !important'
			: props.buttonColor === 'success'
				? '#206123f2 !important'
				: props.buttonColor === 'warning'
					? '#f57c00f2 !important'
					: props.buttonColor === 'info'
						? '#2196f3f2 !important'
						: props.buttonColor === 'scanner'
							? '#ffffffa1 !important'
							: '#777777f2 !important'};
	}
`;

export const PasswordTextField = styled(TextField)`
	input {
		font-family: ${props => {
		if (props.visible) {
			return 'Roboto';
		}
		return 'text-security-disc';
	}};
		-webkit-text-security: ${props => {
		if (props.visible) {
			return 'initial';
		}
		return 'disc';
	}};
	}
`;
