import { apiEls } from 'services/api';

export default async function getElasticSearchValue(symbol){
	const headers = {
		'content-Type' : 'application/json'
	};
	const response = await apiEls.get(`/search?symbol=${symbol}`, {
		headers,
	});
	const idDefinition = response.data.suggest.suggest_symbol[0].options.find((item)=> item.text.toUpperCase() === symbol.toUpperCase())?.text;
	const {data} = await apiEls.get(`/search/${idDefinition}`, {
		headers,
	});
	const local = JSON.parse(localStorage.getItem('paperSuggestions')) || {};
	const merged = { ...local, ...{[symbol]:data._source} };
	localStorage.setItem('paperSuggestions', JSON.stringify(merged));
	return data._source;
}
