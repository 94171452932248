import React, { useState } from 'react';

import {
	useGridApiContext
} from '@mui/x-data-grid-pro';
import { IconButton } from '@mui/material';
import { ChevronRight, KeyboardArrowDown, MoreHoriz } from '@mui/icons-material';

import { LightTooltip } from 'components/LightTooltip';
import { RecurrenceStrategyMenu } from './RecurrenceStrategyMenu';

export const RecurrenceCustomDataTree = (props) => {
	const {
		id,
		field,
		row,
		rowNode,
		isLoading,
		handleSearch,
		setSnackbarState
	} = props;

	const apiRef = useGridApiContext();

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const isNavigationKey = (key) =>
		key === 'Home' ||
		key === 'End' ||
		key.indexOf('Arrow') === 0 ||
		key.indexOf('Page') === 0 ||
		key === ' ';

	const handleKeyDown = (event) => {
		if (event.key === ' ') {
			event.stopPropagation();
		}

		if (isNavigationKey(event.key) && !event.shiftKey) {
			apiRef.current.publishEvent('cellNavigationKeyDown', props, event);
		}
	};

	const handleClick = (event) => {
		apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
		apiRef.current.setCellFocus(id, field);
		event.stopPropagation();
	};

	function handleOpenMenu(event) {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setIsMenuOpen(!isMenuOpen);
	}

	function handleCloseMenu() {
		setAnchorEl(null);
		setIsMenuOpen(false);
	}

	return (
		<>
			{row.hierarchy.length === 1 && (
				<>
					<LightTooltip arrow title="Mais">
						<IconButton
							disabled={isLoading}
							onClick={event => handleOpenMenu(event)}
						>
							<MoreHoriz />
						</IconButton>
					</LightTooltip>

					<LightTooltip arrow title={rowNode.childrenExpanded ? 'Fechar' : 'Expandir'}>
						<IconButton
							disabled={isLoading}
							tabIndex={-1}
							className="iconButton"
							onClick={handleClick}
							onKeyDown={handleKeyDown}
						>
							{rowNode.childrenExpanded ? <KeyboardArrowDown /> : <ChevronRight />}
						</IconButton>
					</LightTooltip>
				</>
			)}

			<RecurrenceStrategyMenu
				row={row}
				isOpen={isMenuOpen}
				anchorEl={anchorEl}
				onClose={handleCloseMenu}
				handleSearch={handleSearch}
				setSnackbarState={setSnackbarState}
			/>
		</>
	);
};
