import {
	calcDaysToExpiration,
	positionModel,
	strategyModel,
} from 'pages/Simulator/utils';
import {
	STRATEGY_ADD,
	STRATEGY_REMOVE,
	VISIBILITY_SET,
	STRATEGY_UPDATE,
	POSITION_UPDATE,
	POSITION_DELETE,
	SET_INTEREST_RATE,
	GET_STRATEGIES,
	SAVE_STRATEGY,
	POSITION_UPDATE_PX,
	SET_SAVED_STRATEGY,
	SET_INITIAL_STATE,
	SET_TIMER_UPDATE,
	RESET_STRATEGY,
	SUBSCRIBE_POSITIONS,
	REMOVE_FROM_STRATEGY_LIST,
} from './actionTypes';

const initialState = {
	strategyList: [],
	activeStrategy: null,
	workspaceCreated: false,
	ir: null,
	timerUpdate: false,
};

const reducer = {
	[SET_INITIAL_STATE]: () => {
		return initialState;
	},
	[STRATEGY_ADD]: (stt, payload) => {
		const { strategy } = payload;
		return {
			...stt,
			strategyList: [
				...stt.strategyList,
				{ ...strategy, saved: false, index: stt.strategyList.length },
			],
			activeStrategy: stt.strategyList.length /*strategy.index*/,
		};
	},
	[STRATEGY_REMOVE]: (stt, payload) => {
		const { strategy } = payload;
		const newStrategies = stt.strategyList.filter(
			item => item.id !== strategy.id
		);
		let newActiveStrategy =
			stt.activeStrategy === strategy.index
				? null
				: stt.activeStrategy < strategy.index
					? stt.activeStrategy
					: stt.activeStrategy - 1;
		newActiveStrategy = newActiveStrategy < 0 ? null : newActiveStrategy;

		return {
			...stt,
			strategyList: newStrategies,
			activeStrategy: newActiveStrategy,
		};
	},
	[VISIBILITY_SET]: (stt, payload) => {
		const { index } = payload;
		return { ...stt, activeStrategy: index };
	},
	[STRATEGY_UPDATE]: (stt, payload) => {
		const { strategy } = payload;
		const newBullets = stt.strategyList.map(item =>
			item.id === strategy.id
				? {
					...item,
					...strategy,
					positions: strategy.positions || item.positions,
					saved:
							item.saved &&
							(strategy.underSymbol || item.underSymbol) ===
								item.underSymbol &&
							item.positions.length ===
								(strategy?.positions?.length ||
									item.positions.length),
				  }
				: item
		);
		return { ...stt, strategyList: newBullets };
	},
	[SET_SAVED_STRATEGY]: (stt, payload) => {
		const { index, saved } = payload;
		return {
			...stt,
			strategyList: stt.strategyList.map((item, i) =>
				i === index ? { ...item, saved } : item
			),
		};
	},
	[POSITION_UPDATE]: (stt, payload) => {
		const { activeStrategy, positionId, data } = payload;

		const newPositios = stt.strategyList[activeStrategy].positions
			.map(item => (item.id === positionId ? { ...item, ...data } : item))
			.sort((a, b) => a.index - b.index);
		const strategyUpdated = {
			...stt.strategyList[activeStrategy],
			positions: newPositios,
		};

		return {
			...stt,
			strategyList: stt.strategyList.map((strategy, i) =>
				i === activeStrategy ? strategyUpdated : strategy
			),
		};
	},
	[POSITION_UPDATE_PX]: (stt, payload) => {
		const { activeStrategy, positionId, data } = payload;
		const newPositios = stt.strategyList[activeStrategy]?.positions
			.map(item => {
				const updatePx = data;
				return item.id === positionId ? { ...item, ...updatePx } : item;
			})
			.sort((a, b) => a.index - b.index);
		const strategyUpdated = {
			...stt.strategyList[activeStrategy],
			positions: newPositios,
		};
		return {
			...stt,
			strategyList: stt.strategyList.map((strategy, i) =>
				i === activeStrategy ? strategyUpdated : strategy
			),
		};
	},
	[SET_INTEREST_RATE]: (stt, payload) => {
		const { ir } = payload;
		return { ...stt, ir };
	},

	[SET_TIMER_UPDATE]: (stt, payload) => {
		const { timerUpdate } = payload;
		return { ...stt, timerUpdate };
	},
	[POSITION_DELETE]: (stt, payload) => {
		const { position, index } = payload;
		const { ...pos } = position;
		const newPositios = stt.strategyList[index].positions
			.filter(item => item.id !== position.id)
			.sort((a, b) => a.index - b.index)
			.map((item, i) => ({ ...item, index: i }));
		const strategyUpdated = {
			...stt.strategyList[index],
			positions: newPositios,
			positionsDeleted: [
				...(stt.strategyList[index]?.positionsDeleted || []),
				{ ...pos, deleted: true },
			].filter(i => typeof i.id === 'string'),
		};

		return {
			...stt,
			strategyList: stt.strategyList.map((strategy, i) =>
				i === index ? { ...strategyUpdated, saved: false } : strategy
			),
		};
	},
	[GET_STRATEGIES]: (stt, payload) => {
		const preExistLen = stt.strategyList.length;
		const strategyList = payload.strategies.map((s, index) => ({
			...strategyModel,
			...s,
			index: index + preExistLen,
			saved: true,
			positions: s.positions
				.sort((a, b) => a.index - b.index)
				.map(p => {
					const position = {
						...positionModel,
						...p,
						theorImpliedVol: p?.theoreticImpliedVol,
						theorManualMode: false,
						exitManualMode: false,
						entryManualMode: false,
						daysToExpiration: calcDaysToExpiration(
							p.expirationDate
						),
						entryGreeks: {
							...positionModel.entryGreeks,
							delta: !p.optionType && !p.exitPxLocked ? 1 : 0,
						},
						exitGreeks: {
							...positionModel.exitGreeks,
							delta: !p.optionType && !p.exitPxLocked ? 1 : 0,
						},
					};
					if (!position.optionType) {
						delete position.optionType;
						delete position.strikePx;
						delete position.daysToExpiration;
						delete position.expirationDate;
					}
					return position;
				}),
		}));

		const mergedArray = [...stt.strategyList, ...strategyList];
		let set = new Set();
		let mergedStrategyList = mergedArray.filter(item => {
			if (!set.has(item.id)) {
				set.add(item.id);
				return true;
			}
			return false;
		}, set);

		return {
			...stt,
			strategyList: mergedStrategyList,
			workspaceCreated: true,
		};
	},
	[SAVE_STRATEGY]: (stt, payload) => {
		const { strategy, strategyResponse } = payload;
		let strategyList = stt.strategyList.map(s =>
			s.id === strategy.id
				? {
					...s,
					...strategyResponse,
					name: strategy.name,
					saved: true,
					positions: s.positions.map(p => {
						const res = strategyResponse.positions.find(
							pos => pos.index === p.index
						);
						return res ? { ...p, ...res } : p;
					}),
				  }
				: s
		);
		return { ...stt, strategyList };
	},
	[RESET_STRATEGY]: (stt, payload) => {
		const { strategy: payloadStrategy } = payload;

		const newPositions = payloadStrategy.positions
			.sort((a, b) => a.index - b.index)
			.map(p => {
				const position = {
					...positionModel,
					...p,
					theorImpliedVol: p?.theoreticImpliedVol,
					theorManualMode: false,
					exitManualMode: false,
					entryManualMode: false,
					daysToExpiration: calcDaysToExpiration(p.expirationDate),
					entryGreeks: {
						...positionModel.entryGreeks,
						delta: !p.optionType && !p.exitPxLocked ? 1 : 0,
					},
					exitGreeks: {
						...positionModel.exitGreeks,
						delta: !p.optionType && !p.exitPxLocked ? 1 : 0,
					},
				};

				if (!position.optionType) {
					delete position.optionType;
					delete position.strikePx;
					delete position.daysToExpiration;
					delete position.expirationDate;
				}

				return position;
			});

		return {
			...stt,
			strategyList: stt.strategyList.map(strategy => {
				if (strategy.id === payload.id) {
					return {
						...strategy,
						...payloadStrategy,
						saved: true,
						positions: newPositions,
					};
				} else {
					return { ...strategy };
				}
			}),
		};
	},
	[SUBSCRIBE_POSITIONS]: (state, payload) => {
		return {
			...state,
			strategyList: state.strategyList.map(strategy => {
				if (strategy.id === payload.id) {
					return {
						...strategy,
						positions: payload.positions,
					};
				} else {
					return { ...strategy };
				}
			}),
		};
	},
	[REMOVE_FROM_STRATEGY_LIST]: (state, payload) => {
		const filteredList = state.strategyList.filter(
			strategy => strategy.id !== payload.id
		);

		const activeIndexAfterRemove = Math.max(0, state.activeStrategy - 1);

		return {
			...state,
			strategyList: filteredList,
			activeStrategy: activeIndexAfterRemove,
		};
	},
};

export default function strategyFloatingReducer(
	state = initialState,
	{ type, payload }
) {
	if (typeof reducer[type] === 'function') {
		return reducer[type](state, payload);
	}
	return state;
}
