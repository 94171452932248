import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LongShort from '../../MaterialLongShort/Leg';
import LSexecutionParams from '../../MaterialLongShort/ExecutionParams';
import paperValidator from 'utils/paperValidation';
import ModeQuantity from 'components/MaterialComponents/ModeQuantity';
import { useDispatch } from 'react-redux';
import { updateCustomParameters } from 'store/modules/bottomBullet/actions';

const LongShortBodyBullet = props => {
	const {
		execType,
		bullet,
		setPaperInfo,
		suggestions,
		onSuggestionsFetchRequested,
		stepFunction,
		setSpreaAuxPrice,
		handleChange,
		marketPrice,
	} = props;
	const dispatch = useDispatch();

	function changeQuantityMode() {
		const { QuantityMode, Trigger } = bullet.content.CustomParameters;
		let changeTriggerType = {};

		if (QuantityMode === 0 && Trigger === 11) {
			changeTriggerType = { Trigger: 2 };
		}

		dispatch(
			updateCustomParameters({
				QuantityMode: QuantityMode ? 0 : 1,
				...changeTriggerType,
			})
		);
	}

	const execTypeIsBasic = execType === 'B';

	return (
		<>
			<Grid item xs={execTypeIsBasic ? 4 : 6}>
				<Grid container spacing={1} direction="row">
					<Grid
						item
						xs={execTypeIsBasic ? 4 : 3}
						style={{ paddingLeft: '6px' }}
					>
						<Typography variant="subtitle1">Entrada</Typography>
					</Grid>
					<Grid item xs={execTypeIsBasic ? 4 : 2}></Grid>
					<Grid item xs={execTypeIsBasic ? 4 : 3}>
						<ModeQuantity
							name="QuantityMode"
							selectType={
								bullet.content.CustomParameters.QuantityMode ??
								0
							}
							changeTrigger={changeQuantityMode}
							disabled={
								paperValidator(
									bullet.StrategyInfos[0],
									bullet
								) || bullet.content.ClOrdID
							}
							execType={execType}
						/>
					</Grid>
					{bullet.content.StrategyLegs.map((leg, index) => (
						<LongShort
							setPaperInfo={setPaperInfo}
							suggestions={suggestions}
							onSuggestionsFetchRequested={
								onSuggestionsFetchRequested
							}
							index={index}
							leg={leg}
							key={index}
							stepFunction={stepFunction}
							callbackMarketPrice={setSpreaAuxPrice}
							execType={execType}
						/>
					))}
				</Grid>
			</Grid>
			<LSexecutionParams
				handleChange={handleChange}
				stepFunction={stepFunction}
				marketPrice={marketPrice}
				execType={execType}
			/>
		</>
	);
};

export default LongShortBodyBullet;
