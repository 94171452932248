import { SET_NEW_STRATEGY, SET_BULLET_DRAWER, SET_PAPER_SUGGESTIONS, CLEAR_STRATEGY } from './types';

export const setNewStrategy = (newStrategy) => ({
	type: SET_NEW_STRATEGY,
	payload: { newStrategy }
});
export const setBulletDrawer = (bulletDrawer) => ({
	type: SET_BULLET_DRAWER,
	payload: { bulletDrawer }
});
export const setPaperSuggestions = (paperSuggestions) => ({
	type: SET_PAPER_SUGGESTIONS,
	payload: { paperSuggestions }
});
export const clearStrategy = () => ({
	type: CLEAR_STRATEGY
});
