const cvvolEdit = bullet => {
	const DELTA_AUTOMATIC = 2;
	const hash = {
		StrategyCode: bullet.content.StrategyCode,
		CustomParameters: {
			TriggerValue: bullet.content.CustomParameters.TriggerValue,
			BookDepth: bullet.content.CustomParameters.BookDepth,
			InterestRate: bullet.content.CustomParameters.InterestRate,
		},
		StrategyLegs: [
			{
				LegQuantity: bullet.content.StrategyLegs[0].LegQuantity,
				LegMaxClipSize: bullet.content.StrategyLegs[0].LegMaxClipSize,
				LegRefID: bullet.content.StrategyLegs[0].LegRefID,
			},
			{
				LegQuantity: bullet.content.StrategyLegs[1].LegQuantity,
				LegMaxClipSize: bullet.content.StrategyLegs[0].LegMaxClipSize,
				LegRefID: bullet.content.StrategyLegs[1].LegRefID,
			},
		],
		EndTime: bullet.content.EndTime,
		ClOrdID: bullet.content.ClOrdID,
	};
	if (bullet.content.CustomParameters.TargetPercent) {
		hash.CustomParameters.TargetPercent =
			+bullet.content.CustomParameters.TargetPercent;
	}
	if (bullet.content.CustomParameters.DeltaType != DELTA_AUTOMATIC) {
		hash.CustomParameters.DeltaFixedValue =
			bullet.content.CustomParameters.DeltaFixedValue;
	}
	if (process.env.NODE_ENV === 'development') console.log('cvvol edit', hash);
	return hash;
};

export default cvvolEdit;
