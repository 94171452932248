import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';
import { format, isValid } from 'date-fns';

import { store } from 'store';
import {
	additionalUpdate,
	resetBottomBullet,
	updateContent,
} from 'store/modules/bottomBullet/actions';
import { cypressSubmit } from './cypressUtils';
import { getStrategyTemplate } from 'utils/templates';
import { setNewStrategy } from 'store/modules/strategy/actions';
import { apiExec, showMessageErroWithoutStatusCode } from 'services/api';
import { getParsedDate } from 'utils/getParsedDate';
import { addStrategy } from 'store/modules/currentStrategies/actions';

const spreadLeg = {
	ILegAllocAccount: '',
	LegQuantity: 0,
	LegSecurityExchange: 'XBSP',
	LegSide: '1',
	LegSymbol: '',
	LegResting: 'N',
	LegMaxClipSize: 0,
};

// TODO -> Validar a necessidade do envio de todos estes campos.
// Já que apenas alguns campos são usados.
const initialState = {
	category: '1',
	category_type: '',
	code: '',
	closeOnSubmit: true,
	tooltip: 'Single',
	visible: true,
	uid: 'sorder',
	subUid: 1,
	id: 0.4180047046265263,
	StrategyInfos: [{}],
	legs: [spreadLeg],
	content: {
		signature: '',
		...getStrategyTemplate('sorder'),
	},
	signature: '',
};

function validateExpireDate(date) {
	const parsedDate = getParsedDate(date);

	if (isValid(parsedDate)) {
		return format(parsedDate, 'yyyyMMdd');
	}

	return date;
}

async function SubmitStrategy(order, closeBullet, recurrenceInfo = null) {
	const params = { ...order };
	store.dispatch(additionalUpdate('disabledSubmit', true));

	if (params.Name && !params.Name.replace(/\s/g, '').length) {
		params.Name = `${order.StrategyCode}${new Date()
			.toLocaleString()
			.replace(/[^0-9a-z]/gi, '')}`;
	}

	if (
		params.CustomParameters &&
		params.CustomParameters.PriceLimit &&
		!params.ClOrdID
	) {
		params.CustomParameters.PriceLimit = parseFloat(
			params.CustomParameters.PriceLimit
		);
	}

	if (
		params.CustomParameters &&
		params.CustomParameters.ToMarketTriggerValue &&
		params.ClOrdID
	) {
		params.CustomParameters.ToMarketTriggerValue =
			params.CustomParameters.ToMarketTriggerValue.toString();
	}

	let request;
	const clOrdId = params.ClOrdID;
	const accounts = params.Accounts;

	const currentStrategies = store.getState().currentStrategies.rows;

	const positions =
		currentStrategies?.filter(row => row.hierarchy.length === 1) ?? [];

	const haveGradient = positions.filter(
		el =>
			el?.StrategyCode === 'grdlin' ||
			el?.TargetStrategy === 'grdlin' ||
			el?.TargetStrategy == 1032
	);

	if (recurrenceInfo?.isRecurrence) {
		const { ActiveStartDate, ActiveEndType, ActiveEndDate, FreqType } =
			recurrenceInfo;

		if (!ActiveEndDate || ActiveEndDate === '') {
			toast.error('Informe uma data final para sua recorrência!');
			store.dispatch(additionalUpdate('disabledSubmit', false));
			return;
		}

		const encodeSignature = btoa(params.Signature);

		const formattedBody = {
			ActiveStartDate: ActiveStartDate,
			ActiveEndType: ActiveEndType,
			ActiveEndDate: ActiveEndDate,
			FreqType: FreqType,
			Signature: encodeSignature,
			StrategyTemplate: {
				...params,
				ExpireDate: validateExpireDate(params.ExpireDate),
			},
		};

		delete formattedBody.StrategyTemplate.Accounts;

		request = apiExec.post('/schedulers', formattedBody);

		ReactGA.event({
			category: 'Recurrence',
			action: `NEW_RECURRENCE_${params.StrategyCode ?? ''}`,
		});
	} else if (params.ClOrdID) {
		// Edição
		delete params.ClOrdID;
		request = apiExec.put(`/algos/${clOrdId}`, params);
	} else if (window.Cypress) {
		delete params.Accounts;

		if (
			params.StrategyCode === 'grdlin' ||
			params.StrategyCode === 'grddin'
		) {
			if (haveGradient.length === 0) {
				request = apiExec.post('/algos', params);

				const stratCode = (params.StrategyCode ?? '').toUpperCase();
				ReactGA.event({
					category: 'Execution',
					action: `NEW_ALGO_${stratCode}`,
				});
			}
			delete params.Name;
		}

		cypressSubmit(params);
		return;
	} else {
		params.ExpireDate = validateExpireDate(params.ExpireDate);

		delete params.Accounts;
		params.BasketId = 'fxs';

		request = apiExec.post('/algos', params);

		// Enviar estatisticas para google analitics
		const stratCode = (params.StrategyCode ?? '').toUpperCase();
		ReactGA.event({
			category: 'Execution',
			action: `NEW_ALGO_${stratCode}`,
		});
	}

	request
		.then(response => {
			if (!store.getState().bottomBullet.bullet.keepSignature) {
				store.dispatch(additionalUpdate('signature', ''));
			}

			if (store.getState().bottomBullet.bullet.closeOnSubmit) {
				initialState.signature = store.getState().bottomBullet.bullet
					.keepSignature
					? store.getState().bottomBullet.bullet.signature
					: '';
				resetBottomBullet(initialState);
			}

			if (!clOrdId) {
				if (!window.Cypress) {
					toast.success('Estratégia enviada com sucesso');
				}

				store.dispatch(setNewStrategy(response.data.ClOrdID));

				params.ClOrdID = response.data.ClOrdID;
				params.Accounts = accounts;

				// Tempo de 1 segundo para o streaming atualizar a tela sem depender diretamente de um dispatch
				setTimeout(() => {
					store.dispatch(addStrategy({ ...params, Status: 1 }));
				}, 1000);
			} else {
				toast.success(
					'Edição de estratégia enviada, aguarde confirmação'
				);
			}

			store.dispatch(updateContent({ Name: '' }));
			store.dispatch(additionalUpdate('disabledSubmit', false));

			if (store.getState().bottomBullet.bullet.closeOnSubmit) {
				closeBullet(false);
			}
		})
		.catch(error => {
			// Verifico se houve um erro sem status code
			showMessageErroWithoutStatusCode(error);

			store.dispatch(additionalUpdate('disabledSubmit', false));
		});
}

export default SubmitStrategy;
