import { RiskActionTypes } from './types';

const INITIAL_STATE = {
	rows: [],
	tablePreferences: {
		density: 'standard',
		filter: {
			filterModel: {
				items: [],
				linkOperator: 'and',
				quickFilterValues: [],
				quickFilterLogicOperator: 'and',
			},
		},
		columns: {
			columnVisibilityModel: {
				account: true,
				operCode: true,
				exchange: true,
				riskType: true,
				securityType: true,
				asset: true,
				symbol: true,
				netBuy: true,
				netSell: true,
				gross: true,
				net: true,
				netClosed: true,
				grossExec: true,
			},
			orderedFields: [
				'account',
				'operCode',
				'exchange',
				'riskType',
				'securityType',
				'asset',
				'symbol',
				'netBuy',
				'netSell',
				'gross',
				'net',
				'netClosed',
				'grossExec',
			],
		},
	},
};

export default function reducer(state = INITIAL_STATE, { type, payload }) {
	switch (type) {
		case RiskActionTypes.SET_RISK_ROWS:
			return { ...state, rows: payload.rows };
		case RiskActionTypes.UPDATE_RISK_USER_PREFERENCES:
			return {
				...state,
				tablePreferences: {
					...state.tablePreferences,
					...payload.userPreferences,
				},
			};
		case RiskActionTypes.UPDATE_RISK_COLUMNS_PREFERENCES:
			return {
				...state,
				tablePreferences: {
					...state.tablePreferences,
					columns: {
						...state.tablePreferences.columns,
						...payload.columnsPreferences,
					},
				},
			};
		case RiskActionTypes.RESET_RISK_STATE:
			return INITIAL_STATE;
		default:
			return state;
	}
}
