import styled from 'styled-components';

const DivDisplayFlex = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ContainerBody = styled(DivDisplayFlex)`
	width: 100%;
	flex-direction: column;
`;

export const HeaderContainer = styled(DivDisplayFlex)`
	width: 100%;
	height: 150px;
`;

export const HeaderMain = styled.div`
	display: flex;
	align-items: flex-end;
	width: 95%;
	height: 95%;
	margin-top: 10px;
`;

export const ItemHeader = styled(DivDisplayFlex)`
	align-items: ${props => (props.align ? 'flex-start' : 'center')};
	height: ${props => props.height};
	width: ${props => props.width};

	p {
		font-size: 12px;
		margin-top: 10px;
		color: rgb(197, 183, 146);
	}

	h1 {
		color: #004d86;
		font-weight: 300;
	}
`;

export const ImageContainer = styled.img`
	width: 90%;
`;

export const ContainerMain = styled.div`
	display: flex;
	flex-direction: column;
	width: 95%;
`;

export const TitleTable = styled.div`
	text-align: center;
	width: 100%;
	height: 20px;
	background-color: ${props => (props.bgColor ? '#007c23' : '#004D86')};
	p {
		font-size: 14px;
		color: white;
		font-weight: 300;
	}
`;

export const TableStrategy = styled.table`
	width: 100%;
	margin-bottom: 20px;
	border-collapse: collapse;
	border: 1px solid #ecf0f1;
	/* font-weight: 300,400,500,700  */
	th {
		font-size: 9px;
		padding: 7px;
		background-color: ${props =>
		props.colorTitles ? '#dae3c0' : '#c5b792'};
		color: ${props => (props.colorTitles ? 'black' : '#FFFFFF')};
		width: 100px;
		text-align: center;
		border-right: none !important;
		border-left: none !important;
	}

	td {
		padding: 5px;
		font-size: 10px;
		text-align: left;
		line-height: 9px;
		width: 110px;
		color: black;
		border: 1px solid #ecf0f1;
	}

	#alignDescribe {
		text-align: right;
	}
`;

export const TableAnalisy = styled.table`
	width: 100%;
	margin-bottom: 20px;
	border-collapse: collapse;
	border: 1px solid rgba(209, 204, 192, 0.75);
`;

export const TitleTdAnalisy = styled.td`
	padding: 3px;
	width: 120px;
	font-size: 9px;
	text-align: left;
	background-color: ${props => (props.colorTd ? '#dae3c0' : '#c5b792')};
	color: ${props => (props.colorTd ? 'black' : '#ecf0f1')};
	border: 1px solid rgba(209, 204, 192, 0.75);
`;

export const TextdAnalisy = styled(TitleTdAnalisy)`
	color: black;
	text-align: right;
	background-color: transparent;
`;

export const ContainerTables = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: auto;
	margin-bottom: ${props => props.marginBottom};
`;

export const ItemConteiner = styled.div`
	display: flex;
	flex-direction: column;
	width: ${props => props.width};
	height: ${props => props.height};
`;

export const TitlePay = styled(TitleTable)`
	background-color: ${props => (props.bgColor ? '#007c23' : '#004D86')};
	width: 100%;
`;

export const TablePay = styled.table`
	width: 100%;
	margin-bottom: 20px;
	border-collapse: collapse;
	border: 1px solid #ecf0f1;
`;

export const TitlethPay = styled.th`
	font-size: 8px;
	padding: 2px;
	background-color: ${props => (props.colorTdPay ? '#dae3c0' : '#c5b792')};
	color: ${props => (props.colorTdPay ? 'black' : '#FFFFFF')};
	width: 50px;
	text-align: center;
	border-right: none !important;
	border-left: none !important;
`;

export const TexttdPay = styled(TextdAnalisy)`
	padding: 2px;
	border: 1px solid #ecf0f1;
`;

export const ItemsDivs = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: ${props => props.height};
	margin-bottom: ${props => (props.margin ? '55px' : '0px')};
`;

export const ContainerGrapich = styled.div`
	height: 100%;
	width: 100%;

	#chart {
		position: absolute;
	}

	#watermarkImg {
		width: 20%;
		margin-left: 48%;
		margin-top: 28%;
		opacity: 0.78;

		img {
			width: 80%;
		}
	}
`;

export const ContainerDescribe = styled.div`
	overflow: hidden;
	color: #485460;
	height: 100%;
	width: 100%;
	text-align: justify;
	text-overflow: ellipsis;
	word-wrap: break-word;
	border: 1px solid #ecf0f1;
`;

export const ContainerFooter = styled.div`
	display: flex;
	flex-direction: column;
	height: 220px;
`;

export const ImageDisclaimer = styled.img`
	width: 10%;
	margin-bottom: 3px;
`;

export const SignatureText = styled.p`
	color: ${props => props.color};
	font-size: ${props => props.size};
	font-weight: ${props => (props.bold ? 'bold' : 'normal')};
	line-height: ${props => props.sizeLineHeight};
`;

export const TextSignature = styled.p`
	color: #485460;
	font-size: ${props => props.size};
	font-weight: ${props => (props.notBold ? 'normal' : 'bold')};
	text-align: justify;
	line-height: ${props => (props.lineSpacing ? '0px' : 'auto')};
`;

export const LinkDisclaimer = styled.p`
	color: rgb(197, 183, 146);
	font-size: ${props => (props.resizeFont ? '9px' : '8px')};
	line-height: 8px;
`;

export const PowerBy = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 100px;
`;

export const ImagePowerBy = styled.img`
	width: 15%;
`;
