import { ConsolidatedPositionsActionTypes } from './actionTypes';

export const setConsolidatedPositionsRows = rows => ({
	type: ConsolidatedPositionsActionTypes.SET_CONSOLIDATED_POSITIONS_ROWS,
	payload: { rows },
});

export const updateConsolidatedPositionsUserPreferences = userPreferences => ({
	type: ConsolidatedPositionsActionTypes.UPDATE_CONSOLIDATED_POSITIONS_USER_PREFERENCES,
	payload: { userPreferences },
});

export const updateConsolidatedPositionsColumnsPreferences =
	columnsPreferences => ({
		type: ConsolidatedPositionsActionTypes.UPDATE_CONSOLIDATED_POSITIONS_COLUMNS_PREFERENCES,
		payload: { columnsPreferences },
	});

export const resetConsolidatedPositionsState = () => ({
	type: ConsolidatedPositionsActionTypes.RESET_CONSOLIDATED_POSITIONS_STATE,
});
