import React from 'react';

import { ModalBody, BoxTittle } from '../AppHeader/Modals/UserMenu/styles';

import { Close } from '@mui/icons-material';
import {
	Box,
	IconButton,
	TextField,
	Modal,
	Grid,
	Typography,
	Button,
} from '@mui/material';

import DecimalInput from '../MaterialComponents/DecimalInput';

export const ModalPdf = ({
	isModalOpen,
	handleClose,
	setNameStrategy,
	comments,
	setComments,
	initPrice,
	endPrice,
	setInitPrice,
	setEndPrice,
	handleDataPDF,
}) => {
	function stepFunction(
		stepBtnType,
		stepType,
		stepSubType,
		stepSize,
		valueToStep
	) {
		if (isNaN(valueToStep)) {
			return;
		}
		if (stepBtnType === 'Up') {
			stepType(valueToStep + stepSize);
		} else if (stepBtnType === 'Down') {
			stepType(valueToStep - stepSize);
		}
	}

	return (
		<>
			<Modal open={isModalOpen} onClose={handleClose}>
				<ModalBody>
					<BoxTittle boxShadow={3} p={1} display="flex">
						<BoxTittle textAlign="center" width="100%" pt={0.5}>
							<Typography variant="h6">Relátorio</Typography>
						</BoxTittle>
						<BoxTittle position="absolute" left="91%">
							<IconButton size="small" onClick={handleClose}>
								<Close fontSize="large" />
							</IconButton>
						</BoxTittle>
					</BoxTittle>
					<Box p={2}>
						<Grid
							container
							spacing={2}
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid item xs={12}>
								<TextField
									size="small"
									fullWidth
									label="Nome da Estratégia"
									variant="outlined"
									onChange={e =>
										setNameStrategy(e.target.value)
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id="outlined-multiline-static"
									label="Comentarios"
									multiline
									rows={4}
									fullWidth
									variant="outlined"
									onChange={e => setComments(e.target.value)}
									inputProps={{ maxlength: 450 }}
									helperText={
										<Typography
											variant="body1"
											sx={{ ml: -1.25 }}
										>
											{comments.length}/450
										</Typography>
									}
								/>
							</Grid>
							<Box sx={{ width: '100%' }}>
								<Box display="flex">
									<Box p={1.5}>
										<Typography variant="h6">
											Intervalo de Preços
										</Typography>
									</Box>
								</Box>
								<Box display="flex" flexDirection="row">
									<Box p={1}>
										<DecimalInput
											label="Inicio"
											minVal={0}
											maxVal={9999999}
											stepFunction={stepFunction}
											stepType={setInitPrice} // type
											stepSubType="" // subtype
											value={initPrice}
											valueToStep={initPrice}
											minDecScale={2}
											stepSize={0.01}
											onChange={e =>
												setInitPrice(
													parseFloat(e.value)
												)
											}
										/>
									</Box>
									<Box p={1}>
										<DecimalInput
											label="Fim"
											minVal={0}
											maxVal={9999999}
											stepFunction={stepFunction}
											stepType={setEndPrice} // type
											stepSubType="" // subtype
											value={endPrice}
											valueToStep={endPrice}
											minDecScale={2}
											stepSize={0.01}
											onChange={e =>
												setEndPrice(parseFloat(e.value))
											}
										/>
									</Box>
								</Box>
							</Box>
							<Grid item xs={6}>
								<Button
									variant="contained"
									color="error"
									onClick={handleClose}
								>
									Fechar
								</Button>
							</Grid>
							<Grid item xs={6}>
								<Box textAlign="right">
									<Button
										variant="contained"
										onClick={handleDataPDF}
									>
										Gerar
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</ModalBody>
			</Modal>
		</>
	);
};
