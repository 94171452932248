import React from 'react';
import Leg from '../../MaterialSingle/Leg';
import Exit from '../../MaterialSingle/Exit';

const SingleBodyBullet = props => {
	const { bullet, setPaperInfo, handleChange, suggestions, onSuggestionsFetchRequested, stepFunction } = props;
	return (
		<>
			<Leg
				subUid={bullet.subUid}
				setPaperInfo={setPaperInfo}
				handleChange={handleChange}
				suggestions={suggestions}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				stepFunction={stepFunction}
			/>
			<Exit subUid={bullet.subUid} handleChange={handleChange} stepFunction={stepFunction} />
		</>
	);
};

export default SingleBodyBullet;
