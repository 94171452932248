import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Delete } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import {
	GridToolbarDensitySelector,
	useGridApiRef,
} from '@mui/x-data-grid-pro';

import { SVD } from 'utils/bulletContext';
import { savedColumns } from 'utils/tablesColumns/saved';
import { handleOrderEntry } from 'utils/handleOrderEntry';
import { saveDataBullet } from 'store/modules/bottomBullet/actions';

import { StyledDataGridPro } from './styles';
import { NoRowsOverlay } from 'components/Portfolio/NoRowsOverlay';

export const SavedTable = ({
	openBullet,
	setIsStrategyExpired,
	handleCloseList,
}) => {
	const dispatch = useDispatch();
	const apiRef = useGridApiRef();

	const savedOrders = useSelector(state => state.bottomBullet.savedOrders);

	const [isRowSelected, setIsRowSelected] = useState(false);

	function handleDelete(selectedRows) {
		const newOrders = savedOrders.filter(orderItem => {
			return !selectedRows.some(
				selectedItem =>
					orderItem.time === selectedItem.time &&
					orderItem.date === selectedItem.date &&
					orderItem.id === selectedItem.id
			);
		});

		apiRef.current.state.selection = [];
		dispatch(saveDataBullet(newOrders, 'delete'));
	}

	function CustomToolbar() {
		const selectedRows = [...apiRef.current.getSelectedRows()].map(
			// eslint-disable-next-line no-unused-vars
			([_, object]) => object
		);

		return (
			<>
				<GridToolbarDensitySelector />

				<Tooltip title="Deletar selecionadas">
					<IconButton
						disabled={
							savedOrders.length <= 0 || selectedRows.length <= 0
						}
						onClick={() => handleDelete(selectedRows)}
					>
						<Delete />
					</IconButton>
				</Tooltip>
			</>
		);
	}

	return (
		<div style={{ height: 300, width: '100%' }}>
			<StyledDataGridPro
				components={{
					Header: CustomToolbar,
					NoRowsOverlay: () =>
						NoRowsOverlay('Nenhuma estratégia salva'),
				}}
				onRowClick={infos => {
					handleOrderEntry(
						infos.row,
						SVD,
						openBullet,
						setIsStrategyExpired
					);
					handleCloseList();
				}}
				onRowSelected={() => setIsRowSelected(!isRowSelected)}
				checkboxSelection
				apiRef={apiRef}
				columns={savedColumns}
				rows={savedOrders}
				density="compact"
				initialState={{
					sorting: {
						sortModel: [
							{
								field: 'date',
								sort: 'desc',
							},
						],
					},
				}}
			/>
		</div>
	);
};
