import React from 'react';

import { Box } from '@mui/material';
import { DownloadingOutlined } from '@mui/icons-material';

import { Container } from './styles';

export const OverlaySimulator = () => {
	return (
		<Container>
			<DownloadingOutlined sx={{ fontSize: 70, color: '#595959' }} />
			<Box sx={{ mt: 1 }}>Carregando...</Box>
		</Container>
	);
};
