import React from 'react';
import moment from 'moment';

import { CircularProgress, IconButton } from '@mui/material';
import {
	FileDownloadOutlined,
	ErrorOutline,
	HighlightOff,
	TaskAlt,
} from '@mui/icons-material';
import { LightTooltip } from 'components/LightTooltip';

const translatedFileStatus = {
	success: {
		label: 'Sucesso',
		icon: <TaskAlt fontSize="large" />,
	},
	progress: {
		label: 'Progresso',
		icon: <CircularProgress size={24} />,
	},
	fail: {
		label: 'Erro',
		icon: <HighlightOff fontSize="large" />,
	},
	not_loaded: {
		label: 'Não carregado',
		icon: <ErrorOutline fontSize="large" />,
	},
};

export const strategiesUploadColumns = [
	{
		flex: 1,
		field: 'file_name',
		headerName: 'Nome',
		type: 'string',
	},
	{
		flex: 1,
		field: 'load_msg',
		headerName: 'Mensagem',
		type: 'string',
	},
	{
		flex: 1,
		field: 'load_date',
		headerName: 'Data',
		type: 'date',
		renderCell: params =>
			params.row.load_date &&
			params.row.load_date !== '' && (
				<span>
					{moment(params.row.load_date).format('DD/MM/YYYY HH:mm:ss')}
				</span>
			),
	},
	{
		flex: 1,
		field: 'load_status',
		headerName: 'Status',
		type: 'string',
		cellClassName: params =>
			`cell-status cell-status-${params.row.load_status}`,
		renderCell: params => (
			<>
				{translatedFileStatus[params.row.load_status]?.icon}
				{translatedFileStatus[params.row.load_status]?.label}
			</>
		),
	},
	{
		field: 'actions',
		headerName: '',
		sortable: false,
		flex: 0.1,
		renderCell: params => (
			<LightTooltip title="Download da estratégia" arrow>
				<IconButton
					disabled={!params.row.url}
					onClick={() => window.open(params.row.url, '_self')}
				>
					<FileDownloadOutlined sx={{ fontSize: 22 }} />
				</IconButton>
			</LightTooltip>
		),
	},
];
