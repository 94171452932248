export const AUTH_SIGN_IN = 'auth/SIGN_IN';
export const AUTH_SIGN_OUT = 'auth/SIGN_OUT';
export const AUTH_CHANGE_SIGNED_IN_NOW = 'auth/CHANGE_SIGNED_IN_NOW';

export const SET_ACCOUNT_TYPE = 'auth/SET_ACCOUNT_TYPE';
export const SET_LAST_UPDATED = 'auth/SET_LAST_UPDATED';
export const SET_CONTIGENCY = 'auth/SET_CONTIGENCY';
export const SET_VALIDATEDSIGNATURE = 'auth/SET_VALIDATEDSIGNATURE';
export const SET_DISCONNECTED = 'auth/SET_DISCONNECTED';
export const SET_EXECTYPE = 'auth/SET_EXECTYPE';
export const SET_PORTAL_LOGIN = 'auth/SET_PORTAL_LOGIN';
export const SET_USER_BLOCKED = 'auth/SET_USER_BLOCKED';
export const SET_FAVORITE_2FA = 'auth/SET_FAVORITE_2FA';
export const SET_USER_AUTH_METHODS = 'auth/SET_USER_AUTH_METHODS';
