import React, { useState } from 'react';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { store } from 'store';
import { apiAWS } from 'services/api';
import { extractPositionsFromDescriptor } from '../utils';
import { useInterestRate } from '../../../utils/getStateIr';

import { Toolbar } from 'components/Scanner/Toolbar';
import { defaultColumns } from 'components/Scanner/columns';
import { StyledDataGridPro, TableContainer } from 'components/Scanner/styles';

export const TableScanner = searchsFound => {
	const apiRef = useGridApiRef();
	const { ir } = useInterestRate();

	const [dragging, setDragging] = useState(false);

	function setUserColumns(preferences) {
		const { username } = store.getState().auth;

		apiAWS
			.post(`/userpreferences/${username}?service=scanner_searchs`, {
				tablePrefs: preferences,
			})
			.then(() => {
				if (dragging) {
					setDragging(false);
				}
			})
			.catch(error => {
				if (dragging) {
					setDragging(false);
				}

				console.log(error);
			});
	}

	function buildState(isReload = false) {
		if (searchsFound.tablePreferences && !isReload) {
			const newColumns = [];

			searchsFound.tablePreferences.forEach(element => {
				defaultColumns.forEach(item => {
					if (Object.values(element).indexOf(item.field) > -1) {
						defaultColumns[
							defaultColumns.findIndex(
								p => p.field === element.field
							)
						].hide = element.hide;
						newColumns.push({ ...element, ...item });
					}
				});
			});

			return newColumns;
		}

		if (isReload) {
			setUserColumns(defaultColumns);
		}

		return defaultColumns;
	}

	const [columns, setColumns] = useState(buildState());

	function formatTimeStamp(date) {
		let timeStamp = date.split(' ');
		let newDate = timeStamp[0].split('-');
		newDate = `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
		let newTime = timeStamp[1].split(':');
		newTime = `${parseFloat(newTime[0]) - 3}:${newTime[1]}:${
			newTime[2].split('.')[0]
		}`;
		timeStamp = `${newDate} às ${newTime}`;

		return timeStamp;
	}

	function onCellClick(row) {
		let finalArray = {};

		const allColums = apiRef.current.getAllColumns();

		setColumns(allColums);

		searchsFound.setTimeStamp(formatTimeStamp(row.ts));

		const positions = extractPositionsFromDescriptor(
			row.positionsDescriptor,
			row.expirationDate
		);

		finalArray = {
			strategies: [
				{
					underPx: row.underPrice,
					underSymbol: row.underSymbol,
					ir: +ir,
					histVol30: row.histVol,
					histVol90: row.histVol,
					histVol180: row.histVol,
					histVol360: row.histVol,
					positions,
				},
			],
		};

		searchsFound.initChart(finalArray, row.underPrice);
		searchsFound.setStrategyLegs(row.positionsDescriptor);
		searchsFound.setStrategyRowSelected(row.id);
	}

	function onStateChange(event) {
		if (event.columnReorder.dragCol !== '') {
			if (!dragging) {
				setTimeout(() => {
					const allColumns = apiRef.current.getAllColumns();
					setUserColumns(allColumns);
				}, 5000);

				setDragging(true);
			}
		}
	}

	function resetColumns() {
		setColumns(buildState(true));
	}

	return (
		<>
			<TableContainer>
				<StyledDataGridPro
					columns={columns}
					rows={
						searchsFound.searchsFound.length <= 0
							? []
							: searchsFound.searchsFound
					}
					components={{
						Toolbar: () => Toolbar(resetColumns),
					}}
					apiRef={apiRef}
					density="compact"
					disableSelectionOnClick
					hideFooterSelectedRowCount
					className="tableSizerCustom"
					onCellClick={event => onCellClick(event.row)}
					onStateChange={event => onStateChange(event)}
					loading={searchsFound.searchsFound.length <= 0}
				/>
			</TableContainer>
		</>
	);
};
