const povEdit = bullet => {
	const hash = {
		StrategyCode: bullet.content.StrategyCode,
		CustomParameters: {
			PriceLimit: bullet.content.CustomParameters.PriceLimit,
			AgressionLevel: bullet.content.CustomParameters.AgressionLevel,
		},
		StrategyLegs: [
			{
				LegQuantity: bullet.content.StrategyLegs[0].LegQuantity,
				LegMaxClipSize: bullet.content.StrategyLegs[0].LegMaxClipSize,
				LegFirstTimeOut: bullet.content.StrategyLegs[0].LegFirstTimeOut,
				LegSecondTimeOut: bullet.content.StrategyLegs[0].LegSecondTimeOut,
				LegRefID: bullet.content.StrategyLegs[0].LegRefID,
			},
		],
		EndTime: bullet.content.EndTime,
		ClOrdID: bullet.content.ClOrdID,
	};
	if (bullet.content.CustomParameters.TargetPercent) {
		hash.CustomParameters.TargetPercent = +bullet.content.CustomParameters.TargetPercent;
	}
	if (process.env.NODE_ENV === 'development') console.log('pov edit', hash);
	return hash;
};

export default povEdit;
