import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { roundNumber } from 'utils/numberFormat';

export const Cell = memo(function Cell({ accessor, position }) {
	const strategyShowTypeGreeks = useSelector(
		state =>
			state.simulator.strategyList[state.simulator.activeStrategy]
				?.showTypeGreeks
	);

	if (
		isNaN(position[strategyShowTypeGreeks + 'Greeks'][accessor]) ||
		position[strategyShowTypeGreeks + 'Greeks'][accessor] === null
	) {
		return <div>{'N/D'}</div>;
	}

	return (
		<div>
			{roundNumber(
				position[strategyShowTypeGreeks + 'Greeks'][accessor] *
					(position?.qtty || 1)
			) || '0,00'}
		</div>
	);
});
