import styled from 'styled-components';
import { Box, IconButton } from '@mui/material';

export const HeaderToolbar = styled(Box)`
	display: flex;
	justify-content: space-between;

	height: 42px;
	padding-left: 1rem;
	margin-left: 50px;
	box-shadow: 50px 3px 6px #00000033;
	background: ${props => props.theme.colors.bgMenu};
`;

export const HeaderInfo = styled(Box)`
	display: flex;
	align-items: center;
	gap: 1rem;

	> img {
		width: 100px;
	}
`;

export const IconButtonMaterial = styled(IconButton)`
	color: #c4c4c4;
	margin: 0px 16px 0px 0px !important;
	display: ${props => (props.mainMenuState ? 'none' : 'block')} !important;

	.MuiSvgIcon-root {
		font-size: 22px !important;
	}
`;

export const AccountBox = styled(Box)`
	border: ${props =>
		props.accountType === 'demo'
			? '1px solid #f57c00'
			: '1px solid #00ff9d'} !important;
	color: ${props => (props.accountType === 'demo' ? '#f57c00' : '#00ff9d')};
	padding: 0.125rem 0.5rem;
	border-radius: 5px;
`;

export const VersionBox = styled(Box)`
	border: 1px solid #f44336 !important;
	color: #f44336;
`;
