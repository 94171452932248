import styled, { createGlobalStyle } from 'styled-components';

export const BlackBackground = styled.div`
	height: 100%;
	min-height: calc(100vh - 140px);
	margin: 0;
	@media screen and (min-width: 769px) {
		padding: 80px;
		min-height: calc(100vh - 80px);
		background: #000;
	}
`;

export const AppContent = styled.div`
	margin-bottom: 70px;
	@media screen and (min-width: 769px) {
		margin-bottom: 0;
	}
`;

export const DarkBackground = styled.div`
	width: 100%;
	height: 100%;
	@media screen and (min-width: 769px) {
		background: #000;
		color: #fff;
	}
`;

export const VisualContainer = styled.div`
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 15px;
	background: #fff;
`;

export const PageContainer = styled.div`
	width: 470px;
	padding: 30px 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: auto;
	min-height: ${props => (props.autoHeight ? 'auto' : '100%')};
`;

export const FormContainer = styled.form`
	button.marginTop20 {
		margin-top: 20px;
		color: #fff;
	}

	button.marginTop30 {
		margin-top: 30px;
	}
`;

export const ModalFilm = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 2;
`;
export const Modal = styled.div`
	position: relative;
	width: 80vw;
	height: 80vh;
	margin: 10vh auto;
	background-color: #fff;
`;
export const ModalClose = styled.div`
	position: absolute;
	top: 10px;
	right: 20px;
	background-color: #000;
	cursor: pointer;
	color: #fff;
	text-align: center;
	padding: 5px 10px;
	font-size: 20px;
	border-radius: 10px;
	&:hover {
		background-color: #454545;
	}
`;

export const EnvironmentOperating = styled.div`
	color: #fff;
	font-size: ${props => props.theme.font.size};

	.environment {
		display: block;
		width: 100%;
		height: 30px;
		margin-top: 10px;
		font-weight: bold;
		text-align: center;
		padding-top: 5px;

		&.red {
			background-color: #ead917;
		}

		&.green {
			background-color: #388e3c;
		}
	}
`;

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: none;
        box-sizing: border-box;
    }

    html, body, #root {
        height: 100%;
        font-family: Roboto !important;
        font-size: ${props => props.theme.font.size} !important;
        overflow-x: hidden;
    }

    #root {
        display: flex;
    }

    .MuiButton-outlinedSecondary {
        color: #ff490d !important;
        border: 1px solid rgba(245, 0, 87, 0.5);
    }

    .carousel.carousel-slider .initial-slide{
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #FFF;
        padding: 0 50px;
    }

    .control-dots{
        position: relative;
    }

    body {
        background-color: #212121;
    }

    .nav.active {
        color: #ff490d !important;
    }

    @media screen and (min-width: 769px) {
        .mobile{
            display: none !important;
        }
    }

    @media screen and (max-width: 769px) {
        .mobile{
            display: block;
        }

        .carousel.carousel-slider{
            margin-top: 20px !important;
        }
    }

    .button.active {
        background-color: rgba(63, 81, 181, 0.3);
    }

    .blocked svg, .blocked {
        cursor: not-allowed !important;
    }

    .leftSidebar .MuiSvgIcon-root, .leftSidebar .MuiTypography-root{
        color: #E5E5E5;
    }

    .strategy-card-active .MuiPaper-root{
        border: 3px solid #3897f0;
        color: #3897f0;
    }

    .strategy-card-inactive .MuiPaper-root{
        border: 1px solid #A7A7A7;
        color: #A7A7A7;

        &:hover{
            border: 1px solid ${props => props.theme.colors.highlight};
            color: ${props => props.theme.colors.highlight};
        }
    }

    .bullet-checkbox{
        width: fit-content;
        margin: 0 auto;

        padding: 0px !important;

        svg{
            font-size: 26px;
            color: ${props => props.theme.colors.primary};
        }
    }

    /* Let's get this party started */
    ::-webkit-scrollbar {
        width: 8px;
        height: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: grey !important
    }

    .styled-select {
        height: 20px;
        width: 110px;
        overflow: hidden;
        background-size: 8px 8px !important;
        box-sizing: border-box;
        border: 1px solid ${props => props.theme.colors.inputBorder};
    }

    .ReactTable {
      border: 1px solid ${props => props.theme.colors.stroke} !important;
      overflow-x: hidden;
    }

    .ReactTable *{
        text-overflow: ellipsis;
    }

    .ReactTable .rt-tbody .rt-tr-group, .ReactTable .rt-th {
      border-bottom: solid 1px ${props => props.theme.colors.stroke} !important;
    }

    .ReactTable .rt-tbody .rt-td {
      font-family: Roboto;
      border-right: 1px solid ${props => props.theme.colors.stroke} !important;
    }

    .ReactTable .status-cell {
        padding: 0px !important;
    }

    .ReactTable .rt-td.rt-expandable .rt-expander:after{
      border-top: 7px solid ${props => props.theme.colors.primary};
    }

    .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
      font-family: Roboto;
      padding: 5px 5px;
      line-height: normal;
      position: relative;
      border-right: 1px solid ${props => props.theme.colors.stroke} !important;
      transition: box-shadow .3s cubic-bezier(.175,.885,.32,1.275);
      box-shadow: inset 0 0 0 0 transparent;
    }

    .rt-draggable-container .arrow:after {
        border-color: ${props => props.theme.colors.secondary} !important;
    }
    .rt-draggable-container .arrow .arrow:before, .rt-draggable-container .arrow:before {
        background-color: ${props => props.theme.colors.secondary} !important;
    }

    .v-align-center {
        display: flex;
        justify-content: center;
    }

    .v-align-center > div {
        display: flex;
        & > div {
            margin-top: 20px
        }
    }

    .react-autosuggest__suggestions-container--open{
        box-shadow: 3px 0px 6px #00000033 !important;
        background-color: ${props => props.theme.colors.background} !important;
        color: ${props => props.theme.colors.primary} !important;
    }

    .customized-menu .MuiMenu-paper{
        color: ${props => props.theme.colors.primary} !important;
        background: ${props => props.theme.colors.background} !important;
        box-shadow: 3px 0px 6px #00000033 !important;

        .MuiListItem-button:hover {
            text-decoration: none;
            background-color: rgba(112, 112, 112, 0.4) !important;
        }
    }

	.user-menu {
		.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
			@media screen and (min-width: 768px) {
				top: 4.5rem !important;
				right: 1rem !important;
				left: auto !important;
				bottom: auto !important;
			}

			@media screen and (max-width: 768px) {
				top: 4rem !important;
				right: 1rem !important;
				left: auto !important;
				bottom: auto !important;
				border: 1rem solid blue !important;
			}
		}
	}

    .MuiOutlinedInput-root{
        background-color: ${props => props.theme.colors.background} !important;
        box-shadow: 3px 0px 6px #00000033 !important;
        color: ${props => props.theme.colors.primary} !important;
    }

    .SplitPane.bullets-opened .Pane.horizontal.Pane2{
        margin-bottom: 30px;
    }

    .pointer {
        cursor: pointer;
    }

    #react-autowhatever-1{
        z-index: 999999;
    }

	.hide-input-spinner input[type=number]::-webkit-inner-spin-button,
	.hide-input-spinner input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.hide-input-spinner input[type=number]::-moz-number-spinner,
	.hide-input-spinner input[type=number]::-ms-number-spinner {
		display: none;
	}

	.black-date-input input[type="date"]::-webkit-calendar-picker-indicator {
      filter: invert(1);
	  transform: scale(1.5);
    }
`;
