export const ExecutionsActionTypes = {
	UPDATE_STATE_VALUES: 'executions/UPDATE_STATE_VALUES',
	SET_ROWS: 'executions/SET_ROWS',
	UPDATE_FORM_VALUES: 'executions/UPDATE_FORM_VALUES',
	UPDATE_SEARCH_PAGINATE: 'executions/UPDATE_SEARCH_PAGINATE',
	CLEAR_STATE_VALUES: 'executions/CLEAR_STATE_VALUES',
	CLEAR_FORM_VALUES: 'executions/CLEAR_FORM_VALUES',
	CLEAR_SEARCH_PAGINATE: 'executions/CLEAR_SEARCH_PAGINATE',
	UPDATE_PAGINATION_INFO: 'executions/UPDATE_PAGINATION_INFO',
	UPDATE_USER_PREFERENCES: 'executions/UPDATE_USER_PREFENCES',
};
