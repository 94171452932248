import {
	SET_STRATEGIES,
	SET_ACTIVE_STRATEGIES,
	UPDATE_ACTIVE_STRATEGIES,
	UPDATE_SAVED_PARAMETER,
	UPDATE_USER_PREFERENCES,
	DELETE_STRATEGY,
	SET_NEW_STRATEGY_MODAL,
	SET_WALLET_VALUES,
	UPDATE_TAB_ID,
	SET_UNSAVED_MODAL,
	UPDATE_DELETION_DATA,
	UPDATE_TAB_INDEX,
	UPDATE_COLUMNS_SIZE,
	SET_ROWS,
} from './actionTypes';

export const setPortfolioStrategies = strategies => ({
	type: SET_STRATEGIES,
	payload: { strategies },
});

export const setActiveStrategies = activeStrategy => ({
	type: SET_ACTIVE_STRATEGIES,
	payload: { activeStrategy },
});

export const updateActiveStrategies = activeStrategy => ({
	type: UPDATE_ACTIVE_STRATEGIES,
	payload: { activeStrategy },
});

export const updateSavedParameter = strategiesToSave => ({
	type: UPDATE_SAVED_PARAMETER,
	payload: { ...strategiesToSave },
});

export const updateUserPreferences = userPreferences => ({
	type: UPDATE_USER_PREFERENCES,
	payload: { userPreferences },
});

export const deleteStrategy = strategy => ({
	type: DELETE_STRATEGY,
	payload: { strategy },
});

export const setNewStrategyModal = isOpen => ({
	type: SET_NEW_STRATEGY_MODAL,
	payload: { isOpen },
});

export const setUnsavedModal = (isOpen, id) => ({
	type: SET_UNSAVED_MODAL,
	payload: { isOpen, id },
});

export const setWalletValues = walletValues => ({
	type: SET_WALLET_VALUES,
	payload: { walletValues },
});

export const updateTabId = (tabId, temporaryId) => ({
	type: UPDATE_TAB_ID,
	payload: { tabId, temporaryId },
});

export const updateDeletionData = deletionData => ({
	type: UPDATE_DELETION_DATA,
	payload: { deletionData },
});

export const updateTabIndex = tabIndex => ({
	type: UPDATE_TAB_INDEX,
	payload: { tabIndex },
});

export const updateColumnsSize = (field, width) => ({
	type: UPDATE_COLUMNS_SIZE,
	payload: { field, width },
});

export const setRows = rows => ({
	type: SET_ROWS,
	payload: { rows },
});
