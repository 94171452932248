import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(Backend)
	.use(initReactI18next)
	.init({
		lng: 'pt-BR',
		backend: {
			loadPath: '/static/{{ns}}/{{lng}}.json',
		},
		fallbackLng: 'pt-BR',
		ns: ['translations'],
		defaultNS: 'translations',
		returnObjects: true,
		interpolation: {
			escapeValue: false,
		},
		react: {
			wait: true,
			useSuspense: true,
		},
	});

export default i18n;
