import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	TextField,
	Typography,
	DialogTitle,
	DialogActions,
	DialogContent,
	DialogContentText,
	Divider,
} from '@mui/material';

import { apiExec } from 'services/api';
import { getSimulatedStrategies } from 'services/getSimulatedStrategies';
import { getStrategies as getSimulatorStrategiesList } from 'store/modules/simulator/actions';

export const ConfirmImportMigrationDialog = ({
	isOpen,
	userToCopyBy,
	setSnackbarState,
	handleChangeMigrationStep,
}) => {
	const dispatch = useDispatch();
	const inputRef = useRef();

	const username = useSelector(state => state.auth.username);

	const [isLoading, setIsLoading] = useState(false);

	function handleSubmit() {
		if (
			!inputRef.current.value ||
			inputRef.current.value.length === 0 ||
			String(inputRef.current.value).toUpperCase() !== 'MIGRAR'
		) {
			setSnackbarState(state => ({
				...state,
				isOpen: true,
				message: 'Digite corretamente.',
				severity: 'error',
			}));
			return;
		}

		setIsLoading(true);

		const body = {
			command: 'simulator_str_migrate',
			from_user: userToCopyBy,
			to_user: username,
		};

		apiExec
			.post('/commands', body)
			.then(() => {
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					message: 'Estratégias migradas com sucesso.',
					severity: 'success',
				}));

				setTimeout(() => {
					getSimulatedStrategies();
					handleChangeMigrationStep('');
					setIsLoading(false);
					handleChangeMigrationStep('');
					dispatch(getSimulatorStrategiesList());
				}, 2000);
			})
			.catch(error => {
				console.error(error);
				const defaultMessage = `Algo deu errado. Tente novamente mais tarde.
					 [${error.response?.data?.code ?? error.status}]`;

				setSnackbarState(state => ({
					...state,
					isOpen: true,
					message: error.response?.data?.title || defaultMessage,
					severity: 'error',
				}));
				setIsLoading(false);
			});
	}

	function handleClose() {
		handleChangeMigrationStep('import-migration');
	}

	return (
		<>
			<Dialog open={isOpen}>
				<DialogTitle>Migrar estratégias do simulador</DialogTitle>

				<DialogContent
					sx={{
						gap: 1,
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Typography variant="subtitle1">
						Usuário de origem: <strong>{userToCopyBy}</strong>
					</Typography>

					<Typography variant="subtitle1">
						Usuário destino: <strong>{username}</strong>
					</Typography>

					<Divider sx={{ my: 0.5 }} />

					<DialogContentText>
						Digite <strong>MIGRAR</strong> para confirmar
					</DialogContentText>

					<TextField
						inputRef={inputRef}
						size="small"
						placeholder="Migrar"
						sx={{ mt: -0.5, ml: -0.25 }}
					/>
				</DialogContent>

				<DialogActions>
					<Button color="inherit" onClick={handleClose}>
						Cancelar
					</Button>

					<LoadingButton
						color="inherit"
						loading={isLoading}
						onClick={handleSubmit}
					>
						Confirmar
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
};
