function getFormattedNumber(number, minDigits = null, maxDigits = null) {
	if (number === null || number === undefined || isNaN(number)) {
		return '-';
	}

	return new Intl.NumberFormat('pt-BR', {
		minimumFractionDigits: minDigits,
		maximumFractionDigits: maxDigits ?? minDigits,
	}).format(number);
}

export const riskColumns = [
	{
		flex: 1,
		field: 'account',
		headerName: 'Conta',
		type: 'string',
	},
	{
		flex: 1,
		field: 'operCode',
		headerName: 'Operador',
		type: 'string',
	},
	{
		flex: 1,
		field: 'exchange',
		headerName: 'Mercado',
		type: 'string',
	},
	{
		flex: 1,
		field: 'riskType',
		headerName: 'Regra',
		type: 'string',
		renderCell: params =>
			params.value === 1 ? 'Financeiro' : 'Quantidade',
	},
	{
		flex: 1,
		field: 'securityType',
		headerName: 'Tipo Instrumento',
		type: 'string',
	},
	{
		flex: 1,
		field: 'asset',
		headerName: 'Asset.',
		type: 'string',
	},
	{
		flex: 1,
		field: 'symbol',
		headerName: 'Instrumento.',
		type: 'string',
	},
	{
		flex: 1,
		field: 'netBuy',
		headerName: 'Net Compra',
		type: 'number',
		renderCell: params => getFormattedNumber(params.value, 2),
	},
	{
		flex: 1,
		field: 'netSell',
		headerName: 'Net Venda',
		type: 'number',
		renderCell: params => getFormattedNumber(params.value, 2),
	},
	{
		flex: 1,
		field: 'gross',
		headerName: 'Gross',
		type: 'number',
		renderCell: params =>
			params.value === -1 ? '-' : getFormattedNumber(params.value, 2),
	},
	{
		flex: 1,
		field: 'net',
		headerName: 'Net Aberto',
		type: 'number',
		renderCell: params => getFormattedNumber(params.value, 2),
	},
	{
		flex: 1,
		field: 'netClosed',
		headerName: 'Net Executado',
		type: 'number',
		renderCell: params => getFormattedNumber(params.value, 2),
	},
	{
		flex: 1,
		field: 'grossExec',
		headerName: 'Execução Gross',
		type: 'number',
		renderCell: params => getFormattedNumber(params.value, 2),
	},
];
