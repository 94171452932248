import { CacheActionsType } from './types';

export const setOptions = (symbol, data) => ({
	type: CacheActionsType.SET_OPTIONS,
	payload: { symbol, data },
});

export const setPaper = (symbol, data) => ({
	type: CacheActionsType.SET_PAPER,
	payload: { symbol, data },
});
