import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import SelectInput from '../SelectInput';
import DecimalInput from '../DecimalInput';
import {
	getDecimalPlacesByTrigger,
	getStepByTrigger,
} from '../../../utils/strategies';
import { useTranslation } from 'react-i18next';
import { isDisableBySymbol } from '../../../utils/isDisableBySymbol';
import { SpreadMarketPrice } from '../../MarketPrice/SpreadMarketPrice';

const LSexecutionParams = ({
	handleChange,
	stepFunction,
	marketPrice,
	execType,
}) => {
	const { bullet } = useSelector(state => state.bottomBullet);
	const [gainCr, setGainCr] = useState(true);
	const [lossCr, setLossCr] = useState(true);
	const [stopGain, setStopGain] = useState(null);
	const [stopLoss, setStopLoss] = useState(null);
	const stopSelected = 'gain';
	const { t } = useTranslation();

	if (bullet.editing || bullet.clone) {
		const newGain = bullet.content.CustomParameters.TriggerValue ?? 0;
		if (stopGain !== Math.abs(newGain)) {
			setGainCr(newGain >= 0);

			setStopGain(Math.abs(newGain));
		}

		const lossEnabled =
			bullet.content.CustomParameters.ToMarketTriggerValueEnabled === 'Y';

		if (lossEnabled) {
			const newLoss =
				bullet.content.CustomParameters.ToMarketTriggerValue ?? 0;
			if (stopLoss !== Math.abs(newLoss)) {
				setLossCr(newLoss >= 0);

				setStopLoss(Math.abs(newLoss));
			}
		}

		// workaround limpeza de boleta
		if (bullet.content.StrategyLegs[0].LegSymbol === '') {
			marketPrice = {};
		}
	}

	const getStopValue = (value, isCr) => {
		const tempValue = Math.abs(parseFloat(value));
		const valueWithoutSignal = isNaN(tempValue) ? null : tempValue;

		if (valueWithoutSignal !== null) {
			return isCr ? valueWithoutSignal : -1 * valueWithoutSignal;
		}

		return null;
	};

	const handleStopChanges = (
		value,
		customParam,
		isCr,
		refSetStopFunction
	) => {
		const tempValue = getStopValue(value, isCr);
		handleChange('Custom', customParam, tempValue);
		refSetStopFunction(Math.abs(tempValue));
	};

	const callbackHandleCreditDebit = isCredit => {
		setGainCr(isCredit);
		const tempGainValue = getStopValue(stopGain, isCredit);
		handleChange('Custom', 'TriggerValue', tempGainValue);
	};

	const callbackHandleCreditDebitLoss = isCredit => {
		setLossCr(isCredit);
		const tempLossValue = getStopValue(stopLoss, isCredit);
		handleChange('Custom', 'ToMarketTriggerValue', tempLossValue);
	};

	const getTriggerLabel = trigger => {
		switch (trigger) {
			case 2:
				return 'Ratio C/V Mercado';
			case 3:
				return 'Ratio V/C Mercado';
			case 4:
				return 'Dif. Preço C-V Mercado';
			case 5:
				return 'Dif. Preço V-C Mercado';
			case 11:
				return 'Dif. Financeiro Mercado';
			default:
				return 'Valor de Mercado';
		}
	};

	const handleChangeTriggerInterceptor = (type, field, value) => {
		handleChange(type, field, value);
		handleChange('Custom', 'TriggerValue', null);
		if (
			bullet.content.CustomParameters.ToMarketTriggerValueEnabled === 'Y'
		) {
			handleChange('Custom', 'ToMarketTriggerValue', 0.0);
		}
	};

	const isDisabledBySymbol = isDisableBySymbol(bullet, 1);

	const getTriggerOptions = (quantityMode) => {
		const triggerOptions = [
			{
				value: 2,
				label: 'Ratio C/V',
			},
			{
				value: 3,
				label: 'Ratio V/C',
			},
			{
				value: 4,
				label: 'Dif. Preço C-V',
			},
			{
				value: 5,
				label: 'Dif. Preço V-C',
			},
			{
				value: 11,
				label: 'Dif. Financeiro',
			},
		];

		if (quantityMode === 1) {
			delete triggerOptions[4];
		};

		return triggerOptions;
	};

	return (
		<Grid item xs={execType === 'B' ? 8 : 6}>
			<Grid container spacing={1} direction="row">
				<Grid item xs={6} style={{ marginLeft: '3px' }}>
					<Typography variant="subtitle1">
						Parâmetros de execução
					</Typography>
				</Grid>

				<Grid
					container
					item
					xs={12}
					spacing={1}
					style={{ marginBottom: '-8px' }}
				>
					<Grid item xs={3}>
						<SelectInput
							disabled={
								isDisabledBySymbol || bullet.content.ClOrdID
							}
							name="executionType"
							label="Modo"
							iconTooltip={t(
								'bullets.long_and_short.execution_type'
							)}
							defaultValue={0}
							onChange={e => {
								handleChange(
									'Custom',
									'ExecutionType',
									+e.target.value
								);
								handleChange(
									'Custom',
									'ToMarketTriggerValueEnabled',
									+e.target.value === 0 ? 'N' : 'Y'
								);
							}}
							value={
								parseInt(
									bullet.content.CustomParameters
										.ExecutionType,
									10
								) ?? 0
							}
							selectOptions={[
								{
									value: 0,
									label: 'Entrada',
								},
								{
									value: 1,
									label: 'Gain/Loss',
								},
							]}
						/>
					</Grid>
					<Grid item xs={3}>
						<SelectInput
							disabled={
								isDisabledBySymbol || bullet.content.ClOrdID
							}
							name="executionTrigger"
							label="Gatilho"
							iconTooltip={t('bullets.long_and_short.trigger')}
							defaultValue={2}
							onChange={e =>
								handleChangeTriggerInterceptor(
									'Custom',
									'Trigger',
									parseInt(e.target.value, 10)
								)
							}
							value={
								parseInt(
									bullet.content.CustomParameters.Trigger,
									10
								) ?? 2
							}
							selectOptions={getTriggerOptions(bullet.content.CustomParameters.QuantityMode)}
						/>
					</Grid>
					<Grid item xs={3}>
						<DecimalInput
							name="triggerValue"
							disabled={isDisabledBySymbol}
							label={
								parseInt(
									bullet.content.CustomParameters
										.ExecutionType,
									10
								) !== 0
									? 'Gain'
									: 'Entrada'
							}
							tooltip={
								parseInt(
									bullet.content.CustomParameters
										.ExecutionType,
									10
								) !== 0
									? t('bullets.general.stop_gain')
									: t('bullets.general.trigger_value')
							}
							allowNegatives={false}
							minVal={-999999.99}
							maxVal={999999.99}
							minDecScale={getDecimalPlacesByTrigger(
								bullet.content.CustomParameters.Trigger
							)} // casas decimais
							stepFunction={stepFunction}
							stepType="Custom" // type
							stepSubType="TriggerValue" // subtype
							stepSize={getStepByTrigger(
								bullet.content.CustomParameters.Trigger
							)} // incremento
							valueToStep={
								bullet.content.CustomParameters.TriggerValue
							} // valor a ser modificado
							value={bullet.content.CustomParameters.TriggerValue}
							onChange={e =>
								handleStopChanges(
									e.value,
									'TriggerValue',
									gainCr,
									setStopGain
								)
							}
							isFinantial={gainCr}
							finantialClick={callbackHandleCreditDebit}
						/>

						{parseInt(
							bullet.content.CustomParameters.ExecutionType,
							10
						) === 1 && (
							<Grid item xs style={{ marginTop: '15px', marginBottom: '10px' }}>
								<DecimalInput
									name="lossValue"
									tooltip={t('bullets.general.stop_loss')}
									disabled={isDisabledBySymbol}
									label="Loss"
									allowNegatives={false}
									minVal={-999999.99}
									maxVal={999999.99}
									minDecScale={getDecimalPlacesByTrigger(
										bullet.content.CustomParameters.Trigger
									)} // casas decimais
									stepFunction={stepFunction}
									stepType="Custom" // type
									stepSubType="ToMarketTriggerValue" // subtype
									stepSize={getStepByTrigger(
										bullet.content.CustomParameters.Trigger
									)} // incremento
									valueToStep={
										bullet.content.CustomParameters
											.ToMarketTriggerValue
									} // valor a ser modificado
									value={
										bullet.content.CustomParameters
											.ToMarketTriggerValue ?? 0
									}
									onChange={e =>
										handleStopChanges(
											e.value,
											'ToMarketTriggerValue',
											lossCr,
											setStopLoss,
											setLossCr
										)
									}
									isFinantial={lossCr}
									finantialClick={
										callbackHandleCreditDebitLoss
									}
								/>
							</Grid>
						)}
					</Grid>

					<Grid item xs={3} style={{ marginTop: '-24px' }}>
						<SpreadMarketPrice
							title={getTriggerLabel(
								bullet.content.CustomParameters.Trigger
							)}
							disabled={isDisabledBySymbol}
							setGainCr={setGainCr}
							setLossCr={setLossCr}
							bulletType={'lgshort'}
							setStopGain={setStopGain}
							setStopLoss={setStopLoss}
							marketPrice={marketPrice}
							handleChange={handleChange}
							stopSelected={stopSelected}
						/>
					</Grid>
				</Grid>
				{execType !== 'B' && (
					<Grid container item xs={12} spacing={1}>
						<Grid item xs={3}>
							<SelectInput
								disabled={isDisabledBySymbol}
								name="bookDepth"
								label="Profundidade do Book"
								iconTooltip={t('bullets.general.book_depth')}
								defaultValue={1}
								value={
									bullet.content.CustomParameters.BookDepth ??
									1
								}
								selectOptions={[
									{
										value: 1,
										label: '1º Nível',
									},
									{
										value: 2,
										label: '2º Nível',
									},
									{
										value: 3,
										label: '3º Nível',
									},
								]}
								onChange={e =>
									handleChange(
										'Custom',
										'BookDepth',
										+e.target.value
									)
								}
							/>
						</Grid>
						<Grid item xs={3}>
							<SelectInput
								disabled={
									isDisabledBySymbol || bullet.content.ClOrdID
								}
								name="compensateExec"
								label="Compensação"
								iconTooltip={t(
									'bullets.long_and_short.compensation'
								)}
								defaultValue={2}
								value={
									bullet.content.CustomParameters
										.CompensateExec ?? 2
								}
								selectOptions={[
									{
										value: 0,
										label: 'Nenhum',
									},
									{
										value: 1,
										label: 'Clip Seguinte',
									},
									{
										value: 2,
										label: 'Gradual',
									},
								]}
								onChange={e =>
									handleChange(
										'Custom',
										'CompensateExec',
										+e.target.value
									)
								}
							/>
						</Grid>
						<Grid item xs={3}>
							<DecimalInput
								disabled={isDisabledBySymbol}
								name="ignoreOffer"
								label="Ignorar múltiplo de lote"
								iconTooltip={t(
									'bullets.long_and_short.ignore_offers_lt'
								)}
								minVal={0}
								maxVal={10}
								minDecScale={0} // casas decimais
								stepFunction={stepFunction}
								stepType="Custom" // type
								stepSubType="IgnoreOffersLT" // subtype
								stepSize={1} // incremento
								valueToStep={
									bullet.content.CustomParameters
										.IgnoreOffersLT
								} // valor a ser modificado
								value={
									bullet.content.CustomParameters
										.IgnoreOffersLT
								}
								onChange={e =>
									handleChange(
										'Custom',
										'IgnoreOffersLT',
										parseInt(e.value)
									)
								}
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default LSexecutionParams;
