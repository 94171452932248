import NEW_ACTION, {
	STRATEGY_ADD,
	STRATEGY_REMOVE,
	VISIBILITY_SET,
	STRATEGY_NAME,
	STRATEGY_UPDATE,
	STRATEGY_INITIAL,
} from './actionTypes';

export const strategyAdd = strategy => ({
	type: STRATEGY_ADD,
	payload: { strategy },
});

export const strategyRemove = strategy => ({
	type: STRATEGY_REMOVE,
	payload: { strategy },
});

export const handleNameStrategy = (id, value) => ({
	type: STRATEGY_NAME,
	payload: { id, value },
});

export const setStrategyVisibility = (visibility, id) => ({
	type: VISIBILITY_SET,
	payload: { visibility, id },
});

export const strategyUpdate = (id, content) => ({
	type: STRATEGY_UPDATE,
	payload: { id, content },
});

export const strategyInitial = id => ({
	type: STRATEGY_INITIAL,
	payload: { id },
});

export const strategyUpdateAction = strategy => ({
	type: NEW_ACTION.NEW_STRATEGY_UPDATE,
	payload: strategy,
});
