import React from 'react';
import { useDispatch } from 'react-redux';

import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Typography
} from '@mui/material';

import { setPartialGlobalSettings } from 'store/modules/panelConfig/actions';
import { store } from 'store';
import SelectInput from 'components/MaterialComponents/SelectInput';

export const BulletSettings = () => {
	const dispatch = useDispatch();

	const accounts = store.getState().auth.accounts;

	const execTypeInitialValue = store.getState().configs.globalSettings?.userSettings?.bullet.execType;

	const defaultAccountValue = store.getState().configs.globalSettings?.userSettings?.bullet.defaultAccount;

	function handleChangeBulletSettings(newExecType, newAccount) {
		if (newExecType) {
			dispatch(setPartialGlobalSettings('bullet', { execType: newExecType }));
		}
		if (newAccount) {
			dispatch(setPartialGlobalSettings('bullet', { defaultAccount: newAccount }));
		}
	}

	const seenCodes = new Set();

	const options = accounts.filter(accountItem => {
		if (seenCodes.has(accountItem.code)) {
			return false;
		}

		seenCodes.add(accountItem.code);
		return true;
	});

	return (
		<>
			<FormControl>
				<FormLabel
					sx={{
						color: '#ffffff',
						'&.Mui-focused': {
							color: '#ffffff'
						}
					}}
				>
					<Typography variant="h6">
						Tipo de boleta de Spread
					</Typography>
				</FormLabel>

				<Typography variant="body1">
					Habilitar/desabilitar a opção de boleta básica ou avançada para as estratégias de Spread e Long&Short.
				</Typography>

				<RadioGroup
					defaultValue={execTypeInitialValue}
					sx={{ '& label': { mb: 0 } }}
				>
					<FormControlLabel
						value="B"
						control={<Radio sx={{ color: '#d1d1d1' }} />}
						label="Básica"
						onChange={(event) => handleChangeBulletSettings(event.target.value, '')}
					/>

					<FormControlLabel
						value="A"
						control={<Radio sx={{ color: '#d1d1d1' }} />}
						label="Avançada"
						onChange={(event) => handleChangeBulletSettings(event.target.value, '')}
					/>
				</RadioGroup>

				<FormLabel
					sx={{
						color: '#ffffff',
						'&.Mui-focused': {
							color: '#ffffff'
						}
					}}
				>
					<Typography variant="h6">
						Definir conta padrão nas boletas
					</Typography>
				</FormLabel>

				<Typography variant="body1">
					Caso você tenha mais de uma conta vinculada ao seu usuário, é possível definir uma conta padrão que sempre virá selecionada na abertura de novas boletas.
				</Typography>

				<SelectInput
					name="DefaultAccount"
					label="Conta padrão"
					style={{ marginTop: '20px', width: '50%' }}
					defaultValue={defaultAccountValue ?? 'Nenhuma'}
					onChange={(event) => handleChangeBulletSettings('', event.target.value)}
					selectOptions={[
						{
							value: 'Nenhuma',
							label: 'Nenhuma',
						},
						...options.map((account) => ({
							value: account.code,
							label: account.code,
						}))
					]}
				/>
			</FormControl>
		</>
	);
};
