import { toast } from 'react-toastify';
import validatePrice from '../submitFunctions/validatePrice';

const bofferEdit = (bullet, t) => {
	if (validatePrice(bullet.content.CustomParameters.PriceLimit)) {
		toast.error(t('O Preço limite precisa ser maior que 0'));
		return;
	}
	const hash = {
		StrategyCode: bullet.content.StrategyCode,
		CustomParameters: {
			PriceLimit: +bullet.content.CustomParameters.PriceLimit,
		},
		EndTime: bullet.content.EndTime,
		StrategyLegs: [
			{
				LegQuantity: bullet.content.StrategyLegs[0].LegQuantity,
				LegRefID: bullet.content.StrategyLegs[0].LegRefID,
				LegMaxClipSize: bullet.content.StrategyLegs[0].LegMaxClipSize,
			},
		],
		ClOrdID: bullet.content.ClOrdID,
	};
	return hash;
};

export default bofferEdit;
