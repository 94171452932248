import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Button, colors, Typography } from '@mui/material';

const PriceBox = ({ label, value, color, ...rest }) => {
	return (
		<Button
			color="inherit"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				flex: 1,
				minWidth: 0,
			}}
			{...rest}
		>
			<Typography sx={{ fontSize: '0.9rem' }}>{label}</Typography>
			<Typography sx={{ fontSize: '1.125rem' }} color={color}>
				{value}
			</Typography>
		</Button>
	);
};

export const DepletionMarketPrice = ({ setEntrancePrice }) => {
	const bidValue = useSelector(
		state => state.bottomBullet.bullet.StrategyInfos[0].bidPx
	);
	const askValue = useSelector(
		state => state.bottomBullet.bullet.StrategyInfos[0].askPx
	);
	const securityDesc = useSelector(
		state => state.bottomBullet.bullet.StrategyInfos[0].securityDesc
	);
	const tickSizeDenominator =
		useSelector(
			state =>
				state.bottomBullet.bullet.StrategyInfos[0].tickSizeDenominator
		) ?? 2;

	function formatMarketPrice(value) {
		if (value) {
			return new Intl.NumberFormat('de-DE', {
				minimumFractionDigits: tickSizeDenominator,
				maximumFractionDigits: tickSizeDenominator,
			}).format(value);
		}

		return '-';
	}

	const bidFormattedValue = formatMarketPrice(bidValue);
	const askFormattedValue = formatMarketPrice(askValue);

	return (
		<Box sx={{ width: '100%', mt: -2 }}>
			<Typography
				sx={{
					color: securityDesc ? colors.grey[400] : colors.grey[900],
				}}
				variant="subtitle2"
				textAlign="center"
			>
				{securityDesc ?? '.'}
			</Typography>

			<Box
				sx={{
					display: 'flex',
					justifyItems: 'center',
					gap: 1,
					mt: 0.5,
					width: '100%',
				}}
			>
				<PriceBox
					size="small"
					color="primary"
					label="BID"
					value={bidFormattedValue}
					onClick={() => setEntrancePrice('bidPx')}
				/>

				<PriceBox
					size="small"
					color="error"
					label="ASK"
					value={askFormattedValue}
					onClick={() => setEntrancePrice('askPx')}
				/>
			</Box>
		</Box>
	);
};
