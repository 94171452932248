import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { apiExec } from 'services/api';

export const ActionConfirmDialog = ({
	apiRef,
	onClose,
	actionType,
	setSnackbarState,
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const actionTypeData = {
		suspend: {
			label: 'Suspender',
			status: [NaN, 0, 5, 6, 7, 9, 10],
			code: 1,
			excludedStrategies: ['grdlin', 'grddin', 'sorder', 'fxorder'],
		},
		cancel: {
			label: 'Cancelar',
			status: [NaN, 0, 5, 6, 7],
			code: 2,
		},
		restart: {
			label: 'Reiniciar',
			status: [NaN, 0, 1, 2, 3, 4, 5, 6, 7, 10, 99],
			code: 3,
		},
	};

	function shouldExcludeStrategy(actionType, strategyCode) {
		return actionType === 'suspend' && actionTypeData[actionType].excludedStrategies.includes(strategyCode);
	}

	function handleChangeStatus() {
		const { status, code } = actionTypeData[actionType];

		const rows =
			apiRef.current.getAllRowIds()
				.map(id => apiRef.current.getRow(id))
				.filter(row => row && row.hierarchy.length === 1);

		const ids = rows
			.filter(execution =>
				!(status.includes(parseInt(execution.OrdStatus) ?? parseInt(execution.Status))) &&
				!shouldExcludeStrategy(actionType, execution.StrategyCode)
			).map(execution => execution.ClOrdID);

		if (ids.length === 0) {
			setSnackbarState({
				isOpen: true,
				message: 'Não há estratégias para executar essa ação.',
				severity: 'error',
			});
			return;
		}

		setIsLoading(true);

		const body = {
			action: code,
			target_type: 'clordid',
			target_ids: ids,
		};

		apiExec
			.post('/algos/commands', body)
			.then(response => {
				const message = `
					${response.data.records_processed.length} posições processadas.
					${response.data.total_erros ? `${response.data.total_processed} com sucesso. ${response.data.total_erros} com erro` : ''}
				`;

				setSnackbarState({
					isOpen: true,
					message: message,
					severity: response.data.total_erros ? 'error' : 'success',
				});
			})
			.catch(error => {
				setSnackbarState({
					isOpen: true,
					message: `${error.message}`,
					severity: 'error',
				});
			})
			.finally(() => setIsLoading(false));
	}

	const modalType = actionType && actionType !== '' ? actionTypeData[actionType].label : '';

	return (
		<>
			<Dialog open={actionType && actionType !== ''}>
				<DialogTitle>
					{modalType} estratégias
				</DialogTitle>

				<DialogContent>
					Você gostaria de <strong>{modalType.toLowerCase()}</strong> suas estratégias disponíveis?
				</DialogContent>

				<DialogActions>
					<Button onClick={onClose}>
						Cancelar
					</Button>

					<LoadingButton
						loading={isLoading}
						onClick={handleChangeStatus}
					>
						Confirmar
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
};
