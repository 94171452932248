import React from 'react';
import { useDispatch } from 'react-redux';

import { Tabs, Typography } from '@mui/material';
import { Add, Close, QueryStats } from '@mui/icons-material';

import { StyledTab } from './styles';

import { setNewStrategyModal, setUnsavedModal } from 'store/modules/portfolio/actions';

import { UnsavedModal } from '../UnsavedModal';
import { NewStrategyModal } from '../NewStrategyModal';
import { LightTooltip } from 'components/LightTooltip';

export const TabsHeader = ({
	closeTab,
	tabIndex,
	setTabIndex,
	handleClose,
	activeStrategies,
	handleSelectStrategyTab
}) => {
	const dispatch = useDispatch();

	function a11yProps(index) {
		return {
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	function handleChange(event, newIndex) {
		if (newIndex === 1) {
			event.stopPropagation();
			dispatch(setNewStrategyModal(true));
		} else if (newIndex === 0) {
			setTabIndex(newIndex);
		} else {
			handleSelectStrategyTab(event.target.id);
			setTabIndex(newIndex);
		}
	}

	return (
		<>
			<Tabs
				value={tabIndex}
				onChange={handleChange}
				aria-label="scrollable auto tabs example"
				variant="scrollable"
				scrollButtons="auto"
			>
				<StyledTab
					label={
						<>
							<span>Estratégias</span>
						</>
					}
					isInactive={tabIndex !== 0}
					icon={<QueryStats fontSize='large' />}
					iconPosition="start"
					{...a11yProps(0)}
				/>

				<StyledTab
					label={
						<LightTooltip title="Criar nova estratégia de simulação">
							<Typography variant="body1" fontWeight="bold">
								Criar
							</Typography>
						</LightTooltip>
					}
					{...a11yProps(1)}
					icon={<Add />}
					iconPosition='start'
					isInactive={tabIndex !== 1}
					name="create-button"
				/>

				{activeStrategies?.map((strategy, index) => (
					<StyledTab
						iconPosition="end"
						key={strategy.id}
						id={strategy.id}
						label={
							<span
								id={strategy.id}
								className={!strategy.saved && 'styled-tab-label'}
							>
								{strategy.name}
							</span>
						}
						icon={
							<Close
								className="close-tab-button"
								onClick={(event) => handleClose(event, tabIndex, strategy.id)}
							/>
						}
						{...a11yProps(index + 2)}
					/>
				))}
			</Tabs>

			<NewStrategyModal
				setTabIndex={setTabIndex}
				activeStrategies={activeStrategies}
				onClose={() => dispatch(setNewStrategyModal(false))}
			/>

			<UnsavedModal
				tabIndex={tabIndex}
				closeTab={closeTab}
				activeStrategies={activeStrategies}
				onClose={() => dispatch(setUnsavedModal(false))}
			/>
		</>
	);
};
