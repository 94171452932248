import React, { memo } from 'react';
import NumberFormat from 'react-number-format';

import { Box, TextField, colors } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

import {
	DecimalDown,
	DecimalUp,
} from '../../../components/MaterialComponents/MaterialStyles/style';

const NumberFormatCustom = ({
	inputRef,
	onChange,
	minDecScale,
	value,
	disabled,
	valueToStep,
	stepSize,
	width,
	isManualMode,
	...other
}) => {
	const manualModeStyles = {
		color: isManualMode ? '#FFF' : colors.orange[500],
		backgroundColor: isManualMode
			? `${colors.orange[600]}a1`
			: 'transparent',
	};

	return (
		<>
			<NumberFormat
				{...other}
				style={{
					width: width || '100%',
					textAlign: 'right',
					padding: '0.375rem 0.75rem',
					borderTopLeftRadius: 3,
					borderBottomLeftRadius: 3,
					WebkitTextFillColor: disabled && colors.orange[500],
					...manualModeStyles,
				}}
				getInputRef={inputRef}
				value={value}
				onValueChange={values => onChange({ value: values.value })}
				onChange={() => onChange({ type: 'change' })}
				fullWidth
				thousandSeparator="."
				decimalSeparator=","
				isNumericString
				fixedDecimalScale
				allowLeadingZeros
				allowNegative={false}
				decimalScale={minDecScale}
				disabled={disabled}
			/>

			<Box display="grid">
				<DecimalUp
					onClick={() =>
						onChange({
							type: 'change',
							value: parseFloat(valueToStep) + stepSize,
						})
					}
					size="small"
					variant="contained"
					disabled={disabled}
				>
					<ArrowDropUp style={{ fontSize: '11px' }} />
				</DecimalUp>

				<DecimalDown
					onClick={() =>
						onChange({
							type: 'change',
							value: parseFloat(valueToStep) - stepSize,
						})
					}
					size="small"
					variant="contained"
					tabIndex={disabled === true ? -1 : -1}
					disabled={disabled}
				>
					<ArrowDropDown style={{ fontSize: '11px' }} />
				</DecimalDown>
			</Box>
		</>
	);
};

export const SimulatorDecimalInput = memo(function SimulatorDecimalInput({
	value,
	label = null,
	onChange,
	disabled,
	maxVal,
	minVal,
	minDecScale, // casas decimais
	stepSize, // incremento
	width,
	onBlur,
	isManualMode,
}) {
	return (
		<>
			<TextField
				size="small"
				label={label}
				value={value}
				disabled={disabled}
				onChange={onChange}
				InputLabelProps={{ shrink: !!label }}
				InputProps={{
					inputComponent: NumberFormatCustom,
					sx: {
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: '#6d6d6d50', // Altere para a cor desejada
						},
						'&:hover .MuiOutlinedInput-notchedOutline': {
							borderColor: '#6d6d6d', // Altere para a cor desejada ao passar o mouse
						},
						'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
							borderColor: '#6d6d6d', // Altere para a cor desejada ao focar
						},
						'&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
							borderColor: '#6d6d6d50', // Altere para a cor desejada quando desabilitado
						},
					},
				}}
				inputProps={{
					maxVal,
					minVal,
					minDecScale,
					disabled,
					stepSize,
					valueToStep: value !== '-' ? value : 0,
					width,
					onBlur,
					isManualMode,
				}}
			/>
		</>
	);
});
