import {
	availablePositionsSaveSimulator,
	createStrategySaveSimulator,
} from 'utils/strategies';
import { parseStrategy } from 'utils/handleSaveStrategy';
import { moveToSimulator } from 'utils/moveToSimulator';

import { apiMarketData } from './api';

export async function saveStrategyInSimulator(row, username, account, history) {
	const legsExecuted = row.Legs.filter(leg => leg.LegExecQty > 0);

	if (legsExecuted.length > 0) {
		const rowSelected = {
			...row,
			Legs: legsExecuted,
		};

		const symbolsLegs = rowSelected.Legs.map(leg => leg.LegSymbol);

		const symbolsRequest = `symbol=${symbolsLegs.join(',')}`;

		try {
			const { data } = await apiMarketData.get(
				`/exchanges/BVMF/instruments?${symbolsRequest}&detailed=1`
			);

			const recordsInfoSymbols = data.records;

			let strategyToSave = createStrategySaveSimulator(
				username,
				account,
				recordsInfoSymbols,
				rowSelected
			);

			let paperInfo = {
				subExchange: 'XBSP',
			};

			if (availablePositionsSaveSimulator(strategyToSave)) {
				const underSymbol =
					strategyToSave.positions[0].underlyingSymbol !== ''
						? strategyToSave.positions[0].underlyingSymbol
						: strategyToSave.positions[0].symbol;

				const { data: dataUnderSymbol } = await apiMarketData.get(
					`/exchanges/BVMF/instruments/${underSymbol}?detailed=1`
				);

				paperInfo = {
					...paperInfo,
					underSymbol,
					underPx: strategyToSave.underPx,
					histVol30: dataUnderSymbol.histVol30,
					histVol90: dataUnderSymbol.histVol90,
					histVol180: dataUnderSymbol.histVol180,
					histVol360: dataUnderSymbol.histVol360,
				};

				strategyToSave = {
					...strategyToSave,
					...paperInfo,
				};

				const strategyParsed = parseStrategy(strategyToSave);

				moveToSimulator(history, strategyParsed);
			}
		} catch (error) {
			console.log(error);
		}
	}
}
