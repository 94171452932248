import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const FullGrid = styled(Grid)`
	display: flex !important;
	justify-content: space-between !important;
`;

export const StyledGrid = styled(Grid)`
	background-color: #303030 !important;
	margin: 16px 0 0 0 !important;
	border-radius: 4px;
	box-shadow: 0 3px 6px #00000030;
`;

export const CheckboxGrid = styled(Grid)`
	background-color: #303030 !important;
	margin: 16px 0 0 0 !important;
	border-radius: 4px;
	box-shadow: 0 3px 6px #00000030;
    max-width: 250px !important;
`;

export const RangeGrid = styled(Grid)`
	background-color: #303030 !important;
	margin: 24px 0 8px 8px !important;
	border-radius: 4px;
	box-shadow: 0 3px 6px #00000030;
`;

export const TittleGrid = styled(Grid)`
	padding: 0px !important;
`;

export const TittleTypo = styled(Typography)`
    margin-top: -18px !important;
`;

export const StrategyInit = styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 3px !important;
`;

export const PriceTittle = styled(Typography)`
	@media screen and (min-width: 1800px) {
		align-self: center;
	};
`;
