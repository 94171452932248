import {
	SET_STRATEGIES,
	SET_ACTIVE_STRATEGIES,
	UPDATE_ACTIVE_STRATEGIES,
	UPDATE_SAVED_PARAMETER,
	UPDATE_USER_PREFERENCES,
	DELETE_STRATEGY,
	SET_NEW_STRATEGY_MODAL,
	SET_WALLET_VALUES,
	UPDATE_TAB_ID,
	SET_UNSAVED_MODAL,
	UPDATE_DELETION_DATA,
	UPDATE_TAB_INDEX,
	UPDATE_COLUMNS_SIZE,
	SET_ROWS,
} from './actionTypes';

const initialState = {
	tabIndex: 0,
	strategies: [],
	activeStrategies: [],
	newStrategyModal: false,
	unsavedModal: false,
	alertId: null,
	deletionData: {},
	rows: [],
	walletValues: {
		walletPl: 0,
		walletTheta: 0,
		walletMargin: 0,
	},
	preferences: {
		rowsExpanded: true,
		density: 'standard',
		filter: {
			filterModel: {
				items: [],
				linkOperator: 'and',
				quickFilterValues: [],
				quickFilterLogicOperator: 'and',
			},
		},
		columns: {
			columnVisibilityModel: {
				userName: false,
				account: false,
				underSymbol: false,
				optionClass: false,
				expired: false,
				createdAt: false,
				updatedAt: false,
				details: false,
				optionType: false,
				gamma: false,
				vega: false,
				deltaUnitary: false,
				gammaUnitary: false,
				thetaUnitary: false,
				vegaUnitary: false,
				approximateMargin: false,
			},
			orderedFields: [
				'__tree_data_group__',
				'name',
				'account',
				'underSymbol',
				'qtty',
				'paper',
				'entryPx',
				'exitPx',
				'strikePx',
				'expirationDate',
				'optionClass',
				'expired',
				'createdAt',
				'updatedAt',
				'pl',
				'cost',
				'pop',
				'delta',
				'gamma',
				'theta',
				'vega',
				'deltaUnitary',
				'gammaUnitary',
				'thetaUnitary',
				'vegaUnitary',
				'approximateMargin',
			],
			columnsSize: [],
		},
		sorting: {
			sortModel: [
				{
					field: 'createdAt',
					sort: 'desc',
				},
			],
		},
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_STRATEGIES:
			return {
				...state,
				strategies: [...payload.strategies],
			};
		case SET_ACTIVE_STRATEGIES:
			return {
				...state,
				activeStrategies: [...payload.activeStrategy],
			};
		case UPDATE_ACTIVE_STRATEGIES:
			return {
				...state,
				activeStrategies: [
					...state.activeStrategies,
					...payload.activeStrategy,
				],
			};
		case UPDATE_SAVED_PARAMETER:
			return {
				...state,
				strategies: state.strategies.map(strategy => {
					if (strategy.id === payload.id) {
						return {
							...strategy,
							saved: payload.saved,
						};
					} else {
						return { ...strategy };
					}
				}),
				activeStrategies: state.activeStrategies.map(strategy => {
					if (strategy.id === payload.id) {
						return {
							...strategy,
							saved: payload.saved,
						};
					} else {
						return { ...strategy };
					}
				}),
			};
		case UPDATE_USER_PREFERENCES:
			return {
				...state,
				preferences: {
					...state.preferences,
					...payload.userPreferences,
				},
			};
		case DELETE_STRATEGY:
			return {
				...state,
				strategies: state.strategies.filter(
					strategy => strategy.id !== payload.strategy.id
				),
			};
		case SET_NEW_STRATEGY_MODAL:
			return {
				...state,
				newStrategyModal: payload.isOpen,
			};
		case SET_UNSAVED_MODAL:
			return {
				...state,
				unsavedModal: payload.isOpen,
				alertId: payload.id,
			};
		case SET_WALLET_VALUES:
			return {
				...state,
				walletValues: payload.walletValues,
			};
		case UPDATE_TAB_ID:
			return {
				...state,
				activeStrategies: state.activeStrategies.map(strategy => {
					if (payload.temporaryId === strategy.id) {
						return {
							...strategy,
							id: payload.tabId,
						};
					} else {
						return { ...strategy };
					}
				}),
			};
		case UPDATE_DELETION_DATA:
			return {
				...state,
				deletionData: payload.deletionData,
			};
		case UPDATE_TAB_INDEX:
			return {
				...state,
				tabIndex: payload.tabIndex,
			};
		case UPDATE_COLUMNS_SIZE: {
			const { field, width } = payload;
			const newColumnsSize = [];

			const columnsSize = state.preferences.columns.columnsSize ?? [];

			const searchedColumn = columnsSize.find(
				column => column.field === payload.field
			);

			if (searchedColumn) {
				searchedColumn.width = payload.width;
			} else {
				newColumnsSize.push({ field, width });
			}

			return {
				...state,
				preferences: {
					...state.preferences,
					columns: {
						...state.preferences.columns,
						columnsSize: [...columnsSize, ...newColumnsSize],
					},
				},
			};
		}
		case SET_ROWS:
			return {
				...state,
				rows: payload.rows.map(item => ({ ...item })),
			};
		default:
			return state;
	}
};
