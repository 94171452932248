export const CurrentStrategiesActionTypes = {
	SET_ROWS: 'currentStrategies/SET_ROWS',
	RESET_STATE: 'currentStrategies/RESET_STATE',
	ADD_STRATEGY: 'currentStrategies/ADD_STRATEGY',
	UPDATE_USER_PREFERENCES: 'currentStrategies/UPDATE_USER_PREFERENCES',
	UPDATE_COLUMNS_SIZE: 'currentStrategies/UPDATE_COLUMNS_SIZE',
	RESET_USER_PREFERENCES: 'currentStrategies/RESET_USER_PREFERENCES',
	UPDATE_COLUMNS_USER_PREFERENCES:
		'currentStrategies/UPDATE_COLUMNS_USER_PREFERENCES',
};
