import styled from 'styled-components';

import { Box } from '@mui/material';

export const Container = styled(Box)`
	height: 100%;

	.MuiTabs-scroller {
		border-bottom: 1px solid rgba(211, 211, 211, 0.25) !important;
	}
`;
