import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { apiAWS } from '../../../../services/api';

import { Box, CircularProgress, Typography } from '@mui/material';

import { ModalTemplate } from '../Template';
import { Buttons } from '../Buttons';

export const SendToEmail = ({
	open,
	handleClose,
	setOpenedModal,
	setSnackbarState,
	setTransactionalToken,
}) => {
	const { t } = useTranslation();

	const { username } = useSelector(state => state.auth);

	const [loading, setLoading] = useState(false);

	function sendToken() {
		setLoading(true);

		const header = { headers: { 'x-authorization': process.env.REACT_APP_AWS_API_TOKEN } };

		const body = {
			user_input: username,
			service: 'reset_signature',
		};

		apiAWS
			.post('/credentials/tokens?type=user_name', body, header)
			.then(response => {
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					autoHide: 3000,
					severity: 'success',
					message: t('modules.settings.credentials.alerts.tokenEmailSent')
				}));

				setTransactionalToken(response.data.transactional_token);
				setOpenedModal('verification-code');
				setTimeout(() => { }, response.data.validate_time_text);
			})
			.catch(error => {
				console.log(error);
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					severity: 'error',
					message: t('modules.settings.credentials.alerts.tokenEmailError')
				}));
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<ModalTemplate
			open={open}
			title="Esqueci ou não tenho assinatura eletrônica"
		>
			<Box
				sx={{
					maxWidth: '40rem',
					display: 'flex',
					flexDirection: 'column',
					gap: 1,
					px: 2
				}}
			>
				<Box>
					<Typography variant="subtitle1">
						Clique no botão<strong style={{ color: '#388e3c' }}> ENVIAR </strong>para receber em seu email o<strong> CÓDIGO DE VERIFICAÇÃO </strong>para criar uma assinatura eletrônica. Verifique sua caixa de entrada e spam.
					</Typography>
				</Box>
				<Buttons
					closeClick={handleClose}
					submitClick={sendToken}
				>
					{loading ? <CircularProgress size={20} /> : 'ENVIAR'}
				</Buttons>
			</Box>
		</ModalTemplate>
	);
};
