export const PRODUCT_TYPES = {
	FLEX_ANALYTIC: 'FLEX_ANALYTIC',
	FLEX_STANDARD: 'FLEX_STANDARD',
	FLEX_ADVANCED: 'FLEX_ADVANCED',
	FLEX_PRO: 'FLEX_PRO',
	FLEX_CUSTOM: 'FLEX_CUSTOM',
};

export const EXTERNAL_PRODUCTS = {
	TRADE_CERTO: 'TRADE_CERTO',
};

export const PRODUCT_FAMILIES = {
	FLEXSCAN: 'Flexscan',
};

export const PROFILE_CODES = {
	FLEX_ANALYTIC: 4,
	FLEX_PRO: 8,
	FLEX_CUSTOM: 10,
	FLEX_STANDARD: 12,
	FLEX_ADVANCED: 14,
};

export const USER_TYPES = {
	ADMIN: 1,
	ASSESSOR: 2,
	FINAL_CLIENT: 3,
	SUPPORT: 4,
	MESA: 5,
};

export const APPLICATION_MODULES = {
	CURRENT_STRATEGIES: 'current_strategies',
	HISTORY_STRATEGIES: 'history_strategies',
	EXECUTED_ORDERS: 'executed_orders',
	RECURRENCE_STRATEGIES: 'recurring_strategies',
	POSITIONS: 'positions',
	CUSTODY: 'custody',
	RISK: 'risk',
	OPTIONS_TRADER: 'options_trader',
	SIMULATOR: 'simulator',
	VOL_STUDIO: 'volstudio',
	UPLOAD_STRATEGIES: 'upload_strategies',
	SCANNER: 'scanner',
};
