export default {
	title: 'light',
	colors: {
		background: '#ffffff', // #f6f6f6
		boxColor: '#f6f6f6',
		bgMenu: '#333333;', // #30353F
		stroke: '#e5e5e5',
		primary: '#707070',
		secondary: 'rgb(112, 112, 112)',
		highlight: '#3897f0',
		border: '#e5e5e5',
		iconActive: '#707070',
		iconInactive: '#C4C4C4',
		actionDrawerBg: '#f6f6f6',
		bulletBg: '#f6f6f6',
		inputBorder: '#333333',
		inputColor: '#333333',
		inputBg: '#f6f6f6',
		selectBg: '#f6f6f6',
		strategyBg: '#ffffff',
		buyButtonColor: '#1976D2',
		sellButtonColor: '#E53935',
		buttonEditStrategy: '#F89706',
		buttonCreateStrategy: '#0C8B28',
		demoEnvColor: '#9E252C',
		realEnvColor: '#259E90',
		inputBgWidget: '#EEEEEE',
		textColorWidget: '#333',
		bgNotification: '#CCCCCC',
		bgNotificationViewed: '#FFF',
		demoStripColor: '#9E252C',
		realStripColor: '#259E90',
		sideBar: '#333333',
		userMenu: '#FFF',
		bgTitleBottomBullet: '#c4c4c4',
		textColorBottomBullet: '#333333',
		borderColorBottomBullet: '#888888',
		bgTitlesInsideBullet: '#D4D4D4',
		bgBottomBullet: '#EEEEEE',
		tableAlt: '#EFEFEF',
		bottomBulletRowPrimary: '#EEEEEE',
		bottomBulletRowSecondary: '#FFFFFF',
		textLabel: '#222',
		inputSimulator: '#555',
		iconFilterActive: '#F89706'
	},
	font: {
		size: '11px',
		title: '12px',
		headerIcons: '22px',
		leftIcons: '30px',
	},
	material: {
		overrides: {
			// Style sheet name ⚛️
			MuiButton: {
				// Name of the rule
				text: {
					// Some CSS
					color: 'black',
				},
			},
		},
	},
};
