export const isDisableBySymbol = (bullet, index = 0) => {
	if (bullet.paramsView) {
		return true;
	}

	if (bullet.content.StrategyCode === 'portbalance') {
		return !bullet.content.CustomParameters.SymbolList || bullet.content.CustomParameters.SymbolList === '';
	}

	if (index < bullet.content.StrategyLegs.length && index >= 0) {
		return (
			!bullet.content.StrategyLegs[index].LegSymbol ||
			(bullet.content.StrategyLegs[index].LegSymbol && bullet.content.StrategyLegs[index].LegSymbol === '')
		);
	}

	return true;
};
