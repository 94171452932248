import axios from 'axios';
import { store } from 'store';
import { toast } from 'react-toastify';

import { showMessageErroWithoutStatusCode } from 'services/api';
import { setAuthErrorMessage } from 'store/modules/notifications/actions';

const headers = {
	post: {
		'Content-Type': 'application/json;charset=utf-8',
	},
	get: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
};

export const apiCadastro = axios.create({
	baseURL: process.env.REACT_APP_AWS_API_REGISTRATION_URL,
	timeout: 10 * 1000,
	headers: {
		...headers,
		'x-api-key': process.env.REACT_APP_API_KEY_TOKEN,
	},
});

apiCadastro.interceptors.response.use(
	response => response,
	error => {
		const { response } = error;

		if (
			response &&
			response.status &&
			response.status >= 400 &&
			response.status <= 500
		) {
			switch (response.status) {
				case 400:
					if (response.data?.code === 6765) {
						store.dispatch(
							setAuthErrorMessage(
								'Assinatura eletrônica deve ter de 6 a 15 dígitos!'
							)
						);
					} else {
						store.dispatch(
							setAuthErrorMessage(
								'Não foi possível prosseguir com a solicitação. Favor realizar o login pelo portal novamente.'
							)
						);
					}
					break;
				case 401:
					store.dispatch(
						setAuthErrorMessage(
							'Não foi possível prosseguir com a solicitação. Favor realizar o login pelo portal novamente.'
						)
					);
					break;
				case 403:
					if (response.data?.code === 3423) {
						store.dispatch(
							setAuthErrorMessage('Assinatura inválida.')
						);
					} else if (response.data?.code === 3404) {
						store.dispatch(setAuthErrorMessage('Usuário inativo.'));
					} else {
						store.dispatch(
							setAuthErrorMessage(
								'Não foi possível prosseguir com a solicitação. Favor realizar o login pelo portal novamente.'
							)
						);
					}
					break;
				case 409:
					if (response.data?.code === 7542) {
						toast.error(
							response.data?.title ||
								'Link de primeiro acesso já enviado. Favor verifique sua caixa de email!'
						);
					}
					break;
				default:
					showMessageErroWithoutStatusCode(error);
					break;
			}
		} else {
			showMessageErroWithoutStatusCode(error);
		}

		return Promise.reject(error);
	}
);
