import {
	BusinessCenter,
	CloudUploadOutlined,
	SpaceDashboardRounded,
	Workspaces,
	CandlestickChart,
} from '@mui/icons-material';
import {
	LeftSimulator,
	TemporaryGraph,
	ScannerIcon,
	OptionTrader,
	RiskIcon,
} from 'assets/icons/IconPack';
import { getPermittedUsers } from 'helpers/gradient/permittedUsers';

import { store } from 'store';

export function getApplicationModulesList() {
	const activeProduct = store.getState().products?.activeProduct ?? {};

	const activeModules = activeProduct?.attributes?.modules ?? [];

	const username = store.getState().auth?.username ?? '';
	const riskEnabled = store.getState().auth?.riskEnabled ?? false;

	const isUserPermitted = getPermittedUsers(username);

	const defaultModules = [
		{
			path: '/dashboard',
			label: 'Acompanhamento',
			value: 'current_strategies',
			icon: SpaceDashboardRounded,
		},
		{
			path: '/posicoes',
			label: 'Posições',
			value: 'positions',
			icon: BusinessCenter,
		},
		{
			path: '/simulator',
			label: 'Simulador',
			value: 'simulator',
			icon: TemporaryGraph,
		},
		{
			path: '/cadeia-opcoes',
			label: 'Options Trader',
			value: 'options_trader',
			icon: OptionTrader,
		},
		{
			path: '/volstudio',
			label: 'Vol. Studio',
			value: 'volstudio',
			icon: LeftSimulator,
		},
		{
			path: '/scanner',
			label: 'Scanner',
			value: 'scanner',
			icon: ScannerIcon,
		},
		{
			path: '/riscos',
			label: 'Limite Operacional',
			value: 'risk',
			icon: RiskIcon,
		},
		{
			path: '/upload-estrategias',
			label: 'Upload de estratégias',
			value: 'strategies_upload',
			icon: CloudUploadOutlined,
		},
	];

	let finalModulesList = [...defaultModules];

	if (isUserPermitted) {
		finalModulesList = [
			...finalModulesList,
			{
				path: '/idxlivecharts',
				label: 'Gradiente de Minis',
				value: 'idx_live_charts',
				icon: CandlestickChart,
			},
		];
	}

	if (activeProduct.isPartnerProduct) {
		const externalModule = {
			path: '/external-product',
			value: 'external-product',
			label: activeProduct.name,
			icon: Workspaces,
		};

		finalModulesList = [...finalModulesList, externalModule];
	}

	if (activeModules.includes('all')) {
		if (!riskEnabled) {
			return finalModulesList.filter(module => module.value !== 'risk');
		}
		return finalModulesList;
	}

	const filteredModules = finalModulesList.filter(module => {
		if (module.value === 'risk' && !riskEnabled) {
			return false;
		} else {
			return activeModules.includes(module.value);
		}
	});

	return filteredModules;
}
