const daysPicker = {
	2: 'Se',
	3: 'Ter',
	4: 'Qua',
	5: 'Qui',
	6: 'Sex',
};

export const getDayPicker = day => {
	const dayInt = parseInt(day);

	const dayPicker = !isNaN(dayInt) ? daysPicker[dayInt] : day;

	return dayPicker;
};
