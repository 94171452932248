import React from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { ButtonBuySell } from '../MaterialStyles/style';

export default function TriggerSwitch(props) {
	const { disabled, triggerType, changeTrigger, isEdit, isTwap, isSingle, namePts, namePerc } = props;
	return (
		<ButtonGroup disabled={disabled} variant="contained" style={{ width: '100%', height: '19px' }}>
			<ButtonBuySell
				name={namePts}
				color={triggerType === 2 ? 'disabled' : 'primary'}
				onClick={isEdit !== undefined ? null : triggerType === 2 ? changeTrigger : null}
				style={{ width: isTwap || isSingle ? '50%' : '100%' }}
				size="small"
			>
				{isTwap ? 'Vol Fin' : 'Pts'}
			</ButtonBuySell>
			<ButtonBuySell
				name={namePerc}
				color={triggerType === 2 ? 'secondary' : 'disabled'}
				onClick={isEdit !== undefined ? null : triggerType === 2 ? null : changeTrigger}
				style={{ width: isTwap || isSingle ? '50%' : '100%' }}
				size="small"
			>
				{isTwap ? 'Qtd' : '%'}
			</ButtonBuySell>
		</ButtonGroup>
	);
}
