import { calcIvol, optionStrategyAnalysis } from '@investflex/iflexquantjs';

import { store } from 'store';
import { calcStrategyGreeks, getGreeksObjectName } from './getPortfolioGreeks';
import { calculateSpread } from 'pages/Simulator/handleBullet';
import {
	getFormatedPaper,
	getFormatedProfitPob,
	getStrategyExpirationDate,
} from './getFormatedDatas';
import {
	getCorrectPositionExitPx,
	getCorrectStrategyExitPx,
} from './getExitPrices';
import { setRows } from 'store/modules/portfolio/actions';

function analyzeStrategy(underPrice, interestRate, volatility, strategy) {
	const simulatorStrategy = store
		.getState()
		.simulator.strategyList.find(stt => stt.id === strategy.id);

	if (simulatorStrategy && simulatorStrategy.positions) {
		simulatorStrategy.positions.forEach(simulatorPosition => {
			const updatedPosition = strategy.positions.find(
				quotationPosition =>
					quotationPosition.id === simulatorPosition.id
			);

			if (updatedPosition) {
				simulatorPosition.exitPx =
					updatedPosition.exitPx ?? simulatorPosition.exitPx;
				simulatorPosition.entryPx =
					updatedPosition.entryPx ?? simulatorPosition.entryPx;
			}
		});

		const strategyToCalcMetrics = {
			underType: strategy.underType,
			positions: strategy.positions,
		};

		const strategyAnalyzed = optionStrategyAnalysis(
			underPrice,
			interestRate,
			0,
			volatility,
			strategyToCalcMetrics
		);

		const entryPx = calculateSpread(
			simulatorStrategy.positions,
			'entryPx',
			true
		);

		const exitPx = calculateSpread(
			simulatorStrategy.positions,
			'exitPx',
			true
		);

		return {
			cost: strategyAnalyzed.entryCost,
			exitCost: strategyAnalyzed.exitCost,
			pl: strategyAnalyzed.pl,
			plDay: strategyAnalyzed.exitCost,
			pop: getFormatedProfitPob(strategyAnalyzed.profitProb),
			approximateMargin: strategyAnalyzed.margin,
			exitPx: exitPx ?? '',
			entryPx: entryPx ?? '',
		};
	}
}

export function formatToTablePattern(strategies) {
	const formatedStrategies = [];

	const selectedGreekType =
		store.getState().configs.globalSettings?.userSettings?.simulator
			.greekType ?? 'exit';

	const greeksName = getGreeksObjectName(selectedGreekType);

	const exitPriceType =
		store.getState().configs.globalSettings.userSettings.simulator
			.exitPrice;

	strategies.forEach(strategy => {
		if (strategy.positions) {
			// When is a partial update the object has a different structure.
			const strategyToFormat = strategy.mainStrategy
				? strategy.mainStrategy
				: strategy;

			const closedLegs = strategyToFormat.positions.filter(
				position => position.exitPxLocked
			).length;
			const expiredLegs = strategyToFormat.positions.filter(
				position => position.expired
			).length;

			const exitPx = getCorrectStrategyExitPx(strategy);

			formatedStrategies.push({
				id: strategy.id,
				hierarchy: [strategy.id],
				name: strategy.name,
				userName: strategy.userName,
				details: strategy.text,
				type: strategy.type,
				expirationDate: getStrategyExpirationDate(strategy.positions),
				underSymbol: strategy.underSymbol,
				createdAt: strategy.createDate,
				updatedAt: strategy.lastUpdate,
				underType: strategy.underType,
				entryPx: strategy.entryPx,
				exitPx: exitPx,
				delta: strategy.delta,
				gamma: strategy.gamma,
				theta: strategy.theta,
				vega: strategy.vega,
				saved: strategy.saved,
				positions: strategy.positions,
				cost: strategy.cost,
				exitCost: strategy.exitCost,
				pl: strategy.pl,
				pop: strategy.pop,
				approximateMargin: strategy.approximateMargin,
				underPrice: strategy.underPrice,
				itm: strategy.itm,
				closedLegs: closedLegs,
				expiredLegs: expiredLegs,
			});
		} else {
			formatedStrategies.push({
				id: strategy.id,
				hierarchy: [strategy.strategyId, strategy.id],
				account: strategy.account,
				securityId: strategy.securityId,
				paper: getFormatedPaper(
					strategy.symbol,
					strategy.securityType,
					strategy.strikePx,
					strategy.optionType
				),
				underSymbol: strategy.strategyUnderSymbol,
				securityType: strategy.securityType,
				optionType: strategy.optionType,
				optionClass: strategy.optionClass,
				strikePx: strategy.strikePx,
				qtty: strategy.qtty,
				entryPx: strategy.entryPx,
				exitPx: getCorrectPositionExitPx(strategy, exitPriceType),
				theoreticImpliedVol: strategy.theoreticImpliedVol,
				expirationDate:
					strategy.securityType === 'OPT' &&
					strategy.unformatedExpirationDate,
				exitDate: strategy.exitDate,
				checksum: strategy.checksum,
				createdAt: strategy.createdAt,
				updatedAt: strategy.updatedAt,
				cost: strategy.cost,
				exitCost: strategy.exitCost,
				delta: strategy[greeksName].delta,
				gamma: strategy[greeksName].gamma,
				theta: strategy[greeksName].theta,
				vega: strategy[greeksName].vega,
				deltaUnitary: strategy[greeksName].deltaUnitary,
				gammaUnitary: strategy[greeksName].gammaUnitary,
				thetaUnitary: strategy[greeksName].thetaUnitary,
				vegaUnitary: strategy[greeksName].vegaUnitary,
				pl: strategy.pl,
				plDay: strategy.plDay,
				expired: strategy.expired,
				strategyEntryPx: strategy.strategyEntryPx,
				underPrice: strategy.underPrice,
				itm: strategy.itm,
				symbol: strategy.symbol,
				exitPxLocked: strategy.exitPxLocked,
				entryPxLocked: strategy.entryPxLocked,
			});
		}
	});

	return formatedStrategies;
}

function addVolatilities(strategy, interestRate, selectedGreekType) {
	const formatedStrategy = strategy;

	formatedStrategy.positions?.forEach(position => {
		const unformatedExpirationDate = position.expirationDate;
		const formatedExpirationDate = position.expirationDate.replaceAll(
			'-',
			''
		);

		position.expirationDate = formatedExpirationDate;

		if (selectedGreekType === 'exit') {
			const resultExitImpliedVol = parseFloat(
				calcIvol(
					strategy.underPrice,
					position.strikePx,
					interestRate,
					position.daysToExpiration,
					position.exitPx,
					position.optionType
				).toFixed(4) || 0
			);

			const exitImpliedVol =
				resultExitImpliedVol === -990000000000
					? 0
					: resultExitImpliedVol;

			position.exitImpliedVol = exitImpliedVol;
			position.correctVolatility = exitImpliedVol;
		} else if (selectedGreekType === 'entry') {
			const resultEntryImpliedVol = parseFloat(
				calcIvol(
					strategy.underPrice,
					position.strikePx,
					interestRate,
					position.daysToExpiration,
					position.entryPx,
					position.optionType
				).toFixed(4) || 0
			);

			const entryImpliedVol =
				resultEntryImpliedVol === -990000000000
					? 0
					: resultEntryImpliedVol;

			position.entryImpliedVol = entryImpliedVol;
			position.correctVolatility = entryImpliedVol;
		} else {
			position.correctVolatility = position.histVol30;
		}

		position.expirationDate = unformatedExpirationDate;
	});

	return formatedStrategy;
}

function isInTheMoney(position) {
	if (!position.exitPxLocked && !position.expired) {
		if (
			position.optionType === 'C' &&
			position.strikePx < position.underPrice
		) {
			return true;
		}

		if (
			position.optionType === 'P' &&
			position.strikePx > position.underPrice
		) {
			return true;
		}
	}

	return false;
}

function addDataEnrichment(strategy, interestRate, isOption = false) {
	let formatedStrategy = strategy;

	formatedStrategy.positions.forEach(position => {
		position.pl = position?.qtty * (position?.exitPx - position?.entryPx);
		position.cost = position?.entryPx * position?.qtty;
		position.exitCost = position?.exitPx * position?.qtty;
		position.underPrice = formatedStrategy.underPrice;
		position.itm = isInTheMoney(position);

		if (isOption) {
			const positionToSubscribe =
				formatedStrategy.mainStrategy.positions.find(
					mainPosition => mainPosition.id === position.id
				);

			positionToSubscribe.itm = position.itm;
		}
	});

	// When is a partial update the object has a different structure.
	const strategyToCalcMetrics = isOption
		? formatedStrategy.mainStrategy
		: formatedStrategy;

	formatedStrategy.itm = strategyToCalcMetrics.positions.some(
		position => position.itm
	);

	const strategyAnalyzed = analyzeStrategy(
		formatedStrategy.underPrice,
		interestRate,
		Number(formatedStrategy.histVol30),
		strategyToCalcMetrics
	);

	const validFields = {
		entryPx: strategyAnalyzed?.entryPx ?? null,
		exitPx: strategyAnalyzed?.exitPx ?? null,
	};

	return {
		...formatedStrategy,
		...strategyAnalyzed,
		...validFields,
	};
}

export function addDefinitions(strategies, interestRate, isOption = false) {
	const formatedStrategies = [];

	const selectedGreekType =
		store.getState().configs.globalSettings?.userSettings?.simulator
			.greekType ?? 'exit';

	strategies.forEach(strategy => {
		const strategyWithVolatilities = addVolatilities(
			strategy,
			interestRate,
			selectedGreekType
		);

		const strategyWithGreeks = calcStrategyGreeks(
			strategyWithVolatilities,
			interestRate,
			selectedGreekType,
			isOption
		);

		const strategyEnriched = addDataEnrichment(
			strategyWithGreeks,
			interestRate,
			isOption
		);

		strategyEnriched.positions.forEach(position =>
			formatedStrategies.push(position)
		);

		formatedStrategies.push(strategyEnriched);
	});

	return formatedStrategies;
}

export function formatStrategies(strategies, interestRate) {
	const strategiesWithDefinitions = addDefinitions(strategies, interestRate);
	store.dispatch(setRows(formatToTablePattern(strategiesWithDefinitions)));
}
