import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography } from '@mui/material';

import { apiExec } from 'services/api';

import { ModalTemplate } from '../Template';
import { PasswordInput } from 'components/PasswordInput';
import { CopyTextField } from 'components/CopyTextField';
import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';

export const ExternalAccessToken = ({ open, onClose }) => {
	const username = useSelector(state => state.auth.username);

	const [token, setToken] = useState(null);
	const [signature, setSignature] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'error',
	});

	function handleChangeSignature(newSignature) {
		const regexCheck = /^[A-Za-z0-9]{0,15}$/.test(newSignature);

		if (regexCheck) {
			setSignature(newSignature);
		}
	}

	function handleChangePasswordVisibility() {
		setIsPasswordVisible(!isPasswordVisible);
	}

	function handleSubmit() {
		const encodeSignature = btoa(signature);

		const body = {
			signature: encodeSignature,
			service: 'create_access_token',
		};

		setIsLoading(true);

		apiExec
			.post(`/users/${username}/credentials`, body)
			.then(response => {
				setToken(atob(response.data.access_token));
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					severity: 'success',
					message: 'Novo token gerado com sucesso'
				}));
			})
			.catch(error => {
				setToken(null);

				const { response } = error;

				const errorMessages = {
					400: 'Formato da requisição inválido',
					401: 'Token inválido ou expirado',
					403: {
						3423: 'Assinatura eletrônica inválida',
						3426: 'Tentativa de criar token de acesso para outro usuário que não o seu',
						default: 'Token válido porém sem permissão para executar a mudança'
					},
				};

				let errorMessage = errorMessages[response.status] || 'Erro desconhecido';

				// Se a mensagem de erro for um objeto, procura por um código específico
				if (typeof errorMessage === 'object') {
					errorMessage = errorMessage[response.data.code] || errorMessage.default;
				}

				setSnackbarState(state => ({
					...state,
					isOpen: true,
					severity: 'error',
					message: errorMessage
				}));
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	function handleClose() {
		onClose();
		setToken(null);
		setSignature('');
	}

	return (
		<>
			<ModalTemplate
				open={open}
				onClose={handleClose}
				title="Tokens de Acesso"
				closeButton={handleClose}
			>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 16,
						padding: 8,
						maxWidth: '40rem'
					}}
				>
					<Typography variant="h6">
						Obtenha um token de acesso para integrar feramentas externas com o Flexscan.
					</Typography>

					<Typography variant="subtitle1" sx={{ color: '#bebebe' }}>
						Ao gerar um novo token, o antigo é desativado.
					</Typography>

					<Grid item container spacing={1}>
						<Grid item xs={8}>
							<PasswordInput
								label="Assinatura"
								required
								withoutAutofill={true}
								value={signature}
								inputProps={{ min: 6, max: 15 }}
								isPasswordVisible={isPasswordVisible}
								changePasswordVisibility={handleChangePasswordVisibility}
								onChange={(event) => handleChangeSignature(event.target.value)}
							/>
						</Grid>

						<Grid item xs={4}>
							<LoadingButton
								color='success'
								variant="contained"
								fullWidth
								sx={{
									height: '100%',
									color: '#fff',
									background: '#205e23',
									'&:hover:not(:disabled)': {
										opacity: 0.9
									}
								}}
								loading={isLoading}
								onClick={handleSubmit}
								disabled={signature.length < 6 || signature.length > 15}
							>
								Gerar novo token
							</LoadingButton>
						</Grid>
					</Grid>

					{token && <CopyTextField value={token} label="Token" />}
				</Box>
			</ModalTemplate>

			<MaterialSnackbar
				open={snackbarState.isOpen}
				severity={snackbarState.severity}
				handleClose={() => setSnackbarState({
					isOpen: false,
					message: '',
				})}
			>
				{snackbarState.message}
			</MaterialSnackbar>
		</>
	);
};
