import React from 'react';
import { mask, unMask } from 'remask';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import createDecorator from 'final-form-focus';

import { Button } from '@mui/material';

import { required } from 'utils/validators';
import { FormContainer } from 'styles/global';

const focusOnError = createDecorator();

const onSubmitEnter = (event) => {
	switch (event.key) {
		case 'Enter':
			document.getElementById('buttonSubmit').click();
			break;
		default:
			return console.log(event.key);
	}
};

export const ForgotPasswordForm = ({ onSubmit }) => {
	return (
		<Form
			onSubmit={onSubmit}
			decorators={[focusOnError]}
			render={({ handleSubmit, submitting }) => (
				<FormContainer onSubmit={handleSubmit}>
					<Field
						name="username"
						id="cpfTextBox"
						type="text"
						component={TextField}
						parse={(value) => mask(unMask(value), ['999.999.999-99', '99.999.999/9999-99'])}
						defaultValue=""
						label="CPF/CNPJ"
						margin="normal"
						fullWidth
						variant="outlined"
						validate={required}
						onKeyDown={(e) => onSubmitEnter(e)}
					/>
					<Button variant="contained" color="primary" fullWidth type="submit" id="buttonSubmit" disabled={submitting}>
						Enviar código de verificação
					</Button>
				</FormContainer>
			)}
		/>
	);
};
