import React, { useState } from 'react';

import {
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
	GridToolbarFilterButton,
	GridToolbarExport,
} from '@mui/x-data-grid-pro';

import { Box, Button, IconButton } from '@mui/material';
import { Expand, Settings, ImportExport } from '@mui/icons-material';

import { Container } from './styles';

import { MigrationMenu } from './MigrationMenu';
import { LightTooltip } from 'components/LightTooltip';

import { store } from 'store';
import { setIsGlobalSettingsOpen } from 'store/modules/panelConfig/actions';

export const Toolbar = (isLoading, changeRowExpansion, setMigrationStep) => {
	const [anchorEl, setAnchorEl] = useState(null);

	function handleOpenMenu(event) {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	}

	function handleCloseMenu() {
		setAnchorEl(null);
	}

	function handleOpenSettingsMenu() {
		store.dispatch(setIsGlobalSettingsOpen(true, 'simulator'));
	}

	return (
		<Container>
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					color="inherit"
					disabled={isLoading}
				/>

				<GridToolbarFilterButton color="inherit" disabled={isLoading} />

				<GridToolbarDensitySelector
					color="inherit"
					disabled={isLoading}
				/>

				<GridToolbarExport color="inherit" disabled={isLoading} />

				<Button
					size="small"
					color="inherit"
					disabled={isLoading}
					startIcon={<Expand />}
					onClick={changeRowExpansion}
				>
					Colapsar/Expandir
				</Button>
			</GridToolbarContainer>

			<Box>
				<LightTooltip
					title="Migrar estratégias"
					placement={anchorEl ? 'left' : 'bottom'}
				>
					<IconButton
						disabled={isLoading}
						color="inherit"
						onClick={handleOpenMenu}
					>
						<ImportExport />
					</IconButton>
				</LightTooltip>

				<LightTooltip title="Configurações">
					<IconButton
						disabled={isLoading}
						color="inherit"
						onClick={handleOpenSettingsMenu}
					>
						<Settings />
					</IconButton>
				</LightTooltip>
			</Box>

			{anchorEl && (
				<MigrationMenu
					anchorEl={anchorEl}
					isOpen={anchorEl}
					onClose={handleCloseMenu}
					setMigrationStep={setMigrationStep}
				/>
			)}
		</Container>
	);
};
