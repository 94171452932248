import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	TextField,
	Typography,
	colors,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { store } from 'store';
import { apiExec } from 'services/api';
import { setFavorite2FA } from 'store/modules/auth/actions';

import { CopyTextField } from 'components/CopyTextField';
import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';

const AppConfirmationDialog = ({
	isOpen,
	onClose,
	handleClose,
	setSnackbarState,
}) => {
	const username = store.getState().auth.username;

	const [isLoading, setIsLoading] = useState(false);

	function handleSubmit() {
		setIsLoading(true);

		const body = { activeAuthenticatorType: 'app' };

		apiExec
			.put(`/users/${username}`, body)
			.then(() => {
				store.dispatch(setFavorite2FA('app'));
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					message: 'Configurado com sucesso.',
					severity: 'success',
				}));
				handleClose();
			})
			.catch(error => {
				console.error(error);
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					message: 'Algo deu errado, tente novamente mais tarde.',
					severity: 'error',
				}));
			})
			.finally(() => setIsLoading(false));
	}

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>Aplicativo configurado com sucesso!</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Você deseja fazer com que seu método de autenticação padrão
					seja via aplicativo?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="inherit" onClick={handleClose}>
					Não
				</Button>

				<LoadingButton
					loading={isLoading}
					variant="contained"
					onClick={handleSubmit}
				>
					Sim
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

const SecretKeyDialog = ({ isOpen, onClose, secretKey }) => {
	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>Sua 2FA secret key</DialogTitle>
			<DialogContent>
				<CopyTextField value={secretKey} alwaysShowCopyButton />
			</DialogContent>
		</Dialog>
	);
};

export const AuthAppConfiguration = ({
	isOpen,
	handleClose,
	favoriteMethod,
	parentSetSnackbarState,
}) => {
	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'error',
	});

	const theme = useTheme();

	const [authAppConfigurationInfo, setAuthAppConfigurationInfo] = useState({
		secret: '',
		uri: '',
	});
	const [isManualConfigurationOpen, setIsManualConfigurationOpen] =
		useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [isAppConfirmationModalOpen, setIsAppConfirmationModalOpen] =
		useState(false);

	const oneTimePasswordRef = useRef();

	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

	const isMobile = isSmallScreen || isExtraSmallScreen;

	useEffect(() => {
		const body = {
			type: 'app',
		};

		apiExec
			.post('/authenticators', body)
			.then(response => {
				setAuthAppConfigurationInfo({
					secret: atob(response.data.secret),
					uri: atob(response.data.uri),
				});
			})
			.catch(error => console.error(error));
	}, []);

	function handleOpenManualConfiguration() {
		setIsManualConfigurationOpen(true);
	}

	function handleCloseManualConfiguration() {
		setIsManualConfigurationOpen(false);
	}

	function handleSubmitNewAuthApp() {
		if (
			!oneTimePasswordRef.current.value ||
			oneTimePasswordRef.current.value === ''
		) {
			setSnackbarState(state => ({
				...state,
				isOpen: true,
				message: 'Preencha o campo corretamente',
				severity: 'error',
			}));
			return;
		}

		setIsLoading(true);

		const body = {
			type: 'app',
			otp: oneTimePasswordRef.current.value,
		};

		apiExec
			.post('authenticate', body)
			.then(() => {
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					message: 'Método de autenticação configurado com sucesso',
					severity: 'success',
				}));

				setIsAppConfirmationModalOpen(true);
			})
			.catch(error => {
				console.error(error);
				const errorMessage =
					error.response?.data?.code === 4002
						? 'Código inválido'
						: error.response?.data?.title;

				setSnackbarState(state => ({
					...state,
					isOpen: true,
					message:
						errorMessage ||
						error.response?.data?.title ||
						error.message,
					severity: 'error',
				}));
			})
			.finally(() => {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			});
	}

	return (
		<>
			<Dialog open={isOpen} onClose={handleClose}>
				<DialogTitle>App de autenticação</DialogTitle>

				<DialogContent
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 1.5,
						maxWidth: isMobile ? 'auto' : '40rem',
					}}
				>
					<Divider />

					<DialogContentText variant="subtitle1">
						Apps de autenticação são aplicativos como google
						Authenticator, Authy, Microsoft Authenticator que
						geraram senhas de uso único, que são usadas como um
						segundo fator para verificar sua identidade durante o
						login.
					</DialogContentText>

					<Typography variant="h6">Scaneie o QR code</Typography>

					<DialogContentText>
						Abra o aplicativo autenticador para scanear o QR code.
					</DialogContentText>

					<div style={{ maxWidth: 96 }}>
						<QRCode
							size={256}
							style={{
								height: 'auto',
								maxWidth: '100%',
								width: '100%',
							}}
							value={authAppConfigurationInfo.uri}
							viewBox="0 0 256 256"
						/>
					</div>

					<DialogContentText>
						Problemas para scanear? Veja o secret para configuração
						manual{' '}
						<span
							style={{
								color: colors.lightBlue[500],
								textDecoration: 'underline',
								cursor: 'pointer',
							}}
							onClick={handleOpenManualConfiguration}
						>
							aqui
						</span>
					</DialogContentText>

					<Divider />

					<Typography variant="h6">
						Verifique o código gerado no app
					</Typography>

					<TextField
						variant="outlined"
						size="small"
						inputRef={oneTimePasswordRef}
						error={
							snackbarState.isOpen &&
							snackbarState.severity === 'error'
						}
					/>
				</DialogContent>

				<DialogActions>
					<Button color="inherit" onClick={handleClose}>
						Fechar
					</Button>

					<LoadingButton
						loading={isLoading}
						variant="contained"
						onClick={handleSubmitNewAuthApp}
					>
						Verificar
					</LoadingButton>
				</DialogActions>

				<MaterialSnackbar
					open={snackbarState.isOpen}
					severity={snackbarState.severity ?? 'error'}
					handleClose={() =>
						setSnackbarState(state => ({
							...state,
							isOpen: false,
						}))
					}
				>
					{snackbarState.message}
				</MaterialSnackbar>
			</Dialog>

			{isManualConfigurationOpen && (
				<SecretKeyDialog
					isOpen={isManualConfigurationOpen}
					onClose={handleCloseManualConfiguration}
					secretKey={authAppConfigurationInfo.secret}
				/>
			)}

			{isAppConfirmationModalOpen && favoriteMethod !== 'app' && (
				<AppConfirmationDialog
					handleClose={handleClose}
					isOpen={isAppConfirmationModalOpen}
					setSnackbarState={parentSetSnackbarState}
					onClose={() => setIsAppConfirmationModalOpen(false)}
				/>
			)}
		</>
	);
};
