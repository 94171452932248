
export const VISION_ADD = 'market//VISION::ADD';
export const VISION_CHANGE = 'market//VISION::CHANGE';
export const VISION_ROW_ADD = 'market//VISION::ADD_ROW';
export const VISION_UPDATE = 'market//VISION::UPDATE';
export const VISION_REMOVE =  'market/VISION::REMOVE';
export const VISION_ROW_REMOVE =  'market//VISION::REMOVE_ROW';
export const VISIBILITY_SET =  'market/VISIBILITY::SET';
export const SET_DEFAULT_VISION =  'market/VISION::SET_DEFAULT';
export const SET_VISIONS =  'market/VISION::SET_VISIONS';
export const VISION_CELL_COLOR_CHANGE = 'market//VISION::COLOR_ROW_CHANGE';
export const INTEREST_RATE_SET = 'market/INTEREST_RATE::SET';