import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { apiAWS, apiCadastro } from '../../../services/api';
import { signInAsync } from 'store/modules/auth/actions';
import { setAuthErrorMessage } from 'store/modules/notifications/actions';

import { IconButton, InputAdornment, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import {
	Container,
	FormBox,
	InputBox,
	StyledInput,
	RulesBox,
	Rules,
	ButtonsBox,
	StyledCheckbox,
} from './styles';

import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';
import Button from '../Button';
import Contract from '../Contract';
import themes from '../../../themes';

export const ContractStep = ({
	history,
	contractInfo,
	setLoading,
	authToken,
	userInfo,
	handleCancelContractSign
}) => {
	const dispatch = useDispatch();

	const { accountType } = useSelector(state => state.auth);
	const { authErrorMessage } = useSelector(state => state.notifications);

	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [eletronicSignature, setEletronicSignature] = useState('');
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [inputError, setInputError] = useState(false);
	const [rulesError, setRulesError] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [checked, setChecked] = useState(false);

	const handleShowPassword = () => {
		setPasswordVisibility(passwordVisibility ? false : true);
	};

	const handleOpen = () => {
		setShowModal(true);
		setDisabled(false);
	};

	const toggleCheckbox = (e) => {
		setChecked(e.target.checked);
		setRulesError(checked ? false : true);
	};

	const handleSignContract = () => {
		setChecked(true);
		setRulesError(false);
		setShowModal(false);
	};

	const handleLogin = () => {
		if (eletronicSignature === '') {
			setInputError(true);
		} else if (!checked) {
			setInputError(false);
			setRulesError(true);
		} else if (checked) {
			setRulesError(false);
			isSignatureValid();
		}
	};

	// Validação da assinatura eletrônica informada pelo usuário
	const isSignatureValid = () => {
		setLoading(true);
		dispatch(setAuthErrorMessage(null));

		const body = { signature: eletronicSignature };

		const header = { headers: { Authorization: `Bearer ${authToken}` } };

		apiCadastro
			.post(`users/${userInfo.user_name.toLowerCase()}/credentials/signatures`, body, header)
			.then(() => {
				setOpenSnackbar(false);
				handleAcceptContract();
			})
			.catch(error => {
				console.log(error);
				setOpenSnackbar(true);
				setLoading(false);
			});
	};

	// Requisição caso o usuário aceita os termos de uso
	const handleAcceptContract = () => {
		setLoading(true);

		const headers = { headers: { Authorization: `Bearer ${authToken}` } };
		const newUserName = userInfo.user_name.toLowerCase();

		apiAWS
			.post(`/users/${newUserName}/contracts`, contractInfo, headers)
			.then(response => {
				dispatch(
					signInAsync({
						authToken,
						userName: userInfo.user_name,
						history,
						accountType,
						handleCancelContractSign,
						userIp: response.data.user_ip,
						userDate: response.data.datetime,
					}),
				);
			})
			.catch(error => {
				console.log(error);
				setLoading(false);
			});
	};

	return (
		<Container>
			<Typography variant="h1">Flex<span>scan</span></Typography>
			<Typography variant="h4">Para utilizar o Flexscan você precisa aceitar os termos de uso.</Typography>
			<Typography variant="h5">*Atualizados em 01/03/2021</Typography>

			<FormBox>
				<InputBox>
					<StyledInput
						id="signature-field"
						variant="outlined"
						label="Assinatura eletrônica"
						type={passwordVisibility ? 'text' : 'password'}
						onChange={e => setEletronicSignature(e.target.value)}
						inputError={inputError}
						inputProps={{ maxLength: 15 }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleShowPassword}
									>
										{passwordVisibility ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
					{inputError && <Typography variant="body1">Este campo é obrigatório </Typography>}
				</InputBox>
				<RulesBox>
					<Rules error={rulesError}>
						<StyledCheckbox
							checked={checked}
							onChange={toggleCheckbox}
							disabled={disabled}
						/>
						<Typography variant="h6">Li, compreendi e estou de acordo com os <br /><span onClick={handleOpen}>Termos de uso do Flexscan.</span></Typography>
					</Rules>
					{rulesError && <Typography variant="body1">Leia e marque acima para concordar com os termos</Typography>}
				</RulesBox>
				<ButtonsBox>
					<Button
						id="close-btn"
						variant="contained"
						type="submit"
						backgroundcolor={themes.orama.colors.white}
						color={themes.orama.colors.green}
						fontFamily="Encode Sans"
						width="120px"
						onClick={handleCancelContractSign}
					>
						Cancelar
					</Button>
					<Button
						variant="contained"
						type="submit"
						backgroundcolor={themes.orama.colors.green}
						onClick={handleLogin}
						fontFamily="Encode Sans"
						width="180px"
					>
						Acessar Flexscan
					</Button>
				</ButtonsBox>
			</FormBox>

			<Contract
				open={showModal}
				onClose={() => setShowModal(false)}
				handleAccept={handleSignContract}
				contractInfo={contractInfo}
			/>

			<MaterialSnackbar
				open={authErrorMessage && openSnackbar}
				severity="error"
			>
				{authErrorMessage}
			</MaterialSnackbar>
		</Container>
	);
};
