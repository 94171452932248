import { apiEls } from '../services/api';
import { toast } from 'react-toastify';

export const validationPortBalance = async (symbolList, initialList, desiredList) => {
	// Separando a string de cada campo em array com ;
	const splitSymbolList = symbolList.split(';');
	const splitInitialList = initialList.split(';');
	const splitDesiredList = desiredList.split(';');

	// Validando que o último ; seja ignorado 
	const verifyArraySymbolList = splitSymbolList.filter(function (item) {
		return item;
	});

	const verifyArrayInitialList = splitInitialList.filter(function (item) {
		return item;
	});

	const verifyArrayDesiredList = splitDesiredList.filter(function (item) {
		return item;
	});

	// Validando que todos os campos tenham a mesma quantidade de elementos inseridos
	if (verifyArraySymbolList.length !== verifyArrayInitialList.length || verifyArraySymbolList.length !== verifyArrayDesiredList.length) {
		toast.error('Os campos devem ter a mesma quantidade de elementos inseridos');
		return true;
	};

	// Validando se o campo "posição desejada (%)" soma em 100
	let positionsCount = 0;

	verifyArrayDesiredList.forEach(item => {
		positionsCount = positionsCount + parseInt(item, 10);
	});

	if (positionsCount !== 100) {
		toast.error('O campo de posição desejada (%) deve somar em 100');
		return true;
	};
	
	// Validando se os ativos inseridos existem
	const symbolRequest = [];

	verifyArraySymbolList.forEach(symbol => {
		symbolRequest.push(apiEls.get(`/search/${symbol}`));
	});

	const verifySymbol = await Promise.all(symbolRequest);

	// Indicando quais ativos inseridos não existem
	const checkSymbolExist = verifySymbol.some(item => item.data.found === false);

	if (checkSymbolExist) {
		const listSymbolDontExist = verifySymbol.filter(item => item.data.found === false);
		let symbols = '';
		listSymbolDontExist.forEach(item => {
			symbols = symbols + item.data._id + ', ';
		});
		toast.error(`Ativo não encontrado: ${symbols.slice(0, -2)}`);
		return true;
	};

	// Validando se os ativos inseridos são do mesmo exchange
	const checkExchange = (currentValue, index, array) => {
		return currentValue.data._source.subExchange === array[0].data._source.subExchange;
	};

	const allEqualExchanges = verifySymbol.filter(checkExchange).length === verifySymbol.length;

	if (allEqualExchanges === false) {
		toast.error('Os ativos devem ser do mesmo exchange');
		return true;
	};

	return false;
};