import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ExpandMore } from '@mui/icons-material';
import {
	Box,
	ClickAwayListener,
	Fade,
	IconButton,
	List,
	MenuItem,
	Paper,
	Popper,
	Typography,
} from '@mui/material';

import { getStrategy } from '../utils';
import { strategyUpdate } from 'store/modules/simulator/actions';

export const HeaderGreek = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const strategy = useSelector(getStrategy);
	const activeStrategy = useSelector(state => state.simulator.activeStrategy);
	const strategyShowTypeGreeks = useSelector(
		state =>
			state.simulator.strategyList[state.simulator.activeStrategy]
				?.showTypeGreeks
	);

	const [anchorEl, setAnchorEl] = useState(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	function handleOpenMenu(event) {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setIsMenuOpen(!isMenuOpen);
	}

	function handleCloseMenu() {
		setAnchorEl(null);
		setIsMenuOpen(false);
	}

	function setValue(value) {
		handleCloseMenu();

		dispatch(
			strategyUpdate(
				{
					...strategy,
					showTypeGreeks: value,
				},
				activeStrategy
			)
		);
	}

	const greekType = {
		exit: t('simulator.table.greeks.exit'),
		entry: t('simulator.table.greeks.entry'),
		theoretical: t('simulator.table.greeks.theoretic'),
	}[strategyShowTypeGreeks];

	const menuActions = [
		{ value: 'exit', label: t('simulator.table.greeks.exit') },
		{ value: 'entry', label: t('simulator.table.greeks.entry') },
		{ value: 'theoretical', label: t('simulator.table.greeks.theoretic') },
	];

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<IconButton size="small" onClick={handleOpenMenu}>
					<ExpandMore />
				</IconButton>

				<Typography variant="body2">Gregas - {greekType}</Typography>
			</Box>

			<Popper
				transition
				placement="bottom"
				open={isMenuOpen}
				anchorEl={anchorEl}
			>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={handleCloseMenu}>
						<Fade {...TransitionProps}>
							<Paper elevation={12} square={false}>
								<List>
									{menuActions.map(item => (
										<MenuItem
											selected={
												strategyShowTypeGreeks ===
												item.value
											}
											key={item.value}
											onClick={() => setValue(item.value)}
										>
											{item.label}
										</MenuItem>
									))}
								</List>
							</Paper>
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
		</>
	);
};
