import React from 'react';

import { Tooltip } from '@mui/material';
import { SizedCheckBoxContainer, SizedCheckBox } from '../MaterialStyles/style';

const MaterialCheckBox = ({
	label,
	onChange,
	disabled,
	checked,
	smallLabel = false,
	tooltip,
	name,
	testId,
}) => {
	return (
		<SizedCheckBoxContainer
			smallLabel={smallLabel}
			control={
				tooltip ? (
					<Tooltip arrow title={tooltip}>
						<SizedCheckBox color="primary" disabled={disabled} />
					</Tooltip>
				) : (
					<SizedCheckBox color="primary" disabled={disabled} />
				)
			}
			label={label}
			name={name}
			onChange={onChange}
			checked={checked}
			data-testId={testId}
		/>
	);
};

export default MaterialCheckBox;
