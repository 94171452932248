import React from 'react';
import { format, parseISO } from 'date-fns';

import { colors } from '@mui/material';

import { ChipCell } from 'components/ChipCell';

export const sideFields = {
	1: {
		label: 'Compra',
		color: colors.blue[500],
	},
	2: {
		label: 'Venda',
		color: colors.red[600],
	},
};

const fieldsTypes = {
	orderType: {
		1: {
			label: 'Mercado',
			color: colors.blue[500],
		},
		2: {
			label: 'Limitada',
			color: colors.grey[400],
		},
		3: {
			label: 'Stop',
			color: colors.green[500],
		},
		4: {
			label: 'Stop limitada',
			color: colors.green[900],
		},
		K: {
			label: 'Mercado (K)',
			color: colors.blue[900],
		},
		k: {
			label: 'Mercado (K)',
			color: colors.blue[900],
		},
	},
	execType: {
		1: {
			label: 'Parcial',
			color: colors.orange[600],
		},
		2: {
			label: 'Total',
			color: colors.green[500],
		},
	},
};

const getColorSide = side => sideFields[side]?.color ?? '';
const getColorExecType = execType =>
	fieldsTypes.execType[execType]?.color ?? '';

export const executionsColumns = [
	{
		flex: 1,
		field: 'StrategyId',
		headerName: 'ID da estratégia',
		type: 'string',
	},
	{
		flex: 1,
		field: 'Name',
		headerName: 'Nome da estratégia',
		type: 'string',
	},
	{
		flex: 1,
		field: 'OrderID',
		headerName: 'Order ID',
		type: 'string',
	},
	{
		flex: 1,
		field: 'Account',
		headerName: 'Conta',
		type: 'string',
	},
	{
		flex: 1,
		field: 'Symbol',
		headerName: 'Instrumento',
		type: 'string',
	},
	{
		flex: 1,
		field: 'Side',
		headerName: 'C / V',
		type: 'string',
		renderCell: params => (
			<ChipCell
				label={sideFields[params.value]?.label}
				color={getColorSide(params.value)}
			/>
		),
	},
	{
		flex: 1,
		field: 'OrderQty',
		headerName: 'Quantidade',
		type: 'number',
	},
	{
		flex: 1,
		field: 'Price',
		headerName: 'Preço',
		type: 'number',
		renderCell: params =>
			new Intl.NumberFormat('pt-BR').format(params.value),
	},
	{
		flex: 1,
		field: 'MarketOrderID',
		headerName: 'Market Order ID',
		type: 'string',
	},
	{
		flex: 1,
		field: 'OrderType',
		headerName: 'Tipo',
		type: 'string',
		renderCell: params => (
			<span
				style={{
					color: fieldsTypes.orderType[params.value]?.color,
				}}
			>
				{fieldsTypes.orderType[params.value]?.label}
			</span>
		),
	},
	{
		flex: 1,
		field: 'ExecType',
		headerName: 'Tipo exec.',
		type: 'string',
		renderCell: params => (
			<ChipCell
				color={getColorExecType(params.value)}
				label={fieldsTypes.execType[params.value]?.label}
			/>
		),
	},
	{
		flex: 1,
		field: 'LastQty',
		headerName: 'Última qtd.',
		type: 'number',
	},
	{
		flex: 1,
		field: 'LastPx',
		headerName: 'Último preço',
		type: 'number',
		renderCell: params =>
			new Intl.NumberFormat('pt-BR').format(params.value),
	},
	{
		flex: 1,
		field: 'ExecutedQty',
		headerName: 'Qtd. exec.',
		type: 'number',
	},
	{
		flex: 1,
		field: 'RemainingQty',
		headerName: 'Qtd. rest.',
		type: 'number',
	},
	{
		flex: 1,
		field: 'StopPrice',
		headerName: 'Preço Stop',
		type: 'number',
	},
	{
		flex: 1,
		field: 'rlp',
		headerName: 'RLP',
		type: 'boolean',
	},
	{
		flex: 1.5,
		field: 'SendingTime',
		headerName: 'Envio',
		type: 'dateTime',
		renderCell: params => {
			return format(
				parseISO(params.row.SendingTime),
				'dd/MM/yyyy HH:mm:ss'
			);
		},
	},
	{
		flex: 1,
		field: 'ExecID',
		headerName: 'Exec ID',
		type: 'string',
	},
];
