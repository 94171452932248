import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import {
	Autocomplete,
	Avatar,
	Box,
	CardHeader,
	Checkbox,
	Grid,
	IconButton,
	MenuItem,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	AddCircle,
	Apps,
	CheckBox,
	CheckBoxOutlineBlank,
	Delete,
	Edit,
	Send,
} from '@mui/icons-material';

import {
	ShadowGrid,
	StyledCard,
	StyledGeneralButton,
	TitleClick,
} from '../style';

import { setQuickSettings } from 'store/modules/scanner/actions';
import { PaperFilter } from '../selectList';
import { NewSelect } from '../NewSelect';

import taxa from '../Graphs/Taxa.png';
import trava from '../Graphs/Trava.png';
import fence from '../Graphs/Fence.png';
import condor from '../Graphs/Condor.png';
import collar from '../Graphs/Collar.png';
import travaLong from '../Graphs/TravaLong.png';
import borboleta from '../Graphs/Borboleta.png';
import condorLong from '../Graphs/CondorLong.png';
import borboletaLong from '../Graphs/BorboletaLong.png';

const SelectScan = savedSearchs => {
	const dispatch = useDispatch();
	const quickSettings = useSelector(state => state.scanner.quickSettings);
	const [quickScanSetting, setQuickScanSetting] = useState({
		underSymbol: [],
		expirationDate: 'Todos',
		quickScans: [
			{
				label: 'Taxa',
				queryId: 'TAXAS',
				optionType: 'Call',
				longShort: 'Todos',
			},
			{
				label: 'Trava',
				queryId: 'TRAVAS',
				optionType: 'Call',
				longShort: 'Long',
			},
			{
				label: 'Borboleta',
				queryId: 'BORBOLETAS',
				optionType: 'Call',
				longShort: 'Long',
			},
			{
				label: 'Condor',
				queryId: 'CONDORS',
				optionType: 'Call',
				longShort: 'Long',
			},
			{
				label: 'Fence',
				queryId: 'FENCES',
				optionType: 'Todos',
				longShort: 'Todos',
			},
			{
				label: 'Collar',
				queryId: 'COLLARS',
				optionType: 'Todos',
				longShort: 'Todos',
			},
		],
	});

	useEffect(() => {
		if (Object.keys(quickSettings).length !== 0) {
			setQuickScanSetting(quickSettings);
		}
	}, [quickSettings]);

	const handleQuickButton = (type, subType, index) => {
		const updatearray = [...quickScanSetting.quickScans];
		let newValue = '';

		if (type === 'optionType') {
			newValue = subType === 'Call' ? 'Put' : 'Call';
		}

		if (type === 'longShort') {
			newValue = subType === 'Long' ? 'Short' : 'Long';
		}

		updatearray[index] = {
			...updatearray[index],
			[type]: newValue,
		};

		setQuickScanSetting({ ...quickScanSetting, quickScans: updatearray });
		dispatch(
			setQuickSettings({ ...quickScanSetting, quickScans: updatearray })
		);
	};

	const handleExecute = async settings => {
		const newSetting = settings;
		newSetting.underSymbol = quickScanSetting.underSymbol;
		newSetting.expirationDate = quickScanSetting.expirationDate;
		await savedSearchs.getUserSearchs(null, 'tablePrefs');
		savedSearchs.scannerSearch(newSetting, true);
	};

	const loadImages = (queryId, longShort) => {
		if (queryId === 'TAXAS') {
			return taxa;
		}
		if (queryId === 'TRAVAS') {
			if (longShort === 'Long') {
				return travaLong;
			}
			return trava;
		}
		if (queryId === 'BORBOLETAS') {
			if (longShort === 'Long') {
				return borboletaLong;
			}
			return borboleta;
		}
		if (queryId === 'CONDORS') {
			if (longShort === 'Long') {
				return condorLong;
			}
			return condor;
		}
		if (queryId === 'FENCES') {
			return fence;
		}
		if (queryId === 'COLLARS') {
			return collar;
		}
	};

	const loadText = (queryId, type, buttonType) => {
		if (queryId === 'TAXAS') {
			if (type === 'Call') {
				if (buttonType) {
					return 'scanner';
				}
				return 'Venda Coberta';
			}
			if (buttonType) {
				return 'scanner';
			}
			return 'Venda de Put';
		}
		if (queryId === 'TRAVAS') {
			if (type === 'Short') {
				if (buttonType) {
					return 'danger';
				}
				return 'Baixa';
			}
			if (type === 'Long') {
				if (buttonType) {
					return 'success';
				}
				return 'Alta';
			}
		}

		if (queryId === 'BORBOLETAS' || queryId === 'CONDORS') {
			if (type === 'Short') {
				if (buttonType) {
					return 'danger';
				}
				return 'Venda';
			}
			if (type === 'Long') {
				if (buttonType) {
					return 'success';
				}
				return 'Compra';
			}
		}
		if (buttonType) {
			return 'scanner';
		}
		return type;
	};

	function handleSelectMaturityDate(date) {
		setQuickScanSetting({
			...quickScanSetting,
			expirationDate: date,
		});

		dispatch(
			setQuickSettings({
				...quickScanSetting,
				expirationDate: date,
			})
		);
	}

	function handleSelectUnderSymbol(symbols) {
		if (symbols.length > 5) {
			toast.error('Escolha até 5 papeis para realizar a busca');
			return;
		}

		setQuickScanSetting({
			...quickScanSetting,
			underSymbol: symbols,
		});

		dispatch(
			setQuickSettings({
				...quickScanSetting,
				underSymbol: symbols,
			})
		);
	}

	return (
		<>
			<ShadowGrid item container spacing={1}>
				<Grid
					item
					container
					xs={12}
					sx={{ alignItems: 'center', px: 1, gap: 2 }}
				>
					<Typography variant="h6" fontWeight="bold">
						Quick Scans
					</Typography>

					<Grid item xs={4}>
						<Autocomplete
							label="Ativos"
							size="small"
							value={quickScanSetting.underSymbol}
							options={PaperFilter}
							multiple
							disableCloseOnSelect
							getOptionLabel={option => option}
							onChange={(_, symbols) =>
								handleSelectUnderSymbol(symbols)
							}
							renderOption={(props, option, { selected }) => (
								<li {...props}>
									<Checkbox
										icon={
											<CheckBoxOutlineBlank fontSize="small" />
										}
										checkedIcon={
											<CheckBox fontSize="small" />
										}
										style={{ marginRight: 8 }}
										checked={selected}
									/>

									{option}
								</li>
							)}
							renderInput={params => (
								<TextField {...params} label="Ativos" />
							)}
						/>
					</Grid>

					<Grid item xs={2}>
						<NewSelect
							fullWidth
							size="small"
							label="Vencimentos"
							defaultValue="Todos"
							maxHeight={300}
							value={quickScanSetting.expirationDate}
						>
							{savedSearchs.dateArray.map(option => (
								<MenuItem
									key={option}
									onClick={() =>
										handleSelectMaturityDate(option)
									}
								>
									{option}
								</MenuItem>
							))}
						</NewSelect>
					</Grid>
				</Grid>

				<Grid item container gap={1}>
					{quickScanSetting.quickScans.map((item, index) => (
						<Grid item xs key={item.label}>
							<StyledCard>
								<CardHeader
									avatar={
										<Tooltip title="Executar">
											<Avatar
												onClick={() =>
													handleExecute(item)
												}
												src={loadImages(
													item.queryId,
													item.longShort
												)}
											/>
										</Tooltip>
									}
									title={
										<Typography variant="h6">
											<TitleClick
												onClick={() =>
													handleExecute(item)
												}
											>
												{item.label}
											</TitleClick>
										</Typography>
									}
									action={
										<Tooltip title="Executar">
											<IconButton
												size="small"
												onClick={() =>
													handleExecute(item)
												}
											>
												<Send />
											</IconButton>
										</Tooltip>
									}
									subheader={
										<Box display="flex" mt={1}>
											{item.optionType !== 'Todos' && (
												<Box width="100%">
													<Tooltip title="Selecione o tipo">
														<StyledGeneralButton
															style={{
																fontSize: '8px',
															}}
															buttonColor={loadText(
																item.queryId,
																item.optionType,
																true
															)}
															fullWidth
															onClick={() => {
																handleQuickButton(
																	'optionType',
																	item.optionType,
																	index
																);
															}}
														>
															{loadText(
																item.queryId,
																item.optionType,
																false
															)}
														</StyledGeneralButton>
													</Tooltip>
												</Box>
											)}
											{item.longShort !== 'Todos' && (
												<Box ml={0.5} width="100%">
													<Tooltip title="Selecione o sentido">
														<StyledGeneralButton
															style={{
																fontSize: '8px',
															}}
															buttonColor={loadText(
																item.queryId,
																item.longShort,
																true
															)}
															fullWidth
															onClick={() => {
																handleQuickButton(
																	'longShort',
																	item.longShort,
																	index
																);
															}}
														>
															{loadText(
																item.queryId,
																item.longShort,
																false
															)}
														</StyledGeneralButton>
													</Tooltip>
												</Box>
											)}
										</Box>
									}
								/>
							</StyledCard>
						</Grid>
					))}
				</Grid>
			</ShadowGrid>

			<ShadowGrid item container spacing={1} xs={12}>
				<Grid item container>
					<Typography variant="h6" fontWeight="bold">
						Minhas Pesquisas
					</Typography>

					<Typography
						variant="overline"
						color="text.secondary"
						sx={{ p: '0.15rem 0 0 1rem' }}
					>
						Máximo de 8
					</Typography>
				</Grid>

				{savedSearchs.savedSearchs.map((item, index) => (
					<Grid item xs={3} key={item.label}>
						<StyledCard>
							<CardHeader
								avatar={
									<Tooltip title="Executar">
										<Avatar
											onClick={() => {
												savedSearchs.getUserSearchs(
													index,
													'execution'
												);
												savedSearchs.setSelectedIndex(
													parseFloat(index)
												);
											}}
										>
											<Apps />
										</Avatar>
									</Tooltip>
								}
								action={
									<>
										<Tooltip title="Deletar">
											<IconButton
												size="small"
												onClick={() =>
													savedSearchs.saveSearch(
														savedSearchs.scannerValues,
														'delete',
														index
													)
												}
											>
												<Delete />
											</IconButton>
										</Tooltip>
										<Tooltip title="Editar">
											<IconButton
												size="small"
												onClick={() => {
													savedSearchs.getUserSearchs(
														index,
														'loadEdit'
													);
													savedSearchs.setSelectedIndex(
														parseFloat(index)
													);
												}}
											>
												<Edit />
											</IconButton>
										</Tooltip>
										<Tooltip title="Executar">
											<IconButton
												size="small"
												onClick={() => {
													savedSearchs.getUserSearchs(
														index,
														'execution'
													);
													savedSearchs.setSelectedIndex(
														parseFloat(index)
													);
												}}
											>
												<Send />
											</IconButton>
										</Tooltip>
									</>
								}
								title={
									<Typography variant="h6">
										<TitleClick
											onClick={() => {
												savedSearchs.getUserSearchs(
													index,
													'execution'
												);
												savedSearchs.setSelectedIndex(
													parseFloat(index)
												);
											}}
										>
											{item.label}
										</TitleClick>
									</Typography>
								}
							/>
						</StyledCard>
					</Grid>
				))}

				{savedSearchs.savedSearchs.length < 8 && (
					<Grid item xs={3}>
						<StyledCard>
							<Box textAlign="center" m={1.5}>
								<Tooltip title="Pesquisa personalizada">
									<IconButton
										size="large"
										onClick={() => {
											savedSearchs.setSelectedIndex(
												parseFloat(
													savedSearchs.allSearchs
														.length
												)
											);
											savedSearchs.setScannerValues({
												...savedSearchs.scannerValues,
												filterIsOpen: true,
												graphIsOpen: false,
												label: `Scanner_${new Date()
													.toLocaleString('pt-BR', {
														timeZone: 'UTC',
													})
													.slice(0, 10)}-${
													Math.floor(
														Math.random() * 900
													) + 1
												}`,
												scanType: 0,
												orderedByType:
													'profitProbability',
												searchLimit: 50,
												orderedBy: '-orderAsc',
												filters: [
													{
														code: 'underSymbol',
														filterValue: 0,
														conditionType: 'CND_2',
														conditionCode: '',
														conditionValue: 0,
														firstParam: [],
														secondParam: 0,
													},
												],
											});
										}}
									>
										<AddCircle fontSize="large" />
									</IconButton>
								</Tooltip>
							</Box>
						</StyledCard>
					</Grid>
				)}
			</ShadowGrid>
		</>
	);
};

export default SelectScan;
