export const ScanFilters = [
	{
		label: 'Ativo Objeto',
		conditionType: 'CND_2',
		code: 'underSymbol',
		conditionCode: '-eq',
	},
	{
		label: 'Estratégia',
		conditionType: 'CND_2',
		code: 'strategyId',
		conditionCode: '-eq',
	},
	{
		label: 'Vencimento',
		conditionType: 'CND_1',
		code: 'expirationDate',
		conditionCode: '-eq',
	},
	{
		label: 'Prob. de lucro (%)',
		conditionType: 'CND_3',
		code: 'profitProbability',
		conditionCode: '-gte,-lte',
	},
	{
		label: 'Qtde. de pernas',
		conditionType: 'CND_1',
		code: 'legs',
		conditionCode: '-eq',
	},
	{
		label: 'Strike',
		conditionType: 'CND_3',
		code: 'minPositionStrike,maxPositionStrike',
		conditionCode: '-gte,-lte',
	},
	{
		label: 'Qtde. de negócios x perna',
		conditionType: 'CND_3',
		code: 'minPositionTradeQtty,maxPositionTradeQtty',
		conditionCode: '-gte,-lte',
	},
	{
		label: 'Volume. de negócios x perna',
		conditionType: 'CND_3',
		code: 'minPositionAcumFinTradeVol,maxPositionAcumFinTradeVol',
		conditionCode: '-gte,-lte',
	},
	{
		label: 'Preço dos contratos x perna',
		conditionType: 'CND_3',
		code: 'minPositionContractPrice,maxPositionContractPrice',
		conditionCode: '-gte,-lte',
	},
	{
		label: 'Intervalo de Lucro',
		conditionType: 'CND_IL',
		code: 'profitIntInit1,profitIntEnd1',
		conditionCode: '-lte,-gte',
	},
	{
		label: 'Taxa financiamento %',
		conditionType: 'CND_3',
		code: 'exerciseYield',
		conditionCode: '-gte,-lte',
	},
	{
		label: 'Taxa proteção %',
		conditionType: 'CND_3',
		code: 'protectionRate',
		conditionCode: '-gte,-lte',
	},
];

export const ScanConditions = {
	CND_1: [
		{
			label: 'É',
			conditionCode: '-eq',
		},
	],
	CND_2: [
		{
			label: 'Qualquer um de',
			conditionCode: '-eq',
		},
	],
	CND_3: [
		{
			label: 'Entre',
			conditionCode: '-gte,-lte',
		},
		{
			label: 'Maior que',
			conditionCode: '-gt',
		},
		{
			label: 'Menor que',
			conditionCode: '-lt',
		},
	],
	CND_IL: [
		{
			label: 'Em todo o intervalo',
			conditionCode: '-lte,-gte',
		},
		{
			label: 'Dentro do intervalo',
			conditionCode: '-gte,-lte',
		},
		{
			label: 'Maior que',
			conditionCode: '-gt',
		},
		{
			label: 'Menor que',
			conditionCode: '-lt',
		},
	],
};

export const FilterSort = [
	{
		label: 'Probabilidade de lucro',
		orderedByType: 'profitProbability',
	},
	{
		label: 'Lucro máximo R$',
		orderedByType: 'maxAbsoluteProfitAmount',
	},
	{
		label: 'Tipo de estratégia',
		orderedByType: 'strategyId',
	},
	{
		label: 'Delta',
		orderedByType: 'delta',
	},
	{
		label: 'Gamma',
		orderedByType: 'gamma',
	},
	{
		label: 'Theta',
		orderedByType: 'theta',
	},
	{
		label: 'Vega',
		orderedByType: 'vega',
	},
];

export const SortOrder = [
	{
		label: 'Crescente',
		orderedBy: '-orderAsc',
	},
	{
		label: 'Decrescente',
		orderedBy: '-orderDesc',
	},
];

export const SortQuantity = [
	{
		label: 10,
		searchLimit: 10,
	},
	{
		label: 50,
		searchLimit: 50,
	},
	{
		label: 100,
		searchLimit: 100,
	},
	{
		label: 250,
		searchLimit: 250,
	},
];

export const langTypeRisk = [
	{
		label: 'Gregas',
	},
	{
		label: 'Português',
	},
];

export const PaperFilter = [
	'ABEV3',
	'AESB3',
	'ALPA4',
	'AMAR3',
	'AMER3',
	'ANIM3',
	'ARML3',
	'ASAI3',
	'AURE3',
	'AZUL4',
	'B3SA3',
	'BBAS3',
	'BBDC4',
	'BEEF3',
	'BHIA3',
	'BMGB4',
	'BOVA11',
	'BOVV11',
	'BPAC11',
	'BPAN4',
	'BRFS3',
	'BRSR6',
	'CASH3',
	'CCRO3',
	'CEAB3',
	'CMIG4',
	'CMIN3',
	'CPFE3',
	'CPLE6',
	'CRFB3',
	'CSAN3',
	'CSMG3',
	'CVCB3',
	'DXCO3',
	'ECOR3',
	'EGIE3',
	'ELET3',
	'EMBR3',
	'ENEV3',
	'ENGI11',
	'EQTL3',
	'FLRY3',
	'GFSA3',
	'GGBR4',
	'GOAU4',
	'HAPV3',
	'HYPE3',
	'IGTI11',
	'ITSA4',
	'ITUB3',
	'ITUB4',
	'JHSF3',
	'KLBN11',
	'LIGT3',
	'LJQQ3',
	'LREN3',
	'LWSA3',
	'MGLU3',
	'MILS3',
	'MOVI3',
	'MRFG3',
	'MRVE3',
	'MULT3',
	'MYPK3',
	'NEOE3',
	'ODPV3',
	'PCAR3',
	'PETR3',
	'PETR4',
	'PETZ3',
	'PNVL3',
	'POMO4',
	'POSI3',
	'PRIO3',
	'PSSA3',
	'QUAL3',
	'RADL3',
	'RAIL3',
	'RAIZ4',
	'RANI3',
	'RAPT4',
	'RDOR3',
	'RECV3',
	'RENT3',
	'ROMI3',
	'SANB11',
	'SBFG3',
	'SBSP3',
	'SEER3',
	'SIMH3',
	'SLCE3',
	'SMAL11',
	'SMFT3',
	'SMTO3',
	'STBP3',
	'SUZB3',
	'TAEE11',
	'TEND3',
	'TIMS3',
	'TOTS3',
	'TRAD3',
	'TRIS3',
	'TRPL4',
	'TUPY3',
	'UGPA3',
	'UNIP6',
	'USIM5',
	'VALE3',
	'VAMO3',
	'VBBR3',
	'VIVA3',
	'VIVT3',
	'VLID3',
	'VULC3',
	'WEGE3',
	'YDUQ3',
	'ZAMP3'
];

export const StrategySelect = [
	['ADJUSTED SHORT IRON BUTTERFLY', 1, 'ADJUSTED_SHORT_IRON_BUTTERFLY'],
	[
		'ASYMMETRIC LEFTW LONG CALL BUTTERFLY',
		2,
		'ASYMMETRIC_LEFTW_LONG_CALL_BUTTERFLY',
	],
	[
		'ASYMMETRIC LEFTW LONG PUT BUTTERFLY',
		3,
		'ASYMMETRIC_LEFTW_LONG_PUT_BUTTERFLY',
	],
	['COVERED CALL', 17, 'COVERED_CALL'],
	[
		'ASYMMETRIC LEFTW SHORT CALL BUTTERFLY',
		4,
		'ASYMMETRIC_LEFTW_SHORT_CALL_BUTTERFLY',
	],
	[
		'ASYMMETRIC LEFTW SHORT PUT BUTTERFLY',
		5,
		'ASYMMETRIC_LEFTW_SHORT_PUT_BUTTERFLY',
	],
	[
		'ASYMMETRIC RIGHTW LONG CALL BUTTERFLY',
		6,
		'ASYMMETRIC_RIGHTW_LONG_CALL_BUTTERFLY',
	],
	[
		'ASYMMETRIC RIGHTW LONG PUT BUTTERFLY',
		7,
		'ASYMMETRIC_RIGHTW_LONG_PUT_BUTTERFLY',
	],
	[
		'ASYMMETRIC RIGHTW SHORT CALL BUTTERFLY',
		8,
		'ASYMMETRIC_RIGHTW_SHORT_CALL_BUTTERFLY',
	],
	[
		'ASYMMETRIC RIGHTW SHORT PUT BUTTERFLY',
		9,
		'ASYMMETRIC_RIGHTW_SHORT_PUT_BUTTERFLY',
	],
	['TRAVA DE BAIXA', 10, 'BEAR_CALL_SPREAD'],
	['TRAVA DE BAIXA PUT', 11, 'BEAR_PUT_SPREAD'],
	['BOX', 12, 'BOX'],
	['TRAVA DE ALTA', 13, 'BULL_CALL_SPREAD'],
	['TRAVA DE ALTA PUT', 14, 'BULL_PUT_SPREAD'],
	['LONG CALL', 26, 'LONG_CALL'],
	['LONG CALL BUTTERFLY', 27, 'LONG_CALL_BUTTERFLY'],
	['LONG CALL CALENDAR SPREAD', 30, 'LONG_CALL_CALENDAR_SPREAD'],
	['LONG CALL CONDOR', 31, 'LONG_CALL_CONDOR'],
	['LONG CALL DIAGONAL SPREAD', 32, 'LONG_CALL_DIAGONAL_SPREAD'],
	['LONG CALL LADDER', 33, 'LONG_CALL_LADDER'],
	['LONG CALL SEAGULL', 34, 'LONG_CALL_SEAGULL'],
	['LONG CALL SYNTHETIC STRADDLE', 35, 'LONG_CALL_SYNTHETIC_STRADDLE'],
	['LONG CALL SYNTHETIC STRANGLE', 36, 'LONG_CALL_SYNTHETIC_STRANGLE'],
	['LONG CART WHEEL', 37, 'LONG_CART_WHEEL'],
	['LONG COLLAR', 38, 'LONG_COLLAR'],
	['LONG COMBO', 39, 'LONG_COMBO'],
	['LONG DOUBLE CALENDAR SPREAD', 41, 'LONG_DOUBLE_CALENDAR_SPREAD'],
	['LONG FENCE', 42, 'LONG_FENCE'],
	['LONG GUT IRON BUTTERFLY', 43, 'LONG_GUT_IRON_BUTTERFLY'],
	['LONG IRON BUTTERFLY', 44, 'LONG_IRON_BUTTERFLY'],
	['LONG PUT', 45, 'LONG_PUT'],
	['LONG PUT BUTTERFLY', 46, 'LONG_PUT_BUTTERFLY'],
	['LONG PUT CALENDAR SPREAD', 49, 'LONG_PUT_CALENDAR_SPREAD'],
	['LONG PUT CONDOR', 50, 'LONG_PUT_CONDOR'],
	['LONG PUT DIAGONAL SPREAD', 51, 'LONG_PUT_DIAGONAL_SPREAD'],
	['LONG PUT SYNTHETIC', 52, 'LONG_PUT_SYNTHETIC_'],
	['LONG STOCK', 53, 'LONG_STOCK'],
	['LONG STRADDLE', 54, 'LONG_STRADDLE'],
	['LONG STRANGLE', 55, 'LONG_STRANGLE'],
	['LONG STRAP', 56, 'LONG_STRAP'],
	['LONG STRIP', 57, 'LONG_STRIP'],
	['LONG WRANGLE', 58, 'LONG_WRANGLE'],
	[
		'MODIFIED LEFTW LONG CALL BUTTERFLY',
		59,
		'MODIFIED_LEFTW_LONG_CALL_BUTTERFLY',
	],
	[
		'MODIFIED LEFTW LONG PUT BUTTERFLY',
		60,
		'MODIFIED_LEFTW_LONG_PUT_BUTTERFLY',
	],
	[
		'MODIFIED LEFTW SHORT CALL BUTTERFLY',
		61,
		'MODIFIED_LEFTW_SHORT_CALL_BUTTERFLY',
	],
	[
		'MODIFIED LEFTW SHORT PUT BUTTERFLY',
		62,
		'MODIFIED_LEFTW_SHORT_PUT_BUTTERFLY',
	],
	[
		'MODIFIED RIGHTW LONG CALL BUTTERFLY',
		63,
		'MODIFIED_RIGHTW_LONG_CALL_BUTTERFLY',
	],
	[
		'MODIFIED RIGHTW LONG PUT BUTTERFLY',
		64,
		'MODIFIED_RIGHTW_LONG_PUT_BUTTERFLY',
	],
	[
		'MODIFIED RIGHTW SHORT CALL BUTTERFLY',
		65,
		'MODIFIED_RIGHTW_SHORT_CALL_BUTTERFLY',
	],
	[
		'MODIFIED RIGHTW SHORT PUT BUTTERFLY',
		66,
		'MODIFIED_RIGHTW_SHORT_PUT_BUTTERFLY',
	],
	['RATIO LEFTW LONG PUT BUTTERFLY', 69, 'RATIO_LEFTW_LONG_PUT_BUTTERFLY'],
	[
		'RATIO RIGHTW LONG CALL BUTTERFLY',
		70,
		'RATIO_RIGHTW_LONG_CALL_BUTTERFLY',
	],
	['SEAGULL CALL RATIO BACKSPREAD', 71, 'SEAGULL_CALL_RATIO_BACKSPREAD'],
	['SEAGULL CALL RATIO SPREAD', 72, 'SEAGULL_CALL_RATIO_SPREAD'],
	['SEAGULL PUT RATIO BACKSPREAD', 73, 'SEAGULL_PUT_RATIO_BACKSPREAD'],
	['SEAGULL PUT RATIO SPREAD', 74, 'SEAGULL_PUT_RATIO_SPREAD'],
	['SHORT CALL', 76, 'SHORT_CALL'],
	['SHORT CALL BUTTERFLY', 77, 'SHORT_CALL_BUTTERFLY'],
	['SHORT CALL CALENDAR SPREAD', 80, 'SHORT_CALL_CALENDAR_SPREAD'],
	['SHORT CALL CONDOR', 81, 'SHORT_CALL_CONDOR'],
	['SHORT CALL DIAGONAL SPREAD', 82, 'SHORT_CALL_DIAGONAL_SPREAD'],
	['SHORT CALL LADDER', 83, 'SHORT_CALL_LADDER'],
	['SHORT CALL SEAGULL', 84, 'SHORT_CALL_SEAGULL'],
	['SHORT CALL SYNTHETIC STRADDLE', 85, 'SHORT_CALL_SYNTHETIC_STRADDLE'],
	['SHORT CALL SYNTHETIC STRANGLE', 88, 'SHORT_CALL_SYNTHETIC_STRANGLE'],
	['SHORT CART WHEEL', 89, 'SHORT_CART_WHEEL'],
	['SHORT COLLAR', 90, 'SHORT_COLLAR'],
	['SHORT COMBO', 91, 'SHORT_COMBO'],
	['SHORT GUT IRON BUTTERFLY', 92, 'SHORT_GUT_IRON_BUTTERFLY'],
	['SHORT IRON BUTTERFLY', 93, 'SHORT_IRON_BUTTERFLY'],
	['SHORT PUT', 94, 'SHORT_PUT'],
	['SHORT PUT BUTTERFLY', 95, 'SHORT_PUT_BUTTERFLY'],
	['SHORT PUT CALENDAR SPREAD', 98, 'SHORT_PUT_CALENDAR_SPREAD'],
	['SHORT PUT CONDOR', 99, 'SHORT_PUT_CONDOR'],
	['SHORT STOCK', 100, 'SHORT_STOCK'],
	['SHORT STRADDLE', 101, 'SHORT_STRADDLE'],
	['SHORT STRANGLE', 102, 'SHORT_STRANGLE'],
	['SHORT STRAP', 103, 'SHORT_STRAP'],
	['SHORT STRIP', 104, 'SHORT_STRIP'],
	['SHORT WRANGLE', 105, 'SHORT_WRANGLE'],
	['CALL BACK SPREAD 1x2', 106, 'CALL_BACK_SPREAD_1x2'],
	['CALL RATIO SPREAD 1x2', 107, 'CALL_RATIO_SPREAD_1x2'],
	['CALL BACK SPREAD 1x3', 108, 'CALL_BACK_SPREAD_1x3'],
	['CALL RATIO SPREAD 1x3', 109, 'CALL_RATIO_SPREAD_1x3'],
	['LONG CALL SYNTHETIC', 110, 'LONG_CALL_SYNTHETIC'],
	['SHORT CALL SYNTHETIC', 111, 'SHORT_CALL_SYNTHETIC'],
	['SHORT PUT SYNTHETIC', 112, 'SHORT_PUT_SYNTHETIC'],
	/* ['BOX 3P DOADOR', 113, 'BOX_3P_DOADOR'],
	['BOX 3P TOMADOR', 114, 'BOX_3P_TOMADOR'], */
];
