import { RecurrenceActionTypes } from './actionTypes';

export const recurrenceInitialState = {
	rows: [],
	searchForm: {
		freqType: 'all',
		account: 'all',
		status: 1,
		strategyCode: 'all',
	},
	paginationInfo: {
		page: 0,
		limit: 26,
		num_records: 0,
		total: 50,
		offset: 0,
	},
	preferences: {
		density: 'standard',
		columns: {
			columnVisibilityModel: {
				Id: false,
			},
			orderedFields: [
				'__tree_data_group__',
				'Name',
				'StrategyCode',
				'Status',
				'Message',
				'FreqType',
				'CreateDate',
				'ActiveStartDate',
				'EndEvent',
				'Account',
				'Id',
			],
		},
	},
};

export function recurrence(state = recurrenceInitialState, { type, payload }) {
	switch (type) {
		case RecurrenceActionTypes.SET_RECURRENCE_ROWS:
			return {
				...state,
				rows: payload.rows.map(item => ({ ...item })),
			};
		case RecurrenceActionTypes.UPDATE_RECURRENCE_SEARCH_FORM:
			return {
				...state,
				searchForm: {
					...state.searchForm,
					[payload.field]: payload.value,
				},
			};
		case RecurrenceActionTypes.UPDATE_RECURRENCE_PAGINATION_INFO:
			return {
				...state,
				paginationInfo: {
					...state.paginationInfo,
					...payload.newPaginationInfo,
				},
			};
		case RecurrenceActionTypes.RESET_SEARCH_FORM:
			return {
				...state,
				searchForm: recurrenceInitialState.searchForm,
			};
		case RecurrenceActionTypes.UPDATE_RECURRENCE_PREFERENCES:
			return {
				...state,
				preferences: {
					...state.preferences,
					...payload.newPreferences,
				},
			};
		case RecurrenceActionTypes.RESET_STATE:
			return recurrenceInitialState;
		default:
			return state;
	}
}
