import { toast } from 'react-toastify';

const cvvolClear = (bullet, setAccessorInfos, account) => {
	const hash = { ...bullet.content };
	const strategyInfos = { ...bullet.StrategyInfos };

	if (hash.StrategyLegs[0].LegMaxClipSize > hash.StrategyLegs[0].LegQuantity) {
		toast.error('Clip não pode ser maior que a quantidade');
		return;
	}
	if (bullet.content.CustomParameters.DeltaType !== 2
         && !hash.CustomParameters.DeltaFixedValue) {
		toast.error('Preencha o campo delta');
		return;
	}

	if (hash.StrategyLegs.some(e => e.LegQuantity <= 0)) {
		return { errorMessage: 'A quantidade precisa ser maior que 0' };
	}

	hash.StrategyLegs.forEach((leg, index) => {
		hash.StrategyLegs[index] = {
			ILegAllocAccount: account(),
			LegSymbol: hash.StrategyLegs[index].LegSymbol,
			LegSecurityExchange: strategyInfos[index].subExchange
				? strategyInfos[index].subExchange
				: 'XBSP',
			LegSide: hash.StrategyLegs[index].LegSide,
			LegQuantity: parseInt(hash.StrategyLegs[index].LegQuantity, 10),
			LegResting: hash.StrategyLegs[index].LegResting,
			LegMaxClipSize: parseInt(
				hash.StrategyLegs[index].LegMaxClipSize,
				10,
			),
			LegFirstTimeOut: hash.StrategyLegs[index].LegFirstTimeOut,
			LegSecondTimeOut: hash.StrategyLegs[index].LegSecondTimeOut,
			LegTimeInForce: 0,
			LegRatioQuantity: 8,
		};
	});
	hash.CustomParameters = {
		BookDepth: bullet.content.CustomParameters.BookDepth,
		BypassFirewall: bullet.content.CustomParameters.BypassFirewall,
		DaysToExpiration: bullet.content.CustomParameters.DaysToExpiration,
		DeltaFixedValue: bullet.content.CustomParameters.DeltaFixedValue,
		DeltaType: parseInt(bullet.content.CustomParameters.DeltaType),
		ExecStrategy: parseInt(bullet.content.CustomParameters.ExecStrategy),
		ExecutionLimit: bullet.content.CustomParameters.ExecutionLimit,
		InitSuspended: bullet.content.CustomParameters.InitSuspended,
		InterestRate: bullet.content.CustomParameters.InterestRate,
		Trigger: bullet.content.CustomParameters.Trigger,
		TriggerValue: bullet.content.CustomParameters.TriggerValue,
	};

	delete hash.signature;
	delete hash.clean;
	hash.StrategyLegs = setAccessorInfos(hash.StrategyLegs);

	const cleanedHash = {
		'Name': hash.Name,
		'InitTime': hash.InitTime,
		'EndTime': hash.EndTime,
		'ExpireDate': hash.ExpireDate,
		'Text': hash.Text,
		'BasketId': hash.BasketId,
		'StrategyCode': hash.StrategyCode,
		'CustomParameters': hash.CustomParameters,
		'StrategyLegs': hash.StrategyLegs,
		'Signature': hash.Signature
	};

	if (process.env.NODE_ENV === 'development')
		console.log('cvvol submit', cleanedHash);
	return cleanedHash;
};

export default cvvolClear;
