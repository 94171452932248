import React from 'react';
import MarketMakerExecParams from '../../MaterialMarketMaker/ExecutionParams';
import MarketMakerLeg from '../../MaterialMarketMaker/Leg';

const MarketMakerBodyBullet = props => {
	const { setPaperInfo, bullet, handleChange, stepFunction, onSuggestionsFetchRequested } = props;

	return (
		<>
			<MarketMakerLeg
				setPaperInfo={setPaperInfo}
				subUid={bullet.subUid}
				stepFunction={stepFunction}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				handleChange={handleChange}
			/>
			<MarketMakerExecParams handleChange={handleChange} stepFunction={stepFunction} />
		</>
	);
};

export default MarketMakerBodyBullet;
