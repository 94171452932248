import { Button, TableCell } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	color: ${props => props.theme.colors.primary};
	border: 1px solid #3333;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	font-size: ${props => props.theme.font.size};
	font-family: Roboto !important;
	border-radius: 0px 0px 4px 4px;
`;

export const WidgetHeader = styled.div`
	font-size: 10.5px;
	background-color: #979797;
	height: 16px;
	color: #fff;
	font-weight: bold;
	display: block;
	align-items: center;
	padding-left: 2px;
`;

export const WidgetContent = styled.div`
	margin: 8px;

	.MuiTableCell-head,
	.MuiTableCell-body {
		font-size: 8px;
	}

	.MuiTableCell-root {
		padding: 0;
	}
`;

export const WidgetFooter = styled.div`
	display: flex;
	justify-content: space-between;
	padding-left: 10px;
`;

export const SearchBar = styled.div`
	display: flex;
	justify-content: space-around;
	padding: 2px;
`;

export const Title = styled.div`
	width: 100%;

	input {
		width: 90px;
		font-size: 12px;
		color: ${props => props.theme.colors.inputColor};
		height: 20px;
		margin-right: 10px;
		border: 1px solid ${props => props.theme.colors.inputBorder};
		background-color: ${props => props.theme.colors.inputBg};
	}
`;

export const ActionButton = styled(Button)`
	font-size: 8px !important;
	padding: 0;

	&:hover {
		opacity: 0.5;
	}
`;

export const TextPercent = styled.span`
	font-size: 14px;
	color: #d9911b;
	font-family: 'Roboto' !important;
	font-weight: bold;
	text-align: center;
	margin-right: 6px;
`;

export const ResultDiv = styled.div`
	margin-top: -5px;
	padding-left: 2px;
`;

export const TitleLine = styled.td`
	padding-top: 4px;
	font-size: 10px;
`;

export const ResultLine = styled.td`
	color: #fff;
	padding-top: 4px;
	font-size: 10px;
`;

export const PriceVariation = styled.span`
	top: 355px;
	height: 10px;
	text-align: center;
	font: normal normal normal 10px Roboto;
	letter-spacing: 0px;
	text-transform: capitalize;
	opacity: 1;
`;

export const ContentButton = styled.div`
	height: 18px;
	bottom: 0;
	position: absolute;
	display: flex;
	width: 50%;

	button {
		cursor: pointer;
		width: 50%;
		height: 100%;
		color: #ccc;
		border: none;
		display: flex;
		align-items: 'center';
		padding-left: 4px !important;
		padding-top: 2px !important;
	}

	.widgetButtonInactive {
		background-color: #888888;
		color: #cccccc;
	}

	.widgetButtonActive {
		background: #1976d2;
		color: #ffffff;
	}
`;

export const GridDiv = styled.div`
	display: grid;
`;

export const WidgetButton = styled.div`
	border-radius: 3px;
	background-color: unset;
	color: ${props => props.color};
	padding: 4px 8px;
	margin-top: 4px;
	border: 0;
	text-align: center;
	text-transform: uppercase;
	cursor: pointer;
	transition: 0.5s;
	:hover {
		background-color: #007aec17;
		transition: 0.5s;
	}
`;
export const ButtonsLine = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const StyledTableCell = styled(TableCell)`
	color: #fff !important;
	font-size: 9px !important;
	border-bottom: none !important;
	padding: 2px !important;
	cursor: ${props => props.cursor};
	transition: 0.5s;
	:hover {
		transition: 0.5s;
		background-color: #5985ab !important;
	}
`;
