export const strategiesType = {
	Singles: [
		{
			name: 'SingleOrder',
			code: 'sorder',
		},
		{
			name: 'Sniper',
			code: 'sniper',
		},
		{
			name: 'BestOffer',
			code: 'boffer',
		},
		{
			name: 'MIT',
			code: 'mit',
		},
		{
			name: 'FlexOrder',
			code: 'fxorder',
		},
	],
	Participativas: [
		{
			name: 'POV',
			code: 'pov',
		},
		{
			name: 'TWAP',
			code: 'twap',
		},
		{
			name: 'VWAP',
			code: 'vwap',
		},
		{
			name: 'Iceberg',
			code: 'iceberg',
		},
	],
	Spreads: [
		{
			name: 'Spread',
			code: 'spread',
		},
		{
			name: 'Spread3p',
			code: 'spread3p',
		},
		{
			name: 'Spread4p',
			code: 'spread4p',
		},
		{
			name: 'Spread5p',
			code: 'spread5p',
		},
		{
			name: 'Spread6p',
			code: 'spread6p',
		},
		{
			name: 'Financiamento',
			code: 'financ',
		},
	],
	Volatilidade: [
		{
			name: 'C/V Volatilidade',
			code: 'cvvol',
		},
		{
			name: 'Skew',
			code: 'skew',
		},
	],
	Gradientes: [
		{
			name: 'Gradiente Linear',
			code: 'grdlin',
		},
		{
			name: 'Gradiente Dinâmico',
			code: 'grddin',
		},
	],
	Customs: [
		{
			name: 'Spreadunits',
			code: 'spreadunits',
		},
	],
	Outras: [
		{
			name: 'Long&short',
			code: 'lgshort',
		},
		{
			name: 'Cateira',
			code: 'portbalance',
		},
		{
			name: 'MarketMaker',
			code: 'marketmaker',
		},
		{
			name: 'Arbitrgem Int. Frac.',
			code: 'arbintf',
		},
	],
};
