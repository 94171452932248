import styled, { css } from 'styled-components';

import { Tab, colors } from '@mui/material';

export const StyledTab = styled(Tab)`
	color: ${props => (props.selected ? '#1976d2' : '#d3d3d3')} !important;
	min-height: 50px !important;

	svg {
		transition: 0.2s !important;
	}

	.close-tab-button:hover {
		color: #d32f2f !important;
	}

	.beta-badge {
		color: #fff !important;
		background: ${props =>
		props.isInactive ? colors.grey[700] : colors.red[800]};
		padding: 3px 4px;
		border-radius: 5px;
		font-size: 0.8rem;
		margin-left: 8px;
		opacity: ${props => (props.isInactive ? 0.7 : 1)};
	}

	${props =>
		props.name === 'create-button' &&
		css`
			border-right: 1px solid #4e4e4e !important;
			color: #fff !important;
		`}
`;
