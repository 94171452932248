import styled from 'styled-components';
import themes from 'themes';

import { Button } from '@material-ui/core';

const oramaColors = themes.orama.colors;

export const StyledButton = styled(Button)`
	background-color: ${props => props.backgroundcolor} !important;
	color: ${props => (props.color ? props.color : oramaColors.white)} !important;
	width: ${props => (props.width ? props.width : '350px')};
	height: 50px;
	font-weight: bold !important;
	font-size: 14px !important;
	transition: 0.3s;
	border-radius: 25px !important;
	font-family: ${props => (props.fontFamily ? props.fontFamily : 'Roboto')} !important;
	text-transform: none !important;

	&:hover {
		opacity: 0.9;
	}
`;
