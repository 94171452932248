import { sub } from 'date-fns';
import { HistoryActionTypes } from './actionTypes';

const yesterday = sub(new Date(), { days: 1 });

export const historyInitialState = {
	rows: [],
	searchForm: {
		name: '',
		order_status: 'all',
		account: 'all',
		ClOrdID: '',
		strategycode: 'all',
		transect_date: [yesterday, yesterday],
		total: null,
	},
	paginationInfo: {
		page: 0,
		limit: 26,
		num_records: 0,
		total: 50,
		offset: 0,
	},
	tablePreferences: {
		density: 'standard',
		filter: {
			filterModel: {
				items: [],
				linkOperator: 'and',
				quickFilterValues: [],
				quickFilterLogicOperator: 'and',
			},
		},
		sorting: {
			sortModel: [
				{
					field: 'createdAt',
					sort: 'desc',
				},
			],
		},
		columns: {
			orderedFields: [
				'__tree_data_group__',
				'account',
				'name',
				'details',
				'execPercentage',
				'totalQuantity',
				'execQuantity',
				'objective',
				'averagePrice',
				'status',
				'createdAt',
				'strategyId',
				'ClOrdID',
				'updatedAt',
			],
			columnsSize: [],
		},
	},
};

export function history(state = historyInitialState, { type, payload }) {
	switch (type) {
		case HistoryActionTypes.SET_ROWS:
			return {
				...state,
				rows: payload.rows.map(item => ({ ...item })),
			};
		case HistoryActionTypes.UPDATE_FORM_VALUES:
			return {
				...state,
				searchForm: {
					...state.searchForm,
					[payload.field]: payload.value,
				},
			};
		case HistoryActionTypes.CLEAR_FORM_VALUES:
			return {
				...state,
				searchForm: historyInitialState.searchForm,
			};
		case HistoryActionTypes.UPDATE_PAGINATION_INFO:
			return {
				...state,
				paginationInfo: {
					...state.paginationInfo,
					...payload.paginationInfo,
				},
			};
		case HistoryActionTypes.UPDATE_USER_PREFERENCES:
			return {
				...state,
				tablePreferences: {
					...state.tablePreferences,
					...payload.userPreferences,
				},
			};
		default:
			return state;
	}
}
