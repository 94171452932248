import React from 'react';

export const LeftDashboard = props => {
	const { fill } = props;
	return (
		<svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill={fill}
				d="M26.6667 0H3.33333C1.5 0 0 1.5 0 3.33333V26.6667C0 28.5 1.5 30 3.33333 30H26.6667C28.5 30 30 28.5 30 26.6667V3.33333C30 1.5 28.5 0 26.6667 0ZM10 23.3333H6.66667V11.6667H10V23.3333ZM16.6667 23.3333H13.3333V6.66667H16.6667V23.3333ZM23.3333 23.3333H20V16.6667H23.3333V23.3333Z"
			/>
		</svg>
	);
}; // #CCCCCC

export const LeftMonitoring = props => {
	const { fill } = props;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.2" viewBox="0 0 20 16.2">
			<g transform="translate(-9 -6)">
				<path d="M10.6,12.5a1.6,1.6,0,1,0,1.6,1.6h0a1.58,1.58,0,0,0-1.6-1.6Z" fill={fill} />
				<path d="M10.6,6a1.6,1.6,0,1,0,1.6,1.6h0A1.58,1.58,0,0,0,10.6,6Z" fill={fill} />
				<path d="M10.6,19a1.6,1.6,0,1,0,1.6,1.6h0A1.58,1.58,0,0,0,10.6,19Z" fill={fill} />
				<path d="M14.8,21.7H28.1a.9.9,0,0,0,.9-.9v-.4a.9.9,0,0,0-.9-.9H14.8a.9.9,0,0,0-.9.9v.4A.9.9,0,0,0,14.8,21.7Z" fill={fill} />
				<path d="M14.8,15.2H28.1a.9.9,0,0,0,.9-.9v-.4a.9.9,0,0,0-.9-.9H14.8a.9.9,0,0,0-.9.9v.4A.9.9,0,0,0,14.8,15.2Z" fill={fill} />
				<path d="M14.8,8.7H28.1a.9.9,0,0,0,.9-.9V7.4a.9.9,0,0,0-.9-.9H14.8a.9.9,0,0,0-.9.9v.4A.9.9,0,0,0,14.8,8.7Z" fill={fill} />
			</g>
		</svg>
	);
};

export const LeftSimulator = props => {
	const { fill } = props;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="17.17" height="20.176" viewBox="0 0 17.17 20.176">
			<g transform="translate(-1515.6 -712.7)">
				<path
					d="M1529.569,785H1516.09a.492.492,0,0,0-.49.49h0v16.489a.492.492,0,0,0,.49.49h13.478a.492.492,0,0,0,.49-.49V785.49h0A.49.49,0,0,0,1529.569,785Zm-.49.981v6.361h-2.523l-1.366-2.37a.577.577,0,0,0-.689-.255.558.558,0,0,0-.311.24l-3.845,6.024-1.411-3.272a.563.563,0,0,0-.131-.187.577.577,0,0,0-.416-.18h-1.8v-6.361Zm-12.494,15.508v-8h1.43l1.674,3.879a.571.571,0,0,0,.243.322h0a.573.573,0,0,0,.794-.176l3.935-6.163,1.071,1.853a.577.577,0,0,0,.547.285h2.8v8Z"
					transform="translate(0 -69.593)"
					fill={fill}
				/>
				<path
					d="M1601.969,712.7H1588.49a.492.492,0,0,0-.49.49V715.4h.981v-1.719h12.5v15.508h-1.726v.981h2.217a.492.492,0,0,0,.49-.491V713.19A.492.492,0,0,0,1601.969,712.7Z"
					transform="translate(-69.689)"
					fill={fill}
				/>
			</g>
		</svg>
	);
};

export const TemporaryArrows = props => {
	const { fill } = props;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.667" viewBox="0 0 20 16.667">
			<g transform="translate(0 -42.666)">
				<g transform="translate(0 42.666)">
					<g transform="translate(0 0)">
						<path
							d="M18.333,45.166H14.167v-.833A1.668,1.668,0,0,0,12.5,42.666h-5a1.668,1.668,0,0,0-1.667,1.667v.833H1.667A1.668,1.668,0,0,0,0,46.833v2.5A1.668,1.668,0,0,0,1.667,51H8.333v-.417a.416.416,0,0,1,.417-.417h2.5a.416.416,0,0,1,.417.417V51h6.667A1.668,1.668,0,0,0,20,49.333v-2.5A1.668,1.668,0,0,0,18.333,45.166Zm-5.833,0h-5v-.833h5Z"
							transform="translate(0 -42.666)"
							fill={fill}
						/>
						<path
							d="M19.769,266.427a.417.417,0,0,0-.437.041,1.645,1.645,0,0,1-1,.343H11.667v1.25a.416.416,0,0,1-.417.417H8.75a.416.416,0,0,1-.417-.417v-1.25H1.667a1.645,1.645,0,0,1-1-.343A.416.416,0,0,0,0,266.8v5.844a1.668,1.668,0,0,0,1.667,1.667H18.333A1.668,1.668,0,0,0,20,272.645V266.8A.417.417,0,0,0,19.769,266.427Z"
							transform="translate(0 -257.645)"
							fill={fill}
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export const OptionTrader = props => {
	const { fill } = props;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20.5" height="21.051" viewBox="0 0 20.5 21.051">
			<g transform="translate(0.1 -0.225)">
				<path
					d="M-9347.4-9747.5h-7.5a.216.216,0,0,1-.2-.2v-5.6a.134.134,0,0,1,.05-.1.128.128,0,0,0,.051-.1l5.6-5.2c0-.048.022-.075.063-.075a.22.22,0,0,1,.139.075l5.4,5.189v3.61h-3.4a.216.216,0,0,0-.2.2v2.2Zm-1.991-8a2.062,2.062,0,0,0-1.107.3,1.945,1.945,0,0,0-.734.848,3.029,3.029,0,0,0-.255,1.278v.247a2.638,2.638,0,0,0,.566,1.8,1.953,1.953,0,0,0,1.545.64,2.473,2.473,0,0,0,1.056-.218,1.7,1.7,0,0,0,.72-.617,1.846,1.846,0,0,0,.282-.919h-1.193a.844.844,0,0,1-.232.62.933.933,0,0,1-.632.193.728.728,0,0,1-.69-.343,2.382,2.382,0,0,1-.2-1.137v-.353a2.141,2.141,0,0,1,.218-1.07.714.714,0,0,1,.656-.333.947.947,0,0,1,.656.2.868.868,0,0,1,.228.651h1.2a1.9,1.9,0,0,0-.625-1.312A2.155,2.155,0,0,0-9349.391-9755.5Z"
					transform="translate(9355 9759)"
					fill={fill}
				/>
				<path
					d="M-9349.362-9747.4a.211.211,0,0,1-.137-.075l-5.5-5.2c-.1-.1-.1-.1-.1-.2v-5.6c0-.207.1-.3.2-.3h11c.1,0,.2.094.2.3v5.6c0,.1,0,.2-.1.2l-5.5,5.2C-9349.3-9747.428-9349.322-9747.4-9349.362-9747.4Zm-1.477-9.6v4.978h1.2v-1.687h.767a2.17,2.17,0,0,0,1.428-.437,1.449,1.449,0,0,0,.521-1.163,1.643,1.643,0,0,0-.24-.882,1.6,1.6,0,0,0-.681-.6,2.32,2.32,0,0,0-1.009-.211Zm1.986,2.364h-.786v-1.436h.8a.661.661,0,0,1,.515.211.8.8,0,0,1,.187.556.638.638,0,0,1-.182.492A.744.744,0,0,1-9348.853-9754.634Z"
					transform="translate(9364.1 9768.676)"
					fill={fill}
				/>
				<rect width="4.8" height="6" transform="translate(3.3 3.3)" fill="none" />
				<rect width="4.7" height="6" transform="translate(12.9 11.4)" fill="none" />
			</g>
		</svg>
	);
};
export const MarketIcon = props => {
	const { fill } = props;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="20.043" viewBox="0 0 18 20.043">
			<g transform="translate(-53)">
				<path
					d="M67.665,7.941V18.853H54.186V1.186H59.6c0-.16.012-.321.012-.481a4.839,4.839,0,0,1,.027-.587c0-.008,0-.016,0-.023,0,.02,0,.043-.008.063,0-.023,0-.047.008-.067L59.626,0H53.595A.6.6,0,0,0,53,.595V19.448a.6.6,0,0,0,.595.595H68.26a.6.6,0,0,0,.595-.595V7.9C68.456,7.906,68.061,7.922,67.665,7.941Z"
					fill={fill}
				/>
				<path d="M137.895,204.99h4.035a.595.595,0,1,0,0-1.19h-4.035a.595.595,0,1,0,0,1.19Z" transform="translate(-81.001 -195.823)" fill={fill} />
				<path d="M145.316,264.6h-7.421a.595.595,0,0,0,0,1.19h7.421a.595.595,0,0,0,0-1.19Z" transform="translate(-81.001 -254.244)" fill={fill} />
				<path d="M145.316,325.5h-7.421a.595.595,0,0,0,0,1.19h7.421a.595.595,0,0,0,0-1.19Z" transform="translate(-81.001 -312.76)" fill={fill} />
				<path d="M145.316,386.2h-7.421a.595.595,0,1,0,0,1.19h7.421a.595.595,0,1,0,0-1.19Z" transform="translate(-81.001 -371.085)" fill={fill} />
				<g transform="translate(58.382)">
					<path
						d="M202.95,3.546a7.09,7.09,0,0,0-12.282,0l-.168.29.168.29a7.09,7.09,0,0,0,12.282,0l.168-.29ZM196.872,5.9a2.25,2.25,0,1,1,2.25-2.25A2.253,2.253,0,0,1,196.872,5.9Zm-5.018-2.063a5.906,5.906,0,0,1,2.09-1.93,3.384,3.384,0,0,0,.454,4.086A5.906,5.906,0,0,1,191.854,3.836Zm7.585,2.055a3.393,3.393,0,0,0,.431-3.867,5.949,5.949,0,0,1,1.894,1.816A5.955,5.955,0,0,1,199.439,5.89ZM197.756,3.6a.947.947,0,1,1-.947-.947A.948.948,0,0,1,197.756,3.6Z"
						transform="translate(-190.5)"
						fill={fill}
					/>
				</g>
			</g>
		</svg>
	);
};

export const TemporaryGraph = props => {
	const { fill } = props;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17">
			<g transform="translate(-895 -529)">
				<path d="M2.5,12.5H4.6L9.1,1.6,14,12.5h2.4v1.1H13.3l-.2-.3L9.2,4.9,5.6,13.3l-.2.3H2.5Z" transform="translate(895 528.9)" fill={fill} />
				<path
					d="M17.5,16.1H1V.6A.472.472,0,0,0,.5.1.472.472,0,0,0,0,.6v16a.472.472,0,0,0,.5.5h17a.5.5,0,0,0,0-1Z"
					transform="translate(895 528.9)"
					fill={fill}
				/>
			</g>
		</svg>
	);
};

export const ScannerIcon = props => {
	const { fill } = props;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="19.25" height="19.257" viewBox="0 0 19.25 19.257">
			<g id="radar" transform="translate(-0.087 0)">
				<g id="Grupo_1790" data-name="Grupo 1790" transform="translate(0.087 0)">
					<path
						id="Caminho_418"
						data-name="Caminho 418"
						d="M16.534,2.8A9.7,9.7,0,0,0,4.126,1.771,1.668,1.668,0,0,0,1.839,4.066,9.642,9.642,0,0,0,15.274,17.5a1.685,1.685,0,0,0,2.312-2.314A9.7,9.7,0,0,0,16.534,2.8Zm-7.387,15.3A8.445,8.445,0,0,1,1.23,10.19H3.506a6.331,6.331,0,0,0,.78,2.511,1.692,1.692,0,0,0,2.346,2.355,6.324,6.324,0,0,0,2.516.771v2.28Zm0-3.413A5.218,5.218,0,0,1,7.3,14.133a1.665,1.665,0,0,0-.419-1.676,1.711,1.711,0,0,0-1.661-.393,5.218,5.218,0,0,1-.577-1.874h3.48a1.691,1.691,0,0,0,1.025,1.025V14.7Zm0-6.659A1.691,1.691,0,0,0,8.123,9.061H4.635a5.078,5.078,0,0,1,4.513-4.5Zm0-4.6A6.2,6.2,0,0,0,3.5,9.061H1.236A8.528,8.528,0,0,1,2.709,4.789,1.682,1.682,0,0,0,4.875,2.623,8.526,8.526,0,0,1,9.148,1.149V3.436Zm1.129,1.129A5.145,5.145,0,0,1,12.9,5.638L10.438,8.1a1.669,1.669,0,0,0-.161-.066Zm0-1.129V1.143a8.557,8.557,0,0,1,5.042,2.078L13.7,4.839A6.254,6.254,0,0,0,10.277,3.436Zm0,7.779A1.7,1.7,0,0,0,11.3,10.186h3.471a5.083,5.083,0,0,1-4.5,4.517ZM16.7,14.478a1.67,1.67,0,0,0-2.155,2.15A8.526,8.526,0,0,1,10.277,18.1v-2.29A6.146,6.146,0,0,0,15.9,10.186h2.287A8.54,8.54,0,0,1,16.7,14.478Z"
						transform="translate(-0.087 0)"
						fill={fill}
					/>
				</g>
			</g>
		</svg>
	);
};

export const LgShortIcon = props => {
	const { fill } = props;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="23" height="20.214" viewBox="0 0 23 20.214">
			<g id="Grupo_1804" data-name="Grupo 1804" transform="translate(-14 -444)">
				<g id="polygon" transform="translate(14 444)">
					<path
						id="Caminho_419"
						data-name="Caminho 419"
						d="M5.776,31,0,41.107,5.776,51.214H17.224L23,41.107,17.224,31Z"
						transform="translate(0 -31)"
						fill={fill}
					/>
				</g>
				<g id="Grupo_1797" data-name="Grupo 1797" transform="translate(19.736 447.707)">
					<text id="L" transform="translate(0.264 10.293)" fill="#101010" fontSize="9" fontFamily="OpenSans-ExtraBold, Open Sans" fontWeight="800">
						<tspan x="0" y="0">
							L
						</tspan>
					</text>
					<text id="S" transform="translate(6.264 10.293)" fill="#101010" fontSize="9" fontFamily="OpenSans-ExtraBold, Open Sans" fontWeight="800">
						<tspan x="0" y="0">
							S
						</tspan>
					</text>
				</g>
			</g>
		</svg>
	);
};

export const QuestionMarkIcon = props => {
	const { fill } = props;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="17.459" viewBox="0 0 12 17.459">
			<path
				d="M7.816,0A6.607,6.607,0,0,0,1.947,3.1a.819.819,0,0,0,.177,1.121L3.595,5.34A.818.818,0,0,0,4.729,5.2c.854-1.07,1.488-1.686,2.822-1.686C8.6,3.513,9.9,4.188,9.9,5.205c0,.769-.635,1.164-1.671,1.745C7.019,7.627,5.42,8.47,5.42,10.578v.334a.818.818,0,0,0,.818.818H8.71a.818.818,0,0,0,.818-.818v-.2c0-1.462,4.272-1.522,4.272-5.477C13.8,2.259,10.71,0,7.816,0ZM7.474,12.735A2.362,2.362,0,1,0,9.836,15.1,2.365,2.365,0,0,0,7.474,12.735Z"
				transform="translate(-1.8)"
				fill={fill}
			/>
		</svg>
	);
};

export const SaveIcon = props => {
	const { fill } = props;

	return (
		<svg width="19" height="19" viewBox="0 0 19 19" aria-hidden="true" title="Salvar">
			<path fill={fill} className="a" d="m14.98,15.66l-6.68,0a0.557,0.557 0 1 0 0,1.113l6.68,0a0.557,0.557 0 1 0 0,-1.113z" id="svg_3" />
			<path fill={fill} className="a" d="m14.98,11.207l-6.68,0a0.557,0.557 0 1 0 0,1.113l6.68,0a0.557,0.557 0 1 0 0,-1.113z" id="svg_5" />
			<path fill={fill} className="a" d="m14.98,13.434l-6.68,0a0.557,0.557 0 1 0 0,1.113l6.68,0a0.557,0.557 0 1 0 0,-1.113z" id="svg_7" />
			<rect fill={fill} className="a" width="7.222" height="4.333" y="0.482" x="4.333" id="svg_10" />
			<path
				fill={fill}
				className="a"
				d="m18.837,3.132l-2.969,-2.969a0.557,0.557 0 0 0 -0.393,-0.163l-2.075,0l0,5.381a0.557,0.557 0 0 1 -0.557,0.557l-8.909,0a0.557,0.557 0 0 1 -0.557,-0.557l0,-5.381l-2.82,0a0.557,0.557 0 0 0 -0.557,0.557l0,17.886a0.557,0.557 0 0 0 0.557,0.557l17.886,0a0.557,0.557 0 0 0 0.557,-0.557l0,-14.918a0.557,0.557 0 0 0 -0.163,-0.393zm-4.327,14.755l-10.02,0l0,-7.793l10.02,0l0,7.793z"
				id="svg_11"
			/>
		</svg>
	);
};

export const RiskIcon = props => {
	const { fill } = props;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="19.909" height="18.323" viewBox="0 0 19.909 18.323">
			<g id="barrier" transform="translate(0 -18)">
				<path fill={fill} id="Caminho_235" data-name="Caminho 235" d="M35.25,286.25h2.643v6.508H35.25Z" transform="translate(-33.697 -256.435)" />
				<path fill={fill} id="Caminho_236" data-name="Caminho 236" d="M35.25,18h2.643v2.907H35.25Z" transform="translate(-33.697)" />
				<path fill={fill} id="Caminho_237" data-name="Caminho 237" d="M356.75,286.25h2.643v6.508H356.75Z" transform="translate(-341.037 -256.435)" />
				<path fill={fill} id="Caminho_238" data-name="Caminho 238" d="M356.75,18h2.643v2.907H356.75Z" transform="translate(-341.037)" />
				<path fill={fill} id="Caminho_239" data-name="Caminho 239" d="M3.557,114,.991,120.266H0V114Z" transform="translate(0 -91.772)" />
				<path
					fill={fill}
					id="Caminho_240"
					data-name="Caminho 240"
					d="M60.683,114l-2.565,6.266h-3.2L57.485,114Z"
					transform="translate(-52.501 -91.772)"
				/>
				<path
					fill={fill}
					id="Caminho_241"
					data-name="Caminho 241"
					d="M165.683,114l-2.565,6.266h-3.2L162.485,114Z"
					transform="translate(-152.876 -91.772)"
				/>
				<path
					fill={fill}
					id="Caminho_242"
					data-name="Caminho 242"
					d="M270.683,114l-2.565,6.266h-3.2L267.485,114Z"
					transform="translate(-253.251 -91.772)"
				/>
				<path fill={fill} id="Caminho_243" data-name="Caminho 243" d="M373.535,114v6.266H369.92L372.485,114Z" transform="translate(-353.627 -91.772)" />
			</g>
		</svg>
	);
};
