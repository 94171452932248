export function mediaRowsChartData(dataChart, dataPdf) {
	const tempChartData = [...dataChart];
	const mediaRows = Math.round(tempChartData.length / 21);
	let arrayChartPay = [];
	let value;
	for (value of Array(20 - dataPdf.strategy.positions.length).keys()) {
		// TODO -> Workaround para resolver falha de lint do build
		value = value + 1;
		const valuesSplices = tempChartData.splice(0, mediaRows);
		if (valuesSplices.length > 0) {
			arrayChartPay.push(valuesSplices[0]);
		}
	}

	const strikesChart = dataChart.filter(
		chartItem =>
			dataPdf.strategy.positions.filter(
				strikes => strikes.strikePx === chartItem.underPx
			).length
	);

	strikesChart.forEach(strikes => {
		arrayChartPay.push(strikes);
	});

	dataPdf.strategy.breakEvens.forEach(breakItem => {
		arrayChartPay.push({ underPx: breakItem, px: 0, var: 0, break: true });
	});

	arrayChartPay.sort((a, b) => {
		return a.underPx < b.underPx ? -1 : a.underPx > b.underPx ? 1 : 0;
	});

	const newArrayChartPay = [];

	arrayChartPay.forEach(item => {
		var duplicated =
			newArrayChartPay.findIndex(payItem => {
				return item.underPx == payItem.underPx;
			}) > -1;

		if (!duplicated) {
			newArrayChartPay.push(item);
		}
	});

	return newArrayChartPay;
}
