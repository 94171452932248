import styled from 'styled-components';

import { Alert, Snackbar } from '@mui/material';

export const StyledSnackbar = styled(Snackbar)`
	@media (min-width: 600px) {
		width: ${props =>
		props.handleWidth ? props => props.handleWidth : ''} !important;
		height: ${props =>
		props.handleHeight ? props => props.handleHeight : ''} !important;
	}
`;

export const StyledAlert = styled(Alert)`
	color: #fff !important;

	.MuiAlert-message {
		font-size: ${props =>
		props.handleFontSize
			? props => props.handleFontSize
			: '12px'} !important;
	}
`;
