import React from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Typography
} from '@mui/material';

export const PasswordInput = ({
	id,
	label,
	value,
	error = false,
	isPasswordVisible,
	changePasswordVisibility,
	helperText = null,
	onChange,
	labelProps,
	labelStyles,
	withoutAutofill = false,
	sx,
	...rest
}) => {
	const stylesWithoutAutofill = {
		'-webkit-text-security': isPasswordVisible ? 'initial' : 'disc',
		'text-security': isPasswordVisible ? 'none' : 'disc',
	};

	const inputType = withoutAutofill ? 'text' : isPasswordVisible ? 'text' : 'password';

	return (
		<FormControl
			fullWidth
			variant="outlined"
			sx={{ '.MuiOutlinedInput-root': { boxShadow: 'none !important' } }}
		>
			<InputLabel
				htmlFor={id}
				error={error}
				sx={{ ...labelStyles }}
				{...labelProps}
			>
				{label}
			</InputLabel>
			<OutlinedInput
				sx={withoutAutofill ? stylesWithoutAutofill : { ...sx }}
				id={id}
				type={inputType}
				error={error}
				label={label}
				value={value}
				onChange={onChange}
				endAdornment={
					<InputAdornment position="end">
						<IconButton onClick={changePasswordVisibility}>
							{isPasswordVisible ? (
								<Visibility />
							) : (
								<VisibilityOff />
							)}
						</IconButton>
					</InputAdornment>
				}
				{...rest}
			/>

			{(error && helperText) && (
				<Typography
					variant="body2"
					color="error"
					sx={{ mt: 0.5 }}
				>
					{helperText}
				</Typography>
			)}
		</FormControl>
	);
};

