import numeral from 'numeral';

numeral.register('locale', 'pt-BR', {
	delimiters: {
		thousands: '.',
		decimal: ',',
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't',
	},
	currency: {
		symbol: 'R$',
	},
});

numeral.locale('pt-BR');
/**
 * function to abbreviate number
 * @param {*} number is a int value
 * @param {*} fixed  abreviation after
 */
function abbreviateNumber(number, fixed = 0) {
	const num = parseInt(number);

	if (num === null) {
		return null;
	}

	fixed = !fixed || fixed < 0 ? 0 : fixed;
	const b = num.toPrecision(2).split('e');
	const k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3);
	const c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed);
	const d = c < 0 ? c : Math.abs(c);
	const e = d + ['', ' K', ' M', ' B', ' T'][k];

	return e;
}

/**
 * function to convert string values to default format values
 *
 * @param {*} value is a value to format
 * @param {*} abrv  is a abreviation
 */
export default function numberFormat(value, decimal, abrv = true, ignoreZero = false, limitOperation = false) {
	let val = value;
	let prefix = '';
	const format = decimal ? `0.0,[${decimal}]` : '0,0';

	val = decimal ? +parseFloat(val) : parseInt(val);

	if (limitOperation) {
		return roundNumber(val);
	}

	if (val == 0 && !ignoreZero) {
		return '--';
	}

	if (Math.sign(val) < 0) {
		prefix = '-';
		val = val *= -1;
	}

	if (abrv && val > 99999.9) {
		val = abbreviateNumber(val);
	} else {
		val = numeral(val).format(format);
	}

	val = `${prefix}${val}`;

	return val;
}

export function roundNumber(number, decimals = 2) {
	if (!number && number !== 0) return;

	return parseFloat(number).toLocaleString('pt-Br', {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	});
}
