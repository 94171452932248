import React from 'react';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from '@mui/material';

const MaterialModal = ({
	title,
	text,
	onClick,
	open,
	setOpen,
	inputLabel,
	id,
	onCancel,
}) => {
	const handleClose = () => {
		setOpen(false);
		onCancel();
	};
	return (
		<>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{text}</DialogContentText>
					{id && (
						<TextField
							autoFocus
							margin="dense"
							size="small"
							id={id}
							label={inputLabel}
							variant="outlined"
							type="text"
							fullWidth
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Não
					</Button>
					<Button
						onClick={() => {
							onClick();
							handleClose();
						}}
						color="primary"
					>
						Sim
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default MaterialModal;
