import { store } from 'store';
import { apiExec } from './api';
import { updateGradientStop } from 'store/modules/idxLiveCharts/actions';

export function getGradientStops() {
	const queryParams = {
		params: {
			realtime: true,
		},
	};

	apiExec
		.get('/globalstops', queryParams)
		.then(response => {
			const globalStops = response.data.records;

			if (globalStops.length > 0) {
				store.dispatch(updateGradientStop(globalStops[0]));
			}
		})
		.catch(error => console.error(error));
}
