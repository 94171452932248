import { store } from 'store';

export function getCorrectPositionExitPx(position, exitPriceType) {
	if (position.exitPxLocked) {
		return position.exitPx;
	} else {
		const prices = {
			last: position.lastTradePx,
			medium: position.mediumPrice,
			market: position.exitPx,
		};

		return prices[exitPriceType];
	}
}

export function getCorrectStrategyExitPx(strategy) {
	const exitPriceType =
		store.getState().configs.globalSettings.userSettings.simulator
			.exitPrice;

	if (exitPriceType === 'medium') {
		strategy.positions.forEach(position => {
			position.mediumPrice = calcMidExitPx(
				position.entryPx,
				position.exitPx,
				position.exitPxLocked
			);
		});

		return strategy.positions.reduce(
			(acc, position) =>
				acc +
				(position.qtty < 0
					? Number(position.mediumPrice) * -1
					: Number(position.mediumPrice)),

			0
		);
	} else if (exitPriceType === 'last') {
		return strategy.positions.reduce(
			(acc, position) =>
				acc +
				(position.qtty < 0
					? Number(position.lastTradePx) * -1
					: Number(position.lastTradePx)),
			0
		);
	} else {
		return strategy.exitPx;
	}
}

export function calcMidExitPx(entryPx, exitPx, isExitPxLocked) {
	if (isExitPxLocked) {
		return exitPx;
	}

	if (entryPx && entryPx !== 'NaN' && exitPx && exitPx !== 'NaN') {
		return parseFloat((entryPx + exitPx) / 2).toFixed(4);
	}

	return entryPx || exitPx;
}
