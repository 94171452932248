import styled from 'styled-components';
import { PageContainer } from 'styles/global';
import { Typography, Grid, Box } from '@mui/material';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export const Container = styled(Grid)`
	background-color: #fff;
	height: 100%;
`;

export const CallToActionContainer = styled(Grid)`
	@media screen and (max-width: 900px) {
		display: none;
	}

	width: 50%;
	background: url(${props => props.backgroundImage}),
		linear-gradient(
			225.55deg,
			rgba(36, 108, 171, 0.77) 12.21%,
			#063d6d 83.68%
		);
`;

export const CallToAction = styled(PageContainer)`
	max-width: 70%;
	color: #e5e5e5;
	text-align: center;

	span {
		color: #56b0ff;
	}
`;

export const FormInstruction = styled(Typography)`
	text-align: center;
	color: #979797;
	font-size: 20px !important;

	span {
		color: red;
	}
`;

export const Title = styled.h1`
	text-align: center;
	color: #979797;
`;

export const SubTitle = styled.h3`
	text-align: justify;
	color: #979797;
`;

export const Logo = styled.img`
	display: block;
	width: 200px;
	margin: 0 auto;
`;

export const AlignForgotPassword = styled.div`
	display: flex;
	justify-content: center;
`;

export const FormChangeButton = styled.div`
	color: #0f72e2;
	text-align: center;
	margin-top: 20px;
	cursor: pointer;
	font-size: 14px !important;
	transform: 0.5s;
	&:hover {
		transform: 0.5s;
		color: #303030;
	}
`;

export const IconHelp = styled(HelpOutlineIcon)`
	margin-left: 30px;
`;

export const ContractBox = styled(Box)`
	max-height: 40vh;
	overflow-y: scroll;
	margin-left: -60px;
	margin-right: -80px;
`;
