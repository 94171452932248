import React from 'react';
import PovLeg from '../../MaterialParticipatory/MaterialPov/Leg';
import PovExecutionParams from '../../MaterialParticipatory/MaterialPov/ExecutionParams';

const PovBodyBullet = props => {
	const { bullet, setPaperInfo, handleChange, suggestions, onSuggestionsFetchRequested, stepFunction } = props;

	return (
		<>
			<PovLeg
				subUid={bullet.subUid}
				setPaperInfo={setPaperInfo}
				handleChange={handleChange}
				suggestions={suggestions}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				stepFunction={stepFunction}
			/>
			<PovExecutionParams handleChange={handleChange} stepFunction={stepFunction} />
		</>
	);
};

export default PovBodyBullet;
