import React from 'react';
import styled from 'styled-components';

import { Box, CircularProgress, Modal } from '@material-ui/core';

import theme from '../../../themes';

export const Loading = ({ open, onClose }) => {
	const Container = styled(Box)({
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100vw',
		height: '100vh',
	});

	const ModalBody = styled(Box)({
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',

		'.MuiCircularProgress-colorPrimary': {
			color: theme.orama.colors.white,
		}
	});


	return (
		<Modal
			open={open}
			onClose={onClose}
		>
			<Container>
				<ModalBody>
					<CircularProgress size={50} />
				</ModalBody>
			</Container>
		</Modal>
	);
};
