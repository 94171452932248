import React from 'react';

import {
	ContainerFooterOrama,
	ConteinerOtherPage,
	FooterOtherPage,
} from '../../style';

import {
	SignatureText,
	TextSignature,
	PowerBy,
	ImagePowerBy,
} from '../../../../style';

import powerImg from '../../../../../../assets/images/powered_by_Flexscan.png';

export const DefaultDisclaimerHeader = () => {
	return (
		<ContainerFooterOrama>
			<SignatureText color={'#485460'} size={'6px'} bold>
				GLOSSÁRIO BÁSICO:
			</SignatureText>
			<TextSignature size={'6px'}>
				- Opção de compra (Call): É a opção de compra de um ativo em uma
				data futura, a um preço pré-determinado (Comprador tem o direito
				de comprar | Vendedor tem a obrigação de vender) - Opção de
				venda (Put): É a opção de venda de um ativo em uma data futura,
				a um preço pré-determinado (Comprador tem o direito de vender |
				Vendedor tem a obrigação de comprar). - Preço de referência (S -
				Spot): Preço de referência do ativo objeto no momento da
				precificação da estratégia e/ou opção. - Strike (Preço de
				Exercício): Preço pré-determinado no qual o ativo será comprado
				ou vendido caso a opção seja exercida. - Opção no dinheiro (ATM
				- At The Money): quando o preço do ativo é igual ou está muito
				próximo do Strike. - Opção dentro do dinheiro (ITM - In The
				Money): Call: quando o preço do ativo é maior que o Strike. Put:
				quando o preço do ativo é menor que o Strike. - Opção fora do
				dinheiro (OTM - Out of The Money): Call: quando o preço do ativo
				é menor que o Strike. Put: quando o preço do ativo é maior que o
				Strike. - Payoff: O resultado no final da operação, podendo este
				variar substancialmente antes do vencimento. =={'>'} Resultado
				se Call: (( SPot - Strike) - Prêmio Opção * Juros
				Período)*contratos Resultado se Put: (( Strike - Spot) - Prêmio
				Opção * Juros Período)*contratos - Exercício (S - Strike):
				Quando o títular (comprador) da opção (Call/Put) decide exercer
				o seu direito de comprar (se for Call) ou vender (se for Put) o
				ativo objeto. Exemplo: O ativo está 10,00 e o investidor possui
				uma Call Strike 9,00, no vencimento ele irá exercer a compra do
				ativo a 9,00 e, se desejar realizar o lucro no ato, deverá
				vender o ativo à mercado ao preço de 10,00, obtendo um ganho
				bruto de 1,00. Neste cenário o lançador (vendedor) da Call
				Strike 9,00 será exercído e obrigado a vender o ativo ao preço
				de 9,00 para o títular da opção. - Prêmio (P - Premium): Preço
				que se negocia cada opção (Call/Put). - Volatilidade: É uma
				medida estatística que aponta a frequência e a intensidade das
				oscilações no preço de um ativo, em um período determinado de
				tempo. Por meio dela, o investidor pode ter uma ideia estimada
				da variação do preço de um ativo no futuro. - Comprado em
				volatilidade: Significa estar posicionado em uma operação que se
				beneficie de um aumento contínuo na volatilidade do ativo
				objeto. - Vendido em volatilidade: Significa estar posicionado
				em uma operação que se beneficie da queda contínua na
				volatilidade do ativo objeto. - Neutro em volatilidade: A
				volatilidade do ativo objeto não tem grande influência na
				operação, por isso o termo Neutro em volatilidade. - Delta Δ:
				Grega do modelo Black Scholes que define o quanto uma opção
				varia em relação à oscilação do ativo objeto. Exemplo: Se a ação
				sobe R$1,00, uma Call com Delta 50% terá prêmio acrescido em
				R$0,50; Se a ação sobe R$1,00, uma Put com Delta 50% terá
				decrescimo no prêmio de R$0,50. - Emolumentos B3: Os Emolumentos
				são as taxas cobradas pela B3 referente às transações realizadas
				no mercado. É uma taxa cobrada sobre cada operação de compra e
				venda de ativos financeiros e que serve para custear os gastos
				da bolsa envolvidos na operação. - Chamada de margem:
				Corresponde ao valor que a B3 entende como necessário para
				garantia de determinada operação. A Chamada de Margem é
				requerida diariamente pela B3 e debitada diretamente da conta do
				cliente junto à Órama. O valor da Chamada de Margem é variável e
				pode aumentar ou diminuir em função da oscilação do mercado. A
				B3 é a responsável pelo cálculo do valor, podendo a Órama, ao
				seu exclusivo critério, estabelecer exigência de garantias
				adicionais para a manutenção da operação. Para execução de
				operações estruturadas e/ou com derivativos no geral, o
				investidor deverá possuir recursos suficientes para atender à
				Chamada de Margem inicial, bem como as Chamadas de Margem
				diárias adicionais feitas pela B3 e/ou Órama, a partir de
				disponibilidades junto à Órama. Caso o cliente não possua saldo
				disponível ou ativos livres para atendimento da Chamada de
				Margem ou reforço de garantia, a operação poderá ser liquidada
				compulsoriamente e/ou sua conta junto à Órama poderá ficar
				negativa, com a incidência da multa sobre saldo devedor. A não
				regularização do saldo devedor também autoriza a liquidação
				compulsória de qualquer posição do cliente pela Órama, conforme
				disposições do Contrato de Intermediação. Alguns ativos aceitos
				em garantia são: TÍTULOS PÚBLICOS FEDERAIS, AÇÕES, CDBs, OURO e
				FINANCEIRO EM CONTA. Quanto aos títulos e ações, estes podem
				sofrer um deságio no valor de face quando alocados em garantia,
				exemplo: Valor de face em R$ 1.000,00, quando alocado, supre a
				margem de R$ 800,00 apenas, sofrendo um deságio. Quanto as ações
				e CDBs, consulte a área de Risco da Órama para saber quais são
				aceitos em margem de garantia.
			</TextSignature>
		</ContainerFooterOrama>
	);
};

export const DefaultDisclaimerFooter = ({ username }) => {
	const getInformationAnalist = username => {
		let name;
		let cvm;
		let cnpi;

		if (
			[
				'ora_mliz',
				'ora_reiji',
				'ora_oraloe',
				'ora_tteixe',
				'ora_viniciu61330',
			].includes(username)
		) {
			name = 'Ricardo Eiji Tominaga';
			cvm = '20/2021';
			cnpi = 'CNPI-T EM 2164';
		} else {
			name = 'Gabriel Montenegro de Carvalho';
			cvm = '03/2020';
			cnpi = 'CNPI EM 2236';
		}

		return { name, cvm, cnpi };
	};

	const { name, cvm, cnpi } = getInformationAnalist(username);

	return (
		<ConteinerOtherPage id="footer2">
			<FooterOtherPage>
				<SignatureText color={'#485460'} size={'6px'} bold>
					AVISOS IMPORTANTES:
				</SignatureText>
				<TextSignature size={'6px'}>
					{'"'}Este documento é distribuído pela ÓRAMA DTVM S.A.
					(“Órama”), estando em conformidade com a Instrução CVM {cvm}
					. O(s) analista(s) de valores mobiliários {name} - {cnpi},
					é(são) o(s) signatário(s) da(s) análise(s) descrita(s) no
					presente documento e se declara(m) inteiramente
					responsável(is) pelas informações e afirmações contidas
					neste material. O(s) analista(s) envolvidos na elaboração
					deste documento declara(m) que as recomendações
					eventualmente contidas no relatório refletem única e
					exclusivamente sua opinião pessoal sobre o(s) ativo(s)
					analisado(s) e foi(ram) elaborado(s) de forma independente,
					inclusive em relação à Órama, bem como declara(m) que nao
					estão em situação que possa afetar a imparcialidade do
					relatório ou que possa configurar conflito de interesse, não
					sendo a remuneração recebida, direta ou indiretamente,
					influenciada pelas receitas provenientes dos negócios e
					operações financeiras realizadas. A Órama declara que não
					existem situações que possam afetar a imparcialidade do
					relatório de análise ou que configurem ou possam configurar
					conflito de interesses. Todas as informações utilizadas
					na(s) análise(s) contida(s) neste documento foram redigidas
					com base em informações públicas de fontes consideradas
					fidedignas. Embora tenham sido tomadas todas as medidas
					razoáveis para assegurar que as informações contidas no
					presente documento não são incertas ou equívocas no momento
					de sua publicação, a Órama e o(s) seu(s) analista(s) não
					respondem por sua veracidade. As informações contidas no
					presente documento tem propósito meramente informativo não
					constituindo em uma oferta de compra ou de venda de qualquer
					ativo. As informações, opiniões, estimativas e projeções
					contidas se referem à data de sua elaboração e/ou
					divulgação, bem como estão sujeitas a mudanças, não havendo
					obrigatoriedade de qualquer comunicação no sentido de
					atualização ou revisão. Ainda, as opiniões a respeito de
					compra, venda ou manutenção dos ativos objeto ou a
					ponderação de tais ativos numa carteira teórica expressam o
					melhor julgamento do(s) analista(s) responsável(is) por sua
					elaboração, porém não devem ser entendidos por investidores
					como recomendação para uma efetiva tomada de decisão ou
					realização de negócios de qualquer natureza. Este material
					não leva em consideração os objetivos de investimento,
					situação financeira ou necessidades pessoais de um
					investidor em específico, não possuindo qualquer vinculação
					com o perfil do investidor (suitability) e não devendo ser
					considerado para este fim. Antes de tomar qualquer decisão
					de investimento, a Órama aconselha que o investidor entre em
					contato com o seu assessor para orientação financeira com
					base em suas características e objetivos pessoais, bem como
					recomenda o preenchimento do seu perfil de investidor.
					Operações estruturadas são compatíveis para investidores que
					possuam perfil arrojado junto à Órama. Rentabilidade
					prevista ou passada não é garantia de rentabilidade futura.
					As projeções constantes neste documento poderão ter
					resultados significativamente diferentes do esperado.
					Recomenda-se a análise das características, prazos e riscos
					dos investimentos antes da decisão de compra ou venda.
					Investimentos nos mercados financeiros e de capitais estão
					sujeitos a riscos de perda superior ao valor total do
					capital investido, não podendo a Órama e/ou o(s) analista(s)
					envovido(s) na elaboração deste material serem
					responsabilizados por qualquer perda direta ou indireta
					decorrente da utilização do seu conteúdo, cabendo a decisão
					de investimento exclusivamente ao investidor. O custo da
					operação e a política de cobrança estão definidos nas
					tabelas de custos operacionais disponibilizadas no site da
					Órama (www.orama.com.br/custos). Os custos e emolumentos da
					bolsa devem ser consultados no site da B3
					(http://www.b3.com.br/pt_br/produtos-e-servicos/tarifas/).
					Eventual resultado da operação não é líquido de impostos. O
					valor de mercado da estrutura antes do vencimento não é
					linear, pois a precificação das opções que a constituem
					dependem de uma série de fatores, tais como volatilidade,
					liquidez, proximidade a data de vencimento, eventos no ativo
					objeto e a variação diária do ativo objeto. As condições de
					mercado, o cenário macroeconômico, dentre outras condições,
					podem afetar o desempenho do investimento objeto da análise
					deste documento. O encerramento antecipado, total ou
					parcial, da operação pode implicar em um resultado
					significativamente diferente daquele esperado para o
					vencimento. Caso o investidor queira montar, desfazer ou
					reduzir sua posição, solicite uma cotação para o seu
					assessor de investimentos ou entre em contato com a mesa de
					produtos estruturados da Órama. Fica proibida a reprodução
					ou redistribuição deste material para qualquer pessoa, no
					todo ou em parte, qualquer que seja o propósito, sem o
					prévio e expresso consentimento da Órama.{'"'}
				</TextSignature>
				<PowerBy>
					<ImagePowerBy src={powerImg} />
				</PowerBy>
			</FooterOtherPage>
		</ConteinerOtherPage>
	);
};
