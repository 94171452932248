export const STRATEGY_HANDLE = 'STRATEGY::HANDLE';
export const STRATEGY_CLEAR = 'STRATEGY::CLEAR';
export const VISIBILITY_SET = 'VISIBILITY::SET';
export const STRATEGY_NAME = 'STRATEGY_NAME::SET';
export const STRATEGY_UPDATE = 'STRATEGY_UPDATE::SET';
export const REPLACE_CUSTOM_PARAMETERS = 'REPLACE_CUSTOM_PARAMETERS::SET';
export const UPDATE_CUSTOM_PARAMETERS = 'UPDATE_CUSTOM_PARAMETERS::SET';
export const UPDATE_STRATEGY_LEG = 'UPDATE_STRATEGY_LEG::SET';
export const UPDATE_STRATEGY_INFO = 'UPDATE_STRATEGY_INFO::SET';
export const UPDATE_STRATEGY_MARKET_PRICES =
	'UPDATE_STRATEGY_MARKET_PRICES::SET';
export const REPLACE_STRATEGY_LEGS = 'REPLACE_STRATEGY_LEGS::SET';
export const ADDITIONAL_UPDATE = 'ADDITIONAL_UPDATE::SET';
export const HANDLE_RECENT_ORDERS = 'STRATEGY::SAVE_RECENTS';
export const HANDLE_SAVED_ORDERS = 'STRATEGY::SAVE_ORDERS';
export const ORDER_ENTRY = 'BOTTOM_BULLET::ORDER_ENTRY';
export const UPDATE_STRATEGY_INFOS = 'UPDATE_STRATEGY_INFOS::SET';
export const UPDATE_CONTENT = 'UPDATE_CONTENT::SET';
export const UPDATE_CONTEXT = 'UPDATE_CONTEXT::SET';
export const LOADING_MARKET_PRICE = 'LOADING_MARKET_PRICE::SET';
export const SET_IS_RECURRENCE_STATE = 'bullet/SET_IS_RECURRENCE_STATE';
export const UPDATE_RECURRENCE_INFO = 'bullet/UPDATE_RECURRENCE_INFO';
