import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { FileDownloadOutlined } from '@mui/icons-material';
import {
	Button,
	ClickAwayListener,
	Fade,
	List,
	MenuItem,
	Paper,
	Popper,
} from '@mui/material';

import { getOrdersCsv } from 'services/getOrdersCsv';
import { USER_TYPES } from 'utils/constants';

export const CurrentStrategiesExportMenu = ({ disabled, apiRef }) => {
	const { userType } = useSelector(state => state.auth);

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	function handleOpenMenu(event) {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setIsMenuOpen(!isMenuOpen);
	}

	function handleCloseMenu() {
		setAnchorEl(null);
		setIsMenuOpen(false);
	}

	const exportOptions = [
		{
			label: 'Baixar como CSV',
			disabled: false,
			action: () => {
				apiRef.current.exportDataAsCsv();
			},
		},
		{
			label: 'Imprimir',
			disabled: false,
			action: () => {
				apiRef.current.exportDataAsPrint();
			},
		},
		{
			label: 'Ordens',
			disabled: userType.id !== USER_TYPES.FINAL_CLIENT,
			action: () => getOrdersCsv(),
		},
	];

	return (
		<>
			<Button
				size="small"
				variant="text"
				color="inherit"
				disabled={disabled}
				startIcon={<FileDownloadOutlined />}
				onClick={handleOpenMenu}
			>
				Exportar
			</Button>

			<Popper
				sx={{ position: 'fixed' }}
				transition
				placement="bottom-start"
				open={isMenuOpen}
				anchorEl={anchorEl}
			>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={handleCloseMenu}>
						<Fade {...TransitionProps}>
							<Paper elevation={12} square={false}>
								<List>
									{exportOptions.map(item => (
										<MenuItem
											key={item.label}
											disabled={item.disabled}
											onClick={item.action}
										>
											{item.label}
										</MenuItem>
									))}
								</List>
							</Paper>
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
		</>
	);
};
