const initialState = {
	filter: null,
	selected: 'Orders'
};

const reducer = {
	['TABLE_ORDERS_FILTER']: (stt, payload) => ({
		...stt,
		...payload
	})
};

export default function tablesReducer(state = initialState, { type, payload }) {
	if (typeof reducer[type] === 'function') {
		return reducer[type](state, payload);
	}
	return state;
};
