import React, { useState } from 'react';
import WebsocketClient from '../WebsocketClient';
import {
	updateStatusStreamingExecution,
	updateStatusStreamingMarket,
} from '../../store/modules/globalStatus/actions';
import { useSelector, useDispatch } from 'react-redux';
import { setContigency } from '../../store/modules/auth/actions';
import { store } from 'store';

const WebsocketComponent = props => {
	const [tradeStatus, setTradeStatus] = useState(false);
	const [candleStatus, setCandleStatus] = useState(false);
	const [miniBookStatus, setMiniBookStatus] = useState(false);
	const [bookStatus, setBookStatus] = useState(false);
	const [executionStatus, setExecutionStatus] = useState(false);
	const dispatch = useDispatch();

	const {
		subscriptionTradeList,
		subscriptionBookList,
		subscriptionMiniBookList,
		subscriptionSnapshotList,
		subscriptionCandleList,
	} = useSelector(state => state.websocket);

	const isMarketDataStatusUp = () => {
		return miniBookStatus && tradeStatus && bookStatus && candleStatus;
	};

	const isConnected = useSelector(state => state.websocket.isConnected);

	const connectMiniBookCallback = () => {
		setMiniBookStatus(true);
		// MarketStatus = tradeStatus(true) && bookStatus
		dispatch(
			updateStatusStreamingMarket(
				bookStatus && tradeStatus && candleStatus
			)
		);
	};

	const errorMiniBookCallback = values => {
		if (isMarketDataStatusUp()) {
			setMiniBookStatus(false);
			dispatch(updateStatusStreamingMarket(false));
			processWbsCallBackError(values);
		}
	};

	const closeEventMiniBookCallback = () => {
		setMiniBookStatus(false);
		dispatch(updateStatusStreamingMarket(false));
	};

	const connectTradeCallback = () => {
		setTradeStatus(true);
		// MarketStatus = tradeStatus(true) && bookStatus
		dispatch(
			updateStatusStreamingMarket(
				bookStatus && miniBookStatus && candleStatus
			)
		);
	};

	const connectCandleCallback = () => {
		setCandleStatus(true);
		dispatch(
			updateStatusStreamingMarket(
				bookStatus && tradeStatus && miniBookStatus
			)
		);
	};

	const errorTradeCallback = values => {
		if (isMarketDataStatusUp()) {
			setTradeStatus(false);
			dispatch(updateStatusStreamingMarket(false));
			processWbsCallBackError(values);
		}
	};

	const errorCandleCallback = values => {
		if (isMarketDataStatusUp()) {
			setCandleStatus(false);
			dispatch(updateStatusStreamingMarket(false));
			processWbsCallBackError(values);
		}
	};

	const closeEventTradeCallback = () => {
		setTradeStatus(false);
		dispatch(updateStatusStreamingMarket(false));
	};

	const closeEventCandleCallback = () => {
		setCandleStatus(false);
		dispatch(updateStatusStreamingMarket(false));
	};

	const connectBookCallback = () => {
		setBookStatus(true);
		dispatch(
			updateStatusStreamingMarket(
				tradeStatus && miniBookStatus && candleStatus
			)
		);
	};

	const errorBookCallback = values => {
		if (isMarketDataStatusUp()) {
			setBookStatus(false);
			dispatch(updateStatusStreamingMarket(false));

			processWbsCallBackError(values);
		}
	};

	const closeEventBookCallback = () => {
		setBookStatus(false);
		dispatch(updateStatusStreamingMarket(false));
	};

	// SNAPSHOT
	const connectCallback = () => {
		setExecutionStatus(true);
		dispatch(updateStatusStreamingExecution(true));
		dispatch(setContigency(false));
	};

	const errorCallback = () => {
		if (executionStatus || !store.getState().auth.contigency) {
			setExecutionStatus(false);
			dispatch(updateStatusStreamingExecution(false));
			dispatch(setContigency(true));
		}
	};

	const closeEventCallback = () => {
		setExecutionStatus(false);
		dispatch(updateStatusStreamingExecution(false));
		dispatch(setContigency(true));
	};

	const processWbsCallBackError = values => {
		console.log(values);
	};

	const tempPropsToSnap = {
		...props,
		callbackOnMessage: props.snapshotStreamingMessage,
	};

	return (
		<>
			<WebsocketClient
				url={process.env.REACT_APP_WBS_BOOK_URL}
				topicType="book"
				subscriptionList={subscriptionBookList.map(
					symbol => `${process.env.REACT_APP_WBS_TOPIC_BOOK}${symbol}`
				)}
				callbackOnConnect={connectBookCallback}
				callbackOnDisconnect={closeEventBookCallback}
				callbackOnConnectFailure={errorBookCallback}
				isConnected={isConnected}
				{...props}
			/>

			<WebsocketClient
				url={process.env.REACT_APP_WBS_TRADE_URL}
				topicType="trade"
				subscriptionList={subscriptionTradeList.map(
					symbol =>
						`${process.env.REACT_APP_WBS_TOPIC_TRADE}${symbol}`
				)}
				callbackOnConnect={connectTradeCallback}
				callbackOnDisconnect={closeEventTradeCallback}
				callbackOnConnectFailure={errorTradeCallback}
				isConnected={isConnected}
				{...props}
			/>

			<WebsocketClient
				url={process.env.REACT_APP_WBS_MINIBOOK_URL}
				topicType="minibook"
				subscriptionList={subscriptionMiniBookList.map(
					symbol =>
						`${process.env.REACT_APP_WBS_TOPIC_MINIBOOK}${symbol}`
				)}
				callbackOnConnect={connectMiniBookCallback}
				callbackOnDisconnect={closeEventMiniBookCallback}
				callbackOnConnectFailure={errorMiniBookCallback}
				isConnected={isConnected}
				{...props}
			/>

			<WebsocketClient
				url={process.env.REACT_APP_WBS_SNAP_URL}
				topicType="snapshot"
				subscriptionList={subscriptionSnapshotList.map(
					() => process.env.REACT_APP_WBS_QUEUE_SNAP
				)}
				callbackOnConnect={connectCallback}
				callbackOnDisconnect={closeEventCallback}
				callbackOnConnectFailure={errorCallback}
				isConnected={isConnected}
				{...tempPropsToSnap}
			/>

			<WebsocketClient
				url={process.env.REACT_APP_WBS_CANDLE_URL}
				topicType="candle"
				subscriptionList={subscriptionCandleList.map(
					symbol =>
						`${process.env.REACT_APP_WBS_TOPIC_CANDLE}${
							symbol.split('_')[1]
						}/bvmf/${symbol.split('_')[0]}`
				)}
				callbackOnConnect={connectCandleCallback}
				callbackOnDisconnect={closeEventCandleCallback}
				callbackOnConnectFailure={errorCandleCallback}
				isConnected={isConnected}
				{...props}
			/>
		</>
	);
};

export default WebsocketComponent;
