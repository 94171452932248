import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getResponseAction } from 'utils/partnerProducts';

export const PartnerProduct = () => {
	const iframeRef = useRef(null);

	const { token } = useSelector(state => state.auth);

	const activeProduct = useSelector(state => state.products.activeProduct);

	async function handleMessage(event) {
		if (event.origin !== activeProduct.urlPartnerProduct) {
			return;
		}

		const { requestId, authToken, type, parameters } = event.data;

		if (authToken !== token) {
			return;
		}

		const isAllowedAction = activeProduct.attributes.actions.some(
			item => item === type
		);

		if (!isAllowedAction) {
			const messageNotAllowed = {
				requestId,
				authToken,
				type,
				parameters,
				statusCode: 403,
				response: {
					message: 'Sem permissao para acessar o recurso!',
					code: 4432,
				},
			};

			iframeRef.current.contentWindow.postMessage(
				messageNotAllowed,
				activeProduct.urlPartnerProduct
			);

			return;
		}

		const dataMessage = {
			requestId,
			authToken,
			type,
			parameters,
			productCode: activeProduct.code,
		};

		const responseAction = await getResponseAction(
			dataMessage,
			activeProduct
		);

		iframeRef.current.contentWindow.postMessage(
			responseAction,
			activeProduct.urlPartnerProduct
		);
	}

	useEffect(() => {
		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, []);

	return (
		<>
			<iframe
				ref={iframeRef}
				title={activeProduct.name}
				src={`${activeProduct.urlPartnerProduct}/special?authToken=${token}`}
				width="100%"
				height="100%"
			/>
		</>
	);
};
