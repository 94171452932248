import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { roundNumber } from 'utils/numberFormat';
import { updatePosition } from 'store/modules/simulator/actions';

export const CellResult = memo(function CellResult({ position }) {
	const dispatch = useDispatch();
	const activeStrategy = useSelector(state => state.simulator.activeStrategy);

	const { id, entryPx, exitPx, entryPxLocked, exitPxLocked, qtty, result } =
		position;

	function updateValue(value) {
		dispatch(updatePosition(activeStrategy, id, { result: value }));
	}

	useEffect(() => {
		const isExitPxInvalid = !exitPxLocked && !exitPx;
		const isEntryPxInvalid = !entryPxLocked && !entryPx;

		if (isEntryPxInvalid || isExitPxInvalid) {
			updateValue(null);
		} else {
			const calculatedResult = parseFloat(
				((qtty || 0) * ((entryPx || 0) - (exitPx || 0)) * -1).toFixed(2)
			);

			updateValue(calculatedResult);
		}
	}, [entryPx, exitPx, qtty, entryPxLocked, exitPxLocked]);

	return <>{roundNumber(result) || 'N/D'}</>;
});
