import React, { useState } from 'react';

import { Typography } from '@mui/material';
import { ContentCopy, LibraryAddCheck } from '@mui/icons-material';
import { LightTooltip } from './LightTooltip';

export const CopyCell = ({ value, tooltipPlacement = 'left', ...rest }) => {
	const [isHovered, setIsHovered] = useState(false);
	const [isAlreadyCopied, setIsAlreadyCopied] = useState(false);

	const isDisabled =
		value === null ||
		value === undefined ||
		value === '';

	function handleMouseEnter() {
		setIsHovered(true);
	}

	function handleMouseLeave() {
		setIsHovered(false);
	}

	function handleCopy() {
		if (navigator.clipboard && !isDisabled) {
			navigator.clipboard.writeText(value);

			setIsAlreadyCopied(true);
			setTimeout(() => {
				setIsAlreadyCopied(false);
			}, 1000);
		}
	}

	const hoveredStyles = isHovered && !isDisabled && {
		cursor: 'pointer',
		borderRadius: '5px',
		transition: 'background-color 0.2s',
		bgcolor: 'rgba(147, 147, 147, 0.4)',
		display: 'flex',
		alignItems: 'center',
		gap: 0.5,
	};

	return (
		<LightTooltip
			placement={tooltipPlacement}
			title={isAlreadyCopied ? 'Copiado!' : 'Copiar'}
		>
			<Typography
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={handleCopy}
				sx={{
					...hoveredStyles,
					padding: '0.25rem 0.5rem',
				}}
				{...rest}
			>
				{(isHovered && !isDisabled) && (
					isAlreadyCopied
						? <LibraryAddCheck size="small" color="success" />
						: <ContentCopy fontSize="small" />
				)}

				{value}
			</Typography>
		</LightTooltip>
	);
};
