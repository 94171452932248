import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextField, Typography } from '@mui/material';

import { setCurrentPositionsFilters } from 'store/modules/positions/actions';

export const CurrentPositionsFilter = () => {
	const dispatch = useDispatch();

	const { platform: selectedPlatform } = useSelector(
		state => state.positions.currentPositionsFilters
	);

	const userPlatforms = [
		{ value: 'all', label: 'Todas' },
		{ value: 'flexscan', label: 'Flexscan' },
		{ value: 'external', label: 'Externo' },
	];

	function handleChangePositionsFilters(field, value) {
		dispatch(setCurrentPositionsFilters(field, value));
	}

	return (
		<>
			<form
				style={{
					marginBottom: 0,
					display: 'flex',
					gap: 8,
					alignItems: 'center',
					paddingLeft: 8,
				}}
			>
				<Typography variant="subtitle1">Plataforma:</Typography>
				<TextField
					id="platform"
					name="platform"
					value={selectedPlatform}
					size="small"
					defaultValue="all"
					SelectProps={{ native: true }}
					select
					onChange={event => {
						handleChangePositionsFilters(
							event.target.name,
							event.target.value
						);
					}}
				>
					{userPlatforms.map(platform => (
						<option key={platform.value} value={platform.value}>
							{platform.label}
						</option>
					))}
				</TextField>
			</form>
		</>
	);
};
