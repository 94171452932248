import { toast } from 'react-toastify';

import { store } from 'store';
import { saveStrategy } from 'store/modules/simulator/actions';

export function handleSaveStrategy(strategyToSave, paper) {
	const { username, account } = store.getState().auth;

	if (typeof strategyToSave.id !== 'string') {
		const maximumStrategies =
			process.env.REACT_APP_SIMULATOR_MAXIMUM_STRATEGIES ?? 100;

		const strategiesQuantity = store.getState().portfolio.strategies.length;
		const strategiesToRemove = Math.abs(
			strategiesQuantity - (maximumStrategies - 1)
		);

		if (strategiesQuantity >= maximumStrategies) {
			return toast.error(
				`Limite máximo de estratégias atingido. Você precisa remover ${strategiesToRemove} estratégias para poder salvas novas.`
			);
		}
	}

	if (!strategyToSave.underSymbol) {
		return toast.error(
			'Favor selecione um papel e agregue ao menos uma perna na estratégia para poder salvar'
		);
	}

	if (!strategyToSave.positions.length > 0) {
		return toast.error(
			'Favor agreguar ao menos uma perna na estratégia para poder salvar'
		);
	}

	if (!paper.subExchange) {
		return toast.error('Campo: SubExchange não informado');
	}

	const { positionsDeleted, ...strategy } = strategyToSave;

	const deleted = positionsDeleted?.map(position => {
		Object.keys(position).forEach(key => {
			if (
				position[key] === null ||
				(typeof position[key] === 'number' && isNaN(position[key]))
			) {
				position[key] = 0;
			}
		});

		return { ...position, subExchange: paper.subExchange };
	});

	const data = {
		...strategy,
		userName: username,
		account,
		positions: [
			...strategy.positions.map(position => {
				const { ...pos } = position;

				let entry = {};
				let exit = {};

				if (position.entryPxLocked) {
					entry = {
						entryPx: position.entryPx || 0,
						entryImpliedVol: position.entryImpliedVol || 0,
					};
				}

				if (position.exitPxLocked) {
					exit = {
						exitPx: position.exitPx || 0,
						exitImpliedVol: position.exitImpliedVol || 0,
					};
				}

				if (pos.optionType) {
					pos.theoreticImpliedVol = position.theorImpliedVol;
				} else {
					delete entry.entryImpliedVol;
					delete exit.exitImpliedVol;
				}

				Object.keys(pos.entryGreeks).forEach(key => {
					if (!pos.entryGreeks[key]) {
						pos.entryGreeks[key] = 0;
					}
				});

				Object.keys(pos.exitGreeks).forEach(key => {
					if (!pos.exitGreeks[key]) {
						pos.exitGreeks[key] = 0;
					}
				});

				if (!pos.isin) {
					delete pos.isin;
				}

				return {
					...pos,
					subExchange: paper.subExchange,
					...exit,
					...entry,
				};
			}),
			...(deleted || []),
		],
	};

	const strategyParsed = parseStrategy(data);

	store.dispatch(saveStrategy(strategyParsed));
}

export function parseStrategy(strategy) {
	const positionRequiredFields = [
		'account',
		'index',
		'checked',
		'symbol',
		'subExchange',
	];

	strategy.positions?.forEach(position => {
		Object.keys(position).forEach(field => {
			if (!positionRequiredFields.includes(field)) {
				if (
					position[field] === null ||
					position[field] === undefined ||
					(typeof position[field] === 'number' &&
						isNaN(position[field]))
				) {
					delete position[field];
				}

				if (field === 'isin') {
					delete position[field];
				}
			}
		});
	});

	return strategy;
}
