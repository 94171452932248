import { store } from 'store';
import { apiAWS } from './api';
import { getQuotes } from './getQuotes';
import { getDefinitions } from './getDefinitions';
import { setPortfolioStrategies } from 'store/modules/portfolio/actions';
import { handleSimulateStrategy } from 'utils/Portfolio/handleSimulateStrategy';

function addDefinitions(strategies, definitions) {
	strategies.forEach(strategy => {
		const paperDefinition = definitions.find(
			item => item.symbol === strategy.underSymbol
		);

		strategy.saved = true;
		strategy.underType = paperDefinition?.securityType;
		strategy.histVol30 = paperDefinition?.histVol30;

		strategy.positions?.forEach(position => {
			const positionDefinition = definitions.find(
				item => item.symbol === position.symbol
			);

			if (positionDefinition) {
				const formatedOptionType =
					positionDefinition?.optionType === 'CALL' ? 'C' : 'P';

				const unformatedExpirationDate = position.expirationDate;
				const formatedExpirationDate =
					position.expirationDate.replaceAll('-', '');

				position.expirationDate = formatedExpirationDate;
				position.histVol30 = paperDefinition?.histVol30;
				position.strikePx = parseFloat(positionDefinition?.strikePrice);
				position.contractMultiplier =
					positionDefinition?.contractMultiplier;
				position.optionType = formatedOptionType;
				position.unformatedExpirationDate = unformatedExpirationDate;

				position.daysToExpiration = parseInt(
					positionDefinition?.workDaysUntilMaturity
				);
				position.margin = parseFloat(positionDefinition?.margin);
				position.posStatus = position.exitPxLocked ? 'C' : 'O';
				position.contractMultiplier = parseFloat(
					positionDefinition?.contractMultiplier
				);
				position.strategyUnderSymbol = strategy.underSymbol;
				position.optionClass = positionDefinition?.optionStyle;
			} else {
				position.daysToExpiration = 0;
				position.posStatus = position.exitPxLocked ? 'C' : 'O';
				position.optionType = '';
			}
		});
	});

	return strategies;
}

function getSymbolsList(strategies) {
	const symbolsList = [];

	strategies.forEach(strategy => {
		if (!symbolsList.includes(strategy.underSymbol)) {
			symbolsList.push(strategy.underSymbol);
		}

		strategy.positions.forEach(position => {
			if (!symbolsList.includes(position.symbol) && !position.expired) {
				symbolsList.push(position.symbol);
			}
		});
	});

	return symbolsList;
}

async function addLastQuotes(strategiesWithDefinitions) {
	let strategiesWithQuotes = strategiesWithDefinitions;

	try {
		const symbolsList = getSymbolsList(strategiesWithQuotes);
		const lastQuotes = await getQuotes(symbolsList);

		strategiesWithQuotes.forEach(strategy => {
			const activeQuotes = lastQuotes.find(
				quotes => quotes.symbol === strategy.underSymbol
			);

			strategy.underPrice =
				activeQuotes?.lastPx ?? activeQuotes?.prevClosePx;

			strategy.positions?.forEach(position => {
				const positionQuotes = lastQuotes.find(
					quotes => quotes.symbol === position.symbol
				);

				if (!position.entryPxLocked) {
					if (position.qtty < 0) {
						position.entryPx = positionQuotes?.bidPx ?? 'NaN';
					} else {
						position.entryPx = positionQuotes?.offerPx ?? 'NaN';
					}
				}

				if (!position.exitPxLocked) {
					if (position.qtty < 0) {
						position.exitPx = positionQuotes?.offerPx ?? 'NaN';
					} else {
						position.exitPx = positionQuotes?.bidPx ?? 'NaN';
					}
				}
			});
		});

		store.dispatch(setPortfolioStrategies(strategiesWithQuotes));
	} catch (error) {
		console.log(error);
	}
}

export function getSimulatedStrategies(strategyToOpen) {
	apiAWS
		.get('/strategies')
		.then(response => {
			return response.data?.strategies;
		})
		.then(strategies => {
			const symbolsList = [];
			const fieldsList = [
				'workDaysUntilMaturity',
				'securityType',
				'histVol30',
				'optionType',
				'margin',
				'strikePrice',
				'contractMultiplier',
				'optionStyle',
			];

			strategies?.forEach(strategy => {
				if (!symbolsList.includes(strategy.underSymbol)) {
					symbolsList.push(strategy.underSymbol);
				}

				strategy.positions?.forEach(position => {
					if (
						!symbolsList.includes(position.symbol) &&
						!position.expired
					) {
						symbolsList.push(position.symbol);
					}
				});
			});

			const definitionsPromise = getDefinitions(symbolsList, fieldsList);

			definitionsPromise
				.then(records => {
					const strategiesWithDefinitions = addDefinitions(
						strategies,
						records
					);

					addLastQuotes(strategiesWithDefinitions);

					if (strategyToOpen) {
						strategiesWithDefinitions.forEach(stt => {
							if (stt.name === strategyToOpen.name) {
								handleSimulateStrategy(
									stt,
									store.getState().portfolio.activeStrategies
								);
							}
						});
					}
				})
				.catch(err => console.log(err));
		})
		.catch(error => {
			console.log(error);
		});
}

export async function getSimulatedStrategy(userToken, strategyId) {
	const headers = { headers: { Authorization: `Bearer ${userToken}` } };
	let strategy = null;

	try {
		const { data } = await apiAWS.get(`/strategies/${strategyId}`, headers);

		strategy = data;
	} catch (error) {
		console.log(error);
	}

	return strategy;
}
