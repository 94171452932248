import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setInvalidSignature } from 'store/modules/userPreferences/actions';

import { PortalSignature } from '../PortalSignature';
import { GlobalSettings } from 'components/GlobalSettings';
import { AcceptableContract } from 'components/Contract/AcceptableContract';

import { setContractVisibility } from 'store/modules/products/actions';
import { setIsGlobalSettingsOpen } from 'store/modules/panelConfig/actions';

export const GlobalModals = () => {
	const dispatch = useDispatch();

	const invalidSignature = useSelector(
		state => state.userPreferences?.utils?.invalidSignature
	);
	const isGlobalSettingsOpen = useSelector(
		state => state.configs?.globalSettings.isGlobalSettingsOpen
	);
	const isContractOpen = useSelector(
		state => state.products.userContracts.contractVisibility
	);

	return (
		<>
			{/* Modal de aviso para a mesma assinatura eletrônica do portal da Órama */}
			<PortalSignature
				open={invalidSignature}
				handleClose={() => dispatch(setInvalidSignature(false))}
			/>

			{/* Modal de configurações globais da plataforma */}
			<GlobalSettings
				open={isGlobalSettingsOpen}
				onClose={() => dispatch(setIsGlobalSettingsOpen(false))}
			/>

			{/* Modal de aceite de contrato caso assinatura não esteja com os termos de uso acordados */}
			{isContractOpen && (
				<AcceptableContract
					isOpen={isContractOpen}
					onClose={() => dispatch(setContractVisibility(false))}
				/>
			)}
		</>
	);
};
