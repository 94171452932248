import React, {
	useEffect,
	useState,
	useContext,
	useImperativeHandle,
	forwardRef,
} from 'react';
import { useDispatch } from 'react-redux';
import PaperSearch from 'services/paperSearch';
import numberFormat from 'utils/numberFormat';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import {
	Container,
	WidgetContent,
	Title,
	TextPercent,
	SearchBar,
	ResultDiv,
	ResultLine,
	TitleLine,
	PriceVariation,
	ButtonsLine,
	GridDiv,
	WidgetButton,
	StyledTableCell,
} from './styles';
import {
	Box,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material';
import { getSubUid } from 'utils/strategies';
import {
	additionalUpdate,
	orderEntry,
	updateStrategyInfosFromApi,
} from 'store/modules/bottomBullet/actions';
import { apiEls } from 'services/api';
import { widgetContentUpdate } from 'store/modules/widgets/actions';
import { updateConfig } from 'store/modules/panelConfig/actions';
import { getStrategyTemplate } from 'utils/templates';
import { EXM } from 'utils/bulletContext';
import moment from 'moment';
import { handleSubscription } from 'utils/actionListener';

const rowColors = ['#3F6280', '#35547F', '#2B4A7F', '#1D3E7F', '#0C327F'];

const SymbolQuotation = forwardRef(function SymbolQuotation(props, ref) {
	const {
		registerCallback,
		unRegisterCallback,
		handleUpdate,
		widget,
		widgetOpen,
		setIsTyping,
	} = props;
	const { content, id, data } = widget;
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [paper, setPaper] = useState({});
	const [book, setBook] = useState({
		askPrices: [0, 0, 0, 0],
		askQuantities: [0, 0, 0, 0],
		bidPrices: [0, 0, 0, 0],
		bidQuantities: [0, 0, 0, 0],
	});

	const [type, setType] = useState(content.type);
	const [connectionId, setConnectionId] = useState(content.connection);
	const [symbol, setSymbol] = useState(content.symbol);
	const [bookConnection, setBookConnection] = useState('');
	const [additionalValues, setAdditionalValues] = useState({
		margin: data?.margin,
		collateral: data?.collateral,
		securityDesc: data?.securityDesc,
		tickSizeDenominator: data?.tickSizeDenominator,
		securityType: data?.securityType,
		maturityDate: data?.maturityDate,
		underlyingSymbol: data?.underlyingSymbol,
		optionType: data?.optionType,
		optionStyle: data?.optionStyle,
		minOrderQty: data?.minOrderQty,
		symbol: data?.continuousRef ?? data?.symbol,
		expType: data?.expType,
		subExchange: data?.subExchange,
		isContinuous: data?.isContinuous,
		continuousRef: data?.continuousRef,
	});

	const formatMaturityDate = moment(
		additionalValues?.maturityDate,
		'YYYYMMDD'
	).format('DD/MM/YYYY');

	const verifyMaturityDate =
		additionalValues?.maturityDate &&
		additionalValues?.maturityDate !== '99991231';

	const [cacheValues, setCacheValues] = useState({
		openPrice: 0.0,
		prevClosePx: 0.0,
	});
	const {
		colors: { inputColor, inputBg },
	} = useContext(ThemeContext);

	const [metricsActive, setMetricsActive] = useState({
		hvP: '--',
		ivR: '--',
		hvR: '--',
		ivP: '--',
	});

	const [isLoading, setIsLoading] = useState(false);

	const [doSocketConnection, setDoSocketConnection] = useState(false);

	const tradeConnect = key => {
		const params = {
			action: 'subscribe',
			type: 'trade',
			key,
			callbackFunction: msg => {
				if (msg.msgType === 'V') {
					setMetricsActive({
						hvP: parseFloat(msg.hvP) * 100,
						ivR: parseFloat(msg.ivR) * 100,
						hvR: parseFloat(msg.hvR) * 100,
						ivP: parseFloat(msg.ivP) * 100,
					});
				}
				if (msg.prevClosePx) {
					setCacheValues({
						prevClosePx: msg.prevClosePx,
						openPrice: msg.openPrice,
					});
				}
				if (msg.msgType === 'T') {
					if (msg.hv_p) {
						setMetricsActive({
							hvP: parseFloat(msg.hv_p.replace(',', '.')),
							ivR: parseFloat(msg.iv_rank.replace(',', '.')),
							hvR: parseFloat(msg.hv_rank.replace(',', '.')),
							ivP: parseFloat(msg.iv_p.replace(',', '.')),
						});
					}
					const merged = { ...paper, ...msg };
					merged.updatedAt = new Date();
					setPaper(merged);
				}
			},
		};

		const socketId = handleSubscription(
			params,
			registerCallback,
			unRegisterCallback
		);
		setConnectionId(socketId);
		const newContent = {
			symbol: key,
			data: additionalValues,
			type,
			connection: connectionId,
			paper,
			book,
		};
		handleUpdate(id, newContent);
	};

	const bookConnect = key => {
		const params = {
			action: 'subscribe',
			type: 'book',
			key,
			callbackFunction: msg => {
				const merged = { ...book, ...msg };
				if (['M', 'T'].includes(msg?.msgType)) return;

				merged.updatedAt = new Date();
				setBook(merged);
			},
		};

		const socketId = handleSubscription(
			params,
			registerCallback,
			unRegisterCallback
		);
		setBookConnection(socketId);
	};

	const handleSymbolResult = (index, data) => {
		if (data.symbol === symbol) {
			setIsTyping(false);
			return;
		}

		disconnectWidget();

		if (!data.symbol) {
			return;
		}

		setAdditionalValues({ ...data });
		setDoSocketConnection(true);
		dispatch(updateConfig('widgetIsLoading', true));
	};

	const handleConnection = handleSymbol => {
		setSymbol(handleSymbol);
		bookConnect(handleSymbol);
		tradeConnect(handleSymbol);
	};

	const disconnectWidget = () => {
		const params = {
			action: 'unsubscribe',
			id: connectionId,
		};

		handleSubscription(params, registerCallback, unRegisterCallback);

		setConnectionId(null);

		const bookParams = {
			action: 'unsubscribe',
			id: bookConnection,
		};

		handleSubscription(bookParams, registerCallback, unRegisterCallback);

		setBookConnection(null);

		setMetricsActive({
			hvP: '--',
			ivR: '--',
			hvR: '--',
			ivP: '--',
		});
	};

	const disconnect = () => {
		disconnectWidget();

		dispatch(
			widgetContentUpdate(widget.id, {
				type: 'BookTrade',
				expanded: true,
				content: {
					definition: '',
					type: 'trade',
					symbol: '',
					connection: '',
					paper: {
						price: null,
						var: null,
						highPx: 0.0,
						lowPx: 0.0,
						finVol: 0.0,
						openPx: 0.0,
						closePx: 0.0,
					},
					book: {
						bidQuantities: [],
						bidPrices: [],
						askPrices: [],
						askQuantities: [],
					},
				},
			})
		);
	};

	const openBullet = async (price, book, side, quantity) => {


		const content = getStrategyTemplate('sorder');
		const subUid = getSubUid(content);
		content.CustomParameters.PriceLimit = price;
		content.StrategyLegs[0].LegSymbol =
			book.symbol ?? additionalValues.symbol;
		content.StrategyLegs[0].LegSide = side;
		content.StrategyLegs[0].LegQuantity = quantity;
		content.StrategyLegs[0].LegSecurityExchange =
			additionalValues.subExchange;
		content.context = EXM;

		await dispatch(orderEntry(content, subUid));
		dispatch(additionalUpdate('isOpen', true));
		dispatch(additionalUpdate('isRecentListVisible', false));
		dispatch(additionalUpdate('isSavedListVisible', false));
		dispatch(updateStrategyInfosFromApi(content.StrategyLegs));
	};

	function formatPrice(value) {
		if (
			value === 0 ||
			value === '0' ||
			isNaN(value) ||
			value.length <= 0 ||
			value === undefined ||
			value === '' ||
			value === null
		) {
			return '--';
		}
		if (!additionalValues) {
			value = +value.toFixed(0);
			value = value.toLocaleString('de-DE', {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			});
		} else {
			value = +value.toFixed(additionalValues.tickSizeDenominator);
			value = value.toLocaleString('de-DE', {
				minimumFractionDigits: additionalValues.tickSizeDenominator,
				maximumFractionDigits: additionalValues.tickSizeDenominator,
			});
		}
		return value;
	}

	function formatPercent(value) {
		if (
			value === 0 ||
			value === '0' ||
			isNaN(value) ||
			value.length <= 0 ||
			value === undefined ||
			value === '' ||
			value === null
		) {
			return '--';
		}
		value = +value.toFixed(2);
		value = value.toLocaleString('de-DE', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});

		return value;
	}

	useImperativeHandle(ref, () => ({
		disconnectRemoveWidget() {
			disconnectWidget();
		},
	}));

	const getElasticSearchPaper = symbol => {
		setTimeout(() => {
			apiEls
				.get(`/search/${symbol}`)
				.then(res => {
					if (res.data.found) {
						if (['DOL', 'WDO'].includes(res.data._source.asset)) {
							res.data._source.tickSizeDenominator = 2;
						}

						setAdditionalValues({ ...res.data._source });
						handleConnection(res.data._source.continuousRef ?? symbol);
					}
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => {
					setIsLoading(false);
					dispatch(updateConfig('widgetIsLoading', false));
					setIsTyping(false);
				});
		}, 500);
	};

	const onFocus = () => {
		setIsTyping(true);
	};

	useEffect(() => {
		if (!widgetOpen) {
			disconnectWidget();
		} else {
			setIsLoading(true);
			dispatch(updateConfig('widgetIsLoading', true));
			setPaper({});
			if (widget?.content?.symbol) {
				getElasticSearchPaper(widget.content.symbol);
			} else {
				dispatch(updateConfig('widgetIsLoading', false));
				setIsLoading(false);
			}
		}
	}, [widgetOpen]);

	useEffect(() => {
		if (additionalValues.symbol && doSocketConnection) {
			setIsLoading(true);
			setPaper({});
			getElasticSearchPaper(additionalValues.symbol);
		}
	}, [additionalValues.symbol, doSocketConnection]);

	return (
		<Container
			style={{
				boxSizing: 'border-box',
			}}
		>
			<WidgetContent>
				{additionalValues?.securityDesc &&
				widget.content.symbol !== '' ? (
						<Box display="flex">
							<Box pr={3}>
								{
								additionalValues?.securityDesc
									.split(' ')
									.filter(Boolean)[0]
								}
							</Box>
							<Box pr={3}>
								{
								additionalValues?.securityDesc
									.split(' ')
									.filter(Boolean)[1]
								}
							</Box>
							<Box pr={3}>
								{/^.*[,].*$/.test(
								additionalValues?.securityDesc
									.split(' ')
									.filter(Boolean)[2]
								)
									? ''
									: additionalValues?.securityDesc
										.split(' ')
										.filter(Boolean)[2]}
							</Box>
						</Box>
					) : (
						''
					)}
				<SearchBar>
					<Title>
						<PaperSearch
							setPaperInfo={handleSymbolResult}
							onBlurWidget={disconnect}
							onFocus={onFocus}
							index={0}
							blocked={false}
							value={symbol}
							style={{
								color: inputColor,
								backgroundColor: inputBg,
								textTransform: 'uppercase',
								marginBottom: 5,
							}}
						/>
					</Title>
					{widget.content.symbol !== '' && (
						<div style={{ display: 'flex' }}>
							<TextPercent>
								{paper.price &&
									formatPrice(parseFloat(paper.price))}
							</TextPercent>
							<GridDiv>
								{paper.var && (
									<Box
										color={
											Math.sign(paper.var) < 0
												? 'error.main'
												: 'success.main'
										}
									>
										<PriceVariation
											style={{ marginRight: 1 }}
										>
											{numberFormat(
												paper.var.toFixed(2),
												'00'
											)}
											%
										</PriceVariation>
									</Box>
								)}
								<small>
									{paper?.updatedAt?.toLocaleTimeString(
										'pt-PT',
										{
											hour12: false,
										}
									)}
								</small>
							</GridDiv>
						</div>
					)}
				</SearchBar>
				{!isLoading ? (
					<>
						{widget.content.symbol !== '' && (
							<>
								{type === 'trade' && (
									<table>
										<tr>
											<TitleLine>
												{t('widgets.trade.max')}:
											</TitleLine>

											<ResultLine
												style={{
													textAlign: 'right',
												}}
											>
												{formatPrice(
													parseFloat(
														paper.highPx ?? 0
													)
												)}
											</ResultLine>
										</tr>
										<tr>
											<TitleLine>
												{t('widgets.trade.min')}:
											</TitleLine>

											<ResultLine
												style={{
													textAlign: 'right',
												}}
											>
												{paper.lowPx === undefined
													? '--'
													: paper.lowPx &&
													  formatPrice(
													  	parseFloat(
													  		paper.lowPx
													  	)
													  )}
											</ResultLine>
										</tr>
										<tr>
											<TitleLine>
												{t('widgets.trade.opening')}:
											</TitleLine>

											<ResultLine
												style={{
													textAlign: 'right',
												}}
											>
												{paper.highPx === undefined
													? '--'
													: paper.highPx &&
													  formatPrice(
													  	parseFloat(
													  		cacheValues.openPrice
													  	)
													  )}
											</ResultLine>
										</tr>
										<tr>
											<TitleLine>
												{t('widgets.trade.closing')}:
											</TitleLine>

											<ResultLine
												style={{
													textAlign: 'right',
												}}
											>
												{paper.highPx === undefined
													? '--'
													: paper.highPx &&
													  formatPrice(
													  	parseFloat(
													  		cacheValues.prevClosePx
													  	)
													  )}
											</ResultLine>
										</tr>
										<tr>
											<TitleLine>
												{t('widgets.trade.vol')}:
											</TitleLine>

											<ResultLine
												style={{
													textAlign: 'right',
												}}
											>
												{paper.finVol === undefined
													? '--'
													: paper.finVol &&
													  numberFormat(
													  	paper.finVol,
													  	''
													  )}
											</ResultLine>
										</tr>
									</table>
								)}

								{type === 'metrics' && (
									<table>
										{additionalValues.securityType ===
											'OPT' ||
										additionalValues.securityType ===
											'FOPT' ||
										additionalValues.securityType ===
											'SOPT' ||
										additionalValues.securityType ===
											'INDEXOPT' ? (
												<>
													<tr>
														<TitleLine>
														Underlying:
														</TitleLine>

														<ResultLine
															style={{
																textAlign: 'right',
															}}
														>
															{
															additionalValues?.underlyingSymbol
															}
														</ResultLine>

														<TitleLine
															style={{
																paddingLeft: '16px',
															}}
														>
														Margem:
														</TitleLine>

														<ResultLine
															style={{
																textAlign: 'right',
															}}
														>
														-{' '}
															{additionalValues.margin &&
															formatPrice(
																Math.abs(
																	parseFloat(
																		additionalValues.margin
																	)
																)
															)}
														</ResultLine>
													</tr>
													<tr>
														<TitleLine>
														Strike:
														</TitleLine>

														<ResultLine
															style={{
																textAlign: 'right',
															}}
														>
															{/^.*[,].*$/.test(
															additionalValues?.securityDesc
																.split(' ')
																.filter(
																	Boolean
																)[2]
															)
																? additionalValues?.securityDesc
																	.split(' ')
																	.filter(
																		Boolean
																	)[2]
																: ''}
															{/^.*[,].*$/.test(
															additionalValues?.securityDesc
																.split(' ')
																.filter(
																	Boolean
																)[3]
															)
																? additionalValues?.securityDesc
																	.split(' ')
																	.filter(
																		Boolean
																	)[3]
																: ''}
														</ResultLine>

														{verifyMaturityDate && (
															<>
																<TitleLine
																	style={{
																		paddingLeft:
																		'16px',
																	}}
																>
																Venc.:
																</TitleLine>

																<ResultLine
																	style={{
																		textAlign:
																		'right',
																	}}
																>
																	{
																		formatMaturityDate
																	}
																</ResultLine>
															</>
														)}
													</tr>
													<tr>
														<TitleLine>Tipo:</TitleLine>

														<ResultLine
															style={{
																textAlign: 'right',
															}}
														>
															{
															additionalValues?.optionType
															}
														</ResultLine>

														{verifyMaturityDate &&
														additionalValues.securityType ===
															'OPT' && (
															<>
																<TitleLine
																	style={{
																		paddingLeft:
																			'16px',
																	}}
																></TitleLine>

																<ResultLine
																	style={{
																		textAlign:
																			'right',
																	}}
																>
																	{additionalValues.expType ===
																	'S'
																		? 'Semanal'
																		: 'Regular'}
																</ResultLine>
															</>
														)}
													</tr>
													<tr>
														<TitleLine>
														Ame/Eur:
														</TitleLine>

														<ResultLine
															style={{
																textAlign: 'right',
															}}
														>
															{additionalValues?.optionStyle ===
														'EUROPEAN'
																? 'EUROPEIA'
																: 'AMERICANA'}
														</ResultLine>
													</tr>
												</>
											) : (
												<>
													<tr>
														<TitleLine>
														IV rank:
														</TitleLine>

														<ResultLine
															style={{
																textAlign: 'right',
															}}
														>
															{typeof metricsActive?.ivR ===
														'number'
																? formatPercent(
																	metricsActive?.ivR
															  )
																: '--'}
														</ResultLine>

														<TitleLine
															style={{
																paddingLeft: '16px',
															}}
														>
														Margem:
														</TitleLine>

														<ResultLine
															style={{
																textAlign: 'right',
															}}
														>
														-{' '}
															{additionalValues.margin ===
														0
																? '--'
																: formatPrice(
																	Math.abs(
																		parseFloat(
																			additionalValues.margin
																		)
																	)
															  )}
														</ResultLine>
													</tr>
													<tr>
														<TitleLine>
														IV percentile:
														</TitleLine>

														<ResultLine
															style={{
																textAlign: 'right',
															}}
														>
															{typeof metricsActive?.ivP ===
														'number'
																? formatPercent(
																	metricsActive?.ivP
															  )
																: '--'}
														</ResultLine>

														<TitleLine
															style={{
																paddingLeft: '16px',
															}}
														>
														Deságio:
														</TitleLine>

														<ResultLine
															style={{
																textAlign: 'right',
															}}
														>
															{formatPrice(
																parseFloat(
																additionalValues?.collateral
																)
															)}
														</ResultLine>
													</tr>
													<tr>
														<TitleLine>
														HV rank:
														</TitleLine>

														<ResultLine
															style={{
																textAlign: 'right',
															}}
														>
															{typeof metricsActive?.hvR ===
														'number'
																? formatPercent(
																	metricsActive?.hvR
															  )
																: 0}
														</ResultLine>

														{verifyMaturityDate && (
															<>
																<TitleLine
																	style={{
																		paddingLeft:
																		'16px',
																	}}
																>
																Venc.:
																</TitleLine>

																<ResultLine
																	style={{
																		textAlign:
																		'right',
																	}}
																>
																	{
																		formatMaturityDate
																	}
																</ResultLine>
															</>
														)}
													</tr>
													<tr>
														<TitleLine>
														HV percentile:
														</TitleLine>

														<ResultLine
															style={{
																textAlign: 'right',
															}}
														>
															{typeof metricsActive?.hvP ===
														'number'
																? formatPercent(
																	metricsActive?.hvP
															  )
																: 0}
														</ResultLine>
													</tr>
												</>
											)}
									</table>
								)}

								{type === 'book' && (
									<ResultDiv style={{ marginTop: 7 }}>
										<Table aria-label="simple table">
											<TableHead>
												<TableRow
													style={{
														backgroundColor:
															'#2C2B2B',
														padding: 5,
													}}
												>
													<TableCell
														style={{
															color: '#E8E8E8',
															textAlign: 'center',
															width: '28%',
														}}
													>
														{t('widgets.book.qtd')}
													</TableCell>
													<TableCell
														style={{
															color: '#E8E8E8',
															textAlign: 'center',
															width: '22%',
														}}
													>
														{t('widgets.book.buy')}
													</TableCell>
													<TableCell
														style={{
															color: '#E8E8E8',
															textAlign: 'center',
															width: '22%',
														}}
													>
														{t('widgets.book.sell')}
													</TableCell>
													<TableCell
														style={{
															color: '#E8E8E8',
															textAlign: 'center',
															width: '28%',
														}}
													>
														{t('widgets.book.qtd')}
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{book.bidPrices &&
													book.bidPrices.map(
														(bid, index) => {
															return (
																<TableRow
																	key={index}
																	style={{
																		backgroundColor:
																			rowColors[
																				index
																			],
																		color: '#FFF',
																	}}
																>
																	<StyledTableCell
																		data-cy="tableCell"
																		align="right"
																		style={{
																			backgroundColor:
																				rowColors[
																					index
																				],
																			color: '#FFF',
																		}}
																		// ID issue 1547967379
																		// onClick={() =>
																		//     openBullet(
																		//         book
																		//             .bidPrices[
																		//             index
																		//         ],
																		//         book,
																		//         '2',
																		//         book
																		//             .bidQuantities[
																		//             index
																		//         ],
																		//     )
																		// }
																	>
																		{numberFormat(
																			book
																				.bidQuantities[
																					index
																				],
																			'',
																			false
																		)}
																	</StyledTableCell>
																	<StyledTableCell
																		align="right"
																		cursor={
																			'pointer !important'
																		}
																		style={{
																			backgroundColor:
																				rowColors[
																					index
																				],
																			color: '#FFF',
																		}}
																		onClick={() =>
																			openBullet(
																				book
																					.bidPrices[
																						index
																					],
																				book,
																				'2',
																				additionalValues.minOrderQty
																			)
																		}
																	>
																		{formatPrice(
																			parseFloat(
																				book
																					.bidPrices[
																						index
																					]
																			)
																		)}
																	</StyledTableCell>
																	<StyledTableCell
																		align="right"
																		cursor={
																			'pointer !important'
																		}
																		style={{
																			backgroundColor:
																				rowColors[
																					index
																				],
																			color: '#FFF',
																		}}
																		onClick={() =>
																			openBullet(
																				book
																					.askPrices[
																						index
																					],
																				book,
																				'1',
																				additionalValues.minOrderQty
																			)
																		}
																	>
																		{formatPrice(
																			parseFloat(
																				book
																					.askPrices[
																						index
																					]
																			)
																		)}
																	</StyledTableCell>
																	<StyledTableCell
																		align="right"
																		style={{
																			backgroundColor:
																				rowColors[
																					index
																				],
																			color: '#FFF',
																		}}
																		// ID issue 1547967379
																		// onClick={() =>
																		//     openBullet(
																		//         book
																		//             .askPrices[
																		//             index
																		//         ],
																		//         book,
																		//         '1',
																		//         book
																		//             .askQuantities[
																		//             index
																		//         ],
																		//     )
																		// }
																	>
																		{numberFormat(
																			book
																				.askQuantities[
																					index
																				],
																			'',
																			false
																		)}
																	</StyledTableCell>
																</TableRow>
															);
														}
													)}
											</TableBody>
										</Table>
									</ResultDiv>
								)}
							</>
						)}
					</>
				) : (
					<LinearProgress />
				)}

				<ButtonsLine>
					<WidgetButton
						color={type === 'trade' ? '#2781D5' : 'white'}
						type="button"
						onClick={() => setType('trade')}
					>
						RESUMO
					</WidgetButton>
					<WidgetButton
						data-cy="bookButton"
						color={type === 'book' ? '#2781D5' : 'white'}
						type="button"
						onClick={() => setType('book')}
					>
						Book
					</WidgetButton>

					<WidgetButton
						color={type === 'metrics' ? '#2781D5' : 'white'}
						type="button"
						onClick={() => setType('metrics')}
					>
						MÉTRICAS
					</WidgetButton>
				</ButtonsLine>
			</WidgetContent>
		</Container>
	);
});

export default SymbolQuotation;
