import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { apiAWS } from '../../../../services/api';

import { Box, CircularProgress, TextField, Typography } from '@mui/material';

import { ModalTemplate } from '../Template';
import { ButtonsContainer, GeneralButton } from '../styles';

export const VerificationCode = ({
	open,
	handleClose,
	setSnackbarState,
	transactionalToken,
	setOpenedModal,
	setTransactionalToken,
}) => {
	const { t } = useTranslation();

	const { email } = useSelector(state => state.auth);

	const [loading, setLoading] = useState(false);
	const [userToken, setUserToken] = useState('');

	function tokenValidator() {
		if (!userToken || userToken === '') {
			setSnackbarState(state => ({
				...state,
				isOpen: true,
				autoHide: 3000,
				severity: 'error',
				message: t('modules.settings.credentials.alerts.invalidToken')
			}));

			return;
		}

		const header = { headers: { 'x-authorization': transactionalToken } };

		setLoading(true);

		apiAWS
			.get(`/credentials/tokens/${userToken?.toUpperCase()}`, header)
			.then(response => {
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					autoHide: 3000,
					severity: 'success',
					message: t('modules.settings.credentials.alerts.correctToken')
				}));

				setTransactionalToken(response.data.transactional_token);
				setOpenedModal('new-signature');
			})
			.catch(error => {
				console.error(error);
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					autoHide: 3000,
					severity: 'error',
					message: t('modules.settings.credentials.alerts.invalidToken')
				}));
			})
			.finally(() => {
				setLoading(false);
				setUserToken('');
			});
	}

	return (
		<ModalTemplate
			open={open}
			title="Esqueci ou não tenho assinatura eletrônica"
			onClose={() => setUserToken('')}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 1,
					px: 2,
					'@media screen and (min-width: 760px)': {
						maxWidth: '40rem'
					},
				}}
			>
				<Typography variant="subtitle1">
					O <strong>CÓDIGO DE VERIFICAÇÃO</strong> para criar uma nova assinatura eletrônica foi enviada para
					o e-mail cadastrado: <strong>{email}</strong>
				</Typography>
				<Typography variant="subtitle1">
					Digite abaixo o <strong>CÓDIGO DE VERIFICAÇÃO</strong> enviado para definir sua nova assinatura
					eletrônica.
				</Typography>

				<TextField
					value={userToken}
					type="text"
					fullWidth
					variant="outlined"
					size="small"
					onChange={e => setUserToken(e.target.value)}
				/>

				<ButtonsContainer >
					<GeneralButton
						variant="contained"
						buttonColor="danger"
						onClick={handleClose}
					>
						Fechar
					</GeneralButton>
					<GeneralButton
						variant="contained"
						buttonColor="success"
						onClick={tokenValidator}
					>
						{loading ? <CircularProgress size={20} /> : 'CONFIRMAR'}
					</GeneralButton>
				</ButtonsContainer>
			</Box>
		</ModalTemplate>
	);
};
