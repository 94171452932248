import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';

import { setModalPreference } from 'store/modules/userPreferences/actions';
import { postNotification } from 'services/userPreferences/notifications';

import { ModalTemplate } from '../../AppHeader/Modals/Template';
import { GeneralButton } from '../GeneralButton';
import MaterialCheckBox from 'components/MaterialComponents/MaterialCheckBox';
import { Container, SubmitBox } from './styles';

export const PortalSignature = ({ open, handleClose }) => {
	const dispatch = useDispatch();

	const { username, token } = useSelector(state => state.auth);

	const [checked, setChecked] = useState(false);

	function toggleCheckbox(e) {
		setChecked(e.target.checked);
	}

	function handleSubmit() {
		if (checked) {
			dispatch(setModalPreference('portalSignature', checked));
			postNotification(username, token);
		}

		handleClose();
	}

	return (
		<ModalTemplate
			open={open}
			onClose={handleClose}
			title='ATENÇÃO!'
		>
			<Container>
				<Typography variant="h6">
                    Assinatura eletrônica incorreta. Utilize a mesma assinatura eletrônica do portal da Órama.
				</Typography>

				<SubmitBox>
					<MaterialCheckBox
						checked={checked}
						onChange={toggleCheckbox}
						label="Não exibir novamente"
					/>

					<GeneralButton
						buttonColor='warning'
						onClick={handleSubmit}
					>
                        Entendi
					</GeneralButton>
				</SubmitBox>
			</Container>
		</ModalTemplate>
	);
};
