export const ProductsActionTypes = {
	SET_ACTIVE_PRODUCT: 'products/SET_ACTIVE_PRODUCT',
	SET_PRODUCTS: 'products/SET_PRODUCTS',
	SET_USER_SIGNATURES: 'products/SET_USER_SIGNATURES',
	RESET_PRODUCTS_STATE: 'products/RESET_PRODUCTS_STATE',
	SET_BULLET_CATEGORIES: 'products/SET_BULLET_CATEGORIES',
	SET_BULLET_TYPES: 'products/SET_BULLET_TYPES',
	SET_PERMITTED_BULLETS: 'products/SET_PERMITTED_BULLETS',
	SET_CONTRACT_VISIBILITY: 'products/SET_CONTRACT_VISIBILITY',
	SET_CONTRACTS_DATA: 'products/SET_CONTRACTS_DATA',
	SET_CONTRACT_TO_ACCEPT: 'products/SET_CONTRACT_TO_ACCEPT',
	SIGN_CONTRACT: 'products/SIGN_CONTRACT',
};
