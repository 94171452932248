import styled from 'styled-components';

import { Dialog } from '@mui/material';

export const StyledDialog = styled(Dialog)`
	.MuiDialog-paper {
		background: #424242 !important;
		color: #fff !important;

		.MuiButton-outlinedPrimary {
			border: 1px solid #ffffff80 !important;
			color: #fff !important;
		}

		.alert-message {
			font-size: 1.1rem !important;
		}
	}
`;
