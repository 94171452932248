const cleanFxorderLeg = (hash, account, bullet) => {
	const cleanLeg = {
		ILegAllocAccount: account(),
		LegSecurityExchange: hash.StrategyLegs[0].LegSecurityExchange
			? hash.StrategyLegs[0].LegSecurityExchange
			: bullet.StrategyInfos[0].subExchange
				? bullet.StrategyInfos[0].subExchange
				: 'XBSP',
		LegSide: hash.StrategyLegs[0].LegSide,
		LegSymbol: hash.StrategyLegs[0].LegSymbol,
		LegOrdType: '2',
	};
	return cleanLeg;
};

export default cleanFxorderLeg;
