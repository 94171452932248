import { toast } from 'react-toastify';
import { apiAWS, apiMarketData } from 'services/api';
import NEW_ACTION, {
	STRATEGY_ADD,
	STRATEGY_REMOVE,
	VISIBILITY_SET,
	STRATEGY_NAME,
	STRATEGY_UPDATE,
	POSITION_UPDATE,
	SET_SAVED_STRATEGY,
	POSITION_DELETE,
	SET_INTEREST_RATE,
	GET_STRATEGIES,
	SAVE_STRATEGY,
	POSITION_UPDATE_PX,
	SET_INITIAL_STATE,
	SET_TIMER_UPDATE,
	RESET_STRATEGY,
	SUBSCRIBE_POSITIONS,
	REMOVE_FROM_STRATEGY_LIST,
} from './actionTypes';

import { format } from 'date-fns';
import { positionModel, strategyModel } from '../../../pages/Simulator/utils';
import { getSimulatedStrategies } from 'services/getSimulatedStrategies';
import { updateSavedParameter, updateTabId } from '../portfolio/actions';

export const setInitialStateSimulator = () => ({
	type: SET_INITIAL_STATE,
});
export const strategyAdd = strategy => ({
	type: STRATEGY_ADD,
	payload: { strategy },
});

export const strategyRemove = strategy => {
	return async (_dispatch, getState) => {
		const { token } = getState().auth;
		if (typeof strategy.id === 'string') {
			await apiAWS.delete(`/strategies/${strategy.id}`, {
				headers: { Authorization: `Bearer ${token}` },
			});
		}
		toast.success('Estratégia apagada com sucesso!');
		return _dispatch({
			type: STRATEGY_REMOVE,
			payload: { strategy },
		});
	};
};

export const handleNameStrategy = (id, value) => ({
	type: STRATEGY_NAME,
	payload: { id, value },
});

export const setStrategyVisibility = index => ({
	type: VISIBILITY_SET,
	payload: { index },
});

export const strategyUpdate = strategy => ({
	type: STRATEGY_UPDATE,
	payload: { strategy },
});
export const setSavedStrategy = (index, saved) => ({
	type: SET_SAVED_STRATEGY,
	payload: { saved, index },
});
export const updatePosition = (activeStrategy, positionId, data) => ({
	type: POSITION_UPDATE,
	payload: { activeStrategy, positionId, data },
});
export const updatePxPosition = (activeStrategy, positionId, data) => ({
	type: POSITION_UPDATE_PX,
	payload: { activeStrategy, positionId, data },
});
export const deletePosition = (position, index) => ({
	type: POSITION_DELETE,
	payload: { position, index },
});

export const getIr = cb => {
	return async _dispatch => {
		const { data } = await apiMarketData.get('/exchanges/BVMF/params');
		cb(data.IR);
		return _dispatch({
			type: SET_INTEREST_RATE,
			payload: { ir: data.IR },
		});
	};
};
export const setInterestRate = ir => ({
	type: SET_INTEREST_RATE,
	payload: { ir },
});

export const setTimerUpdate = timerUpdate => ({
	type: SET_TIMER_UPDATE,
	payload: { timerUpdate },
});

export const getStrategies = (maximumRetry = 3) => {
	return (_dispatch, getState) => {
		return _getStrategies(maximumRetry, _dispatch, getState);
	};
};

async function _getStrategies(maximumRetry, _dispatch, getState) {
	const { token } = getState().auth;
	await apiAWS
		.get('/strategies', {
			headers: { Authorization: `Bearer ${token}` },
			timeout: 25000, //10s
			timeoutErrorMessage:
				'Falha de comunicação com servidor ao trazer estratégias. Verifique a conexão.',
		})
		.then(function (response) {
			return _dispatch({
				type: GET_STRATEGIES,
				payload: {
					strategies: response.data.strategies.map(strategy => {
						return { ...strategy, persisted: true };
					}),
				},
			});
		})
		.catch(function (error) {
			if (maximumRetry > 0) {
				return _getStrategies(maximumRetry - 1, _dispatch, getState);
			} else {
				return toast.error(
					error?.message || 'Erro ao trazer estratégias'
				);
			}
		});
}

export const saveStrategy = strategy => {
	return async _dispatch => {
		try {
			let response;
			const update = typeof strategy.id === 'string';
			if (update) {
				response = await apiAWS.put(
					`/strategies/${strategy.id}`,
					strategy
				);
			} else {
				response = await apiAWS.post('/strategies', strategy);

				_dispatch(updateTabId(response.data.id, strategy.id));
			}

			_dispatch(
				updateSavedParameter({ id: response.data.id, saved: true })
			);

			getSimulatedStrategies(strategy);
			toast.success('Estratégia salva com sucesso!');

			return _dispatch({
				type: SAVE_STRATEGY,
				payload: {
					strategy,
					action: update ? 'updated' : 'created',
					strategyResponse: { ...response.data, persisted: true },
				},
			});
		} catch (error) {
			console.log(error);
			toast.error('Erro ao salvar estratégias');
		}
	};
};

/**
 *
 * refactor actions
 *
 */

export const strategyUpdateAction = strategy => ({
	type: NEW_ACTION.NEW_STRATEGY_UPDATE,
	payload: strategy,
});

//Prepare strategy with parameters set to be added
export const getSimStrategyToAdd = (
	strategy,
	username,
	account,
	strategyName = ''
) => {
	if (!strategy?.positions || strategy?.positions.length === 0) return null;

	const chart = document.getElementById('sim-chart');
	if (chart) chart.innerHTML = '';

	const createDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
	const stratIndex = -1; //reduce will handle
	const stratName = strategyName
		? strategyName
		: strategy.name
			? strategy.name
			: 'SIM-' + strategy?.underSymbol + '-' + createDate;

	const newStrategy = {
		...strategyModel,
		...strategy,
		index: stratIndex,
		id: new Date().getTime(),
		userName: username,
		account,
		createDate,
		saved: true,
		name: stratName,
		positions: strategy.positions
			.sort((a, b) => a.index - b.index)
			.map(p => {
				const position = {
					...positionModel,
					...p,
					id: new Date().getTime() + p.index,
					account,
					theorManualMode: false,
					exitManualMode: false,
					entryManualMode: false,
					daysToExpiration: p?.daysToExpiration || 0,
					entryGreeks: {
						delta:
							p?.entryGreeks?.delta ||
							(['CS', 'PS'].includes(p.securityType) &&
							!p.entryPxLocked
								? 1
								: 0),
						gamma:
							p?.entryGreeks?.gamma ||
							positionModel.entryGreeks.gamma,
						theta:
							p?.entryGreeks?.theta ||
							positionModel.entryGreeks.theta,
						vega:
							p?.entryGreeks?.vega ||
							positionModel.entryGreeks.vega,
						rho:
							p?.entryGreeks?.rho ||
							positionModel.entryGreeks.rho,
					},
					exitGreeks: {
						delta:
							p?.exitGreeks?.delta ||
							(['CS', 'PS'].includes(p.securityType) &&
							!p.exitPxLocked
								? 1
								: 0),
						gamma:
							p?.exitGreeks?.gamma ||
							positionModel.exitGreeks.gamma,
						theta:
							p?.exitGreeks?.theta ||
							positionModel.exitGreeks.theta,
						vega:
							p?.exitGreeks?.vega ||
							positionModel.exitGreeks.vega,
						rho: p?.exitGreeks?.rho || positionModel.exitGreeks.rho,
					},
				};

				if (['CS', 'PS'].includes(position.securityType)) {
					delete position.optionType;
					delete position.strikePx;
					delete position.daysToExpiration;
					delete position.expirationDate;
				}
				return position;
			}),
	};

	toast.success('Estratégia adicionada no simulador: ' + stratName);
	return strategyAdd(newStrategy);
};

export const resetStrategy = (id, strategy) => ({
	type: RESET_STRATEGY,
	payload: { id, strategy },
});

export const subscribePositions = (id, positions) => ({
	type: SUBSCRIBE_POSITIONS,
	payload: { id, positions },
});

export const removeFromStrategyList = id => ({
	type: REMOVE_FROM_STRATEGY_LIST,
	payload: { id },
});
