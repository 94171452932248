import {
	WIDGET_ADD,
	WIDGET_REMOVE,
	WIDGET_UPDATE,
	WIDGETS_UPDATE,
	WIDGET_EXPAND,
	WIDGET_CONTENT_UPDATE,
	WIDGET_SYMBOL_UPDATE,
	WIDGET_ADD_SYMBOL_QUOTATION
} from './actionTypes';

const initialState = {
	paperIsLoading: false,
	widgets: [],
};

const initialContent = {
	BlackScholesCalculator: {
		s: 0.18,
		k: 0.01,
		v: 0.01,
		dte: 1,
		ir: 2.0,
		optType: 'C',
		delta: '0,0000',
		gamma: '0,0000',
		theta: '0,0000',
		vega: '0,0000',
		rho: '0,0000',
		price: '0,00',
	},
	BookTrade: {
		definition: '',
		type: 'trade',
		symbol: '',
		connection: '',
		paper: {
			price: null,
			var: null,
			highPx: 0.0,
			lowPx: 0.0,
			finVol: 0.0,
			openPx: 0.0,
			closePx: 0.0,
		},
		book: {
			bidQuantities: [],
			bidPrices: [],
			askPrices: [],
			askQuantities: [],
		},
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case WIDGET_ADD: {
			const widget = {};
			widget.id = Math.random();
			widget.type = payload.widget;
			widget.expanded = true;
			widget.content = initialContent[widget.type];
			return { widgets: [...state.widgets, widget] };
		}

		case WIDGET_REMOVE: {
			const { widget } = payload;
			const newWidgets = state.widgets.filter(item => item.id !== widget);
			return { widgets: newWidgets };
		}

		case WIDGET_UPDATE: {
			const { id } = payload;
			const newWidgets = state.widgets.map(item => {
				return item.id === id
					? {
						...item,
						type: payload.type,
						content: initialContent[payload.type],
					}
					: item;
			});
			return { widgets: newWidgets };
		}

		case WIDGETS_UPDATE: {
			const { widgetList } = payload;

			return { widgets: widgetList };
		}

		case WIDGET_EXPAND: {
			const { id, expanded } = payload;
			const newWidgets = state.widgets.map(item => {
				return item.id === id ? { ...item, expanded } : item;
			});
			return { widgets: newWidgets };
		}

		case WIDGET_CONTENT_UPDATE: {
			const { id, content } = payload;
			const newWidgets = state.widgets.map(item => {
				return item.id === id ? content : item;
			});
			return { widgets: newWidgets };
		}

		case WIDGET_SYMBOL_UPDATE: {
			const { id, symbol } = payload;
			const newWidgets = state.widgets.map(item => {
				return item.id === id
					? { ...item, content: { ...item.content, symbol: symbol, type: 'book' } }
					: item;
			});
			return { widgets: newWidgets };
		}

		case WIDGET_ADD_SYMBOL_QUOTATION: {
			const { id, symbol, expanded, type } = payload;
			const widget = {
				id,
				type,
				expanded,
				content: {...initialContent[type], symbol, type: 'book'},
			};

			return { widgets: [...state.widgets, widget] };
		}

		default:
			return state;
	}
};
