import { apiExec } from './api';

import { store } from 'store';
import { setFavorite2FA, setUserAuthMethods } from 'store/modules/auth/actions';

export function getUserAuthMethods() {
	const params = { params: { detailed: true } };

	apiExec
		.get('/authenticators', params)
		.then(response => {
			store.dispatch(setUserAuthMethods(response.data.records));
			store.dispatch(
				setFavorite2FA(response.data.activeAuthenticatorType)
			);
		})
		.catch(error => {
			console.error(error);
		});
}
