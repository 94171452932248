import {
	SAVE_STORAGE,
	LOAD_STORAGE,
} from './actionTypes';

const initialState = {
	content:{
	},
	optionsChain: {

	}
};

const reducer = {
	[SAVE_STORAGE]: (stt, payload) => {
		const { content, module } = payload;
		const hash = {};
		hash[module] = content;
		return hash;
	},

	[LOAD_STORAGE]: (stt, payload) => {
		const { content } = payload;
		const newBullets = { ...stt.bullet, content };
		return {content: newBullets};
	},
};

export default function strategyFloatingReducer(state = initialState, { type, payload }) {
	if (typeof reducer[type] === 'function') {
		return reducer[type](state, payload);
	}
	return state;
};
