import { postCurrentStrategiesPreferences } from 'services/userPreferences/currentStrategies';
import { CurrentStrategiesActionTypes } from './actionTypes';
import { formatDefaultStrategies } from 'utils/strategiesHelpers';

export const currentStrategiesInitialState = {
	rows: [],
	preferences: {
		rowsExpanded: false,
		density: 'standard',
		filter: {
			filterModel: {
				items: [],
				linkOperator: 'and',
				quickFilterValues: [],
				quickFilterLogicOperator: 'and',
			},
		},
		columns: {
			orderedFields: [
				'__tree_data_group__',
				'Accounts',
				'Name',
				'Text',
				'CumQtyPerc',
				'TargetQtty',
				'ExecutedQtty',
				'TargetDesc',
				'AvgPrice',
				'Status',
				'CreateDate',
				'TimeInForce',
				'ExpireDate',
				'StrategyId',
				'ClOrdID',
				'StatusDate',
			],
			columnsSize: [],
			columnVisibilityModel: {
				StrategyId: false,
				ClOrdID: false,
			},
		},
		sorting: {
			sortModel: [
				{
					field: 'CreateDate',
					sort: 'asc',
				},
			],
		},
	},
};

export function currentStrategies(
	state = currentStrategiesInitialState,
	{ type, payload }
) {
	switch (type) {
		case CurrentStrategiesActionTypes.SET_ROWS:
			return { ...state, rows: payload.rows };
		case CurrentStrategiesActionTypes.ADD_STRATEGY: {
			let tableRows = [...state.rows];

			const existingStrategy = state.rows.find(
				row => row.ClOrdID === payload.strategy.ClOrdID
			);

			if (existingStrategy) {
				const isStrategyPending = [1, 99].includes(
					payload.strategy.Status
				);

				if (isStrategyPending) {
					return state;
				}

				const formattedRows = formatDefaultStrategies([
					payload.strategy,
				]);

				const mainIndex = tableRows.findIndex(
					tableRow => tableRow.id === payload.strategy.id
				);

				const filteredRows = tableRows.filter(
					tableRow => tableRow.hierarchy[0] !== payload.strategy.id
				);

				filteredRows.splice(mainIndex, 0, ...formattedRows);

				return {
					...state,
					rows: filteredRows,
				};
			}

			const formattedRows = formatDefaultStrategies([payload.strategy]);

			return {
				...state,
				rows: [...tableRows, ...formattedRows],
			};
		}
		case CurrentStrategiesActionTypes.UPDATE_USER_PREFERENCES: {
			return {
				...state,
				preferences: {
					...state.preferences,
					...payload.newPreferences,
				},
			};
		}
		case CurrentStrategiesActionTypes.UPDATE_COLUMNS_USER_PREFERENCES:
			return {
				...state,
				preferences: {
					...state.preferences,
					columns: {
						...state.preferences.columns,
						...payload.newColumnsPreferences,
					},
				},
			};
		case CurrentStrategiesActionTypes.UPDATE_COLUMNS_SIZE: {
			const { field, width } = payload;
			const newColumnsSize = [];

			const columnsSize = state.preferences.columns.columnsSize ?? [];

			const searchedColumn = columnsSize.find(
				column => column.field === payload.field
			);

			if (searchedColumn) {
				searchedColumn.width = payload.width;
			} else {
				newColumnsSize.push({ field, width });
			}

			return {
				...state,
				preferences: {
					...state.preferences,
					columns: {
						...state.preferences.columns,
						columnsSize: [...columnsSize, ...newColumnsSize],
					},
				},
			};
		}
		case CurrentStrategiesActionTypes.RESET_USER_PREFERENCES:
			postCurrentStrategiesPreferences(
				payload.username,
				currentStrategiesInitialState.preferences
			);

			return {
				...state,
				preferences: currentStrategiesInitialState.preferences,
			};
		case CurrentStrategiesActionTypes.RESET_STATE:
			return currentStrategiesInitialState;
		default:
			return state;
	}
}
