import React from 'react';
import { Form, Field } from 'react-final-form';

import { TextField } from 'final-form-material-ui';
import { Button, Link } from '@mui/material';

import { required } from 'utils/validators';
import { FormContainer } from 'styles/global';
import { Container } from './style';

export default function ResetTokenValidator({
	onSubmit,
	timeout,
	sendTokenToEmailUser,
}) {
	return (
		<Container>
			<div>Seu código vai expirar em {timeout}.</div>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit, submitting }) => (
					<FormContainer onSubmit={handleSubmit}>
						<Field
							name="token"
							type="text"
							component={TextField}
							label="Código"
							margin="normal"
							fullWidth
							variant="outlined"
							validate={required}
						/>

						<Button
							variant="contained"
							color="primary"
							fullWidth
							type="submit"
							disabled={submitting}
						>
							Validar código
						</Button>
					</FormContainer>
				)}
			/>
			{sendTokenToEmailUser && (
				<Link
					style={{ cursor: 'pointer' }}
					onClick={sendTokenToEmailUser}
				>
					{' '}
					Solicitar um novo código
				</Link>
			)}
		</Container>
	);
}
