import { idxLiveChartsActionTypes } from './actionTypes';

const initialState = {
	gradientStop: {
		finLimitLoss: null,
		finLimitGain: null,
	},
	visibleRange: {},
	priceRange: {},
	scrollPosition: {},
	chartData: {},
	toggleAsset: { WIN: true, WDO: true },
	toggleCount: 2,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case idxLiveChartsActionTypes.SET_STRATEGIES: {
			return { ...state, rows: payload };
		}
		case idxLiveChartsActionTypes.UPDATE_GRADIENT_STOP: {
			return { ...state, gradientStop: { ...payload.gradientStop } };
		}
		case idxLiveChartsActionTypes.SET_VISIBLE_RANGE: {
			return {
				...state,
				visibleRange: {
					...state.visibleRange,
					[payload.id]: payload.visibleRange,
				},
			};
		}
		case idxLiveChartsActionTypes.SET_PRICE_RANGE: {
			return {
				...state,
				priceRange: {
					...state.priceRange,
					[payload.id]: payload.priceRange,
				},
			};
		}
		case idxLiveChartsActionTypes.SET_CHART_DATA: {
			return {
				...state,
				chartData: {
					...state.chartData,
					[payload.id]: payload.chartData,
				},
			};
		}
		case idxLiveChartsActionTypes.SET_SCROLL_POSITION: {
			return {
				...state,
				scrollPosition: {
					...state.scrollPosition,
					[payload.id]: Math.round(payload.scrollPosition),
				},
			};
		}
		case idxLiveChartsActionTypes.SET_TOGGLE_ASSET: {
			return {
				...state,
				toggleAsset: {
					...state.toggleAsset,
					[payload.asset]: payload.toggleAsset,
				},
			};
		}
		case idxLiveChartsActionTypes.SET_TOGGLE_COUNT: {
			return {
				...state,
				toggleCount: payload.count,
			};
		}
		case idxLiveChartsActionTypes.RESET_IDX_CHART_STATE:
			return initialState;
		default:
			return state;
	}
};
