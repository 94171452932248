import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import SelectInput from '../SelectInput';
import DecimalInput from '../DecimalInput';
import paperValidator from 'utils/paperValidation';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const MarketMakerExecParams = ({ handleChange, stepFunction }) => {
	const { bullet } = useSelector((state) => state.bottomBullet);
	const { t } = useTranslation();

	const handleChangeTriggerInterceptor = (type, field, value) => {
		handleChange(type, field, value);
		handleChange('Custom', 'TriggerValue', 0.0);
		if (bullet.content.CustomParameters.ToMarketTriggerValueEnabled === 'Y') {
			handleChange('Custom', 'ToMarketTriggerValue', 0.0);
		}
	};

	const handleStopChanges = (value, customParam) => {
		handleChange('Custom', customParam, value);
	};

	return (
		<Grid item xs={6}>
			<Grid container spacing={1} direction="row">
				<Grid item xs={12} style={{ marginLeft: '3px' }}>
					<Typography variant="subtitle1">Parâmetros de execução</Typography>
				</Grid>
				<Grid item xs={3}>
					<SelectInput
						name="TriggerType"
						label="Base de cálculo"
						iconTooltip={t('bullets.marketmaker.trigger_type')}
						disabled={paperValidator(bullet.StrategyInfos[0], bullet)}
						defaultValue={1}
						onChange={(e) => handleChangeTriggerInterceptor('Custom', 'TriggerType', +e.target.value)}
						value={parseInt(bullet.content.CustomParameters.TriggerType, 10) ?? 1}
						selectOptions={[
							{
								value: 1,
								label: 'Ùltimo negócio',
							},
							{
								value: 2,
								label: 'Mid bid-ask',
							},
						]}
					/>
				</Grid>
				<Grid item xs={3}>
					<DecimalInput
						name="TriggerValue"
						label="Diferença de preço (%)"
						iconTooltip={t('bullets.marketmaker.trigger_value')}
						disabled={paperValidator(bullet.StrategyInfos[0], bullet)}
						minVal={0}
						maxVal={99999999}
						minDecScale={2} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="TriggerValue" // subtype
						stepSize={0.0001} // incremento
						valueToStep={bullet.content.CustomParameters.TriggerValue} // valor a ser modificado
						value={bullet.content.CustomParameters.TriggerValue * 100}
						onChange={(e) => handleStopChanges(parseFloat(e.value) / 100, 'TriggerValue')}
					/>
				</Grid>
				<Grid item xs={3}>
					<DecimalInput
						name="AgressorThresholdOn"
						label="Ativação do Agressor Threshold (%)"
						iconTooltip={t('bullets.marketmaker.agressor_threshold_on')}
						disabled={paperValidator(bullet.StrategyInfos[0], bullet)}
						defaultValue={90}
						minVal={0}
						maxVal={1}
						minDecScale={2} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="AgressorThresholdOn" // subtype
						stepSize={0.0001} // incremento
						valueToStep={bullet.content.CustomParameters.AgressorThresholdOn} // valor a ser modificado
						value={bullet.content.CustomParameters.AgressorThresholdOn * 100}
						onChange={(e) => handleChange('Custom', 'AgressorThresholdOn', parseFloat(e.value) / 100, 0)}
					/>
				</Grid>
				<Grid item xs={3}>
					<DecimalInput
						name="AgressorThresholdOff"
						label="Desativação do Agressor Threshold (%)"
						iconTooltip={t('bullets.marketmaker.agressor_threshold_off')}
						disabled={paperValidator(bullet.StrategyInfos[0], bullet)}
						defaultValue={80}
						minVal={0}
						maxVal={1}
						minDecScale={2} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="AgressorThresholdOff" // subtype
						stepSize={0.0001} // incremento
						valueToStep={bullet.content.CustomParameters.AgressorThresholdOff} // valor a ser modificado
						value={bullet.content.CustomParameters.AgressorThresholdOff * 100}
						onChange={(e) => handleChange('Custom', 'AgressorThresholdOff', parseFloat(e.value) / 100, 0)}
					/>
				</Grid>
				<Grid item xs={3}>
					<DecimalInput
						name="StopLossFinance"
						label="Stop Loss financeiro"
						iconTooltip={t('bullets.marketmaker.stop_loss_finance')}
						disabled={paperValidator(bullet.StrategyInfos[0], bullet)}
						defaultValue={-0.01}
						minVal={-999999}
						maxVal={-0.01}
						minDecScale={2} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="StopLossFinance" // subtype
						stepSize={0.01} // incremento
						valueToStep={bullet.content.CustomParameters.StopLossFinance} // valor a ser modificado
						value={bullet.content.CustomParameters.StopLossFinance}
						onChange={(e) => handleChange('Custom', 'StopLossFinance', parseFloat(e.value), 0)}
					/>
				</Grid>
				<Grid item xs={3}>
					<DecimalInput
						name="StopPositionQuantity"
						label="Qtd máxima em aberto"
						iconTooltip={t('bullets.marketmaker.stop_position_qty')}
						disabled={paperValidator(bullet.StrategyInfos[0], bullet)}
						defaultValue={bullet.StrategyInfos[0].RoundLot ?? 0 * 10}
						minVal={0}
						maxVal={99999999}
						minDecScale={0} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="StopPositionQuantity" // subtype
						stepSize={bullet.StrategyInfos[0].roundLot || bullet.StrategyInfos[0].Cst_MinOrderQuantity} // incremento
						valueToStep={bullet.content.CustomParameters.StopPositionQuantity} // valor a ser modificado
						value={bullet.content.CustomParameters.StopPositionQuantity}
						onChange={(e) => handleChange('Custom', 'StopPositionQuantity', parseInt(e.value, 10), 0)}
					/>
				</Grid>
				<Grid item xs={3}>
					<DecimalInput
						name="StopLossPerc"
						label="Limite de perda na apregoação (%)"
						iconTooltip={t('bullets.marketmaker.stop_loss_perc')}
						disabled={paperValidator(bullet.StrategyInfos[0], bullet)}
						defaultValue={30}
						minVal={0}
						maxVal={99999999}
						minDecScale={2} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="StopLossPerc" // subtype
						stepSize={0.0001} // incremento
						valueToStep={bullet.content.CustomParameters.StopLossPerc} // valor a ser modificado
						value={bullet.content.CustomParameters.StopLossPerc * 100}
						onChange={(e) => handleChange('Custom', 'StopLossPerc', parseFloat(e.value) / 100, 0)}
					/>
				</Grid>
				<Grid item xs={3}>
					<DecimalInput
						name="StopGainPerc"
						label="Limite de ganho na apregoação (%)"
						iconTooltip={t('bullets.marketmaker.stop_gain_perc')}
						disabled={paperValidator(bullet.StrategyInfos[0], bullet)}
						defaultValue={20}
						minVal={0}
						maxVal={99999999}
						minDecScale={2} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="StopGainPerc" // subtype
						stepSize={0.0001} // incremento
						valueToStep={bullet.content.CustomParameters.StopGainPerc} // valor a ser modificado
						value={bullet.content.CustomParameters.StopGainPerc * 100}
						onChange={(e) => handleChange('Custom', 'StopGainPerc', parseFloat(e.value) / 100, 0)}
					/>
				</Grid>
				<Grid item xs={3}>
					<DecimalInput
						name="RevertOnExecQty"
						label="Qtd em aberto para iniciar reversão"
						iconTooltip={t('bullets.marketmaker.revert_on_exec_qty')}
						disabled={paperValidator(bullet.StrategyInfos[0], bullet)}
						defaultValue={0}
						minVal={0}
						maxVal={99999999}
						minDecScale={0} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="RevertOnExecQty" // subtype
						stepSize={bullet.StrategyInfos[0].roundLot || bullet.StrategyInfos[0].Cst_MinOrderQuantity} // incremento
						valueToStep={bullet.content.CustomParameters.RevertOnExecQty} // valor a ser modificado
						value={bullet.content.CustomParameters.RevertOnExecQty}
						onChange={(e) => handleChange('Custom', 'RevertOnExecQty', parseInt(e.value, 10), 0)}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default MarketMakerExecParams;
