import { toast } from 'react-toastify';

import { api } from './api';
import { apiEls } from './apiEls';
import { apiAWS } from './apiAWS';
import { apiExec } from './apiExec';
import { apiCadastro } from './apiCadastro';
import { apiMarketData } from './apiMarketData';
import { setAuthErrorMessage } from 'store/modules/notifications/actions';
import { store } from 'store';


function showMessageErroWithoutStatusCode(error) {
	if (!(error.response && error.response.status)) {
		if (error && error.message && error.message === 'Network Error') {
			toast.error(
				'Problema ao enviar solicitação. Favor verifique sua conexão com a internet.'
			);
			store.dispatch(setAuthErrorMessage('Problema ao enviar solicitação. Favor verifique sua conexão com a internet.'));
		} else if (error && error.code && error.code === 'ECONNABORTED') {
			toast.error(
				'Problema ao processar a solicitação. Tempo limite alcançado!'
			);
		} else if (error) {
			toast.error(
				'Problema ao enviar solicitação. Entre em contato com a equipe de suporte.'
			);
		}
	}
}

export {
	api,
	apiMarketData,
	apiExec,
	apiEls,
	apiAWS,
	apiCadastro,
	showMessageErroWithoutStatusCode,
};
