import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { FileDownloadOutlined, FileUploadOutlined, Search } from '@mui/icons-material';
import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from '@mui/material';

import { setSearchFormValues } from 'store/modules/strategiesUpload/actions';

export const Header = ({
	loadings,
	searchForm,
	handleSearch,
	handleOpenUploadForm,
}) => {
	const dispatch = useDispatch();

	function handleChangeSearchFormValues(field, value) {
		dispatch(setSearchFormValues(field, value));
	}

	return (
		<form
			onSubmit={(event) => handleSearch(event)}
			style={{ width: '100%' }}
		>
			<Grid
				item
				container
				spacing={1}
				sx={{ mt: 1, justifyContent: 'space-between' }}
			>
				<Grid item xs={9} container gap={2}>
					<Grid item xs={2}>
						<TextField
							id="date"
							fullWidth
							size="small"
							type="date"
							label="Data"
							className='black-date-input'
							value={searchForm.date ?? moment(new Date()).format('YYYY-MM-DD')}
							InputLabelProps={{ shrink: true }}
							onChange={event => handleChangeSearchFormValues(event.target.id, event.target.value)}
						/>
					</Grid>

					<Grid item xs={2}>
						<FormControl fullWidth variant="outlined" size="small">
							<InputLabel htmlFor="list_size_label">
								Tamanho da lista
							</InputLabel>
							<Select
								id="list_size"
								name="list_size"
								label="Tamanho da lista"
								labelId="list_size_label"
								value={searchForm.list_size}
								onChange={event => handleChangeSearchFormValues(event.target.name, event.target.value)}
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={15}>15</MenuItem>
								<MenuItem value={30}>30</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={1.5}>
						<FormControl
							fullWidth
							size="small"
						>
							<LoadingButton
								startIcon={<Search />}
								variant="contained"
								type="submit"
								loading={loadings.isTableLoading}
							>
								Buscar
							</LoadingButton>
						</FormControl>
					</Grid>
				</Grid>

				<Grid
					container
					item
					xs={2}
					spacing={1}
				>
					<Grid item xs={6}>
						<FormControl
							fullWidth
							size="small"
						>
							<Button
								fullWidth
								variant="contained"
								startIcon={<FileDownloadOutlined />}
								onClick={() => window.open(process.env.REACT_APP_S3_FILELOADER_TEMPLATE, '_self')}
							>
								Template
							</Button>
						</FormControl>
					</Grid>

					<Grid item xs={6}>
						<FormControl
							fullWidth
							size="small"
						>
							<Button
								fullWidth
								variant="contained"
								startIcon={<FileUploadOutlined />}
								onClick={handleOpenUploadForm}
							>
								Upload
							</Button>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};
