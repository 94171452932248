import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateConfig } from 'store/modules/panelConfig/actions';
import Widgets from '../widgets';
import { isMobile } from '../../utils/isMobile';
import { DrawerMaterial, WidgetHandleButton } from '../../styles';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

export default function WidgetBar({ registerCallback, unRegisterCallback }) {
	const dispatch = useDispatch();
	const widgetDrawer = useSelector(state => state.configs.widgetBar);

	const [widgetOpen, setWidgetOpen] = useState(false);
	const [isTyping, setIsTyping] = useState(false);

	const handleWidgetDrawer = () => {
		setWidgetOpen(!widgetOpen);
		dispatch(updateConfig('widgetBar', !widgetDrawer));
		dispatch(updateConfig('widgetIsLoading', false));
	};

	return (
		<>
			<WidgetHandleButton
				variant="contained"
				disabled={isTyping}
				onClick={handleWidgetDrawer}
				style={{ right: widgetDrawer ? 250 : 0 }}
			>
				{widgetDrawer ? (
					<ArrowRight fontSize="large" />
				) : (
					<ArrowLeft fontSize="large" />
				)}
			</WidgetHandleButton>
			<DrawerMaterial
				variant="persistent"
				anchor="right"
				open={widgetDrawer}
				width={!widgetDrawer ? '0' : ''}
				style={{
					width: `${widgetDrawer ? '250px' : '0'}`,
					flex: isMobile && 0,
				}}
			>
				<div style={{ marginTop: '42px' }}>
					<Widgets
						registerCallback={registerCallback}
						unRegisterCallback={unRegisterCallback}
						widgetOpen={widgetDrawer}
						setIsTyping={setIsTyping}
					/>
				</div>
			</DrawerMaterial>
		</>
	);
}
