import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { apiCadastro } from 'services/api';
import { doLogout } from 'store/modules/auth/actions';

import { LoginForm } from '../../components/OramaLogin/LoginForm';
import { ContractStep } from '../../components/OramaLogin/ContractStep';

import Banner from '../../components/OramaLogin/Banner';
import { Loading } from '../../components/OramaLogin/Loading';
import { MaterialSnackbar } from '../../components/MaterialComponents/MaterialSnackbar';

import { Container, FormContainer } from './styles';

const LoginOrama = () => {
	const { token } = useSelector(state => state.auth);
	const { authErrorMessage } = useSelector(state => state.notifications);

	const { userToken } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [userInfo, setUserInfo] = useState({});
	const [isContractStep, setIsContractStep] = useState(false);
	const [authToken, setAuthToken] = useState('');
	const [contractInfo, setContractInfo] = useState({});

	// Verificando se o usuário está autenticado
	useEffect(() => {
		if (token) {
			dispatch(doLogout(history));
			history.push('/');
		}
	}, []);

	// Chamada da api de cadastro de usuário
	useEffect(() => {
		setLoading(true);

		const body = { service_type: 'page_validation' };
		const header = { headers: { Authorization: `Bearer ${userToken}` } };

		apiCadastro
			.post('/credentials/tokens', body, header)
			.then((response) => {
				setUserInfo(response.data);
				setOpenSnackbar(false);
			})
			.catch((error) => {
				console.log(error);
				setOpenSnackbar(true);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const handleCancelContractSign = () => {
		setIsContractStep(false);
	};

	return (
		<Container>
			<Banner />
			<FormContainer>
				{isContractStep ? (
					<ContractStep
						history={history}
						userInfo={userInfo}
						authToken={authToken}
						userToken={userToken}
						contractInfo={contractInfo}
						setLoading={setLoading}
						handleCancelContractSign={handleCancelContractSign}
					/>
				) : (
					<LoginForm
						history={history}
						userInfo={userInfo}
						disabled={openSnackbar}
						setLoading={setLoading}
						setAuthToken={setAuthToken}
						setContractInfo={setContractInfo}
						setIsContractStep={setIsContractStep}
						handleCancelContractSign={handleCancelContractSign}
					/>
				)}
			</FormContainer>
			<Loading open={loading} onClose={!loading} />

			<MaterialSnackbar
				open={openSnackbar}
				severity="error"
			>
				{authErrorMessage}
			</MaterialSnackbar>
		</Container>
	);
};

export default LoginOrama;
