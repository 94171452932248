import { SET_MODALS, SET_MODAL_PREFERENCE, SET_GOT_NOTIFICATIONS, SET_INVALID_SIGNATURE } from './types';

export const setModals = (modals) => ({
	type: SET_MODALS,
	payload: { modals },
});

export const setModalPreference = (modalOption, hasSeen) => ({
	type: SET_MODAL_PREFERENCE,
	payload: { modalOption, hasSeen },
});

export const setInvalidSignature = (isValid) => ({
	type: SET_INVALID_SIGNATURE,
	payload: { isValid },
});

export const setGotNotifications = (hasGottenNotifications) => ({
	type: SET_GOT_NOTIFICATIONS,
	payload: { hasGottenNotifications },
});
