import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import {
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	CircularProgress,
} from '@mui/material';

import { store } from 'store';
import { handleSaveStrategy } from 'utils/handleSaveStrategy';
import { removeFromStrategyList, resetStrategy } from 'store/modules/simulator/actions';
import { getSimulatedStrategy } from 'services/getSimulatedStrategies';

import { StyledDialog } from './styles';
import { updateSavedParameter } from 'store/modules/portfolio/actions';

export const UnsavedModal = ({ tabIndex, onClose, closeTab, activeStrategies }) => {
	const papers = useSelector(state => state.cache?.paper);
	const alertId = useSelector(state => state.portfolio?.alertId);
	const isModalOpen = useSelector(state => state.portfolio?.unsavedModal);

	const [isLoading, setIsLoading] = useState(false);

	function handleSave() {
		setIsLoading(true);

		const simulatorStrategy = store.getState().simulator.strategyList.find(strategy => strategy.id === alertId);
		const paper = papers[simulatorStrategy.underSymbol];

		if (simulatorStrategy) {
			const index = activeStrategies.indexOf(simulatorStrategy.id);
			handleSaveStrategy(simulatorStrategy, paper);
			onClose();
			closeTab(tabIndex, index);
		} else {
			toast.error('Erro ao salvar estratégia');
		}

		setIsLoading(false);
	}

	async function handleDiscard() {
		setIsLoading(true);

		let strategyIndex;
		const userToken = store.getState().auth.token;

		activeStrategies.forEach((strategy, index) => {
			if (strategy.id === alertId) {
				strategyIndex = index;
			}
		});

		const alreadyExists = store.getState().portfolio.strategies.find(strategy => strategy.id === alertId);

		if (alreadyExists) {
			const strategy = await getSimulatedStrategy(userToken, alertId);

			if (strategy) {
				store.dispatch(resetStrategy(alertId, strategy));
				store.dispatch(updateSavedParameter({ id: strategy.id, saved: true }));
			} else {
				toast.error('Erro ao salvar estratégia');
			}
		} else {
			store.dispatch(removeFromStrategyList(alertId));
		}

		setIsLoading(false);
		onClose();
		closeTab(tabIndex, strategyIndex);
	}

	return (
		<StyledDialog
			open={isModalOpen}
			onClose={onClose}
		>
			<DialogTitle id="alert-dialog-title">
				Atenção, estratégia não está salva!
			</DialogTitle>

			<DialogContent>
				<span className="alert-message">
					Você possui alterações pendentes na estratégia que está tentando fechar. Gostaria de salvá-la antes de fechar ou deseja descartar alterações?
				</span>
			</DialogContent>

			<DialogActions id="actions-container">
				{isLoading ? (
					<CircularProgress size={30} />
				) : (
					<>
						<Button
							variant="outlined"
							onClick={handleSave}>
							Salvar e fechar
						</Button>

						<Button
							variant="outlined"
							onClick={handleDiscard}>
							Descartar e fechar
						</Button>

						<Button
							variant="outlined"
							onClick={onClose}>
							Cancelar
						</Button>
					</>
				)}
			</DialogActions>
		</StyledDialog>
	);
};
