import React from 'react';

import GradientBullet from '../../MaterialGradient/GradientBullet';
import { DepletionGradient } from '../Gradients/Depletion';

const GradientBodyBullet = props => {
	const {
		handleChange,
		setPaperInfo,
		onSuggestionsFetchRequested,
		bullet,
		stepFunction,
	} = props;

	return (
		<>
			{[1, 2].includes(bullet.subUid) && (
				<GradientBullet
					handleChange={handleChange}
					setPaperInfo={setPaperInfo}
					onSuggestionsFetchRequested={onSuggestionsFetchRequested}
					subUid={bullet.subUid}
					stepFunction={stepFunction}
				/>
			)}

			{bullet.subUid === 3 && <DepletionGradient {...props} />}
		</>
	);
};

export default GradientBodyBullet;
