import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import 'styles/strategiesUpload.css';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { Box, Divider, Grid, Typography } from '@mui/material';

import { apiAWS } from 'services/api';
import { setRows } from 'store/modules/strategiesUpload/actions';
import { strategiesUploadColumns } from 'utils/tablesColumns/strategiesUpload';

import { Header } from './Header';
import { UploadForm } from './UploadForm';
import { StyledDataGridPro } from 'components/Portfolio/styles';
import { NoRowsOverlay } from 'components/Portfolio/NoRowsOverlay';
import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';

export const StrategiesUpload = () => {
	const apiRef = useGridApiRef();
	const dispatch = useDispatch();

	const { rows, searchForm } = useSelector(state => state.strategiesUpload);

	const [isUploadFormOpen, setIsUploadFormOpen] = useState(false);

	const [loadings, setLoadings] = useState({
		isTableLoading: false,
		isUploading: false,
	});

	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
	});

	function handleSearch(event) {
		if (event) {
			event.preventDefault();
		}

		setLoadings({ ...loadings, isTableLoading: true });

		const queryParams = {
			params: {
				service: 'strategy',
				file_date: searchForm.date,
				list_size: searchForm.list_size
			}
		};

		apiAWS
			.get('/files', queryParams)
			.then(response => {
				const formattedRows = response.data.map(file => {
					file.id = `${file.file_name}${file.load_date}`;
					return file;
				});

				dispatch(setRows(formattedRows));
			})
			.catch(error => {
				console.log(error);
				setSnackbarState({
					isOpen: true,
					message: `${error.response?.data?.title ?? 'Erro ao buscar arquivos'}. [${error.response?.status}]`
				});
			})
			.finally(() => setLoadings({ ...loadings, isTableLoading: false }));
	}

	function handleOpenUploadForm() {
		setIsUploadFormOpen(true);
	}

	function handleCloseUploadForm() {
		setIsUploadFormOpen(false);
	}

	function changeSnackbarState(newState) {
		setSnackbarState({
			...snackbarState,
			...newState
		});
	}

	return (
		<Grid
			container
			sx={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				gap: 0.5,
				p: '1.5rem 1rem 0.5rem'
			}}
		>
			<Typography variant="h5">
				<strong>Upload de estratégias</strong>
			</Typography>

			<Divider sx={{ mt: 1 }} />

			<Header
				loadings={loadings}
				searchForm={searchForm}
				handleSearch={handleSearch}
				handleOpenUploadForm={handleOpenUploadForm}
			/>

			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexGrow: 1
				}}
			>
				<StyledDataGridPro
					rows={rows}
					hideFooter
					apiRef={apiRef}
					checkboxSelection={false}
					loading={loadings.isTableLoading}
					columns={strategiesUploadColumns}
					components={{ NoRowsOverlay: () => NoRowsOverlay('') }}
					initialState={{
						sorting: {
							sortModel: [
								{
									field: 'load_date',
									sort: 'desc',
								}
							]
						}
					}}
				/>
			</Box>

			<UploadForm
				isOpen={isUploadFormOpen}
				handleSearch={handleSearch}
				handleClose={handleCloseUploadForm}
				changeSnackbarState={changeSnackbarState}
			/>

			<MaterialSnackbar
				open={snackbarState.isOpen}
				severity="error"
				handleClose={() => setSnackbarState({
					isOpen: false,
					message: '',
				})}
			>
				{snackbarState.message}
			</MaterialSnackbar>
		</Grid>
	);
};
