import React, { useRef } from 'react';

import {
	Button,
	Dialog,
	TextField,
	Typography,
	DialogTitle,
	DialogActions,
	DialogContent,
} from '@mui/material';

export const ExportMigrationDialog = ({
	isOpen,
	handleChangeMigrationStep,
	setUserToReceive,
	setSnackbarState,
}) => {
	const inputRef = useRef();

	function handleValidateUser() {
		if (!inputRef.current.value || inputRef.current.value.length === 0) {
			setSnackbarState(state => ({
				...state,
				isOpen: true,
				message: 'Informe o usuário corretamente.',
				severity: 'error',
			}));
			return;
		}

		setUserToReceive(inputRef.current.value);
		handleChangeMigrationStep('confirm-export-migration');
	}

	function handleClose() {
		handleChangeMigrationStep('');
	}

	return (
		<>
			<Dialog open={isOpen} onClose={handleClose}>
				<DialogTitle>Migrar estratégias do simulador</DialogTitle>

				<DialogContent
					sx={{
						gap: 1,
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Typography variant="subtitle1">
						Informe o usuário do qual deseja enviar uma{' '}
						<strong>CÓPIA</strong> de suas estratégias
					</Typography>

					<TextField
						inputRef={inputRef}
						label="Usuário"
						size="small"
						sx={{ mt: 1 }}
					/>
				</DialogContent>

				<DialogActions>
					<Button color="inherit" onClick={handleClose}>
						Cancelar
					</Button>

					<Button color="inherit" onClick={handleValidateUser}>
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
