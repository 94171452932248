import React, { useState, useEffect } from 'react';

export const CountdownTimer = ({ timerDuration, onTimerEnd, isTimerFinished, ...rest }) => {
	const [secondsLeft, setSecondsLeft] = useState(timerDuration);

	useEffect(() => {
		if (!isTimerFinished && secondsLeft > 0) {
			const timer = setInterval(() => {
				setSecondsLeft(prevSeconds => {
					const newSeconds = prevSeconds - 1;

					if (newSeconds <= 0) {
						clearInterval(timer);
						onTimerEnd();
					}

					return newSeconds;
				});
			}, 1000);

			return () => clearInterval(timer);
		}
	}, [isTimerFinished, secondsLeft, onTimerEnd]);

	const minutes = Math.floor(secondsLeft / 60);
	const seconds = secondsLeft % 60;

	const timeIsOver = secondsLeft <= 0;
	const label = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;

	return (
		<span {...rest}>
			{timeIsOver ? '' : label}
		</span>
	);
};
