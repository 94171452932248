import styled from 'styled-components';

import { DataGridPro } from '@mui/x-data-grid-pro';

export const StyledDataGridPro = styled(DataGridPro)`
	width: 100% !important;
	color: #ffffff !important;
	border: none !important;
	font-size: 11px !important;

	.iconButton,
	.MuiDataGrid-sortIcon,
	.MuiDataGrid-columnSeparator--resizable:hover,
	.MuiDataGrid-menuIcon svg {
		color: #ffffff !important;
	}

	.strategy-legs {
		background-color: #2f2f2f !important;

		&:hover {
			background-color: #424242 !important;
			border: 1px solid #424242 !important;
		}
	}

	.main-strategy:hover {
		background-color: #424242 !important;
		border-top: 1px solid #dcdcdc !important;
		border-bottom: 1px solid #dcdcdc !important;
	}

	.colored-positive {
		color: #31c593 !important;
	}

	.colored-negative {
		color: #ff7777 !important;
	}

	.paper-cell {
		font-weight: bold !important;
		font-size: 1rem !important;
	}

	.MuiDataGrid-filterIcon {
		color: #1976d2 !important;
	}

	.MuiDataGrid-pinnedColumns,
	.MuiDataGrid-columnHeader {
		background-color: #212121 !important;
	}

	.MuiDataGrid-booleanCell {
		color: #ffffff !important;
	}

	.clickable-text {
		cursor: pointer;
		padding: 0.25rem 0.5rem;
		border-radius: 5px;
		transition: background-color 0.2s;

		&:hover {
			background-color: rgba(147, 147, 147, 0.4);
		}
	}
`;

export const TableContainer = styled.div`
	height: 100%;
	width: 100%;
`;
