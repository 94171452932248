import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { apiAWS } from '../../../../services/api';

import { Box, CircularProgress, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import { Check, Close, Visibility, VisibilityOff } from '@mui/icons-material';

import { ModalTemplate } from '../Template';
import { PasswordTextField } from '../styles';
import { Buttons } from '../Buttons';

export const ChangeSignature = ({
	open,
	onClose,
	credentials,
	setCredentials,
	authToken,
	handleClose,
}) => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [passwordVisibility, setPasswordVisibility] = useState({
		0: false,
		1: false,
		2: false,
	});

	const changeVisibility = index => {
		setPasswordVisibility({
			...passwordVisibility,
			[index]: !passwordVisibility[index],
		});
	};

	const regexCheck = (inputType) => {
		let typeJunction = `${inputType}Signature`;
		const regexSignature = [/^[0-9]{6,6}$/.test(credentials.signature[typeJunction]), /^[0-9]{1,}$/.test(credentials.signature[typeJunction])];

		setCredentials({
			...credentials,
			signatureRegex: {
				...credentials.signatureRegex,
				caracteres: regexSignature[0],
				numbers: regexSignature[1],
			},
		});
	};

	const disabled = (credentials) => {
		if (
			credentials.signatureRegex.caracteres === true &&
			credentials.signatureRegex.numbers === true &&
			credentials.signature.newSignature !== '' &&
			credentials.signature.confirmationSignature !== '' &&
			credentials.signature.newSignature === credentials.signature.confirmationSignature
		) {
			return false;
		} else {
			return true;
		}
	};

	const handleSignatureChange = (inputType, inputValue) => {
		setCredentials({
			...credentials,
			signature: {
				...credentials.signature,
				[`${inputType}Signature`]: inputValue,
			},
		});
	};

	const handleNewSignature = () => {
		if (credentials.signature.newSignature !== credentials.signature.confirmationSignature) {
			toast.error(t('modules.settings.credentials.alerts.signaturesDontMatch'));
			return;
		}

		const header = { headers: { 'x-authorization': authToken } };

		const body = {
			old_password: credentials.signature.oldSignature,
			password: credentials.signature.newSignature,
			service: 'change_signature',
		};

		setLoading(true);

		apiAWS
			.post('/credentials/commands', body, header)
			.then(() => {
				toast.success(t('modules.settings.credentials.alerts.successSignatureChange'));
			})
			.catch(error => {
				toast.error(error.response?.data?.title || t('modules.settings.credentials.alerts.errorSignatureChange'));
			})
			.finally(() => {
				setLoading(false);
				handleClose();
			});
	};

	return (
		<ModalTemplate
			open={open}
			onClose={onClose}
			title="Trocar assinatura eletrônica"
		>
			<Box width="50rem">
				<Grid item xs={12}>
					<Typography style={{ margin: '8px 0 16px 0' }}>
						Preencha abaixo a nova assinatura eletrônica.
					</Typography>
				</Grid>
				<Grid container xs={12} spacing={2}>
					<Grid item xs={4}>
						<PasswordTextField
							value={credentials.signature.oldSignature}
							onChange={e => handleSignatureChange('old', e.target.value)}
							onKeyUp={() => regexCheck('old')}
							onFocus={() => regexCheck('old')}
							fullWidth
							label='Assinatura eletrônica antiga'
							type="text"
							variant="outlined"
							size="small"
							visible={passwordVisibility[0]}
							InputLabelProps={{ shrink: true }}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton size="small" onClick={() => changeVisibility(0)}>
											{passwordVisibility[0] ? <Visibility fontSize="medium" /> : <VisibilityOff fontSize="medium" />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={4}>
						<PasswordTextField
							value={credentials.signature.newSignature}
							onChange={e => handleSignatureChange('new', e.target.value)}
							onKeyUp={() => regexCheck('new')}
							onFocus={() => regexCheck('new')}
							fullWidth
							label='Nova assinatura eletrônica'
							type="text"
							variant="outlined"
							size="small"
							visible={passwordVisibility[1]}
							InputLabelProps={{ shrink: true }}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton size="small" onClick={() => changeVisibility(1)}>
											{passwordVisibility[1] ? <Visibility fontSize="medium" /> : <VisibilityOff fontSize="medium" />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={4}>
						<PasswordTextField
							value={credentials.signature.confirmationSignature}
							onChange={e => handleSignatureChange('confirmation', e.target.value)}
							onKeyUp={() => regexCheck('confirmation')}
							onFocus={() => regexCheck('confirmation')}
							fullWidth
							label='Repetir nova assinatura eletrônica'
							type="text"
							variant="outlined"
							size="small"
							visible={passwordVisibility[2]}
							InputLabelProps={{ shrink: true }}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton size="small" onClick={() => changeVisibility(2)}>
											{passwordVisibility[2] ? <Visibility fontSize="medium" /> : <VisibilityOff fontSize="medium" />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Typography style={{ margin: '8px 0' }}>Regras da senha</Typography>
					<Box
						color={credentials.signatureRegex.caracteres ? 'success.main' : 'error.main'}
						display="flex"
					>
						<Box pr={1}>
							{credentials.signatureRegex.caracteres ? <Check /> : <Close />}
						</Box>
						<Typography>Conter 6 caracteres</Typography>
					</Box>
					<Box
						color={credentials.signatureRegex.numbers ? 'success.main' : 'error.main'}
						display="flex"
					>
						<Box pr={1}>
							{credentials.signatureRegex.numbers ? <Check /> : <Close />}
						</Box>
						<Typography>Conter apenas números</Typography>
					</Box>
					<Box
						color={
							!credentials.signature.newSignature || !credentials.signature.confirmationSignature
								? 'error.main'
								: credentials.signature.newSignature === credentials.signature.confirmationSignature
									? 'success.main'
									: 'error.main'
						}
						display="flex"
					>
						<Box pr={1}>
							{!credentials.signature.newSignature || !credentials.signature.confirmationSignature ? (
								<Close />
							) : credentials.signature.newSignature === credentials.signature.confirmationSignature ? (
								<Check />
							) : (
								<Close />
							)}
						</Box>
						<Typography>A nova assinatura e sua confirmação devem coincidir</Typography>
					</Box>
				</Grid>
				<Buttons
					closeClick={handleClose}
					submitColor={disabled(credentials) ? 'disabled' : 'success'}
					disabled={disabled(credentials)}
					submitClick={handleNewSignature}
				>
					{loading ? <CircularProgress size={20} /> : 'SALVAR'}
				</Buttons>
			</Box>
		</ModalTemplate>
	);
};
