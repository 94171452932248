import ReactGA from 'react-ga4';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import React, { useState, useEffect, Suspense } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider, useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { LicenseInfo as xGridProLicenseInfo } from '@mui/x-license-pro';

import Routes from 'routes';
import { getExchangesBVMF } from 'services/getExchanges';
import { getNotifications } from 'services/userPreferences/notifications';
import { getOptionsTraderUserPreferences } from 'services/userPreferences/optionsTrader';

import {
	deleteItemBookSubscriptionList,
	deleteItemMiniBookSubscriptionList,
	deleteItemSnapshotSubscriptionList,
	deleteItemTradeSubscriptionList,
	deleteCandleSubscriptionList,
} from './store/modules/websocket/actions';
import { store, persistor } from './store';
import { setValidatedSignature } from 'store/modules/auth/actions';

import WebsocketComponent from './components/Websocket';
import { GlobalModals } from 'components/Modals/GlobalModals';

import './styles/global.css';
import themes from './themes';
import GlobalStyle from 'styles/global';

import {
	createTheme as createThemeV5,
	ThemeProvider as ThemeProviderV5,
} from '@mui/material';
import { ptBR } from '@mui/x-data-grid-pro';
import { getSimulatedStrategies } from 'services/getSimulatedStrategies';
import { getHistoryPreferences } from 'services/userPreferences/history';
import { getExecutionsPreferences } from 'services/userPreferences/executions';
import { getGlobalSettings } from 'services/userPreferences/globalSettings';
import { getSimulatorPreferences } from 'services/userPreferences/simulator';

import { getHolidays } from 'services/getHolidays';
import { getStrategies } from 'store/modules/simulator/actions';
import { getPositionsPreferences } from 'services/userPreferences/positions';
import { getRiskPreferences } from 'services/userPreferences/risk';
import { getCurrentStrategiesPreferences } from 'services/userPreferences/currentStrategies';
import {
	fetchRecentOrders,
	fetchSavedOrders,
} from 'services/fetchDynamoOrders';
import { getUserAuthMethods } from 'services/getUserAuthMethods';
import { getConsolidatedPositionsPreferences } from 'services/userPreferences/consolidatedPositions';
import { PRODUCT_TYPES } from 'utils/constants';
import { getGradientStops } from 'services/getGradientStops';

const callbacksList = {};

function App() {
	xGridProLicenseInfo.setLicenseKey(
		process.env.REACT_APP_MATERIAL_XGRID_PRO_LICENSE
	);

	ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);

	useEffect(() => {
		window.store = store;

		if (window.Cypress) {
			window.__store__ = store;
		}
	}, []);

	const getTopicFromMdType = mdType => {
		switch (mdType) {
			case 'trade':
				return process.env.REACT_APP_WBS_TOPIC_TRADE;
			case 'minibook':
				return process.env.REACT_APP_WBS_TOPIC_MINIBOOK;
			case 'book':
				return process.env.REACT_APP_WBS_TOPIC_BOOK;
			case 'candle':
				return process.env.REACT_APP_WBS_TOPIC_CANDLE;
			default:
				return '';
		}
	};

	const onMessageFromStreaming = (message, topic, mdType) => {
		let symbol = message.symbol;
		if (mdType === 'candle') {
			symbol = `${message.symbol}_${message.timeframe}`;
		}

		if (callbacksList[symbol] && callbacksList[symbol][mdType]) {
			callbacksList[symbol][mdType].forEach(item => {
				item.callback(message);
			});
		}

		const valueToReplace = getTopicFromMdType(mdType);
		const newTopic = topic.replace(valueToReplace, '');

		if (newTopic !== symbol) {
			if (callbacksList[newTopic] && callbacksList[newTopic][mdType]) {
				callbacksList[newTopic][mdType].forEach(item => {
					item.callback(message);
				});
			}
		}
	};

	function snapshotStreamingMessage(message) {
		if (callbacksList.snapshot) {
			callbacksList.snapshot.callback(message);
		}
	}

	const generateUUID = () => Math.random();

	const registerCallback = props => {
		const { key, type, callbackFunction } = props;
		const uuid = generateUUID();

		if (type === 'snapshot') {
			if (!callbacksList[type]) {
				callbacksList[type] = {};
			}

			callbacksList[type] = { uuid, callback: callbackFunction };
		}

		if (callbacksList[key]) {
			if (!callbacksList[key][type]) {
				callbacksList[key][type] = [];
			}
		} else {
			callbacksList[key] = {};
			callbacksList[key][type] = [];
		}

		callbacksList[key][type].push({ uuid, callback: callbackFunction });

		return uuid;
	};

	const handleDeleteSubscriptionSymbol = (symbol, type) => {
		switch (type) {
			case 'trade': {
				store.dispatch(deleteItemTradeSubscriptionList(symbol));
				break;
			}
			case 'book': {
				store.dispatch(deleteItemBookSubscriptionList(symbol));
				break;
			}
			case 'minibook': {
				store.dispatch(deleteItemMiniBookSubscriptionList(symbol));
				break;
			}
			case 'candle': {
				store.dispatch(deleteCandleSubscriptionList(symbol));
				break;
			}
			case 'snapshot': {
				store.dispatch(deleteItemSnapshotSubscriptionList(type));
				break;
			}
			default:
				console.log('tipo nao tratado: ' + type);
		}
	};

	const unRegisterCallback = id => {
		if (callbacksList?.snapshot?.uuid === id) {
			delete callbacksList.snapshot;
			handleDeleteSubscriptionSymbol(null, 'snapshot');
		}
		for (const symbol in callbacksList) {
			for (const type in callbacksList[symbol]) {
				if (Array.isArray(callbacksList[symbol][type])) {
					const indexToRemove = callbacksList[symbol][type]
						.map(item => {
							return item.uuid;
						})
						.indexOf(id);
					if (indexToRemove !== -1) {
						callbacksList[symbol][type].splice(indexToRemove, 1);
						if (
							callbacksList[symbol][type] &&
							callbacksList[symbol][type].length === 0
						) {
							handleDeleteSubscriptionSymbol(symbol, type);
						}
					}
				}
			}
		}
	};

	return (
		<Suspense fallback={<></>}>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<ThemeRender
						onMessageFromStreaming={onMessageFromStreaming}
						registerCallback={registerCallback}
						unRegisterCallback={unRegisterCallback}
						snapshotStreamingMessage={snapshotStreamingMessage}
					/>
				</PersistGate>
			</Provider>
		</Suspense>
	);
}

function ThemeRender({
	onMessageFromStreaming,
	registerCallback,
	unRegisterCallback,
	snapshotStreamingMessage,
}) {
	const dispatch = useDispatch();

	const { light, dark } = themes;
	const [theme, setTheme] = useState(light);
	const selectedTheme = useSelector(state => state.ui.theme);
	const {
		token: isAuthenticated,
		username,
		profile,
	} = useSelector(state => state.auth);
	const workspaceCreated = useSelector(
		state => state.simulator?.workspaceCreated
	);
	const activeProduct = useSelector(state => state.products.activeProduct);

	useEffect(() => {
		setTheme(selectedTheme === 'white' ? light : dark);
	}, [selectedTheme, light, dark]);

	useEffect(() => {
		if (isAuthenticated) {
			getHolidays();
			getExchangesBVMF();
			getUserAuthMethods();
			getSimulatedStrategies();
			getNotifications(username);
			getGlobalSettings(username);
			getSimulatorPreferences(username);
			getOptionsTraderUserPreferences();
			dispatch(setValidatedSignature(false));

			if (activeProduct.code !== PRODUCT_TYPES.FLEX_ANALYTIC) {
				fetchSavedOrders();
				fetchRecentOrders();
				getRiskPreferences(username);
				getHistoryPreferences(username);
				getPositionsPreferences(username);
				getCurrentStrategiesPreferences();
				getExecutionsPreferences(username);
				getConsolidatedPositionsPreferences(username);
			}

			if (
				[PRODUCT_TYPES.FLEX_PRO, PRODUCT_TYPES.FLEX_ADVANCED].includes(
					activeProduct.code
				)
			) {
				getGradientStops();
			}

			if (!workspaceCreated) {
				dispatch(getStrategies());
			}
		}
	}, [username, isAuthenticated, profile]);

	const themeV5 = createThemeV5(
		{
			palette: {
				mode: isAuthenticated ? 'dark' : 'light',
				primary: {
					main: '#2196f3',
				},
				secondary: {
					main: '#d32f2f',
				},
			},
			components: {
				MuiTooltip: {
					styleOverrides: {
						tooltip: {
							fontSize: '0.875rem',
						},
					},
				},
				MuiInputLabel: {
					styleOverrides: {
						root: {
							color: '#fff',
							textShadow: '1px 1px 1px black',
						},
					},
				},
			},
		},
		ptBR
	);

	const materialDarkTheme = createTheme({
		palette: {
			type: isAuthenticated ? 'dark' : 'light',
			secondary: {
				main: '#d32f2f',
			},
			primary: {
				main: '#2196f3',
			},
		},
		overrides: {
			MuiTooltip: {
				tooltip: {
					fontSize: '12px',
				},
			},
			MuiInputLabel: {
				root: {
					color: '#fff',
					textShadow: '1px 1px 1px black',
				},
			},
		},
	});

	return (
		<ThemeProviderV5 theme={themeV5}>
			<ThemeProvider theme={theme}>
				<MuiThemeProvider theme={materialDarkTheme}>
					{isAuthenticated && (
						<>
							<WebsocketComponent
								authToken={isAuthenticated}
								callbackOnMessage={onMessageFromStreaming}
								snapshotStreamingMessage={
									snapshotStreamingMessage
								}
							/>

							<GlobalModals />
						</>
					)}

					<Router>
						<CssBaseline />
						<GlobalStyle />
						<ToastContainer style={{ top: 45 }} autoClose={3000} />
						<Routes
							registerCallback={registerCallback}
							unRegisterCallback={unRegisterCallback}
						/>
					</Router>
				</MuiThemeProvider>
			</ThemeProvider>
		</ThemeProviderV5>
	);
}

export default App;
