import styled from 'styled-components';

import { TextField } from '@mui/material';

export const StyledInput = styled(TextField)`
	width: 30rem;
	color: #333 !important;
	box-shadow: none !important;
	font-size: 1rem !important;

	.MuiFormHelperText-root {
		font-size: 0.9rem !important;
	}

	.MuiInputLabel-root {
		text-shadow: none !important;
	}
`;
