import React from 'react';

import {
	GridToolbarExport,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import { PRODUCT_TYPES } from 'utils/constants';

export const HistoryToolbar = (isLoading, activeProduct) => {
	const isButtonsDisabled =
		isLoading || activeProduct.code === PRODUCT_TYPES.FLEX_ANALYTIC;

	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton
				color="inherit"
				disabled={isButtonsDisabled}
			/>

			<GridToolbarFilterButton
				color="inherit"
				disabled={isButtonsDisabled}
				title="Exibir filtros da página atual"
			/>

			<GridToolbarDensitySelector
				color="inherit"
				disabled={isButtonsDisabled}
			/>

			<GridToolbarExport color="inherit" disabled={isButtonsDisabled} />
		</GridToolbarContainer>
	);
};
