import { PositionsActionTypes } from './types';

export const setPositionsRows = rows => ({
	type: PositionsActionTypes.SET_POSITIONS_ROWS,
	payload: { rows },
});

export const setPositionsConsolidation = consolidation => ({
	type: PositionsActionTypes.SET_POSITIONS_CONSOLIDATION,
	payload: { consolidation },
});

export const updatePositionsUserPreferences = userPreferences => ({
	type: PositionsActionTypes.UPDATE_POSITIONS_USER_PREFERENCES,
	payload: { userPreferences },
});

export const updatePositionsColumnsPreferences = columnsPreferences => ({
	type: PositionsActionTypes.UPDATE_POSITIONS_COLUMNS_PREFERENCES,
	payload: { columnsPreferences },
});

export const resetPositionsState = () => ({
	type: PositionsActionTypes.RESET_POSITIONS_STATE,
});

export const setCurrentPositionsFilters = (field, value) => ({
	type: PositionsActionTypes.SET_CURRENT_POSITIONS_FILTERS,
	payload: { field, value },
});
