import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { IconButton } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';

import { HeaderToolsContainer } from './styles';

import { setColorMode } from 'store/modules/ui/actions';
import { doLogout, signOut } from 'store/modules/auth/actions';
import { setConfig, updateConfig } from 'store/modules/panelConfig/actions';

import { UserMenu } from './UserMenu';
import { LightTooltip } from 'components/LightTooltip';

export function HeaderTools({ openedModal, handleOpenModal }) {
	const history = useHistory();
	const dispatch = useDispatch();

	const { menuAnchorEl } = useSelector(state => state.configs);
	const { isDisconnected } = useSelector(state => state.auth);

	const openMenu = Boolean(menuAnchorEl);

	function handleOpenMenu(event) {
		dispatch(setConfig({ menuAnchorEl: Boolean(event.currentTarget) }));
	}

	function handleCloseMenu() {
		if (openedModal === '') {
			dispatch(setConfig({ menuAnchorEl: null }));
		}
	}

	useEffect(() => {
		if (isDisconnected) {
			dispatch(signOut());
		}
	}, [isDisconnected]);

	// Controle de logout de outras tabs
	useEffect(() => {
		if (!window.Cypress) {
			const handleInvalidToken = e => {
				if (
					e.key === '_sisemtypy' &&
					e.oldValue !== e.newValue &&
					e.newValue
				) {
					dispatch(doLogout(history));
				}
			};

			window.addEventListener('storage', handleInvalidToken);

			return function cleanup() {
				window.removeEventListener('storage', handleInvalidToken);
			};
		}
	}, []);

	useEffect(() => {
		dispatch(setColorMode('dark'));
		dispatch(updateConfig('theme', 'dark'));
	}, []);

	const location = useLocation();

	useLayoutEffect(() => {
		if (!location.pathname == '/simulador') {
			return;
		}
	}, [location.pathname, dispatch]);

	return (
		<HeaderToolsContainer>
			<LightTooltip title="Menu">
				<IconButton onClick={event => handleOpenMenu(event)}>
					<AccountCircle
						fontSize="large"
						sx={{ color: '#fffffff2' }}
					/>
				</IconButton>
			</LightTooltip>

			<UserMenu
				open={openMenu}
				anchorEl={menuAnchorEl}
				onClose={handleCloseMenu}
				handleOpenModal={handleOpenModal}
			/>
		</HeaderToolsContainer>
	);
}
