import React, { useState } from 'react';

import {
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';

import { Button } from '@mui/material';
import { Sync, ViewWeek } from '@mui/icons-material';

import { ColumnsPanel } from './ColumnsPanel';

export function Toolbar(resetColumns) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<GridToolbarContainer>
			<Button
				variant="text"
				color="inherit"
				onClick={() => setIsOpen(true)}
				startIcon={<ViewWeek />}
			>
				Colunas
			</Button>

			<Button
				variant="text"
				color="inherit"
				onClick={resetColumns}
				startIcon={<Sync />}
			>
				Resetar Colunas
			</Button>

			<GridToolbarFilterButton color="inherit" />

			<GridToolbarDensitySelector color="inherit" />

			{isOpen && (
				<ColumnsPanel
					open={isOpen}
					handleClose={() => setIsOpen(false)}
				/>
			)}
		</GridToolbarContainer>
	);
}
