import { strategyBulletParsed } from '../utils/strategyBulletParsed';

export const parseMenuAction = async (clordid, action, payload, api = '', apiExec = '', showMessageErroWithoutStatusCode = '') => {

	const { positions } = payload;
	const { algos } = payload;

	let str = null;

	if (['EDIT', 'CLONE', 'REVERT', 'PARAMS'].includes(action)) {
		str = await strategyBulletParsed(clordid, positions, api);

		const strategyTemp = algos.find(
			algo => algo.api_code == str?.StrategyCode,
		);

		if (strategyTemp) {

			if (str.InitTime === undefined) {
				return {handleStrategyOptions:{}, accountStrategy:{accountXBSP:undefined, accountXBMF: undefined}};
			}

			const actionMapping = {
				'PARAMS': {paramsView: true},
				'CLONE': {clone: true},
				'REVERT': {revert: true}
			};
			//Retornar o objeto para fazer o disptach do handleStrategy
			const tooltip = strategyTemp.name;
			const uid = strategyTemp.api_code;

			const extraParams = actionMapping[action] ?? {};

			const handleStrategyOptions = {
				tooltip,
				visible: true,
				uid,
				values: str,
				editing: true,
				...extraParams
			};

			let accountXBSP = ['EDIT', 'PARAMS'].includes(action) ? undefined : '';
			let accountXBMF = ['EDIT', 'PARAMS'].includes(action) ? undefined : '';

			str.StrategyLegs.forEach(leg => {

				if (leg.LegSecurityExchange === 'XBSP') {
					accountXBSP = leg.ILegAllocAccount;
				} else {
					accountXBMF = leg.ILegAllocAccount;
				}

			});

			const accountStrategy = {
				accountXBSP,
				accountXBMF
			};

			return { handleStrategyOptions, accountStrategy };

		}
		else{
			return {handleStrategyOptions:{}, accountStrategy:{accountXBSP:undefined, accountXBMF: undefined}};
		}
	}

	if(apiExec != ''){
		apiExec.post(`/algos/${clordid}/commands?Action=${action}`).catch(error => {
			showMessageErroWithoutStatusCode(error);
		});
	}

};
