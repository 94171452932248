import {
	GLOBAL_STATUS_UPDATE_MARKET,
	GLOBAL_STATUS_UPDATE_EXECUTION,
	GLOBAL_STATUS_UPDATE_ALL,
} from './actionTypes';

export const updateStatusStreamingMarket = marketStatus => ({
	type: GLOBAL_STATUS_UPDATE_MARKET,
	payload: { marketStatus },
});

export const updateStatusStreamingExecution = executionStatus => ({
	type: GLOBAL_STATUS_UPDATE_EXECUTION,
	payload: { executionStatus },
});

export const updateStatusStreamingAll = (executionStatus, marketStatus) => ({
	type: GLOBAL_STATUS_UPDATE_ALL,
	payload: { executionStatus, marketStatus },
});
