import styled from 'styled-components';

import { Button } from '@mui/material';

export const StyledButton = styled(Button)`
	padding: 2px 8px !important;
	transform: 0.5s;
	color: ${props =>
		props.buttonColor === 'scanner'
			? '#303030 !important'
			: 'white !important'};

	background-color: ${props =>
		props.buttonColor === 'danger'
			? '#f44336a1 !important'
			: props.buttonColor === 'success'
				? '#206123a1 !important'
				: props.buttonColor === 'warning'
					? '#f57c00a1 !important'
					: props.buttonColor === 'info'
						? '#2196f3a1 !important'
						: props.buttonColor === 'scanner'
							? '#fffffff2 !important'
							: '#777777a1 !important'};

	:hover {
		transform: 0.5s;
		background-color: ${props =>
		props.buttonColor === 'danger'
			? '#f44336f2 !important'
			: props.buttonColor === 'success'
				? '#206123f2 !important'
				: props.buttonColor === 'warning'
					? '#f57c00f2 !important'
					: props.buttonColor === 'info'
						? '#2196f3f2 !important'
						: props.buttonColor === 'scanner'
							? '#ffffffa1 !important'
							: '#777777f2 !important'};
	}
`;
