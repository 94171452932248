import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MaterialCheckBox from 'components/MaterialComponents/MaterialCheckBox';

import { getStrategy } from '../utils';
import { updatePosition } from 'store/modules/simulator/actions';
import { setSavedStrategy } from 'store/modules/simulator/actions';

export const CheckedSelector = memo(function CheckedSelector({
	position,
	value,
}) {
	const dispatch = useDispatch();

	const strategy = useSelector(getStrategy);
	const activeStrategy = useSelector(state => state.simulator.activeStrategy);

	function handleCheckboxChange(position, isChecked) {
		const selectedItemCount = strategy?.positions.filter(
			i => i.checked
		).length;

		if (selectedItemCount === 1 && !isChecked) {
			return;
		}

		const data = { checked: isChecked };

		dispatch(updatePosition(activeStrategy, position.id, data));
		dispatch(setSavedStrategy(activeStrategy, false));
	}

	return (
		<MaterialCheckBox
			size="small"
			checked={value}
			color="primary"
			className="icon"
			onChange={() => handleCheckboxChange(position, !value)}
		/>
	);
});
