import styled from 'styled-components';

import { Button, Drawer } from '@mui/material';

export const DrawerMaterial = styled(Drawer)`
	background-color: ${props => props.theme.colors.background} !important;
	.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight.MuiDrawer-paperAnchorDockedRight.MuiPaper-elevation0 {
		transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
		${({ open }) =>
		!open &&
			`
			transform: translateX(951px) translateX(-701px) !important;
    		visibility: hidden;
  		`};
	}

	& > div {
		width: ${props => props.width};
		padding-top: 1rem;
		background-color: #303030;
		border-left: solid 1px #424242;
		z-index: 101;
		width: 250px;
	}

	& .MuiCard-root {
		margin: auto;
		width: 200px;
		background: none;
		border: none;
	}
	.order-content {
		background: #fff;
	}
`;

export const WidgetHandleButton = styled(Button)`
	min-height: 0 !important;
	min-width: 16px !important;
	max-width: 16px !important;
	padding: 0 !important;
	z-index: 102;
	top: 48%;
	height: 60px;
	border-radius: 16px 0 0 16px !important;
	position: absolute !important;
	background: #d5d5d5 !important;

	> svg {
		color: #1c1c1c;
	}
`;

export const MainContent = styled.div`
	height: 100%;
	overflow-y: scroll;
	/* width */
	::-webkit-scrollbar {
		display: none;
	}

	/* Track */
	::-webkit-scrollbar-track {
		display: none;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		display: none;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		display: none;
	}
`;

export const Footer = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	font-size: 12px;
	background: #222222;
	color: #cccccc;

	.container {
		display: flex;
		justify-content: space-between;

		.infos {
			display: flex;
			flex-wrap: wrap;

			div {
				padding: 0 14px;
			}
		}

		.market-status {
			display: flex;
			align-items: center;
		}
	}
`;

export const LinkButton = styled.div`
	cursor: pointer;

	&:hover {
		opacity: 0.5;
	}
`;
