import { RecurrenceActionTypes } from './actionTypes';

export const resetRecurrenceState = () => ({
	type: RecurrenceActionTypes.RESET_STATE,
});

export const updateRecurrenceSearchForm = (field, value) => ({
	type: RecurrenceActionTypes.UPDATE_RECURRENCE_SEARCH_FORM,
	payload: { field, value },
});

export const updateRecurrencePaginationInfo = newPaginationInfo => ({
	type: RecurrenceActionTypes.UPDATE_RECURRENCE_PAGINATION_INFO,
	payload: { newPaginationInfo },
});

export const setRecurrenceRows = rows => ({
	type: RecurrenceActionTypes.SET_RECURRENCE_ROWS,
	payload: { rows },
});

export const resetRecurrenceSearchForm = () => ({
	type: RecurrenceActionTypes.RESET_SEARCH_FORM,
});

export const updateRecurrencePreferences = newPreferences => ({
	type: RecurrenceActionTypes.UPDATE_RECURRENCE_PREFERENCES,
	payload: { newPreferences },
});
