import React, { useLayoutEffect } from 'react';
import { ChartFactory } from '@investflex/volchartsjs/chart_factory';

import { Box, Typography } from '@mui/material';
import '@investflex/volchartsjs/styles.css';

export default function VolStudioCharts() {
	document.title = 'Vol. Studio | Flexscan';

	const symbol = 'PETR4';
	const workspaceId = 'w001';
	var workspaceCreated = false;

	const actionListener = async action => {
		if (action === 'state_change') {
			// const data = {
			//     state: params
			// };
			//dispatch(saveToDynamo("volStudioChart", data))
		}
	};

	useLayoutEffect(() => {
		const chartFactory = new ChartFactory();
		if (!workspaceCreated) {
			const catalog = chartFactory.getComponentsCatalog();
			const containers = [
				'meskew',
				'timelapseskew',
				'atmtermstruct',
				'deltacallput',
				'histvol',
				'impliedvol',
			];
			const chartTypes = catalog.map(d => d.code);
			chartFactory.createChartsWorkspace(
				workspaceId,
				containers,
				chartTypes,
				symbol,
				actionListener
			);
			workspaceCreated = true;
		} else {
			chartFactory.instantiateChartsWorkspace(workspaceId);
		}
	}, []);

	return (
		<>
			<div
				id="volstudio"
				style={{
					display: 'flex',
					flexWrap: 'wrap',
				}}
			>
				<Box p={1} width="100%">
					<Typography variant="h5">
						<strong>Vol Studio</strong>
					</Typography>
				</Box>
				<div className="vs-chart-div" id="meskew" />
				<div className="vs-chart-div" id="timelapseskew" />
				<div className="vs-chart-div" id="atmtermstruct" />
				<div className="vs-chart-div" id="deltacallput" />
				<div className="vs-chart-div" id="histvol" />
				<div className="vs-chart-div" id="impliedvol" />
			</div>
		</>
	);
}
