import { toast } from 'react-toastify';
import cleanCustomMarket from './singleFunctions/custom/market';

const povClear = (bullet, setAccessorInfos, account, t, validatePrice) => {
	const hash = { ...bullet.content };
	const strategyInfos = { ...bullet.StrategyInfos };

	if (hash.StrategyLegs.some(leg => leg.LegSymbol === '')) {
		toast.error(t('Select all symbols'));
		return;
	}

	if (hash.StrategyLegs[0].LegOrdType === 2 && validatePrice(hash.CustomParameters.PriceLimit)) {
		toast.error(t('O Preço limite precisa ser maior que 0'));
		return;
	}

	if (hash.StrategyLegs.some(e => e.LegQuantity <= 0)) {
		return { errorMessage: 'A quantidade precisa ser maior que 0' };
	}

	if (hash.StrategyLegs[0].LegOrdType === 1) {
		const { PriceLimit } = cleanCustomMarket(bullet);
		hash.CustomParameters.PriceLimit = PriceLimit;
	}

	hash.StrategyLegs[0] = {
		ILegAllocAccount: account(),
		LegSymbol: hash.StrategyLegs[0].LegSymbol,
		LegSecurityExchange: strategyInfos[0].subExchange ? strategyInfos[0].subExchange : 'XBSP',
		LegSide: hash.StrategyLegs[0].LegSide,
		LegQuantity: hash.StrategyLegs[0].LegQuantity,
		LegOrdType: parseInt(hash.StrategyLegs[0].LegOrdType),
		LegMaxClipSize: hash.StrategyLegs[0].LegMaxClipSize,
		LegFirstTimeOut: hash.StrategyLegs[0].LegFirstTimeOut,
		LegSecondTimeOut: hash.StrategyLegs[0].LegSecondTimeOut,
	};

	hash.CustomParameters = {
		PriceLimit: hash.CustomParameters.PriceLimit,
		TargetPercent: hash.CustomParameters.TargetPercent,
		AgressionLevel: parseInt(hash.CustomParameters.AgressionLevel),
		ConsiderCrossOrders: hash.CustomParameters.ConsiderCrossOrders,
		ConsiderCrossOrdersNotIntentional: hash.CustomParameters.ConsiderCrossOrdersNotIntentional,
		ConsiderPriceLimitInVolume: hash.CustomParameters.ConsiderPriceLimitInVolume,
		ParticipateOwnVolume: hash.CustomParameters.ParticipateOwnVolume,
	};

	delete hash.signature;
	delete hash.clean;
	hash.StrategyLegs = setAccessorInfos(hash.StrategyLegs);

	const cleanedHash = {
		'Name': hash.Name,
		'InitTime': hash.InitTime,
		'EndTime': hash.EndTime,
		'ExpireDate': hash.ExpireDate,
		'Text': hash.Text,
		'BasketId': hash.BasketId,
		'StrategyCode': hash.StrategyCode,
		'CustomParameters': hash.CustomParameters,
		'StrategyLegs': hash.StrategyLegs,
		'Signature': hash.Signature
	};

	return cleanedHash;
};

export default povClear;
