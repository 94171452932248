export default {
	title: 'dark',
	colors: {
		background: '#212121', // #1B1F27 => #151721
		boxColor: '#212121',
		bgMenu: '#333333;',
		stroke: '#707070',
		primary: '#CCCCCC',
		secondary: '#C4C4C4',
		highlight: '#3897f0',
		border: '#C4C4C4',
		iconActive: '#ddd',
		iconInactive: '#444',
		actionDrawerBg: '#212121',
		bulletBg: '#212121',
		inputBorder: '#D5D5D5',
		inputColor: '#FFF',
		inputBg: '#212121',
		selectBg: '#707070',
		strategyBg: '#212121',
		buyButtonColor: '#1976D2',
		sellButtonColor: '#E53935',
		demoEnvColor: '#9E252C',
		realEnvColor: '#259E90',
		buttonEditStrategy: '#F89706',
		buttonCreateStrategy: '#0C8B28',
		inputBgWidget: '#212121',
		textColorWidget: '#ccc',
		bgNotification: '#212121',
		bgNotificationViewed: '#202030',
		demoStripColor: '#9E252C',
		realStripColor: '#259E90',
		sideBar: '#333333',
		userMenu: '#212121',
		bgTitleBottomBullet: '#0F1017',
		textColorBottomBullet: '#ffffff',
		borderColorBottomBullet: '#ffffff',
		bgTitlesInsideBullet: '#202030',
		bgBottomBullet: '#0F1017',
		tableAlt: '#2f2f2f',
		bottomBulletRowPrimary: '#202030',
		bottomBulletRowSecondary: '#212121',
		textLabel: '#fff',
		inputSimulator: '#d7d7d7',
		iconFilterActive: '#F89706'
	},
	font: {
		size: '11px',
		title: '12px',
		headerIcons: '22px',
		leftIcons: '26px',
	},
};
