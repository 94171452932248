import React, { useEffect, useRef, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { EmailOutlined, PhoneAndroid } from '@mui/icons-material';
import { Button, Divider, TextField, Typography, colors } from '@mui/material';

import { apiExec } from 'services/api';

import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';

export const AppAuthentication = ({
	handleCancel,
	setAuthMethod,
	setLoginStep,
	authToken,
}) => {
	const inputRef = useRef();

	const [isLoading, setIsLoading] = useState(false);
	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'error',
	});

	useEffect(() => inputRef.current.focus(), []);

	function handleSubmit(event) {
		event.preventDefault();
		const inputValue = inputRef.current.value;

		if (inputValue && inputValue !== '') {
			const body = {
				type: 'app',
				otp: inputValue,
			};

			const headers = {
				headers: { Authorization: `Bearer ${authToken}` },
			};

			const formattedURL = `${process.env.REACT_APP_EXE_DMA_API_URL}/authenticate`;

			apiExec
				.post(formattedURL, body, headers)
				.then(() => setLoginStep('contractValidation'))
				.catch(error => {
					console.error(error);
					setSnackbarState(state => ({
						...state,
						isOpen: true,
						message: error.response?.data?.title || error.message,
						severity: 'error',
					}));
				})
				.finally(() => setIsLoading(false));
		} else {
			setSnackbarState(state => ({
				...state,
				isOpen: true,
				message: 'Preencha o campo corretamente.',
				severity: 'error',
			}));
		}
	}

	return (
		<>
			<Typography
				variant="h5"
				sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
			>
				<PhoneAndroid />
				Validação por aplicativo
			</Typography>

			<Typography variant="subtitle1" sx={{ color: colors.grey[800] }}>
				Abra seu app de 2FA (TOTP) para visualizar seu código de
				autenticação:
			</Typography>

			<form
				style={{
					gap: 24,
					marginBottom: 0,
					display: 'flex',
					flexDirection: 'column',
				}}
				onSubmit={event => handleSubmit(event)}
			>
				<TextField inputRef={inputRef} />

				<Button variant="contained" type="submit">
					Validar código
				</Button>
			</form>

			<Divider orientation="horizontal">
				<Typography
					variant="subtitle1"
					sx={{ color: colors.grey[500] }}
				>
					ou
				</Typography>
			</Divider>

			<LoadingButton
				variant="outlined"
				onClick={() => setAuthMethod('email')}
				color="inherit"
				startIcon={<EmailOutlined />}
				sx={{ opacity: 0.5, '&:hover': { opacity: 0.7 } }}
				loading={isLoading}
			>
				Validação por e-mail
			</LoadingButton>

			<Button onClick={handleCancel} color="error">
				Cancelar
			</Button>

			<MaterialSnackbar
				open={snackbarState.isOpen}
				severity={snackbarState.severity ?? 'error'}
				handleClose={() =>
					setSnackbarState(state => ({
						...state,
						isOpen: false,
					}))
				}
			>
				{snackbarState.message}
			</MaterialSnackbar>
		</>
	);
};
