import React from 'react';
import moment from 'moment';

import { getHour } from '../../getHour';
import { roundNumber } from '../../../../utils/numberFormat';
import { mediaRowsChartData } from '../../utils';

import {
	ContainerBody,
	HeaderContainer,
	HeaderMain,
	ItemHeader,
	ImageContainer,
	TitleTable,
	ContainerMain,
	TableStrategy,
	TableAnalisy,
	TitleTdAnalisy,
	TextdAnalisy,
	ContainerTables,
	ItemConteiner,
	TitlePay,
	TablePay,
	TitlethPay,
	TexttdPay,
	ItemsDivs,
	ContainerGrapich,
	ContainerDescribe,
	ContainerFooter,
	ImageDisclaimer,
	SignatureText,
	LinkDisclaimer,
	TextSignature,
	PowerBy,
	ImagePowerBy,
} from '../../style';

import guideLogo from '../../../../assets/images/guide_logo.png';
import assImg from '../../../../assets/images/guide_signature.png';
import powerImg from '../../../../assets/images/powered_by_Flexscan.png';
import marcaDagua from '../../../../assets/images/chart_water_mark.png';

const GuideTemplate = ({ dataPdf }) => {
	return (
		<ContainerBody id="container">
			<HeaderContainer>
				<HeaderMain>
					<ItemHeader height={'100%'} width={'20%'} align>
						<ImageContainer src={guideLogo} />
					</ItemHeader>
					<ItemHeader height={'70%'} width={'60%'} align>
						<h1>
							{dataPdf.strategy.strategyName == ''
								? 'Análise de Operação Estruturada'
								: dataPdf.strategy.strategyName}
						</h1>
					</ItemHeader>
					<ItemHeader height={'100%'} width={'20%'} align>
						<p>
							{getHour().data} {getHour().horas}
						</p>
					</ItemHeader>
				</HeaderMain>
			</HeaderContainer>
			<ContainerMain>
				<TitleTable>
					<p>COMPOSIÇÃO DA ESTRATÉGIA</p>
				</TitleTable>
				<TableStrategy style={{ borderCollapse: 'collapse' }}>
					<tr>
						<th>Operação</th>
						<th>Quantidade</th>
						<th>Tipo</th>
						<th>Preço</th>
						<th>Ativo</th>
						<th>Data Expiração</th>
						<th>Strike</th>
						<th>Custo</th>
					</tr>
					{dataPdf.strategy.positions.map(position => (
						<tr key={position.symbol}>
							<td>{position.qtty > 0 ? 'Compra' : 'Venda'}</td>
							<td id="alignDescribe">
								{position.qtty.toString().indexOf('-') == 0
									? parseInt(
										position.qtty
											.toString()
											.replace('-', '')
									  )
									: parseInt(position.qtty.toString())}
							</td>
							<td>
								{position.optionType !== undefined
									? position.optionType === 'C'
										? 'Call'
										: 'Put'
									: 'Ativo'}
							</td>
							<td id="alignDescribe">
								{Intl.NumberFormat('pt-BR', {
									style: 'currency',
									currency: 'BRL',
								}).format(position.entryPx)}
							</td>
							<td>{position.symbol}</td>
							<td>
								{position.expirationDate != undefined
									? moment(
										position.expirationDate,
										'YYYYMMDD'
									  ).format('DD/MM/YYYY')
									: '-'}
							</td>
							<td id="alignDescribe">
								{position.strikePx !== undefined
									? Intl.NumberFormat('pt-BR', {
										style: 'currency',
										currency: 'BRL',
									  }).format(position.strikePx)
									: '-'}{' '}
							</td>
							<td id="alignDescribe">
								{Intl.NumberFormat('pt-BR', {
									style: 'currency',
									currency: 'BRL',
								}).format(position.entryCost)}
							</td>
						</tr>
					))}
				</TableStrategy>
				<TitleTable>
					<p>ANÁLISE DA ESTRATÉGIA</p>
				</TitleTable>
				<TableAnalisy>
					<tr>
						<TitleTdAnalisy>Ativo Objeto</TitleTdAnalisy>
						<TextdAnalisy>
							{dataPdf.strategy.underSymbol}
						</TextdAnalisy>
						<TitleTdAnalisy>Lucro Máximo</TitleTdAnalisy>
						<TextdAnalisy>
							{dataPdf.strategy.maxProfit != 'unlimited'
								? Intl.NumberFormat('pt-BR', {
									style: 'currency',
									currency: 'BRL',
								  }).format(dataPdf.strategy.maxProfit)
								: 'Ilimitado'}
						</TextdAnalisy>
					</tr>
					<tr>
						<TitleTdAnalisy>Data Alvo</TitleTdAnalisy>
						<TextdAnalisy>
							{moment(
								dataPdf.strategy.expirationDate,
								'YYYYMMDD'
							).format('DD/MM/YYYY')}{' '}
							({dataPdf.strategy.dte} d.u)
						</TextdAnalisy>
						<TitleTdAnalisy>Lucro Máximo Percentual</TitleTdAnalisy>
						<TextdAnalisy>
							{dataPdf.strategy.maxProfitPerc === undefined
								? '-'
								: dataPdf.strategy.maxProfitPerc !== 'unlimited'
									? roundNumber(
										dataPdf.strategy.maxProfitPerc,
										2
								  ) + '%'
									: 'Ilimitado'}
						</TextdAnalisy>
					</tr>
					<tr>
						<TitleTdAnalisy>Custo</TitleTdAnalisy>
						<TextdAnalisy>
							{Intl.NumberFormat('pt-BR', {
								style: 'currency',
								currency: 'BRL',
							}).format(dataPdf.strategy.entryCost)}
						</TextdAnalisy>
						<TitleTdAnalisy>% Sobre CDI</TitleTdAnalisy>
						<TextdAnalisy>
							{dataPdf.strategy.cdiPerc !== null &&
							dataPdf.strategy.cdiPerc !== undefined
								? dataPdf.strategy.cdiPerc === 'unlimited'
									? 'Ilimitado'
									: roundNumber(dataPdf.strategy.cdiPerc, 2) +
									  '%'
								: '-'}
						</TextdAnalisy>
					</tr>
					<tr>
						<TitleTdAnalisy>Margem</TitleTdAnalisy>
						<TextdAnalisy>
							{dataPdf.strategy.margin != null
								? Intl.NumberFormat('pt-BR', {
									style: 'currency',
									currency: 'BRL',
								  }).format(dataPdf.strategy.margin)
								: 'N/D'}
						</TextdAnalisy>
						<TextdAnalisy></TextdAnalisy>
						<TextdAnalisy></TextdAnalisy>
					</tr>
				</TableAnalisy>
				<ContainerTables marginBottom={'20px'}>
					<ItemConteiner width={'45%'}>
						<TitlePay>
							<p>PAY-OFF</p>
						</TitlePay>
						<TablePay style={{ borderCollapse: 'collapse' }}>
							<tr>
								<TitlethPay>Preço do Ativo</TitlethPay>
								<TitlethPay>Resultado Financeiro</TitlethPay>
								<TitlethPay>Resultado %</TitlethPay>
							</tr>
							{mediaRowsChartData(
								dataPdf.strategy.chartData,
								dataPdf
							).map(pay => (
								<tr key={pay.underPx}>
									<TexttdPay>
										{Intl.NumberFormat('pt-BR', {
											style: 'currency',
											currency: 'BRL',
										}).format(pay.underPx)}
									</TexttdPay>
									<TexttdPay>
										{Intl.NumberFormat('pt-BR', {
											style: 'currency',
											currency: 'BRL',
										}).format(pay.px)}
									</TexttdPay>
									<TexttdPay>
										{isNaN(pay.var) === true
											? '-'
											: pay.var != null
												? roundNumber(pay.var, 2) + '%'
												: '-'}
									</TexttdPay>
								</tr>
							))}
						</TablePay>
					</ItemConteiner>
					<ItemConteiner width={'45%'}>
						<ItemsDivs height={'50%'} margin>
							<TitlePay>
								<p>Gráfico no Vencimento</p>
							</TitlePay>
							<ContainerGrapich>
								<div id="chart"></div>
								<div id="watermarkImg">
									<img src={marcaDagua} />
								</div>
							</ContainerGrapich>
						</ItemsDivs>
						<ItemsDivs height={'35%'}>
							<TitlePay>
								<p>Comentário de Estrutura</p>
							</TitlePay>
							<ContainerDescribe>
								{dataPdf.strategy.comments}
							</ContainerDescribe>
						</ItemsDivs>
					</ItemConteiner>
				</ContainerTables>
				<ContainerFooter style={{ marginTop: '15px' }}>
					<TextSignature size={'8px'} lineSpacing>
						Equipe de Investimentos:
					</TextSignature>
					<TextSignature size={'8px'} notBold lineSpacing>
						Renda Variável*
					</TextSignature>
					<ImageDisclaimer
						src={assImg}
						style={{ marginTop: '-7px' }}
					/>
					<SignatureText
						color={'red'}
						size={'9px'}
						sizeLineHeight={'1px'}
					>
						Fernando Siqueira dos Santos – CNPI
					</SignatureText>
					<LinkDisclaimer resizeFont resizeLine>
						research@guide.com.br
					</LinkDisclaimer>
					<SignatureText
						color={'#485460'}
						size={'6px'}
						bold
						sizeLineHeight={'2px'}
					>
						DISCLAIMER
					</SignatureText>
					<TextSignature size={'5.5px'} style={{ marginTop: '-7px' }}>
						*A área de Renda Variável é a responsável por todas as
						recomendações de valores mobiliários contidas neste
						relatório.
						<br />
						Este relatório foi elaborado pela Guide Investimentos
						S.A. Corretora de Valores, para uso exclusivo e
						intransferível de seu destinatário. Este relatório não
						pode ser reproduzido ou distribuído a qualquer pessoa
						sem a expressa autorização da Guide Investimentos S.A.
						Corretora de Valores. Este relatório é baseado em
						informações disponíveis ao público. As informações aqui
						contidas não representam garantia de veracidade das
						informações prestadas ou julgamento sobre a qualidade
						das mesmas e não devem ser consideradas como tal. Este
						relatório não representa uma oferta de compra ou venda
						ou solicitação de compra ou venda de qualquer ativo.
						Investir em ações envolve riscos. Este relatório não
						contêm todas as informações relevantes sobre a
						Companhias citadas. Sendo assim, o relatório não
						consiste e não deve ser visto como, uma representação ou
						garantia quanto à integridade, precisão e credibilidade
						da informação nele contida. Os destinatários devem,
						portanto, desenvolver suas próprias análises e
						estratégias de investimentos. Os investimentos em ações
						ou em estratégias de derivativos de ações guardam
						volatilidade intrinsecamente alta, podendo acarretar
						fortes prejuízos e devem ser utilizados apenas por
						investidores experientes e cientes de seus riscos. Os
						ativos e instrumentos financeiros referidos neste
						relatório podem não ser adequados a todos os
						investidores. Este relatório não leva em consideração os
						objetivos de investimento, a situação financeira ou as
						necessidades específicas de cada investidor.
						Investimentos em ações representam riscos elevados e sua
						rentabilidade passada não assegura rentabilidade futura.
						Informações sobre quaisquer sociedades, valores
						mobiliários ou outros instrumentos financeiros objeto
						desta análise podem ser obtidas mediante solicitações. A
						informação contida neste documento está sujeita a
						alterações sem aviso prévio, não havendo nenhuma
						garantia quanto à exatidão de tal informação. A Guide
						Investimentos S.A. Corretora de Valores ou seus
						analistas não aceitam qualquer responsabilidade por
						qualquer perda decorrente do uso deste documento ou de
						seu conteúdo. Ao aceitar este documento, concorda-se com
						as presentes limitações. Os analistas responsáveis pela
						elaboração deste relatório declaram, nos termos do
						artigo 21 da Instrução CVM nº.598/2018, que: (I)
						Quaisquer recomendações contidas neste relatório
						refletem única e exclusivamente as suas opiniões
						pessoais e foram elaboradas de forma independente,
						inclusive em relação à Guide Investimentos S.A.
						Corretora de Valores.
					</TextSignature>
					<PowerBy style={{ height: '80px' }}>
						<ImagePowerBy src={powerImg} />
					</PowerBy>
				</ContainerFooter>
			</ContainerMain>
		</ContainerBody>
	);
};

export default GuideTemplate;
