import React from 'react';
import { useDispatch } from 'react-redux';

import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography
} from '@mui/material';

import { store } from 'store';
import { setPartialGlobalSettings } from 'store/modules/panelConfig/actions';

export const SimulatorSettings = () => {
	const dispatch = useDispatch();

	const greekTypeInitialValue = store.getState().configs.globalSettings?.userSettings?.simulator.greekType;

	const exitPriceInitialValue = store.getState().configs.globalSettings?.userSettings?.simulator.exitPrice;

	function handleChangeSimulatorSettings(newSettings) {
		if (newSettings) {
			dispatch(setPartialGlobalSettings('simulator', newSettings));
		}
	}

	return (
		<>
			<Grid container flexDirection="column">
				<FormControl>
					<Typography variant="h6">
						Tela de resumo de estratégias
					</Typography>

					<FormLabel
						sx={{
							mt: 1,
							color: '#ffffff',
							'&.Mui-focused': {
								color: '#ffffff'
							}
						}}
					>
						Mostrar gregas
					</FormLabel>

					<RadioGroup
						defaultValue={greekTypeInitialValue}
						sx={{ '& label': { mb: 0 } }}
					>
						<FormControlLabel
							value="exit"
							control={<Radio sx={{ color: '#d1d1d1' }} />}
							label="De saída"
							onChange={(event) => handleChangeSimulatorSettings({ greekType: event.target.value })}
						/>

						<FormControlLabel
							value="entry"
							control={<Radio sx={{ color: '#d1d1d1' }} />}
							label="De entrada"
							onChange={(event) => handleChangeSimulatorSettings({ greekType: event.target.value })}
						/>

						<FormControlLabel
							value="theoretical"
							control={<Radio sx={{ color: '#d1d1d1' }} />}
							label="Teórica"
							onChange={(event) => handleChangeSimulatorSettings({ greekType: event.target.value })}
						/>
					</RadioGroup>
				</FormControl>

				<FormControl>
					<FormLabel
						sx={{
							mt: 1,
							color: '#ffffff',
							'&.Mui-focused': {
								color: '#ffffff'
							}
						}}
					>
						Preço de saída
					</FormLabel>

					<RadioGroup
						defaultValue={exitPriceInitialValue}
						sx={{ '& label': { mb: 0 } }}
					>
						<FormControlLabel
							value="market"
							control={<Radio sx={{ color: '#d1d1d1' }} />}
							label="Preço de mercado"
							onChange={(event) => handleChangeSimulatorSettings({ exitPrice: event.target.value })}
						/>

						<FormControlLabel
							value="medium"
							control={<Radio sx={{ color: '#d1d1d1' }} />}
							label="Preço médio"
							onChange={(event) => handleChangeSimulatorSettings({ exitPrice: event.target.value })}
						/>

						<FormControlLabel
							value="last"
							control={<Radio sx={{ color: '#d1d1d1' }} />}
							label="Preço do últ. negócio"
							onChange={(event) => handleChangeSimulatorSettings({ exitPrice: event.target.value })}
						/>
					</RadioGroup>
				</FormControl>
			</Grid>
		</>
	);
};
