import {
	SET_NOTIFICATIONS,
	SET_VIEWED_NOTIFICATION,
	CLOSE_NOTIFICATION,
	SET_CHECKED_NOTIFICATION,
	SET_AUTH_ERROR_MESSAGE,
} from './types';

const initialState = {
	notifications: [],
	authErrorMessage: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_AUTH_ERROR_MESSAGE:
			return {
				...state,
				authErrorMessage: payload.message,
			};

		case SET_NOTIFICATIONS:
			return { ...state, notifications: payload.notifications };

		case SET_VIEWED_NOTIFICATION:
			return {
				...state,
				notifications: state.notifications.map(n =>
					n.message_id === payload.notification.message_id
						? { ...n, viewed: true }
						: n
				),
			};

		case CLOSE_NOTIFICATION:
			return {
				...state,
				notifications: state.notifications.map(n =>
					n.message_id === payload.notification.message_id
						? { ...n, closed: true }
						: n
				),
			};

		case SET_CHECKED_NOTIFICATION:
			return {
				...state,
				notifications: state.notifications.map(n =>
					n.message_id === payload.notification.message_id
						? { ...n, checked: payload.value }
						: n
				),
			};

		default:
			return state;
	}
};
