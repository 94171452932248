import { SET_NEW_STRATEGY, SET_BULLET_DRAWER, SET_PAPER_SUGGESTIONS, CLEAR_STRATEGY } from './types';

const initialState = {
	newStrategy: null,
	bulletDrawer: false,
	paperSuggestions: {}
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_NEW_STRATEGY:
			return { ...state, newStrategy: payload.newStrategy };
		case SET_BULLET_DRAWER:
			return { ...state, bulletDrawer: payload.bulletDrawer };
		case SET_PAPER_SUGGESTIONS:
			return { ...state, paperSuggestions: payload.paperSuggestions };
		case CLEAR_STRATEGY:
			return { ...initialState };

		default:
			return state;
	}
};
