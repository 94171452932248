export function extractPositionsFromDescriptor(
	positionsDescriptor,
	expirationDate
) {
	const splitedLegs = positionsDescriptor.split('#');

	const positions = splitedLegs.map(item => {
		const separatedDesc = item.split('@');
		if (separatedDesc[separatedDesc.length - 1].length <= 4) {
			return {
				symbol: separatedDesc[1],
				securityType: 'OPT',
				optionType: separatedDesc[6],
				strikePx: parseFloat(separatedDesc[4]),
				expirationDate,
				daysToExpiration: parseInt(separatedDesc[5]), // cell.daysUntilExpiration,
				entryPx: parseFloat(separatedDesc[2]),
				exitImpliedVol: parseFloat(separatedDesc[3]),
				posStatus: 'O', // "O"pen , "C"losed (closed we use entryPx and exitPx to calculate simple payoff for the leg)
				qtty: parseFloat(separatedDesc[0]), // (+) compra, (-) venda
			};
		}
		return {
			symbol: separatedDesc[1],
			securityType: 'STOCK',
			expirationDate,
			entryPx: parseFloat(separatedDesc[2]),
			qtty: parseFloat(separatedDesc[0]),
			posStatus: 'O', // "O"pen , "C"losed (closed we use entryPx and exitPx to calculate simple payoff for the leg)
		};
	});
	return positions;
}
