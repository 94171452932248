import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 50rem;
    padding: 0 8px;

	img {
		width: 100%;
        border-radius: 5px;
	}

	button {
        margin-top: 16px;
	}
`;
