import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { RecurrenceToolbar } from './RecurrenceToolbar';
import { RecurrenceSearchForm } from './RecurrenceSearchForm';
import { StyledDataGridPro } from 'components/Portfolio/styles';
import { NoRowsOverlay } from 'components/Portfolio/NoRowsOverlay';
import { RecurrenceCustomDataTree } from './RecurrenceCustomDataTree';
import { RecurrenceCustomPagination } from './RecurrenceCustomPagination';
import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';

import { apiExec } from 'services/api';
import { formatRecurrences } from 'utils/strategiesHelpers';
import { recurrenceColumns } from 'utils/tablesColumns/recurrence';
import { recurrenceInitialState } from 'store/modules/recurrence/reducer';
import {
	setRecurrenceRows,
	updateRecurrencePaginationInfo,
	updateRecurrencePreferences,
} from 'store/modules/recurrence/actions';
import { PRODUCT_TYPES } from 'utils/constants';

export const Recurrence = ({ activeProduct }) => {
	document.title = 'Recorrências | Flexscan';

	const apiRef = useGridApiRef();
	const dispatch = useDispatch();

	const { rows, searchForm, paginationInfo, preferences } = useSelector(
		state => state.recurrence
	);

	const [isLoading, setIsLoading] = useState(false);
	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'success',
		autoHide: null,
	});

	useEffect(() => {
		if (activeProduct.code !== PRODUCT_TYPES.FLEX_ANALYTIC) {
			handleSearch();
		}
	}, []);

	function formatQueryParameters(form) {
		const formattedParameters = { ...form };

		Object.keys(formattedParameters).forEach(key => {
			if (['', 'all'].includes(formattedParameters[key])) {
				delete formattedParameters[key];
			}
		});

		return formattedParameters;
	}

	async function handleSearch(
		paginationInfo = recurrenceInitialState.paginationInfo,
		event = null,
		notify = false
	) {
		if (event) {
			event.preventDefault();
		}

		setIsLoading(true);

		try {
			const queryParams = {
				params: {
					...formatQueryParameters(searchForm),
					limit: paginationInfo.limit,
					offset: paginationInfo.offset,
					detailed: true,
				},
			};

			const { data } = await apiExec.get('/schedulers', queryParams);

			while (data.records.length >= 26) {
				data.records.pop();
			}

			const formattedRecurrences = formatRecurrences(data.records);

			const newPageInfo = {
				...recurrenceInitialState.paginationInfo,
				num_records: data.records.length,
				page: paginationInfo.page,
				offset: paginationInfo.offset,
				total: paginationInfo.total
					? paginationInfo.total + 25
					: recurrenceInitialState.paginationInfo.total,
			};

			dispatch(setRecurrenceRows(formattedRecurrences));
			dispatch(updateRecurrencePaginationInfo(newPageInfo));

			if (notify) {
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					severity: 'info',
					message: 'Recorrências atualizadas com sucesso.',
				}));
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	const groupingColDef = {
		headerName: 'Ações',
		type: 'actions',
		flex: 1,
		renderCell: params => (
			<RecurrenceCustomDataTree
				isLoading={isLoading}
				handleSearch={handleSearch}
				setSnackbarState={setSnackbarState}
				{...params}
			/>
		),
	};

	function onColumnVisibilityModelChange(event) {
		dispatch(
			updateRecurrencePreferences({
				columns: {
					...preferences.columns,
					columnVisibilityModel: { ...event },
				},
			})
		);
	}

	function onColumnOrderChange() {
		const columnsOrder = apiRef.current.getAllColumns().map(column => {
			return column.field;
		});

		dispatch(
			updateRecurrencePreferences({
				columns: {
					...preferences.columns,
					orderedFields: [...columnsOrder],
				},
			})
		);
	}

	function onTableStateChange(event) {
		if (event.density.value !== preferences.density) {
			const density = { density: event.density.value };

			dispatch(updateRecurrencePreferences(density));
		}
	}

	return (
		<>
			<Grid
				container
				sx={{
					gap: 0.5,
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					p: '1.5rem 0 0.5rem',
				}}
			>
				<RecurrenceSearchForm
					searchForm={searchForm}
					paginationInfo={paginationInfo}
					handleSearch={handleSearch}
					activeProduct={activeProduct}
				/>

				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexGrow: 1,
					}}
				>
					<StyledDataGridPro
						components={{
							Toolbar: () =>
								RecurrenceToolbar(isLoading, activeProduct),
							NoRowsOverlay: () =>
								NoRowsOverlay('Nenhuma recorrencia encontrada'),
							Pagination: () =>
								RecurrenceCustomPagination(
									apiRef,
									paginationInfo
								),
						}}
						rows={rows}
						loading={isLoading}
						columns={recurrenceColumns}
						density={preferences?.density ?? 'standard'}
						initialState={{
							columns: preferences?.columns,
							sorting: {
								sortModel: [
									{
										field: 'CreateDate',
										sort: 'asc',
									},
								],
							},
						}}
						treeData
						groupingColDef={groupingColDef}
						getTreeDataPath={row => row.hierarchy}
						apiRef={apiRef}
						pagination
						paginationMode="server"
						rowsPerPageOptions={[25]}
						page={paginationInfo.page}
						rowCount={paginationInfo.total}
						pageSize={25}
						onPageChange={newPage =>
							handleSearch({
								...paginationInfo,
								page: newPage,
								offset: newPage * 25,
							})
						}
						getRowClassName={params =>
							params.row.hierarchy.length === 1
								? 'main-strategy'
								: 'strategy-legs'
						}
						disableSelectionOnClick
						onStateChange={event => onTableStateChange(event)}
						onColumnOrderChange={event =>
							onColumnOrderChange(event)
						}
						onColumnVisibilityModelChange={event =>
							onColumnVisibilityModelChange(event)
						}
					/>
				</Box>
			</Grid>

			<MaterialSnackbar
				open={snackbarState.isOpen}
				severity={snackbarState.severity}
				autoHide={snackbarState.autoHide}
				handleClose={() =>
					setSnackbarState(state => ({ ...state, isOpen: false }))
				}
			>
				{snackbarState.message}
			</MaterialSnackbar>
		</>
	);
};
