import React from 'react';
import { Grid } from '@material-ui/core';
import DynamicAdjust from './DynamicAdjust';
import GradientFinalization from './Finalization/Finalization';
import GradientEntryParams from './objective/EntryParams';
import GradientStrategyRisk from './StrategyRisk';

const GradientBullet = ({
	setPaperInfo,
	onSuggestionsFetchRequested,
	subUid,
	stepFunction,
	handleChange,
}) => {
	return (
		<>
			<Grid item container xs={9}>
				<GradientEntryParams
					handleChange={handleChange}
					stepFunction={stepFunction}
					setPaperInfo={setPaperInfo}
					onSuggestionsFetchRequested={onSuggestionsFetchRequested}
					onlyCall={false}
					onlyOptions={false}
					subUid={subUid}
				/>
				<GradientFinalization
					handleChange={handleChange}
					stepFunction={stepFunction}
				/>

				<DynamicAdjust
					handleChange={handleChange}
					stepFunction={stepFunction}
				/>
			</Grid>
			<Grid item container spacing={2} xs={3}>
				<GradientStrategyRisk />
				{/* <InicitalRange /> */}
			</Grid>
		</>
	);
};

export default GradientBullet;
