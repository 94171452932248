import { SET_SEARCH, SET_QUICKSETTINGS } from './types';

export const setSearchScanner = filters => ({
	type: SET_SEARCH,
	payload: { filters },
});

export const setQuickSettings = quickSettings => ({
	type: SET_QUICKSETTINGS,
	payload: { quickSettings },
});
