import {
	SET_AUTH_ERROR_MESSAGE,
	SET_NOTIFICATIONS,
	SET_VIEWED_NOTIFICATION,
	CLOSE_NOTIFICATION,
	SET_CHECKED_NOTIFICATION,
} from './types';

export const setAuthErrorMessage = message => ({
	type: SET_AUTH_ERROR_MESSAGE,
	payload: { message },
});

export const setNotifications = notifications => ({
	type: SET_NOTIFICATIONS,
	payload: { notifications },
});

export const setViewedNotification = notification => ({
	type: SET_VIEWED_NOTIFICATION,
	payload: { notification },
});

export const closeNotification = notification => ({
	type: CLOSE_NOTIFICATION,
	payload: { notification },
});

export const setCheckedNotification = (notification, value) => ({
	type: SET_CHECKED_NOTIFICATION,
	payload: { notification, value },
});
