import styled from 'styled-components';

export const HeaderToolsContainer = styled.div`
	display: flex;
	margin-right: 1rem;

	.customized-menu .MuiMenu-paper {
		color: ${props => props.theme.colors.primary} !important;
		border: #757575 solid 1px !important;
	}

	.customized-menu .container-notification:hover {
		text-decoration: none;
		-webkit-text-decoration: none;
		background-color: none !important;
	}
`;

export const ToolItem = styled.div`
	display: flex;
	align-items: center;

	svg {
		cursor: pointer;
		font-size: ${props => props.theme.font.headerIcons};
	}

	.sound {
		:hover {
			color: #f7c600;
		}
	}

	.icon-exit {
		:hover {
			color: #f7c600;
		}
	}
`;
