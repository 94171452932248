import React from 'react';

import { Box, Tooltip, Typography } from '@mui/material';

function formatDescription(description) {
	let mountedValues = '';
	const newDescription = description.split('#');

	newDescription.forEach(item => {
		const separatedDesction = item.split('@');
		mountedValues +=
			(separatedDesction[0] < 0 ? ' -' : ' +') +
			Math.abs(separatedDesction[0]).toLocaleString('de-DE') +
			' ' +
			separatedDesction[1] +
			'@' +
			separatedDesction[2] +
			'\n';
	});

	return mountedValues;
}

function formatTableNumbers(value, isPercentage, isGreek) {
	if (Math.abs(value) >= 99999.99999) {
		return 'ilimitado';
	}

	if (isPercentage) {
		if (Math.abs(value) === 0) {
			return '-';
		}

		return `${value.toLocaleString('pt-BR', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		})} ${isGreek ? '' : '%'}`;
	}

	return value;
}

function formatSimpleDate(date) {
	let newDate = date.split('-');

	return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
}

export const defaultColumns = [
	{
		field: 'strategyId',
		headerName: 'Estratégia',
		flex: 1,
	},
	{
		field: 'positionsDescriptor',
		headerName: 'Posições',
		flex: 1,
		renderCell: params => (
			<Tooltip
				title={
					<div style={{ whiteSpace: 'break-spaces' }}>
						{formatDescription(params.value)}
					</div>
				}
				arrow
			>
				<span className="table-cell-trucate">
					{formatDescription(params.value)}
				</span>
			</Tooltip>
		),
	},
	{
		field: 'underSymbol',
		headerName: 'Ativo',
		flex: 1,
	},
	{
		field: 'entrySpreadCost',
		headerName: 'Spread de entrada',
		type: 'number',
		flex: 1,
		sortKey: params => params.value,
		renderCell: params => {
			return (
				<Box width="100%">
					<Typography
						style={
							params.value < 0
								? { color: '#f44336' }
								: { color: '#388e3c' }
						}
					>
						{params.value < 0 ? 'Db' : 'Cr'}{' '}
						{params.value.toLocaleString('de-DE')}
					</Typography>
				</Box>
			);
		},
	},
	{
		field: 'entryCost',
		headerName: 'Custo',
		type: 'number',
		hide: true,
		sortKey: params => params.value,
		renderCell: params => {
			return (
				<Box width="100%">
					<Typography
						style={
							params.value < 0
								? { color: '#f44336' }
								: { color: '#388e3c' }
						}
					>
						{params.value < 0 ? 'Db' : 'Cr'}{' '}
						{params.value.toLocaleString('de-DE')}
					</Typography>
				</Box>
			);
		},
	},
	{
		field: 'profitProbability',
		headerName: 'Prob. Lucro (%)',
		type: 'number',
		flex: 1,
		sortKey: params => params.value,
		valueFormatter: params => {
			return `${(parseFloat(params.value) * 100).toLocaleString('pt-BR', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			})} %`;
		},
	},
	{
		field: 'maxAbsoluteRiskAmount',
		headerName: 'Risco Max.',
		type: 'number',
		flex: 1,
		valueFormatter: params =>
			Math.abs(params.value) === 99999999.99
				? 'ilimitado'
				: params.value.toLocaleString('de-DE'),
	},
	{
		field: 'exerciseYield',
		headerName: 'Taxa',
		type: 'number',
		flex: 1,
		valueFormatter: params =>
			formatTableNumbers(params.value, true).toLocaleString('de-DE'),
		sortKey: params => params.value,
	},
	{
		field: 'protectionRate',
		headerName: 'Taxa de proteção',
		type: 'number',
		flex: 1,
		valueFormatter: params => {
			return `${formatTableNumbers(params.value, true)}`;
		},
	},
	{
		field: 'maxPotRiskPerc3',
		headerName: 'Risco Max (%)',
		type: 'number',
		flex: 1,
		sortKey: params => params.value,
		valueFormatter: params => {
			return `${formatTableNumbers(params.value, true)}`;
		},
	},
	{
		field: 'maxAbsoluteProfitAmount',
		headerName: 'Lucro Max.',
		type: 'number',
		flex: 1,
		valueFormatter: params =>
			formatTableNumbers(params.value).toLocaleString('de-DE'),
	},
	{
		field: 'maxPotProfitPerc3',
		headerName: 'Lucro Max. (%)',
		type: 'number',
		flex: 1,
		sortKey: params => params.value,
		valueFormatter: params => {
			return `${formatTableNumbers(params.value, true)}`;
		},
	},
	{
		field: 'underPrice',
		headerName: 'Preço Ativo',
		type: 'number',
		hide: true,
		valueFormatter: params => {
			return `${formatTableNumbers(params.value, true, true)}`;
		},
	},
	{
		field: 'margin',
		headerName: 'Margem',
		type: 'date',
		hide: true,
	},
	{
		field: 'delta',
		headerName: 'Delta',
		type: 'number',
		hide: true,
		valueFormatter: params => {
			return `${formatTableNumbers(params.value, true, true)}`;
		},
	},
	{
		field: 'gamma',
		headerName: 'Gamma',
		type: 'number',
		hide: true,
		valueFormatter: params => {
			return `${formatTableNumbers(params.value, true, true)}`;
		},
	},
	{
		field: 'theta',
		headerName: 'Theta',
		type: 'number',
		hide: true,
		valueFormatter: params => {
			return `${formatTableNumbers(params.value, true, true)}`;
		},
	},
	{
		field: 'vega',
		headerName: 'Vega',
		type: 'number',
		hide: true,
		valueFormatter: params => {
			return `${formatTableNumbers(params.value, true, true)}`;
		},
	},
	{
		field: 'rho',
		headerName: 'Rho',
		type: 'number',
		hide: true,
		valueFormatter: params => {
			return `${formatTableNumbers(params.value, true, true)}`;
		},
	},
	{
		field: 'expirationDate',
		headerName: 'Data exp.',
		type: 'date',
		hide: true,
		sortKey: params => params.value,
		valueFormatter: params => {
			return `${formatSimpleDate(params.value)}`;
		},
	},
	{
		field: 'daysUntilExpiration',
		headerName: 'Dias exp.',
		type: 'number',
		description: 'Dias para expiração',
		flex: 1,
	},
];
