import * as React from 'react';

export function PlanetSvg(props) {
	return (
		<svg
			width={230}
			height={230}
			viewBox="0 0 230 230"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_538_32)">
				<mask
					id="a"
					style={{
						maskType: 'luminance'
					}}
					maskUnits="userSpaceOnUse"
					x={0}
					y={0}
					width={230}
					height={230}
				>
					<path d="M230 0H0v230h230V0z" fill="#fff" />
				</mask>
				<g mask="url(#a)">
					<path
						d="M72.176 50.622l2.593 5.228 5.777.828-4.17 4.082.999 5.749-5.172-2.705-5.159 2.726.974-5.753-4.188-4.065 5.774-.85 2.572-5.24z"
						fill="#2196F3"
					/>
					<path
						d="M45.881 141.126l4.646 9.366 10.35 1.482-7.471 7.313 1.788 10.3-9.264-4.845-9.242 4.884 1.745-10.308-7.501-7.282 10.343-1.525 4.606-9.385z"
						fill="#1769AA"
					/>
					<path
						d="M65.072 101.92c7.602-27.463 36.027-43.563 63.49-35.96 27.464 7.601 43.563 36.025 35.961 63.488-7.602 27.463-36.027 43.564-63.49 35.962-27.464-7.602-43.563-36.027-35.961-63.49z"
						fill="#35BAF6"
					/>
					<path
						d="M101.033 165.41c18.237 5.048 36.889-.365 49.596-12.607a51.338 51.338 0 01-27.176-.091c-27.463-7.602-43.564-36.027-35.962-63.49a51.348 51.348 0 0113.895-23.356C84.353 70.47 70.12 83.685 65.072 101.92c-7.602 27.463 8.497 55.888 35.961 63.49z"
						fill="#4DABF5"
						fillOpacity={0.67}
					/>
					<path
						d="M128.562 65.96a52.026 52.026 0 00-7.725-1.523 56.394 56.394 0 00-2.048 15.161c.056 26.858 18.906 49.269 44.073 54.856a51.856 51.856 0 001.661-5.006c7.602-27.463-8.498-55.887-35.961-63.489z"
						fill="#fff"
						fillOpacity={0.52}
					/>
					<path
						d="M63.37 111.545c-20.032-7.757-32.497-14.786-31.458-18.537 1.727-6.24 40.16-1.046 85.845 11.599 45.685 12.646 81.319 27.954 79.592 34.194-1.034 3.739-15.251 3.374-36.307-.241"
						stroke="#1769AA"
						strokeWidth={3.14333}
						strokeMiterlimit={10}
					/>
					<path
						d="M136.27 172.064l3.327 6.707 7.411 1.061-5.35 5.237 1.281 7.378-6.634-3.471-6.62 3.499 1.251-7.384-5.372-5.214 7.407-1.092 3.299-6.721z"
						fill="#2196F3"
					/>
					<path
						d="M165.729 36.726l4.646 9.364 10.348 1.482-7.471 7.313 1.79 10.3-9.264-4.845-9.244 4.884 1.747-10.308-7.503-7.282 10.343-1.525 4.608-9.383z"
						fill="#1769AA"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_538_32">
					<path fill="#fff" d="M0 0H230V230H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}
