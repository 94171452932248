import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ptBR } from 'date-fns/locale';
import {
	isToday,
	addYears,
	format,
	isBefore,
	isWeekend,
	startOfDay,
	isValid,
} from 'date-fns';

import { Grid, Tooltip } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';

import { SizedTextField } from '../MaterialStyles/style';

import { getParsedDate } from 'utils/getParsedDate';
import {
	setIsRecurrenceBullet,
	updateContent,
} from 'store/modules/bottomBullet/actions';

const DATE_FORMAT = 'yyyy-MM-dd';

export const TimeInForceInput = ({
	bullet,
	setSnackbarState,
	disableExpirationType,
	disableDateSelector,
	...rest
}) => {
	const dispatch = useDispatch();

	const holidays = useSelector(state => state.configs.holidays);

	const { ExpireDate, TimeInForce, StrategyCode, CustomParameters } =
		bullet.content;

	const timeInForceDefaultValues = {
		0: format(new Date(), DATE_FORMAT),
		1: getVacDate(),
		6: null,
	};

	const isRecurrenceBullet = [
		'sorder',
		'fxorder',
		'sniper',
		'boffer',
		'mit',
		'spread',
		'spread3p',
		'spread4p',
		'spread5p',
		'spread6p',
		'financ',
		'lgshort',
	].includes(StrategyCode);

	function getIsVacBullet(strategyCode, isFlexOrder) {
		if (strategyCode === 'sorder' && isFlexOrder) {
			if (['1', '6'].includes(TimeInForce)) {
				dispatch(updateContent({ TimeInForce: '0' }));
			}

			return false;
		}

		const validStrategyCodes = [
			'sorder',
			'mit',
			'spread',
			'spread3p',
			'spread4p',
			'spread5p',
			'spread6p',
			'financ',
			'lgshort',
		];

		return validStrategyCodes.includes(strategyCode);
	}

	const isVacBullet = getIsVacBullet(StrategyCode, CustomParameters.stop);

	function getIsDESBullet(strategyCode, isFlexOrder) {
		if (strategyCode === 'sorder' && isFlexOrder) {
			if (['1', '6'].includes(TimeInForce)) {
				dispatch(updateContent({ TimeInForce: '0' }));
			}

			return false;
		}

		const validStrategyCodes = [
			'sorder',
			'mit',
			'spread',
			'spread3p',
			'spread4p',
			'spread5p',
			'spread6p',
			'financ',
			'lgshort',
		];

		return validStrategyCodes.includes(strategyCode);
	}

	const isDESBullet = getIsDESBullet(StrategyCode, CustomParameters.stop);

	const timeInForceOptions = [
		{
			tooltip: 'Válida até data hoje (DIA)',
			value: '0',
			label: 'DIA',
			permission: true,
		},
		{
			tooltip: 'Válida até cancelar (VAC)',
			value: '1',
			label: 'VAC',
			permission: isVacBullet,
		},
		{
			tooltip: 'Válida até data específica (DATA)',
			value: '6',
			label: 'DES',
			permission: isDESBullet,
		},
		{
			tooltip: 'Recorrente',
			value: 'recurrence',
			label: 'REC',
			permission: isRecurrenceBullet,
		},
	];

	useEffect(() => {
		if (!bullet.context || bullet.context === 'NEW') {
			dispatch(
				updateContent({
					ExpireDate:
						timeInForceDefaultValues[
							parseInt(bullet.content.TimeInForce)
						],
				})
			);
		}

		const parsedDate = getParsedDate(ExpireDate);
		const currentDay = startOfDay(new Date());

		if (
			isDESBullet &&
			TimeInForce === '6' &&
			isBefore(parsedDate, currentDay)
		) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message:
					'A data de vencimento anterior é inválida ou já passou.',
			});

			dispatch(updateContent({ ExpireDate: null }));
		}
	}, [bullet.context]);

	function getVacDate() {
		const dateWithAddedYears = addYears(new Date(), 10);

		return format(dateWithAddedYears, DATE_FORMAT);
	}

	function handleChangeTimeInForce(event) {
		const { value } = event.target;

		if (bullet.isRecurrence || value !== bullet.content.TimeInForce) {
			const TimeInForce = value === 'recurrence' ? '0' : value;

			const ExpireDate =
				value === 'recurrence'
					? format(new Date(), DATE_FORMAT)
					: timeInForceDefaultValues[parseInt(value)];

			dispatch(updateContent({ TimeInForce, ExpireDate }));
			dispatch(setIsRecurrenceBullet(value === 'recurrence'));
		}
	}

	function isDateDisabled(date) {
		if (isWeekend(date)) {
			return true;
		}

		if (holidays.includes(format(date, DATE_FORMAT))) {
			return true;
		}

		return false;
	}

	function handleChangeDES(date, typedDate) {
		if (!date && !typedDate) {
			dispatch(updateContent({ ExpireDate: null }));
		}

		if ((typedDate && typedDate.length === 10) || (!typedDate && date)) {
			if (isValid(date)) {
				dispatch(
					updateContent({
						ExpireDate: format(date, DATE_FORMAT),
						TimeInForce: isToday(date) ? '0' : '6',
					})
				);
			}
		}
	}

	const selectedTooltip =
		timeInForceOptions.find(option => option.value === (TimeInForce ?? '0'))
			?.tooltip ?? '';

	const minimumDate = new Date().toISOString().split('T')[0]; // Obtém a data de hoje no formato DATE_FORMAT

	const enabledOptions = timeInForceOptions.filter(
		option => option.permission
	);

	function getCalendarValue(date) {
		if (!date || date === '20191801') {
			return null;
		}

		const formattedDate = getParsedDate(date, DATE_FORMAT);

		return formattedDate;
	}

	const calendarValue = getCalendarValue(ExpireDate);

	return (
		<>
			<Grid item xs={1}>
				<Tooltip title={selectedTooltip}>
					<SizedTextField
						size="small"
						label="Tipo validade"
						onChange={event => handleChangeTimeInForce(event)}
						value={
							!bullet.isRecurrence ? TimeInForce : 'recurrence'
						}
						variant="outlined"
						SelectProps={{ native: 'native' }}
						select
						defaultValue="0"
						fullWidth
						disabled={
							disableExpirationType ||
							!isDESBullet ||
							enabledOptions.length <= 1
						}
						{...rest}
					>
						{enabledOptions.map(item => (
							<option key={item.value} value={item.value}>
								{item.label}
							</option>
						))}
					</SizedTextField>
				</Tooltip>
			</Grid>

			{['0', '6'].includes(TimeInForce) && (
				<Grid item xs>
					<LocalizationProvider
						adapterLocale={ptBR}
						dateAdapter={AdapterDateFns}
					>
						<DatePicker
							disablePast
							label="Validade"
							value={calendarValue}
							minDate={minimumDate}
							shouldDisableDate={date => isDateDisabled(date)}
							onChange={(date, typedDate) =>
								handleChangeDES(date, typedDate)
							}
							disabled={
								disableDateSelector || TimeInForce === '0'
							}
							renderInput={params => (
								<SizedTextField
									{...params}
									size="small"
									variant="outlined"
									InputLabelProps={{ shrink: true }}
								/>
							)}
						/>
					</LocalizationProvider>
				</Grid>
			)}
		</>
	);
};
