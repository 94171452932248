import React from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { additionalUpdate } from 'store/modules/bottomBullet/actions';

import { ModalTemplate } from '../Template';
import { GeneralButton } from '../styles';

export const UnauthorizedBullet = ({ open, bullet }) => {
	const dispatch = useDispatch();

	const getBySubUid = () => {
		let name = 'Single ';
		switch (bullet.subUid) {
			case 1:
				name += 'Limite';
				break;
			case 2:
				name += 'Mercado';
				break;
			case 3:
				name += 'Start';
				break;
			case 4:
				name += 'Saída';
				break;
			case 5:
				name += 'Leilão de Abertura';
				break;
			case 6:
				name += 'Leilão de Fechamento';
				break;
			case 7:
				name += 'Best Offer';
				break;
			case 8:
				name += 'Sniper';
				break;
			case 9:
				name += 'MIT';
				break;
			default:
				break;
		}

		return name;
	};

	const formatName = strategyCode => {
		switch (strategyCode) {
			case 'sorder':
				return getBySubUid();
			case 'fxorder':
				return getBySubUid();
			case 'sniper':
				return 'Sniper';
			case 'boffer':
				return 'Best Offer';
			case 'mit':
				return 'MIT';
			case 'spread':
				return 'Spread 2 pernas';
			case 'spread3p':
				return 'Spread 3 pernas';
			case 'spread4p':
				return 'Spread 4 pernas';
			case 'spread5p':
				return 'Spread 5 pernas';
			case 'spread6p':
				return 'Spread 6 pernas';
			case 'financ':
				return 'Financiamento';
			case 'lgshort':
				return 'Long & Short';
			case 'grddin':
				return 'Gradiente';
			default:
				break;
		}
	};

	return (
		<ModalTemplate open={open} title="Boleta não autorizada">
			<Box width="50rem" textAlign="center">
				<Typography variant="h6">
					A estratégia {formatName(bullet.content.StrategyCode)} não
					está liberada para seu perfil. Caso queira utilizar, entre
					em contato com a sua corretora.
				</Typography>
				<GeneralButton
					style={{ marginTop: '8px' }}
					variant="contained"
					buttonColor="danger"
					onClick={() =>
						dispatch(additionalUpdate('unauthorized', false))
					}
				>
					Fechar
				</GeneralButton>
			</Box>
		</ModalTemplate>
	);
};
