export const UPDATE_TRADE_SUB_LIST = 'UPDATE_TRADE_SUB_LIST';
export const UPDATE_BOOK_SUB_LIST = 'UPDATE_BOOK_SUB_LIST';
export const SET_TRADE_SUB_LIST = 'SET_TRADE_SUB_LIST';
export const SET_BOOK_SUB_LIST = 'SET_BOOK_SUB_LIST';
export const SET_MINI_BOOK_SUB_LIST = 'SET_MINI_BOOK_SUB_LIST';
export const SET_CANDLE_SUB_LIST = 'SET_CANDLE_SUB_LIST';
export const DELETE_ITEM_TRADE_SUB_LIST = 'DELETE_ITEM_TRADE_SUB_LIST';
export const DELETE_ITEM_CANDLE_SUB_LIST = 'DELETE_ITEM_CANDLE_SUB_LIST';
export const DELETE_ITEM_BOOK_SUB_LIST = 'DELETE_ITEM_BOOK_SUB_LIST';
export const DELETE_ITEM_MINI_BOOK_SUB_LIST = 'DELETE_ITEM_MINI_BOOK_SUB_LIST';
export const ADD_ITEM_BOOK_SUB_LIST = 'ADD_ITEM_BOOK_SUB_LIST';
export const ADD_ITEM_MINI_BOOK_SUB_LIST = 'ADD_ITEM_MINI_BOOK_SUB_LIST';
export const ADD_ITEM_TRADE_SUB_LIST = 'ADD_ITEM_TRADE_SUB_LIST';
export const ADD_ITEM_CANDLE_SUB_LIST = 'ADD_ITEM_CANDLE_SUB_LIST';
export const UPDATE_WEBSOCKET_CONNECTION = 'UPDATE_WEBSOCKET_CONNECTION';
export const SET_SNAPSHOT_SUB_LIST = 'SET_SNAPSHOT_SUB_LIST';
export const ADD_ITEM_SNAPSHOT_SUB_LIST = 'ADD_ITEM_SNAPSHOT_SUB_LIST';
export const DELETE_ITEM_SNAPSHOT_SUB_LIST = 'DELETE_ITEM_SNAPSHOT_SUB_LIST';
