import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from '@mui/material';

import { updatePosition } from 'store/modules/simulator/actions';
import { setSavedStrategy } from 'store/modules/simulator/actions';

export const InputSide = memo(function InputSide({ position }) {
	const dispatch = useDispatch();

	const activeStrategy = useSelector(state => state.simulator.activeStrategy);
	const strategyShowTypeExit = useSelector(
		state =>
			state.simulator.strategyList[state.simulator.activeStrategy]
				?.showTypeExit
	);

	function handleSide(pos) {
		const pxExitSource = {
			top: 'last_entryPx',
			mid: 'last_midPx',
			last: 'last_tradePx',
		}[strategyShowTypeExit];

		const data = {
			qtty: -pos.qtty,
			entryPx:
				pos.entryPxLocked || pos.entryManualMode
					? pos.entryPx
					: pos.last_exitPx, //Caso entry fechado fica do jeito que está; Caso entry esteja aberto, pega o exitPx porque por definição a saida estará aberta
			exitPx:
				pos.exitPxLocked || pos.exitManualMode
					? pos.exitPx //Caso fechado fica do jeito que está
					: pos[pxExitSource], //pega o last_exitPx se a saida estiver fechada
			last_entryPx: pos.last_exitPx,
			last_exitPx: pos.last_entryPx,
		};

		dispatch(updatePosition(activeStrategy, pos.id, data));
		dispatch(setSavedStrategy(activeStrategy, false));
	}

	const isBuying = position.qtty >= 0;

	return (
		<Button
			size="small"
			variant="contained"
			color={isBuying ? 'primary' : 'error'}
			disabled={position.expired}
			onClick={() => handleSide(position)}
		>
			{isBuying ? 'Compra' : 'Venda'}
		</Button>
	);
});
