const icebergEdit = bullet => {
	const hash = {
		StrategyCode: bullet.content.StrategyCode,
		CustomParameters: {
			PriceLimit: bullet.content.CustomParameters.PriceLimit,
		},
		EndTime: bullet.content.EndTime,
		StrategyLegs: [
			{
				LegQuantity: bullet.content.StrategyLegs[0].LegQuantity,
				LegMaxClipSize: bullet.content.StrategyLegs[0].LegMaxClipSize,
				LegRefID: bullet.content.StrategyLegs[0].LegRefID,
			},
		],
		ClOrdID: bullet.content.ClOrdID,
	};
	return hash;
};

export default icebergEdit;
