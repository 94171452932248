
import React from 'react';

import { setPaperInfo } from 'utils/setPaperInfo';

import { onSuggestionsFetchRequested } from 'components/MaterialComponents/MaterialBullet/bulletFunctions';
import MaterialTabs from 'components/MaterialComponents/MaterialBullet/MaterialHeader';

export const Bullet = () => {
	// TODO -> Trazer Header, Body e Footer pra cá
	return (
		<>
			<MaterialTabs
				setPaperInfo={setPaperInfo}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
			/>
		</>
	);
};
