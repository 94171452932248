import moment from 'moment';

import { roundNumber } from 'utils/numberFormat';

export function getFormatedProfitPob(profitProb) {
	return profitProb !== null || profitProb !== undefined
		? isNaN(profitProb)
			? '-'
			: roundNumber(profitProb * 100, 2) + '%'
		: 'N/D';
}

function getFormatedStrikePx(strikePx) {
	if (strikePx) {
		return new Intl.NumberFormat('pt-BR', {
			minimumFractionDigits: 2,
		}).format(strikePx);
	}

	return '';
}

export function getFormatedPaper(symbol, securityType, strikePx, optionType) {
	if (securityType !== 'OPT') {
		return symbol;
	}

	const formatedOptionType = optionType ? `/ ${optionType}` : '';

	return `${symbol} / ${getFormatedStrikePx(strikePx)} ${formatedOptionType}`;
}

export function getStrategyExpirationDate(positions) {
	const validExpirationDates = [];

	const validPositions = positions.filter(
		position =>
			position.securityType === 'OPT' &&
			!position.exitPxLocked &&
			!position.expired
	);

	if (validPositions.length > 0) {
		validPositions.forEach(position => {
			validExpirationDates.push(moment(position.expirationDate));
		});

		return moment.min(validExpirationDates).format('YYYY-MM-DD 18:00:00');
	}

	return null;
}
