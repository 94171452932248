export function getPermittedUsers(username) {
	const permittedUsernames = [
		'cmc_ricardo78690',
		'cmc_luis',
		'flx_junger',
		'btg_luis',
		'cmc_pedro7496',
		'btg_hernan',
		'cmc_valerio',
		'btg_fabrici54307',
		'btg_terezinh',
		'cmc_juliana',
		'cmc_daniel28785',
		'btg_felipe41829',
	];

	return permittedUsernames.includes(username);
}
