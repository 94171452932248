import { store } from 'store';
import {
	updateActiveStrategies,
	updateTabIndex,
} from 'store/modules/portfolio/actions';
import { setStrategyVisibility } from 'store/modules/simulator/actions';

export function handleSelectStrategyTab(id) {
	const simulatorStrategies = store.getState().simulator.strategyList;
	const index = simulatorStrategies.findIndex(
		strategy => String(strategy.id) === String(id)
	);

	if (index !== -1) {
		store.dispatch(setStrategyVisibility(index));
	}
}

export function handleSimulateStrategy(strategy) {
	const activeStrategies = store.getState().portfolio.activeStrategies;

	let localTabIndex;

	const existingStrategy = activeStrategies.find((activeStrategy, index) => {
		localTabIndex = index + 2;

		return String(activeStrategy.id) === strategy.id;
	});

	if (existingStrategy) {
		store.dispatch(updateTabIndex(localTabIndex));
	} else {
		store.dispatch(
			updateActiveStrategies([
				{
					id: strategy.id,
					name: strategy.name,
					saved: strategy.saved,
				},
			])
		);

		if (activeStrategies.length === 0) {
			store.dispatch(updateTabIndex(2));
		} else {
			store.dispatch(updateTabIndex(activeStrategies.length + 2));
		}
	}

	handleSelectStrategyTab(strategy.id);
}
