import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import IconWithTooltip from '../IconTooltip';
import { SizedTextField } from '../MaterialStyles/style';

import { marginLabelBullet } from 'utils/strategies.js';

const SelectInput = ({
	selectOptions,
	label,
	onChange,
	defaultValue,
	disabled,
	onClickSelect,
	value,
	iconTooltip,
	name,
	fixedTooltip,
	...rest
}) => {
	const options = selectOptions;

	const widgetOpen = useSelector(state => state.configs.widgetBar);

	return (
		<>
			<SizedTextField
				InputLabelProps={{ style: { pointerEvents: 'auto' } }}
				label={
					<Box
						display="flex"
						width="auto"
						style={marginLabelBullet(widgetOpen)}
					>
						{label}
						{iconTooltip && (
							<Box ml={0.5} mt={-0.4}>
								<IconWithTooltip
									title={iconTooltip}
									fixedTooltip={
										!fixedTooltip ? 'top' : fixedTooltip
									}
								/>
							</Box>
						)}
					</Box>
				}
				name={name}
				select
				size="small"
				variant="outlined"
				onChange={onChange}
				defaultValue={defaultValue}
				value={value}
				disabled={disabled}
				fullWidth
				SelectProps={{
					native: 'native',
				}}
				{...rest}
			>
				{options.map(option => (
					<option
						key={option.key}
						value={option.value}
						onClick={onClickSelect}
					>
						{option.label}
					</option>
				))}
			</SizedTextField>
		</>
	);
};

export default SelectInput;
