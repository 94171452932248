import React from 'react';

import { StyledSnackbar, StyledAlert } from './styles';

export const MaterialSnackbar = ({
	open,
	children,
	handleClose,
	severity,
	variant = 'filled',
	autoHide,
	handleWidth,
	handleFontSize,
	handleHeight,
	anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
	...rest
}) => {
	return (
		<StyledSnackbar
			open={open}
			autoHideDuration={autoHide}
			onClose={handleClose}
			anchorOrigin={anchorOrigin}
			handleWidth={handleWidth}
			handleHeight={handleHeight}
			{...rest}
		>
			<StyledAlert
				onClose={handleClose}
				variant={variant}
				severity={severity}
				handleFontSize={handleFontSize}
			>
				{children}
			</StyledAlert>
		</StyledSnackbar>
	);
};
