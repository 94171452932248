import React, { useState, useEffect } from 'react';

import drawChart from './drawChart';

import InvestTemplate from './components/InvestTemplate';
import GuideTemplate from './components/GuideTemplate';
import OramaTemplate from './components/OramaTemplate';

import { ContainerBody } from './style';

const PdfComponent = ({ dataPdf, username }) => {
	const [typeAccount, setTypeAccount] = useState('');

	const getDataPDf = () => {
		if (dataPdf && dataPdf.strategy && dataPdf.strategy.chartData) {
			drawChart(dataPdf);
		}
	};

	useEffect(() => {
		if (
			[
				'fut_hernan',
				'fut_21035',
				'flx_durvalsingulare',
				'fut_rtom',
				'flx_flavioguarino',
				'flx_ermel',
				'flx_Ermel',
				'flx_guto',
			].includes(username)
		) {
			setTypeAccount('investflex');
		} else if (
			['flx_maira', 'gui_latarch', 'gui_luiz', 'fut_gjunq'].includes(
				username
			)
		) {
			setTypeAccount('guide');
		} else if (
			[
				'ora_tteixe',
				'ora_mliz',
				'ora_reiji',
				'ora_oraloe',
				'ora_viniciu61330',
				'ora_sergmf',
				'ora_felippe53244',
				'flx_junger',
				'flx_lemos',
				'fut_isilva',
			].includes(username)
		) {
			setTypeAccount('orama');
		}
		getDataPDf();
	}, [dataPdf]);

	return (
		<div
			id="mainContainer"
			style={{
				overflow: 'hidden',
				height: '0',
				width: '700px',
				top: '0',
				left: '0',
				position: 'absolute',
			}}
		>
			{dataPdf && dataPdf.strategy && dataPdf.strategy.chartData ? (
				<>
					{typeAccount === 'guide' && (
						<GuideTemplate dataPdf={dataPdf} />
					)}
					{typeAccount === 'investflex' && (
						<InvestTemplate dataPdf={dataPdf} />
					)}
					{typeAccount === 'orama' && (
						<OramaTemplate dataPdf={dataPdf} username={username} />
					)}
				</>
			) : (
				<ContainerBody id="container">
					<h1 style={{ color: 'black' }}>Carregando Documento..</h1>
				</ContainerBody>
			)}
		</div>
	);
};

export default PdfComponent;
