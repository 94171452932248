import { PositionsActionTypes } from './types';

const INITIAL_STATE = {
	rows: [],
	consolidation: {},
	tablePreferences: {
		density: 'standard',
		filter: {
			filterModel: {
				items: [],
				linkOperator: 'and',
				quickFilterValues: [],
				quickFilterLogicOperator: 'and',
			},
		},
		columns: {
			columnVisibilityModel: {
				account: true,
				symbol: true,
				net_pl: true,
				marcacao_mercado: true,
				financeiro_liquido: true,
				buyQtty: true,
				avgBuyPx: true,
				sellQtty: true,
				avgSellPx: true,
				volume_financeiro: true,
			},
			orderedFields: [
				'account',
				'symbol',
				'net_pl',
				'marcacao_mercado',
				'financeiro_liquido',
				'buyQtty',
				'avgBuyPx',
				'sellQtty',
				'avgSellPx',
				'volume_financeiro',
			],
		},
		sorting: {
			sortModel: [
				{
					field: 'account',
					sort: 'asc',
				},
			],
		},
	},
	currentPositionsFilters: {
		platform: 'all',
	},
};

export default function reducer(state = INITIAL_STATE, { type, payload }) {
	switch (type) {
		case PositionsActionTypes.SET_POSITIONS_ROWS:
			return { ...state, rows: payload.rows };
		case PositionsActionTypes.SET_POSITIONS_CONSOLIDATION:
			return { ...state, consolidation: payload.consolidation };
		case PositionsActionTypes.UPDATE_POSITIONS_USER_PREFERENCES: {
			const orderedFields = payload.userPreferences?.columns?.orderedFields?.length > 0 ? payload.userPreferences.columns.orderedFields : state.tablePreferences.columns.orderedFields;
			const columnVisibilityModel = payload.userPreferences?.columns?.columnVisibilityModel ?? state.tablePreferences.columns.columnVisibilityModel;
			return {
				...state,
				tablePreferences: {
					...state.tablePreferences,
					density: payload.userPreferences?.density ?? state.tablePreferences.density,
					filter: payload.userPreferences?.filter ?? state.tablePreferences.filter,
					sorting: payload.userPreferences?.sorting ?? state.tablePreferences.sorting,
					columns: {
						...state.tablePreferences.columns,
						orderedFields: [...orderedFields],
						columnVisibilityModel: {...columnVisibilityModel}
					}
				},
			};
		}
		case PositionsActionTypes.UPDATE_POSITIONS_COLUMNS_PREFERENCES:
			return {
				...state,
				tablePreferences: {
					...state.tablePreferences,
					columns: {
						...state.tablePreferences.columns,
						...payload.columnsPreferences,
					},
				},
			};
		case PositionsActionTypes.SET_CURRENT_POSITIONS_FILTERS:
			return {
				...state,
				currentPositionsFilters: {
					...state.currentPositionsFilters,
					[payload.field]: payload.value,
				},
			};
		case PositionsActionTypes.RESET_POSITIONS_STATE:
			return INITIAL_STATE;
		default:
			return state;
	}
}
