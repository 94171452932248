import React from 'react';

import {
	GridToolbarExport,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';

import { PRODUCT_TYPES } from 'utils/constants';

export const CurrentPositionsToolbar = (apiRef, activeProduct) => {
	const isButtonsDisabled =
		apiRef.current.getAllRowIds().length === 0 ||
		activeProduct.code === PRODUCT_TYPES.FLEX_ANALYTIC;

	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton
				color="inherit"
				disabled={activeProduct.code === PRODUCT_TYPES.FLEX_ANALYTIC}
			/>

			<GridToolbarFilterButton
				color="inherit"
				disabled={isButtonsDisabled}
			/>

			<GridToolbarDensitySelector
				color="inherit"
				disabled={isButtonsDisabled}
			/>

			<GridToolbarExport color="inherit" disabled={isButtonsDisabled} />
		</GridToolbarContainer>
	);
};
