import { toast } from 'react-toastify';
import moment from 'moment';

import { store } from 'store';

import { apiCadastro } from './api';
import { getGroupAccountsOptions } from 'utils/getGroupAccounts';

function getQueryParams(filters) {
	const queryParams = { ...filters, detailed: 1 };

	Object.keys(queryParams).forEach(key => {
		if (['all', ''].includes(queryParams[key])) {
			delete queryParams[key];
		}

		if (key === 'active') {
			queryParams.symbol = queryParams[key]?.trim();
			delete queryParams[key];
		}

		if (key === 'date' && queryParams[key] !== '') {
			queryParams.date = moment(queryParams[key]).format('YYYY-MM-DD');
		}
	});

	return queryParams;
}

export function getOrdersCsv() {
	const { token, accounts } = store.getState().auth;
	const searchPaginate = store.getState().executions.searchPaginate;

	const params = getQueryParams(searchPaginate);

	const groupAccounts = getGroupAccountsOptions(accounts);

	const body = {
		...params,
		cblc: groupAccounts[0],
		report: 'trades',
	};

	if ('strategyId' in body) {
		delete body.strategyId;
	}

	const headers = { headers: { Authorization: `Bearer ${token}` } };

	apiCadastro
		.post('/reports', body, headers)
		.then(response => {
			window.open(response.data.url, '_self');
		})
		.catch(error => {
			toast.error('Problema ao processar a requisição!');
			console.error(`Problema ao processar a requisição! ${error}`);
		});
}
