import { toast } from 'react-toastify';
import validatePrice from './validatePrice';

const twapClear = (bullet, setAccessorInfos, account, t) => {
	const hash = { ...bullet.content };
	const strategyInfos = { ...bullet.StrategyInfos };
	if (hash.StrategyLegs.some(leg => leg.LegSymbol === '')) {
		toast.error(t('Select all symbols'));
		return;
	}

	if (hash.StrategyLegs.some(e => e.LegQuantity <= 0)) {
		return { errorMessage: 'A quantidade precisa ser maior que 0' };
	}

	hash.StrategyLegs[0] = {
		ILegAllocAccount: account(),
		LegSymbol: hash.StrategyLegs[0].LegSymbol,
		LegSecurityExchange: strategyInfos[0].subExchange
			? strategyInfos[0].subExchange
			: 'XBSP',
		LegSide: hash.StrategyLegs[0].LegSide,
		LegQuantity: hash.StrategyLegs[0].LegQuantity,
		LegMaxClipSize: hash.StrategyLegs[0].LegMaxClipSize,
		LegOrdType: parseInt(hash.StrategyLegs[0].LegOrdType) ?? 2,
	};

	hash.CustomParameters = {
		PriceLimit: hash.CustomParameters.PriceLimit,
		AgressionLevel: +hash.CustomParameters.AgressionLevel,
		UsingFinancialVolume: hash.CustomParameters.UsingFinancialVolume,
		LimitByVolume: hash.CustomParameters.LimitByVolume,
	};

	if (hash.CustomParameters.UsingFinancialVolume === 'Y') {
		hash.CustomParameters.MaxFinancialVolume =
			bullet.content.CustomParameters.MaxFinancialVolume;
	}

	if (hash.CustomParameters.LimitByVolume === 'Y') {
		hash.CustomParameters.TargetPercent =
			+bullet.content.CustomParameters.TargetPercent;
		hash.CustomParameters.ConsiderCrossOrders =
			bullet.content.CustomParameters.ConsiderCrossOrders;
	}

	if (
		hash.StrategyLegs[0].LegOrdType === 2 &&
		validatePrice(hash.CustomParameters.PriceLimit)
	) {
		toast.error(t('O Preço limite precisa ser maior que 0'));
		return;
	}

	if (bullet.content.StrategyLegs[0].LegOrdType === 1) {
		delete hash.CustomParameters.PriceLimit;
	}

	delete hash.signature;
	delete hash.clean;
	hash.StrategyLegs = setAccessorInfos(hash.StrategyLegs);

	const cleanedHash = {
		Name: hash.Name,
		InitTime: hash.InitTime,
		EndTime: hash.EndTime,
		ExpireDate: hash.ExpireDate,
		Text: hash.Text,
		BasketId: hash.BasketId,
		StrategyCode: hash.StrategyCode,
		CustomParameters: hash.CustomParameters,
		StrategyLegs: hash.StrategyLegs,
		Signature: hash.Signature,
	};

	return cleanedHash;
};

export default twapClear;
