import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	FormControl,
	FormLabel,
	Slider,
	Stack,
	Typography,
} from '@mui/material';
import { VolumeDown, VolumeOff, VolumeUp } from '@mui/icons-material';

import { setPartialGlobalSettings } from 'store/modules/panelConfig/actions';

export const GeneralSettings = () => {
	const dispatch = useDispatch();

	const userVolumeValue = useSelector(
		state => state.configs.globalSettings?.userSettings?.platform.volume
	);

	function handleChangeVolume(_, newVolumeValue) {
		dispatch(
			setPartialGlobalSettings('platform', { volume: newVolumeValue })
		);
	}

	return (
		<FormControl>
			<FormLabel
				sx={{
					color: '#ffffff',
					'&.Mui-focused': {
						color: '#ffffff',
					},
				}}
			>
				<Typography variant="h6">Volume dos sons do sistema</Typography>
			</FormLabel>

			<Stack
				spacing={2}
				direction="row"
				sx={{ mb: 1 }}
				alignItems="center"
			>
				{userVolumeValue === 0 ? <VolumeOff /> : <VolumeDown />}

				<Slider
					aria-label="volume"
					getAriaValueText={() => userVolumeValue}
					defaultValue={userVolumeValue}
					valueLabelDisplay="auto"
					shiftStep={30}
					step={10}
					marks
					min={0}
					max={100}
					onChange={handleChangeVolume}
				/>

				<VolumeUp />
			</Stack>
		</FormControl>
	);
};
