import styled from 'styled-components';
import themes from 'themes';

const oramaColors = themes.orama.colors;

export const BannerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	position: relative;

	width: 50%;
	height: 100vh;
	padding: 0 64px;
	text-align: center;
	color: ${oramaColors.white};

	background: linear-gradient(180deg, #54862a 0%, #639c31 40.1%, #7cc33e 100%);

	> img {
		position: absolute;
		align-self: flex-end;
		z-index: 0;
		width: 25%;
		max-width: 400px;
	}

	@media screen and (max-width: 1024px) {
		display: none;
	}
`;

export const BannerContent = styled.div`
	max-width: 600px;
	height: 500px;
	z-index: 2;

	> img {
		width: 400px;
	}

	h3 {
		font-size: 2.5rem !important;
		margin-bottom: 32px;
	}

	@media screen and (min-height: 768px) {
		h3 {
			font-size: 3rem !important;
		}

		h5 {
			font-size: 2rem;
		}
	}

	@media screen and (min-width: 1920px) {
		height: 600px;
	}
`;

export const Divider = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${oramaColors.white};
	margin: 80px 0;
`;
