export function calcFinancialStop(
	customParameters,
	strategyInfos,
	strategyLegs
) {
	const {
		EntrancePrice,
		NumberOfEntrances,
		IncrementPriceStepEntrance,
		StopByPrice1,
		OrderQuantity,
		StopLossFinance,
	} = customParameters;
	const initialRange = EntrancePrice;

	const isBuy = strategyLegs[0].LegSide === '1';
	const rangesEnd = isBuy
		? initialRange - (NumberOfEntrances - 1) * IncrementPriceStepEntrance
		: initialRange + (NumberOfEntrances - 1) * IncrementPriceStepEntrance;

	const maxExposure = OrderQuantity * NumberOfEntrances;
	const tickValue = strategyInfos[0].contractMultiplier;

	const rangeDifference =
		initialRange - (Number(initialRange) - rangesEnd) / 2 - StopByPrice1;

	const stopFinance = rangeDifference * (maxExposure * tickValue);

	if (stopFinance < StopLossFinance) {
		return Math.abs(stopFinance);
	}

	return Math.abs(StopLossFinance);
}

function formatNumber(value, digits) {
	const numericValue = Number(value);

	if (isNaN(numericValue)) {
		return null;
	}

	const roundedValue = numericValue.toFixed(digits);

	return Number(roundedValue).toLocaleString('de-DE', {
		minimumFractionDigits: digits,
		maximumFractionDigits: digits,
	});
}

function calcAvg(
	legSide,
	NumberOfEntrances,
	EntrancePrice,
	IncrementPriceStepEntrance
) {
	const isBuySide = legSide === '1';
	let total = 0;

	for (let i = 0; i < NumberOfEntrances; i++) {
		const priceAdjustment = IncrementPriceStepEntrance * i;

		total += isBuySide
			? EntrancePrice - priceAdjustment
			: EntrancePrice + priceAdjustment;
	}

	return total / NumberOfEntrances;
}

export function getFinancialStopColor(
	financialStop,
	strategyInfos,
	customParameters,
	strategyLegs
) {
	const GREEN_COLOR = '#388E3C';
	const RED_COLOR = '#ff5a5a';
	const ORANGE_COLOR = '#F57C00';

	const {
		EntrancePrice,
		NumberOfEntrances,
		IncrementPriceStepEntrance,
		StopByPrice1,
		OrderQuantity,
	} = customParameters;

	const {
		minPriceIncrement,
		Cst_MinPriceIncrement,
		tickSizeDenominator,
		contractMultiplier,
	} = strategyInfos[0];

	const minimumPriceIncrement = minPriceIncrement || Cst_MinPriceIncrement;

	const adjustedEntrancePrice = parseFloat(
		EntrancePrice - (NumberOfEntrances - 1) * IncrementPriceStepEntrance
	);

	const priceStepAdjustedValue =
		Math.round(adjustedEntrancePrice / minimumPriceIncrement) *
		minimumPriceIncrement;

	const formattedEntranceAdjustedPrice = formatNumber(
		priceStepAdjustedValue,
		tickSizeDenominator
	);

	const adjustedStopLossPrice = parseFloat(
		adjustedEntrancePrice - 4 * IncrementPriceStepEntrance
	);

	const roundedStopLossPrice =
		Math.round(adjustedStopLossPrice / minimumPriceIncrement) *
		minimumPriceIncrement;

	const formattedStopLossPrice = formatNumber(
		roundedStopLossPrice,
		tickSizeDenominator
	);

	if (financialStop < customParameters.StopLossFinance) {
		const formattedStopByPrice = formatNumber(
			StopByPrice1,
			tickSizeDenominator
		);

		if (strategyLegs[0].LegSide === '1') {
			if (formattedEntranceAdjustedPrice <= formattedStopByPrice) {
				return RED_COLOR;
			}

			if (formattedStopLossPrice <= formattedStopByPrice) {
				return GREEN_COLOR;
			}

			return ORANGE_COLOR;
		}

		if (formattedEntranceAdjustedPrice >= formattedStopByPrice) {
			return RED_COLOR;
		}

		const adjustedStopLossPrice = parseFloat(
			adjustedEntrancePrice + 4 * IncrementPriceStepEntrance
		);

		const roundedStopLossPrice =
			Math.round(adjustedStopLossPrice / minimumPriceIncrement) *
			minimumPriceIncrement;

		const formattedStopLossPrice = formatNumber(
			roundedStopLossPrice,
			tickSizeDenominator
		);

		if (formattedStopLossPrice >= formattedStopByPrice) {
			return GREEN_COLOR;
		}

		return ORANGE_COLOR;
	}

	const averageEntryPrice = calcAvg(
		strategyLegs[0].LegSide,
		NumberOfEntrances,
		EntrancePrice,
		IncrementPriceStepEntrance
	);
	const calculatedFinancialStop = calcFinancialStop(
		customParameters,
		strategyInfos,
		strategyLegs
	);
	const totalExposure =
		NumberOfEntrances * OrderQuantity * contractMultiplier;

	if (strategyLegs[0].LegSide === '1') {
		const adjustedPriceDifference = parseFloat(
			averageEntryPrice - calculatedFinancialStop / totalExposure
		);

		const priceStepRoundedValue =
			Math.round(adjustedPriceDifference / minimumPriceIncrement) *
			minimumPriceIncrement;

		const formattedRoundedPrice = formatNumber(
			priceStepRoundedValue,
			tickSizeDenominator
		);

		if (formattedEntranceAdjustedPrice <= formattedRoundedPrice) {
			return RED_COLOR;
		}

		if (formattedStopLossPrice <= formattedRoundedPrice) {
			return GREEN_COLOR;
		}

		return ORANGE_COLOR;
	}

	const adjustedPriceDifference = parseFloat(
		averageEntryPrice + calculatedFinancialStop / totalExposure
	);

	const priceStepRoundedValue =
		Math.round(adjustedPriceDifference / minimumPriceIncrement) *
		minimumPriceIncrement;

	const formattedRoundedPrice = formatNumber(
		priceStepRoundedValue,
		tickSizeDenominator
	);

	if (formattedEntranceAdjustedPrice >= formattedRoundedPrice) {
		return RED_COLOR;
	}

	const adjustedEntryPrice = parseFloat(
		EntrancePrice +
			(NumberOfEntrances - 1) * IncrementPriceStepEntrance +
			4 * IncrementPriceStepEntrance
	);

	const priceStepRoundedEntry =
		Math.round(adjustedEntryPrice / minimumPriceIncrement) *
		minimumPriceIncrement;

	const formattedRoundedEntryPrice = formatNumber(
		priceStepRoundedEntry,
		tickSizeDenominator
	);

	if (formattedRoundedEntryPrice >= formattedRoundedPrice) {
		return GREEN_COLOR;
	}

	return ORANGE_COLOR;
}

export function getGradientRisk(strategyInfos, customParameters, strategyLegs) {
	const {
		OrderQuantity,
		EntrancePrice,
		NumberOfEntrances,
		IncrementPriceStepEntrance,
	} = customParameters;

	const { LegSide } = strategyLegs[0];
	const { contractMultiplier } = strategyInfos[0];

	if (!IncrementPriceStepEntrance) {
		return;
	}

	const maxExposure = OrderQuantity * NumberOfEntrances;

	const finalRange =
		LegSide === '1'
			? EntrancePrice -
			  (NumberOfEntrances - 1) * IncrementPriceStepEntrance
			: EntrancePrice +
			  (NumberOfEntrances - 1) * IncrementPriceStepEntrance;

	const stopLossPrice =
		LegSide === '1'
			? finalRange - IncrementPriceStepEntrance * 2
			: finalRange + IncrementPriceStepEntrance * 2;

	if (LegSide === '1') {
		return (
			(EntrancePrice -
				(+EntrancePrice - finalRange) / 2 -
				stopLossPrice) *
			(maxExposure * contractMultiplier) *
			-1
		);
	}

	return (
		(stopLossPrice - (EntrancePrice + (+finalRange - EntrancePrice) / 2)) *
		(maxExposure * contractMultiplier) *
		-1
	);
}

export function getMaxContractExposure(
	incrementPriceStepEntrance,
	numberOfEntrances,
	orderQuantity
) {
	if (!incrementPriceStepEntrance) {
		return 0;
	}

	const maxExposure = numberOfEntrances * orderQuantity;

	if (isNaN(maxExposure)) {
		return 0;
	}

	return formatNumber(maxExposure, 0);
}

export function getFinalRangePrice(
	customParameters,
	strategyInfos,
	strategyLegs
) {
	const { LegSide } = strategyLegs[0];
	const { tickSizeDenominator } = strategyInfos[0];
	const { IncrementPriceStepEntrance, EntrancePrice, NumberOfEntrances } =
		customParameters;

	if (!IncrementPriceStepEntrance) {
		return 0;
	}

	const priceAdjustment =
		IncrementPriceStepEntrance * (NumberOfEntrances - 1);

	const finalPrice =
		LegSide === '1'
			? EntrancePrice - priceAdjustment
			: EntrancePrice + priceAdjustment;

	return formatNumber(finalPrice, tickSizeDenominator);
}

export function getStopLossPrice(
	customParameters,
	strategyInfos,
	strategyLegs
) {
	const { LegSide } = strategyLegs[0];
	const {
		tickSizeDenominator,
		contractMultiplier,
		minPriceIncrement,
		Cst_MinPriceIncrement,
	} = strategyInfos[0];
	const {
		IncrementPriceStepEntrance,
		StopByPrice1,
		NumberOfEntrances,
		OrderQuantity,
		EntrancePrice,
	} = customParameters;

	if (!IncrementPriceStepEntrance) {
		return 0;
	}

	const minimumPriceIncrement = minPriceIncrement || Cst_MinPriceIncrement;

	const totalExposure =
		NumberOfEntrances * OrderQuantity * contractMultiplier;

	const averageEntryPrice = calcAvg(
		LegSide,
		NumberOfEntrances,
		EntrancePrice,
		IncrementPriceStepEntrance
	);

	const calculatedFinancialStop = calcFinancialStop(
		customParameters,
		strategyInfos,
		strategyLegs
	);

	const formattedStopByPrice = formatNumber(
		StopByPrice1,
		tickSizeDenominator
	);

	if (LegSide === '1') {
		const adjustedPriceDifference = parseFloat(
			averageEntryPrice - calculatedFinancialStop / totalExposure
		);

		const priceStepRoundedValue =
			Math.round(adjustedPriceDifference / minimumPriceIncrement) *
			minimumPriceIncrement;

		const formattedRoundedPrice = formatNumber(
			priceStepRoundedValue,
			tickSizeDenominator
		);

		if (formattedStopByPrice > formattedRoundedPrice) {
			return formattedStopByPrice;
		}

		return formattedRoundedPrice;
	}

	const adjustedPriceDifference = parseFloat(
		averageEntryPrice + calculatedFinancialStop / totalExposure
	);

	const priceStepRoundedValue =
		Math.round(adjustedPriceDifference / minimumPriceIncrement) *
		minimumPriceIncrement;

	const formattedRoundedPrice = formatNumber(
		priceStepRoundedValue,
		tickSizeDenominator
	);

	if (!StopByPrice1) {
		return formattedRoundedPrice;
	}

	if (formattedStopByPrice < formattedRoundedPrice) {
		return formattedStopByPrice;
	}

	return formattedRoundedPrice;
}
