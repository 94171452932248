import { apiMarketData } from './api';

import { store } from 'store';
import { INTEREST_RATE_SET } from 'store/modules/market/actionTypes';
import { SET_INTEREST_RATE } from 'store/modules/simulator/actionTypes';

export function getExchangesBVMF() {
	apiMarketData
		.get('/exchanges/BVMF')
		.then(response => {
			store.dispatch({
				type: SET_INTEREST_RATE,
				payload: { ir: response.data.IR },
			});

			store.dispatch({
				type: INTEREST_RATE_SET,
				payload: response.data.IR,
			});
		})
		.catch(error => console.log(error));
}
