import styled from 'styled-components';

export const ListContainer = styled.div`
    background: #424242;
    top: 24px;
    position: absolute;
    z-index: 9;
    left: 31px;
    border-radius: 2px;
    box-shadow: 3px 0px 6px #00000033 !important;
    ul {
        list-style: none;
        padding-top: 8px;
        li {
            padding: 5px 10px;
            transition: 0.2s ease-in-out;
        }

        li:hover {
            background: #ffffff40;
            color: #fff;
        }
    }
`;
