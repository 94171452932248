const cleanLgshort = (hash, execType) => {
	const cleanCustomParameters = {
		ExecutionType: parseInt(hash.CustomParameters.ExecutionType, 10),
		QuantityMode: hash.CustomParameters.QuantityMode,
		ToMarketTriggerValueEnabled:
			hash.CustomParameters.ExecutionType === 1 ? 'Y' : 'N',
		Trigger: hash.CustomParameters.Trigger,
		TriggerValue: hash.CustomParameters.TriggerValue,
		BookDepth: hash.CustomParameters.BookDepth
			? hash.CustomParameters.BookDepth
			: 1,
		CompensateExec: parseInt(hash.CustomParameters.CompensateExec, 10),
	};

	if (cleanCustomParameters.ExecutionType === 1) {
		cleanCustomParameters.ToMarketTriggerValue =
			hash.CustomParameters.ToMarketTriggerValue;
	}

	if (execType === 'B') {
		cleanCustomParameters.ExecStrategy = 1;
	}

	return cleanCustomParameters;
};

const lgshortClear = (
	bullet,
	setAccessorInfos,
	account,
	execType
) => {
	const hash = { ...bullet.content };
	const strategyInfos = { ...bullet.StrategyInfos };

	if (hash.StrategyLegs.some(leg => leg.LegSymbol === '')) {
		return { errorMessage: 'Select all symbols' };
	}

	const duplicatedPaper = hash.StrategyLegs.map(leg => {
		const papers = hash.StrategyLegs.filter(
			el => el.LegSymbol === leg.LegSymbol
		);

		if (papers.length > 1) {
			return true;
		}

		return false;
	});

	if (duplicatedPaper.filter(e => e === true).length > 0) {
		return {
			errorMessage:
				'Não é permitido inserir o mesmo ativo em mais de uma perna',
		};
	}

	if (
		!hash.StrategyLegs.some(leg => leg.LegResting === 'Y') &&
		execType !== 'B'
	) {
		return { errorMessage: 'At least one leg to resting' };
	}

	if (
		!hash.CustomParameters.TriggerValue &&
		hash.CustomParameters.TriggerValue !== '0,00000'
	) {
		const fieldName =
			hash.CustomParameters.ExecutionType === 0 ? 'Entrada' : 'Gain';

		return { errorMessage: `Favor preencher campo ${fieldName}` };
	}

	if (
		hash.CustomParameters.ExecutionType === 1 &&
		!hash.CustomParameters.ToMarketTriggerValue
	) {
		return { errorMessage: 'Favor preencher campo Loss' };
	}

	if (
		hash.CustomParameters.QuantityMode === 1 &&
		(hash.StrategyLegs[0].LegPrice === 0 ||
			hash.StrategyLegs[1].LegPrice === 0)
	) {
		return { errorMessage: 'Favor preencher campo Financeiro' };
	}

	if (hash.StrategyLegs.some(e => e.LegQuantity <= 0)) {
		return { errorMessage: 'A quantidade precisa ser maior que 0' };
	}

	hash.StrategyLegs.forEach((leg, index) => {
		hash.StrategyLegs[index] = {
			ILegAllocAccount: account(),
			LegSymbol: hash.StrategyLegs[index].LegSymbol,
			LegSecurityExchange: strategyInfos[index].subExchange
				? strategyInfos[index].subExchange
				: 'XBSP',
			LegSide: hash.StrategyLegs[index].LegSide,
			LegQuantity: parseInt(hash.StrategyLegs[index].LegQuantity, 10),
			LegPrice: parseFloat(hash.StrategyLegs[index].LegPrice, 10),
			LegMaxClipSize:
				execType !== 'B'
					? parseInt(hash.StrategyLegs[index].LegMaxClipSize, 10)
					: 0,
			LegResting: hash.StrategyLegs[index].LegResting,
		};
	});

	hash.CustomParameters = cleanLgshort(hash, execType);

	delete hash.signature;
	delete hash.clean;
	hash.CustomParameters.InitSuspended =
		bullet.content.CustomParameters.InitSuspended ?? 'N';

	if (execType !== 'B') {
		hash.StrategyLegs.forEach((leg, index) => {
			leg.LegResting = bullet.content.StrategyLegs[index].LegResting;
		});

		hash.CustomParameters.IgnoreOffersLT =
			bullet.content.CustomParameters.IgnoreOffersLT;
	} else {
		hash.CustomParameters.IgnoreOffersLT = -1;

		hash.StrategyLegs.forEach(leg => {
			leg.LegMaxClipSize = 0;
		});
	}

	hash.StrategyLegs = setAccessorInfos(hash.StrategyLegs);

	const cleanedHash = {
		'Name': hash.Name,
		'InitTime': hash.InitTime,
		'EndTime': hash.EndTime,
		'ExpireDate': hash.ExpireDate,
		'Text': hash.Text,
		'BasketId': hash.BasketId,
		'StrategyCode': hash.StrategyCode,
		'CustomParameters': hash.CustomParameters,
		'StrategyLegs': hash.StrategyLegs,
		'Signature': hash.Signature
	};

	if (process.env.NODE_ENV === 'development') {
		console.log('Long&Short submit', cleanedHash);
	}

	return cleanedHash;
};

export default lgshortClear;
