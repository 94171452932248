import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import {
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography
} from '@mui/material';

import { StyledDialog } from './styles';

import { apiAWS } from 'services/api';
import { STRATEGY_REMOVE } from 'store/modules/simulator/actionTypes';
import { deleteStrategy, updateDeletionData } from 'store/modules/portfolio/actions';

export const DeletionModal = ({
	token,
	isOpen,
	onClose,
	tabIndex,
	handleClose,
	deletionData,
}) => {
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(false);

	function handleDelete(event, strategy) {
		setIsLoading(true);

		const headers = {
			headers: { Authorization: `Bearer ${token}` },
		};

		apiAWS.delete(`/strategies/${strategy.id}`, headers)
			.then(() => {
				toast.success('Estratégia apagada com sucesso.');

				dispatch({
					type: STRATEGY_REMOVE,
					payload: { strategy },
				});

				dispatch(deleteStrategy(strategy));
				handleClose(event, tabIndex, strategy.id);
				onClose();
			})
			.catch((error) => {
				console.log(error);
				toast.error('Não foi possível processar a solicitação');
			})
			.finally(() => setIsLoading(false));
	}

	return (
		<StyledDialog
			open={isOpen}
			onClose={onClose}
		>
			<DialogTitle id="alert-dialog-title">
				Excluir estratégia
			</DialogTitle>

			<DialogContent>
				<Typography sx={{ fontSize: '1.1rem' }}>
					Você está apagando a estratégia: <i>{deletionData.strategy?.name ?? ''}</i>. Deseja realmente excluí-la?
				</Typography>
			</DialogContent>

			<DialogActions id="actions-container">
				{isLoading ? (
					<CircularProgress size={30} />
				) : (
					<>
						<Button
							variant="outlined"
							onClick={(event) => handleDelete(event, deletionData.strategy)}
						>
							Excluir
						</Button>

						<Button
							variant="outlined"
							onClick={() => {
								dispatch(updateDeletionData({}));
								onClose();
							}}
						>
							Cancelar
						</Button>
					</>
				)}
			</DialogActions>
		</StyledDialog>
	);
};
