import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import { api, apiAWS } from 'services/api';
import { setAccountType, signInAsync } from 'store/modules/auth/actions';
import { setAuthErrorMessage } from 'store/modules/notifications/actions';

import { FormBox, TitleBox } from './styles';

import Button from '../Button';
import { Loading } from '../Loading';
import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';

import themes from 'themes';

export const LoginForm = ({
	setLoading,
	disabled,
	setAuthToken,
	userInfo,
	setContractInfo,
	setIsContractStep,
	history,
	handleCancelContractSign
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { isUserBlocked } = useSelector(state => state.auth);
	const { authErrorMessage } = useSelector(state => state.notifications);

	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [localLoading, setLocalLoading] = useState(false);
	const [isEnvironmentError, setIsEnvironmentError] = useState(false);

	useEffect(() => {
		if (isUserBlocked) {
			setOpenSnackbar(true);
		} else if (isUserBlocked === false) {
			setOpenSnackbar(false);
		}
	}, [isUserBlocked]);

	const verifyEnvironment = (environmentType) => {
		if (environmentType === 'real') {
			setIsEnvironmentError(false);
			return process.env.REACT_APP_USR_DMA_API_URL;
		} else if (environmentType === 'demo') {
			setIsEnvironmentError(false);
			return process.env.REACT_APP_USR_SIM_API_URL;
		} else {
			setLoading(false);
			setIsEnvironmentError(true);
			setOpenSnackbar(true);
			return;
		}
	};

	const handleLoginSubmit = (environmentType) => {
		dispatch(setAuthErrorMessage(null));
		dispatch(setAccountType(environmentType));

		const headers = {
			headers: {
				'content-type': 'application/json',
				Authorization: `Bearer ${userInfo.transactional_token}`,
				'token-login-mode': true,
			}
		};

		const environment = verifyEnvironment(environmentType);

		if (environment) {
			api
				.post(`${environment}/connect/login`, {}, headers, {
					timeout: 15000,
				})
				.then(response => {
					setLoading(true);
					setLocalLoading(true);
					setAuthToken(response.data.auth_token);
					isContractPending(response.data.auth_token, environmentType);
				})
				.catch(error => {
					console.log(error);
					setOpenSnackbar(true);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const isContractPending = (token, environmentType) => {
		setLoading(true);

		const newUserName = userInfo.user_name.toLowerCase();
		const headers = { headers: { Authorization: `Bearer ${token}` } };

		apiAWS
			.get(`/users/${newUserName}/contracts?product=flexscan`, headers)
			.then(response => {
				if (response.data.have_pendencies) {
					setContractInfo(response.data);
					setIsContractStep(true);
					setLocalLoading(false);
				} else {
					dispatch(
						signInAsync({
							authToken: token,
							userName: userInfo.user_name,
							history,
							accountType: environmentType,
							handleCancelContractSign,
							userIp: response.data.user_ip,
							userDate: response.data.datetime,
						}),
					);
				}
			})
			.catch(error => {
				console.log(error);
				setLoading(false);
			});
	};

	return (
		<FormBox>
			<Typography variant="h1">Flex<span>scan</span></Typography>
			<TitleBox>
				<Typography variant="h3">Em qual ambiente você deseja entrar?</Typography>
				<Typography variant="h4">Selecione alguma das opções abaixo para continuar</Typography>
			</TitleBox>
			<Button
				variant="contained"
				type="submit"
				backgroundcolor={themes.orama.colors.orange}
				onClick={() => handleLoginSubmit('demo')}
				disabled={disabled}
			>
				Entrar em ambiente simulado
			</Button>
			<Button
				variant="contained"
				type="submit"
				backgroundcolor={themes.orama.colors.green}
				onClick={() => handleLoginSubmit('real')}
				disabled={disabled}
			>
				Entrar em ambiente real
			</Button>

			<Loading open={localLoading} onClose={!localLoading} />

			<MaterialSnackbar
				open={openSnackbar}
				severity="error"
			>
				{authErrorMessage ? authErrorMessage : isEnvironmentError ? t('login.environmentError') : t('login.defaultCase')}
			</MaterialSnackbar>
		</FormBox>
	);
};
