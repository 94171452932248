import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	TextField,
	Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AttachFile } from '@mui/icons-material';

import { apiAWS } from 'services/api';
import { PasswordInput } from 'components/PasswordInput';
import { removeDuplicatesItem } from 'utils/removeDuplicatesItem';

export const UploadForm = ({
	isOpen,
	handleClose,
	handleSearch,
	changeSnackbarState
}) => {
	const accounts = useSelector(state => state.auth.accounts);

	const formattedAccounts = removeDuplicatesItem(accounts, 'code');

	const [isLoading, setIsLoading] = useState(false);
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const [formValues, setFormValues] = useState({
		account: formattedAccounts.length === 1 ? formattedAccounts[0].code : null,
		initSuspended: 'Y',
		signature: null,
		file: null
	});

	function handleChangeFormValues(field, value) {
		setFormValues({ ...formValues, [field]: value });
	}

	function handleChangeSignature(field, value) {
		const regexCheck = /^[A-Za-z0-9]{0,15}$/.test(value);

		if (regexCheck) {
			handleChangeFormValues(field, value);
		}
	}

	function handleCloseModal(event, reason) {
		if (reason === 'backdropClick') {
			event.stopPropagation();
			return;
		}

		setFormValues(
			{
				account: formattedAccounts.length === 1 ? formattedAccounts[0].code : null,
				initSuspended: 'Y',
				signature: null,
				file: null
			}
		);

		handleClose();
	}

	function getFormData(fields, file) {
		const formData = new FormData();

		Object.entries(fields).forEach(([key, value]) => {
			formData.append(key, value);
		});

		formData.append('file', file);

		return formData;
	}

	function loadFile(body) {
		apiAWS.post('/files/loads', body)
			.then(() => setTimeout(() => handleSearch(), 2000))
			.catch(error => {
				console.log(error);
				changeSnackbarState({
					isOpen: true,
					message: `${error.response?.data?.title ?? 'Erro ao processar o arquivo.'} [${error.response?.status}]`
				});
			})
			.finally(() => handleCloseModal());
	}

	function handleSubmit() {
		if (!formValues.signature || !formValues.file) {
			changeSnackbarState({
				isOpen: true,
				message: 'Preencha os campos obrigatórios'
			});

			return;
		}

		// A validação é feita com ao menos 5 caracteres pois ele conta a extensão ".xslx" que já possui 5.
		if (formValues.file.name.length < 6 || formValues.file.name.length > 120) {
			changeSnackbarState({
				isOpen: true,
				message: `Nome do arquivo deve estar entre 1 e 115 caracteres. (${formValues.file.name.length - 5})`
			});

			return;
		}

		setIsLoading(true);

		const body = {
			service: 'strategy',
			file_name: formValues.file.name
		};

		apiAWS.post('/files', body)
			.then(response => {
				const headers = {
					headers: {
						'Content-Type': 'multipart/form-data'
					},
				};

				const formData = getFormData(response.data.upload_url.fields, formValues.file);

				return axios.post(response.data.upload_url.url, formData, headers);
			})
			.then(() => {
				const loadBody = {
					...body,
					...formValues
				};

				loadFile(loadBody);
			})
			.catch(error => {
				console.log(error);
				changeSnackbarState({
					isOpen: true,
					message: `${error.response?.data?.title ?? 'Erro ao fazer upload do arquivo'}. [${error.response?.status}]`
				});
			})
			.finally(() => setIsLoading(false));
	}

	function handleChangePasswordVisibility() {
		setIsPasswordVisible(!isPasswordVisible);
	}

	return (
		<Dialog
			open={isOpen}
			onClose={(event, reason) => handleCloseModal(event, reason)}
			disableEscapeKeyDown
		>
			<DialogTitle>Upload de estratégias</DialogTitle>

			<DialogContent>
				<Grid
					item
					container
					sx={{
						maxWidth: 350,
						flexDirection: 'column',
						gap: 2,
						py: 1
					}}
				>
					<Grid
						item
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 0.5
						}}
					>
						<Typography variant="body1" sx={{ color: '#d1d1d1' }}>
							O nome do arquivo <strong>não</strong> deve conter caracteres especiais.
						</Typography>

						<FormControl
							fullWidth
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: 0.5
							}}
						>
							<TextField
								id="file-textfield"
								name="file-textfield"
								placeholder='Selecione um arquivo .xslx'
								value={formValues.file?.name ?? ''}
								InputProps={{ readOnly: true }}
								sx={{ width: '80%' }}
							/>

							<Button
								component="label"
								variant="contained"
								name="file"
								sx={{ width: '20%' }}
							>
								<AttachFile fontSize="large" />
								<input
									hidden
									name="file"
									accept='.xlsx'
									type="file"
									onChange={event => handleChangeFormValues(event.target.name, event.target.files[0])}
								/>
							</Button>
						</FormControl>
					</Grid>

					<PasswordInput
						label="Assinatura"
						required
						withoutAutofill
						value={formValues.signature}
						isPasswordVisible={isPasswordVisible}
						changePasswordVisibility={handleChangePasswordVisibility}
						onChange={(event) => handleChangeSignature('signature', event.target.value)}
					/>

					<Grid item>
						<FormControl>
							<FormControlLabel
								sx={{ mr: 0 }}
								label="Iniciar suspensa"
								control={
									<Checkbox
										name="initSuspended"
										value={formValues.initSuspended}
										checked={formValues.initSuspended === 'Y'}
										onChange={(event) => handleChangeFormValues(
											event.target.name,
											formValues.initSuspended === 'Y' ? 'N' : 'Y'
										)}
									/>
								}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions>
				<Button onClick={handleCloseModal}>
					Cancelar
				</Button>

				<LoadingButton
					variant="contained"
					loading={isLoading}
					onClick={handleSubmit}
					disabled={!formValues.signature || !formValues.file}
				>
					Enviar
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
