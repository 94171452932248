import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Book, SwapVert } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import ComboBoxInput from '../ComboBoxInput';
import MaterialCheckBox from '../MaterialCheckBox';
import { ButtonBuySell } from '../MaterialStyles/style';
import DecimalInput from '../DecimalInput';
import {
	updateCustomParameters,
	updateStrategyLeg,
} from '../../../store/modules/bottomBullet/actions';
import paperValidator from 'utils/paperValidation';
import { apiEls } from 'services/api';
import addWidgetFromButton from 'utils/addWidgetFromButton';
import IconWithTooltip from '../IconTooltip';
import { useTranslation } from 'react-i18next';
import { MarketPrice } from '../../MarketPrice';
import { symbolOpenBook } from 'utils/symbolOpenBook';

const Iceberg = props => {
	const {
		onSuggestionsFetchRequested,
		setPaperInfo,
		handleChange,
		subUid,
		onlyCall,
		onlyOptions,
		stepFunction,
	} = props;

	const { bullet } = useSelector(state => state.bottomBullet);
	const lockBook = useSelector(state => state.configs.widgetIsLoading);
	const [maxByAsset, setMaxByAsset] = useState();
	const { StrategyInfos } = bullet;
	const { StrategyLegs } = bullet.content;
	const { maxOrderQty } = bullet.StrategyInfos[0];
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const changeLegSide = () => {
		dispatch(
			updateStrategyLeg(
				{
					LegSide:
						bullet.content.StrategyLegs[0].LegSide === '1'
							? '2'
							: '1',
				},
				0
			)
		);
	};

	const setPriceLimit = () => {
		dispatch(
			updateCustomParameters({
				PriceLimit: bullet.StrategyInfos[0].lastPx,
			})
		);
	};

	const handleFixedQuantity = () => {
		handleChange(
			'Custom',
			'FixedOpenQuantity',
			bullet.content.CustomParameters.FixedOpenQuantity === 'Y'
				? 'N'
				: 'Y',
			0
		);
	};

	// Ajuste para ativo fracionado ter qtd máxima do ativo cheio
	useEffect(() => {
		if (maxOrderQty === 99 && !maxByAsset) {
			apiEls
				.get(
					`/search/${
						StrategyInfos[0].asset +
						StrategyLegs[0].LegSymbol[
							StrategyLegs[0].LegSymbol.length - 2
						]
					}`
				)
				.then(res => {
					setMaxByAsset(res.data._source.maxOrderQty);
				})
				.catch(error => console.log(error));
		}
	}, [maxOrderQty, StrategyInfos, StrategyLegs, maxByAsset]);

	return (
		<Grid item xs={12}>
			<Grid container spacing={1} direction="row">
				<Grid item xs={12}>
					<Typography variant="subtitle1">
						{subUid !== 4 ? 'Entrada' : 'Posição'}
					</Typography>
				</Grid>
				<Box pb={0.5}>
					<Tooltip title="Abrir book">
						<IconButton
							size="small"
							onClick={() =>
								addWidgetFromButton(
									bullet.content.StrategyLegs[0].LegSymbol
								)
							}
							disabled={
								symbolOpenBook(
									bullet.StrategyInfos[0],
									bullet
								) || lockBook
							}
						>
							<Book />
						</IconButton>
					</Tooltip>
				</Box>
				<Grid item xs>
					<ComboBoxInput
						name="paperField"
						label="Ativo"
						setPaperInfo={setPaperInfo}
						comboOptions={
							bullet.suggestions && bullet.suggestions.source
								? {
									value: bullet.suggestions.source.id,
									title: bullet.suggestions.source.symbol,
								  }
								: bullet.suggestions
						}
						onChange={e =>
							onSuggestionsFetchRequested(
								e.target.value,
								onlyOptions,
								onlyCall,
								0
							)
						}
						value={
							bullet.content.StrategyLegs[0].LegSymbol
								? bullet.content.StrategyLegs[0].LegSymbol.toUpperCase()
								: ''
						}
						disabled={bullet.content.ClOrdID}
						isPaper
					/>
				</Grid>
				<Grid item xs>
					<ButtonBuySell
						name="singleSideButtom"
						variant="contained"
						fullWidth
						size="small"
						color={
							bullet.content.StrategyLegs[0].LegSide === '1'
								? 'primary'
								: 'secondary'
						}
						startIcon={<SwapVert />}
						onClick={changeLegSide}
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet,
							'singleSideButtom'
						)}
					>
						{subUid === 4
							? bullet.content.StrategyLegs[0].LegSide === '1'
								? 'Comprei'
								: 'Vendi'
							: bullet.content.StrategyLegs[0].LegSide === '1'
								? 'Compra'
								: 'Venda'}
					</ButtonBuySell>
				</Grid>
				<Grid item xs>
					<DecimalInput
						name="qtyField"
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet,
							'marketQty'
						)}
						label="Quantidade"
						minVal={bullet.StrategyInfos[0].minOrderQty ?? 0}
						maxVal={
							bullet.StrategyInfos[0].maxOrderQty === 99
								? maxByAsset
								: bullet.StrategyInfos[0].maxOrderQty ?? 9999999
						}
						minDecScale={0} // casas decimais
						stepFunction={stepFunction}
						stepType={'Leg'} // type
						stepSubType={'LegQuantity'} // subtype
						stepSize={
							bullet.StrategyInfos[0].roundLot ||
							bullet.StrategyInfos[0].Cst_MinOrderQuantity
						} // incremento
						valueToStep={bullet.content.StrategyLegs[0].LegQuantity} // valor a ser modificado
						value={bullet.content.StrategyLegs[0].LegQuantity}
						onChange={e =>
							handleChange(
								'Leg',
								'LegQuantity',
								parseInt(e.value, 10),
								0
							)
						}
					/>
				</Grid>

				<Grid item xs>
					<DecimalInput
						name="clipField"
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet
						)}
						label="Clip máximo"
						iconTooltip={t('bullets.general.clip')}
						minVal={bullet.StrategyInfos[0].minOrderQty ?? 0}
						maxVal={bullet.StrategyInfos[0].maxOrderQty ?? 9999999}
						minDecScale={0} // casas decimais
						stepFunction={stepFunction}
						stepType="Leg" // type
						stepSubType="LegMaxClipSize" // subtype
						stepSize={
							bullet.StrategyInfos[0].roundLot ||
							bullet.StrategyInfos[0].Cst_MinOrderQuantity
						} // incremento
						valueToStep={
							bullet.content.StrategyLegs[0].LegMaxClipSize
						} // valor a ser modificado
						value={bullet.content.StrategyLegs[0].LegMaxClipSize}
						onChange={e =>
							handleChange(
								'Leg',
								'LegMaxClipSize',
								parseInt(e.value, 10),
								0
							)
						}
					/>
				</Grid>

				<Grid item xs>
					<DecimalInput
						name="priceField"
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet,
							'isPrice'
						)}
						label="Preço Limite"
						iconTooltip={t('bullets.general.limit_price')}
						minVal={0}
						maxVal={999999.99}
						minDecScale={
							bullet.StrategyInfos[0].tickSizeDenominator
						} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="PriceLimit" // subtype
						stepSize={
							bullet.StrategyInfos[0].minPriceIncrement ||
							bullet.StrategyInfos[0].Cst_MinPriceIncrement
						} // incremento
						valueToStep={bullet.content.CustomParameters.PriceLimit} // valor a ser modificado
						value={bullet.content.CustomParameters.PriceLimit}
						onChange={e =>
							handleChange('Custom', 'PriceLimit', e.value, 0)
						}
					/>
				</Grid>

				<Grid item xs style={{ marginTop: '-24px' }}>
					<MarketPrice setPriceLimit={setPriceLimit} />
				</Grid>

				<Grid item xs>
					<Box display="flex" alignItems="center">
						<MaterialCheckBox
							name="fixedOpenQuantity"
							label="Quantidade aberta fixa?"
							color="default"
							className="bullet-checkbox"
							onChange={handleFixedQuantity}
							disabled={
								bullet.content.ClOrdID ||
								paperValidator(bullet.StrategyInfos[0], bullet)
							}
							checked={
								bullet.content.CustomParameters
									.FixedOpenQuantity === 'Y'
							}
							smallLabel
						/>
						<Box ml={1}>
							<IconWithTooltip
								sizeFont="12px"
								title={t(
									'bullets.participatory.iceberg.fixed_open_quantity'
								)}
							/>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Iceberg;
