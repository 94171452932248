import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Financiamento from '../../MaterialFinanciamento/Leg';
import FinancExecutionParams from '../../MaterialFinanciamento/ExecutionParams';

const FinancBodyBullet = props => {
	const { execType, bullet, setPaperInfo, suggestions, onSuggestionsFetchRequested, stepFunction, setSpreaAuxPrice, handleChange } = props;

	return (
		<>
			<Grid item xs={execType === 'B' ? 4 : 6}>
				<Grid container spacing={1}>
					<Grid item xs={12} style={{ marginLeft: '6px' }}>
						<Typography variant="subtitle1">Entrada</Typography>
					</Grid>
					{bullet.content.StrategyLegs.map((leg, index) => (
						<Financiamento
							setPaperInfo={setPaperInfo}
							suggestions={suggestions}
							onSuggestionsFetchRequested={onSuggestionsFetchRequested}
							index={index}
							leg={leg}
							onlyCall
							onlyOptions
							key={index}
							stepFunction={stepFunction}
							callbackMarketPrice={setSpreaAuxPrice}
							execType={execType}
						/>
					))}
				</Grid>
			</Grid>
			<FinancExecutionParams handleChange={handleChange} stepFunction={stepFunction} execType={execType} />
		</>
	);
};

export default FinancBodyBullet;
