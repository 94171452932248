import { SET_PAGE_NAME, SET_COMPONENT, CLEAR_ROUTES } from './types';

const initialState = {
	pageName: 'Flexscan',
	component: null
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_PAGE_NAME:
			return { ...state, pageName: payload.pageName };
		case SET_COMPONENT:
			return { ...state, component: payload.component };
		case CLEAR_ROUTES:
			return { ...initialState };

		default:
			return state;
	}
};
