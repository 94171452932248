import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga4';

function PrivateRoute({ children, ...rest }) {
	// TODO colocar em variavel de ambiente.
	const isAuthenticated = useSelector(state => state.auth.token);
	const { path } = rest;

	useEffect(() => {
		ReactGA.send({ hitType: 'pageview', page: path });
	}, [path]);

	// Deslogar usuario ao fechar aba/browser
	useEffect(() => {
		if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
			window.addEventListener('beforeunload', () => {
				localStorage.removeItem('persist:flexscan');
				return window.content.localStorage.removeItem(
					'persist:flexscan'
				);
			});
		}
	}, [window]);

	return (
		<Route
			{...rest}
			render={({ location }) => {
				if (isAuthenticated) {
					return children;
				}

				return (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: location },
						}}
					/>
				);
			}}
		/>
	);
}

export default PrivateRoute;
