import * as d3 from 'd3';

import './chart.css';

const checkDateExperation = (value, index, array) => {
	if(index === 0){
		return true;
	}else{
        
		return (value.expirationDate == array[index - 1].expirationDate);
	}
};

export default function drawChart(dataPdf){

	document.getElementById('chart').innerHTML = '';

	const chartDataGraphic = dataPdf.strategy.chartData;

	const margin = { top: 10, right: 10, bottom: 70, left: 63 };
	const width = 220;
	const height = 150;

	const yMinValue = d3.min(chartDataGraphic, d => d.px);
	const yMaxValue = d3.max(chartDataGraphic, d => d.px);

	const xMinValue = d3.min(chartDataGraphic, d => d.underPx);
	const xMaxValue = d3.max(chartDataGraphic, d => d.underPx);

	const svg = d3
		.select('#chart')
		.append('svg')
		.attr('width', width + margin.left + margin.right)
		.attr('height', height + margin.top + margin.bottom)
		.append('g')
		.attr('transform', `translate(${margin.left},${margin.top})`);

	const scaleX = d3
		.scaleLinear()
		.domain([0, 100])
		.range([0, width]);

	const xScale = d3
		.scaleLinear()
		.domain([xMinValue, xMaxValue])
		.range([0, width]);


	const yScale = d3
		.scaleLinear()
		.domain([yMinValue, +yMaxValue])
		.range([height, 0]);
    
	const line = d3
		.line()
		.x(d => xScale(d.underPx))
		.y(d => yScale(d.px))
		.curve(d3.curveLinear);

	const line2 = d3
		.line()
		.x(d => xScale(d.underPx))
		.y(d => yScale(d.px))
		.curve(d3.curveBasis);

	const gridLinesX = d3
		.axisTop()
		.tickFormat('')
		.tickSize(-height)
		.scale(scaleX);

	const gridLinesY = d3
		.axisLeft()
		.tickFormat('')
		.tickSize(-width)
		.scale(yScale);



	const formatValue = (d => { return 'R$' + d3.format('.2f')(d); });
	svg
		.append('g')
		.attr('stroke', 'black')
		.attr('class', 'x-axisChart')
		.attr('stroke-width', 0.3)
		.attr('transform', `translate(0,${height + 20})`)
		.call(d3.axisBottom().scale(xScale).ticks(10).tickFormat(formatValue));

	svg
		.append('g')
		.attr('class', 'y-axisChart')
		.attr('stroke', 'black')
		.attr('stroke-width', 0.3)
		.call(d3.axisLeft(yScale).tickFormat(formatValue));

	svg.append('text')
		.attr('transform','translate(' + (width/2) + ' ,' + (height + 15 + 35) + ')')
		.style('text-anchor', 'middle')
		.attr('class', 'textLabel-x')
		.text('Valor do Ativo');

	svg
		.append('g')
		.attr('class', 'gridLine')
		.call(gridLinesX);

	svg
		.append('g')
		.attr('class', 'gridLine')
		.call(gridLinesY);

	svg
		.append('path')
		.datum(chartDataGraphic)
		.attr('fill', 'none')
		.attr('stroke', 'steelblue')
		.attr('stroke-width', 1)
		.attr('class', 'line')
		.attr('d', dataPdf.strategy.positions.every(checkDateExperation) !== true ? line2 : line);

	svg
		.append('line')
		.attr('class', 'zero-lineChart')
		.merge(d3.select('.zero-lineChart'))
		.attr('y1', yScale(0.0))
		.attr('y2', yScale(0.0))
		.attr('x1', 0)
		.attr('x2', width);

}