import { toast } from 'react-toastify';

const cleanFinanc = (hash, execType) => {
	const cleanCustomParameters = {
		ExecutionType: parseInt(hash.CustomParameters.ExecutionType, 10),
		ToMarketTriggerValueEnabled:
			hash.CustomParameters.ExecutionType === 1 ? 'Y' : 'N',
		Trigger: hash.CustomParameters.Trigger,
		TriggerValue: parseFloat(hash.CustomParameters.TriggerValue),
		BookDepth: hash.CustomParameters.BookDepth
			? hash.CustomParameters.BookDepth
			: 1,
		CompensateExec: parseInt(hash.CustomParameters.CompensateExec, 10),
	};
	if (cleanCustomParameters.ExecutionType === 1) {
		cleanCustomParameters.ToMarketTriggerValue = parseFloat(
			hash.CustomParameters.ToMarketTriggerValue
		);
	}
	if (execType === 'B') {
		cleanCustomParameters.ExecStrategy = 1;
	}

	return cleanCustomParameters;
};

const financClear = (
	bullet,
	setAccessorInfos,
	account,
	t,
	execType
) => {
	const hash = { ...bullet.content };
	const strategyInfos = { ...bullet.StrategyInfos };

	if (hash.StrategyLegs.some(leg => leg.LegSymbol === '')) {
		toast.error(t('Select all symbols'));
		return;
	}

	if (
		!hash.StrategyLegs.some(leg => leg.LegResting === 'Y') &&
		execType !== 'B'
	) {
		toast.error(t('At least one leg to resting'));
		return;
	}

	if (!hash.CustomParameters.TriggerValue) {
		const fieldName =
			hash.CustomParameters.ExecutionType === 0 ? 'Entrada' : 'Gain';
		toast.error(`Favor preencher campo ${fieldName}`);
		return;
	}

	if (
		hash.CustomParameters.ExecutionType === 1 &&
		!hash.CustomParameters.ToMarketTriggerValue
	) {
		toast.error('Favor preencher campo Loss');
		return;
	}

	if (hash.StrategyLegs.some(e => e.LegQuantity <= 0)) {
		return { errorMessage: 'A quantidade precisa ser maior que 0' };
	}

	hash.StrategyLegs.forEach((leg, index) => {
		hash.StrategyLegs[index] = {
			ILegAllocAccount: account(),
			LegSymbol: hash.StrategyLegs[index].LegSymbol,
			LegSecurityExchange: strategyInfos[index].subExchange
				? strategyInfos[index].subExchange
				: 'XBSP',
			LegSide: hash.StrategyLegs[index].LegSide,
			LegQuantity: parseInt(hash.StrategyLegs[index].LegQuantity, 10),
			LegMaxClipSize:
				execType !== 'B'
					? parseInt(hash.StrategyLegs[index].LegMaxClipSize, 10)
					: 0,
			LegResting: hash.StrategyLegs[index].LegResting,
		};
	});

	hash.CustomParameters = cleanFinanc(hash, execType);

	delete hash.signature;
	delete hash.clean;
	hash.CustomParameters.InitSuspended =
		bullet.content.CustomParameters.InitSuspended ?? 'N';
	if (execType !== 'B') {
		hash.StrategyLegs.forEach((leg, index) => {
			leg.LegResting = bullet.content.StrategyLegs[index].LegResting;
		});
		hash.CustomParameters.IgnoreOffersLT =
			bullet.content.CustomParameters.IgnoreOffersLT;
	} else {
		hash.CustomParameters.IgnoreOffersLT = -1;
		hash.StrategyLegs.forEach(leg => {
			leg.LegMaxClipSize = 0;
		});
	}
	hash.StrategyLegs = setAccessorInfos(hash.StrategyLegs);

	const cleanedHash = {
		'Name': hash.Name,
		'InitTime': hash.InitTime,
		'EndTime': hash.EndTime,
		'ExpireDate': hash.ExpireDate,
		'Text': hash.Text,
		'BasketId': hash.BasketId,
		'StrategyCode': hash.StrategyCode,
		'CustomParameters': hash.CustomParameters,
		'StrategyLegs': hash.StrategyLegs,
		'Signature': hash.Signature
	};

	if (process.env.NODE_ENV === 'development')
		console.log('Financiamento submit', cleanedHash);

	return cleanedHash;
};

export default financClear;
