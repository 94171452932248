import styled from 'styled-components';
import { Box } from '@mui/material';

export const MenuContainer = styled.div`
	width: 300px;
	display: flex;
	flex-direction: column;
	color: #fff;
`;

export const MenuSection = styled.div`
	width: 100%;
	border-bottom: 1px solid #757575;
`;

export const MenuSectionDouble = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
`;

export const MenuIconItem = styled.span`
	line-height: 14px;
	margin: 0 10px 0 10px;
`;

export const BoxTittle = styled(Box)`
	background-color: #424242 !important;
	border-radius: 3px 3px 0px 0px;
`;

export const ModalBody = styled(Box)`
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	background-color: #303030;
	border-radius: 3px;
`;

export const StyledMenuLink = styled.a`
	color: white;
	text-decoration: none;
	border-top: 1px solid #757575;
	:hover {
		color: white;
		text-decoration: none;
	}
`;
