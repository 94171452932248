import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import SelectInput from '../SelectInput';
import DecimalInput from '../DecimalInput';
import { getDecimalPlacesByTrigger, getStepByTrigger } from '../../../utils/strategies';
import { updateCustomParameters } from 'store/modules/bottomBullet/actions';
import { useInterestRate } from '../../../utils/getStateIr';
import { isDisableBySymbol } from '../../../utils/isDisableBySymbol';
import { useTranslation } from 'react-i18next';
import { SpreadMarketPrice } from '../../MarketPrice/SpreadMarketPrice';

const SkewExit = ({ handleChange, stepFunction, lastVol }) => {
	const { bullet } = useSelector((state) => state.bottomBullet);
	const dispatch = useDispatch();
	const { ir } = useInterestRate();
	const { t } = useTranslation();

	if (!bullet.content.CustomParameters.InterestRate) {
		dispatch(
			updateCustomParameters({
				InterestRate: ir * 100,
			}),
		);
	}

	const handleStopChanges = (value, customParam) => {
		handleChange('Custom', customParam, value);
	};

	const isDisabledBySymbol = isDisableBySymbol(bullet, bullet.content.StrategyLegs.length - 1);

	return (
		<Grid item xs={6}>
			<Grid container spacing={1} direction="row">
				<Grid item xs={12} style={{ marginLeft: '3px' }}>
					<Typography variant="subtitle1">Parâmetros de execução</Typography>
				</Grid>
				<Grid item xs={4}>
					<SelectInput
						disabled={isDisabledBySymbol || bullet.content.ClOrdID}
						label="Modo de execução"
						iconTooltip={t('bullets.volatility.skew.execution_mode')}
						defaultValue={1}
						value={bullet.content.CustomParameters.ExecutionMode ?? 1}
						selectOptions={[
							{
								value: 1,
								label: 'Arbitragem de opções',
							},
							{
								value: 2,
								label: 'Zerar delta pelo ativo',
							},
							{
								value: 3,
								label: 'Zerar delta pela opção',
							},
							{
								value: 4,
								label: 'Zerar vega sem hedge de delta pelo ativo',
							},
							{
								value: 5,
								label: 'Zerar vega com hedge de delta pelo ativo',
							},
						]}
						onChange={(e) => handleChange('Custom', 'ExecutionMode', +e.target.value)}
					/>
				</Grid>
				<Grid item xs={4}>
					<DecimalInput
						disabled={isDisabledBySymbol}
						label="Dif de Volatilidade(%)"
						iconTooltip={t('bullets.volatility.skew.trigger_value')}
						minVal={-999999.99}
						maxVal={999999.99}
						minDecScale={getDecimalPlacesByTrigger(bullet.content.CustomParameters.Trigger)} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="TriggerValue" // subtype
						stepSize={getStepByTrigger(bullet.content.CustomParameters.Trigger)} // incremento
						valueToStep={bullet.content.CustomParameters.TriggerValue} // valor a ser modificado
						value={bullet.content.CustomParameters.TriggerValue}
						onChange={(e) => handleStopChanges(parseFloat(e.value), 'TriggerValue')}
					/>
				</Grid>
				<Grid item xs={4} style={{ marginTop: '-25px' }}>
					<SpreadMarketPrice title="Valor atual no mercado" lastVol={lastVol} handleChange={handleChange} disabled={isDisabledBySymbol} />
				</Grid>
				<Grid item xs={4}>
					<SelectInput
						disabled={isDisabledBySymbol || bullet.content.ClOrdID}
						label="Tipo de delta"
						iconTooltip={t('bullets.volatility.delta_type')}
						defaultValue={1}
						value={bullet.content.CustomParameters.DeltaType ?? 1}
						selectOptions={[
							{
								value: 1,
								label: 'Fixo',
							},
							{
								value: 2,
								label: 'Automático',
							},
						]}
						onChange={(e) => handleChange('Custom', 'DeltaType', +e.target.value)}
					/>
				</Grid>
				<Grid item xs={4}>
					<SelectInput
						disabled={isDisabledBySymbol}
						label="Profundidade do Book"
						iconTooltip={t('bullets.general.book_depth')}
						defaultValue={1}
						value={bullet.content.CustomParameters.BookDepth ?? 3}
						selectOptions={[
							{
								value: 1,
								label: '1º Nível',
							},
							{
								value: 2,
								label: '2º Nível',
							},
							{
								value: 3,
								label: '3º Nível',
							},
						]}
						onChange={(e) => handleChange('Custom', 'BookDepth', +e.target.value)}
					/>
				</Grid>
				<Grid item xs={4}>
					<DecimalInput
						disabled={isDisabledBySymbol}
						label="Juros(%)"
						iconTooltip={t('bullets.volatility.interest_rate')}
						minVal={0.0}
						maxVal={999999.99}
						minDecScale={2} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="InterestRate" // subtype
						stepSize={0.01} // incremento
						valueToStep={bullet.content.CustomParameters.InterestRate} // valor a ser modificado
						value={bullet.content.CustomParameters.InterestRate}
						onChange={(e) => handleStopChanges(parseFloat(e.value), 'InterestRate')}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default SkewExit;
