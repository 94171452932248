import React from 'react';

import { TextField } from '@mui/material';

export const BulletSelect = ({
	label,
	value,
	options,
	onChange,
	defaultValue = null,
	disabled = false,
	...rest
}) => {
	return (
		<>
			<TextField
				size="small"
				select
				fullWidth
				label={label}
				value={value}
				onChange={onChange}
				disabled={disabled}
				defaultValue={defaultValue}
				SelectProps={{ native: 'native' }}
				inputProps={{ style: { padding: 4, paddingLeft: 8 } }}
				{...rest}
			>
				{options.map(option => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</TextField>
		</>
	);
};
