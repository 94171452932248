import React from 'react';

import { Tabs } from '@mui/material';
import {
	FormatListBulleted,
	RepeatRounded,
	RequestQuote,
	Restore,
} from '@mui/icons-material';

import { APPLICATION_MODULES } from 'utils/constants';

import { StyledTab } from 'components/Portfolio/TabsHeader/styles';

export const StrategiesTabs = ({
	tabIndex,
	activeProduct,
	handleChangeTabIndex,
}) => {
	const permittedModules = activeProduct.attributes?.modules ?? [];

	const isHistoryStrategiesVisible = permittedModules.includes(
		APPLICATION_MODULES.HISTORY_STRATEGIES
	);

	const isExecutedOrdersVisible = permittedModules.includes(
		APPLICATION_MODULES.EXECUTED_ORDERS
	);

	const isRecurrenceOrdersVisible = permittedModules.includes(
		APPLICATION_MODULES.RECURRENCE_STRATEGIES
	);

	function handleChange(_, newIndex) {
		handleChangeTabIndex(newIndex);
	}

	function a11yProps(index) {
		return {
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	return (
		<>
			<Tabs
				value={tabIndex}
				onChange={handleChange}
				variant="scrollable"
				scrollButtons="auto"
			>
				<StyledTab
					label="Acomp. Estratégias"
					icon={<FormatListBulleted />}
					iconPosition="start"
					sx={{ minHeight: '50px !important' }}
					{...a11yProps(0)}
				/>

				{isHistoryStrategiesVisible && (
					<StyledTab
						label="Histórico Estratégias"
						icon={<Restore />}
						iconPosition="start"
						sx={{ minHeight: '50px !important' }}
						{...a11yProps(1)}
					/>
				)}

				{isExecutedOrdersVisible && (
					<StyledTab
						label="Ordens Executadas"
						icon={<RequestQuote />}
						iconPosition="start"
						sx={{ minHeight: '50px !important' }}
						{...a11yProps(2)}
					/>
				)}

				{isRecurrenceOrdersVisible && (
					<StyledTab
						label={
							<>
								<span>Recorrências</span>
								<span className="beta-badge">BETA</span>
							</>
						}
						icon={<RepeatRounded />}
						iconPosition="start"
						sx={{ minHeight: '50px !important' }}
						{...a11yProps(isExecutedOrdersVisible ? 3 : 2)}
					/>
				)}
			</Tabs>
		</>
	);
};
