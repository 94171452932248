import React from 'react';

import { StyledButton } from './styles';

export const GeneralButton = ({
	variant = 'contained',
	onClick,
	disabled,
	buttonColor,
	children,
}) => {
	return (
		<StyledButton
			variant={variant}
			onClick={onClick}
			disabled={disabled}
			buttonColor={buttonColor}
		>
			{children}
		</StyledButton>
	);
};
