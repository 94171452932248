import React, { useState } from 'react';

import {
	Box,
	Divider,
	Grid,
	IconButton,
	Tooltip,
	Typography,
	Autocomplete,
	Button,
	Checkbox,
	MenuItem,
	TextField,
} from '@mui/material';
import {
	AddBox,
	Cancel,
	Send,
	CheckBox as CheckBoxIcon,
	CheckBoxOutlineBlank,
} from '@mui/icons-material';
import {
	FilterSort,
	ScanConditions,
	ScanFilters,
	SortOrder,
	StrategySelect,
	SortQuantity,
	PaperFilter,
} from '../selectList';
import { NewSelect } from '../NewSelect';
import { FilterGrid, ChartContainer, TitleBody } from '../style';
import DecimalInput from 'components/MaterialComponents/DecimalInput';

export const NewScan = ({
	scannerValues,
	setScannerValues,
	scannerSearch,
	dateArray,
}) => {
	// Estado que sinaliza o valor que o usuario esta digitando no filtro
	const [filterValue, setFilterValue] = useState('');

	const updatearray = [...scannerValues.filters];

	const handleFilter = (data, index, label, filterIndex) => {
		let updatedData = { ...data };
		if (index || label) {
			if (label === 'firstParam' && index === false) {
				if (typeof data === 'string') {
					updatedData = {};
					updatedData = { ...updatedData, firstParam: data };
				}
				if (typeof data === 'object') {
					updatedData = {};
					updatedData = { ...updatedData, firstParam: [...data] };
				}
			} else {
				updatedData = { ...updatedData, [label]: index };
			}
		}

		if (typeof data !== 'string') {
			if (
				!('firstParam' in data) &&
				!('secondParam' in data) &&
				filterIndex >= 0
			) {
				let resetParams = {
					firstParam: 0,
					secondParam: 0,
				};

				if (data.code === 'expirationDate') {
					resetParams = {
						firstParam: 'Selecione',
						secondParam: 0,
					};
				}
				updatearray[filterIndex] = {
					...updatearray[filterIndex],
					...resetParams,
				};
			}
		}

		if (label === 'filterValue') {
			const resetParams = {
				conditionValue: 0,
			};
			updatearray[filterIndex] = {
				...updatearray[filterIndex],
				...resetParams,
			};
		}
		updatearray[filterIndex] = {
			...updatearray[filterIndex],
			...updatedData,
		};
		delete updatearray[filterIndex].label;
		setScannerValues({ ...scannerValues, filters: updatearray });
	};

	const handleSorting = data => {
		let updatearray = { ...scannerValues };
		const updatedData = { ...data };
		updatearray = { ...updatearray, ...updatedData };
		delete updatearray.label;
		setScannerValues({ ...scannerValues, ...updatearray });
	};

	// Houve ajuste de chamada de parametros que estavam sendo referenciados de forma erada, assim passando o LegIndex com valor errado
	/* eslint-disable no-unused-vars */
	function stepFunction(
		stepBtnType,
		stepType,
		stepSubType,
		stepSize,
		valueToStep,
		legIndex,
		maxVal,
		minVal
	) {
		if (isNaN(valueToStep)) {
			return;
		}

		setScannerValues(prevScannerValues => {
			let updatedFilters = [...prevScannerValues.filters];

			updatedFilters[legIndex] = {
				...updatedFilters[legIndex],
				[stepType]: parseFloat(
					stepBtnType === 'Up'
						? valueToStep + stepSize
						: valueToStep - stepSize
				),
			};

			return {
				...prevScannerValues,
				filters: updatedFilters,
			};
		});
	}

	const addFilter = (type, index, length) => {
		const allFilters = [...ScanFilters];

		const setNextFilter = filters => {
			filters.forEach(element => {
				allFilters.forEach(values => {
					if (Object.values(values).indexOf(element.code) > -1) {
						allFilters.splice(
							allFilters.findIndex(p => p.code === element.code),
							1
						);
					}
				});
			});
			const firstParamValuer = code => {
				if (code === 'underSymbol' || code === 'strategyId') {
					return [];
				}
				if (code === 'expirationDate') {
					return 'Selecione';
				}
				return 0;
			};
			return {
				code: allFilters[0].code,
				filterValue: Object.values(ScanFilters).findIndex(
					values => values.code === allFilters[0].code
				),
				conditionType: allFilters[0].conditionType,
				conditionCode: allFilters[0].conditionCode,
				conditionValue: 0,
				firstParam: firstParamValuer(allFilters[0].code),
				secondParam: 0,
			};
		};

		if (type === 'add' && length < 4) {
			setScannerValues({
				...scannerValues,
				filters: [
					...scannerValues.filters,
					setNextFilter(scannerValues.filters),
				],
			});
		} else if (type === 'remove' && length > 1) {
			const newArray = scannerValues.filters;
			newArray.splice(index, 1);
			setScannerValues({ ...scannerValues, filters: newArray });
		}
	};

	const checkSecondValueBlock = (conditionType, conditionValue) => {
		if (conditionType === 'CND_3' && conditionValue > 0) {
			return true;
		}
		if (conditionType === 'CND_IL' && conditionValue > 1) {
			return true;
		}
		return false;
	};

	// Função que retorna o valor para controle do AutoComplete
	function getAutocompleteValue(value) {
		const scannerValue = value ?? '';

		if (typeof scannerValue === 'number') {
			return [];
		} else {
			return scannerValue;
		}
	}

	// Essa função faz com que o texto seja mostrado corretamente no chip, apos ser selecionado.
	function getAutoCompleteOptionLabel(option, filterValue) {
		const notOnlyActives = filterValue !== 0;

		return notOnlyActives ? option[0] : option;
	}

	// Funcção que verifica se a opção foi selecionada com base no tipo de filtro, por exemplo (Ativos/Estrategias)
	function getAutoCompleteOptionSelected(option, values, filterValue) {
		const notOnlyActives = filterValue !== 0;

		// Validação caso o filtro seja do tipo estrategias
		if (notOnlyActives && option[0] === values[0]) {
			return true;
		} else if (option === values) {
			return true;
		} else {
			return false;
		}
	}

	return (
		<>
			{scannerValues.graphIsOpen && (
				<Grid
					container
					xs={12}
					direction="row"
					alignItems="center"
					spacing={1}
				>
					<Grid item xs>
						<ChartContainer id="sim-chart" />
					</Grid>
				</Grid>
			)}
			{!scannerValues.graphIsOpen && (
				<FilterGrid
					item
					container
					xs={12}
					direction="row"
					justify="flex-start"
					alignItems="center"
					spacing={1}
				>
					<TitleBody item xs={12}>
						<Box display="flex">
							<Box p={1}>
								<Typography variant="h6">
									Parâmetros de filtro
								</Typography>
							</Box>
							<Box ml={2} p={1} width="25%">
								<TextField
									fullWidth
									label="Nome da pesquisa"
									variant="outlined"
									size="small"
									InputLabelProps={{ shrink: true }}
									value={scannerValues.label}
									onChange={e =>
										setScannerValues({
											...scannerValues,
											label: e.target.value,
										})
									}
								/>
							</Box>
						</Box>
					</TitleBody>
					{scannerValues.filters &&
						scannerValues.filters.map((item, mapIndex) => {
							return (
								<>
									<Grid item xs={1}>
										<Box textAlign="center">
											{mapIndex === 0 ? 'Onde' : 'E'}
										</Box>
									</Grid>
									<Grid item xs={2}>
										<NewSelect
											fullWidth
											size="small"
											label="Filtro"
											defaultValue="Todos"
											value={
												ScanFilters[
													scannerValues.filters[
														mapIndex
													].filterValue
												].label
											}
										>
											{ScanFilters.map(
												(option, index) => (
													<MenuItem
														key={option.code}
														onClick={() =>
															handleFilter(
																option,
																index,
																'filterValue',
																mapIndex
															)
														}
														value={option.code}
													>
														{option.label}
													</MenuItem>
												)
											)}
										</NewSelect>
									</Grid>
									<Grid item xs={2}>
										<NewSelect
											fullWidth
											size="small"
											label="Condições"
											defaultValue="Todos"
											value={
												ScanConditions[
													scannerValues.filters[
														mapIndex
													].conditionType
												][
													scannerValues.filters[
														mapIndex
													].conditionValue
												].label
											}
										>
											{ScanConditions[
												scannerValues.filters[mapIndex]
													.conditionType
											].map((option, index) => (
												<MenuItem
													key={option.code}
													onClick={() =>
														handleFilter(
															option,
															index,
															'conditionValue',
															mapIndex
														)
													}
													value={option.code}
												>
													{option.label}
												</MenuItem>
											))}
										</NewSelect>
									</Grid>
									{[0, 1].includes(
										scannerValues.filters[mapIndex]
											.filterValue
									) ? (
											<Grid item xs={6}>
												<Autocomplete
													label={
														scannerValues.filters[
															mapIndex
														].filterValue === 0
															? 'Ativos'
															: 'Estratégias'
													}
													size="small"
													defaultValue={[]}
													value={getAutocompleteValue(
														scannerValues.filters[
															mapIndex
														].firstParam
													)}
													options={
														scannerValues.filters[
															mapIndex
														].filterValue === 0
															? PaperFilter
															: StrategySelect
													}
													multiple
													disableCloseOnSelect
													getOptionLabel={option =>
														getAutoCompleteOptionLabel(
															option,
															scannerValues.filters[
																mapIndex
															].filterValue
														)
													}
													inputValue={filterValue}
													onInputChange={(
														event,
														newInputValue,
														eventType
													) => {
														if (eventType !== 'reset') {
															setFilterValue(
																newInputValue
															);
														}
													}}
													onBlur={() => {
														setFilterValue('');
													}}
													onChange={(_, data) =>
														handleFilter(
															data,
															false,
															'firstParam',
															mapIndex
														)
													}
													isOptionEqualToValue={(
														option,
														values
													) =>
														getAutoCompleteOptionSelected(
															option,
															values,
															scannerValues.filters[
																mapIndex
															].filterValue
														)
													}
													renderOption={(
														props,
														option,
														{ selected }
													) => (
														<li {...props}>
															<Checkbox
																icon={
																	<CheckBoxOutlineBlank fontSize="small" />
																}
																checkedIcon={
																	<CheckBoxIcon fontSize="small" />
																}
																style={{
																	marginRight: 8,
																}}
																checked={selected}
															/>

															{getAutoCompleteOptionLabel(
																option,
																scannerValues
																	.filters[
																		mapIndex
																	].filterValue
															)}
														</li>
													)}
													renderInput={params => (
														<TextField
															{...params}
															label={
																scannerValues
																	.filters[
																		mapIndex
																	].filterValue === 0
																	? 'Ativos'
																	: 'Estratégias'
															}
														/>
													)}
												/>
											</Grid>
										) : (
											''
										)}

									{scannerValues.filters[mapIndex]
										.filterValue === 2 && (
										<Grid item xs={3}>
											<NewSelect
												fullWidth
												size="small"
												label="Vencimento"
												defaultValue="Todos"
												value={
													scannerValues.filters[
														mapIndex
													].firstParam === 0
														? 'Selecione'
														: scannerValues.filters[
															mapIndex
														  ].firstParam
												}
											>
												{dateArray.map(option => (
													<MenuItem
														key={option}
														onClick={() =>
															handleFilter(
																option,
																false,
																'firstParam',
																mapIndex
															)
														}
														value={option}
													>
														{option}
													</MenuItem>
												))}
											</NewSelect>
										</Grid>
									)}
									{scannerValues.filters[mapIndex]
										.filterValue > 2 &&
									scannerValues.filters[mapIndex]
										.filterValue <= 11 ? (
											<Grid item xs={3}>
												<DecimalInput
													label={
														scannerValues.filters[
															mapIndex
														].conditionType === 'CND_3'
															? 'Valor inicial'
															: 'Valor'
													}
													minVal={0}
													maxVal={99999999}
													minDecScale={
														scannerValues.filters[
															mapIndex
														].filterValue === 5 ||
													scannerValues.filters[
														mapIndex
													].filterValue === 8
															? 2
															: 0
													} // casas decimais
													stepFunction={stepFunction}
													stepType="firstParam" // type
													stepSubType="" // subtype
													stepSize={
														scannerValues.filters[
															mapIndex
														].filterValue === 5 ||
													scannerValues.filters[
														mapIndex
													].filterValue === 8
															? 0.01
															: 1
													} // incremento
													legIndex={mapIndex}
													valueToStep={
														scannerValues.filters[
															mapIndex
														].firstParam ?? 0
													} // valor a ser modificado
													value={
														scannerValues.filters[
															mapIndex
														].firstParam ?? 0
													}
													onChange={e => {
														handleFilter(
															{
																firstParam:
																parseFloat(
																	e.value
																),
															},
															false,
															false,
															mapIndex
														);
													}}
												/>
											</Grid>
										) : (
											''
										)}

									{scannerValues.filters[mapIndex]
										.filterValue > 2 &&
									scannerValues.filters[mapIndex]
										.filterValue <= 11 &&
									scannerValues.filters[mapIndex]
										.filterValue !== 4 ? (
											<Grid item xs={3}>
												<DecimalInput
													disabled={checkSecondValueBlock(
														scannerValues.filters[
															mapIndex
														].conditionType,
														scannerValues.filters[
															mapIndex
														].conditionValue
													)}
													label={
														scannerValues.filters[
															mapIndex
														].conditionType === 'CND_3'
															? 'Valor final'
															: 'Valor'
													}
													minVal={0}
													maxVal={99999999}
													minDecScale={
														scannerValues.filters[
															mapIndex
														].filterValue === 5 ||
													scannerValues.filters[
														mapIndex
													].filterValue === 8
															? 2
															: 0
													} // casas decimais
													stepFunction={stepFunction}
													stepType="secondParam" // type
													stepSubType="" // subtype
													stepSize={
														scannerValues.filters[
															mapIndex
														].filterValue === 5 ||
													scannerValues.filters[
														mapIndex
													].filterValue === 8
															? 0.01
															: 1
													} // incremento
													legIndex={mapIndex}
													valueToStep={
														scannerValues.filters[
															mapIndex
														].secondParam ?? 0
													} // valor a ser modificado
													value={
														scannerValues.filters[
															mapIndex
														].secondParam ?? 0
													}
													onChange={e =>
														handleFilter(
															{
																secondParam:
																parseFloat(
																	e.value
																),
															},
															false,
															false,
															mapIndex
														)
													}
												/>
											</Grid>
										) : (
											''
										)}
									{[2, 4].includes(
										scannerValues.filters[mapIndex]
											.filterValue
									) ? (
											<Grid item xs={3}>
												<Box
													style={{
														backgroundColor: '#595959',
														padding: '10px',
														borderRadius: '3px',
														boxShadow:
														'0px 3px 6px #363636',
													}}
												/>
											</Grid>
										) : (
											''
										)}
									<Grid item xs={1}>
										<Box textAlign="center">
											{mapIndex !== 0 ? (
												<Tooltip title="Exluir filtro">
													<IconButton
														size="small"
														onClick={() =>
															addFilter(
																'remove',
																mapIndex,
																scannerValues
																	.filters
																	.length
															)
														}
													>
														<Cancel
															fontSize="large"
															color="secondary"
														/>
													</IconButton>
												</Tooltip>
											) : (
												<Tooltip title="Adicionar filtro">
													<IconButton
														size="small"
														disabled={
															scannerValues
																.filters
																.length === 4
														}
														onClick={() =>
															addFilter(
																'add',
																mapIndex,
																scannerValues
																	.filters
																	.length
															)
														}
													>
														<AddBox
															fontSize="large"
															color={
																scannerValues
																	.filters
																	.length ===
																4
																	? 'disabled'
																	: 'primary'
															}
														/>
													</IconButton>
												</Tooltip>
											)}
										</Box>
									</Grid>
								</>
							);
						})}

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={2}>
						<NewSelect
							fullWidth
							size="small"
							label="Ordenado por"
							defaultValue="Todos"
							value={
								scannerValues &&
								FilterSort[
									FilterSort.findIndex(
										p =>
											p.orderedByType ==
											scannerValues.orderedByType
									)
								].label
							}
						>
							{FilterSort.map((option, index) => (
								<MenuItem
									key={option.orderedByType}
									onClick={() => handleSorting(option, index)}
									value={option.orderedByType}
								>
									{option.label}
								</MenuItem>
							))}
						</NewSelect>
					</Grid>

					<Grid item xs={2}>
						<NewSelect
							fullWidth
							size="small"
							label="Ordenação"
							defaultValue="Todos"
							value={
								SortOrder[
									SortOrder.findIndex(
										p =>
											p.orderedBy ==
											scannerValues.orderedBy
									)
								].label
							}
						>
							{SortOrder.map((option, index) => (
								<MenuItem
									key={option.orderedBy}
									onClick={() => handleSorting(option, index)}
									value={option.orderedBy}
								>
									{option.label}
								</MenuItem>
							))}
						</NewSelect>
					</Grid>

					<Grid item xs={2}>
						<NewSelect
							fullWidth
							size="small"
							label="Nro de operações a visualizar"
							defaultValue="Todos"
							value={
								SortQuantity[
									SortQuantity.findIndex(
										p =>
											p.searchLimit ==
											scannerValues.searchLimit
									)
								].label
							}
						>
							{SortQuantity.map((option, index) => (
								<MenuItem
									key={option.searchLimit}
									onClick={() => handleSorting(option, index)}
									value={option.searchLimit}
								>
									{option.label}
								</MenuItem>
							))}
						</NewSelect>
					</Grid>

					<Grid item xs>
						<Box width="100%" textAlign="right">
							<Button
								size="small"
								variant="contained"
								startIcon={<Send />}
								onClick={() => scannerSearch(scannerValues)}
							>
								Consultar
							</Button>
						</Box>
					</Grid>
				</FilterGrid>
			)}
		</>
	);
};
