import { apiMarketData } from './api';

function createRequestsFromSymbolsList(symbolsList) {
	const requestsArray = [];
	const newArray = [];

	for (let i = 0; i < symbolsList.length; i += 40) {
		const newPart = symbolsList.slice(i, i + 40);
		newArray.push(newPart);
	}

	newArray.forEach(splicedSymbolList => {
		let formattedSymbols = '';
		const symbols = [];

		splicedSymbolList.forEach(symbol => {
			if (!symbols.includes(symbol)) {
				formattedSymbols += `${symbol},`;
			}
		});

		const queryParams = {
			params: {
				symbols: formattedSymbols,
			},
		};

		requestsArray.push(
			apiMarketData.get('/exchanges/BVMF/quotes/last', queryParams)
		);
	});

	return requestsArray;
}

export async function getQuotes(symbolsList) {
	// symbolsList -> Array of paper symbols. Ex: ["PETR4", "VALE3"]

	try {
		const splicedArrays = createRequestsFromSymbolsList(symbolsList);
		const responses = await Promise.all(splicedArrays);

		const recordsArray = [];

		responses.forEach(response => recordsArray.push(...response.data));

		return recordsArray;
	} catch (error) {
		console.log(error);
	}
}
