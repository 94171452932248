import { apiMarketData } from './api';

export async function getVolLastQuotes(bullet, index, option) {
	try {
		const { data } = await apiMarketData.get(
			`/exchanges/BVMF/instruments/${bullet.content.StrategyLegs[index].LegSymbol}/quotes/last`
		);

		if (option) {
			return {
				optAskPx: data.offerPx,
				optBidPx: data.bidPx,
			};
		}

		return {
			lastPx: data.lastPx,
			bidPx: data.bidPx,
			askPx: data.offerPx,
			prevClosePx: data.prevClosePx,
		};
	} catch (error) {
		console.log(error);

		if (option) {
			return {
				optAskPx: null,
				optBidPx: null,
			};
		}

		return {
			lastPx: null,
			bidPx: null,
			askPx: null,
			prevClosePx: null,
		};
	}
}
