import React from 'react';

import { Typography } from '@mui/material';

import { Container } from './styles';
import { ModalTemplate } from '../Template';

export const Contract = ({ open, userDate, userIp, handleClose, path }) => {
	return (
		<ModalTemplate
			open={open}
			closeButton={handleClose}
			title="Contrato de termo de uso do serviço"
		>
			<Container>
				<iframe src={path} />
				<Typography variant="h6">Assinado em: {userDate} IP: {userIp}</Typography>
			</Container>
		</ModalTemplate>
	);
};
