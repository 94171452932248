import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

export const LogoutDialog = ({ isOpen, handleClose, handleLogout }) => {
	const strategyList = useSelector(state => state.simulator.strategyList);
	const hasSimulatorStrategiesUnsaved = strategyList.some(strategy => !strategy.saved);

	const dialogMessage = hasSimulatorStrategiesUnsaved
		? 'Existem estratégias no simulador que não foram salvas, tem certeza que deseja sair?'
		: 'Tem certeza de que deseja sair ?';

	return (
		<Dialog open={isOpen} onClose={handleClose}>
			<DialogTitle variant='h4'>Atenção!</DialogTitle>

			<DialogContent>
				<Typography variant='subtitle1' fontSize="1.125rem">
					{dialogMessage}
				</Typography>
			</DialogContent>

			<DialogActions>
				<Button color="inherit" onClick={handleClose}>
					Cancelar
				</Button>

				<Button color="inherit" onClick={handleLogout}>
					Sair
				</Button>
			</DialogActions>
		</Dialog>
	);
};
