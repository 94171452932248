import {
	additionalUpdate,
	updateCustomParameters,
} from 'store/modules/bottomBullet/actions';
import { store } from 'store';
import {
	updateStrategyInfos,
	updateStrategyLeg,
} from '../../../store/modules/bottomBullet/actions';
import { getTradeableSecurityTypes } from '@investflex/iflexquantjs';
import { apiEls } from 'services/api';
import { updateFormValues } from 'store/modules/executions/actions';

// Controle de requisições do axios (AbortController)
let controller = null;

const escapeRegexCharacters = str => {
	return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const getSugestions = async (value, onlyOptions, onlyCall, signal) => {
	try {
		let arraySugestions = [];

		const response = await apiEls.get(`/search?symbol=${value}`, {
			signal,
			headers: {
				'content-Type': 'application/json',
			},
		});

		const suggs = response.data.suggest.suggest_symbol[0].options;

		let responseArray;

		responseArray = suggs.filter(s =>
			getTradeableSecurityTypes().includes(s._source.securityType)
		);

		if (onlyOptions) {
			responseArray = suggs.filter(s => s._source.securityType === 'OPT');
		}

		if (onlyCall) {
			responseArray = responseArray.filter(
				s => s._source.optionType === 'CALL'
			);
		}

		const array = responseArray.map(r => {
			r._source.id = r.text;
			r._source.symbol = r.text;

			return {
				source: r._source,
			};
		});

		arraySugestions = array;

		return arraySugestions;
	} catch (error) {
		if (error.name === 'AbortError') {
			console.log('Requisição cancelada:', error.message);
		} else {
			console.log(error);
		}
	}
};

export const onSuggestionsFetchRequested = async (
	value,
	onlyOptions,
	onlyCall,
	index,
	typeCall = 'bullet'
) => {
	if (typeCall === 'bullet') {
		store.dispatch(
			updateStrategyLeg({ LegSymbol: value.toUpperCase() }, index)
		);
		store.dispatch(additionalUpdate('clone', false));
		store.dispatch(additionalUpdate('revert', false));

		if (index === 'band') {
			store.dispatch(
				updateCustomParameters({ BandPriceSymbol: value.toUpperCase() })
			);
		}

		const escapedValueLocal = escapeRegexCharacters(value.trim());

		if (escapedValueLocal === '' || escapedValueLocal.length < 4) {
			store.dispatch(additionalUpdate('suggestions', []));
			store.dispatch(updateStrategyInfos({}, index));
			return [];
		}

		if (controller) {
			controller.abort();
		}

		controller = new AbortController();

		const sugestions = await getSugestions(
			escapedValueLocal,
			onlyOptions,
			onlyCall,
			controller.signal
		);

		store.dispatch(additionalUpdate('suggestions', sugestions));
	} else if (typeCall == 'executions') {
		store.dispatch(updateFormValues('active', value));

		const escapedValueLocal = escapeRegexCharacters(value.trim());

		if (escapedValueLocal === '' || escapedValueLocal.length < 4) {
			return;
		}

		if (controller) {
			controller.abort();
		}

		const sugestions = await getSugestions(
			escapedValueLocal,
			onlyOptions,
			onlyCall,
			controller.signal
		);

		return sugestions;
	}
};

export const findShortestTime = (times, type) => {
	const intervalMinutes = parseInt(
		process.env.REACT_APP_INTERVAL_MINUTES ?? 2
	);

	let shorterHour = null;

	for (const time of times) {
		let hour = '';
		if (time) {
			hour = new Date(`1970-01-01T${time}Z`);
		} else {
			hour = new Date(
				`1970-01-01T${
					type === 1
						? process.env.REACT_APP_DEFAULT_CLOSE_HOUR
						: process.env.REACT_APP_DEFAULT_OPEN_HOUR
				}Z`
			);
		}

		if (type === 1) {
			const discount = hour.getMinutes() - intervalMinutes;
			if (discount < 0) {
				hour.setMinutes(60 + discount);
				hour.setHours(hour.getHours() - 1);
			} else {
				hour.setMinutes(discount);
			}

			if (shorterHour === null || hour < shorterHour) {
				shorterHour = hour;
			}
		} else {
			if (shorterHour === null || hour > shorterHour) {
				shorterHour = hour;
			}
		}
	}
	if (
		shorterHour === null ||
		Date(shorterHour).toString() === 'Invalid Date'
	) {
		return type === 1
			? process.env.REACT_APP_DEFAULT_CLOSE_HOUR
			: process.env.REACT_APP_DEFAULT_OPEN_HOUR;
	}

	const res = shorterHour.toISOString().substr(11, 8);

	return res;
};
