import React from 'react';
import { ptBR } from 'date-fns/locale';
import { format, isValid, isWeekend } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';

import { getParsedDate } from 'utils/getParsedDate';

import { SizedTextField } from '../MaterialStyles/style';
import { updateRecurrenceInfo } from 'store/modules/bottomBullet/actions';

const DATE_FORMAT = 'yyyy-MM-dd';

export const RecurrenceDateInput = ({ value, disabled, ...rest }) => {
	const dispatch = useDispatch();

	const holidays = useSelector(state => state.configs.holidays);

	function getCalendarValue(date) {
		if (!date || date === '20191801') {
			return null;
		}

		const formattedDate = getParsedDate(date, DATE_FORMAT);

		return formattedDate;
	}

	const calendarValue = getCalendarValue(value);

	function isDateDisabled(date) {
		if (isWeekend(date)) {
			return true;
		}

		if (holidays.includes(format(date, DATE_FORMAT))) {
			return true;
		}

		return false;
	}

	const minimumDate = new Date().toISOString().split('T')[0]; // Obtém a data de hoje no formato DATE_FORMAT

	function handleChange(date, typedDate) {
		if (!date && !typedDate) {
			dispatch(
				updateRecurrenceInfo({
					ActiveEndDate: null
				})
			);
		}

		if (
			(typedDate && typedDate.length === 10) ||
			(!typedDate && date)
		) {
			if (isValid(date)) {
				dispatch(
					updateRecurrenceInfo({
						ActiveEndDate: format(date, DATE_FORMAT),
						ActiveStartDate: format(
							new Date(),
							'yyyy-MM-dd'
						),
					})
				);
			}
		}
	}

	return (
		<LocalizationProvider
			adapterLocale={ptBR}
			dateAdapter={AdapterDateFns}
		>
			<DatePicker
				disablePast
				label="Validade"
				value={calendarValue}
				minDate={minimumDate}
				shouldDisableDate={(date) => isDateDisabled(date)}
				onChange={(date, typedDate) => handleChange(date, typedDate)}
				disabled={disabled}
				renderInput={params => (
					<SizedTextField
						{...params}
						size="small"
						variant="outlined"
						InputLabelProps={{ shrink: true }}
					/>
				)}
				{...rest}
			/>
		</LocalizationProvider>

	);
};
