import moment from 'moment';

import { ActionTypes } from './actionTypes';

const initialState = {
	rows: [],
	searchForm: {
		date: moment(new Date()).format('YYYY-MM-DD'),
		list_size: 10,
	},
};

export function strategiesUpload(state = initialState, { type, payload }) {
	switch (type) {
		case ActionTypes.SET_ROWS:
			return {
				...state,
				rows: payload.rows.map(item => ({ ...item })),
			};
		case ActionTypes.SET_SEARCH_FORM_VALUES:
			return {
				...state,
				searchForm: {
					...state.searchForm,
					[payload.field]: payload.value,
				},
			};
		default:
			return state;
	}
}
