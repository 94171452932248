import React from 'react';

import { Button, ButtonGroup, colors } from '@mui/material';

export const LegSideButton = ({
	legSide,
	disabled,
	onClick,
	sx = {},
	...rest
}) => {
	const notSelectedButtonStyle = {
		color: colors.grey[500],
		borderColor: colors.grey[600],
	};

	const isBuySide = legSide === '1';
	const isSellSide = legSide === '2';

	function handleClick(event) {
		if (disabled) {
			event.stopPropagation();
			return;
		}

		onClick();
	}

	return (
		<>
			<ButtonGroup
				fullWidth
				size="small"
				onClick={handleClick}
				disabled={disabled}
				sx={{ ...sx, cursor: disabled ? 'not-allowed' : 'auto' }}
				{...rest}
			>
				<Button
					color={isBuySide ? 'primary' : 'inherit'}
					variant={isBuySide ? 'contained' : 'outlined'}
					sx={isSellSide ? notSelectedButtonStyle : {}}
				>
					Compra
				</Button>

				<Button
					color={isSellSide ? 'error' : 'inherit'}
					variant={isSellSide ? 'contained' : 'outlined'}
					sx={isBuySide ? notSelectedButtonStyle : {}}
				>
					Venda
				</Button>
			</ButtonGroup>
		</>
	);
};
