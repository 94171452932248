import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calcOptionPrice } from '@investflex/iflexquantjs';

import { Typography, colors } from '@mui/material';

import { getPaper } from '../utils';
import { roundNumber } from 'utils/numberFormat';
import { updatePosition } from 'store/modules/simulator/actions';

export const InputTheorPx = memo(function InputTheorPx({ value, position }) {
	const dispatch = useDispatch();

	const price = useSelector(getPaper).price;
	const ir = useSelector(state => state.simulator.ir);
	const activeStrategy = useSelector(state => state.simulator.activeStrategy);

	const {
		id,
		optionType,
		last_midPx,
		strikePx,
		daysToExpiration,
		theorImpliedVol,
		expired,
	} = position;

	useEffect(() => {
		if (!optionType) {
			handleUpdateLastMidPx();
		} else {
			handleUpdateTheoreticalPrice();
		}
	}, [price, strikePx, ir, daysToExpiration, theorImpliedVol, last_midPx, optionType]);

	function handleUpdateLastMidPx() {
		const newValue = last_midPx || 0;

		if (newValue !== value) {
			updateValue(newValue);
		}
	}

	function handleUpdateTheoreticalPrice() {
		const newTheoreticalPrice = calcOptionPrice(
			price,
			strikePx,
			ir,
			theorImpliedVol / 100,
			daysToExpiration,
			optionType
		).toFixed(4);

		if (newTheoreticalPrice !== value) {
			updateValue(newTheoreticalPrice);
		}
	}

	function updateValue(value) {
		dispatch(
			updatePosition(activeStrategy, id, {
				theorPx: parseFloat(value),
			})
		);
	}

	const label = expired ? '-' : !value ? 'N/D' : roundNumber(value, 4);

	return (
		<Typography
			color={colors.orange[500]}
			textAlign="right"
			variant="subtitle1"
			paddingRight={0.75}
		>
			{label}
		</Typography>
	);
});
