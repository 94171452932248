import React from 'react';
import numberFormat from 'utils/numberFormat';

import { Typography } from '@mui/material';

export default function ProjectedCoust(props) {
	const { value } = props;

	return (
		<>
			<Typography variant="subtitle1">{`${
				value < 0 ? 'Você pagará' : 'Você receberá'
			}:`}</Typography>
			<Typography variant="body1">
				{` R$ ${numberFormat(value, '00', false)
					.toString()
					.replace('-', '')}`}
			</Typography>
		</>
	);
}
