import { CacheActionsType } from './types';

const initialState = {
	options: {},
	paper: {},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case CacheActionsType.SET_OPTIONS:
			return {
				...state,
				options: { ...state.options, [payload?.symbol]: payload?.data },
			};
		case CacheActionsType.SET_PAPER:
			return {
				...state,
				paper: {
					...state.paper,
					[payload?.symbol]: {
						...state.paper[payload.symbol],
						...payload?.data,
						time: new Date(),
					},
				},
			};
		default:
			return state;
	}
};
