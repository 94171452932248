import styled from 'styled-components';
import { Box } from '@mui/material';

export const Container = styled(Box)`
	display: flex;
	align-items: center;
	flex-direction: column;

	padding: 16px;

	> iframe {
		width: 80vw;
		height: 70vh;
	}

	h6 {
		margin-top: 16px;
	}
`;
