import React, { useState } from 'react';

import { ContentCopy, LibraryAddCheck } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';

export const CopyTextField = ({
	value,
	label = '',
	disabled = false,
	alwaysShowCopyButton = false,
	InputProps = {},
	...rest
}) => {
	const [isHovered, setIsHovered] = useState(false);
	const [isAlreadyCopied, setIsAlreadyCopied] = useState(false);

	const isDisabled =
		value === null || value === undefined || value === '' || disabled;

	function handleMouseEnter() {
		setIsHovered(true);
	}

	function handleMouseLeave() {
		setIsHovered(false);
	}

	function handleCopy(value) {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(value);

			setIsAlreadyCopied(true);
			setTimeout(() => {
				setIsAlreadyCopied(false);
			}, 1000);
		}
	}

	return (
		<TextField
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			fullWidth
			value={value}
			label={label}
			variant="outlined"
			size="small"
			disabled={isDisabled}
			InputProps={{
				readOnly: true,
				endAdornment: (
					<InputAdornment position="end">
						{(isHovered || alwaysShowCopyButton) && !isDisabled && (
							<IconButton
								onClick={() => handleCopy(value)}
								edge="end"
							>
								{isAlreadyCopied ? (
									<LibraryAddCheck
										size="small"
										color="primary"
									/>
								) : (
									<ContentCopy fontSize="small" />
								)}
							</IconButton>
						)}
					</InputAdornment>
				),
				...InputProps,
			}}
			{...rest}
		/>
	);
};
