import React from 'react';

import { Typography } from '@material-ui/core';

import { BannerContainer, BannerContent, Divider } from './styles';

import oIcon from '../../../assets/svg/o-icon.svg';
import logo from '../../../assets/svg/logo-orama.svg';

const Banner = () => {
	return (
		<BannerContainer>
			<BannerContent>
				<img src={logo} alt="Órama" />
				<Divider />
				<Typography variant="h3">
                    O mais avançado software de <strong>simulação de estratégias</strong> de opções, long & short e <strong>execução de algoritmos</strong> do mercado.
				</Typography>
				<Typography variant="h5">Procure, simule e acompanhe suas estratégias em tempo real!</Typography>
			</BannerContent>
			<img id="logo" src={oIcon} alt="Ó" />
		</BannerContainer>
	);
};

export default Banner;
