import styled from 'styled-components';

import { Grid } from '@mui/material';

export const Container = styled(Grid)`
	display: flex;
	justify-content: space-between;

	width: 100%;
	padding: 8px 8px 0 0;

	.Mui-disabled {
		color: #777777 !important;
	}
`;
