import styled from 'styled-components';

import { Grid } from '@material-ui/core';

export const Container = styled(Grid)`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100vw;
	min-height: 100vh;
`;

export const FormContainer = styled(Grid)`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	min-height: 100vh;
	background-color: #ffffff;

	@media screen and (min-width: 1024px) {
		height: 100vh;
		width: 50%;
		padding: 0 64px;
	}
`;
