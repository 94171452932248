import {
	STRATEGY_ADD,
	STRATEGY_REMOVE,
	VISIBILITY_SET,
	STRATEGY_NAME,
	STRATEGY_UPDATE,
	STRATEGY_INITIAL
} from './actionTypes';

const initialState = {
	bullets:[]
};

const reducer = {
	[STRATEGY_ADD]: (stt, payload) => {
		const { strategy } = payload;
		strategy.id = Math.random();
		strategy.content = {};
		strategy.content.Name = (strategy.tooltip.replace(/ /g,''))+ (new Date()).toLocaleString().replace(/[^0-9a-z]/gi, '');
		return { bullets: [...stt.bullets, strategy] };
	},
	[STRATEGY_REMOVE]: (stt, payload) => {
		const { strategy } = payload;
		const newBullets = stt.bullets.filter(
			(item) => item.id !== strategy
		);

		return { bullets: newBullets };
	},
	[VISIBILITY_SET]: (stt, payload) => {
		const { visibility, id } = payload;
		const newBullets = stt.bullets.map((item) =>
			item.id === id ? { ...item, visible: visibility } : item
		);

		return { bullets: newBullets };
	},
	[STRATEGY_NAME]: (stt, payload) => {
		const {id, value} = payload;
		const newBullets = stt.bullets.map((item) =>
			item.id === id ? { ...item, name: value } : item
		);

		return {bullets: newBullets };
	},
	[STRATEGY_UPDATE]: (stt, payload) => {
		const { id, content } = payload;
		const newBullets = stt.bullets.map((item) =>
			item.id === id ? { ...item, content } : item
		);

		return {bullets: newBullets};
	},
	[STRATEGY_INITIAL]: () => {
		return {bullets: []};
	}
};

export default function strategyFloatingReducer(state = initialState, { type, payload }) {
	if (typeof reducer[type] === 'function') {
		return reducer[type](state, payload);
	}
	return state;
};
