import {getStrategyTemplate} from './templates';
import {getFirstLegFlexOrder} from './strategies';

export const handleRevertStrategy = (strategy) => {

	let templateCustomParameter = getStrategyTemplate(strategy.StrategyCode)?.CustomParameters;

	if(strategy.StrategyCode === 'lgshort'){
		templateCustomParameter = {
			...templateCustomParameter, 
			QuantityMode: parseInt(strategy.CustomParameters.QuantityMode, 10), 
			ExecStrategy: parseInt(strategy.CustomParameters.ExecStrategy, 10)
		};
	}
	
	if (strategy.StrategyCode.includes('spread')) {
		templateCustomParameter = {
			...templateCustomParameter,
			ExecStrategy: parseInt(strategy.CustomParameters.ExecStrategy, 10),
			Trigger: parseInt(strategy.CustomParameters.Trigger),
			ExecutionType: parseInt(strategy.CustomParameters.ExecutionType),
			CompensateExec: strategy.CustomParameters.CompensateExec
		};
	}

	if (strategy.StrategyCode === 'fxorder') {
		templateCustomParameter.stop = false;
		strategy.StrategyLegs = getFirstLegFlexOrder(strategy.StrategyLegs);
		//saida
		if(strategy.CustomParameters.CustomOrdType === 3){
			templateCustomParameter.PriceLimit = strategy.CustomParameters.EntranceExecPrice;
		} else if([2, 4].includes(strategy.CustomParameters.CustomOrdType)){
			// start e limit
			templateCustomParameter.PriceLimit = strategy.CustomParameters.StartPriceTrigger;
		}

		templateCustomParameter.OrderQuantity = strategy.CustomParameters.OrderQuantity;
		templateCustomParameter.CustomOrdType = strategy.CustomParameters.CustomOrdType;

	} else if(strategy.StrategyCode === 'cvvol'){
		templateCustomParameter.DaysToExpiration = parseInt(strategy.CustomParameters.DaysToExpiration);
		strategy.StrategyLegs = strategy.StrategyLegs.map((leg, index) => {
			if(index === 1){
				return {...leg, LegQuantity: 100, LegMaxClipSize: 100};
			}
			else{
				return {...leg};
			}
		});
	}

	strategy.CustomParameters = templateCustomParameter;

	return strategy;
};
