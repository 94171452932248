export function getStrategiesBySymbol(strategies) {
	let strategiesByUnderSymbols = {};
	let strategiesByPositionSymbols = {};

	strategies.forEach(strategy => {
		if (
			!Object.prototype.hasOwnProperty.call(
				strategiesByUnderSymbols,
				strategy.underSymbol
			)
		) {
			strategiesByUnderSymbols[strategy.underSymbol] = [];
		}

		strategiesByUnderSymbols[strategy.underSymbol].push(strategy);

		strategy.positions.forEach(position => {
			if (
				!Object.prototype.hasOwnProperty.call(
					strategiesByPositionSymbols,
					position.symbol
				)
			) {
				strategiesByPositionSymbols[position.symbol] = [];
			}

			strategiesByPositionSymbols[position.symbol].push(strategy);
		});
	});

	return { strategiesByUnderSymbols, strategiesByPositionSymbols };
}
