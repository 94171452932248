import React from 'react';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

import { Box } from '@mui/material';
import { ArrowBack, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

import IconWithTooltip from '../IconTooltip';
import MaterialCheckBox from '../MaterialCheckBox';
import {
	DecimalDown,
	DecimalTextField,
	DecimalUp,
	FinanticalBtn,
} from '../MaterialStyles/style';

import { marginLabelBullet } from 'utils/strategies.js';

const NumberFormatCustom = ({
	inputRef,
	onChange,
	maxVal,
	minVal,
	minDecScale,
	value,
	disabled,
	stepFunction,
	stepType,
	stepSubType,
	stepSize,
	valueToStep,
	legIndex,
	isFinantial,
	finantialClick,
	startButton,
	checkBox,
	checkBoxDisabled,
	checkBoxChecked,
	checkBoxHandle,
	checkBoxName,
	allowNegatives,
	...other
}) => {
	const handleCreditDebit = () => {
		if (finantialClick) {
			finantialClick(!isFinantial);
		}
	};

	return (
		<>
			{finantialClick && !startButton ? (
				<>
					<FinanticalBtn
						disabled={disabled}
						onClick={handleCreditDebit}
						color={isFinantial ? '' : 'secondary'}
						style={
							isFinantial
								? { backgroundColor: '#388e3c', color: '#fff' }
								: {}
						}
						variant="contained"
						tabIndex={-1}
					>
						{isFinantial ? 'Cr' : 'Db'}
					</FinanticalBtn>
				</>
			) : (
				''
			)}
			{startButton && (
				<>
					<FinanticalBtn
						name="backButton"
						disabled={disabled}
						onClick={handleCreditDebit}
						color="primary"
						variant="contained"
						tabIndex={-1}
					>
						<ArrowBack />
					</FinanticalBtn>
				</>
			)}
			{checkBox && (
				<MaterialCheckBox
					name={checkBoxName}
					disabled={checkBoxDisabled}
					checked={checkBoxChecked}
					onChange={checkBoxHandle}
				/>
			)}
			<NumberFormat
				{...other}
				getInputRef={inputRef}
				value={value ?? ''}
				style={
					isFinantial
						? { textAlign: 'right', color: '#81c784' }
						: isFinantial === false
							? { textAlign: 'right', color: '#e57373' }
							: { textAlign: 'right' }
				}
				onValueChange={values => {
					onChange({
						value: values.value,
					});
				}}
				onClick={() => document.activeElement.select()}
				onBlurCapture={() =>
					stepFunction(
						'Round',
						stepType,
						stepSubType,
						stepSize,
						valueToStep,
						legIndex,
						maxVal,
						minVal
					)
				}
				fullWidth
				thousandSeparator="."
				decimalSeparator=","
				isNumericString
				fixedDecimalScale
				allowLeadingZeros
				decimalScale={minDecScale}
				disabled={disabled}
				allowNegative={allowNegatives}
			/>
			<Box display="grid">
				<DecimalUp
					onClick={() =>
						stepFunction(
							'Up',
							stepType,
							stepSubType,
							stepSize,
							valueToStep,
							legIndex,
							maxVal,
							minVal
						)
					}
					size="small"
					variant="contained"
					tabIndex={disabled === true ? -1 : -1}
					disabled={disabled}
				>
					<ArrowDropUp style={{ fontSize: '11px' }} />
				</DecimalUp>
				<DecimalDown
					onClick={() =>
						stepFunction(
							'Down',
							stepType,
							stepSubType,
							stepSize,
							valueToStep,
							legIndex,
							maxVal,
							minVal
						)
					}
					size="small"
					variant="contained"
					tabIndex={disabled === true ? -1 : -1}
					disabled={disabled}
				>
					<ArrowDropDown style={{ fontSize: '11px' }} />
				</DecimalDown>
			</Box>
		</>
	);
};

const DecimalInput = ({
	value,
	label,
	onChange,
	disabled,
	maxVal,
	minVal,
	minDecScale,
	stepFunction,
	stepType,
	stepSubType,
	stepSize,
	valueToStep,
	legIndex,
	isFinantial,
	finantialClick,
	startButton,
	checkBox,
	checkBoxDisabled,
	checkBoxChecked,
	checkBoxHandle,
	iconTooltip,
	name,
	difSpread,
	error,
	checkBoxName,
	fixedTooltip,
	allowNegatives = true,
}) => {
	function TabExample(evt) {
		const tabKey = 9;
		if (evt.shiftKey && evt.keyCode === 38) {
			stepFunction(
				'Up',
				stepType,
				stepSubType,
				stepSize * 10,
				valueToStep,
				legIndex,
				maxVal,
				minVal
			);
		}
		if (evt.shiftKey && evt.keyCode === 40) {
			stepFunction(
				'Down',
				stepType,
				stepSubType,
				stepSize * 10,
				valueToStep,
				legIndex,
				maxVal,
				minVal
			);
		}
		if (!evt.shiftKey && evt.keyCode === 38) {
			stepFunction(
				'Up',
				stepType,
				stepSubType,
				stepSize,
				valueToStep,
				legIndex,
				maxVal,
				minVal
			);
		}
		if (!evt.shiftKey && evt.keyCode === 40) {
			stepFunction(
				'Down',
				stepType,
				stepSubType,
				stepSize,
				valueToStep,
				legIndex,
				maxVal,
				minVal
			);
		}
		if (evt.keyCode === tabKey) {
			if (
				!document.activeElement.parentElement.parentElement
					.parentElement.nextElementSibling
			) {
				return;
			}

			const nextInput =
				document.activeElement.parentElement.parentElement.parentElement.nextElementSibling.getElementsByTagName(
					'input'
				);

			if (nextInput) {
				if (nextInput[0]) {
					evt.preventDefault();
					setTimeout(function () {
						nextInput[0].focus();
						nextInput[0].select();
					}, 400);
				}
			}
		}
	}

	const widgetOpen = useSelector(state => state.configs.widgetBar);

	return (
		<>
			<DecimalTextField
				error={error}
				variant="outlined"
				size="small"
				disabled={disabled}
				InputLabelProps={{
					shrink: true,
					style: { pointerEvents: 'auto' },
				}}
				label={
					<Box
						display="flex"
						width="auto"
						style={marginLabelBullet(widgetOpen)}
					>
						{label}
						{iconTooltip && (
							<Box ml={0.5} mt={-0.4}>
								<IconWithTooltip
									title={iconTooltip}
									fixedTooltip={
										!fixedTooltip ? 'top' : fixedTooltip
									}
								/>
							</Box>
						)}
					</Box>
				}
				value={value ?? ''}
				name={name}
				fullWidth
				autoComplete="off"
				onKeyDown={e => TabExample(e)}
				InputProps={{
					inputComponent: NumberFormatCustom,
				}}
				inputProps={{
					onChange,
					maxVal,
					minVal,
					minDecScale,
					disabled,
					stepFunction,
					stepType,
					stepSubType,
					stepSize,
					valueToStep,
					legIndex,
					isFinantial,
					finantialClick,
					startButton,
					checkBox,
					checkBoxDisabled,
					checkBoxChecked,
					checkBoxHandle,
					difSpread,
					checkBoxName,
					allowNegatives,
				}}
			/>
		</>
	);
};

export default DecimalInput;
