import React from 'react';

import { Box, Typography } from '@mui/material';
import { LightTooltip } from 'components/LightTooltip';

export const DescriptionTooltip = ({ label, description }) => {
	return (
		<Typography textAlign="justify" variant="subtitle1">
			<strong>{label}: </strong>
			{description}
		</Typography>
	);
};

export const LightHTMLTooltip = ({ children, element }) => {
	return (
		<LightTooltip
			arrow
			title={
				<>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 1,
						}}
					>
						{children}
					</Box>
				</>
			}
		>
			{element}
		</LightTooltip>
	);
};
