import React, { useState } from 'react';

import { Help } from '@mui/icons-material';
import { Tooltip } from '@material-ui/core';
import { ClickAwayListener, Grid } from '@mui/material';

const IconWithTooltip = ({ title, sizeFont, fixedTooltip }) => {
	const [open, setOpen] = useState(false);

	function handleTooltipClose() {
		setOpen(false);
	}

	function handleTooltipOpen() {
		setOpen(true);
	}

	return (
		<Grid item>
			<ClickAwayListener onClickAway={handleTooltipClose}>
				<Tooltip
					PopperProps={{
						disablePortal: true,
					}}
					onClose={handleTooltipClose}
					open={open}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					title={
						<div
							style={{
								fontSize: sizeFont ? sizeFont : '16px',
								minWidth: '200px',
								fontWeight: 'normal',
								textShadow: 'none',
								lineHeight: '1.5em',
							}}
						>
							{title}
						</div>
					}
					placement={fixedTooltip}
				>
					<Help
						onClick={handleTooltipOpen}
						style={{ width: '12px' }}
					/>
				</Tooltip>
			</ClickAwayListener>
		</Grid>
	);
};

export default IconWithTooltip;
