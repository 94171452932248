import { ConsolidatedPositionsActionTypes } from './actionTypes';

const CONSOLIDATED_POSITIONS_INITIAL_STATE = {
	rows: [],
	tablePreferences: {
		density: 'standard',
		filter: {
			filterModel: {
				items: [],
				linkOperator: 'and',
				quickFilterValues: [],
				quickFilterLogicOperator: 'and',
			},
		},
		columns: {
			columnVisibilityModel: {
				account: true,
				symbol: true,
				net_pl: true,
				buyQtty: true,
				sellQtty: true,
				lastPx: true,
			},
			orderedFields: [
				'account',
				'symbol',
				'net_pl',
				'marcacao_mercado',
				'buyQtty',
				'sellQtty',
			],
		},
		sorting: {
			sortModel: [
				{
					field: 'symbol',
					sort: 'asc',
				},
			],
		},
	},
};

export function consolidatedPositions(
	state = CONSOLIDATED_POSITIONS_INITIAL_STATE,
	{ type, payload }
) {
	switch (type) {
		case ConsolidatedPositionsActionTypes.RESET_CONSOLIDATED_POSITIONS_STATE:
			return CONSOLIDATED_POSITIONS_INITIAL_STATE;
		case ConsolidatedPositionsActionTypes.SET_CONSOLIDATED_POSITIONS_ROWS:
			return {
				...state,
				rows: payload.rows,
			};
		case ConsolidatedPositionsActionTypes.UPDATE_CONSOLIDATED_POSITIONS_USER_PREFERENCES:
			return {
				...state,
				tablePreferences: {
					...state.tablePreferences,
					...payload.userPreferences,
				},
			};
		case ConsolidatedPositionsActionTypes.UPDATE_CONSOLIDATED_POSITIONS_COLUMNS_PREFERENCES:
			return {
				...state,
				tablePreferences: {
					...state.tablePreferences,
					columns: {
						...state.tablePreferences.columns,
						...payload.columnsPreferences,
					},
				},
			};
		default:
			return state;
	}
}
