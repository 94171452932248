export default {
	title: 'orama',
	colors: {
		green: '#639D31',
		darkGreen: '#498637',
		lightGreen: '#3DFE02',
		white: '#ffffff',
		lightGray: '#EBEBEB',
		mediumGray: '#909192',
		darkGray: '#505152',
		black: '#1A1A1A',
		red: '#e84242',
		orange: '#f57c00',
	},
};
