import styled from 'styled-components';
import { TextField } from '@mui/material';

export const TextFieldMaterial = styled(TextField)`
	input {
		color: #d9911b;
		background: ${props => props.theme.colors.inputBgWidget};
		height: 8px;
		text-align: center;
		padding-top: 5px;
		padding-bottom: 6px;
		width: 140px;
	}

	input::-webkit-inner-spin-button {
		color: pink;
	}

	.MuiInput-underline:before {
		border: none !important;
	}
`;
export const Container = styled.div`
	background: ${props => props.theme.colors.background};
	width: 100%;
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;
	box-sizing: border-box;
`;

export const Right = styled.div`
	display: flex;
	margin-top: 1px;
	justify-content: flex-end;
	color: ${props => props.theme.colors.textColorWidget};
`;

export const Select = styled.select`
	border: none !important;
	line-height: 1;
	border: 0;
	border-radius: 0;
	font-size: 11px;
	background: #212121;
	height: 18px;
	width: 130px;
	color: ${props => (props.textColor === 'P' ? '#1976d2' : '#1976d2')};
	cursor: pointer;
	border-radius: 3px;
	padding-left: 30px;
	transition: 0.5s;
	:hover {
		background-color: ${props =>
		props.textColor === 'P' ? '#007aec17' : '#007aec17'};
		transition: 0.5s;
	}
	option:hover {
		background-color: none !important;
	}
`;

export const ItemForm = styled.div`
	display: flex;
	align-items: center;
	margin-top: 5px;

	.label-result {
		margin: 0 0px 1.3px 6px;
		color: white;
		font-size: 10px;
		width: 48px;
		margin-top: 5px;
	}

	label {
		color: white;
		font-size: 10px;
		width: 126px;
		margin-bottom: 0;
	}
`;

export const CalcButton = styled.button`
	border-radius: 3px;
	background-color: unset;
	color: #1976d2;
	padding: 4px 8px;
	margin-top: 4px;
	margin-bottom: 4px;
	border: 0;
	text-align: left;
	text-transform: uppercase;
	cursor: pointer;
	transition: 0.5s;
	:hover {
		background-color: #007aec17;
		transition: 0.5s;
	}
`;
