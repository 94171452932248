import { api, apiMarketData, apiExec, apiEls, apiAWS } from 'services/api';
import {
	AUTH_SIGN_IN,
	AUTH_SIGN_OUT,
	SET_ACCOUNT_TYPE,
	SET_LAST_UPDATED,
	SET_CONTIGENCY,
	SET_VALIDATEDSIGNATURE,
	SET_DISCONNECTED,
	SET_EXECTYPE,
	SET_PORTAL_LOGIN,
	SET_USER_BLOCKED,
	SET_FAVORITE_2FA,
	SET_USER_AUTH_METHODS,
} from './actionTypes';

const initialState = {
	token: null,
	username: null,
	name: null,
	account: null,
	email: null,
	algos: [],
	signature: null,
	inactivity_logout_interval: 60,
	portalAccess: false,
	validatedSignature: false,
	isDisconnected: true,
	isPortalLogin: false,
	isUserBlocked: false,
	profile: null,
	registrationDate: null,
	twoFactorAuthentication: {
		favoriteMethod: null,
		registeredMethods: [],
	},
	riskEnabled: false,
	custodyEnabled: false,
	products: [],
	sponsor: null,
	sponsorName: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'persist/REHYDRATE':
			if (!payload) return state;

			api.defaults.headers.common.Authorization = `Bearer ${payload.auth.token}`;
			apiMarketData.defaults.headers.common.Authorization = `Bearer ${payload.auth.token}`;
			apiExec.defaults.headers.common.Authorization = `Bearer ${payload.auth.token}`;
			apiEls.defaults.headers.common.Authorization = `Bearer ${payload.auth.token}`;
			apiAWS.defaults.headers.common.Authorization = `Bearer ${payload.auth.token}`;

			return { ...state, ...payload.auth };

		case AUTH_SIGN_IN:
			return { ...state, ...payload };

		case AUTH_SIGN_OUT:
			delete api.defaults.headers.common.Authorization;
			delete apiMarketData.defaults.headers.common.Authorization;
			delete apiExec.defaults.headers.common.Authorization;
			delete apiEls.defaults.headers.common.Authorization;
			delete apiAWS.defaults.headers.common.Authorization;

			return {
				...state,
				token: null,
				username: null,
				name: null,
				account: null,
				email: null,
				cpf: null,
				accounts: [],
				phoneCel: null,
				lastUpdated: null,
				algos: [],
				signature: null,
				userContractDate: null,
				userContractIp: null,
				validatedSignature: false,
				isDisconnected: true,
				isPortalLogin: false,
				isUserBlocked: false,
				profile: null,
				registrationDate: null,
				twoFactorAuthentication: initialState.twoFactorAuthentication,
				riskEnabled: false,
				custodyEnabled: false,
				products: [],
				sponsor: null,
				sponsorName: null,
			};

		case SET_ACCOUNT_TYPE:
			return { ...state, accountType: payload.accountType };
		case SET_LAST_UPDATED:
			return {
				...state,
				lastUpdated: payload.lastUpdated,
			};
		case SET_CONTIGENCY:
			return {
				...state,
				contigency: payload.contigency,
			};
		case SET_VALIDATEDSIGNATURE:
			return {
				...state,
				validatedSignature: payload.validatedSignature,
			};
		case SET_DISCONNECTED:
			return {
				...state,
				isDisconnected: payload.isDisconnected,
			};
		case SET_EXECTYPE:
			return {
				...state,
				execType: payload.execType,
			};
		case SET_PORTAL_LOGIN:
			return {
				...state,
				isPortalLogin: payload.isPortalLogin,
			};
		case SET_USER_BLOCKED:
			return {
				...state,
				isUserBlocked: payload.isBlocked,
			};
		case SET_FAVORITE_2FA:
			return {
				...state,
				twoFactorAuthentication: {
					...state.twoFactorAuthentication,
					favoriteMethod: payload.favoriteMethod,
				},
			};
		case SET_USER_AUTH_METHODS:
			return {
				...state,
				twoFactorAuthentication: {
					...state.twoFactorAuthentication,
					registeredMethods: payload.authMethods,
				},
			};
		default:
			return state;
	}
};
