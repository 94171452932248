const cleanCloseAuctionCustom = (hash, bullet) => {
	hash.CustomParameters.StopType = hash.CustomParameters.StopType
		? hash.CustomParameters.StopType
		: 1;
	hash.CustomParameters.ReversionType =
        !hash.CustomParameters.ReversionType ||
        hash.CustomParameters.ReversionType === 3
        	? 3
        	: 1;
	hash.CustomParameters.UseLevel3 =
        !hash.CustomParameters.UseLevel3 ||
        hash.CustomParameters.UseLevel3 === 'N'
        	? 'N'
        	: 'Y';
	let cleanCustomParameters;
	if (hash.CustomParameters.StopType == 3) {
		cleanCustomParameters = {
			UseAuctionOpenPrice:
                !hash.CustomParameters.UseAuctionOpenPrice ||
                !hash.CustomParameters.UseAuctionOpenPrice === 'N'
                	? 'N'
                	: 'Y',
			OrderQuantity: hash.CustomParameters.OrderQuantity,
			CustomOrdType: hash.CustomParameters.CustomOrdType,
			ReversionType: hash.CustomParameters.stop
				? hash.CustomParameters.ReversionType
				: 4,
			StopType: hash.CustomParameters.StopType
				? hash.CustomParameters.StopType
				: 1,
			TriggerEntryType: hash.CustomParameters.TriggerEntryType
				? hash.CustomParameters.TriggerEntryType
				: 2,
			StopLoss: parseFloat(hash.CustomParameters.StopLoss),
			UseLevel3: hash.CustomParameters.UseLevel3,
			StopGainL1: parseFloat(hash.CustomParameters.StopGainL1),
			OrderQuantityL1: parseFloat(hash.CustomParameters.OrderQuantityL1),
			StopGainL2: parseFloat(hash.CustomParameters.StopGainL2),
			OrderQuantityL2: parseFloat(hash.CustomParameters.OrderQuantityL2),
		};
		if (cleanCustomParameters.UseLevel3 === 'Y') {
			cleanCustomParameters.StopGainL3 = parseFloat(
				hash.CustomParameters.StopGainL3,
			);
			cleanCustomParameters.OrderQuantityL3 = parseFloat(
				hash.CustomParameters.OrderQuantityL3,
			);
		}
	} else if (hash.CustomParameters.StopType == 1) {
		cleanCustomParameters = {
			UseAuctionOpenPrice:
                !hash.CustomParameters.UseAuctionOpenPrice ||
                !hash.CustomParameters.UseAuctionOpenPrice === 'N'
                	? 'N'
                	: 'Y',
			OrderQuantity: hash.CustomParameters.OrderQuantity,
			CustomOrdType: hash.CustomParameters.CustomOrdType,
			ReversionType: hash.CustomParameters.stop
				? hash.CustomParameters.ReversionType
				: 4,
			StopType: hash.CustomParameters.StopType
				? hash.CustomParameters.StopType
				: 1,
			TriggerEntryType: hash.CustomParameters.TriggerEntryType,
			StopLoss: parseFloat(hash.CustomParameters.StopLoss),
			StopGain: parseFloat(hash.CustomParameters.StopGain),
			UseLevel3: 'N',
		};
	} else {
		cleanCustomParameters = {
			UseAuctionOpenPrice:
                !hash.CustomParameters.UseAuctionOpenPrice ||
                !hash.CustomParameters.UseAuctionOpenPrice === 'N'
                	? 'N'
                	: 'Y',
			OrderQuantity: hash.CustomParameters.OrderQuantity,
			CustomOrdType: hash.CustomParameters.CustomOrdType,
			ReversionType: hash.CustomParameters.stop
				? hash.CustomParameters.ReversionType
				: 4,
			StopType: hash.CustomParameters.StopType
				? hash.CustomParameters.StopType
				: 1,
			TriggerEntryType: hash.CustomParameters.TriggerEntryType,
			StopLoss: parseFloat(hash.CustomParameters.StopLoss),
			UseLevel3: 'N',
		};
	}
	if (!hash.CustomParameters.stop) {
		cleanCustomParameters.StopLoss =
            bullet.StrategyInfos[0].minPriceIncrement;
		cleanCustomParameters.StopGain =
            bullet.StrategyInfos[0].minPriceIncrement;
		cleanCustomParameters.TriggerEntryType = 1;
	}
	if (cleanCustomParameters.UseAuctionOpenPrice === 'N') {
		cleanCustomParameters.StartPriceTrigger =
            hash.CustomParameters.PriceLimit;
	}
	return cleanCustomParameters;
};

export default cleanCloseAuctionCustom;
