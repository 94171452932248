import React, { useState } from 'react';
import { Box, Grid, IconButton, Tooltip } from '@material-ui/core';
import { Book, SwapVert } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
	setMarketPriceLoading,
	strategyUpdate,
} from 'store/modules/bottomBullet/actions';
import ComboBoxInput from '../ComboBoxInput';
import MaterialCheckBox from '../MaterialCheckBox';
import { ButtonBuySell } from '../MaterialStyles/style';
import {
	additionalUpdate,
	updateStrategyLeg,
} from '../../../store/modules/bottomBullet/actions';
import DecimalInput from '../DecimalInput';
import { getFinatialValue } from '../../../utils/strategies';
import paperValidator from 'utils/paperValidation';
import { useTranslation } from 'react-i18next';
import addWidgetFromButton from 'utils/addWidgetFromButton';
import { symbolOpenBook } from 'utils/symbolOpenBook';
import { apiEls } from 'services/api';

const SkewLeg = ({
	onSuggestionsFetchRequested,
	setPaperInfo,
	index,
	onlyCall,
	onlyOptions,
	callbackMarketPrice,
	stepFunction,
}) => {
	const { bullet } = useSelector(state => state.bottomBullet);
	const dispatch = useDispatch();
	const [legResting, setLegResting] = useState(false);
	const [updateMarketPrice, setUpdateMarketPrice] = useState(false);
	const { t } = useTranslation();
	const lockBook = useSelector(state => state.configs.widgetIsLoading);

	if (bullet.editing || bullet.clone) {
		if (!updateMarketPrice) {
			setUpdateMarketPrice(true);
			callbackMarketPrice(
				bullet.content.StrategyLegs,
				bullet.content.CustomParameters.Trigger
			);
		}

		const tempLegResting =
			(bullet.content.StrategyLegs[index].LegResting ?? 'N') === 'Y';

		if (legResting !== tempLegResting) {
			setLegResting(tempLegResting);
		}
	}

	const handleChange = (type, subType, value, indexLeg) => {
		const hash = { ...bullet };

		if (type === 'Leg') {
			hash.content.StrategyLegs[indexLeg][subType] = value;
		} else if (type === 'Custom') {
			hash.content.CustomParameters[subType] = value;
		}

		if (subType === 'LegQuantity') {
			hash.StrategyInfos[indexLeg].finantial = getFinatialValue(
				hash.StrategyInfos[indexLeg].lastPx,
				hash.content.StrategyLegs[indexLeg].LegQuantity,
				hash.StrategyInfos[indexLeg].asset
			);
		}

		if (subType === 'LegQuantity' || subType === 'LegSymbol') {
			hash.content.CustomParameters.TriggerValue = null;
			callbackMarketPrice(
				hash.content.StrategyLegs,
				hash.content.CustomParameters.Trigger
			);
			dispatch(setMarketPriceLoading(true));
		}

		dispatch(additionalUpdate('StrategyInfos', hash.StrategyInfos));
		dispatch(strategyUpdate(hash.content));
	};

	const changeLegSide = index => {
		bullet.content.CustomParameters.TriggerValue = null;
		dispatch(setMarketPriceLoading(true));

		dispatch(
			updateStrategyLeg(
				{
					LegSide:
						bullet.content.StrategyLegs[index].LegSide === '1'
							? '2'
							: '1',
				},
				index
			)
		);
	};

	const setPaperInfoInterceptor = (tempIndex, data) => {
		if (data.optionType !== 'NOT A OPTION') {
			setPaperInfo(tempIndex, data);
			apiEls.get(`/search/${data.underlyingSymbol}`).then(response => {
				if (response && response.data._source) {
					// workaround para ticksize de dolar e minidolar usar tickSizeDenominator com valor 2.
					if (['DOL', 'WDO'].includes(response.data._source.asset)) {
						response.data._source.tickSizeDenominator = 2;
					}
					if (response.data._source.isContinuous) {
						response.data._source.symbol = response.data._source.continuousRef;
					}

					setPaperInfo(2, {
						...response.data._source,
					});
				}
				callbackMarketPrice(
					bullet.content.StrategyLegs,
					bullet.content.CustomParameters.Trigger
				);
			});
		}
	};

	const handleOnChangeLegResting = () => {
		handleChange('Leg', 'LegResting', !legResting ? 'Y' : 'N', index);
		setLegResting(!legResting);
	};

	return (
		<>
			<Grid container item xs={12} spacing={1}>
				<Box pb={0.5}>
					<Tooltip title="Abrir book">
						<IconButton
							size="small"
							onClick={() =>
								addWidgetFromButton(
									bullet.content.StrategyLegs[index].LegSymbol
								)
							}
							disabled={
								symbolOpenBook(
									bullet.StrategyInfos[0],
									bullet
								) || lockBook
							}
						>
							<Book />
						</IconButton>
					</Tooltip>
				</Box>
				<Grid item xs={2}>
					{index == 2 ? (
						<ComboBoxInput
							label="Ativo"
							setPaperInfo={setPaperInfoInterceptor}
							comboOptions={
								bullet.suggestions && bullet.suggestions.source
									? {
										value: bullet.suggestions.source.id,
										title: bullet.suggestions.source
											.symbol,
									  }
									: bullet.suggestions
							}
							index={index}
							disabled={true}
							value={bullet.content.StrategyLegs[index].LegSymbol}
							isPaper
						/>
					) : (
						<ComboBoxInput
							label="Opção"
							setPaperInfo={setPaperInfoInterceptor}
							comboOptions={
								bullet.suggestions && bullet.suggestions.source
									? {
										value: bullet.suggestions.source.id,
										title: bullet.suggestions.source
											.symbol,
									  }
									: bullet.suggestions
							}
							onChange={e =>
								onSuggestionsFetchRequested(
									e.target.value,
									onlyOptions,
									onlyCall,
									index
								)
							}
							index={index}
							disabled={bullet.content.ClOrdID}
							value={bullet.content.StrategyLegs[index].LegSymbol}
							isPaper
						/>
					)}
				</Grid>
				{index !== 2 && (
					<>
						<Grid item xs>
							<ButtonBuySell
								variant="contained"
								fullWidth
								size="small"
								color={
									bullet.content.StrategyLegs[index]
										.LegSide === '1'
										? 'primary'
										: 'secondary'
								}
								startIcon={<SwapVert />}
								disabled={
									paperValidator(
										bullet.StrategyInfos[index],
										bullet
									) || bullet.content.ClOrdID
								}
								onClick={() => changeLegSide(index)}
							>
								{bullet.content.StrategyLegs[index].LegSide ===
								'1'
									? 'Compra'
									: 'Venda'}
							</ButtonBuySell>
						</Grid>
						<Grid item xs={2}>
							{(bullet.content.CustomParameters.ExecutionMode ===
								1 ||
								bullet.content.CustomParameters
									.ExecutionMode === 2 ||
								index === 0) && (
								<DecimalInput
									disabled={paperValidator(
										bullet.StrategyInfos[index],
										bullet
									)}
									label="Quantidade"
									minVal={
										bullet.StrategyInfos[index]
											.minOrderQty ?? 0
									}
									maxVal={
										bullet.StrategyInfos[index]
											.maxOrderQty ?? 9999999
									}
									minDecScale={0} // casas decimais
									stepFunction={stepFunction}
									stepType="Leg" // type
									stepSubType="LegQuantity" // subtype
									stepSize={
										bullet.StrategyInfos[index].roundLot ||
										bullet.StrategyInfos[index]
											.Cst_MinOrderQuantity
									} // incremento
									valueToStep={
										bullet.content.StrategyLegs[index]
											.LegQuantity
									} // valor a ser modificado
									value={
										bullet.content.StrategyLegs[index]
											.LegQuantity
									}
									legIndex={index}
									onChange={e =>
										handleChange(
											'Leg',
											'LegQuantity',
											parseInt(e.value),
											index
										)
									}
								/>
							)}
						</Grid>
						<Grid item xs>
							<DecimalInput
								disabled={paperValidator(
									bullet.StrategyInfos[index],
									bullet
								)}
								label="Clip"
								iconTooltip={t('bullets.general.clip')}
								minVal={
									bullet.StrategyInfos[index].minOrderQty ?? 0
								}
								maxVal={
									bullet.StrategyInfos[index].maxOrderQty ??
									9999999
								}
								minDecScale={0} // casas decimais
								stepFunction={stepFunction}
								stepType="Leg" // type
								stepSubType="LegMaxClipSize" // subtype
								stepSize={
									bullet.StrategyInfos[index].roundLot ||
									bullet.StrategyInfos[index]
										.Cst_MinOrderQuantity
								} // incremento
								valueToStep={
									bullet.content.StrategyLegs[index]
										.LegMaxClipSize
								} // valor a ser modificado
								value={
									bullet.content.StrategyLegs[index]
										.LegMaxClipSize
								}
								legIndex={index}
								onChange={e =>
									handleChange(
										'Leg',
										'LegMaxClipSize',
										parseInt(e.value),
										index
									)
								}
							/>
						</Grid>
						<Grid item xs>
							<Box mt={-0.3}>
								<MaterialCheckBox
									labelSide="top"
									label="Resting"
									disabled={paperValidator(
										bullet.StrategyInfos[index],
										bullet
									)}
									checked={
										bullet.content.StrategyLegs[index]
											.LegResting === 'Y'
									}
									onChange={() => handleOnChangeLegResting()}
								/>
							</Box>
						</Grid>
						{(bullet.content.CustomParameters.ExecutionMode == 2 ||
							bullet.content.CustomParameters.ExecutionMode ==
								3 ||
							bullet.content.CustomParameters.ExecutionMode ==
								5) &&
							bullet.content.CustomParameters.DeltaType !== 2 && (
							<Grid item xs>
								<DecimalInput
									disabled={paperValidator(
										bullet.StrategyInfos[index],
										bullet
									)}
									label="Delta"
									iconTooltip={
										bullet.StrategyInfos[index]
											.optionType === 'PUT'
											? t(
												'bullets.volatility.delta_put'
												  )
											: t(
												'bullets.volatility.delta_call'
												  )
									}
									minVal={
										bullet.StrategyInfos[index]
											.optionType === 'PUT'
											? -1.0
											: 0
									}
									maxVal={
										bullet.StrategyInfos[index]
											.optionType === 'PUT'
											? 0
											: 1.0
									}
									minDecScale={3} // casas decimais
									stepFunction={stepFunction}
									stepType="Custom" // type
									stepSubType={
										index === 0
											? 'DeltaFixedValue1'
											: 'DeltaFixedValue2'
									} // subtype
									stepSize={0.001} // incremento
									valueToStep={
										index === 0
											? bullet.content
												.CustomParameters
												.DeltaFixedValue1
											: bullet.content
												.CustomParameters
												.DeltaFixedValue2
									} // valor a ser modificado
									value={
										index === 0
											? bullet.content
												.CustomParameters
												.DeltaFixedValue1 ?? 0
											: bullet.content
												.CustomParameters
												.DeltaFixedValue2 ?? 0
									}
									legIndex={index}
									onChange={e =>
										handleChange(
											'Custom',
											index === 0
												? 'DeltaFixedValue1'
												: 'DeltaFixedValue2',
											parseFloat(e.value),
											index
										)
									}
								/>
							</Grid>
						)}
						<Grid item xs>
							<DecimalInput
								disabled={
									paperValidator(
										bullet.StrategyInfos[index],
										bullet
									) || bullet.content.ClOrdID
								}
								label="Dias p/expiração"
								iconTooltip={t(
									'bullets.volatility.days_to_expiration'
								)}
								minVal={0}
								maxVal={9999999}
								minDecScale={0} // casas decimais
								stepFunction={stepFunction}
								stepType="Custom" // type
								stepSubType={
									bullet.StrategyInfos[index]
										.workDaysUntilMaturity
								} // subtype
								stepSize={1} // incremento
								valueToStep={
									bullet.StrategyInfos[index]
										.workDaysUntilMaturity
								} // valor a ser modificado
								value={
									bullet.content.ClOrdID || bullet.clone
										? index === 0
											? bullet.content.CustomParameters
												.DaysToExpiration1
											: bullet.content.CustomParameters
												.DaysToExpiration2
										: bullet.StrategyInfos[index]
											.workDaysUntilMaturity
								}
								legIndex={index}
							/>
						</Grid>
					</>
				)}
			</Grid>
		</>
	);
};

export default SkewLeg;
