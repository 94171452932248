export function getMaxClipSize(minOrderQty, quantity) {
	if (minOrderQty === 1) {
		if (quantity >= 1000) {
			return 100;
		} else if (quantity >= 100) {
			return 10;
		}
	}

	return minOrderQty;
}
