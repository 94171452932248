import { apiAWS } from 'services/api';
import { store } from 'store';

import { updateUserPreferences } from 'store/modules/executions/actions';

export function getExecutionsPreferences(username) {
	apiAWS
		.get(`/userpreferences/${username}?service=executions`)
		.then(({ data }) => {
			let formattedResponse = data;

			delete formattedResponse.user_name;
			delete formattedResponse.service_name;

			if (formattedResponse.columnVisibilityModel) {
				formattedResponse.columns = {
					...formattedResponse.columns,
					columnVisibilityModel:
						formattedResponse.columnVisibilityModel,
				};

				delete formattedResponse.columnVisibilityModel;
			}

			const columnsFields = ['orderedFields', 'columnsSize'];

			columnsFields.forEach(field => {
				if (field in formattedResponse) {
					formattedResponse.columns = {
						...formattedResponse.columns,
						[field]: formattedResponse[field],
					};

					delete formattedResponse[field];
				} else if (
					formattedResponse.columns &&
					!(field in formattedResponse.columns)
				) {
					formattedResponse.columns = {
						...formattedResponse.columns,
						[field]: [],
					};
				}
			});

			if (formattedResponse.tableFilter) {
				formattedResponse.filter = formattedResponse.tableFilter;
				delete formattedResponse.tableFilter;
			}

			store.dispatch(updateUserPreferences(formattedResponse));
		})
		.catch(error => console.error(error));
}

export function postExecutionsPreferences(username, body) {
	apiAWS
		.post(`/userpreferences/${username}?service=executions`, body)
		.catch(error => console.error(error));
}
