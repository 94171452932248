import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { doLogout } from 'store/modules/auth/actions';
import { useHistory } from 'react-router-dom';

export default function Logout() {
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		dispatch(doLogout(history));
	}, []);

	return <></>;
}
