import { toast } from 'react-toastify';

const skewClear = (bullet, setAccessorInfos, account) => {
	const hash = { ...bullet.content };
	const strategyInfos = { ...bullet.StrategyInfos };
	if (
		strategyInfos[0].optionType === 'NOT A OPTION' ||
        strategyInfos[1].optionType === 'NOT A OPTION'
	) {
		toast.error('Simbolo não permitido para esta estratégia');
		return;
	}
	if (
		strategyInfos[0].underlyingSymbol !== strategyInfos[1].underlyingSymbol
	) {
		toast.error('As opções devem ser do mesmo ativo');
		return;
	}
	if (
		(bullet.content.CustomParameters.ExecutionMode === 2 ||
        bullet.content.CustomParameters.ExecutionMode === 3 ||
        bullet.content.CustomParameters.ExecutionMode === 5) &&
        bullet.content.CustomParameters.DeltaType !== 2
	) {
		if (
			!hash.CustomParameters.DeltaFixedValue1 ||
                !hash.CustomParameters.DeltaFixedValue2
		) {
			toast.error('Preencha o campo delta');
			return;
		} else if (strategyInfos[0].optionType === 'PUT' && bullet.content.CustomParameters.DeltaFixedValue1 > 0) {
			toast.error('Delta inválido. Ao adicionar uma Put o Delta deve ser negativo.');
			return;
		} else if (strategyInfos[1].optionType === 'PUT' && bullet.content.CustomParameters.DeltaFixedValue2 > 0) {
			toast.error('Delta inválido. Ao adicionar uma Put o Delta deve ser negativo.');
			return;
		} else if (strategyInfos[0].optionType === 'CALL' && bullet.content.CustomParameters.DeltaFixedValue1 < 0) {
			toast.error('Delta inválido. Ao adicionar uma Call o Delta deve ser positivo.');
			return;
		} else if (strategyInfos[1].optionType === 'CALL' && bullet.content.CustomParameters.DeltaFixedValue2 < 0) {
			toast.error('Delta inválido. Ao adicionar uma Call o Delta deve ser positivo.');
			return;
		}
	}
	if (hash.StrategyLegs[0].LegMaxClipSize > hash.StrategyLegs[0].LegQuantity || hash.StrategyLegs[1].LegMaxClipSize > hash.StrategyLegs[1].LegQuantity) {
		toast.error('Clip não pode ser maior que a quantidade');
		return;
	}

	if (hash.StrategyLegs.some(e => e.LegQuantity <= 0)) {
		return { errorMessage: 'A quantidade precisa ser maior que 0' };
	}

	hash.StrategyLegs.forEach((leg, index) => {
		hash.StrategyLegs[index] = {
			ILegAllocAccount: account(),
			LegSymbol: hash.StrategyLegs[index].LegSymbol,
			LegSecurityExchange: strategyInfos[index === 2 ? 0 : index]
				.subExchange
				? strategyInfos[index === 2 ? 0 : index].subExchange
				: 'XBSP',
			LegSide: hash.StrategyLegs[index].LegSide,
			LegQuantity: parseInt(hash.StrategyLegs[index].LegQuantity, 10),
			LegResting: hash.StrategyLegs[index].LegResting,
			LegMaxClipSize: parseInt(
				hash.StrategyLegs[index].LegMaxClipSize,
				10,
			),
		};
	});

	hash.StrategyLegs[2] = {
		ILegAllocAccount: account(),
		LegSymbol: bullet.StrategyInfos[0].underlyingSymbol ?? bullet.content.StrategyLegs[2].LegSymbol,
		LegSecurityExchange: strategyInfos[0].subExchange
			? strategyInfos[0].subExchange
			: 'XBSP',
		LegSide: hash.StrategyLegs[0].LegSide,
		LegQuantity: strategyInfos[0].minOrderQty,
		LegResting: 'N',
		LegMaxClipSize: strategyInfos[0].minOrderQty,
	};

	hash.CustomParameters = {
		TriggerValue: bullet.content.CustomParameters.TriggerValue,
		ExecutionLimit: bullet.content.CustomParameters.ExecutionLimit,
		ExecutionLimitValue:
            bullet.content.CustomParameters.ExecutionLimitValue,
		DeltaType: bullet.content.CustomParameters.DeltaType,
		ExecutionMode: bullet.content.CustomParameters.ExecutionMode,
		InterestRate: bullet.content.CustomParameters.InterestRate,
		DaysToExpiration1: bullet.content.CustomParameters.DaysToExpiration1,
		DaysToExpiration2: bullet.content.CustomParameters.DaysToExpiration2,
		BookDepth: bullet.content.CustomParameters.BookDepth,
	};

	if (
		(bullet.content.CustomParameters.ExecutionMode === 2 ||
        bullet.content.CustomParameters.ExecutionMode === 3 ||
        bullet.content.CustomParameters.ExecutionMode === 5) &&
        bullet.content.CustomParameters.DeltaType !== 2
	) {
		hash.CustomParameters.DeltaFixedValue1 =
            bullet.content.CustomParameters.DeltaFixedValue1;
		hash.CustomParameters.DeltaFixedValue2 =
            bullet.content.CustomParameters.DeltaFixedValue2;
	} else {
		hash.CustomParameters.DeltaFixedValue1 = strategyInfos[0].optionType === 'PUT' ? -0.001 : 0.001;
		hash.CustomParameters.DeltaFixedValue2 = strategyInfos[1].optionType === 'PUT' ? -0.001 : 0.001;
	}
	hash.StrategyLegs = setAccessorInfos(hash.StrategyLegs);

	delete hash.signature;
	delete hash.clean;

	const cleanedHash = {
		'Name': hash.Name,
		'InitTime': hash.InitTime,
		'EndTime': hash.EndTime,
		'ExpireDate': hash.ExpireDate,
		'Text': hash.Text,
		'BasketId': hash.BasketId,
		'StrategyCode': hash.StrategyCode,
		'CustomParameters': hash.CustomParameters,
		'StrategyLegs': hash.StrategyLegs,
		'Signature': hash.Signature
	};

	if (process.env.NODE_ENV === 'development')
		console.log('skew submit', cleanedHash);

	return cleanedHash;
};

export default skewClear;
