import React, { useState, useEffect, memo } from 'react';
import { calcOptionPrice } from '@investflex/iflexquantjs';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, IconButton } from '@mui/material';
import { Explicit, LockOpen, LockOutlined } from '@mui/icons-material';

import { LightTooltip } from 'components/LightTooltip';
import { SimulatorDecimalInput } from './DecimalInput';

import {
	updatePosition,
	setSavedStrategy,
} from 'store/modules/simulator/actions';
import { getPaper } from '../utils';

export const InputExitPx = memo(function InputExitPx({ value, position }) {
	const {
		exitManualMode,
		exitPxLocked,
		exitImpliedVol,
		id,
		expired,
		optionType,
		strikePx,
		daysToExpiration,
	} = position;

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const ir = useSelector(state => state.simulator.ir);
	const activeStrategy = useSelector(state => state.simulator.activeStrategy);
	const stratShowTypeExit = useSelector(
		state => state.simulator.strategyList[activeStrategy].showTypeExit
	);

	const paper = useSelector(getPaper);
	const [workingPrice, setWorkingPrice] = useState(value); //Stores working price

	const pxExitSource = {
		top: 'last_exitPx',
		mid: 'last_midPx',
		last: 'last_tradePx',
	}[stratShowTypeExit];

	useEffect(() => {
		if (optionType) {
			//OPÇÃO
			if (!exitPxLocked && !exitManualMode) {
				setWorkingPrice(position[pxExitSource]);
				updateValue(position[pxExitSource]);
			}
		} else if (!exitPxLocked) {
			//ATIVO
			const newValue = position[pxExitSource] || 0;

			if (newValue !== workingPrice) {
				setWorkingPrice(newValue);
				updateValue(newValue);
			}
		}
	}, [position[pxExitSource], stratShowTypeExit, exitPxLocked, exitManualMode]);

	// Função que atualiza o preço baseado na vol somente em simulação (manual mode) e cadeado verde
	useEffect(() => {
		if (exitManualMode && !exitPxLocked) {
			handleUpdateTheorPx();
		}
	}, [paper?.price, strikePx, ir, daysToExpiration, exitImpliedVol]);

	// Função que atualiza o preço baseado na vol para cada acessor sendo que exit e entry somente em simulação
	function handleUpdateTheorPx() {
		const volatility = exitImpliedVol / 100;

		const newValue = parseFloat(
			calcOptionPrice(
				paper?.price,
				strikePx,
				ir,
				volatility,
				daysToExpiration,
				optionType
			).toFixed(4)
		);

		if (newValue !== value && newValue !== workingPrice) {
			setWorkingPrice(newValue);
			updateValue(newValue);
		}
	}

	function updateValue(value) {
		dispatch(
			updatePosition(activeStrategy, id, {
				exitPx: parseFloat(value),
			})
		);
	}

	// eslint-disable-next-line no-unused-vars
	function toggleLock() {
		if (position.expired) return;

		dispatch(setSavedStrategy(activeStrategy, false));
		const greeksDefault = { delta: 0, gamma: 0, vega: 0, theta: 0, rho: 0 };
		const greeksDefaultNA = {
			delta: null,
			gamma: null,
			vega: null,
			theta: null,
			rho: null,
		};

		if (position?.exitPxLocked) {
			//Se estava fechado, e vai abrir a saída
			let greeks =
				value === 0 && position.optionType
					? greeksDefaultNA
					: { ...greeksDefault, delta: !position.optionType ? 1 : 0 };
			dispatch(
				updatePosition(activeStrategy, position.id, {
					entryGreeks: greeks,
					exitGreeks: greeks,
					exitPxLocked: false,
					exitPx: value, //!position.exitPxLocked ? position[ getPxExitSource() ] : value,
					exitManualMode: false, //Tira do manual mode se o cadeado (da entrada) for fechado
				})
			);
		} else {
			//if(!position?.exitPxLocked){//Se estava aberto e vai fechar a linha toda
			dispatch(
				updatePosition(activeStrategy, position.id, {
					entryPxLocked: true,
					exitPxLocked: true,
					entryGreeks: greeksDefault,
					exitGreeks: greeksDefault,
					entryManualMode: false, //Tira do manual mode se o cadeado for fechado
					exitManualMode: false, //Tira do manual mode se o cadeado for fechado
				})
			);
		}
	}

	function handleToggle() {
		if (expired) {
			return;
		}

		dispatch(setSavedStrategy(activeStrategy, false));

		const greeksDefault = { delta: 0, gamma: 0, vega: 0, theta: 0, rho: 0 };
		const greeksDefaultNA = {
			delta: null,
			gamma: null,
			vega: null,
			theta: null,
			rho: null,
		};

		if (exitPxLocked) {
			//Se estava fechado, e vai abrir a saída
			const greeks =
				value === 0 && position.optionType
					? greeksDefaultNA
					: { ...greeksDefault, delta: !position.optionType ? 1 : 0 };

			dispatch(
				updatePosition(activeStrategy, position.id, {
					entryGreeks: greeks,
					exitGreeks: greeks,
					exitPxLocked: false,
					exitPx: value, //!position.exitPxLocked ? position[ getPxExitSource() ] : value,
					exitManualMode: false, //Tira do manual mode se o cadeado (da entrada) for fechado
				})
			);
		} else {
			dispatch(
				updatePosition(activeStrategy, position.id, {
					entryPxLocked: true,
					exitPxLocked: true,
					entryGreeks: greeksDefault,
					exitGreeks: greeksDefault,
					entryManualMode: false, //Tira do manual mode se o cadeado for fechado
					exitManualMode: false, //Tira do manual mode se o cadeado for fechado
				})
			);
		}
	}

	function handleChange(event) {
		if (!(typeof event.value === 'undefined')) {
			updateValue(event.value);

			if (event.type === 'change') {
				dispatch(setSavedStrategy(activeStrategy, false));
			}
		}
	}

	const iconColor = exitPxLocked
		? expired
			? '#ce8100'
			: '#ff5a5a'
		: '#388e3c';

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<SimulatorDecimalInput
				width={40}
				minVal={0}
				minDecScale={4}
				stepSize={0.01}
				maxVal={999999.99}
				disabled={!exitPxLocked}
				onChange={event => handleChange(event)}
				value={exitPxLocked ? value : workingPrice || '-'} //Exibe '-' se o valor quando editavel for zero
			/>

			<IconButton
				size="small"
				onClick={handleToggle}
				style={{ color: iconColor }}
			>
				<LightTooltip
					arrow
					title={
						exitPxLocked &&
						expired &&
						t('simulator.tooltip.expiredButton')
					}
				>
					{exitPxLocked ? (
						expired ? (
							<Explicit />
						) : (
							<LockOutlined />
						)
					) : (
						<LockOpen />
					)}
				</LightTooltip>
			</IconButton>
		</Box>
	);
});
