import {
	GLOBAL_STATUS_UPDATE_MARKET,
	GLOBAL_STATUS_UPDATE_EXECUTION,
	GLOBAL_STATUS_UPDATE_ALL,
} from './actionTypes';

const initialState = {
	market: false,
	execution: false,
};

const reducer = {
	[GLOBAL_STATUS_UPDATE_MARKET]: (state, payload) => {
		return {
			...state,
			market: payload.marketStatus,
		};
	},

	[GLOBAL_STATUS_UPDATE_EXECUTION]: (state, payload) => {
		return {
			...state,
			execution: payload.executionStatus,
		};
	},

	[GLOBAL_STATUS_UPDATE_ALL]: (state, payload) => {
		return {
			...state,
			execution: payload.executionStatus,
			market: payload.marketStatus,
		};
	},
};

export default function globalStateReducer(
	state = initialState,
	{ type, payload },
) {
	if (typeof reducer[type] === 'function') {
		return reducer[type](state, payload);
	}
	return state;
}
