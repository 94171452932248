import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SimulatorDecimalInput } from './DecimalInput';

import {
	setSavedStrategy,
	updatePosition,
} from 'store/modules/simulator/actions';
import { getPaper } from '../utils';

export const InputQtty = ({ position, value }) => {
	const dispatch = useDispatch();

	const minOrderQty = useSelector(getPaper).minOrderQty;
	const activeStrategy = useSelector(state => state.simulator.activeStrategy);

	function handleChange(event) {
		const inputValue = parseFloat(event.value);

		if (!isNaN(inputValue) && inputValue !== 0) {
			const data = {
				qtty: value > 0 ? inputValue : -inputValue,
			};

			dispatch(updatePosition(activeStrategy, position.id, data));
			dispatch(setSavedStrategy(activeStrategy, false));
		}
	}

	function handleBlur() {
		const roundedNumber =
			Math.ceil(parseFloat(value) / minOrderQty) * minOrderQty;

		const data = {
			qtty: parseInt(roundedNumber),
		};

		dispatch(updatePosition(activeStrategy, position.id, data));
		dispatch(setSavedStrategy(activeStrategy, false));
	}

	return (
		<SimulatorDecimalInput
			width={40}
			minVal={0}
			maxVal={999999}
			minDecScale={0}
			onBlur={handleBlur}
			stepSize={minOrderQty}
			value={Math.abs(value)}
			onChange={event => handleChange(event)}
		/>
	);
};
