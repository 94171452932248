import { addDefinitions, formatToTablePattern } from './formatStrategies';

export function partialStrategyCalc(strategy, interestRate) {
	const positionsToCalc = [];

	strategy.positions.forEach(position => {
		if (position.hasChanged) {
			positionsToCalc.push(position);
			position.hasChanged = false;
		}
	});

	if (positionsToCalc.length > 0) {
		const strategyToCalc = {
			id: strategy.id,
			name: strategy.name,
			mainStrategy: strategy,
			underPrice: strategy.underPrice,
			positions: positionsToCalc,
			histVol30: strategy.histVol30,
		};

		const strategiesCalculated = addDefinitions(
			[strategyToCalc],
			interestRate,
			true
		);

		const rowsWithTablePattern = formatToTablePattern(strategiesCalculated);

		const formatedRows = rowsWithTablePattern.map(row => {
			Object.keys(row).forEach(field => {
				if (row[field] === null || row[field] === undefined) {
					delete row[field];
				}
			});

			return row;
		});

		return formatedRows;
	}

	return null;
}
