import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { apiAWS } from 'services/api';
import {
	setContractToAccept,
	signContract,
} from 'store/modules/products/actions';

export const AcceptableContract = ({ isOpen, onClose }) => {
	const dispatch = useDispatch();

	const { username, token } = useSelector(state => state.auth);
	const pendingContract = useSelector(
		state => state.products.userContracts.contractToAccept
	);

	const [isLoading, setIsLoading] = useState(false);

	function handleClose() {
		dispatch(setContractToAccept(null));
		onClose();
	}

	function handleSubmit() {
		setIsLoading(true);

		const headers = { headers: { Authorization: `Bearer ${token}` } };

		apiAWS
			.post(`/users/${username}/contracts`, pendingContract, headers)
			.then(response => {
				console.log(response.data);
				dispatch(signContract(pendingContract));
				onClose();
				toast.success('Contrato assinado com sucesso.');
			})
			.catch(error => {
				console.error(error);
				toast.error(
					'Problema durante a assinatura do contrato. Tente novamente mais tarde.'
				);
			})
			.finally(() => setIsLoading(false));
	}

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>Termos de uso</DialogTitle>

			<DialogContent>
				<DialogContentText sx={{ mb: 2 }}>
					Você precisa aceitar os termos de uso para utilizar este
					produto.
				</DialogContentText>

				<iframe src={pendingContract?.file} width="500" height="300" />
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose} variant="contained" color="error">
					Fechar
				</Button>

				<LoadingButton
					onClick={handleSubmit}
					variant="contained"
					color="primary"
					loading={isLoading}
				>
					Aceitar
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
