import React from 'react';

import { StyledButton } from './styles';

const Button = ({
	variant = 'contained',
	backgroundcolor,
	children,
	width,
	color,
	disabled,
	onClick,
	fontFamily,
}) => {
	return (
		<StyledButton
			variant={variant}
			type="submit"
			backgroundcolor={backgroundcolor}
			width={width}
			color={color}
			disabled={disabled}
			onClick={onClick}
			fontFamily={fontFamily}
		>
			{children}
		</StyledButton>
	);
};

export default Button;
