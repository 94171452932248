import React from 'react';
import { ListContainer } from './styles';

export default function Dropdown(props) {
	const { id, visible, options, handleClick, index } = props;
	return (
		<div>
			{ visible &&   <ListContainer>
				<ul>
					{options &&   
            options.map(option => (
            	<li key={option.id} onClick={()=> handleClick(id, option.id, index)}>
            		{option.name}
            	</li>
            ))
					}
				</ul>
			</ListContainer>
			}
		</div>

  
	);
}