import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { SizedAutocomplete } from '../MaterialStyles/style';
import { apiEls, showMessageErroWithoutStatusCode } from 'services/api';
import { useDispatch } from 'react-redux';
import { additionalUpdate } from 'store/modules/bottomBullet/actions';
import { CircularProgress } from '@material-ui/core';

const ComboBoxInput = ({
	comboOptions,
	label,
	isPaper,
	onChange,
	setPaperInfo,
	index,
	value,
	disabled,
	name,
	onlyOptions,
	inputRef,
}) => {
	const dispatch = useDispatch();

	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	async function setPaperValue(value) {
		if (!value) {
			return;
		}

		const symbol = value?.source?.symbol ?? value;

		if (symbol === undefined) {
			return;
		}

		setIsLoading(true);

		apiEls
			.get(`/search/${symbol}`)
			.then(response => {
				if (
					!response.data._source ||
					(onlyOptions &&
						response.data._source.securityType !== 'OPT')
				) {
					setError(true);
					dispatch(additionalUpdate('disabledSubmit', true));
				} else {
					// workaround para ticksize de dolar e minidolar usar tickSizeDenominator com valor 2.
					if (['DOL', 'WDO'].includes(response.data._source.asset)) {
						response.data._source.tickSizeDenominator = 2;
					}
					if (response.data._source.isContinuous) {
						response.data._source.symbol =
							response.data._source.continuousRef;
					}

					setError(false);
					setPaperInfo(index || 0, response.data._source);
					dispatch(additionalUpdate('disabledSubmit', false));
				}
			})
			.catch(error => {
				setError(true);
				dispatch(additionalUpdate('disabledSubmit', true));
				showMessageErroWithoutStatusCode(error);
			})
			.finally(() => setIsLoading(false));
	}

	return (
		<>
			<SizedAutocomplete
				name={name}
				loadingText="Digite um ativo"
				autoHighlight
				size="small"
				variant="outlined"
				inputValue={value}
				label={label}
				closeIcon={false}
				fullWidth
				options={comboOptions ? comboOptions : []}
				getOptionLabel={comboOption =>
					isPaper ? comboOption?.source?.symbol : comboOption
				}
				renderInput={params => (
					<TextField
						{...params}
						label={label}
						inputRef={inputRef}
						variant="outlined"
						InputLabelProps={{ shrink: true }}
						error={value && error}
						InputProps={{
							...params.InputProps,
							type: 'search',
							endAdornment: (
								<>
									{isLoading && (
										<CircularProgress
											color="primary"
											size={15}
											style={{ marginRight: '30px' }}
										/>
									)}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				)}
				selectOnFocus
				onInput={onChange}
				disabled={disabled}
				loading={isLoading}
				cleanOnEscape
				onChange={(_, newValue) => setPaperValue(newValue)}
				onKeyDown={e => {
					if (e.key === 'Enter') {
						e.target.blur();
					}
				}}
				onBlur={event => setPaperValue(event.target.value)}
				noOptionsText="Ativo não encontrado"
			/>
		</>
	);
};

export default ComboBoxInput;
