import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, CircularProgress, Grid } from '@mui/material';

import { ContractBox, SubTitle } from '../../pages/Login/styles';

import { apiAWS } from '../../services/api';
import { signInAsync } from '../../store/modules/auth/actions';
import { setContractsData } from 'store/modules/products/actions';
import { PRODUCT_TYPES } from 'utils/constants';
import { toast } from 'react-toastify';

const Contract = ({
	authToken,
	userName,
	history,
	from,
	accountType,
	handleCancelContractSign,
	product,
}) => {
	const dispatch = useDispatch();

	const [acceptSent, setAcceptSent] = useState(false);
	const [contract, setContract] = useState(null);

	const tempHeaders = { headers: { Authorization: `Bearer ${authToken}` } };
	const newUserName = userName.toLowerCase();

	function getContractToSign(contracts) {
		if (product) {
			const productContract = contracts.find(
				contract => contract.product === product.toUpperCase()
			);

			if (productContract) {
				return productContract;
			}

			return null;
		}

		const possibleProducts = Object.values(PRODUCT_TYPES);

		return (
			contracts.find(contract =>
				possibleProducts.includes(contract.product)
			) || contracts[0]
		);
	}

	const checkContractsPending = () => {
		apiAWS
			.get(`/users/${newUserName}/contracts`, tempHeaders)
			.then(response => {
				dispatch(setContractsData(response.data.records));
				const contractToSign = getContractToSign(response.data.records);

				console.log({ contractToSign });

				if (!contractToSign) {
					toast.error(
						'O usuário informado não tem permissão para o produto especificado.'
					);

					setTimeout(() => history.push('/login'), 1500);
					handleCancelContractSign();
				}

				if (contractToSign?.have_pendencies) {
					setContract(contractToSign);
					return;
				}

				dispatch(
					signInAsync({
						authToken,
						userName,
						history,
						from,
						accountType,
						handleCancelContractSign,
						userDate: contractToSign.datetime,
						userIp: contractToSign.user_ip,
						productPath: product,
					})
				);
			})
			.catch(() => {
				handleCancelContractSign();
			});
	};

	useEffect(() => {
		if (!contract) {
			checkContractsPending(authToken, userName);
		}
	}, [contract]);

	const handleContractAccept = () => {
		apiAWS
			.post(`/users/${newUserName}/contracts`, contract, tempHeaders)
			.then(response => {
				//Em caso de sucesso, fazer login
				dispatch(
					signInAsync({
						authToken,
						userName,
						history,
						from,
						accountType,
						handleCancelContractSign,
						userDate: response.data.datetime,
						userIp: response.data.user_ip,
						productPath: product,
					})
				);
			})
			.catch(() => {
				handleCancelContractSign();
			});
	};

	const acceptContractInterceptor = () => {
		// desabilita botão de aceite de contrato
		setAcceptSent(true);
		handleContractAccept();
	};

	const showProgress = () => {
		return (
			<>
				<Grid style={{ textAlign: 'center' }}>
					<CircularProgress />
				</Grid>
			</>
		);
	};

	return (
		<>
			{!contract && showProgress()}
			{contract && (
				<>
					<SubTitle>
						{`Para utilizar o sistema você precisa aceitar os termos de uso, atualizados em ${new Date(
							contract.create_datetime ?? new Date()
						).toLocaleDateString('pt-BR')}`}
					</SubTitle>
					<ContractBox color="text.primary">
						<iframe src={contract.file} width="570" height="230" />
					</ContractBox>
					<ContractBox
						color="primary.main"
						mt={2}
						pl={1.5}
						mb={2}
						textAlign="right"
					></ContractBox>
					<Grid container spacing={2}>
						<Grid item xs={5}>
							<Button
								fullWidth
								variant="contained"
								color="secondary"
								onClick={handleCancelContractSign}
								disabled={acceptSent}
							>
								Não aceito os termos
							</Button>
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={5}>
							<Button
								variant="contained"
								color="primary"
								disabled={acceptSent}
								fullWidth
								onClick={acceptContractInterceptor}
							>
								Aceito os termos
							</Button>
						</Grid>
					</Grid>
					{acceptSent && showProgress()}
				</>
			)}
		</>
	);
};

export default Contract;
