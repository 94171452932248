import { calcGreeks } from '@investflex/iflexquantjs';

function getGreeks(underPrice, position, interestRate) {
	const calculatedGreeks = calcGreeks(
		underPrice,
		position.strikePx,
		position.correctVolatility,
		position.daysToExpiration,
		interestRate,
		position.optionType
	);

	function getFormatedGreek(greekType, isUnitary = false) {
		if (position.exitPxLocked) {
			return 0;
		} else if (
			!calculatedGreeks[greekType] ||
			isNaN(calculatedGreeks[greekType])
		) {
			return null;
		}

		if (isUnitary) {
			return calculatedGreeks[greekType];
		}

		return calculatedGreeks[greekType] * position.qtty;
	}

	return {
		delta:
			position.securityType === 'OPT'
				? getFormatedGreek('delta')
				: position.qtty,
		gamma: getFormatedGreek('gamma'),
		theta: getFormatedGreek('theta'),
		vega: getFormatedGreek('vega'),
		deltaUnitary: getFormatedGreek('delta', true),
		gammaUnitary: getFormatedGreek('gamma', true),
		thetaUnitary: getFormatedGreek('theta', true),
		vegaUnitary: getFormatedGreek('vega', true),
	};
}

export function getGreeksObjectName(selectedGreekType) {
	const greeksObjectName = {
		exit: 'exitGreeks',
		entry: 'entryGreeks',
		theoretical: 'theoreticalGreeks',
	};

	return greeksObjectName[selectedGreekType];
}

export function calcStrategyGreeks(
	strategy,
	interestRate,
	selectedGreekType,
	isOption = false
) {
	let formatedStrategy = strategy;

	const greeksName = getGreeksObjectName(selectedGreekType);

	const positionsCalculated = formatedStrategy.positions.map(position => {
		const greeks = getGreeks(strategy.underPrice, position, interestRate);

		if (isOption) {
			const positionToSubscribe =
				formatedStrategy.mainStrategy.positions.find(
					mainPosition => mainPosition.id === position.id
				);

			positionToSubscribe[greeksName] = {
				[greeksName]: {
					...greeks,
				},
			};
		}

		return {
			...position,
			[greeksName]: {
				...greeks,
			},
		};
	});

	formatedStrategy.positions = positionsCalculated;

	const greekTypes = ['delta', 'gamma', 'theta', 'vega'];

	// When is a partial update the object has a different structure.
	const strategyToGoThrough = isOption
		? formatedStrategy.mainStrategy
		: formatedStrategy;

	greekTypes.forEach(greek => {
		if (
			strategyToGoThrough.positions.some(
				pos => pos[greeksName][greek] === null
			)
		) {
			formatedStrategy[greek] = null;
		} else {
			formatedStrategy[greek] = strategyToGoThrough.positions.reduce(
				(acc, position) => acc + position[greeksName][greek],
				0
			);
		}
	});

	return formatedStrategy;
}
