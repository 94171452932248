import { CurrentStrategiesActionTypes } from './actionTypes';

export const setCurrentStrategiesRows = rows => ({
	type: CurrentStrategiesActionTypes.SET_ROWS,
	payload: { rows },
});

export const addStrategy = strategy => ({
	type: CurrentStrategiesActionTypes.ADD_STRATEGY,
	payload: { strategy },
});

export const resetCurrentStrategiesState = () => ({
	type: CurrentStrategiesActionTypes.RESET_STATE,
});

export const updateCurrentStrategiesUserPreferences = newPreferences => ({
	type: CurrentStrategiesActionTypes.UPDATE_USER_PREFERENCES,
	payload: { newPreferences },
});

export const updateCurrentStrategiesColumnsSize = (field, width) => ({
	type: CurrentStrategiesActionTypes.UPDATE_COLUMNS_SIZE,
	payload: { field, width },
});

export const resetCurrentStrategiesUserPreferences = username => ({
	type: CurrentStrategiesActionTypes.RESET_USER_PREFERENCES,
	payload: { username },
});

export const updateCurrentStrategiesColumnsPreferences =
	newColumnsPreferences => ({
		type: CurrentStrategiesActionTypes.UPDATE_COLUMNS_USER_PREFERENCES,
		payload: { newColumnsPreferences },
	});
