import { ActionTypes } from './actionTypes';

export const setRows = rows => ({
	type: ActionTypes.SET_ROWS,
	payload: { rows },
});

export const setSearchFormValues = (field, value) => ({
	type: ActionTypes.SET_SEARCH_FORM_VALUES,
	payload: { field, value },
});
