import React from 'react';
import { Container, GeneralButton } from './styles';

// Componente que possui os dois botões padrão dos modais. Ex: Fechar e Enviar.
export const Buttons = ({
	variant = 'contained',
	closeColor = 'danger',
	closeClick,
	submitColor = 'success',
	disabled,
	submitClick,
	children
}) => {
	return (
		<Container>
			<GeneralButton
				variant={variant}
				buttonColor={closeColor}
				onClick={closeClick}
			>
                Fechar
			</GeneralButton>
			<GeneralButton
				variant={variant}
				buttonColor={submitColor}
				disabled={disabled}
				onClick={submitClick}
			>
				{children}
			</GeneralButton>
		</Container>
	);
};
