import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Divider, Stack } from '@mui/material';

const COLOR_POSITIVE = '#31c593';
const COLOR_NEGATIVE = '#ff7777';

export const CurrentPositionsFooter = () => {
	const consolidation = useSelector(state => state.positions.consolidation);
	const orderedFields = useSelector(
		state => state.positions.tablePreferences.columns.orderedFields
	);

	const emptyFields = ['account', 'symbol'];

	function getLabel(column) {
		return consolidation[column] || '';
	}

	function getLabelColor(label, column) {
		if (label && column) {
			const coloredFields = ['marcacao_mercado', 'financeiro_liquido'];

			if (coloredFields.includes(column)) {
				const numericLabel = parseFloat(label.replace(',', '.'));

				if (!isNaN(numericLabel)) {
					return numericLabel > 0
						? COLOR_POSITIVE
						: numericLabel < 0
							? COLOR_NEGATIVE
							: '';
				}
			}
		}

		return '';
	}

	return (
		<>
			<Divider textAlign="left">Total</Divider>

			<Stack direction="row" spacing={1}>
				{orderedFields.map(column => {
					const label = getLabel(column);
					const labelColor = getLabelColor(label, column);

					return (
						<Box
							key={column}
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								flex: 1,
								height: 40,
								pr: 1.25,
								color: labelColor,
							}}
						>
							{emptyFields.includes(column) ? '' : label}
						</Box>
					);
				})}
			</Stack>
		</>
	);
};
