import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, colors, Grid, Typography } from '@mui/material';

import { NumericInput } from 'components/Bullet/NumericInput';

import paperValidator from 'utils/paperValidation';
import { updateCustomParameters } from 'store/modules/bottomBullet/actions';
import { DescriptionTooltip, LightHTMLTooltip } from 'components/HelperTooltip';
import { Info } from '@mui/icons-material';

export const DepletionGradientProfitPreservation = ({ bullet }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { content, StrategyInfos } = bullet;

	const {
		UsePreservation,
		PreservationPercentual,
		PreservationStartTrigger,
	} = content.CustomParameters;

	const isBulletDisabled = paperValidator(StrategyInfos[0], bullet);

	function handleChangeNumericCustomParameter(
		fieldName,
		value,
		minValue = null,
		maxValue = null
	) {
		if (minValue && value < minValue) {
			return;
		}

		if (maxValue && value > maxValue) {
			return;
		}

		dispatch(updateCustomParameters({ [fieldName]: value }));
	}

	function handleToggleUserPreservation() {
		dispatch(
			updateCustomParameters({
				UsePreservation: UsePreservation === 'Y' ? 'N' : 'Y',
				PreservationStartTrigger: null,
				PreservationPercentual: null,
			})
		);
	}

	return (
		<Grid
			item
			container
			sx={{
				bgcolor: colors.grey[900],
				borderRadius: 1,
				p: 1,
			}}
		>
			<Box
				sx={{
					display: 'flex',
					gap: 1,
				}}
			>
				<Typography variant="subtitle1">
					Preservação de lucro
				</Typography>

				<LightHTMLTooltip
					element={<Info fontSize="small" sx={{ mt: 0.25 }} />}
				>
					<DescriptionTooltip
						label="Gatilho financeiro"
						description={t('bullets.gradient.finantial_trigger')}
					/>

					<DescriptionTooltip
						label="% stop"
						description={t('bullets.gradient.percent')}
					/>
				</LightHTMLTooltip>
			</Box>

			<Grid item container spacing={1}>
				<Grid item xs={2}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							gap: 1,
							flex: 1,
						}}
					>
						<Typography
							color={
								isBulletDisabled
									? colors.grey[500]
									: colors.grey[50]
							}
							variant="subtitle1"
						>
							Ativo
						</Typography>

						<Checkbox
							sx={{ p: 0 }}
							checked={UsePreservation === 'Y'}
							disabled={isBulletDisabled}
							onChange={handleToggleUserPreservation}
						/>
					</Box>
				</Grid>

				<Grid item xs={5}>
					<NumericInput
						decimalScale={2}
						fixedDecimalScale
						disabled={UsePreservation === 'N' || isBulletDisabled}
						label="Gatilho financeiro"
						value={PreservationStartTrigger ?? ''}
						stepSize={0.01}
						minValue={0}
						onChangeCallback={value =>
							handleChangeNumericCustomParameter(
								'PreservationStartTrigger',
								value,
								0
							)
						}
					/>
				</Grid>

				<Grid item xs={5}>
					<NumericInput
						disabled={UsePreservation === 'N' || isBulletDisabled}
						label="% stop"
						value={PreservationPercentual ?? ''}
						stepSize={1}
						minValue={1}
						maxValue={100}
						onChangeCallback={value =>
							handleChangeNumericCustomParameter(
								'PreservationPercentual',
								value,
								1,
								100
							)
						}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};
