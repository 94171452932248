export const SET_INITIAL_STATE = 'simulator/SET_INITIAL_STATE';
export const STRATEGY_ADD = 'simulator/STRATEGY::ADD';
export const STRATEGY_REMOVE = 'simulator/STRATEGY::REMOVE';
export const VISIBILITY_SET = 'simulator/VISIBILITY::SET';
export const STRATEGY_NAME = 'simulator/STRATEGY_NAME::SET';
export const STRATEGY_UPDATE = 'simulator/STRATEGY_UPDATE::SET';
export const POSITION_UPDATE = 'simulator/POSITION_UPDATE::SET';
export const POSITION_DELETE = 'simulator/POSITION_DELETE::SET';
export const SET_PAPER = 'simulator/SET_PAPER::SET';
export const SET_SAVED_STRATEGY = 'simulator/SET_SAVED_STRATEGY::SET';
export const SET_OPTIONS = 'simulator/SET_OPTIONS::SET';
export const SET_INTEREST_RATE = 'simulator/SET_INTEREST_RATE::SET';
export const GET_STRATEGIES = 'simulator/GET_STRATEGIES';
export const SAVE_STRATEGY = 'simulator/SAVE_STRATEGY';
export const POSITION_FIELD_UPDATE = 'simulator/POSITION_FIELD_UPDATE';
export const POSITION_UPDATE_PX = 'simulator/POSITION_UPDATE_PX';
export const SET_TIMER_UPDATE = 'simulator/SET_TIMER_UPDATE::SET';
export const RESET_STRATEGY = 'simulator/RESET_STRATEGY::SET';
export const SUBSCRIBE_POSITIONS = 'simulator/SUBSCRIBE_POSITIONS::SET';
export const REMOVE_FROM_STRATEGY_LIST = 'simulator/REMOVE_FROM_STRATEGY_LIST';

const NEW_STRATEGY_UPDATE = 'simulator/NEW_STATEGY::UPDATE';

export default {
	NEW_STRATEGY_UPDATE,
};
