import React from 'react';

import { Modal, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import {
	Container,
	ModalBody,
	ModalHeader,
	CloseBox,
	ModalContract,
	ModalFooter
} from './styles';

import Button from '../Button';
import themes from '../../../themes';

const Contract = ({ open, onClose, handleAccept, contractInfo }) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
		>
			<Container>
				<ModalBody>
					<ModalHeader>
						<Typography variant="h3">Termos de uso do Flexscan</Typography>
						<CloseBox onClick={onClose}>
							<Close fontSize="large" />
						</CloseBox>
					</ModalHeader>
					<ModalContract>
						<iframe src={contractInfo.file} />
					</ModalContract>
					<ModalFooter>
						<Button
							variant="contained"
							type="submit"
							backgroundcolor={themes.orama.colors.green}
							width="200px"
							onClick={handleAccept}
						>
                            Li e Concordo
						</Button>
					</ModalFooter>
				</ModalBody>
			</Container>
		</Modal>
	);
};

export default Contract;
