import React from 'react';
import moment from 'moment';

import { getHour } from '../../getHour';
import { roundNumber } from '../../../../utils/numberFormat';

import { mediaRowsChartData } from '../../utils';

import {
	ContainerBody,
	HeaderContainer,
	HeaderMain,
	ItemHeader,
	ImageContainer,
	TitleTable,
	ContainerMain,
	TableStrategy,
	TableAnalisy,
	TitleTdAnalisy,
	TextdAnalisy,
	ContainerTables,
	ItemConteiner,
	TitlePay,
	TablePay,
	TitlethPay,
	TexttdPay,
	ItemsDivs,
	ContainerGrapich,
	ContainerDescribe,
	ContainerFooter,
	ImageDisclaimer,
	SignatureText,
	LinkDisclaimer,
	TextSignature,
	PowerBy,
	ImagePowerBy,
} from '../../style';

import powerImg from '../../../../assets/images/powered_by_Flexscan.png';
import marcaDagua from '../../../../assets/images/chart_water_mark.png';
import investLogo from '../../../../assets/images/flexscan_logo.png';

const InvestTemplate = ({ dataPdf }) => {
	return (
		<ContainerBody id="container">
			<HeaderContainer>
				<HeaderMain>
					<ItemHeader height={'100%'} width={'20%'} align>
						<ImageContainer src={investLogo} />
					</ItemHeader>
					<ItemHeader height={'70%'} width={'60%'} align>
						<h1>
							{dataPdf.strategy.strategyName == ''
								? 'Análise de Operação Estruturada'
								: dataPdf.strategy.strategyName}
						</h1>
					</ItemHeader>
					<ItemHeader height={'100%'} width={'20%'} align>
						<p>
							{getHour().data} {getHour().horas}
						</p>
					</ItemHeader>
				</HeaderMain>
			</HeaderContainer>
			<ContainerMain>
				<TitleTable>
					<p>COMPOSIÇÃO DA ESTRATÉGIA</p>
				</TitleTable>
				<TableStrategy style={{ borderCollapse: 'collapse' }}>
					<tr>
						<th>Operação</th>
						<th>Quantidade</th>
						<th>Tipo</th>
						<th>Preço</th>
						<th>Ativo</th>
						<th>Data Expiração</th>
						<th>Strike</th>
						<th>Custo</th>
					</tr>
					{dataPdf.strategy.positions.map(position => (
						<tr key={position.symbol}>
							<td>{position.qtty > 0 ? 'Compra' : 'Venda'}</td>
							<td id="alignDescribe">
								{position.qtty.toString().indexOf('-') == 0
									? parseInt(
										position.qtty
											.toString()
											.replace('-', '')
									  )
									: parseInt(position.qtty.toString())}
							</td>
							<td>
								{position.optionType !== undefined
									? position.optionType === 'C'
										? 'Call'
										: 'Put'
									: 'Ativo'}
							</td>
							<td id="alignDescribe">
								{Intl.NumberFormat('pt-BR', {
									style: 'currency',
									currency: 'BRL',
								}).format(position.entryPx)}
							</td>
							<td>{position.symbol}</td>
							<td>
								{position.expirationDate != undefined
									? moment(
										position.expirationDate,
										'YYYYMMDD'
									  ).format('DD/MM/YYYY')
									: '-'}
							</td>
							<td id="alignDescribe">
								{position.strikePx !== undefined
									? Intl.NumberFormat('pt-BR', {
										style: 'currency',
										currency: 'BRL',
									  }).format(position.strikePx)
									: '-'}{' '}
							</td>
							<td id="alignDescribe">
								{Intl.NumberFormat('pt-BR', {
									style: 'currency',
									currency: 'BRL',
								}).format(position.entryCost)}
							</td>
						</tr>
					))}
				</TableStrategy>
				<TitleTable>
					<p>ANÁLISE DA ESTRATÉGIA</p>
				</TitleTable>
				<TableAnalisy>
					<tr>
						<TitleTdAnalisy>Ativo Objeto</TitleTdAnalisy>
						<TextdAnalisy>
							{dataPdf.strategy.underSymbol}
						</TextdAnalisy>
						<TitleTdAnalisy>Lucro Máximo</TitleTdAnalisy>
						<TextdAnalisy>
							{dataPdf.strategy.maxProfit != 'unlimited'
								? Intl.NumberFormat('pt-BR', {
									style: 'currency',
									currency: 'BRL',
								  }).format(dataPdf.strategy.maxProfit)
								: 'Ilimitado'}
						</TextdAnalisy>
					</tr>
					<tr>
						<TitleTdAnalisy>Data Alvo</TitleTdAnalisy>
						<TextdAnalisy>
							{moment(
								dataPdf.strategy.expirationDate,
								'YYYYMMDD'
							).format('DD/MM/YYYY')}{' '}
							({dataPdf.strategy.dte} d.u)
						</TextdAnalisy>
						<TitleTdAnalisy>Lucro Máximo Percentual</TitleTdAnalisy>
						<TextdAnalisy>
							{dataPdf.strategy.maxProfitPerc === undefined
								? '-'
								: dataPdf.strategy.maxProfitPerc !== 'unlimited'
									? roundNumber(
										dataPdf.strategy.maxProfitPerc,
										2
								  ) + '%'
									: 'Ilimitado'}
						</TextdAnalisy>
					</tr>
					<tr>
						<TitleTdAnalisy>Custo</TitleTdAnalisy>
						<TextdAnalisy>
							{Intl.NumberFormat('pt-BR', {
								style: 'currency',
								currency: 'BRL',
							}).format(dataPdf.strategy.entryCost)}
						</TextdAnalisy>
						<TitleTdAnalisy>% Sobre CDI</TitleTdAnalisy>
						<TextdAnalisy>
							{dataPdf.strategy.cdiPerc !== null &&
							dataPdf.strategy.cdiPerc !== undefined
								? dataPdf.strategy.cdiPerc === 'unlimited'
									? 'Ilimitado'
									: roundNumber(dataPdf.strategy.cdiPerc, 2) +
									  '%'
								: '-'}
						</TextdAnalisy>
					</tr>
					<tr>
						<TitleTdAnalisy>Margem</TitleTdAnalisy>
						<TextdAnalisy>
							{dataPdf.strategy.margin != null
								? Intl.NumberFormat('pt-BR', {
									style: 'currency',
									currency: 'BRL',
								  }).format(dataPdf.strategy.margin)
								: 'N/D'}
						</TextdAnalisy>
						<TextdAnalisy></TextdAnalisy>
						<TextdAnalisy></TextdAnalisy>
					</tr>
				</TableAnalisy>
				<ContainerTables marginBottom={'10px'}>
					<ItemConteiner width={'45%'}>
						<TitlePay>
							<p>PAY-OFF</p>
						</TitlePay>
						<TablePay style={{ borderCollapse: 'collapse' }}>
							<tr>
								<TitlethPay>Preço do Ativo</TitlethPay>
								<TitlethPay>Resultado Financeiro</TitlethPay>
								<TitlethPay>Resultado %</TitlethPay>
							</tr>
							{mediaRowsChartData(
								dataPdf.strategy.chartData,
								dataPdf
							).map(pay => (
								<tr key={pay.px}>
									<TexttdPay>
										{Intl.NumberFormat('pt-BR', {
											style: 'currency',
											currency: 'BRL',
										}).format(pay.underPx)}
									</TexttdPay>
									<TexttdPay>
										{Intl.NumberFormat('pt-BR', {
											style: 'currency',
											currency: 'BRL',
										}).format(pay.px)}
									</TexttdPay>
									<TexttdPay>
										{isNaN(pay.var) === true
											? '-'
											: pay.var != null
												? roundNumber(pay.var, 2) + '%'
												: '-'}
									</TexttdPay>
								</tr>
							))}
						</TablePay>
					</ItemConteiner>
					<ItemConteiner width={'45%'}>
						<ItemsDivs height={'50%'} margin>
							<TitlePay>
								<p>Gráfico no Vencimento</p>
							</TitlePay>
							<ContainerGrapich>
								<div id="chart"></div>
								<div id="watermarkImg">
									<img src={marcaDagua} />
								</div>
							</ContainerGrapich>
						</ItemsDivs>
						<ItemsDivs height={'35%'}>
							<TitlePay>
								<p>Comentário de Estrutura</p>
							</TitlePay>
							<ContainerDescribe>
								{dataPdf.strategy.comments}
							</ContainerDescribe>
						</ItemsDivs>
					</ItemConteiner>
				</ContainerTables>
				<ContainerFooter>
					<ImageDisclaimer />
					<SignatureText color={'red'} size={'7px'}></SignatureText>
					<LinkDisclaimer></LinkDisclaimer>
					<SignatureText color={'#485460'} size={'6px'} bold>
						DISCLAIMER
					</SignatureText>
					<TextSignature size={'8px'}>
						{'"'}Apesar de todo o cuidado na montagem deste
						relatório, a empresa não se responsabiliza pela
						correção, qualidade e precisão de seus modelos ou de
						suas informações. Como qualquer método quantitativo, os
						modelos utilizados neste relatório possuem limitações e
						baseiam-se em hipóteses que restringem o seu alcance. Os
						resultados obtidos com o uso deste relatório são
						condicionados ao preenchimento de certos dados
						referentes a hipóteses de trabalho que não são de
						responsabilidade da empresa ou ainda de pessoas físicas
						a ela vinculadas. Este relatório e as informações dela
						obtidas não representam recomendações de compra ou de
						venda de qualquer ativo ou contrato de derivativo ou,
						ainda, de posicionamento em qualquer mercado. A empresa
						não se responsabiliza por prejuízos ou quaisquer perdas
						advindas do uso deste relatório ou de suas informações.
						{'"'}
					</TextSignature>
					<PowerBy>
						<ImagePowerBy src={powerImg} />
					</PowerBy>
				</ContainerFooter>
			</ContainerMain>
		</ContainerBody>
	);
};

export default InvestTemplate;
