import { toast } from 'react-toastify';
import cleanCloseAuctionCustom from './singleFunctions/custom/closeAuction';
import cleanFxorderCustom from './singleFunctions/custom/fxorder';
import cleanCustomMarket from './singleFunctions/custom/market';
import cleanMitCustom from './singleFunctions/custom/mit';
import cleanSorderCustom from './singleFunctions/custom/sorder';
import cleanBofferLeg from './singleFunctions/leg/boffer';
import cleanFxorderLeg from './singleFunctions/leg/fxorder';
import cleanSorderMarket from './singleFunctions/leg/market';
import cleanMitLeg from './singleFunctions/leg/mit';
import cleanSniperLeg from './singleFunctions/leg/sniper';
import cleanSorderLeg from './singleFunctions/leg/sorder';
import validatePrice from './validatePrice';

const singleClear = (bullet, setAccessorInfos, account, t) => {
	const hash = { ...bullet.content };

	if (bullet.subUid === 9) {
		hash.StrategyCode = 'mit';
	} else if (bullet.subUid === 7) {
		hash.StrategyCode = 'boffer';
	} else if (bullet.subUid === 8) {
		hash.StrategyCode = 'sniper';
	} else if (
		bullet.subUid === 4 ||
		bullet.subUid === 3 ||
		bullet.subUid === 5
	) {
		hash.StrategyCode = 'fxorder';
	}

	if (hash.StrategyCode === 'sorder' || hash.StrategyCode === 'fxorder') {
		if (
			(hash.StrategyLegs[0].LegQuantity === 0 ||
				hash.StrategyLegs[0].LegQuantity === '0' ||
				isNaN(hash.StrategyLegs[0].LegQuantity) ||
				hash.StrategyLegs[0].LegQuantity === undefined) &&
			(hash.CustomParameters.OrderQuantity === 0 ||
				hash.CustomParameters.OrderQuantity === '0' ||
				isNaN(hash.CustomParameters.OrderQuantity) ||
				hash.CustomParameters.OrderQuantity === undefined)
		) {
			toast.error(t('A quantidade precisa ser maior que 0'));
			return;
		}
		if (
			validatePrice(hash.CustomParameters.PriceLimit) &&
			bullet.subUid !== 2 &&
			!hash.CustomParameters.UseAuctionOpenPrice
		) {
			toast.error(t('O Preço limite precisa ser maior que 0'));
			return;
		}
		if (bullet.subUid === 4) {
			hash.CustomParameters.CustomOrdType = 3;
		} else if (bullet.subUid === 3) {
			hash.CustomParameters.CustomOrdType = 2;
		} else if (bullet.subUid === 5) {
			hash.CustomParameters.CustomOrdType = 5;
		} else if (bullet.subUid === 1) {
			hash.CustomParameters.CustomOrdType = 4;
		} else if (bullet.subUid === 2) {
			hash.CustomParameters.CustomOrdType = 1;
		} else if (bullet.subUid === 6) {
			hash.CustomParameters.CustomOrdType = 5;
			hash.StrategyCode = 'fxorder';
		}
		if (bullet.subUid === 5) {
			hash.CustomParameters.OrderQuantity = hash.StrategyLegs[0]
				.LegQuantity
				? parseInt(hash.StrategyLegs[0].LegQuantity, 10)
				: parseInt(bullet.content.CustomParameters.OrderQuantity, 10);
			hash.CustomParameters = cleanCloseAuctionCustom(hash, bullet);
			hash.StrategyLegs[0] = cleanFxorderLeg(
				hash,
				account,
				bullet
			);
		} else if (hash.CustomParameters.stop || bullet.subUid === 3) {
			hash.CustomParameters.OrderQuantity = bullet.content.
				CustomParameters.OrderQuantity
				? parseInt(bullet.content.CustomParameters.OrderQuantity, 10)
				: parseInt(hash.StrategyLegs[0].LegQuantity, 10);
			if (bullet.subUid !== 3) {
				if (
					(hash.CustomParameters.StopGain === '' ||
						hash.CustomParameters.StopGain === 0 ||
						hash.CustomParameters.StopGain === undefined) &&
					hash.CustomParameters.StopType === 1
				) {
					toast.error(t('O campo Stop Gain não pode estar vazio'));
					return;
				}
				if (
					hash.CustomParameters.StopLoss === 0 ||
					hash.CustomParameters.StopLoss === '0' ||
					hash.CustomParameters.StopLoss === '' ||
					hash.CustomParameters.StopLoss === undefined ||
					hash.CustomParameters.StopLoss === null
				) {
					toast.error(t('Stop Loss precisa ser diferente de 0'));
					return;
				}
			}
			hash.CustomParameters = cleanFxorderCustom(
				hash,
				bullet.subUid,
				bullet.StrategyInfos[0].minPriceIncrement,
				t
			);
			hash.StrategyLegs[0] = cleanFxorderLeg(
				hash,
				account,
				bullet
			);
			hash.StrategyCode = 'fxorder';
		} else if (bullet.subUid === 2) {
			hash.CustomParameters = cleanCustomMarket(bullet);
			hash.StrategyLegs[0] = cleanSorderMarket(
				hash,
				account,
				bullet
			);
		} else if (bullet.subUid === 6) {
			hash.CustomParameters.OrderQuantity = hash.StrategyLegs[0]
				.LegQuantity
				? parseInt(hash.StrategyLegs[0].LegQuantity, 10)
				: parseInt(bullet.content.CustomParameters.OrderQuantity, 10);
			hash.CustomParameters = {
				StopType: 1,
				UseAuctionOpenPrice: hash.CustomParameters.UseAuctionOpenPrice
					? 'Y'
					: 'N',
				OrderQuantity: hash.CustomParameters.OrderQuantity,
				ReversionType: 4,
				CustomOrdType: hash.CustomParameters.CustomOrdType,
				UseLevel3: 'N',
				TriggerEntryType: 1,
				StopLoss: bullet.StrategyInfos[0].minPriceIncrement,
			};
			hash.StrategyLegs[0] = cleanFxorderLeg(
				hash,
				account,
				bullet
			);
		} else {
			hash.CustomParameters = cleanSorderCustom(hash);
			hash.StrategyLegs[0] = cleanSorderLeg(
				hash,
				account,
				bullet
			);
			hash.StrategyCode = 'sorder';
		}
	}
	delete hash.signature;

	if (['mit', 'boffer', 'sniper'].includes(hash.StrategyCode)) {
		if (validatePrice(hash.CustomParameters.PriceLimit)) {
			toast.error(t('O Preço limite precisa ser maior que 0'));
			return;
		}
	}
	if (hash.StrategyCode === 'boffer') {
		hash.CustomParameters = cleanSorderCustom(hash);
		hash.StrategyLegs[0] = cleanBofferLeg(
			hash,
			account,
			bullet
		);
	} else if (hash.StrategyCode === 'sniper') {
		hash.CustomParameters = cleanSorderCustom(hash);
		hash.StrategyLegs[0] = cleanSniperLeg(
			hash,
			account,
			bullet
		);
	} else if (hash.StrategyCode === 'mit') {
		if (
			hash.StrategyLegs[0].LegMinClipSize === 0 ||
			hash.StrategyLegs[0].LegMinClipSize === '' ||
			isNaN(hash.StrategyLegs[0].LegMinClipSize) ||
			hash.StrategyLegs[0].LegMinClipSize === undefined
		) {
			toast.error(t('Preencha o campo de Clipe Mínimo'));
			return;
		}
		if (
			hash.StrategyLegs[0].LegMaxClipSize === 0 ||
			hash.StrategyLegs[0].LegMaxClipSize === '' ||
			isNaN(hash.StrategyLegs[0].LegMaxClipSize) ||
			hash.StrategyLegs[0].LegMaxClipSize === undefined
		) {
			toast.error(t('Preencha o campo de Clipe Máximo'));
			return;
		}
		if (
			hash.CustomParameters.RestingPrice === 0 ||
			hash.CustomParameters.RestingPrice === '' ||
			isNaN(hash.CustomParameters.RestingPrice) ||
			hash.CustomParameters.RestingPrice === undefined
		) {
			toast.error(t('Preencha o campo de Dif. preço apregoar'));
			return;
		}
		if (
			hash.CustomParameters.RestingQuantity === 0 ||
			hash.CustomParameters.RestingQuantity === '' ||
			isNaN(hash.CustomParameters.RestingQuantity) ||
			hash.CustomParameters.RestingQuantity === undefined
		) {
			toast.error(t('Preencha o campo de Qtd. apregoar'));
			return;
		}
		hash.CustomParameters = cleanMitCustom(hash);
		hash.StrategyLegs[0] = cleanMitLeg(
			hash,
			account,
			bullet
		);
	}
	if (!hash.StrategyLegs || !hash.CustomParameters) {
		return;
	}

	if (['mit', 'boffer', 'sniper'].includes(hash.StrategyCode)) {
		hash.CustomParameters.InitSuspended =
			bullet.content.CustomParameters.InitSuspended ?? 'N';
	}

	// Todas as Flexorders que não tenham reversao do tipo 4 (nao reverter)
	// Verifica se os precos de gain e loss nao sao vazios
	if (
		hash.StrategyCode === 'fxorder' &&
		hash.CustomParameters.ReversionType !== 4
	) {
		let fieldsToValidate = ['StopLoss'];
		//stop tipo gain/loss
		if (hash.CustomParameters.StopType === 1) {
			fieldsToValidate.push('StopGain');
		} else if (hash.CustomParameters.StopType === 3) {
			//gain parciais
			fieldsToValidate.push('StopGainL1');
			fieldsToValidate.push('StopGainL2');
			if (hash.CustomParameters.UseLevel3 === 'Y') {
				fieldsToValidate.push('StopGainL3');
			}
		}

		let abort = false;
		fieldsToValidate.forEach(field => {
			if (validatePrice(hash.CustomParameters[field])) {
				toast.error(t(`Campo ${field} precisa ser maior que 0`));
				abort = true;
			}
		});

		if (abort) {
			return;
		}
	}

	delete hash.clean;
	if (hash.StrategyCode !== 'fxorder' && hash.StrategyCode !== 'sorder') {
		hash.CustomParameters.InitSuspended =
			bullet.content.CustomParameters.InitSuspended ?? 'N';
	}
	hash.StrategyLegs = setAccessorInfos(hash.StrategyLegs);

	const cleanedHash = {
		'Name': hash.Name,
		'InitTime': hash.InitTime,
		'EndTime': hash.EndTime,
		'ExpireDate': hash.ExpireDate,
		'Text': hash.Text,
		'BasketId': hash.BasketId,
		'StrategyCode': hash.StrategyCode,
		'CustomParameters': hash.CustomParameters,
		'StrategyLegs': [hash.StrategyLegs[0]],
		'Signature': hash.Signature
	};

	if (process.env.NODE_ENV === 'development')
		console.log('Single submit', cleanedHash);

	return cleanedHash;
};

export default singleClear;
