import React from 'react';
import { useSelector } from 'react-redux';

import { Avatar } from '@mui/material';

import { colorsBySponsor } from 'utils/colorsBySponsor';

export const UserAvatar = ({ sx = {}, ...props }) => {
	const { name, sponsor } = useSelector(state => state.auth);

	return (
		<Avatar
			sx={{
				fontSize: '1.5rem',
				color: colorsBySponsor.font[sponsor],
				background: colorsBySponsor.avatar[sponsor],
				...sx,
			}}
			{...props}
		>
			{String(name).charAt(0).toUpperCase()}
		</Avatar>
	);
};
