const haveNull = obj => {
	return Object.keys(obj).find(key => {
		return obj[key] === null || obj[key] === undefined || (isNaN(obj[key]) && typeof obj[key] !== 'string');
	});
};

export const generalValidation = bullet => {
	const validateCustom = haveNull(bullet.CustomParameters);
	if (bullet.StrategyCode !== 'portbalance' && bullet?.StrategyLegs?.length > 0) {
		const validateLegs = [];
		bullet.StrategyLegs.forEach(el => {
			validateLegs.push(haveNull(el));
		});
		return validateCustom || validateLegs.filter(e => e !== undefined).length > 0;
	}

	return validateCustom;
};
