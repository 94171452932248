import React from 'react';
import { useSelector } from 'react-redux';

import { AppBar, Box, Typography } from '@mui/material';

import { HeaderToolbar, HeaderInfo, AccountBox } from './styles';

import { HeaderTools } from './HeaderTools';

import { ProductSelector } from './ProductSelector';

export const Header = ({ handleOpenModal, openedModal }) => {
	const { username, accountType } = useSelector(state => state.auth);

	const environmentLabel =
		accountType === 'demo' ? 'CONTA SIMULADA' : 'CONTA REAL';

	return (
		<AppBar position="fixed">
			<HeaderToolbar>
				<HeaderInfo>
					<ProductSelector />

					<AccountBox accountType={accountType}>
						<Typography variant="h6">{environmentLabel}</Typography>
					</AccountBox>
				</HeaderInfo>

				<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
					<Typography variant="subtitle1">
						<strong>{username}</strong>
					</Typography>

					<HeaderTools
						openedModal={openedModal}
						handleOpenModal={handleOpenModal}
					/>
				</Box>
			</HeaderToolbar>
		</AppBar>
	);
};
