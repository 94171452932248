import React, { useState } from 'react';
import { Box, Grid, IconButton, Tooltip } from '@material-ui/core';
import { Book, SwapVert } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import ComboBoxInput from '../ComboBoxInput';
import {
	strategyUpdate,
	additionalUpdate,
} from 'store/modules/bottomBullet/actions';
import MaterialCheckBox from '../MaterialCheckBox';
import { ButtonBuySell } from '../MaterialStyles/style';
import DecimalInput from '../DecimalInput';
import { getFinatialValue } from '../../../utils/strategies';
import { useTranslation } from 'react-i18next';
import addWidgetFromButton from 'utils/addWidgetFromButton';
import { isDisableBySymbol } from '../../../utils/isDisableBySymbol';
import { symbolOpenBook } from 'utils/symbolOpenBook';

const Financiamento = ({
	onSuggestionsFetchRequested,
	setPaperInfo,
	index,
	onlyOptions,
	callbackMarketPrice,
	stepFunction,
	execType,
}) => {
	const { bullet } = useSelector(state => state.bottomBullet);
	const dispatch = useDispatch();
	const [legResting, setLegResting] = useState(false);
	const [updateMarketPrice, setUpdateMarketPrice] = useState(false);
	const lockBook = useSelector(state => state.configs.widgetIsLoading);
	const { t } = useTranslation();

	if (bullet.editing || bullet.clone) {
		if (!updateMarketPrice) {
			setUpdateMarketPrice(true);
			callbackMarketPrice(
				bullet.content.StrategyLegs,
				bullet.content.CustomParameters.Trigger
			);
		}

		const tempLegResting =
			(bullet.content.StrategyLegs[index].LegResting ?? 'N') === 'Y';
		if (legResting !== tempLegResting) {
			setLegResting(tempLegResting);
		}
	}

	const handleChange = (type, subType, value, indexLeg) => {
		const hash = { ...bullet };
		const ativoIndex = indexLeg + 1;
		if (type === 'Leg') {
			hash.content.StrategyLegs[indexLeg][subType] = value;
		} else if (type === 'Custom') {
			hash.content.CustomParameters[subType] = value;
		}

		if (subType === 'LegSymbol') {
			callbackMarketPrice(
				hash.content.StrategyLegs,
				hash.content.CustomParameters.Trigger
			);
		}

		if (subType === 'LegQuantity') {
			hash.StrategyInfos[indexLeg].finantial = getFinatialValue(
				hash.StrategyInfos[indexLeg].lastPx,
				hash.content.StrategyLegs[indexLeg].LegQuantity,
				hash.StrategyInfos[indexLeg].asset
			);

			if (hash.content.StrategyLegs[ativoIndex]) {
				hash.content.StrategyLegs[ativoIndex][subType] = value;

				hash.StrategyInfos[ativoIndex].finantial = getFinatialValue(
					hash.StrategyInfos[ativoIndex].lastPx,
					hash.content.StrategyLegs[ativoIndex].LegQuantity,
					hash.StrategyInfos[ativoIndex].asset
				);
			}
		}

		dispatch(additionalUpdate('StrategyInfos', hash.StrategyInfos));
		dispatch(strategyUpdate(hash.content));
	};

	const setPaperInfoInterceptor = (tempIndex, data) => {
		if (data.optionType === 'CALL') {
			setPaperInfo(tempIndex, data);
			setPaperInfo(tempIndex + 1, {
				...data,
				symbol: data.underlyingSymbol,
				underlyingSymbol: '',
			});
			callbackMarketPrice(
				bullet.content.StrategyLegs,
				bullet.content.CustomParameters.Trigger
			);
		}
	};

	const getSymbolLabel = legIndex => {
		return legIndex === 0 ? 'Opção' : 'Ativo';
	};

	const isFieldDisableBySymbol = () => {
		return isDisableBySymbol(bullet, index);
	};

	const handleOnChangeLegResting = () => {
		handleChange('Leg', 'LegResting', !legResting ? 'Y' : 'N', index);
		setLegResting(!legResting);
	};

	return (
		<Grid container item xs={12} spacing={1}>
			<Grid item xs={execType === 'B' ? 4 : 3}>
				<Box display="flex">
					<Box pb={0.5}>
						<Tooltip title="Abrir book">
							<IconButton
								size="small"
								onClick={() =>
									addWidgetFromButton(
										bullet.content.StrategyLegs[index]
											.LegSymbol
									)
								}
								disabled={
									symbolOpenBook(
										bullet.StrategyInfos[0],
										bullet
									) || lockBook
								}
							>
								<Book />
							</IconButton>
						</Tooltip>
					</Box>
					<ComboBoxInput
						name={'paper' + index}
						label={getSymbolLabel(index)}
						onlyOptions={index === 0}
						tooltip={
							index === 0
								? t('bullets.general.paper_code')
								: t('bullets.general.paper_name')
						}
						setPaperInfo={setPaperInfoInterceptor}
						comboOptions={
							bullet.suggestions && bullet.suggestions.source
								? {
									value: bullet.suggestions.source.id,
									title: bullet.suggestions.source.symbol,
								  }
								: bullet.suggestions
						}
						onChange={e =>
							onSuggestionsFetchRequested(
								e.target.value,
								onlyOptions,
								true,
								index
							)
						}
						index={index}
						value={bullet.content.StrategyLegs[index].LegSymbol}
						disabled={index === 1 || bullet.content.ClOrdID}
						isPaper
					/>
				</Box>
			</Grid>
			<Grid item xs={execType === 'B' ? 4 : 2}>
				<ButtonBuySell
					name={'btn-side' + index}
					variant="contained"
					fullWidth
					size="small"
					color={index === 1 ? 'primary' : 'secondary'}
					startIcon={<SwapVert />}
					disabled={
						isFieldDisableBySymbol() || bullet.content.ClOrdID
					}
				>
					{index === 1 ? 'Compra' : 'Venda'}
				</ButtonBuySell>
			</Grid>
			<Grid item xs={execType === 'B' ? 4 : 3}>
				<DecimalInput
					name={'quantity' + index}
					disabled={
						isFieldDisableBySymbol() ||
						index === 1 ||
						bullet.content.ClOrdID
					}
					label="Quantidade"
					tooltip={t('bullets.general.quantity')}
					minVal={bullet.StrategyInfos[index].minOrderQty}
					maxVal={bullet.StrategyInfos[index].maxOrderQty}
					minDecScale={0} // casas decimais
					stepFunction={stepFunction}
					stepType="Leg" // type
					stepSubType="LegQuantity" // subtype
					stepSize={bullet.StrategyInfos[index].roundLot} // incremento
					valueToStep={bullet.content.StrategyLegs[index].LegQuantity} // valor a ser modificado
					value={bullet.content.StrategyLegs[index].LegQuantity}
					legIndex={index}
					onChange={e =>
						handleChange(
							'Leg',
							'LegQuantity',
							parseInt(e.value),
							index
						)
					}
				/>
			</Grid>
			{execType !== 'B' && (
				<>
					<Grid item xs={2}>
						<DecimalInput
							name={'clip' + index}
							disabled={
								isFieldDisableBySymbol() ||
								bullet.content.ClOrdID
							}
							label="Clip máximo"
							iconTooltip={t('bullets.general.clip')}
							minVal={bullet.StrategyInfos[index].minOrderQty}
							maxVal={bullet.StrategyInfos[index].maxOrderQty}
							minDecScale={0} // casas decimais
							stepFunction={stepFunction}
							stepType="Leg" // type
							stepSubType="LegMaxClipSize" // subtype
							stepSize={bullet.StrategyInfos[index].roundLot} // incremento
							valueToStep={
								bullet.content.StrategyLegs[index]
									.LegMaxClipSize
							} // valor a ser modificado
							value={
								bullet.content.StrategyLegs[index]
									.LegMaxClipSize
							}
							legIndex={index}
							onChange={e =>
								handleChange(
									'Leg',
									'LegMaxClipSize',
									parseInt(e.value),
									index
								)
							}
						/>
					</Grid>
					<Grid item xs={2} container alignItems="center">
						<Box mt={-1}>
							<MaterialCheckBox
								testId={'resting' + index}
								name={'resting' + index}
								labelSide="top"
								label="Apregoar"
								disabled={
									isFieldDisableBySymbol() ||
									bullet.content.ClOrdID
								}
								checked={
									bullet.content.StrategyLegs[index]
										.LegResting === 'Y'
								}
								onChange={() => handleOnChangeLegResting()}
							/>
						</Box>
					</Grid>
				</>
			)}
		</Grid>
	);
};

export default Financiamento;
