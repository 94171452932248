const paperValidator = (suggestions, bullet, disableChoice, subSpecify) => {
	// Validações específicas
	if (bullet.content.ClOrdID && bullet.subUid === 2 && disableChoice === 'marketQty') {
		return true;
	}

	if (disableChoice === 'isPrice') {
		if (bullet.content.CustomParameters.UseAuctionOpenPrice === 'Y') {
			return true;
		}
	}

	if (bullet.content.ClOrdID && disableChoice === 'singleSideButtom') {
		return true;
	}

	if (disableChoice === 'spreadBlock' && (!suggestions || Object.keys(suggestions).length === 0 || isNaN(suggestions.finantial))) {
		if (bullet.content.ClOrdID && subSpecify === 'isQtdSpread') {
			return true;
		}
		return true;
	}

	if (bullet.paramsView) {
		return true;
	}

	// Fim
	if (bullet.content.ClOrdID) {
		return false;
	}

	if (bullet.clone) {
		return false;
	}

	if (!suggestions || suggestions.length === 0 || Object.keys(suggestions).length === 0) {
		return true;
	}

	if (suggestions.symbol === undefined) {
		return true;
	}

	return false;
};

export default paperValidator;
