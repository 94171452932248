import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ExpandMore } from '@mui/icons-material';
import {
	ClickAwayListener,
	Fade,
	MenuItem,
	Paper,
	Popper,
	Typography,
	List,
	Box,
	IconButton,
} from '@mui/material';

import { getStrategy } from '../utils';
import { strategyUpdate } from 'store/modules/simulator/actions';

export const HeaderExit = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const strategy = useSelector(getStrategy);
	const activeStrategy = useSelector(state => state.simulator.activeStrategy);

	const [anchorEl, setAnchorEl] = useState(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	function handleOpenMenu(event) {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setIsMenuOpen(!isMenuOpen);
	}

	function handleCloseMenu() {
		setAnchorEl(null);
		setIsMenuOpen(false);
	}

	function setValue(value) {
		handleCloseMenu();

		dispatch(
			strategyUpdate(
				{
					...strategy,
					showTypeExit: value,
				},
				activeStrategy
			)
		);
	}

	const exit = {
		top: t('simulator.table.exitPx.marketPrice'),
		mid: t('simulator.table.exitPx.averagePrice'),
		last: t('simulator.table.exitPx.lastPrice'),
	}[strategy?.showTypeExit];

	const menuActions = [
		{ value: 'top', label: t('simulator.table.exitPx.marketPrice') },
		{ value: 'mid', label: t('simulator.table.exitPx.averagePrice') },
		{ value: 'last', label: t('simulator.table.exitPx.lastPrice') },
	];

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<IconButton size="small" onClick={handleOpenMenu}>
					<ExpandMore />
				</IconButton>

				<Typography variant="body2">{exit}</Typography>
			</Box>

			<Popper
				transition
				placement="bottom"
				open={isMenuOpen}
				anchorEl={anchorEl}
			>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={handleCloseMenu}>
						<Fade {...TransitionProps}>
							<Paper elevation={12} square={false}>
								<List>
									{menuActions.map(item => (
										<MenuItem
											selected={
												item.value ===
												strategy?.showTypeExit
											}
											key={item.value}
											onClick={() => setValue(item.value)}
										>
											{item.label}
										</MenuItem>
									))}
								</List>
							</Paper>
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
		</>
	);
};
