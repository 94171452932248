export function removeDuplicatesItem(array, field) {
	const uniqueCodes = new Set();

	return array.filter(item => {
		if (!uniqueCodes.has(item[field])) {
			uniqueCodes.add(item[field]);

			return true;
		}

		return false;
	});
}
