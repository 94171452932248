import React from 'react';
import moment from 'moment';

import { getHour } from '../../getHour';
import { roundNumber } from '../../../../utils/numberFormat';
import { mediaRowsChartData } from '../../utils';

import {
	ContainerBody,
	TitleTable,
	ContainerMain,
	TableStrategy,
	TableAnalisy,
	TitleTdAnalisy,
	TextdAnalisy,
	ContainerTables,
	ItemConteiner,
	TitlePay,
	TablePay,
	TitlethPay,
	TexttdPay,
	ItemsDivs,
	ContainerGrapich,
	ContainerDescribe,
} from '../../style';

import {
	HeaderContainerOrama,
	ContainerOramaHeader,
	HeaderOrama,
	ItemHeaderOrama,
} from './style';

import marcaDagua from '../../../../assets/images/chart_water_mark.png';

import {
	DefaultDisclaimerHeader,
	DefaultDisclaimerFooter,
} from './components/DefaultDisclaimer/index';

const OramaTemplate = ({ dataPdf, username }) => {
	return (
		<>
			<ContainerBody id="container">
				<HeaderContainerOrama>
					<HeaderOrama>
						<ItemHeaderOrama
							height={'90%'}
							width={'61%'}
							justify={'flex-end'}
						>
							<p>Produtos Estruturados</p>
						</ItemHeaderOrama>
						<ItemHeaderOrama
							height={'90%'}
							width={'33%'}
							align
							justify={'flex-end'}
						>
							<p id="hourDate">
								{getHour().data} {getHour().horas}
							</p>
						</ItemHeaderOrama>
					</HeaderOrama>
					<ContainerOramaHeader height={'28%'} width={'100%'}>
						<p>
							{dataPdf.strategy.strategyName == ''
								? 'Análise de Operação Estruturada'
								: dataPdf.strategy.strategyName}
						</p>
					</ContainerOramaHeader>
				</HeaderContainerOrama>
				<ContainerMain>
					<TitleTable bgColor>
						<p>COMPOSIÇÃO DA ESTRATÉGIA</p>
					</TitleTable>
					<TableStrategy
						style={{ borderCollapse: 'collapse' }}
						colorTitles
					>
						<tr>
							<th>Operação</th>
							<th>Quantidade</th>
							<th>Tipo</th>
							<th>Preço</th>
							<th>Ativo</th>
							<th>Data Expiração</th>
							<th>Strike</th>
							<th>Custo</th>
						</tr>
						{dataPdf.strategy.positions.map(position => (
							<tr key={position.symbol}>
								<td>
									{position.qtty > 0 ? 'Compra' : 'Venda'}
								</td>
								<td id="alignDescribe">
									{position.qtty.toString().indexOf('-') == 0
										? parseInt(
											position.qtty
												.toString()
												.replace('-', '')
										  )
										: parseInt(position.qtty.toString())}
								</td>
								<td>
									{position.optionType !== undefined
										? position.optionType === 'C'
											? 'Call'
											: 'Put'
										: 'Ativo'}
								</td>
								<td id="alignDescribe">
									{Intl.NumberFormat('pt-BR', {
										style: 'currency',
										currency: 'BRL',
									}).format(position.entryPx)}
								</td>
								<td>{position.symbol}</td>
								<td>
									{position.expirationDate != undefined
										? moment(
											position.expirationDate,
											'YYYYMMDD'
										  ).format('DD/MM/YYYY')
										: '-'}
								</td>
								<td id="alignDescribe">
									{position.strikePx !== undefined
										? Intl.NumberFormat('pt-BR', {
											style: 'currency',
											currency: 'BRL',
										  }).format(position.strikePx)
										: '-'}{' '}
								</td>
								<td id="alignDescribe">
									{Intl.NumberFormat('pt-BR', {
										style: 'currency',
										currency: 'BRL',
									}).format(position.entryCost)}
								</td>
							</tr>
						))}
					</TableStrategy>
					<TitleTable bgColor>
						<p>ANÁLISE DA ESTRATÉGIA</p>
					</TitleTable>
					<TableAnalisy>
						<tr>
							<TitleTdAnalisy colorTd>
								Ativo Objeto
							</TitleTdAnalisy>
							<TextdAnalisy>
								{dataPdf.strategy.underSymbol}
							</TextdAnalisy>
							<TitleTdAnalisy colorTd>
								Lucro Máximo
							</TitleTdAnalisy>
							<TextdAnalisy>
								{dataPdf.strategy.maxProfit != 'unlimited'
									? Intl.NumberFormat('pt-BR', {
										style: 'currency',
										currency: 'BRL',
									  }).format(dataPdf.strategy.maxProfit)
									: 'Ilimitado'}
							</TextdAnalisy>
						</tr>
						<tr>
							<TitleTdAnalisy colorTd>Data Alvo</TitleTdAnalisy>
							<TextdAnalisy>
								{moment(
									dataPdf.strategy.expirationDate,
									'YYYYMMDD'
								).format('DD/MM/YYYY')}{' '}
								({dataPdf.strategy.dte} d.u)
							</TextdAnalisy>
							<TitleTdAnalisy colorTd>
								Lucro Máximo Percentual
							</TitleTdAnalisy>
							<TextdAnalisy>
								{dataPdf.strategy.maxProfitPerc === undefined
									? '-'
									: dataPdf.strategy.maxProfitPerc !==
									  'unlimited'
										? roundNumber(
											dataPdf.strategy.maxProfitPerc,
											2
									  ) + '%'
										: 'Ilimitado'}
							</TextdAnalisy>
						</tr>
						<tr>
							<TitleTdAnalisy colorTd>Custo</TitleTdAnalisy>
							<TextdAnalisy>
								{Intl.NumberFormat('pt-BR', {
									style: 'currency',
									currency: 'BRL',
								}).format(dataPdf.strategy.entryCost)}
							</TextdAnalisy>
							<TitleTdAnalisy colorTd>% Sobre CDI</TitleTdAnalisy>
							<TextdAnalisy>
								{dataPdf.strategy.cdiPerc !== null &&
								dataPdf.strategy.cdiPerc !== undefined
									? dataPdf.strategy.cdiPerc === 'unlimited'
										? 'Ilimitado'
										: roundNumber(
											dataPdf.strategy.cdiPerc,
											2
										  ) + '%'
									: '-'}
							</TextdAnalisy>
						</tr>
						<tr>
							<TitleTdAnalisy colorTd>Margem</TitleTdAnalisy>
							<TextdAnalisy>
								{dataPdf.strategy.margin != null
									? Intl.NumberFormat('pt-BR', {
										style: 'currency',
										currency: 'BRL',
									  }).format(dataPdf.strategy.margin)
									: 'N/D'}
							</TextdAnalisy>
							<TextdAnalisy></TextdAnalisy>
							<TextdAnalisy></TextdAnalisy>
						</tr>
					</TableAnalisy>
					<ContainerTables marginBottom={'30px'}>
						<ItemConteiner width={'45%'}>
							<TitlePay bgColor>
								<p>PAY-OFF</p>
							</TitlePay>
							<TablePay style={{ borderCollapse: 'collapse' }}>
								<tr>
									<TitlethPay colorTdPay>
										Preço do Ativo
									</TitlethPay>
									<TitlethPay colorTdPay>
										Resultado Financeiro
									</TitlethPay>
									<TitlethPay colorTdPay>
										Resultado %
									</TitlethPay>
								</tr>
								{mediaRowsChartData(
									dataPdf.strategy.chartData,
									dataPdf
								).map(pay => (
									<tr key={pay.px}>
										<TexttdPay>
											{Intl.NumberFormat('pt-BR', {
												style: 'currency',
												currency: 'BRL',
											}).format(pay.underPx)}
										</TexttdPay>
										<TexttdPay>
											{Intl.NumberFormat('pt-BR', {
												style: 'currency',
												currency: 'BRL',
											}).format(pay.px)}
										</TexttdPay>
										<TexttdPay>
											{isNaN(pay.var) === true
												? '-'
												: pay.var != null
													? roundNumber(pay.var, 2) + '%'
													: '-'}
										</TexttdPay>
									</tr>
								))}
							</TablePay>
						</ItemConteiner>
						<ItemConteiner width={'45%'}>
							<ItemsDivs height={'50%'} margin>
								<TitlePay bgColor>
									<p>Gráfico no Vencimento</p>
								</TitlePay>
								<ContainerGrapich>
									<div id="chart"></div>
									<div id="watermarkImg">
										<img src={marcaDagua} />
									</div>
								</ContainerGrapich>
							</ItemsDivs>
							<ItemsDivs height={'35%'}>
								<TitlePay bgColor>
									<p>Comentário de Estrutura</p>
								</TitlePay>
								<ContainerDescribe>
									{dataPdf.strategy.comments}
								</ContainerDescribe>
							</ItemsDivs>
						</ItemConteiner>
					</ContainerTables>
					<DefaultDisclaimerHeader />
				</ContainerMain>
			</ContainerBody>
			<DefaultDisclaimerFooter username={username} />
		</>
	);
};

export default OramaTemplate;
