const cleanSpread = (hash, execType) => {
	const cleanCustomParameters = {
		ExecutionType: parseInt(hash.CustomParameters.ExecutionType, 10),
		ToMarketTriggerValueEnabled:
			hash.CustomParameters.ExecutionType === 1 ? 'Y' : 'N',
		Trigger: hash.CustomParameters.Trigger,
		TriggerValue:
			hash.CustomParameters.Trigger === 1
				? hash.CustomParameters.TriggerValue
				: parseInt(hash.CustomParameters.TriggerValue, 10),
		BandPriceRef: hash.CustomParameters.BandPriceRef
			? hash.CustomParameters.BandPriceRef === 'Y'
				? 'Y'
				: 'N'
			: 'N',
		BookDepth: hash.CustomParameters.BookDepth
			? hash.CustomParameters.BookDepth
			: 1,
		CompensateExec: parseInt(hash.CustomParameters.CompensateExec, 10),
	};
	if (cleanCustomParameters.ExecutionType === 1) {
		cleanCustomParameters.ToMarketTriggerValue =
			cleanCustomParameters.Trigger === 1
				? hash.CustomParameters.ToMarketTriggerValue
				: parseInt(hash.CustomParameters.ToMarketTriggerValue, 10);
	}
	if (cleanCustomParameters.BandPriceRef === 'Y') {
		cleanCustomParameters.BandPriceSymbol =
			hash.CustomParameters.BandPriceSymbol;
		cleanCustomParameters.BandPriceLow = hash.CustomParameters.BandPriceLow;
		cleanCustomParameters.BandPriceHigh =
			hash.CustomParameters.BandPriceHigh;
		cleanCustomParameters.BandPriceExchange =
			hash.CustomParameters.BandPriceExchange ?? 'XBSP';
	}
	if (execType === 'B') {
		cleanCustomParameters.ExecStrategy = 1;
	}
	return cleanCustomParameters;
};

const spreadClear = (
	bullet,
	setAccessorInfos,
	account,
	execType
) => {
	const hash = { ...bullet.content };
	const strategyInfos = { ...bullet.StrategyInfos };

	if (hash.StrategyLegs.some(leg => leg.LegSymbol === '')) {
		return { errorMessage: 'Select all symbols' };
	}

	const duplicatedPaper = hash.StrategyLegs.map(leg => {
		const papers = hash.StrategyLegs.filter(
			el => el.LegSymbol === leg.LegSymbol
		);
		if (papers.length > 1) {
			return true;
		}
		return false;
	});

	if (duplicatedPaper.filter(e => e === true).length > 0) {
		return { errorMessage: 'Papéis duplicados' };
	}

	if (hash.StrategyLegs.some(e => e.LegQuantity <= 0)) {
		return { errorMessage: 'A quantidade precisa ser maior que 0' };
	}

	if (
		!hash.StrategyLegs.some(leg => leg.LegResting === 'Y') &&
		execType !== 'B'
	) {
		return { errorMessage: 'At least one leg to resting' };
	}

	if (
		!hash.CustomParameters.TriggerValue &&
		hash.CustomParameters.TriggerValue !== 0
	) {
		const fieldName =
			hash.CustomParameters.ExecutionType === 0 ? 'Entrada' : 'Gain';
		return { errorMessage: `Favor preencher campo ${fieldName}` };
	}

	if (
		hash.CustomParameters.ExecutionType === 1 &&
		!hash.CustomParameters.ToMarketTriggerValue
	) {
		return { errorMessage: 'Favor preencher campo Loss' };
	}

	if (
		hash.CustomParameters.BandPriceRef &&
		hash.CustomParameters.BandPriceRef === 'Y' &&
		(!hash.CustomParameters.BandPriceSymbol ||
			(hash.CustomParameters.BandPriceSymbol &&
				hash.CustomParameters.BandPriceSymbol === ''))
	) {
		return { errorMessage: 'Favor preencher campo Ativo do Intervalo' };
	}

	hash.StrategyLegs.forEach((leg, index) => {
		hash.StrategyLegs[index] = {
			ILegAllocAccount: account(),
			LegSymbol: hash.StrategyLegs[index].LegSymbol,
			LegSecurityExchange: strategyInfos[index].subExchange
				? strategyInfos[index].subExchange
				: 'XBSP',
			LegSide: hash.StrategyLegs[index].LegSide,
			LegQuantity: parseInt(hash.StrategyLegs[index].LegQuantity, 10),
			LegResting: hash.StrategyLegs[index].LegResting,
			LegMaxClipSize: parseInt(
				hash.StrategyLegs[index].LegMaxClipSize,
				10
			),
		};
	});
	hash.CustomParameters = cleanSpread(hash, execType);

	delete hash.signature;
	delete hash.clean;
	hash.CustomParameters.InitSuspended =
		bullet.content.CustomParameters.InitSuspended ?? 'N';
	if (execType !== 'B') {
		hash.StrategyLegs.forEach((leg, index) => {
			leg.LegResting = bullet.content.StrategyLegs[index].LegResting;
		});
		hash.CustomParameters.IgnoreOffersLT =
			bullet.content.CustomParameters.IgnoreOffersLT;
	} else {
		hash.CustomParameters.IgnoreOffersLT = -1;
		hash.StrategyLegs.forEach(leg => {
			leg.LegMaxClipSize = 0;
		});
	}
	hash.StrategyLegs = setAccessorInfos(hash.StrategyLegs);

	const cleanedHash = {
		'Name': hash.Name,
		'InitTime': hash.InitTime,
		'EndTime': hash.EndTime,
		'ExpireDate': hash.ExpireDate,
		'Text': hash.Text,
		'BasketId': hash.BasketId,
		'StrategyCode': hash.StrategyCode,
		'CustomParameters': hash.CustomParameters,
		'StrategyLegs': hash.StrategyLegs,
		'Signature': hash.Signature
	};

	if (process.env.NODE_ENV === 'development')
		console.log('Spread submit', cleanedHash);

	return cleanedHash;
};

export default spreadClear;
