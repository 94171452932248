import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import SkewLeg from '../../MaterialSkew/SkewLeg';
import SkewExit from '../../MaterialSkew/SkewExit';

const SkewBodyBullet = props => {
	const { execType, bullet, setPaperInfo, onSuggestionsFetchRequested, stepFunction, setSpreaAuxPrice, handleChange, lastVol } = props;

	return (
		<>
			<Grid item xs={6}>
				<Grid container spacing={1}>
					<Grid item xs={12} style={{ marginLeft: '2px' }}>
						<Typography variant="subtitle1">Entrada</Typography>
					</Grid>
					{bullet.content.StrategyLegs.map(
						(leg, index) =>
							 (
								<SkewLeg
									setPaperInfo={setPaperInfo}
									index={index}
									leg={leg}
									key={`${bullet.uid}_${index}`}
									callbackMarketPrice={setSpreaAuxPrice}
									stepFunction={stepFunction}
									execType={execType}
									onSuggestionsFetchRequested={onSuggestionsFetchRequested}
									onlyOptions
								/>
							),
					)}
				</Grid>
			</Grid>
			<SkewExit handleChange={handleChange} stepFunction={stepFunction} execType={execType} lastVol={lastVol} />
		</>
	);
};

export default SkewBodyBullet;
