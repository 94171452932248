import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { additionalUpdate } from '../../../store/modules/bottomBullet/actions';

import { PasswordTextField } from '../MaterialStyles/style';

const PasswordInput = ({ disabled }) => {
	const dispatch = useDispatch();
	const signature = useSelector(state => state.bottomBullet.bullet.signature);
	const paramsView = useSelector(
		state => state.bottomBullet.bullet.paramsView
	);
	const [showPassword, setShowPassword] = useState(false);

	const handleChange = value => {
		const regexCheck = /^[A-Za-z0-9]{0,15}$/.test(value);

		if (regexCheck) {
			dispatch(additionalUpdate('signature', value));
		}
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};

	return (
		<>
			<PasswordTextField
				name="signature-field"
				fullWidth
				label="Assinatura"
				type="text"
				variant="outlined"
				size="small"
				value={paramsView ? '' : signature}
				onChange={e => handleChange(e.target.value)}
				autoComplete="off"
				InputLabelProps={{ shrink: true }}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								size="small"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
							>
								{showPassword ? (
									<Visibility fontSize="medium" />
								) : (
									<VisibilityOff fontSize="medium" />
								)}
							</IconButton>
						</InputAdornment>
					),
				}}
				visible={showPassword}
				disabled={disabled}
			/>
		</>
	);
};

export default PasswordInput;
