import React, { memo, useMemo } from 'react';
import { toast } from 'react-toastify';
import { parseISO, format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import {
	setSavedStrategy,
	updatePosition,
} from 'store/modules/simulator/actions';
import {
	calcDaysToExpiration,
	getOptions,
	getPaper,
	getStrikePx,
} from '../utils';

import { SimulatorSelect } from './SimulatorSelect';

export const SelectSerie = memo(function SelectSerie({ label, position }) {
	const dispatch = useDispatch();

	const paper = useSelector(getPaper);
	const options = useSelector(getOptions);
	const activeStrategy = useSelector(state => state.simulator.activeStrategy);

	const { id, expired, optionType, expirationDate, daysToExpiration } =
		position;

	const data = useMemo(() => {
		if (expired) {
			return [];
		}

		const formattedOptionType =
			{ C: 'CALL', P: 'PUT' }[optionType] || optionType;

		const dataWithoutDuplicated = new Set(
			options
				.filter(option => option.optionType === formattedOptionType)
				.map(i => i.maturityDate)
		);

		const series = [...dataWithoutDuplicated].map(item => {
			const selectedOption = options.find(i => i.maturityDate === item);

			return {
				maturityDate: item,
				expType: selectedOption.expType,
				workDaysUntilMaturity:
					selectedOption.workDaysUntilMaturity ||
					calcDaysToExpiration(item),
			};
		});

		return series;
	}, [expired, options, optionType, expirationDate]);

	function handleChange(event) {
		const valueSelected = data.filter(
			dataValue =>
				dataValue.workDaysUntilMaturity === Number(event.target.value)
		);

		if (!valueSelected || valueSelected.length == 0) {
			return toast.error('Erro ao selecionar série');
		}

		const { indexValue, data: dataValues } = getStrikePx(
			options,
			paper.price,
			valueSelected[0].maturityDate,
			optionType
		);

		dispatch(
			updatePosition(activeStrategy, id, {
				strikePx: dataValues[indexValue].strikePrice,
				symbol: dataValues[indexValue].symbol,
				expirationDate: valueSelected[0].maturityDate,
				daysToExpiration: valueSelected[0].workDaysUntilMaturity,
				entryPxLocked: false,
				exitPxLocked: false,
				entryPx: 0,
				exitPx: 0,
				last_entryPx: 0,
				last_exitPx: 0,
				last_midPx: 0,
				last_tradePx: 0,
			})
		);
		dispatch(setSavedStrategy(activeStrategy, false));
	}

	function getFormattedLabel(item) {
		const formattedDate = format(parseISO(item[label]), 'dd-MM-yyyy');
		const formattedExpirationType = item.expType === 'S' ? ' - S' : '';

		return formattedDate + formattedExpirationType;
	}

	const seriesOptions = data.map(item => ({
		label: getFormattedLabel(item),
		value: item.workDaysUntilMaturity,
	}));

	const formattedExpirationDate = format(
		parseISO(expirationDate),
		'dd-MM-yyyy'
	);

	return (
		<>
			{data?.length > 0 ? (
				<Box sx={{ px: 0.25 }}>
					<SimulatorSelect
						onChange={handleChange}
						options={seriesOptions}
						value={daysToExpiration}
					/>
				</Box>
			) : (
				<Typography variant="body2">
					{formattedExpirationDate}
				</Typography>
			)}
		</>
	);
});
