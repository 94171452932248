export const SET_STRATEGIES = 'portfolio/SET_STRATEGIES';
export const SET_ACTIVE_STRATEGIES = 'portfolio/SET_ACTIVE_STRATEGIES';
export const UPDATE_ACTIVE_STRATEGIES = 'portfolio/UPDATE_ACTIVE_STRATEGIES';
export const UPDATE_SAVED_PARAMETER = 'portfolio/UPDATE_SAVED_PARAMETER';
export const UPDATE_USER_PREFERENCES = 'portfolio/UPDATE_USER_PREFERENCES';
export const DELETE_STRATEGY = 'portfolio/DELETE_STRATEGY';
export const SET_NEW_STRATEGY_MODAL = 'portfolio/SET_NEW_STRATEGY_MODAL';
export const SET_WALLET_VALUES = 'portfolio/SET_WALLET_VALUES';
export const UPDATE_TAB_ID = 'portfolio/UPDATE_TAB_ID';
export const SET_UNSAVED_MODAL = 'portfolio/SET_UNSAVED_MODAL';
export const UPDATE_DELETION_DATA = 'portfolio/UPDATE_DELETION_DATA';
export const UPDATE_TAB_INDEX = 'portfolio/UPDATE_TAB_INDEX';
export const UPDATE_COLUMNS_SIZE = 'portfolio/UPDATE_COLUMNS_SIZE';
export const SET_ROWS = 'portfolio/SET_ROWS';
