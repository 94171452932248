import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';

import rootReducer from './modules/rootReducer';

const persistConfig = {
	key: 'flexscan',
	storage,
	whitelist: [
		'auth',
		'order',
		'ui',
		'strategy',
		'userPreferences',
		'notifications',
		'widgets',
		'globalStatus',
		'routes',
		'market',
		'externalStorage',
		'products',
	],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const features = {
	pause: true, // start/pause recording of dispatched actions
	lock: false, // lock/unlock dispatching actions and side effects
	persist: true, // persist states on page reloading
	export: false, // export history of actions in a file
	import: 'custom', // import history of actions from a file
	jump: true, // jump back and forth (time travelling)
	skip: false, // skip (cancel) actions
	reorder: false, // drag and drop actions in the history list
	dispatch: false, // dispatch custom actions or action creators
	test: false, // generate tests for the selected actions
};

const composeEnhancers = composeWithDevTools(
	process.env.NODE_ENV !== 'production' ? { trace: false } : { features }
);

const store = createStore(
	persistedReducer,
	composeEnhancers(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export { store, persistor };
