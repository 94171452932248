import { getYear } from 'date-fns';

import { api } from './api';
import { store } from 'store';
import { setHolidays } from 'store/modules/panelConfig/actions';

export function getHolidays() {
	const queryParams = {
		params: {
			detailed: 1,
			current_year: getYear(new Date()),
		},
	};

	api.get('/exchanges/BVMF/holidays', queryParams)
		.then(response => {
			const holidays = response.data.records.map(
				holiday => holiday.holidayDate
			);

			store.dispatch(setHolidays(holidays));
		})
		.catch(error => console.error(error));
}
