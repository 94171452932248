import { toast } from 'react-toastify';

const icebergClear = (
	bullet,
	setAccessorInfos,
	account,
	t,
	validatePrice,
) => {
	const hash = { ...bullet.content };
	const strategyInfos = { ...bullet.StrategyInfos };
	if (hash.StrategyLegs.some(leg => leg.LegSymbol === '')) {
		toast.error(t('Select all symbols'));
		return;
	}

	if (validatePrice(hash.CustomParameters.PriceLimit)) {
		toast.error(t('O Preço limite precisa ser maior que 0'));
		return;
	}

	if (
		hash.StrategyLegs[0].LegQuantity %
            hash.StrategyLegs[0].LegMaxClipSize !==
        0
	) {
		toast.error('Quantidade deve ser multiplo do clip');
		return;
	}

	if (hash.StrategyLegs.some(e => e.LegQuantity <= 0)) {
		return { errorMessage: 'A quantidade precisa ser maior que 0' };
	}

	hash.CustomParameters = {
		PriceLimit: hash.CustomParameters.PriceLimit,
		FixedOpenQuantity: hash.CustomParameters.FixedOpenQuantity,
	};

	hash.StrategyLegs[0] = {
		ILegAllocAccount: account(),
		LegSymbol: hash.StrategyLegs[0].LegSymbol,
		LegSecurityExchange: strategyInfos[0].subExchange
			? strategyInfos[0].subExchange
			: 'XBSP',
		LegSide: hash.StrategyLegs[0].LegSide,
		LegQuantity: hash.StrategyLegs[0].LegQuantity,
		LegMaxClipSize: hash.StrategyLegs[0].LegMaxClipSize,
	};

	delete hash.signature;
	delete hash.clean;
	hash.StrategyLegs = setAccessorInfos(hash.StrategyLegs);

	const cleanedHash = {
		'Name': hash.Name,
		'InitTime': hash.InitTime,
		'EndTime': hash.EndTime,
		'ExpireDate': hash.ExpireDate,
		'Text': hash.Text,
		'BasketId': hash.BasketId,
		'StrategyCode': hash.StrategyCode,
		'CustomParameters': hash.CustomParameters,
		'StrategyLegs': hash.StrategyLegs,
		'Signature': hash.Signature
	};

	return cleanedHash;
};

export default icebergClear;
