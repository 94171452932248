import React from 'react';

import { Box, Typography } from '@mui/material';

import { ModalTemplate } from '../Template';
import { GeneralButton } from '../styles';

export const OramaSignature = ({ open, handleClose }) => {
	return (
		<ModalTemplate
			open={open}
			title="ATENÇÃO"
		>
			<Box width="45rem" textAlign="center">
				<Typography variant="h6">
					A troca ou recuperação de sua assinatura eletrônica deve ser feita no portal da Órama. Na sequência, será
					necessário realizar novamente o login no FlexScan.
				</Typography>
				<GeneralButton
					style={{ marginTop: '16px' }}
					variant="contained"
					buttonColor="success"
					onClick={handleClose}
				>
					OK
				</GeneralButton>
			</Box>
		</ModalTemplate>
	);
};
