import axios from 'axios';
import { store } from 'store';
import { toast } from 'react-toastify';

import i18n from '../../i18n';
import { notAuthError } from 'store/modules/auth/actions';
import { showMessageErroWithoutStatusCode } from 'services/api';

const headers = {
	post: {
		'Content-Type': 'application/json;charset=utf-8',
	},
	get: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
};

export const apiAWS = axios.create({
	baseURL: process.env.REACT_APP_AWS_API_URL,
	timeout: 20 * 1000,
	headers,
});

apiAWS.interceptors.response.use(
	response => response,
	error => {
		const { response } = error;
		if (
			response &&
			response.status &&
			response.status >= 400 &&
			response.status <= 504
		) {
			if (response.status === 401) {
				if (response.data?.code !== 7000) {
					store.dispatch(notAuthError());
				}
			} else if (response.status === 400) {
				toast.error(
					response.message
						? response.message
						: 'Problema ao processar a requisição. Entre em contato com a equipe de suporte informando o erro: E-43557'
				);
			} else if (response.status === 404) {
				if (response.data.code === '4568') {
					toast.error(
						'Seu acesso ao FlexScan deve ser realizado via portal Órama.'
					);
				} else if (response.data.code == '4569') {
					toast.error(
						response.message
							? response.message
							: 'Usuário não encontrado com o CPF informado.'
					);
				}
			} else if (response.status >= 500) {
				toast.error(i18n.t('serverError'));
			} else {
				showMessageErroWithoutStatusCode(error);
			}
		}

		return Promise.reject(error);
	}
);
