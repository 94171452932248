import { automaticClipConfigure } from '@investflex/iflexquantjs';

import { getMaxClipSize } from './getMaxClipSize';

export function addClipProportion(strategyLegs, strategyInfos) {
	const quantities = [];
	const minQuantities = [];

	strategyLegs.forEach(leg => {
		const minOrderQty =
			strategyInfos.find(el => el.symbol === leg.LegSymbol)
				?.minOrderQty ?? 0;

		const maxClipSize = getMaxClipSize(minOrderQty, leg.LegQuantity);

		quantities.push(leg.LegQuantity);
		minQuantities.push(maxClipSize);
	});

	const maxClipSizes = automaticClipConfigure(quantities, minQuantities);

	if (maxClipSizes.every(item => !isNaN(item) && item > 0)) {
		maxClipSizes.forEach((clipSize, index) => {
			strategyLegs[index].LegMaxClipSize = clipSize;
		});
	}
}
