import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';

import { CircularProgress, Grid } from '@mui/material';

import AlertToken from 'components/ModalToken';
import { NewPasswordForm } from './NewPasswordForm';
import { NewSignature } from './NewSignature';

import { EXTERNAL_PRODUCTS, PRODUCT_TYPES } from 'utils/constants';
import { apiAWS, apiCadastro } from '../../services/api';

export const NewUserForm = () => {
	const history = useHistory();
	const { newUserToken } = useParams();

	const [step, setStep] = useState(0);
	const [email, setEmail] = useState();
	const [modal, setModal] = useState(false);
	const [product, setProduct] = useState(null);
	const [showCircularProgress, setShowCircularProgress] = useState(false);

	const showProgress = () => {
		return (
			<>
				<Grid style={{ textAlign: 'center' }}>
					<CircularProgress />
				</Grid>
			</>
		);
	};

	var formattedEmail = function (email) {
		var splitAt = email.split('@', 2);
		return formattedName(splitAt[0]) + '@' + splitAt[1];
	};

	var formattedName = function (name) {
		var nameArray = Array.from(name);
		var hiddenChars =
			name.length % 2 == 1 ? (name.length + 1) / 2 : name.length / 2;
		var loopCount = 0;

		while (loopCount < name.length) {
			if (
				loopCount < hiddenChars / 2 ||
				loopCount > Math.floor(name.length / 2) + hiddenChars / 2 - 1
			) {
				nameArray[loopCount] = 'x';
			}
			loopCount++;
		}

		return nameArray.join().replaceAll(',', '');
	};

	const tempHeaders = { headers: { 'x-authorization': newUserToken } };

	function getProduct() {
		const validProduct = jwtDecode(newUserToken).products.find(
			product => product in PRODUCT_TYPES || product in EXTERNAL_PRODUCTS
		);

		setProduct(validProduct);
	}

	useEffect(() => {
		getProduct();

		setShowCircularProgress(true);
		apiAWS
			.get('/credentials/tokens', tempHeaders)
			.then(() => {
				setStep(1);
			})
			.catch(error => {
				if (error && error.response?.status === 401) {
					setModal(true);
				}
			})
			.finally(() => {
				setShowCircularProgress(false);
			});
	}, []);

	function handleNewPassword(event, values) {
		event.preventDefault();

		if (values.password !== values.password_confirmation) {
			toast.error('Senhas não conferem');
			return;
		}

		const body = {
			service: 'create_password',
			password: values.password,
		};

		setShowCircularProgress(true);
		apiAWS
			.post('/credentials/commands', body, tempHeaders)
			.then(() => {
				toast.success(
					'Senha criada com sucesso, agora vamos criar a assinatura eletrônica!'
				);
				setStep(2);
			})
			.catch(() => {
				toast.error(
					'Erro ao criar nova senha, entre em contato com o suporte!'
				);

				if (product) {
					history.push(`/login/product/${product.toUpperCase()}`);
					return;
				}

				history.push('/login');
			})
			.finally(() => {
				setShowCircularProgress(false);
			});
	}

	const handleNewLink = () => {
		apiCadastro
			.post(
				'/credentials',
				{},
				{
					headers: {
						Authorization: `Bearer ${newUserToken}`,
					},
				}
			)
			.then(response => {
				const emailResponse = atob(response.data);
				const emailDecoded = JSON.parse(emailResponse);
				const maskEmail = formattedEmail(emailDecoded.email);
				setEmail(maskEmail);
				setModal(true);
			})
			.catch(() => {
				setModal(false);
				history.push('/login');
			});
	};

	return (
		<>
			<AlertToken
				modalIsOpen={modal}
				sendLink={handleNewLink}
				alterEmail={email}
			/>
			{showCircularProgress && showProgress()}
			{step === 1 && <NewPasswordForm handleSubmit={handleNewPassword} />}
			{step === 2 && (
				<NewSignature newUserToken={newUserToken} product={product} />
			)}
		</>
	);
};
