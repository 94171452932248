import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import './i18n';
import App from './App';

const render = () => {
	const root = createRoot(document.getElementById('root'));
	root.render(<App />);
};

render();
