import React from 'react';

import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	DialogContentText,
} from '@mui/material';

export const UserBlockedModal = ({ isOpen, onClose, onSubmit }) => {
	return (
		<Dialog open={isOpen} onClose={onClose}>
			<Box sx={{ p: '1.5rem 1rem' }}>
				<DialogTitle>Seu usuário foi bloqueado!</DialogTitle>

				<DialogContent>
					<DialogContentText>
						Para desbloquear seu usuário, crie uma nova senha.
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button variant="outlined" onClick={onClose}>
						Cancelar
					</Button>

					<Button variant="contained" onClick={onSubmit}>
						Criar nova senha
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};
