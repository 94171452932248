import { PRODUCT_TYPES } from 'utils/constants';
import { ProductsActionTypes } from './actionTypes';

const productsInitialState = {
	activeProduct: { code: PRODUCT_TYPES.FLEX_STANDARD },
	products: [],
	userSignatures: [],
	permittedBullets: {
		permittedBulletTypes: [],
		permittedBulletCategories: [],
		permittedStrategyCodes: [],
	},
	userContracts: {
		contractVisibility: false,
		contractsData: [],
		contractToAccept: null,
	},
};

export const products = (state = productsInitialState, { type, payload }) => {
	switch (type) {
		case ProductsActionTypes.SET_ACTIVE_PRODUCT:
			return { ...state, activeProduct: payload.activeProduct };
		case ProductsActionTypes.SET_PRODUCTS:
			return { ...state, products: payload.products };
		case ProductsActionTypes.SET_USER_SIGNATURES:
			return { ...state, userSignatures: payload.signatures };
		case ProductsActionTypes.SET_PERMITTED_BULLETS:
			return {
				...state,
				permittedBullets: {
					...state.permittedBullets,
					...payload.permittedBullets,
				},
			};
		case ProductsActionTypes.SET_BULLET_TYPES:
			return {
				...state,
				permittedBullets: {
					...state.permittedBullets,
					permittedBulletTypes: payload.bulletTypes,
				},
			};
		case ProductsActionTypes.SET_BULLET_CATEGORIES:
			return {
				...state,
				permittedBullets: {
					...state.permittedBullets,
					permittedBulletCategories: payload.bulletCategories,
				},
			};
		case ProductsActionTypes.SET_CONTRACT_VISIBILITY:
			return {
				...state,
				userContracts: {
					...state.userContracts,
					contractVisibility: payload.visibility,
				},
			};
		case ProductsActionTypes.SET_CONTRACTS_DATA:
			return {
				...state,
				userContracts: {
					...state.userContracts,
					contractsData: payload.contractsData,
				},
			};
		case ProductsActionTypes.SET_CONTRACT_TO_ACCEPT:
			return {
				...state,
				userContracts: {
					...state.userContracts,
					contractToAccept: payload.contract,
				},
			};
		case ProductsActionTypes.SIGN_CONTRACT: {
			const shouldUpdateByProduct =
				payload.signedContract.product === 'flexscan';

			const updatedContractsList = state.userContracts.contractsData.map(
				contract => {
					const shouldUpdateContract = shouldUpdateByProduct
						? contract.product === payload.signedContract.product
						: contract.id === payload.signedContract.id;

					if (shouldUpdateContract) {
						return { ...contract, have_pendencies: false };
					}

					return contract;
				}
			);

			const getActiveProduct = signedContract => {
				const productCode =
					signedContract.product === 'flexscan'
						? 'flexscan'
						: signedContract.product;

				return state.userSignatures.find(
					signature => signature.product.code === productCode
				).product;
			};

			const newActiveProduct = getActiveProduct(payload.signedContract);

			return {
				...state,
				activeProduct: newActiveProduct,
				userContracts: {
					...state.userContracts,
					contractToAccept: null,
					contractVisibility: false,
					contractsData: updatedContractsList,
				},
			};
		}
		case ProductsActionTypes.RESET_PRODUCTS_STATE:
			return productsInitialState;
		default:
			return state;
	}
};
