import styled from 'styled-components';
import { Box, Grid } from '@mui/material';

export const Container = styled(Box)`
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	background-color: #303030;
	border-radius: 3px;
`;

export const HeaderBox = styled(Box)`
	background-color: #424242 !important;
	border-radius: 3px 3px 0px 0px;
`;

export const ModalBody = styled(Grid)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;

	padding: 10px;
	min-height: 80px;
`;
