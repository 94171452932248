import { SET_COLOR_MODE, SET_SAVE_BULLET_LOADING } from './types';

const initialState = {
	colorMode: 'white',
	theme: 'white',
	savingOrderLoading: false,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_COLOR_MODE:
			return { ...state, colorMode: payload.colorMode, theme: payload.colorMode };

		case SET_SAVE_BULLET_LOADING:
			return { ...state, savingOrderLoading: payload.isLoading };

		default:
			return state;
	}
};
