import { apiAWS } from 'services/api';
import { store } from 'store';
import { updateUserPreferences } from 'store/modules/portfolio/actions';

export function getSimulatorPreferences(username) {
	apiAWS
		.get(`/userpreferences/${username}?service=simulator`)
		.then(response => {
			const formattedResponse = response.data;

			delete formattedResponse.user_name;
			delete formattedResponse.service_name;

			if (formattedResponse.columnVisibilityModel) {
				formattedResponse.columns = {
					...formattedResponse.columns,
					columnVisibilityModel:
						formattedResponse.columnVisibilityModel,
				};

				delete formattedResponse.columnVisibilityModel;
			}

			const columnsFields = ['orderedFields', 'columnsSize'];

			columnsFields.forEach(field => {
				if (field in formattedResponse) {
					formattedResponse.columns = {
						...formattedResponse.columns,
						[field]: formattedResponse[field],
					};

					delete formattedResponse[field];
				} else if (
					formattedResponse.columns &&
					!(field in formattedResponse.columns)
				) {
					formattedResponse.columns = {
						...formattedResponse.columns,
						[field]: [],
					};
				}
			});

			if (formattedResponse.tableFilter) {
				formattedResponse.filter = formattedResponse.tableFilter;
				delete formattedResponse.tableFilter;
			}

			store.dispatch(updateUserPreferences(formattedResponse));
		})
		.catch(error => {
			console.log(error);
		});
}

export function postSimulatorPreferences(username, body) {
	apiAWS
		.post(`/userpreferences/${username}?service=simulator`, body)
		.catch(error => {
			console.log(error);
		});
}
