import React, { memo, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { SimulatorSelect } from './SimulatorSelect';

import { roundNumber } from 'utils/numberFormat';
import {
	setSavedStrategy,
	updatePosition,
} from 'store/modules/simulator/actions';
import { getOptions, getPaperFromStore, getStrikePx } from '../utils';

export const SelectStrike = memo(function SelectStrike({ value, position }) {
	const dispatch = useDispatch();

	const activeStrategy = useSelector(state => state.simulator.activeStrategy);

	const { id, optionType, expired, expirationDate, strikePx, symbol } =
		position;

	const paper = getPaperFromStore();
	const options = useSelector(getOptions);

	const data = useMemo(() => {
		if (!optionType || expired || !expirationDate) {
			return [];
		}

		const { data } = getStrikePx(
			options,
			paper?.price,
			expirationDate,
			optionType,
			[strikePx] //Changed to select only position strike
		);

		if (data.length > 0) {
			const strikeSearchBySymbol = data.find(
				i => i?.symbol === symbol
			)?.strikePrice;

			if (strikeSearchBySymbol && strikeSearchBySymbol === value) {
				return data;
			}

			if (strikeSearchBySymbol && strikeSearchBySymbol !== value) {
				dispatch(
					updatePosition(activeStrategy, id, {
						strikePx: strikeSearchBySymbol,
					})
				);
			} else {
				toast.error(
					`Falha ao recuperar ativo salvo: ${symbol} para a estratégia ${activeStrategy}. Contate o suporte.`
				);
			}
		}

		return data;
	}, [expirationDate, options, optionType]);

	function handleChange(event) {
		const valueSelected = data.filter(
			dataValue => dataValue.symbol === event.target.value
		);

		dispatch(
			updatePosition(activeStrategy, id, {
				strikePx: valueSelected[0].strikePrice,
				symbol: valueSelected[0].symbol,
				entryPxLocked: false,
				exitPxLocked: false,
				entryPx: 0,
				exitPx: 0,
				last_entryPx: 0,
				last_exitPx: 0,
				last_midPx: 0,
				last_tradePx: 0,
			})
		);
		dispatch(setSavedStrategy(activeStrategy, false));
	}

	const strikeOptions = data.map(item => {
		const formattedStrikePrice = roundNumber(item.strikePrice);

		const formattedOptionType = item?.optionType === 'CALL' ? '(C)' : '(P)';

		return {
			label: `${formattedStrikePrice} - ${item.symbol} ${formattedOptionType}`,
			value: item.symbol,
		};
	});

	return (
		<>
			{data.length > 0 ? (
				<Box sx={{ width: '100%', px: 0.5 }}>
					<SimulatorSelect
						value={symbol}
						onChange={handleChange}
						options={strikeOptions}
					/>
				</Box>
			) : (
				<Typography variant="body2" sx={{ ml: 1.25 }}>
					{symbol} {expired ? `(${optionType})` : ''}
				</Typography>
			)}
		</>
	);
});
