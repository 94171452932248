import styled from 'styled-components';

import oramaHeader from '../../../../assets/images/orama_header.png';

const DivDisplayFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const HeaderContainerOrama = styled(DivDisplayFlex)`
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 130px;
    margin-bottom: 6px;
`;

export const HeaderOrama = styled.div`
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 55%;
    background: url(${oramaHeader});
    background-repeat: no-repeat;
    background-size: cover;
`;

export const ItemHeaderOrama = styled(DivDisplayFlex)`
    align-items: ${props => props.align ? 'flex-start' : 'center'};
    height: ${props => props.height};
    width: ${props => props.width};
    justify-content: ${props => props.justify};

    p{
        color: white;
        font-weight: bold;
        font-size: 16px;
    }

    #hourDate{
        color: white;
        font-weight: 300;
        font-size: 12px;
    }

`;


export const ContainerOramaHeader = styled(DivDisplayFlex)`
    height: ${props => props.height};
    width: ${props => props.width};
    background: rgb(0,124,35);
    align-items: flex-end;
    justify-content: center;

    p{
        color: white;
        font-weight: 300;
        font-size: 14px;
    }

`;

export const ContainerFooterOrama = styled.div`
    display: flex;
    flex-direction: column;
    height: 220px;
`;

export const ConteinerOtherPage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 270px;
`;

export const FooterOtherPage = styled.div`
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 95%;
`;
