import { HistoryActionTypes } from './actionTypes';

export const setRows = rows => ({
	type: HistoryActionTypes.SET_ROWS,
	payload: { rows },
});

export const updateFormValues = (field, value) => ({
	type: HistoryActionTypes.UPDATE_FORM_VALUES,
	payload: { field, value },
});

export const clearFormValues = () => ({
	type: HistoryActionTypes.CLEAR_FORM_VALUES,
});

export const updatePaginationInfo = paginationInfo => ({
	type: HistoryActionTypes.UPDATE_PAGINATION_INFO,
	payload: { paginationInfo },
});

export const updateUserPreferences = userPreferences => ({
	type: HistoryActionTypes.UPDATE_USER_PREFERENCES,
	payload: { userPreferences },
});
