import { validationPortBalance } from 'utils/validatePortBalance';

const portbalanceClear = async (bullet, setAccessorInfosOnCustomParams, account) => {
	const hash = { ...bullet.content };

	const execPortBalance = await validationPortBalance(bullet.content.CustomParameters.SymbolList, bullet.content.CustomParameters.InitialPosList, bullet.content.CustomParameters.DesiredPosPercList);

	if (execPortBalance) {
		return;
	};

	// setando informações de acessor
	hash.CustomParameters = setAccessorInfosOnCustomParams(hash.CustomParameters);
	hash.CustomParameters = {
		...hash.CustomParameters, 
		AllocAccount: account()
	};

	delete hash.signature;
	delete hash.clean;
	delete hash.StrategyLegs;

	return hash;
};

export default portbalanceClear;
