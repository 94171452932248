import { idxLiveChartsActionTypes } from './actionTypes';

export const setFilterStrategies = ({ id, filters }) => ({
	type: idxLiveChartsActionTypes.SET_STRATEGIES,
	payload: { id, filters },
});

export const updateGradientStop = gradientStop => ({
	type: idxLiveChartsActionTypes.UPDATE_GRADIENT_STOP,
	payload: { gradientStop },
});

export const setVisibleRange = (id, visibleRange) => ({
	type: idxLiveChartsActionTypes.SET_VISIBLE_RANGE,
	payload: { id, visibleRange },
});

export const setPriceRange = (id, priceRange) => ({
	type: idxLiveChartsActionTypes.SET_PRICE_RANGE,
	payload: { id, priceRange },
});

export const setChartData = (id, chartData) => ({
	type: idxLiveChartsActionTypes.SET_CHART_DATA,
	payload: { id, chartData },
});

export const setScrollPosition = (id, scrollPosition) => ({
	type: idxLiveChartsActionTypes.SET_SCROLL_POSITION,
	payload: { id, scrollPosition },
});

export const setToggleAsset = (asset, toggleAsset) => ({
	type: idxLiveChartsActionTypes.SET_TOGGLE_ASSET,
	payload: { asset, toggleAsset },
});

export const setToggleCount = count => ({
	type: idxLiveChartsActionTypes.SET_TOGGLE_COUNT,
	payload: { count },
});

export const resetIdxChartState = () => ({
	type: idxLiveChartsActionTypes.RESET_IDX_CHART_STATE,
});
