import React from 'react';
import Iceberg from '../../MaterialParticipatory/Iceberg';

const IcebergBodyBullet = props => {
	const { bullet, setPaperInfo, handleChange, suggestions, onSuggestionsFetchRequested, stepFunction } = props;

	return (
		<Iceberg
			subUid={bullet.subUid}
			setPaperInfo={setPaperInfo}
			handleChange={handleChange}
			suggestions={suggestions}
			onSuggestionsFetchRequested={onSuggestionsFetchRequested}
			stepFunction={stepFunction}
		/>
	);
};

export default IcebergBodyBullet;
