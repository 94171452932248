import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;

	.ant-empty-img-1 {
		fill: #262626;
	}

	.ant-empty-img-2 {
		fill: #595959;
	}

	.ant-empty-img-3 {
		fill: #434343;
	}

	.ant-empty-img-4 {
		fill: #1c1c1c;
	}

	.ant-empty-img-5 {
		fill-opacity: 0.08;
		fill: #fff;
	}
`;
