import React from 'react';
import { useHistory } from 'react-router-dom';

import {
	Button,
	Grid,
	Typography,
	colors,
	useMediaQuery,
	useTheme,
} from '@mui/material';

import { PlanetSvg } from 'assets/svg/planet';

import backgroundImage from 'assets/images/404-bg.png';
import logo from 'assets/images/flexscan_logo.png';

export const NotFound = () => {
	const history = useHistory();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

	const isMobile = isSmallScreen || isExtraSmallScreen;

	return (
		<Grid
			container
			flexDirection={isMobile ? 'column' : 'row'}
			sx={{
				height: '100%',
				backgroundImage: `url(${backgroundImage})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		>
			<Grid
				item
				xs={!isMobile && 5}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					gap: 2,
					p: 4,
					flexGrow: isSmallScreen ? 1 : 0,
				}}
			>
				<img src={logo} width={192} />

				<Typography variant={isMobile ? 'h4' : 'h3'} fontWeight="bold" color="#fff">
					Página não encontrada!
				</Typography>

				<Typography fontSize="1.25rem" color="#fff">
					Parece que você está tentando acessar uma página que não
					existe.
				</Typography>

				<Button
					variant="outlined"
					sx={{ maxWidth: isMobile ? 'auto' : 200 }}
					onClick={() => history.push('/')}
				>
					Voltar
				</Button>
			</Grid>

			{!isMobile && (
				<Grid
					item
					xs={7}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: 2,
					}}
				>
					<Typography
						fontWeight="bold"
						fontSize={124}
						color={colors.blue[500]}
					>
						4
					</Typography>

					<PlanetSvg width={230} height={230} />

					<Typography
						fontWeight="bold"
						fontSize={124}
						color={colors.lightBlue[500]}
					>
						4
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};
