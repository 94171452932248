import { apiAWS } from 'services/api';
import { store } from 'store';
import { setGlobalSettings } from 'store/modules/panelConfig/actions';
import { globalSettingsInitialState } from 'store/modules/panelConfig/reducer';

export function postGlobalSettings(
	username,
	body,
	setIsLoading = () => {},
	handleCloseMenu = () => {}
) {
	setIsLoading(true);

	apiAWS
		.post(`/userpreferences/${username}?service=settings`, body)
		.then(() => getGlobalSettings(username))
		.catch(error => {
			console.log(error);
		})
		.finally(() => {
			setIsLoading(false);
			handleCloseMenu();
		});
}

export function getGlobalSettings(username) {
	apiAWS
		.get(`/userpreferences/${username}?service=settings`)
		.then(response => {
			delete response.data.user_name;
			delete response.data.service_name;
			delete response.data.undefined;

			const formattedSettings = {
				...globalSettingsInitialState,
				...response.data,
			};

			store.dispatch(setGlobalSettings(formattedSettings));
		})
		.catch(error => console.log(error));
}
