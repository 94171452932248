import { toast } from 'react-toastify';
import validatePrice from '../submitFunctions/validatePrice';

const fxorderEdit = (bullet, t) => {
	const hash = {
		StrategyCode: bullet.content.StrategyCode,
		EndTime: bullet.content.EndTime || bullet.values.EndTime,
		ClOrdID: bullet.content.ClOrdID,
	};
	if (bullet.content.CustomParameters.stop === false) {
		hash.CustomParameters = {
			StartPriceTrigger: bullet.content.CustomParameters.StartPriceTrigger,
			OrderQuantity: bullet.content.CustomParameters.OrderQuantity,
		};
	} else if (bullet.content.CustomParameters.StopType === 1) {
		hash.CustomParameters = {
			StopGain: bullet.content.CustomParameters.StopGain,
			StopLoss: bullet.content.CustomParameters.StopLoss,
		};
	} else if (bullet.content.CustomParameters.StopType === 2) {
		hash.CustomParameters = {
			StopLoss: bullet.content.CustomParameters.StopLoss,
		};
	} else if (bullet.content.CustomParameters.StopType === 3) {
		if (bullet.content.CustomParameters.StopGainL3) {
			hash.CustomParameters = {
				StopLoss: bullet.content.CustomParameters.StopLoss,
				StopGainL1: bullet.content.CustomParameters.StopGainL1,
				StopGainL2: bullet.content.CustomParameters.StopGainL2,
				StopGainL3: bullet.content.CustomParameters.StopGainL3,
				OrderQuantityL1: bullet.content.CustomParameters.OrderQuantityL1,
				OrderQuantityL2: bullet.content.CustomParameters.OrderQuantityL2,
				OrderQuantityL3: bullet.content.CustomParameters.OrderQuantityL3,
			};
		} else {
			hash.CustomParameters = {
				StopLoss: bullet.content.CustomParameters.StopLoss,
				StopGainL1: bullet.content.CustomParameters.StopGainL1,
				StopGainL2: bullet.content.CustomParameters.StopGainL2,
				OrderQuantityL1: bullet.content.CustomParameters.OrderQuantityL1,
				OrderQuantityL2: bullet.content.CustomParameters.OrderQuantityL2,
			};
		}
	}
	if (bullet.content.CustomParameters.CustomOrdType === 3) {
		delete hash.CustomParameters.EntranceExecPrice;
	} else {
		if (validatePrice(bullet.content.CustomParameters.PriceLimit) && bullet.subUid !== 2) {
			toast.error(t('O Preço limite precisa ser maior que 0'));
			return;
		}
		hash.CustomParameters.StartPriceTrigger = +bullet.content.CustomParameters.PriceLimit;
		hash.CustomParameters.OrderQuantity = bullet.content.CustomParameters.OrderQuantity;
	}
	if (bullet.subUid === 2) {
		delete hash.CustomParameters.StartPriceTrigger;
		delete hash.CustomParameters.OrderQuantity;
	}

	if (process.env.NODE_ENV === 'development') console.log('fxorder edit', hash);
	return hash;
};

export default fxorderEdit;
