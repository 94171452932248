import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

export function refreshUpdateAt() {
	return format(new Date(), 'dd \'de\' MMMM \'de\' yyyy \'às\' HH:mm', {
		locale: ptBR,
	});
}

export function formatExecutionsStrategies(strategies) {
	const formattedStrategies = [];

	strategies.forEach(strategy => {
		formattedStrategies.push({
			...strategy,
			id: strategy.ClOrdID,
			hierarchy: [strategy.ClOrdID],
			strategyCode: strategy.StrategyCode,
			TimeInForce: strategy.TimeInForce ?? '0',
		});

		strategy.Legs?.forEach(leg => {
			const formattedLegSide = leg.LegSide === '1' ? 'Compra' : 'Venda';

			formattedStrategies.push({
				...leg,
				id: leg.LegRefID,
				hierarchy: [strategy.ClOrdID, leg.LegRefID],
				Text: leg.LegMsg,
				CumQtyPerc: leg.LegCumQtyPerc,
				TargetQtty: leg.LegQuantity,
				ExecutedQtty: leg.LegExecQty,
				AvgPrice: leg.LegAvgPx,
				Accounts: leg.ILegAllocAccount,
				Name: `${leg.LegSymbol} - ${formattedLegSide}`,
				Status: strategy.OrdStatus ?? strategy.Status,
				StatusDate: leg.LegDatedDate,
			});
		});
	});

	return formattedStrategies;
}
