import styled from 'styled-components';
import themes from 'themes';

import { Grid } from '@material-ui/core';

const oramaColors = themes.orama.colors;

export const FormBox = styled(Grid)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	height: 500px;

	> h1 {
		font-weight: bold;
		color: ${oramaColors.green};
		margin-bottom: 40px;

		span {
			color: ${oramaColors.mediumGray};
		}
	}
`;

export const TitleBox = styled.div`
	width: 100%;
	height: auto;
	text-align: center;
	margin-bottom: 64px;

	> h3 {
		color: ${oramaColors.black};
		margin-bottom: 16px !important;
		font-weight: bold;
		font-size: 2.5rem !important;
	}

	> h4 {
		color: ${oramaColors.mediumGray};
		font-size: 20px !important;
		font-weight: bold;
	}
`;
