import React, { useState } from 'react';

import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { passwordRegex } from 'utils/passwordRegex';

import { PasswordInput } from 'components/PasswordInput';
import { PasswordValidationList } from 'components/PasswordValidationList';

export const NewPasswordForm = ({ handleSubmit, buttonLoading }) => {
	const [isVisible, setIsVisible] = useState({
		password: false,
		confirm_password: false,
	});

	const [credentials, setCredentials] = useState({
		password: '',
		password_confirmation: '',
	});

	const passwordsIsEquals =
		credentials.password === credentials.password_confirmation;

	const regex = passwordRegex(
		credentials.password,
		credentials.password_confirmation
	);

	function handlePassword(field, value) {
		setCredentials({ ...credentials, [field]: value });
	}

	function handleVisiblePassword(type) {
		setIsVisible({ ...isVisible, [type]: !isVisible[type] });
	}

	return (
		<form onSubmit={event => handleSubmit(event, credentials)}>
			<Grid
				container
				sx={{
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
					width: '100%',
					gap: 2,
					mt: 1,
				}}
			>
				<PasswordInput
					required
					label="Nova Senha"
					value={credentials.password}
					labelStyles={{ color: '#888888', textShadow: 'none' }}
					onChange={event =>
						handlePassword('password', event.target.value)
					}
					withoutAutofill
					isPasswordVisible={isVisible.password}
					changePasswordVisibility={() =>
						handleVisiblePassword('password')
					}
				/>

				<PasswordInput
					label="Confirme sua senha"
					value={credentials.password_confirmation}
					labelStyles={{ color: '#888888', textShadow: 'none' }}
					onChange={event =>
						handlePassword(
							'password_confirmation',
							event.target.value
						)
					}
					withoutAutofill
					isPasswordVisible={isVisible.confirm_password}
					changePasswordVisibility={() =>
						handleVisiblePassword('confirm_password')
					}
				/>

				<PasswordValidationList
					isPasswordsEqual={
						passwordsIsEquals && credentials.password !== ''
					}
					password={credentials.password}
					secondPassword={credentials.password_confirmation}
				/>

				<LoadingButton
					fullWidth
					type="submit"
					size="large"
					variant="contained"
					loading={buttonLoading}
					disabled={
						!(
							regex[0] &&
							regex[1] &&
							regex[2] &&
							regex[3] &&
							regex[4] &&
							!regex[5]
						) || !passwordsIsEquals
					}
				>
					Definir nova senha
				</LoadingButton>
			</Grid>
		</form>
	);
};
