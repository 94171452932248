import React, { useState } from 'react';

import { ExportMigrationDialog } from './ExportMigrationDialog';
import { ConfirmExportMigrationDialog } from './ConfirmExportMigrationDialog';
import { ImportMigrationDialog } from './ImportMigrationDialog';
import { ConfirmImportMigrationDialog } from './ConfirmImportMigrationDialog';
import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';

export const MigrationsFlows = ({ migrationStep, setMigrationStep }) => {
	const [userOnFlow, setUserOnFlow] = useState('');
	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'success',
		autoHide: null,
	});

	function handleSetUserOnFlow(user) {
		setUserOnFlow(user);
	}

	function handleChangeMigrationStep(step) {
		setMigrationStep(step);
	}

	return (
		<>
			{migrationStep === 'export-migration' && (
				<ExportMigrationDialog
					setSnackbarState={setSnackbarState}
					setUserToReceive={handleSetUserOnFlow}
					isOpen={migrationStep === 'export-migration'}
					handleChangeMigrationStep={handleChangeMigrationStep}
				/>
			)}

			{migrationStep === 'confirm-export-migration' && (
				<ConfirmExportMigrationDialog
					userToReceive={userOnFlow}
					setSnackbarState={setSnackbarState}
					isOpen={migrationStep === 'confirm-export-migration'}
					handleChangeMigrationStep={handleChangeMigrationStep}
				/>
			)}

			{migrationStep === 'import-migration' && (
				<ImportMigrationDialog
					setSnackbarState={setSnackbarState}
					setUserToCopyBy={handleSetUserOnFlow}
					isOpen={migrationStep === 'import-migration'}
					handleChangeMigrationStep={handleChangeMigrationStep}
				/>
			)}

			{migrationStep === 'confirm-import-migration' && (
				<ConfirmImportMigrationDialog
					isOpen={migrationStep === 'confirm-import-migration'}
					handleChangeMigrationStep={handleChangeMigrationStep}
					userToCopyBy={userOnFlow}
					setSnackbarState={setSnackbarState}
				/>
			)}

			<MaterialSnackbar
				open={snackbarState.isOpen}
				severity={snackbarState.severity}
				autoHide={snackbarState.autoHide}
				handleClose={() =>
					setSnackbarState(state => ({ ...state, isOpen: false }))
				}
			>
				{snackbarState.message}
			</MaterialSnackbar>
		</>
	);
};
