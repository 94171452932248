import React from 'react';

import { Box, Typography } from '@mui/material';
import { LightTooltip } from 'components/LightTooltip';

export const OutlinedLabel = ({
	label,
	variant = 'subtitle1',
	style = {},
	tooltip = label,
	color,
	placement = 'bottom',
	...rest
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				border: `1px solid ${color}`,
				padding: '1px 5px',
				borderRadius: 2,
				'&:hover': {
					cursor: 'pointer',
				},
				...style
			}}
		>
			<LightTooltip placement={placement} arrow title={tooltip}>
				<Typography
					variant={variant}
					color={color}
					{...rest}
				>
					{label}
				</Typography>
			</LightTooltip>
		</Box>
	);
};
