import { SET_SEARCH, SET_QUICKSETTINGS } from './types';

const inicitalState = {
	userSearch: {},
	quickSettings: {},
};

export default (initial = inicitalState, { type, payload }) => {
	switch (type) {
		case SET_SEARCH:
			return { ...initial, userSearch: payload.filters };
		case SET_QUICKSETTINGS:
			return { ...initial, quickSettings: payload.quickSettings };

		default:
			return initial;
	}
};
