import { apiMarketData } from './api';

function getDefinitionsUrl(symbolsList, fieldsList, detailed = false) {
	let url = '';
	let fieldsUrl = '';
	let symbolUrl = '';
	const symbolList = [];

	fieldsList.forEach((field, index) => {
		if (index === 0) {
			fieldsUrl += field;
		} else {
			fieldsUrl += `,${field}`;
		}
	});

	symbolsList.map(symbol => {
		if (!symbolList.includes(symbol)) {
			if (symbolList.length === 0) {
				symbolUrl += `&symbol=${symbol}`;
			} else {
				symbolUrl += `,${symbol}`;
			}

			symbolList.push(symbol);
		}

		symbol.positions?.map(position => {
			if (!symbolList.includes(position.symbol) && !position.expired) {
				symbolUrl += `,${position.symbol}`;
				symbolList.push(position.symbol);
			}
		});
	});

	url = fieldsUrl + symbolUrl;

	if (detailed) {
		url += '&detailed=1';
	}

	return url;
}

function createRequestsFromSymbolsList(symbolsList, fieldsList) {
	const requestsArray = [];
	const newArray = [];

	for (let i = 0; i < symbolsList.length; i += 40) {
		const newPart = symbolsList.slice(i, i + 40);
		newArray.push(newPart);
	}

	newArray.forEach(splicedSymbolList => {
		const definitionsUrl = getDefinitionsUrl(splicedSymbolList, fieldsList);

		requestsArray.push(
			apiMarketData.get(
				`/exchanges/BVMF/instruments?opt_fields=${definitionsUrl}`
			)
		);
	});

	return requestsArray;
}

export async function getDefinitions(symbolsList, fieldsList) {
	// symbolsList -> Array of paper symbols. Ex: ["PETR4", "VALE3"]
	// fieldsList -> Array of fields that you want. Ex: ["workDaysUntilMaturity", "securityType"]

	if (symbolsList && symbolsList.length > 0) {
		try {
			const requests = createRequestsFromSymbolsList(
				symbolsList,
				fieldsList
			);
			const responses = await Promise.all(requests);

			const recordsArray = [];

			responses.forEach(response =>
				recordsArray.push(...response.data.records)
			);

			return recordsArray;
		} catch (error) {
			console.log(error);
		}
	} else {
		return Promise.reject('Symbols list is empty');
	}
}
