import React, { useMemo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { simChart } from '@investflex/simchartjs/simChart';
import * as d3 from 'd3';
import usePrevState from '../usePrevState';
//import { getPaperFromStore, getStrategyFromStore } from '../utils';
import { getPaper, getStrategy, getStrategyForIflexquantChart } from '../utils';

const watermark =
	'https://iflex-repo.s3.amazonaws.com/assets/logo-fs-mdagua.png';

function Chart({ addedLeg }) {
	const ir = useSelector(state => state.simulator?.ir);
	const activeStrategy = useSelector(state => state.simulator.activeStrategy);
	const colorMode = useSelector(state => state.ui?.colorMode);
	const paper = useSelector(getPaper);

	const strategy = useSelector(getStrategy);
	const prevStrategy = usePrevState(strategy);

	const portifolio = useMemo(() => {
		if (activeStrategy === null) return null;
		return {
			strategies:
				activeStrategy !== null
					? [
						getStrategyForIflexquantChart(
							strategy,
							paper?.price,
							ir
						),
					  ]
					: [],
		};
	}, [activeStrategy, strategy]);

	const chart = useMemo(() => {
		return simChart();
	}, [activeStrategy, strategy?.underSymbol]);

	useEffect(() => {
		if (addedLeg === 1 && initChart) initChart(false);
	}, [addedLeg]);

	// monta o grafico na primeira vez
	useEffect(() => {
		if (activeStrategy === null || strategy.positions.length === 0) return;
		//console.log("### INIT CHART ##")
		initChart();
	}, [activeStrategy]);

	const initChart = useCallback(
		(resetChart = true) => {
			chart
				.refreshInterval(1000)
				.ir(ir)
				.underPx(paper?.price || 0)
				.pricingModel('BLACKSCHOLES')
				.portfolio(portifolio)
				.xAxisPoints(100)
				.locale('pt')
				.lang('pt')
				.currencySymbol('R$')
				.showStockChart(false)
				.theme(colorMode === 'dark' ? 'theme-dark' : 'theme-light')
				.watermark(`url(${watermark}) no-repeat center `)
				// .enableModeIcon(true)
				.enableCenterIcon(true)
				.enablePercentSwitch(true)
				.enableControlPanel(true)
				.debug(false);

			let simChart = document.getElementById('sim-chart');
			// tratamento para casos de troca de tela e o cursor está no componente de pesquisa de papel e o onBlur é acionado em uma troca de tela,
			if (simChart && resetChart) {
				simChart.innerHTML = '';
				d3.select('#sim-chart').call(chart);
			}
		},
		[portifolio, paper?.price, chart, ir, activeStrategy]
	);

	// atualiza o portifólio do grafico
	useEffect(() => {
		if (!prevStrategy) return;
		if (activeStrategy === null || strategy.positions.length === 0) return;
		let change = false;

		if (strategy?.positions?.length !== prevStrategy?.positions?.length) {
			change = true;
		}
		if (strategy?.positions && prevStrategy?.positions) {
			strategy.positions.forEach((position, index) => {
				const prevPosition =
					prevStrategy.positions.length >= 0
						? prevStrategy.positions[index]
						: {};
				// verifica se o optionType, qtty, ou strike mudaram
				let hasChanged =
					position?.optionType !== prevPosition?.optionType ||
					position?.qtty !== prevPosition?.qtty ||
					position.strikePx !== prevPosition.strikePx ||
					position.checked !== prevPosition.checked ||
					position.entryPxLocked !== prevPosition.entryPxLocked ||
					position.exitPxLocked !== prevPosition.exitPxLocked;
				if (hasChanged) {
					change = true;
					return;
				}
			});
		}
		if (!change) return;
		chart.portfolio(portifolio);
	}, [strategy, activeStrategy]);

	// atualiza valor do papel no grafico
	useEffect(() => {
		if (activeStrategy === null || strategy.positions.length === 0) return;
		chart.underPx(paper?.price);
	}, [paper?.price]);

	// Alteração de dados/cotações da estratégia:
	useEffect(() => {
		if (activeStrategy === null || strategy.positions.length === 0) {
			if (chart) initChart();
			return;
		}
		if (!prevStrategy) return;
		let change = false;

		if (strategy?.positions && prevStrategy?.positions) {
			strategy.positions.forEach((position, index) => {
				const prevPosition =
					prevStrategy.positions.length >= 0
						? prevStrategy.positions[index]
						: {};
				// verifica se o optionType, qtty, ou strike mudaram
				let hasChanged =
					position?.exitPx !== prevPosition?.exitPx ||
					position?.entryPx !== prevPosition?.entryPx ||
					(!position?.theorPx && !!prevPosition?.theorPx) ||
					(!!position?.theorPx && !prevPosition?.theorPx) ||
					Math.abs(
						(position?.theorPx - prevPosition?.theorPx) /
							prevPosition?.theorPx
					) > 0.03 ||
					(!position?.exitImpliedVol &&
						!!prevPosition?.exitImpliedVol) ||
					(!position?.entryImpliedVol &&
						!!prevPosition?.entryImpliedVol) ||
					(!position?.theorImpliedVol &&
						!!prevPosition?.theorImpliedVol) ||
					(!!position?.exitImpliedVol &&
						!prevPosition?.exitImpliedVol) ||
					(!!position?.entryImpliedVol &&
						!prevPosition?.entryImpliedVol) ||
					(!!position?.theorImpliedVol &&
						!prevPosition?.theorImpliedVol) ||
					Math.abs(
						(position?.exitImpliedVol -
							prevPosition?.exitImpliedVol) /
							prevPosition?.exitImpliedVol
					) > 0.03 ||
					Math.abs(
						(position?.entryImpliedVol -
							prevPosition?.entryImpliedVol) /
							prevPosition?.entryImpliedVol
					) > 0.03 ||
					Math.abs(
						(position?.theorImpliedVol -
							prevPosition?.theorImpliedVol) /
							prevPosition?.theorImpliedVol
					) > 0.03;

				//if (position?.exitPx !== prevPosition?.exitPx) console.log("CHART 5 changed exitPx " + position?.exitPx + " - " + prevPosition?.exitPx);
				//if (position?.entryPx !== prevPosition?.entryPx) console.log("CHART 5 changed entryPx " + position?.entryPx + " - " + prevPosition?.entryPx);
				//if (position?.theorPx !== prevPosition?.theorPx) console.log("CHART 5 changed theorPx " + position?.theorPx + " - " + prevPosition?.theorPx + " - "+Math.abs( (position?.theorPx - prevPosition?.theorPx)/prevPosition?.theorPx ));
				//if ( position?.exitImpliedVol !== prevPosition?.exitImpliedVol) console.log("CHART 5 changed exitImpliedVol " + position?.exitImpliedVol + " - " + prevPosition?.exitImpliedVol + " - "+Math.abs( (position?.exitImpliedVol - prevPosition?.exitImpliedVol)/prevPosition?.exitImpliedVol ));
				//if (position?.entryImpliedVol !== prevPosition?.entryImpliedVol) console.log("CHART 5 changed entryImpliedVol " + position?.entryImpliedVol + " - " + prevPosition?.entryImpliedVol + " - "+Math.abs( (position?.entryImpliedVol - prevPosition?.entryImpliedVol)/prevPosition?.entryImpliedVol ));
				//if (position?.theorImpliedVol !== prevPosition?.theorImpliedVol) console.log("CHART 5 changed theorImpliedVol " + position?.theorImpliedVol + " - " + prevPosition?.theorImpliedVol + " - "+Math.abs( (position?.theorImpliedVol - prevPosition?.theorImpliedVol)/prevPosition?.theorImpliedVol ));
				if (hasChanged) {
					change = true;
					return;
				}
			});
		}
		if (!change) return;
		chart.portfolioUpdate(portifolio);
	}, [strategy?.positions]);

	useEffect(() => {
		if (activeStrategy === null || strategy.positions.length === 0) return;
		chart.portfolioUpdate(portifolio);
	}, [ir]);

	return (
		<div className="chart-container">
			<div className="cell-lg" id="sim-chart">
				<div />
			</div>
		</div>
	);
}

export default Chart;
