export const colorByStatus = status => {
	switch (parseInt(status)) {
		case 0: // NOVA
			return '#3FD293';
		case 1: // AGUARDANDO RESPOSTA
			return '#c54fff';
		case 2: // AGENDADA
			return '#80cbc4';
		case 3: // EXECUTANDO
			return '#3FD293';
		case 4: // UNBALANCED
			return '#a2a2ff';
		case 5: // EXECUTADA
			return '#4891F2';
		case 6: // CANCELANDO
			return '#FFCB00';
		case 7: // CANCELADA
			return '#FFCB00';
		case 8: // Suspendendo
			return '#CCCCCC';
		case 9: // SUSPENSA
			return '#CCCCCC';
		case 10: // INICIANDO
			return '#00CA72';
		case 99:
			return '#c54fff';
		default:
	}
};

export const statusName = status => {
	// eslint-disable-next-line default-case
	switch (parseInt(status)) {
		case 0: // NOVA
			return 'Nova';
		case 1: // AGUARDANDO RESPOSTA
			return 'Pendente';
		case 2: // AGENDADA
			return 'Agendada';
		case 3: // EXECUTANDO
			return 'Executando';
		case 4: // UNBALANCED
			return 'Unbalanced';
		case 5: // EXECUTADA
			return 'Finalizada';
		case 6: // CANCELANDO
			return 'Cancelando';
		case 7: // CANCELADA
			return 'Cancelada';
		case 8: // Suspendendo
			return 'Suspendendo';
		case 9: // SUSPENSA
			return 'Suspensa';
		case 10: // INICIANDO
			return 'Iniciando';
		case 99:
			return 'Pendente';
	}
};

export const statusId = status => {
	switch (status) {
		case 'Nova':
			return 0;
		case 'Agendada':
			return 2;
		case 'Executando':
			return 3;
		case 'Unbalanced':
			return 4;
		case 'Finalizada':
			return 5;
		case 'Cancelando':
			return 6;
		case 'Cancelada':
			return 7;
		case 'Suspendendo':
			return 8;
		case 'Suspensa':
			return 9;
		case 'Iniciando':
			return 10;
		case 'Aguardando resposta':
			return 99;
		default:
			return status;
	}
};

export const statusMapping = {
	0: { label: 'Nova', color: '#3FD293' },
	1: { label: 'Pendente', color: '#c54fff' },
	2: { label: 'Agendada', color: '#80cbc4' },
	3: { label: 'Executando', color: '#3FD293' },
	4: { label: 'Desbalanceada', color: '#a2a2ff' },
	5: { label: 'Finalizada', color: '#4891F2' },
	6: { label: 'Cancelando', color: '#FFCB00' },
	7: { label: 'Cancelada', color: '#FFCB00' },
	8: { label: 'Suspendendo', color: '#CCCCCC' },
	9: { label: 'Suspensa', color: '#CCCCCC' },
	10: { label: 'Iniciando', color: '#00CA72' },
	99: { label: 'Pendente', color: '#c54fff' },
};
