export const strategyBulletParsed = async (clordid, positions, api) => {
	let str = null;

	str = positions.find((str) => str?.ClOrdID === clordid);

	if(str){
		if (!str.StrategyLegs) {
			str.StrategyLegs = str.Legs;
		}

		if (str.CustomParameters && typeof str.CustomParameters !== 'object') {
			str.CustomParameters = JSON.parse(str.CustomParameters);
		}

		let strategyCode = str.StrategyCode;
		if (
			strategyCode === 'grdlin' ||
			strategyCode === 'grddin' ||
			str.TargetStrategy === 'grdlin' ||
			str.TargetStrategy === 'grddin' ||
			str.TargetStrategy == 1032
		) {
			str.StrategyLegs[0] = str.StrategyLegs.filter((leg) => leg.LegRefID[leg.LegRefID.length - 1] === '1')[0];
		}

		if (api !== '') {
			if (!strategyCode && str.ClOrdID) {
				strategyCode = (await api.get(`/strategycode/${str.ClOrdID}`)).data.strategyCode;
			}
		}

		// str.TargetStrategy = strategyCode;
		str.StrategyCode = strategyCode;
	}



	return str;
};
