import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useGridApiRef } from '@mui/x-data-grid-pro';
import { Box, Divider, Grid, LinearProgress, Typography } from '@mui/material';

import { apiAWS } from 'services/api';
import { riskColumns } from 'utils/tablesColumns/risk';
import { postRiskPreferences } from 'services/userPreferences/risk';
import { setRiskRows, updateRiskColumnsPreferences, updateRiskUserPreferences } from 'store/modules/Risk/actions';

import { StyledDataGridPro } from 'components/Portfolio/styles';
import { NoRowsOverlay } from 'components/Portfolio/NoRowsOverlay';
import { HistoryToolbar } from 'pages/Strategies/History/HistoryToolbar';

export const Risk = () => {
	document.title = 'Limites Operacionais | Flexscan';

	const apiRef = useGridApiRef();
	const dispatch = useDispatch();
	const history = useHistory();

	const { rows, tablePreferences } = useSelector(state => state.risk);
	const { username, riskEnabled, profile } = useSelector(state => state.auth);

	useEffect(() => {
		// Evitando que o usuário não autorizado, acesse a rota, através da url diretamente
		if (Number(profile) !== 4 && riskEnabled) {
			apiAWS.get('/riskrules')
				.then(response => dispatch(setRiskRows(response.data.records)))
				.catch(error => console.error(error));
		} else {
			history.push('/dashboard');
		}
	}, []);

	function onTableStateChange(event) {
		if (event.density.value !== tablePreferences.density) {
			const density = { density: event.density.value };

			dispatch(updateRiskUserPreferences(density));
			postRiskPreferences(username, density);
		}
	}

	function onColumnVisibilityModelChange(event) {
		dispatch(updateRiskColumnsPreferences({ columnVisibilityModel: { ...event } }));
		postRiskPreferences(username, { columnVisibilityModel: { ...event } });
	}

	function onColumnOrderChange() {
		const columnsOrder = apiRef.current.getAllColumns().map(column => column.field);
		dispatch(updateRiskColumnsPreferences({ orderedFields: [...columnsOrder] }));
		postRiskPreferences(username, { orderedFields: [...columnsOrder] });
	}

	function onFilterModelChange(event) {
		dispatch(updateRiskUserPreferences({ filter: { filterModel: { ...event } } }));
		postRiskPreferences(username, { tableFilter: { filterModel: { ...event } } });
	}

	function onSortModelChange(event) {
		const sorting = { sorting: { sortModel: [...event] } };
		dispatch(updateRiskUserPreferences(sorting));
		postRiskPreferences(username, sorting);
	}

	return (
		<>
			<Grid
				container
				sx={{
					height: '100%',
					flexDirection: 'column',
					pt: 1
				}}
			>
				<Typography variant="h5" sx={{ ml: 1 }}>Limites Operacionais</Typography>

				<Divider sx={{ m: 1 }} />

				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexGrow: 1,
					}}
				>
					<StyledDataGridPro
						rows={rows}
						apiRef={apiRef}
						columns={riskColumns}
						components={{ Toolbar: HistoryToolbar, LoadingOverlay: LinearProgress, NoRowsOverlay: () => NoRowsOverlay('Nenhum limite configurado') }}
						onStateChange={(event) => onTableStateChange(event)}
						onSortModelChange={(event) => onSortModelChange(event)}
						onColumnOrderChange={(event) => onColumnOrderChange(event)}
						onFilterModelChange={(event) => onFilterModelChange(event)}
						onColumnVisibilityModelChange={(event) => onColumnVisibilityModelChange(event)}
						initialState={{
							columns: tablePreferences?.columns,
							sorting: tablePreferences?.sorting,
							filter: tablePreferences?.filter,
						}}
					/>
				</Box>
			</Grid>
		</>
	);
};
