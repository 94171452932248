const cleanSorderMarket = (hash, account, bullet) => {
	const cleanLeg = {
		ILegAllocAccount: account(),
		LegQuantity: parseInt(hash.StrategyLegs[0].LegQuantity, 10),
		LegSecurityExchange: hash.StrategyLegs[0].LegSecurityExchange
			? hash.StrategyLegs[0].LegSecurityExchange
			: bullet.StrategyInfos[0].subExchange
				? bullet.StrategyInfos[0].subExchange
				: 'XBSP',
		LegSide: hash.StrategyLegs[0].LegSide,
		LegSymbol: hash.StrategyLegs[0].LegSymbol,
		LegOrdType: bullet.StrategyInfos[0].subExchange === 'XBSP' ? '1' : 'K',
	};
	return cleanLeg;
};

export default cleanSorderMarket;
