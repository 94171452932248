import styled from 'styled-components';

import { Checkbox, TextField } from '@material-ui/core';
import themes from '../../../themes';

const oramaColors = themes.orama.colors;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 500px;

	> h1 {
		font-weight: bold;
		color: ${oramaColors.green};
		margin-bottom: 40px;

		span {
			color: ${oramaColors.mediumGray};
		}
	}

	> h4 {
		font-weight: bold;
		text-align: center;
		font-size: 28px;
		color: ${oramaColors.darkGray};
	}

	> h5 {
		color: ${oramaColors.mediumGray};
		margin: 16px 0 32px 0;
	}
`;

export const FormBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	min-width: 350px;
	max-width: 450px;
	padding: 32px 20px;
	background-color: #ffffff;
	box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
	border-radius: 10px;

	> a {
		color: ${oramaColors.darkGray};
		text-decoration: underline;
		font-size: 14px;
		margin: 20px 0;
		font-weight: 500;
		font-family: 'Encode Sans';
	}
`;

export const InputBox = styled.div`
	width: 100%;
	height: auto;
	p {
		margin-left: 8px;
		color: ${oramaColors.red};
	}
`;

export const StyledInput = styled(TextField)`
	width: 100%;
	border-radius: 10px !important;
	border: ${props => (props.inputError ? `1px solid ${oramaColors.red}` : `1px solid ${oramaColors.mediumGray}`)} !important;
	&:focus-within {
		border: none !important;
		.MuiFormLabel-filled {
			display: block !important;
		}
		.MuiInputLabel-outlined {
			top: 0px !important;
		}
	}
	label {
		color: ${oramaColors.darkGray} !important;
		text-shadow: none !important;
		font-family: 'Encode Sans' !important;
	}
	input {
		font-size: 1rem !important;
		color: ${oramaColors.darkGray} !important;
		font-family: 'Encode Sans' !important;
	}
	button {
		color: ${oramaColors.darkGray};
	}
	svg {
		color: ${oramaColors.mediumGray};
	}
	.MuiFormControl-root .MuiTextField-root {
		border-color: ${oramaColors.green} !important;
	}
	.MuiOutlinedInput-root {
		box-shadow: none !important;
		background-color: transparent !important;
	}
	.Mui-focused {
		color: ${oramaColors.green} !important;
		font-size: 12px !important;
		font-weight: bold;
	}
	.MuiOutlinedInput-notchedOutline:focus {
		border-radius: 10px !important;
		border: 2px solid ${oramaColors.green} !important;
	}
	.Mui-focused fieldset {
		border-radius: 10px !important;
		border-color: ${oramaColors.green} !important;
	}
	.MuiFormLabel-filled {
		display: none !important;
		top: -6px !important;
	}
`;

export const RulesBox = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;

	width: 100%;
    margin: 32px 0;

	> p {
		color: ${oramaColors.red};
		font-family: 'Encode Sans';
	}
`;

export const Rules = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-start;

	width: 100%;
	min-height: 70px;
	background-color: #f7f8fa;
	border-radius: 10px;
	border: ${props => (props.error ? `1px solid ${oramaColors.red}` : 'none')};

	> h6 {
		align-self: center;
		color: ${oramaColors.darkGray} !important;
		font-family: 'Encode Sans';

		span {
			font-weight: bold;
			color: ${oramaColors.green} !important;
			text-decoration: underline;
			font-family: 'Encode Sans';
			cursor: pointer;
		}
	}

	> span {
		margin-top: 8px !important;
	}
`;

export const ButtonsBox = styled.div`
	display: flex;
    justify-content: space-between;

    width: 100%;
`;

export const StyledCheckbox = styled(Checkbox)`
	color: ${props => (props.checked ? oramaColors.green : oramaColors.mediumGray)} !important;
`;
