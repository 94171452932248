import React from 'react';

import { Dialog, Button } from '@mui/material';

import { StyledColumnsPanel } from './styles';

export const ColumnsPanel = ({ open, handleClose }) => {
	return (
		<Dialog open={open}>
			<StyledColumnsPanel />

			<Button onClick={handleClose}>
				Fechar
			</Button>
		</Dialog>
	);
};
