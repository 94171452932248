import { toast } from 'react-toastify';
import { store } from 'store';
import { updateConfig } from 'store/modules/panelConfig/actions';
import { widgetAddSymbolQuotation } from 'store/modules/widgets/actions';

const addWidgetFromButton = symbol => {
	const widgets = store.getState().widgets.widgets;

	if (
		widgets.some(widget => {
			return widget.content.symbol === symbol;
		})
	) {
		return toast.error('Já existe um book com este ativo');
	} else {
		const widgetBody = {
			id: Math.random(),
			symbol,
			expanded: true,
			type: 'BookTrade',
		};
		store.dispatch(updateConfig('widgetBar', true));
		store.dispatch(widgetAddSymbolQuotation(widgetBody));
	}
};

export default addWidgetFromButton;
