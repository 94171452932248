import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Box,
	Chip,
	Divider,
	IconButton,
	TextField,
	Typography,
	colors,
} from '@mui/material';
import { Edit } from '@mui/icons-material';

import { apiExec } from 'services/api';
import { setFavorite2FA } from 'store/modules/auth/actions';

import { AuthAppConfiguration } from './AuthAppConfiguration';
import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';

export const AuthenticationSettings = () => {
	const dispatch = useDispatch();

	const { email, twoFactorAuthentication, username } = useSelector(
		state => state.auth
	);

	const [isAuthAppModalOpen, setIsAuthAppModalOpen] = useState(false);
	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'error',
	});

	function handleOpenAuthAppModal() {
		setIsAuthAppModalOpen(true);
	}

	function handleCloseAuthAppModal() {
		setIsAuthAppModalOpen(false);
	}

	function handleChangeDefaultMethod(event) {
		const newMethod = event.target.value;

		const body = { activeAuthenticatorType: newMethod };

		apiExec
			.put(`/users/${username}`, body)
			.then(() => dispatch(setFavorite2FA(newMethod)))
			.catch(error => {
				console.error(error);
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					message: 'Algo deu errado, tente novamente mais tarde.',
					severity: 'error',
				}));
			});
	}

	function isMethodConfigured(type) {
		return twoFactorAuthentication?.registeredMethods?.some(
			method => method.authenticatorType === type && method.verified
		);
	}

	const hasAuthAppConfigured = isMethodConfigured('app');
	const hasAuthEmailConfigured = isMethodConfigured('email');

	const authMethods = [
		{
			value: 'app',
			label: 'Aplicativo',
			isConfigured: hasAuthAppConfigured,
		},
		{
			value: 'email',
			label: 'E-mail',
			isConfigured: hasAuthEmailConfigured,
		},
	];

	const userAuthMethodsOptions = authMethods.filter(
		method => method.isConfigured
	);

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
				}}
			>
				<Typography variant="h6">
					Autenticação de dois fatores
				</Typography>

				<Box>
					<Typography variant="subtitle1" sx={{ ml: 0.25, mb: 0.5 }}>
						Método 2FA preferido
					</Typography>

					<TextField
						select
						size="small"
						SelectProps={{ native: true }}
						sx={{ width: '50%' }}
						value={twoFactorAuthentication.favoriteMethod}
						onChange={event => handleChangeDefaultMethod(event)}
						disabled={userAuthMethodsOptions.length <= 1}
					>
						{userAuthMethodsOptions.map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</TextField>
				</Box>

				<Divider />

				<Box sx={{ width: '100%' }}>
					<Typography variant="h6">Métodos 2FA</Typography>

					<Box
						sx={{
							width: '100%',
							gap: 0.25,
							display: 'flex',
							flexDirection: 'column',
							mt: 1,
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								width: '100%',
							}}
						>
							<Typography variant="subtitle1">
								Autenticação por aplicativo
							</Typography>

							<Chip
								size="small"
								variant="outlined"
								color={
									hasAuthAppConfigured ? 'success' : 'warning'
								}
								label={
									hasAuthAppConfigured
										? 'Configurado'
										: 'Não configurado'
								}
							/>
						</Box>

						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								width: '100%',
							}}
						>
							<Box sx={{ width: '75%' }}>
								<Typography
									variant="body1"
									color={colors.grey[500]}
								>
									Use um aplicativo de autenticação para obter
									códigos de autenticação de dois fatores
									quando solicitado.
								</Typography>
							</Box>

							<IconButton onClick={handleOpenAuthAppModal}>
								<Edit />
							</IconButton>
						</Box>
					</Box>
				</Box>

				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Typography variant="subtitle1" sx={{ ml: 0.25, mb: 0.5 }}>
						E-mail
					</Typography>

					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<TextField
							size="small"
							InputProps={{ readOnly: true }}
							value={email}
							sx={{ width: '70%' }}
						/>

						<Chip
							size="small"
							variant="outlined"
							color={
								hasAuthEmailConfigured ? 'success' : 'warning'
							}
							label={
								hasAuthEmailConfigured
									? 'Configurado'
									: 'Não configurado'
							}
						/>
					</Box>
				</Box>
			</Box>

			<MaterialSnackbar
				open={snackbarState.isOpen}
				severity={snackbarState.severity ?? 'error'}
				handleClose={() =>
					setSnackbarState(state => ({
						...state,
						isOpen: false,
					}))
				}
			>
				{snackbarState.message}
			</MaterialSnackbar>

			{isAuthAppModalOpen && (
				<AuthAppConfiguration
					favoriteMethod={twoFactorAuthentication.favoriteMethod}
					isOpen={isAuthAppModalOpen}
					snackbarState={snackbarState}
					parentSetSnackbarState={setSnackbarState}
					handleClose={handleCloseAuthAppModal}
				/>
			)}
		</>
	);
};
