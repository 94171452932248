const longshortEdit = bullet => {
	const hashLegs = [];
	bullet.content.StrategyLegs.forEach((legs) => {
		hashLegs.push({
			LegRefID: legs.LegRefID,
			LegQuantity: legs.LegQuantity,
			LegPrice: legs.LegPrice,
			LegResting: legs.LegResting,
			LegMaxClipSize: legs.LegMaxClipSize,
			LegFirstTimeOut: legs.LegFirstTimeOut,
		});
	});
	const hash = {
		StrategyCode: bullet.content.StrategyCode,
		CustomParameters: {
			TriggerValue: bullet.content.CustomParameters.TriggerValue,
			IgnoreOffersLT: bullet.content.CustomParameters.IgnoreOffersLT,
			BookDepth: bullet.content.CustomParameters.BookDepth,
		},
		StrategyLegs: hashLegs,
		ClOrdID: bullet.content.ClOrdID,
		EndTime: bullet.content.EndTime || bullet.values.EndTime,
	};
	if (bullet.content.CustomParameters.ExecutionType == 1) {
		hash.CustomParameters.ToMarketTriggerValue =
			bullet.content.CustomParameters.ToMarketTriggerValue;
	}
	if (bullet.content.CustomParameters.BandPriceRef === 'Y') {
		hash.CustomParameters.BandPriceLow =
			bullet.content.CustomParameters.BandPriceLow;
		hash.CustomParameters.BandPriceHigh =
			bullet.content.CustomParameters.BandPriceHigh;
	}
	if (process.env.NODE_ENV === 'development') console.log('hash', hash);
	return hash;
};

export default longshortEdit;
