import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	useGridApiContext
} from '@mui/x-data-grid-pro';
import { IconButton } from '@mui/material';
import { ChevronRight, Delete, KeyboardArrowDown, Save } from '@mui/icons-material';
import { Flask } from 'phosphor-react';

import { store } from 'store';
import { handleSaveStrategy } from 'utils/handleSaveStrategy';
import { updateDeletionData } from 'store/modules/portfolio/actions';
import { handleSimulateStrategy } from 'utils/Portfolio/handleSimulateStrategy';

import { LightTooltip } from 'components/LightTooltip';

export const CustomDataTree = (props) => {
	const {
		id,
		field,
		row,
		rowNode,
		isLoading,
		openDeletionModal,
	} = props;

	const dispatch = useDispatch();
	const apiRef = useGridApiContext();

	const papers = useSelector(state => state.cache?.paper);

	const isNavigationKey = (key) =>
		key === 'Home' ||
		key === 'End' ||
		key.indexOf('Arrow') === 0 ||
		key.indexOf('Page') === 0 ||
		key === ' ';

	const handleKeyDown = (event) => {
		if (event.key === ' ') {
			event.stopPropagation();
		}

		if (isNavigationKey(event.key) && !event.shiftKey) {
			apiRef.current.publishEvent('cellNavigationKeyDown', props, event);
		}
	};

	const handleClick = (event) => {
		apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
		apiRef.current.setCellFocus(id, field);
		event.stopPropagation();
	};

	function handleSave(row) {
		const paper = papers[row.underSymbol];

		const simulatorStrategies = store.getState().simulator.strategyList;
		const strategyToSave = simulatorStrategies.find(strategy => strategy.id === row.id);

		handleSaveStrategy(strategyToSave, paper);
	}

	function handleDelete(row) {
		dispatch(updateDeletionData({ strategy: row }));
		openDeletionModal();
	}

	return (
		<>
			{row.hierarchy.length === 1 && (
				<>
					<LightTooltip arrow title={rowNode.childrenExpanded ? 'Fechar' : 'Expandir'}>
						<IconButton
							disabled={isLoading}
							tabIndex={-1}
							className="iconButton"
							onClick={handleClick}
							onKeyDown={handleKeyDown}
						>
							{rowNode.childrenExpanded ? <KeyboardArrowDown /> : <ChevronRight />}
						</IconButton>
					</LightTooltip>

					<LightTooltip arrow title="Salvar alterações">
						<IconButton
							disabled={isLoading || row.saved}
							onClick={() => handleSave(row)}
						>
							<Save style={{ color: isLoading || row.saved ? '#777777' : '#ffffff' }} />
						</IconButton>
					</LightTooltip>

					<LightTooltip arrow title="Apagar">
						<IconButton
							disabled={isLoading}
							className="iconButton"
							onClick={() => handleDelete(row)}
						>
							<Delete style={{ color: isLoading ? '#777777' : '#ffffff' }} />
						</IconButton>
					</LightTooltip>

					<LightTooltip arrow title="Simular">
						<IconButton
							disabled={isLoading}
							className="iconButton"
							onClick={() => handleSimulateStrategy(row)}
						>
							<Flask size={20} style={{ color: isLoading ? '#777777' : '#ffffff' }} />
						</IconButton>
					</LightTooltip>
				</>
			)}
		</>
	);
};
