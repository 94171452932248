const cleanSorderLeg = (hash, account, bullet) => {
	console.log('account', account);
	const cleanLeg = {
		ILegAllocAccount: account(),
		LegQuantity: hash.StrategyLegs[0].LegQuantity
			? parseInt(hash.StrategyLegs[0].LegQuantity)
			: parseInt(bullet.content.CustomParameters.OrderQuantity),
		LegSecurityExchange: hash.StrategyLegs[0].LegSecurityExchange
			? hash.StrategyLegs[0].LegSecurityExchange
			: bullet.StrategyInfos[0].subExchange
				? bullet.StrategyInfos[0].subExchange
				: 'XBSP',
		LegSide: hash.StrategyLegs[0].LegSide,
		LegSymbol: hash.StrategyLegs[0].LegSymbol,
		LegOrdType:
            bullet.subUid === 2
            	? bullet.StrategyInfos[0].subExchange === 'XBSP'
            		? '1'
            		: 'K'
            	: '2',
	};
	return cleanLeg;
};

export default cleanSorderLeg;
