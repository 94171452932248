import React from 'react';

import { TextField } from '@mui/material';
import { strategiesType } from 'utils/strategiesType';

export const StrategyCodeSelect = ({ onChange, value, label, id, name, ...rest }) => {
	const strategyCodes = [{ name: 'Todos', code: 'all' }];

	const excludedStrategies = [
		'vwap',
		'iceberg',
		'skew',
		'spreadunits',
		'portbalance',
		'marketmaker',
		'arbintf',
	];

	Object.keys(strategiesType).forEach(category => {
		if (category !== 'Customs') {
			strategiesType[category].forEach(strategy => {
				if (!excludedStrategies.includes(strategy.code)) {
					strategyCodes.push(strategy);
				}
			});
		}
	});

	return (
		<TextField
			id={id}
			select
			fullWidth
			size="small"
			name={name}
			value={value}
			label={label}
			defaultValue="all"
			onChange={onChange}
			labelId={`${id}-label`}
			SelectProps={{ native: 'native' }}
			{...rest}
		>
			{strategyCodes.map(item => (
				<option key={item.code} value={item.code}>
					{item.name}
				</option>
			))}
		</TextField>
	);
};
