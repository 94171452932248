import { store } from 'store';

import { setStrategiesTabIndex } from 'store/modules/panelConfig/actions';
import {
	setStateValues,
	updateFormValues,
} from 'store/modules/executions/actions';
import { parse } from 'date-fns';

export function viewExecutionStrategy(row) {
	const [leg] = row.Legs;
	const account = leg.ILegAllocAccount;

	const parsedDate = parse(row.CreateDate, 'yyyy-MM-dd HH:mm:ss', new Date());

	store.dispatch(updateFormValues('strategyId', row.StrategyId));
	store.dispatch(updateFormValues('cblc', account));
	store.dispatch(updateFormValues('date', parsedDate));
	store.dispatch(setStateValues('filtersTransactionTabs', true));

	store.dispatch(setStrategiesTabIndex(2));
}
