const cleanMitCustom = hash => {
	const cleanCustomParameters = {
		PriceLimit: hash.CustomParameters.PriceLimit,
		RestingPrice: parseFloat(hash.CustomParameters.RestingPrice),
		RestingQuantity: hash.CustomParameters.RestingQuantity,
	};
	return cleanCustomParameters;
};

export default cleanMitCustom;
