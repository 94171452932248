import { colors } from '@mui/material';

export const colorsBySponsor = {
	font: {
		flx: colors.grey[50],
		fut: colors.grey[50],
		cmc: colors.grey[50],
		btg: colors.grey[50],
	},
	background: {
		flx: colors.blue[400],
		fut: colors.blue[800],
		cmc: colors.blue[900],
		btg: colors.indigo[900],
	},
	avatar: {
		flx: colors.blue[800],
		fut: colors.blue[300],
		cmc: colors.orange[800],
		btg: colors.indigo[400],
	},
};
