const spreadEdit = bullet => {
	const hashLegs = [];
	bullet.content.StrategyLegs.forEach((leg) => {
		hashLegs.push({
			LegRefID: leg.LegRefID,
			LegQuantity: leg.LegQuantity,
			LegResting: leg.LegResting,
			LegMaxClipSize: leg.LegMaxClipSize,
			LegFirstTimeOut: leg.LegFirstTimeOut,
		});
	});

	const hash = {
		StrategyCode: bullet.content.StrategyCode,
		CustomParameters: {
			TriggerValue: bullet.content.CustomParameters.TriggerValue,
			IgnoreOffersLT: bullet.content.CustomParameters.IgnoreOffersLT,
			BookDepth: bullet.content.CustomParameters.BookDepth,
		},
		StrategyLegs: hashLegs,
		ClOrdID: bullet.content.ClOrdID,
		EndTime: bullet.content.EndTime || bullet.values.EndTime,
	};

	if (bullet.content.CustomParameters.ExecutionType == 1) {
		hash.CustomParameters.ToMarketTriggerValue =
			bullet.content.CustomParameters.ToMarketTriggerValue;
	}

	if (bullet.content.CustomParameters.BandPriceRef === 'Y') {
		hash.CustomParameters.BandPriceLow =
			bullet.content.CustomParameters.BandPriceLow;
		hash.CustomParameters.BandPriceHigh =
			bullet.content.CustomParameters.BandPriceHigh;
	}

	if (process.env.NODE_ENV === 'development') {
		console.log('hash', hash);
	}

	return hash;
};

export default spreadEdit;
