const cleanBofferLeg = (hash, account, bullet) => {
	const cleanLeg = {
		ILegAllocAccount: account(),
		LegSymbol: hash.StrategyLegs[0].LegSymbol,
		LegSecurityExchange: hash.StrategyLegs[0].LegSecurityExchange
			? hash.StrategyLegs[0].LegSecurityExchange
			: bullet.StrategyInfos[0].subExchange
				? bullet.StrategyInfos[0].subExchange
				: 'XBSP',
		LegSide: hash.StrategyLegs[0].LegSide,
		LegQuantity: parseInt(hash.StrategyLegs[0].LegQuantity, 10),
		LegMaxClipSize: parseInt(hash.StrategyLegs[0].LegMaxClipSize, 10),
	};
	return cleanLeg;
};

export default cleanBofferLeg;
