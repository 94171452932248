/* eslint-disable react/jsx-key */
import React from 'react';
import { useTable } from 'react-table';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TableStyles, TitleStyled } from './styles';

export default function Table({ columns, data, onClickRow, setRowsTable }) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		footerGroups,
		rows,
		prepareRow,
	} = useTable({
		columns,
		data,
	});

	const getItemStyle = (isDragging, draggableStyle, checked = true) => ({
		userSelect: 'none',
		...draggableStyle,
		cursor: 'auto',
		opacity: checked ? 1 : 0.45,
	});

	const reorder = (startIndex, endIndex) => {
		const result = Array.from(data.sort((a, b) => a.index - b.index));
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onDragEnd = result => {
		if (!result.destination) {
			return;
		}
		const items = reorder(
			result.source.index,
			result.destination.index
		).map((item, index) => ({ ...item, index }));
		setRowsTable(items);
	};

	// Render the UI for your table
	return (
		<TableStyles>
			<table {...getTableProps()}>
				<thead>
					{headerGroups.map(group => (
						<tr
							{...group.getHeaderGroupProps()}
							style={{ borderTop: '1px solid #808080' }}
						>
							{group.headers.map(column => (
								<th
									{...column.getHeaderProps()}
									className={column?.className}
								>
									{column?.id === 'entryPx' ? (
										<div style={{ width: '100%' }}>
											<TitleStyled>
												{column.render('Header')}
											</TitleStyled>
										</div>
									) : (
										<div style={{ width: '100%' }}>
											{column.render('Header')}
										</div>
									)}
								</th>
							))}
						</tr>
					))}
				</thead>

				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable">
						{provided => (
							<tbody
								{...getTableBodyProps()}
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{rows.map((row, i) => {
									prepareRow(row);
									return (
										<Draggable
											key={row.id}
											draggableId={row.id}
											index={i}
										>
											{(provided, snapshot) => (
												<tr
													{...row.getRowProps()}
													onClick={event =>
														onClickRow &&
														onClickRow({
															event,
															row,
														})
													}
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(
														snapshot.isDragging,
														provided.draggableProps
															.style,
														row.original.checked
													)}
												>
													{/* <td><DragIndicator /></td> */}
													{row.cells.map(cell => {
														return (
															<td
																{...cell.getCellProps()}
																style={{
																	maxWidth:
																		cell
																			?.column
																			?.maxWidth,
																	width: cell
																		?.column
																		?.width,
																}}
																className={
																	cell?.column
																		?.className
																}
															>
																{cell.render(
																	'Cell'
																)}
															</td>
														);
													})}
												</tr>
											)}
										</Draggable>
									);
								})}
							</tbody>
						)}
					</Droppable>
				</DragDropContext>

				<tfoot>
					{footerGroups.map(group => (
						<tr
							{...group.getFooterGroupProps()}
							style={{ borderBottom: '1px solid #707070' }}
						>
							{group.headers.map(column => {
								if (
									column.Header === 'Preço' ||
									column.id === 'exitPx'
								) {
									return;
								}
								return (
									column.Footer && (
										<td
											{...column.getFooterProps()}
											className={
												[
													'Strike / Papel',
													'Vol.Impl',
													'Resultado',
													'Volatilidade',
												].includes(column.Header)
													? 'border-right'
													: ''
											}
											colSpan={
												[
													'Vol.Impl',
													'Volatilidade',
												].includes(column.Header)
													? 2
													: 1
											}
										>
											{column.render('Footer')}
										</td>
									)
								);
							})}
						</tr>
					))}
				</tfoot>
			</table>
		</TableStyles>
	);
}
