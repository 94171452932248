import React from 'react';

import { Button, ButtonGroup } from '@mui/material';

export default function ModeQuantity({ disabled, selectType, changeTrigger }) {
	const selectTypeIsQtty = selectType === 0;
	const selectTypeIsPrice = selectType === 1;

	const backgroundSelectTypeIsQtty = selectTypeIsQtty ? '#264f28' : '#595959';
	const backgroundSelectTypeIsPrice = selectTypeIsPrice
		? '#264f28'
		: '#595959';

	function handleQuantityChange() {
		if (selectTypeIsPrice) {
			changeTrigger();
		}
	}

	function handlePriceChange() {
		if (selectTypeIsQtty) {
			changeTrigger();
		}
	}

	return (
		<ButtonGroup
			disabled={disabled}
			variant="contained"
			fullWidth
			sx={{ ml: '-4px' }}
		>
			<Button
				name="modeQuantity"
				onClick={handleQuantityChange}
				size="small"
				sx={{
					background: backgroundSelectTypeIsQtty,
					color: selectTypeIsQtty ? '#e0e0e0' : '#8b8b8b',
					'&:hover': {
						background: backgroundSelectTypeIsQtty,
					},
					padding: '1px 10px !important',
				}}
			>
				Quantidade
			</Button>

			<Button
				name="modePrice"
				sx={{
					background: backgroundSelectTypeIsPrice,
					color: selectTypeIsPrice ? '#e0e0e0' : '#8b8b8b',
					'&:hover': {
						background: backgroundSelectTypeIsPrice,
					},
					padding: '1px 10px !important',
				}}
				onClick={handlePriceChange}
				size="small"
			>
				Financeiro
			</Button>
		</ButtonGroup>
	);
}
