import { parseISO, format } from 'date-fns';
import { store } from 'store';
import { roundNumber } from 'utils/numberFormat';
import {
	optionStrategyAnalysis,
	calcOptionsStrategyPayoffCurve,
} from '@investflex/iflexquantjs';

export function getStrikePx(
	options,
	price,
	date,
	optionType,
	listStrikePxExisting = []
) {
	date = format(parseISO(date), 'yyyyMMdd');
	const dateToCompare =
		parseInt(options[0]?.maturityDate) > parseInt(date)
			? options[0].maturityDate
			: date;

	optionType =
		{
			P: 'PUT',
			C: 'CALL',
		}[optionType] || optionType;

	const data = options
		.filter(item => item.optionType === optionType)
		.filter(item => item.maturityDate === dateToCompare);

	let indexValue = data.reduce(
		(r, a, i, aa) =>
			i &&
			Math.abs(aa[r].strikePrice - price) <
				Math.abs(a.strikePrice - price)
				? r
				: i,
		-1
	);

	while (
		listStrikePxExisting.some(i => i === data[indexValue]?.strikePrice)
	) {
		indexValue++;
		if (indexValue === data.length - 1) break;
	}

	return { data, indexValue };
}

export function getIr() {
	return store.getState().simulator.ir;
}

export function calcDaysToExpiration() {
	return 0;
}

export function getPaper(state) {
	if (state.simulator.activeStrategy === null) return {};
	const strategy =
		state.simulator.strategyList[state.simulator.activeStrategy];
	if (!strategy?.underSymbol) return {};
	return state.cache.paper[strategy?.underSymbol] || {};
}
export function getPaperFromStore() {
	if (store.getState().simulator.activeStrategy === null) return null;
	const strategy =
		store.getState().simulator.strategyList[
			store.getState().simulator.activeStrategy
		];
	if (!strategy?.underSymbol) return null;
	return store.getState().cache.paper[strategy?.underSymbol] || null;
}
export function getOptions(state) {
	if (state.simulator.activeStrategy === null) return [];
	const strategy =
		state.simulator.strategyList[state.simulator.activeStrategy];
	if (!strategy?.underSymbol) return [];
	return state.cache.options[strategy?.underSymbol] || [];
}

export function getStrategy(state) {
	if (state.simulator.activeStrategy === null) return {};
	return state.simulator.strategyList[state.simulator.activeStrategy];
}
export function getStrategyFromStore() {
	if (store.getState().simulator.activeStrategy === null) return null;
	return (
		store.getState().simulator.strategyList[
			store.getState().simulator.activeStrategy
		] || []
	);
}

export function getStrategyListFromStore() {
	return store.getState().simulator.strategyList || [];
}

export function getMetricAnalysis(paper, options, strategy, ir) {
	let abortCalc = false;
	const { price, histVol30 } = paper;
	const dte = 0;
	const strategyToCalcMetrics = {
		underType: strategy.underType,
		positions: strategy.positions
			.filter(i => i.checked)
			.map(item => {
				const { ...rest } = item;

				if (isNaN(item.entryPx)) {
					abortCalc = true;
				} else {
					//if(!item.margin){
					if (item.optionType) {
						rest.margin = options.find(
							i => i.symbol === item.symbol
						)?.margin;
					} else {
						rest.margin = paper?.margin;
					}
					//}

					//Bug correction: Added parameters to function
					const positionFormated = {
						...rest,
						posStatus: rest.exitPxLocked ? 'C' : 'O',
					};
					const volatilities = {
						theorImpliedVol: parseFloat(
							(rest.theorImpliedVol / 100).toFixed(4)
						),
						entryImpliedVol: parseFloat(
							(rest.entryImpliedVol / 100).toFixed(4)
						),
						exitImpliedVol: parseFloat(
							(rest.exitImpliedVol / 100).toFixed(4)
						),
					};
					return rest.optionType
						? {
							...positionFormated,
							...volatilities,
							rest: rest.qtty,
						  }
						: {
							...positionFormated,
							rest: rest.qtty,
						  };
				}

				return rest;
			}),
	};

	if (abortCalc) {
		return {
			maxProfit: 0,
			maxLoss: 0,
			profitProb: 0,
			margin: null,
			breakEvens: [],
			entryCost: 0,
			exitCost: 0,
			maxProfitPerc: 0,
			maxLossPerc: 0,
		};
	} else {
		// workaround hotfix until problem with IR in strategy be resolved
		//const value = optionStrategyAnalysis(price,strategy.ir,dte,histVol30,strategyToCalcMetrics);

		const value = optionStrategyAnalysis(
			price,
			ir,
			dte,
			histVol30,
			strategyToCalcMetrics
		);

		return value;
	}
}

function closeDte(strategy) {
	var closeDte = Infinity;
	strategy.positions.forEach(function (leg) {
		if (
			leg.daysToExpiration < closeDte &&
			leg.exitPxLocked == false &&
			'OPT' == leg.securityType
		) {
			closeDte = leg.daysToExpiration;
		}
	});
	return closeDte;
}

const OPTION_TYPE_CODES = ['OPT', 'SOPT', 'FOPT', 'INDEXOPT'];

const isOptionContract = o => OPTION_TYPE_CODES.includes(o.securityType);

const isOptionPosition = p => isOptionContract(p);

const checkDaysToExpiration = (value, index, array) => {
	if (index === 0) {
		return true;
	} else {
		return value.daysToExpiration == array[index - 1].daysToExpiration;
	}
};

const isCalendarStrategy = strategy => {
	const a = strategy.positions.filter(
		e => isOptionPosition(e) && e.daysToExpiration !== undefined
	);
	if (typeof a !== 'undefined' && a.length > 0) {
		return a.every(checkDaysToExpiration);
	}
	return false;
};

export function getStrategyReportData(
	strategyName,
	comments,
	initPrice,
	endPrice,
	onlyPosChecked = true
) {
	const ir = getIr();
	const selStrategy = getStrategyFromStore();
	const paper = getPaperFromStore();
	const options = getOptions(store.getState());
	if (!selStrategy || !paper) return;
	const analysis = getMetricAnalysis(paper, options, selStrategy, ir);
	let minExpirationDate = '';
	let minDte = 999999999;
	let dteOffSet = 0;

	if (!isCalendarStrategy(selStrategy)) {
		dteOffSet = closeDte(selStrategy);
	}

	roundNumber(initPrice);
	roundNumber(endPrice);

	const curveResp = calcOptionsStrategyPayoffCurve(
		getStrategyForIflexquantChart(
			selStrategy,
			paper?.price,
			ir,
			onlyPosChecked
		),
		paper?.price,
		ir,
		'EXP',
		'MKT',
		dteOffSet,
		0,
		{ priceInitEnd: [initPrice, endPrice], curvePoints: 100 }
	);
	const chartData = curveResp.retCode === 0 ? curveResp.data : {};

	return {
		strategy: {
			strategyName,
			underSymbol: selStrategy.underSymbol,
			waterMarkFlexscan:
				'https://iflex-repo.s3.amazonaws.com/assets/logo-fs-mdagua.png',
			logoSponsor:
				'https://iflex-repo.s3.amazonaws.com/assets/logo-sponsor.pgn',
			entryCost: analysis.entryCost,
			margin: analysis.margin,
			maxProfit: analysis.maxProfit,
			maxRisk: analysis.maxloss,
			maxProfitPerc: analysis.maxProfitPerc,
			cdiPerc: analysis.profitPercOfCdi,
			breakEvens: analysis.breakEvens,
			comments,
			sponsor: 'guide',
			analystName: 'Luis Gustavo Lopes',
			analystSignature:
				'https://iflex-repo.s3.amazonaws.com/assets/lsignature.pgn',
			analystEmail: 'luisglopes@gmail.com',
			customCss: '',
			positions: selStrategy.positions
				.filter(i => !onlyPosChecked || i?.checked)
				.map(pos => {
					if (pos.securityType === 'OPT') {
						if (pos.daysToExpiration < minDte) {
							minDte = pos.daysToExpiration;
							minExpirationDate = pos.expirationDate;
						}
						return {
							symbol: pos.symbol,
							qtty: pos.qtty,
							optionType: pos.optionType,
							entryPx: !Number.isNaN(pos.entryPx)
								? pos.entryPx
								: 'N/D',
							expirationDate: pos.expirationDate,
							strikePx: !Number.isNaN(pos.strikePx)
								? pos.strikePx
								: 'N/D',
							entryCost: !Number.isNaN(pos.entryPx)
								? -1 * pos.qtty * pos.entryPx
								: 'N/D',
						};
					} else {
						//STOCK
						return {
							symbol: pos.symbol,
							qtty: pos.qtty,
							entryPx: !Number.isNaN(pos.entryPx)
								? pos.entryPx
								: 'N/D',
							entryCost: !Number.isNaN(pos.entryPx)
								? -1 * pos.qtty * pos.entryPx
								: 'N/D',
						};
					}
				}),
			expirationDate: minExpirationDate, //YYYYMMDD
			dte: minDte === 999999999 ? 0 : minDte, //dias uteis ate expiração
			chartData: chartData,
		},
	};
}

export function getStrategyForIflexquantChart(
	strategy,
	underPx,
	ir,
	onlyPosChecked = true
) {
	return {
		...strategy,
		ir,
		underPx,
		positions: strategy?.positions
			.filter(i => !onlyPosChecked || i.checked)
			.map(position => {
				const positionFormated = {
					...position,
					exitPx: position.exitPxLocked ? position.exitPx : null,
					posStatus: position.exitPxLocked ? 'C' : 'O',
				};
				const volatilities = {
					theorImpliedVol: parseFloat(
						(position.theorImpliedVol / 100).toFixed(4)
					),
					entryImpliedVol: parseFloat(
						(position.entryImpliedVol / 100).toFixed(4)
					),
					exitImpliedVol: parseFloat(
						(position.exitImpliedVol / 100).toFixed(4)
					),
				};
				return position.optionType
					? {
						...positionFormated,
						...volatilities,
						position: position.qtty,
					  }
					: {
						...positionFormated,
						position: position.qtty,
					  };
			}),
	};
}

export function getAccessorVolatility(accessor) {
	return {
		entryPx: 'entryImpliedVol',
		exitPx: 'exitImpliedVol',
		theorPx: 'theorImpliedVol',
	}[accessor];
}

export function convertStringToFloat(value) {
	if (!value || value === 'N/D') return 0.0;
	return parseFloat(value.toString().replace('.', '').replace(',', '.'));
}
export function convertFloatToString(value) {
	return roundNumber(value).toString().replace('.', ',');
}

export const positionModel = {
	id: 'pos001',
	index: 1,
	checked: true,
	symbol: '',
	securityType: 'OPT',
	// "optionType": "C",
	optionClass: 'A',
	// "strikePx": 21.14,
	// "daysToExpiration": 30,
	// "entryImpliedVol": 0.34,
	contractMultiplier: 1,
	entryPx: 0,
	// "exitImpliedVol": 0.35,
	exitPx: 0, // only when leg is closed
	qtty: 1000, // (+) compra, (-) venda
	// "theorPx": 0.25,
	// "theorImpliedVol": 0.25,
	expired: false,
	entryPxLocked: false,
	exitPxLocked: false,
	exitManualMode: false,
	entryManualMode: false,
	theorManualMode: false,
	// "expirationDate": 20200417,
	createDate: '2020-06-20 10:00:00',
	lastUpdate: '2020-06-20 10:00:01',
	exitDate: '2020-06-20 10:00:01',
	entryGreeks: {
		delta: 0,
		gamma: 0,
		theta: 0,
		vega: 0,
		rho: 0,
	},
	exitGreeks: {
		delta: 0,
		gamma: 0,
		theta: 0,
		vega: 0,
		rho: 0,
	},
	theoreticalGreeks: {
		delta: 0,
		gamma: 0,
		theta: 0,
		vega: 0,
		rho: 0,
	},
};

export const strategyModel = {
	name: 'strategy',
	index: 0,
	// "user": "flx_hernan",
	account: 'account',
	type: 'OPT_STR', // "OPT_STR", "LS_STR"
	underSymbol: '', //
	underType: 'STOCK',
	createDate: '2020-06-20 10:00:00',
	lastUpdate: '2020-06-20 10:00:06',
	theoreticMode: 'HIST', // "HIST" | "CUSTOM" | "MANUAL"
	text: '', //texto ingressado pelo usuario como comentario desta estrategia
	ir: null,
	showTypeGreeks: 'exit',
	showTypeExit: 'top', //"last" | "mid"
	histVol30: null,
	histVol90: null,
	histVol180: null,
	histVol360: null,
	positions: [],
};
