import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Spread from '../../MaterialSpread/Leg';
import ExecutionParams from '../../MaterialSpread/ExecutionParams';

const SpreadBodyBullet = props => {
	const { execType, bullet, setPaperInfo, setSpreaAuxPrice, stepFunction, onSuggestionsFetchRequested, handleChange, suggestions, marketPrice } = props;
	return (
		<>
			<Grid item xs={execType === 'B' ? 4 : 6}>
				<Grid container spacing={1}>
					<Grid item xs={12} style={{ marginLeft: '6px' }}>
						<Typography variant="subtitle1">Entrada</Typography>
					</Grid>
					{bullet.content.StrategyLegs.map((leg, index) => (
						<Spread
							setPaperInfo={setPaperInfo}
							index={index}
							leg={leg}
							key={`${bullet.uid}_${index}`}
							callbackMarketPrice={setSpreaAuxPrice}
							stepFunction={stepFunction}
							execType={execType}
							onSuggestionsFetchRequested={onSuggestionsFetchRequested}
						/>
					))}
				</Grid>
			</Grid>
			<ExecutionParams
				setPaperInfo={setPaperInfo}
				handleChange={handleChange}
				suggestions={suggestions}
				stepFunction={stepFunction}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				marketPrice={marketPrice}
				execType={execType}
			/>
		</>
	);
};

export default SpreadBodyBullet;
