import axios from 'axios';
import { store } from 'store';
import { toast } from 'react-toastify';

import i18n from '../../i18n';
import { notAuthError } from 'store/modules/auth/actions';

const headers = {
	post: {
		'Content-Type': 'application/json;charset=utf-8',
	},
	get: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
};

export const apiEls = axios.create({
	baseURL: process.env.REACT_APP_ELS_API_URL,
	timeout: 15 * 1000,
	headers,
});

apiEls.interceptors.response.use(
	response => response,
	error => {
		const { response } = error;

		if (response && response.status >= 400 && response.status <= 504) {
			if ([401, 403].includes(response.status)) {
				store.dispatch(notAuthError());
			} else if (response.status >= 500) {
				toast.error(i18n.t('serverError'));
			}
		}

		return Promise.reject(error);
	}
);
