import React, { useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from '@mui/material';

import { StyledInput } from './styles';

import { strategyModel } from 'pages/Simulator/utils';
import { strategyAdd } from 'store/modules/simulator/actions';
import { updateActiveStrategies } from 'store/modules/portfolio/actions';

export const NewStrategyModal = ({
	onClose,
	setTabIndex,
	activeStrategies
}) => {
	const dispatch = useDispatch();

	const { strategyList } = useSelector(state => state.simulator);
	const { username, account } = useSelector(state => state.auth);
	const isModalOpen = useSelector(state => state.portfolio?.newStrategyModal);

	const [name, setName] = useState('');
	const [isEmpty, setIsEmpty] = useState(false);

	function handleCreate() {
		if (name.length > 0) {
			const strategy = {
				...strategyModel,
				id: new Date().getTime(),
				name,
				index: strategyList.length,
				userName: username,
				account,
				createDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
			};

			dispatch(strategyAdd(strategy));
			dispatch(updateActiveStrategies([strategy]));
			setTabIndex(activeStrategies.length + 2);
			onClose();
		}
	}

	function handleChange(value) {
		if (value.length <= 0 || value === '') {
			setIsEmpty(true);
		} else {
			setIsEmpty(false);
		}

		setName(value);
	}

	function handleClose() {
		onClose();
		setIsEmpty(false);
	}

	return (
		<Dialog
			open={isModalOpen}
			onClose={handleClose}
		>
			<DialogTitle id="alert-dialog-title">
				Defina o nome da estratégia
			</DialogTitle>

			<DialogContent>
				<StyledInput
					error={isEmpty}
					helperText={isEmpty ? 'O nome deve ter pelo menos 1 caracter' : ''}
					variant="standard"
					label="Nome da estratégia"
					onChange={(e) => handleChange(e.target.value)}
				/>
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose}>
					Fechar
				</Button>

				<Button onClick={handleCreate}>
					Confirmar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
