const marketmakerClear = (bullet, setAccessorInfos, account) => {
	const hash = { ...bullet.content };
	const strategyInfos = { ...bullet.StrategyInfos };

	hash.StrategyLegs[0] = {
		ILegAllocAccount: account(),
		LegSymbol: hash.StrategyLegs[0].LegSymbol,
		LegSecurityExchange: strategyInfos[0].subExchange ? strategyInfos[0].subExchange : 'XBSP',
		LegSide: hash.StrategyLegs[0].LegSide,
		LegQuantity: hash.StrategyLegs[0].LegQuantity,
		LegMaxClipSize: hash.StrategyLegs[0].LegMaxClipSize,
	};

	hash.StrategyLegs[1] = {
		ILegAllocAccount: account(),
		LegSymbol: hash.StrategyLegs[0].LegSymbol,
		LegSecurityExchange: strategyInfos[0].subExchange ? strategyInfos[0].subExchange : 'XBSP',
		LegSide: hash.StrategyLegs[1].LegSide,
		LegQuantity: hash.StrategyLegs[0].LegQuantity,
		LegMaxClipSize: hash.StrategyLegs[0].LegMaxClipSize,
	};

	hash.CustomParameters = {
		TriggerType: hash.CustomParameters.TriggerType,
		TriggerValue: hash.CustomParameters.TriggerValue,
		AgressorThresholdOn: hash.CustomParameters.AgressorThresholdOn,
		AgressorThresholdOff: hash.CustomParameters.AgressorThresholdOff,
		StopLossFinance: hash.CustomParameters.StopLossFinance,
		StopPositionQuantity: hash.CustomParameters.StopPositionQuantity,
		StopLossPerc: hash.CustomParameters.StopLossPerc,
		StopGainPerc: hash.CustomParameters.StopGainPerc,
		RevertOnExecQty: hash.CustomParameters.RevertOnExecQty,
	};

	delete hash.signature;
	delete hash.clean;
	hash.StrategyLegs = setAccessorInfos(hash.StrategyLegs);

	return hash;
};

export default marketmakerClear;
