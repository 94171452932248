import React from 'react';
import { PortBalanceLeg } from '../../MaterialPortBalance/Leg';

const PortBalanceBodyBullet = (props) => {
	const { bullet, stepFunction, handleChange } = props;

	return (
		<PortBalanceLeg
			bullet={bullet}
			stepFunction={stepFunction}
			handleChange={handleChange}
		/>
	);
};

export default PortBalanceBodyBullet;
