import { ProductsActionTypes } from './actionTypes';

export const resetProductsState = () => ({
	type: ProductsActionTypes.RESET_PRODUCTS_STATE,
});

export const setProducts = products => ({
	type: ProductsActionTypes.SET_PRODUCTS,
	payload: { products },
});

export const setUserSignatures = signatures => ({
	type: ProductsActionTypes.SET_USER_SIGNATURES,
	payload: { signatures },
});

export const setActiveProduct = activeProduct => ({
	type: ProductsActionTypes.SET_ACTIVE_PRODUCT,
	payload: { activeProduct },
});

export const setPermittedBullets = permittedBullets => ({
	type: ProductsActionTypes.SET_PERMITTED_BULLETS,
	payload: { permittedBullets },
});

export const setBulletTypes = bulletTypes => ({
	type: ProductsActionTypes.SET_BULLET_TYPES,
	payload: { bulletTypes },
});

export const setBulletCategories = bulletCategories => ({
	type: ProductsActionTypes.SET_BULLET_CATEGORIES,
	payload: { bulletCategories },
});

export const setContractVisibility = visibility => ({
	type: ProductsActionTypes.SET_CONTRACT_VISIBILITY,
	payload: { visibility },
});

export const setContractsData = contractsData => ({
	type: ProductsActionTypes.SET_CONTRACTS_DATA,
	payload: { contractsData },
});

export const setContractToAccept = contract => ({
	type: ProductsActionTypes.SET_CONTRACT_TO_ACCEPT,
	payload: { contract },
});

export const signContract = signedContract => ({
	type: ProductsActionTypes.SIGN_CONTRACT,
	payload: { signedContract },
});
