import { store } from 'store';
import { strategyAdd } from 'store/modules/simulator/actions';
import {
	updateActiveStrategies,
	updateTabIndex,
} from 'store/modules/portfolio/actions';

export function moveToSimulator(history, strategy) {
	const activeStrategies = store.getState().portfolio.activeStrategies;

	store.dispatch(strategyAdd(strategy));
	store.dispatch(updateActiveStrategies([strategy]));
	store.dispatch(updateTabIndex(activeStrategies.length + 2));

	history.push('/simulator');
}
