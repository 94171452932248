import styled from 'styled-components';
import themes from 'themes';

const oramaColors = themes.orama.colors;

export const Container = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
`;

export const ModalBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	width: 95vw;
	max-width: 1090px;
	background-color: ${oramaColors.white};
	border-radius: 20px;
	padding: 16px 8px;

	@media screen and (min-width: 1024px) {
		padding: 64px;
		width: 80vw;
	}
`;

export const ModalHeader = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 100%;
	height: auto;

	> h3 {
		color: ${oramaColors.green};
	}

	@media screen and (max-width: 1024px) {
		h3 {
			font-size: 2rem;
		}
	}
`;

export const CloseBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 40px;
	height: 40px;
	background-color: ${oramaColors.green};
	border-radius: 50% 0 0 50%;
	cursor: pointer;

	svg {
		color: ${oramaColors.white};
		transition: 0.3s;
	}

	&:hover {
		svg {
			transform: scale(1.1);
		}
	}

	@media screen and (min-width: 1024px) {
		margin-right: -64px;
	}
`;

export const ModalContract = styled.main`
	width: 100%;
	height: auto;
	max-height: 450px;
	overflow: hidden;
	margin: 32px 0;

	font-size: 16px;
	color: ${oramaColors.darkGray};
	text-align: justify;

	iframe {
		width: 100%;
		min-height: 300px;
	}

	@media screen and (min-width: 1024px) {
		iframe {
			min-height: 500px;
		}
	}
`;

export const ModalFooter = styled.footer`
	display: flex;
	flex-direction: column;
	width: 100%;

	button {
		align-self: flex-end;
	}
`;
