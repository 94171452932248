import { apiAWS } from './api';

import { store } from 'store';
import { setMarketPriceLoading } from 'store/modules/bottomBullet/actions';

function getCalcRequestLegs(strategyLegs, trigger) {
	if (trigger === 1 || trigger === 11) {
		return {
			legs: strategyLegs.map(leg => ({
				LegQuantity: leg.LegQuantity,
				LegSecurityExchange: leg.LegSecurityExchange,
				LegSymbol: leg.LegSymbol.toUpperCase(),
				LegSide: leg.LegSide,
			})),
		};
	} else if ([2, 3, 4, 5].includes(trigger)) {
		const longLeg = strategyLegs.filter(leg => {
			return leg.LegSide === '1';
		})[0];

		const shortLeg = strategyLegs.filter(leg => {
			return leg.LegSide === '2';
		})[0];

		return {
			LongExchange: longLeg.LegSecurityExchange,
			LongSymbol: longLeg.LegSymbol,
			ShortExchange: shortLeg.LegSecurityExchange,
			ShortSymbol: shortLeg.LegSymbol,
		};
	} else {
		console.error('Trigger nao cadastrado!');
		return {};
	}
}

//TODO criar constantes para os triggers
function getCalcType(trigger) {
	switch (trigger) {
		case 1:
			return 'price';
		case 2:
			return 'ls-priceratio';
		case 3:
			return 'sl-priceratio';
		case 4:
			return 'ls-pricediff';
		case 5:
			return 'sl-pricediff';
		case 11:
			return 'fin';
		default:
			return '';
	}
}

function getTriggerUrl(trigger) {
	let endpoint;

	switch (trigger) {
		case 1:
			endpoint = 'mkt-spread';
			break;
		case 2:
		case 3:
		case 4:
		case 5:
			endpoint = 'mkt-longshort';
			break;
		case 11:
			endpoint = 'mkt-spread';
			break;
		default:
			endpoint = '';
	}

	return `${process.env.REACT_APP_AWS_API_URL}/calc/${endpoint}`;
}

export function getMarketPrice(
	trigger,
	strategyLegs,
	setMarketPrice,
	controller,
	resetCancelAxiosToken
) {
	const body = {
		calc_type: getCalcType(trigger),
		...getCalcRequestLegs(strategyLegs, trigger),
	};

	const headers = {
		signal: controller.signal,
	};

	apiAWS
		.post(getTriggerUrl(parseInt(trigger)), body, headers)
		.then(response => {
			const { data } = response;
			const bid = ![null, undefined].includes(data.bid)
				? parseFloat(data.bid.toFixed(5))
				: null;
			const mid = ![null, undefined].includes(data.mid)
				? parseFloat(data.mid.toFixed(5))
				: null;
			const ask = ![null, undefined].includes(data.ask)
				? parseFloat(data.ask.toFixed(5))
				: null;

			setMarketPrice({ bid, mid, ask });

			store.dispatch(setMarketPriceLoading(false));
			resetCancelAxiosToken();
		})
		.catch(err => {
			console.log(err);
			setMarketPrice({});
		});
}
