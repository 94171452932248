import React from 'react';

import { colors } from '@mui/material';

import { getFormattedDate } from 'utils/getParsedDate';

import { ChipCell } from 'components/ChipCell';
import { strategiesType } from 'utils/strategiesType';
import { CopyCell } from 'components/CopyCell';

const statusOptions = {
	1: { label: 'Agendado', color: colors.green['A400'] },
	2: { label: 'Cancelado', color: colors.yellow[600] },
	3: { label: 'Erro', color: colors.red[500] },
	4: { label: 'Concluído', color: 'white' },
};

const freqTypeMapping = {
	1: { label: 'Agendado', color: colors.grey[100] },
	2: { label: 'Diária', color: colors.green['A400'] },
	3: { label: 'Semanal', color: colors.blue[500] },
};

export function getTranslatedStrategyCode(code) {
	let translatedCode = code;

	Object.keys(strategiesType).forEach(type => {
		strategiesType[type].forEach(strategy => {
			if (strategy.code === code) {
				translatedCode = strategy.name;
			}
		});
	});

	return translatedCode;
}

export const recurrenceColumns = [
	{
		flex: 1,
		sortable: false,
		field: 'Name',
		headerName: 'Nome',
		type: 'string',
	},
	{
		flex: 1,
		sortable: false,
		field: 'StrategyCode',
		headerName: 'Estratégia',
		type: 'string',
		renderCell: params => {
			if (params.row.hierarchy.length === 1) {
				return getTranslatedStrategyCode(params.value);
			}
		},
	},
	{
		flex: 1,
		sortable: false,
		field: 'Status',
		headerName: 'Status',
		type: 'string',
		renderCell: params => {
			if (params.row.hierarchy.length === 1) {
				const isFinished = params.row.ScheduledJobs.every(
					job => job.Status !== 1
				);

				const label = isFinished ? 'Encerrado' : 'Em andamento';
				const color = isFinished ? colors.grey[50] : colors.blue[400];

				return (
					params.row.hierarchy.length === 1 && (
						<ChipCell
							label={label}
							color={color}
							sx={{ width: '90%' }}
						/>
					)
				);
			} else {
				return (
					<div
						style={{
							color: statusOptions[params.value]?.color,
							textAlign: 'center',
							width: '90%',
						}}
					>
						{statusOptions[params.value]?.label}
					</div>
				);
			}
		},
	},
	{
		flex: 1,
		sortable: false,
		field: 'Message',
		headerName: 'Detalhe',
		type: 'string',
		renderCell: params => {
			if (params.row.hierarchy.length > 1) {
				return params.value;
			}
		},
	},
	{
		flex: 1,
		sortable: false,
		field: 'FreqType',
		headerName: 'Tipo',
		type: 'string',
		renderCell: params => {
			if (params.row.hierarchy.length === 1) {
				return (
					<span
						style={{ color: freqTypeMapping[params.value].color }}
					>
						{freqTypeMapping[params.value].label}
					</span>
				);
			}
		},
	},
	{
		flex: 1,
		sortable: false,
		field: 'CreateDate',
		headerName: 'Dt. Criação',
		type: 'dateTime',
		renderCell: params => {
			if (params.row.hierarchy.length === 1) {
				return getFormattedDate(params.value, 'dd/MM/yyyy HH:mm:ss');
			}

			return '';
		},
	},
	{
		flex: 1,
		sortable: false,
		field: 'ActiveStartDate',
		headerName: 'Dt. Início',
		type: 'date',
		renderCell: params => getFormattedDate(params.value, 'dd/MM/yyyy'),
	},
	{
		flex: 1,
		sortable: false,
		field: 'EndEvent',
		headerName: 'Término',
		type: 'dateTime',
		valueGetter: params => params.row.ActiveEndDate,
		renderCell: params => {
			if (params.row.ActiveEndType === 1) {
				return getFormattedDate(params.value, 'dd/MM/yyyy');
			}

			return '';
		},
	},
	{
		flex: 1,
		sortable: false,
		field: 'Account',
		headerName: 'Conta',
		type: 'string',
	},
	{
		flex: 1,
		sortable: false,
		field: 'Id',
		headerName: 'ID',
		type: 'string',
		renderCell: params => <CopyCell value={params.value} />,
	},
];
