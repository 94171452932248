import React, { useEffect, useMemo, useState } from 'react';
import { compareAsc, format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';

import {
	MetricsTd,
	MetricsAlign,
	MetricsGraphic,
	MetricsDivision,
} from './styles';
import { Box, Tooltip, Typography } from '@mui/material';

import { roundNumber } from 'utils/numberFormat';
import { getFormatedProfitPob } from 'utils/Portfolio/getFormatedDatas';
import { getOptions, getPaper, getStrategy, getMetricAnalysis } from './utils';

export default function Metrics() {
	//const {activeStrategy,ir, timerUpdate} =useSelector(state=> state.simulator)
	const activeStrategy = useSelector(state => state.simulator.activeStrategy);
	const ir = useSelector(state => state.simulator.ir);
	const timerUpdate = useSelector(state => state.simulator.timerUpdate);
	const strategy = useSelector(getStrategy);
	const paper = useSelector(getPaper);
	const options = useSelector(getOptions);
	const [calcPending, setCalcPending] = useState(false);
	const [analysis, setAnaysis] = useState(null);

	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	useEffect(() => {
		setCalcPending(true);
	}, [strategy, ir, activeStrategy]);

	useEffect(() => {
		if (!paper || !strategy) return;
		if (!calcPending) return;
		setCalcPending(false);
		setAnaysis(getMetricAnalysis(paper, options, strategy, ir));
	}, [timerUpdate]);

	const maxProfit =
		analysis?.maxProfit !== null
			? analysis?.maxProfit === 'unlimited'
				? 'Ilimitado'
				: roundNumber(analysis?.maxProfit, 2)
			: 'N/D';
	const maxProfitPerc =
		analysis?.maxProfitPerc !== null
			? roundNumber(analysis?.maxProfitPerc, 2) + '%'
			: 'N/D';
	const maxLoss =
		analysis?.maxLoss !== null
			? analysis?.maxLoss === 'unlimited'
				? 'Ilimitado'
				: roundNumber(analysis?.maxLoss, 2)
			: 'N/D';
	const maxLossPerc =
		analysis?.maxLossPerc !== null
			? roundNumber(analysis?.maxLossPerc, 2) + '%'
			: 'N/D';
	const profitPercOfCdi =
		analysis?.profitPercOfCdi !== null
			? isNaN(analysis?.profitPercOfCdi)
				? '-'
				: analysis?.profitPercOfCdi === 'unlimited'
					? 'Ilimitado'
					: roundNumber(analysis?.profitPercOfCdi, 2) + '%'
			: 'N/D';
	const profitProb = getFormatedProfitPob(analysis?.profitProb);
	const margin =
		analysis?.margin !== null
			? typeof analysis?.margin === 'number'
				? roundNumber(analysis?.margin, 2)
				: 'Não calculada'
			: 'N/D';
	const breakEvens =
		analysis?.breakEvens !== null
			? analysis?.breakEvens?.length === 0
				? '-'
				: analysis?.breakEvens?.map(i => roundNumber(i, 2))?.join(' | ')
			: 'N/D';
	const entryCost =
		analysis?.entryCost !== null
			? !analysis?.entryCost
				? '-'
				: `${analysis?.entryCost > 0 ? 'Cr ' : 'Db '}` +
				  roundNumber(Math.abs(analysis?.entryCost), 2)
			: 'N/D';
	const exitCost =
		analysis?.exitCost !== null
			? !analysis?.exitCost
				? '-'
				: `${analysis?.exitCost > 0 ? 'Cr ' : 'Db '}` +
				  roundNumber(Math.abs(analysis?.exitCost), 2)
			: 'N/D';
	const plText =
		analysis?.pl !== null
			? analysis?.pl >= 0
				? 'Lucro atual'
				: 'Prejuízo atual'
			: 'N/D';
	const pl = analysis?.pl !== null ? roundNumber(analysis?.pl, 2) : 'N/D';
	const plPerc =
		analysis?.plPerc !== null
			? analysis?.plPerc === undefined
				? '-'
				: roundNumber(analysis?.plPerc, 2) + '%'
			: '-';
	const maturity = useMemo(() => {
		if (!strategy?.positions.length > 0) return null;
		const foundNoExpired = strategy.positions.find(
			i =>
				i.expirationDate &&
				i.checked &&
				i.optionType &&
				!i.expired &&
				!i.exitPxLocked
		);
		const firstMaturity = foundNoExpired
			? strategy.positions
				.filter(
					i =>
						i.expirationDate &&
							i.checked &&
							i.optionType &&
							!i.exitPxLocked &&
							!i.expired
				)
				.sort((a, b) =>
					compareAsc(
						parseISO(a.expirationDate),
						parseISO(b.expirationDate)
					)
				)
			: strategy.positions
				.filter(i => i.expirationDate && i.checked && i.optionType)
				.sort((a, b) =>
					compareAsc(
						parseISO(a.expirationDate),
						parseISO(b.expirationDate)
					)
				);

		const expirationDate = firstMaturity[0]?.expirationDate;
		const dte = firstMaturity[0]?.daysToExpiration;
		if (!expirationDate) return null;
		return `${format(
			parseISO(expirationDate),
			'dd/MM/yyyy'
		)} (${dte} d.u.)`;
	}, [strategy]);

	const allLocked = () => {
		const locked = [];
		strategy.positions.forEach(position => {
			locked.push(
				position.expired ||
					(position.entryPxLocked && position.exitPxLocked)
					? true
					: false
			);
		});
		return locked.includes(false);
	};

	return (
		analysis &&
		strategy?.positions?.length > 0 && (
			<table style={{ fontSize: '12px' }}>
				<MetricsDivision>
					<MetricsTd>Vencimento</MetricsTd>
					<MetricsAlign>{maturity}</MetricsAlign>
				</MetricsDivision>
				<tr>
					<MetricsTd>Max. Lucro</MetricsTd>
					{allLocked() ? (
						<MetricsTd>
							{maxProfit !== 'N/D' ? (
								<MetricsAlign>
									{maxProfit === 'Ilimitado' && (
										<MetricsGraphic
											style={{ background: '#388E3C' }}
										>
											Ilimitado
										</MetricsGraphic>
									)}
									{maxProfit !== 'Ilimitado' && (
										<>
											<MetricsAlign
												style={
													analysis?.maxProfit >= 0
														? {
															color: '#31c593',
															marginRight:
																	'10px',
														  }
														: {
															color: '#ff5a5a',
															marginRight:
																	'10px',
														  }
												}
											>
												{maxProfit}
											</MetricsAlign>

											<MetricsGraphic
												style={
													analysis?.maxProfit >= 0
														? {
															background:
																	'#388E3C',
														  }
														: {
															background:
																	'#ff5a5a',
														  }
												}
											>
												{maxProfitPerc}
											</MetricsGraphic>
										</>
									)}
								</MetricsAlign>
							) : (
								<MetricsAlign>{maxProfit}</MetricsAlign>
							)}
						</MetricsTd>
					) : (
						<MetricsTd>
							<MetricsAlign>-</MetricsAlign>
						</MetricsTd>
					)}
				</tr>
				<tr>
					<MetricsTd>% sobre CDI</MetricsTd>
					<MetricsTd>
						<MetricsAlign style={{ color: 'white' }}>
							{profitPercOfCdi}
						</MetricsAlign>
					</MetricsTd>
				</tr>
				<MetricsDivision>
					<MetricsTd>Max. Perda</MetricsTd>
					{allLocked() ? (
						<MetricsTd>
							<MetricsAlign>
								{maxLoss == 'Ilimitado' && (
									<MetricsGraphic
										style={{ background: '#ff5a5a' }}
									>
										Ilimitado
									</MetricsGraphic>
								)}
								{maxLoss !== 'Ilimitado' && (
									<>
										{maxProfit !== 'N/D' && (
											<MetricsAlign
												style={{
													color: '#ff5a5a',
													marginRight: '10px',
												}}
											>
												{maxLoss}
											</MetricsAlign>
										)}
										<MetricsGraphic
											style={{
												background:
													maxLossPerc !== 'N/D' &&
													'#ff5a5a',
												marginRight:
													maxLossPerc === 'N/D' &&
													'-5px',
											}}
										>
											{maxLossPerc}
										</MetricsGraphic>
									</>
								)}
							</MetricsAlign>
						</MetricsTd>
					) : (
						<MetricsTd>
							<MetricsAlign>-</MetricsAlign>
						</MetricsTd>
					)}
				</MetricsDivision>
				<tr>
					<MetricsTd>Prob. de lucro</MetricsTd>
					<MetricsTd>
						<MetricsAlign>
							<MetricsAlign
								style={{
									color: 'white',
									background:
										profitProb !== 'N/D' && '#388E3C',
									borderRadius: '3px',
									height: '15px',
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
									padding: profitProb !== 'N/D' && '5px',
								}}
							>
								{profitProb}
							</MetricsAlign>
						</MetricsAlign>
					</MetricsTd>
				</tr>
				<tr>
					<Tooltip
						open={open}
						onClose={handleClose}
						onOpen={handleOpen}
						title="Margem calculada pela InvestFlex"
					>
						<MetricsTd>Margem aprox.</MetricsTd>
					</Tooltip>
					<MetricsTd>
						<MetricsAlign
							style={{ color: margin !== 'N/D' && '#ff5a5a' }}
						>
							{margin}
						</MetricsAlign>
					</MetricsTd>
				</tr>
				<MetricsDivision>
					<MetricsTd>Break even</MetricsTd>
					<MetricsTd>
						<MetricsAlign color={'white'}>
							{breakEvens}
						</MetricsAlign>
					</MetricsTd>
				</MetricsDivision>
				<tr>
					<MetricsTd>Custo entrada</MetricsTd>
					<MetricsTd>
						<MetricsAlign
							style={
								entryCost !== 'N/D'
									? analysis?.entryCost >= 0
										? { color: '#31c593' }
										: { color: '#ff5a5a' }
									: { color: 'white' }
							}
						>
							{entryCost}
						</MetricsAlign>
					</MetricsTd>
				</tr>
				<tr>
					<MetricsTd>Custo saída</MetricsTd>
					<MetricsTd>
						<MetricsAlign
							style={
								exitCost !== 'N/D'
									? analysis?.exitCost >= 0
										? { color: '#31c593' }
										: { color: '#ff5a5a' }
									: { color: 'white' }
							}
						>
							{exitCost}
						</MetricsAlign>
					</MetricsTd>
				</tr>

				<tr>
					{analysis?.retCode === 0 ? (
						<>
							<MetricsTd>{plText}</MetricsTd>
							<MetricsTd>
								<MetricsAlign>
									<MetricsAlign
										style={
											analysis?.pl >= 0
												? {
													color: '#31c593',
												  }
												: {
													color: '#ff5a5a',
												  }
										}
									>
										{pl}
									</MetricsAlign>
									{plPerc !== '-' && (
										<MetricsGraphic
											style={
												analysis?.pl >= 0
													? {
														background:
																'#388E3C',
														marginLeft: '10px',
													  }
													: {
														background:
																'#ff5a5a',
														marginLeft: '10px',
													  }
											}
										>
											{plPerc}
										</MetricsGraphic>
									)}
								</MetricsAlign>
							</MetricsTd>
						</>
					) : (
						<Box color="warning.main">
							<Typography></Typography>
						</Box>
					)}
				</tr>
			</table>
		)
	);
}
