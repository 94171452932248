import {
	SAVE_STORAGE,
	LOAD_STORAGE,
} from './actionTypes';

export const saveExternalStorage = (content, module) => ({
	type: SAVE_STORAGE,
	payload: { content, module }
});

export const loadExternalStorage = (content) => ({
	type: LOAD_STORAGE,
	payload: { content }
});
