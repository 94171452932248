import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from '@mui/material';

import { parseStrategy } from 'utils/handleSaveStrategy';
import { saveStrategy, strategyAdd } from 'store/modules/simulator/actions';

//  Parâmetro "saveMode": "saveas" | "clone"
//  - saveas (default) - clona e salva
//  - clone - apenas clona, não salva

export default function FormSaveStrategy({
	setForm,
	openForm,
	strategyToSave,
	saveMode = 'saveas',
}) {
	const dispatch = useDispatch();
	const strategyList = useSelector(state => state.simulator.strategyList);
	const username = useSelector(state => state.auth.username);
	const account = useSelector(state => state.auth.account);

	const [name, setName] = useState(strategyToSave?.name || '');
	const paper = useSelector(
		state => state.cache.paper[strategyToSave?.underSymbol]
	);

	const handleClose = () => {
		setForm(false);
	};

	// Processo similar mas diferente do salvar estratégia normal
	// Aqui há preocupação em mudar o createDate, adicionar id e index se o nome for diferente
	// A estratégia deve ser adicionada primeiro
	// Atenção para refletir alterações a serem feitas nessa função a função em handleSaveStrategy em ToolsTable, pois guardam semelhança
	const handleSaveStrategyAs = () => {
		if (
			(saveMode === 'clone' || name !== strategyToSave?.name) &&
			strategyList.find(i => i?.name === name)
		) {
			return toast.error('Já existe uma estratégia com o nome: ' + name);
		}

		if (!strategyToSave.underSymbol)
			return toast.error(
				'Favor selecione um papel e agregue ao menos uma perna na estratégia para poder salvar'
			);
		if (!strategyToSave.positions.length > 0)
			return toast.error(
				'Favor agreguar ao menos uma perna na estratégia para poder salvar'
			);
		if (!paper.subExchange)
			return toast.error('Campo: SubExchange não informado');
		const { positionsDeleted, ...strategy } = strategyToSave;
		const deleted = positionsDeleted?.map(i => {
			Object.keys(i).forEach(key => {
				if (
					i[key] === null ||
					(typeof i[key] === 'number' && isNaN(i[key]))
				) {
					i[key] = 0;
				}
			});

			return { ...i, subExchange: paper.subExchange };
		});

		const createDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

		const strategyModelFromExisting = {
			...strategy,

			createDate:
				name !== strategyToSave?.name
					? createDate
					: strategyToSave.createDate, //If updated name reset createDate
			id:
				name !== strategyToSave?.name
					? new Date().getTime()
					: strategyToSave.id, //If updated name reset id
			index:
				name !== strategyToSave?.name
					? strategyList.length
					: strategyToSave.index, //If updated name reset index
			saved: saveMode === 'saveas' ? true : false,

			userName: username,
			name: name,
			account,
			positions: [
				...strategy.positions.map(i => {
					const { ...pos } = i;
					const entry = i.entryPxLocked
						? {
							entryPx: i.entryPx || 0,
							entryImpliedVol: i.entryImpliedVol || 0,
						  }
						: {};
					const exit = i.exitPxLocked
						? {
							exitPx: i.exitPx || 0,
							exitImpliedVol: i.exitImpliedVol || 0,
						  }
						: {};
					if (pos.optionType) {
						pos.theoreticImpliedVol = i.theorImpliedVol;
					} else {
						delete entry.entryImpliedVol;
						delete exit.exitImpliedVol;
					}
					Object.keys(pos.entryGreeks).forEach(key => {
						if (!pos.entryGreeks[key]) {
							pos.entryGreeks[key] = 0;
						}
					});
					Object.keys(pos.exitGreeks).forEach(key => {
						if (!pos.exitGreeks[key]) {
							pos.exitGreeks[key] = 0;
						}
					});

					return {
						...pos,
						subExchange: paper.subExchange,
						...exit,
						...entry,
					};
				}),
				...((name !== strategyToSave?.name ? [] : deleted) || []), //Se for estrategia nova não agrega pernas para deletar
			],
		};

		const parsedStrategy = parseStrategy(strategyModelFromExisting);

		// First add strategy to screen
		dispatch(strategyAdd({ ...parsedStrategy }));

		// Now save strategy
		if (saveMode === 'saveas') {
			dispatch(saveStrategy(parsedStrategy));
		}

		handleClose();
	};

	return (
		<div>
			<Dialog
				open={openForm}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					{saveMode === 'saveas'
						? name !== strategyToSave?.name
							? 'Salvar Como Estratégia'
							: 'Salvar Estratégia'
						: 'Criar Cópia da Estratégica'}
				</DialogTitle>

				<DialogContent>
					<DialogContentText>
						{saveMode === 'saveas'
							? 'Salve a estratégia. Escolha um novo nome caso queira manter a estratégia anterior.'
							: 'Crie cópia da estratégia em edição. Escolha um novo nome.'}
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						size="small"
						label="Nome"
						fullWidth
						value={name}
						onChange={e => setName(e.target.value)}
					/>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} color="inherit">
						Cancelar
					</Button>
					<Button onClick={handleSaveStrategyAs} color="inherit">
						{saveMode === 'saveas'
							? name !== strategyToSave?.name
								? 'Salvar'
								: 'Salvar'
							: 'Criar Cópia'}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
