import React from 'react';

import { TextField } from '@mui/material';

export const SimulatorSelect = ({
	label = null,
	options,
	handleChange,
	...rest
}) => {
	return (
		<>
			<TextField
				select
				fullWidth
				size="small"
				label={label}
				onChange={handleChange}
				SelectProps={{ native: true }}
				InputLabelProps={{ shrink: !!label }}
				inputProps={{ style: { padding: '0.375rem 0.75rem' } }}
				{...rest}
			>
				{options.map(option => (
					<option
						key={option.value}
						value={option.value}
					>
						{option.label}
					</option>
				))}
			</TextField>
		</>
	);
};
