import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import paperValidator from 'utils/paperValidation';
import DecimalInput from 'components/MaterialComponents/DecimalInput';
import MaterialCheckBox from 'components/MaterialComponents/MaterialCheckBox';
import { StyledGrid, TittleGrid, TittleTypo } from '../style';
import { useTranslation } from 'react-i18next';

const GradientFinalization = ({ stepFunction, handleChange }) => {
	const { bullet } = useSelector(state => state.bottomBullet);
	const { t } = useTranslation();

	const handlePreservation = () => {
		if (bullet.content.CustomParameters.UsePreservation === 'N') {
			handleChange('Custom', 'PreservationStartTrigger', 100, 0);
			handleChange('Custom', 'PreservationPercentual', 20, 0);
		} else {
			handleChange('Custom', 'PreservationStartTrigger', null, 0);
			handleChange('Custom', 'PreservationPercentual', null, 0);
		}
		handleChange(
			'Custom',
			'UsePreservation',
			bullet.content.CustomParameters.UsePreservation === 'N' ? 'Y' : 'N',
			0
		);
	};

	return (
		<Grid
			container
			xs={12}
			style={{ margin: '8px 0 0 0', justifyContent: 'space-between' }}
		>
			<StyledGrid item container spacing={2} xs={4}>
				<TittleGrid item xs={12}>
					<TittleTypo variant="subtitle1" xs={9}>
						Stop Loss
					</TittleTypo>
				</TittleGrid>
				<Grid item xs={6}>
					<Box mt={0.4}>
						<DecimalInput
							name="lossFinanceField"
							disabled={paperValidator(
								bullet.StrategyInfos[0],
								bullet
							)}
							error={bullet.errorStopLossFinance}
							label="Financeiro"
							iconTooltip={t('bullets.gradient.loss_finance')}
							fixedTooltip="top-start"
							minVal={0}
							maxVal={999999.99}
							minDecScale={2} // casas decimais
							stepFunction={stepFunction}
							stepType="Custom" // type
							stepSubType="StopLossFinance" // subtype
							stepSize={0.01} // incremento
							valueToStep={
								bullet.content.CustomParameters.StopLossFinance
							} // valor a ser modificado
							value={
								bullet.content.CustomParameters.StopLossFinance
							}
							onChange={e => {
								handleChange(
									'Custom',
									'StopLossFinance',
									parseFloat(e.value),
									0,
									'errorStopLossFinance'
								);
							}}
						/>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<DecimalInput
						name="lossByPriceField"
						error={bullet.errorStopByPrice1}
						checkBox
						checkBoxName="finalizationCheckBox0"
						checkBoxDisabled={
							paperValidator(bullet.StrategyInfos[0], bullet) ||
							bullet.content.ClOrdID
						}
						checkBoxChecked={
							bullet.content.CustomParameters
								.SelectStopByPrice1 == 1
						}
						checkBoxHandle={() => {
							handleChange(
								'Custom',
								'SelectStopByPrice1',
								bullet.content.CustomParameters
									.SelectStopByPrice1 == 1
									? 0
									: 1,
								0,
								'errorStopByPrice1'
							);
							handleChange('Custom', 'StopByPrice1', null, 0);
						}}
						disabled={
							paperValidator(bullet.StrategyInfos[0], bullet) ||
							(bullet.content.ClOrdID &&
								!bullet.content.CustomParameters
									.StopByPrice1) ||
							bullet.content.CustomParameters
								.SelectStopByPrice1 == 0
						}
						label="Por preço"
						iconTooltip={t('bullets.gradient.loss_price')}
						minVal={
							bullet.content.StrategyLegs[0].LegSide === '2'
								? bullet.content.CustomParameters.EntrancePrice
								: bullet.StrategyInfos[0].minPriceIncrement ||
								  bullet.StrategyInfos[0].Cst_MinPriceIncrement
						}
						maxVal={
							bullet.content.StrategyLegs[0].LegSide === '1'
								? bullet.content.CustomParameters.EntrancePrice
								: 999999.99
						}
						minDecScale={
							bullet.StrategyInfos[0].tickSizeDenominator
						} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="StopByPrice1" // subtype
						stepSize={
							bullet.StrategyInfos[0].minPriceIncrement ||
							bullet.StrategyInfos[0].Cst_MinPriceIncrement
						} // incremento
						valueToStep={
							bullet.content.CustomParameters.StopByPrice1
						} // valor a ser modificado
						value={bullet.content.CustomParameters.StopByPrice1}
						onChange={e => {
							handleChange(
								'Custom',
								'StopByPrice1',
								parseFloat(e.value),
								0,
								'errorStopByPrice1'
							);
						}}
					/>
				</Grid>
			</StyledGrid>

			<StyledGrid item container spacing={2} xs={4}>
				<TittleGrid item xs={12}>
					<TittleTypo variant="subtitle1" xs={9}>
						Stop Gain
					</TittleTypo>
				</TittleGrid>
				<Grid item xs={6}>
					<DecimalInput
						name="gainFinanceField"
						error={bullet.errorStopGainGlobalFinance}
						checkBox
						checkBoxName="finalizationCheckBox1"
						checkBoxDisabled={
							paperValidator(bullet.StrategyInfos[0], bullet) ||
							bullet.content.ClOrdID
						}
						checkBoxChecked={
							bullet.content.CustomParameters
								.UseStopGainGlobalFinance === 'Y'
						}
						checkBoxHandle={() => {
							handleChange(
								'Custom',
								'UseStopGainGlobalFinance',
								bullet.content.CustomParameters
									.UseStopGainGlobalFinance === 'Y'
									? 'N'
									: 'Y',
								0
							);
							handleChange(
								'Custom',
								'StopGainGlobalFinance',
								null,
								0
							);
						}}
						disabled={
							paperValidator(bullet.StrategyInfos[0], bullet) ||
							(bullet.content.ClOrdID &&
								bullet.content.CustomParameters
									.StopGainGlobalFinance === undefined) ||
							bullet.content.CustomParameters
								.UseStopGainGlobalFinance === 'N'
						}
						label="Financeiro"
						iconTooltip={t('bullets.gradient.gain_finance')}
						minVal={0}
						maxVal={999999.99}
						minDecScale={2} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="StopGainGlobalFinance" // subtype
						stepSize={0.01} // incremento
						valueToStep={
							bullet.content.CustomParameters
								.StopGainGlobalFinance
						} // valor a ser modificado
						value={
							bullet.content.CustomParameters
								.StopGainGlobalFinance
						}
						onChange={e => {
							handleChange(
								'Custom',
								'StopGainGlobalFinance',
								parseFloat(e.value),
								0,
								'errorStopGainGlobalFinance'
							);
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<DecimalInput
						name="gainByPriceField"
						error={bullet.errorStopByPrice2}
						checkBox
						checkBoxName="finalizationCheckBox2"
						checkBoxDisabled={
							paperValidator(bullet.StrategyInfos[0], bullet) ||
							bullet.content.ClOrdID
						}
						checkBoxChecked={
							bullet.content.CustomParameters
								.SelectStopByPrice2 == 1
						}
						checkBoxHandle={() => {
							handleChange(
								'Custom',
								'SelectStopByPrice2',
								bullet.content.CustomParameters
									.SelectStopByPrice2 == 1
									? 0
									: 1,
								0,
								'errorStopByPrice2'
							);
							handleChange('Custom', 'StopByPrice2', null, 0);
						}}
						disabled={
							paperValidator(bullet.StrategyInfos[0], bullet) ||
							(bullet.content.ClOrdID &&
								bullet.content.CustomParameters.StopByPrice2 ===
									undefined) ||
							bullet.content.CustomParameters
								.SelectStopByPrice2 == 0
						}
						label="Por preço"
						iconTooltip={t('bullets.gradient.gain_price')}
						minVal={
							bullet.content.StrategyLegs[0].LegSide === '1'
								? bullet.content.CustomParameters.EntrancePrice
								: bullet.StrategyInfos[0].minPriceIncrement ||
								  bullet.StrategyInfos[0].Cst_MinPriceIncrement
						}
						maxVal={
							bullet.content.StrategyLegs[0].LegSide === '2'
								? bullet.content.CustomParameters.EntrancePrice
								: 999999.99
						}
						minDecScale={
							bullet.StrategyInfos[0].tickSizeDenominator
						} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="StopByPrice2" // subtype
						stepSize={
							bullet.StrategyInfos[0].minPriceIncrement ||
							bullet.StrategyInfos[0].Cst_MinPriceIncrement
						} // incremento
						valueToStep={
							bullet.content.CustomParameters.StopByPrice2
						} // valor a ser modificado
						value={bullet.content.CustomParameters.StopByPrice2}
						onChange={e => {
							handleChange(
								'Custom',
								'StopByPrice2',
								parseFloat(e.value),
								0,
								'errorStopByPrice2'
							);
						}}
					/>
				</Grid>
			</StyledGrid>

			<StyledGrid item container spacing={2} xs={3}>
				<TittleGrid item xs={12}>
					<TittleTypo variant="subtitle1" xs={9}>
						Preservação de lucro
						<MaterialCheckBox
							name="preservCheckbox"
							tooltip="Esta função permite preservar um percentual do lucro máximo caso o gatilho de valor financeiro seja acionado."
							disabled={
								paperValidator(
									bullet.StrategyInfos[0],
									bullet
								) || bullet.content.ClOrdID
							}
							checked={
								bullet.content.CustomParameters
									.UsePreservation === 'Y'
							}
							onChange={() => handlePreservation()}
						/>
					</TittleTypo>
				</TittleGrid>

				<Grid item xs={6}>
					<DecimalInput
						name="finantialTriggerField"
						disabled={
							bullet.content.CustomParameters.UsePreservation ===
								'N' ||
							paperValidator(bullet.StrategyInfos[0], bullet)
						}
						label="Gatilho financeiro"
						iconTooltip={t('bullets.gradient.finantial_trigger')}
						minVal={0}
						maxVal={9999999999}
						minDecScale={2} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="PreservationStartTrigger" // subtype
						stepSize={0.01} // incremento
						valueToStep={
							bullet.content.CustomParameters
								.PreservationStartTrigger
						} // valor a ser modificado
						value={
							bullet.content.CustomParameters
								.PreservationStartTrigger
						}
						onChange={e =>
							handleChange(
								'Custom',
								'PreservationStartTrigger',
								parseFloat(e.value),
								0
							)
						}
					/>
				</Grid>
				<Grid item xs={6}>
					<DecimalInput
						name="percentField"
						disabled={
							bullet.content.CustomParameters.UsePreservation ===
								'N' ||
							paperValidator(bullet.StrategyInfos[0], bullet)
						}
						label="% stop"
						iconTooltip={t('bullets.gradient.percent')}
						minVal={1}
						maxVal={100}
						minDecScale={2} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="PreservationPercentual" // subtype
						stepSize={1} // incremento
						valueToStep={
							bullet.content.CustomParameters
								.PreservationPercentual
						} // valor a ser modificado
						value={
							bullet.content.CustomParameters
								.PreservationPercentual
						}
						onChange={e =>
							handleChange(
								'Custom',
								'PreservationPercentual',
								parseFloat(e.value),
								0
							)
						}
					/>
				</Grid>
			</StyledGrid>
		</Grid>
	);
};

export default GradientFinalization;
