import {
	WIDGET_ADD,
	WIDGET_REMOVE,
	WIDGET_UPDATE,
	WIDGETS_UPDATE,
	WIDGET_EXPAND,
	WIDGET_CONTENT_UPDATE,
	WIDGET_SYMBOL_UPDATE,
	WIDGET_ADD_SYMBOL_QUOTATION
} from './actionTypes';

export const addWidget = widget => ({
	type: WIDGET_ADD,
	payload: { widget },
});

export const removeWidget = widget => ({
	type: WIDGET_REMOVE,
	payload: { widget },
});

export const updateWidget = (id, type, content) => ({
	type: WIDGET_UPDATE,
	payload: { id, type, content },
});

export const updateWidgetList = widgetList => ({
	type: WIDGETS_UPDATE,
	payload: { widgetList },
});

export const expandWidget = (id, expanded) => ({
	type: WIDGET_EXPAND,
	payload: { id, expanded },
});

export const widgetContentUpdate = (id, content) => ({
	type: WIDGET_CONTENT_UPDATE,
	payload: { id, content },
});

export const widgetSymbolUpdate = (id, symbol) => ({
	type: WIDGET_SYMBOL_UPDATE,
	payload: { id, symbol },
});

export const widgetAddSymbolQuotation = ({ id, symbol, expanded, type }) => ({
	type: WIDGET_ADD_SYMBOL_QUOTATION,
	payload: { id, symbol, expanded, type },
});
