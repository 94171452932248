const marketmakerEdit = bullet => {
	const hash = {
		StrategyCode: bullet.content.StrategyCode,
		CustomParameters: {
			TriggerType: bullet.content.CustomParameters.TriggerType,
			TriggerValue: bullet.content.CustomParameters.TriggerValue,
			AgressorThresholdOn: bullet.content.CustomParameters.AgressorThresholdOn,
			AgressorThresholdOff: bullet.content.CustomParameters.AgressorThresholdOff,
			StopLossFinance: bullet.content.CustomParameters.StopLossFinance,
			StopPositionQuantity: bullet.content.CustomParameters.StopPositionQuantity,
			StopLossPerc: bullet.content.CustomParameters.StopLossPerc,
			StopGainPerc: bullet.content.CustomParameters.StopGainPerc,
			RevertOnExecQty: bullet.content.CustomParameters.RevertOnExecQty,
		},
		StrategyLegs: [
			{
				LegQuantity: bullet.content.StrategyLegs[0].LegQuantity,
				LegMaxClipSize: bullet.content.StrategyLegs[0].LegMaxClipSize,
				LegRefID: bullet.content.StrategyLegs[0].LegRefID,
			},
		],
		EndTime: bullet.content.EndTime,
		ClOrdID: bullet.content.ClOrdID,
	};
	if (process.env.NODE_ENV === 'development') console.log('marketmaker edit', hash);
	return hash;
};

export default marketmakerEdit;
