import React from 'react';
import { useDispatch } from 'react-redux';

import { Container } from './styles';

import { setPortalLogin } from 'store/modules/auth/actions';

import { GeneralButton } from '../GeneralButton';
import { ModalTemplate } from 'components/AppHeader/Modals/Template';
import accessAdviceImg from '../../../assets/images/access_advice.jpg';

export const PortalAccessOnly = ({ open }) => {
	const dispatch = useDispatch();

	function handleSubmit() {
		dispatch(setPortalLogin(false));
	}

	return (
		<ModalTemplate
			open={open}
			title="Acesso Órama"
		>
			<Container>
				<img
					src={accessAdviceImg}
					alt="Atenção: agora, o seu acesso ao Flexscan deve ser feito pelo portal da Órama, diretamente pelo menu Invista Agora!"
				/>

				<GeneralButton
					buttonColor="info"
					onClick={handleSubmit}
				>
                    Entendi
				</GeneralButton>
			</Container>
		</ModalTemplate>
	);
};
