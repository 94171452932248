import { SET_COLOR_MODE, SET_SAVE_BULLET_LOADING } from './types';

export const setColorMode = (colorMode) => ({
	type: SET_COLOR_MODE,
	payload: { colorMode },
});

export const setSaveBulletLoading = (isLoading) => ({
	type: SET_SAVE_BULLET_LOADING,
	payload: { isLoading },
});
