import React from 'react';
import { Box, Grid } from '@material-ui/core';
import DecimalInput from '../DecimalInput';
import paperValidator from 'utils/paperValidation';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from '../SelectInput';
import MaterialCheckBox from '../MaterialCheckBox';
import { StyledGrid, TittleGrid, TittleTypo, CheckboxGrid } from './style';
import { updateCustomParameters } from '../../../store/modules/bottomBullet/actions';
import { useTranslation } from 'react-i18next';
import IconWithTooltip from '../IconTooltip';

const DynamicAdjust = ({ stepFunction, handleChange }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { bullet } = useSelector(state => state.bottomBullet);
	const handleRetroAliment = () => {
		if (bullet.content.CustomParameters.UseRetroaliment === 'N') {
			handleChange('Custom', 'MaximumZeroTimeWindow', 30, 0);
			handleChange(
				'Custom',
				'RetroalimentParam1',
				bullet.StrategyInfos[0].minPriceIncrement ||
          bullet.StrategyInfos[0].Cst_MinPriceIncrement,
				0,
			);
		} else {
			handleChange('Custom', 'MaximumZeroTimeWindow', null, 0);
			handleChange('Custom', 'RetroalimentParam1', null, 0);
		}
		handleChange(
			'Custom',
			'UseRetroaliment',
			bullet.content.CustomParameters.UseRetroaliment === 'N' ? 'Y' : 'N',
			0,
		);
	};
	return (
		<Grid container xs={12} style={{ justifyContent: 'space-between', margin: '8px 0 0 0' }}>
			<StyledGrid
				item
				container
				direction="row"
				justify="space-between"
				alignItems="center"
				spacing={2}
				xs={9}
			>
				<TittleGrid item xs={12}>
					<TittleTypo variant="subtitle1" xs={9}>
            Ajuste dinâmico do preço de início do range
						<MaterialCheckBox
							name="dinamicAdjustCheckBox"
							labelSide="top"
							tooltip={t('bullets.gradient.dinamic_adjust')}
							disabled={
								paperValidator(bullet.StrategyInfos[0], bullet) ||
                bullet.content.ClOrdID
							}
							checked={bullet.content.CustomParameters.UseRetroaliment === 'Y'}
							onChange={() => handleRetroAliment()}
						/>
					</TittleTypo>
				</TittleGrid>
				<Grid item xs={4}>
					<DecimalInput
						disabled={
							!bullet.content.ClOrdID
								? paperValidator(bullet.StrategyInfos[0], bullet) ||
                  bullet.content.CustomParameters.UseRetroaliment === 'N' ||
                  !bullet.content.CustomParameters.UseRetroaliment
								: bullet.content.CustomParameters.UseRetroaliment === 'N'
						}
						name="waitTime"
						label="Esperar para iniciar (segundos)"
						iconTooltip={t('bullets.gradient.wait_time')}
						minVal={30}
						maxVal={3600}
						minDecScale={0} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="MaximumZeroTimeWindow" // subtype
						stepSize={1} // incremento
						valueToStep={bullet.content.CustomParameters.MaximumZeroTimeWindow} // valor a ser modificado
						value={bullet.content.CustomParameters.MaximumZeroTimeWindow}
						onChange={e =>
							handleChange(
								'Custom',
								'MaximumZeroTimeWindow',
								parseInt(e.value, 10),
								0,
							)
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<SelectInput
						disabled={
							!bullet.content.ClOrdID
								? paperValidator(bullet.StrategyInfos[0], bullet) ||
                  bullet.content.CustomParameters.UseRetroaliment === 'N' ||
                  !bullet.content.CustomParameters.UseRetroaliment
								: bullet.content.CustomParameters.UseRetroaliment === 'N'
						}
						name="paramField"
						label="Parâmetro"
						iconTooltip={t('bullets.gradient.param')}
						onChange={e =>
							handleChange(
								'Custom',
								'RetroalimentType',
								parseInt(e.target.value),
								0,
							)
						}
						defaultValue={5}
						value={bullet.content.CustomParameters.RetroalimentType ?? 5}
						selectOptions={[
							{
								value: 1,
								label: 'Qtd Desvios Padrão hist. preço',
							},
							{
								value: 2,
								label: 'Perc. sobre Min/Max hist. preço',
							},
							{
								value: 3,
								label: 'Valor sobre Min/Max hist. preço',
							},
							{
								value: 4,
								label: 'Perc. sobre último negócio',
							},
							{
								value: 5,
								label: 'Valor sobre último negócio',
							},
						]}
					/>
				</Grid>
				<Grid item xs={2}>
					<DecimalInput
						disabled={
							!bullet.content.ClOrdID
								? paperValidator(bullet.StrategyInfos[0], bullet) ||
                  bullet.content.CustomParameters.UseRetroaliment === 'N' ||
                  !bullet.content.CustomParameters.UseRetroaliment
								: bullet.content.CustomParameters.UseRetroaliment === 'N'
						}
						name="dinamicPercent"
						label={
							bullet.content.CustomParameters.RetroalimentType == 1
								? 'Qtd.'
								: bullet.content.CustomParameters.RetroalimentType == 3 ||
                  bullet.content.CustomParameters.RetroalimentType == 5
									? 'Valor'
									: '%'
						}
						iconTooltip={t('bullets.gradient.dinamic_percent')}
						minVal={
							bullet.content.CustomParameters.RetroalimentType == 1
								? 0.1
								: bullet.content.CustomParameters.RetroalimentType == 2 ||
                  bullet.content.CustomParameters.RetroalimentType == 4
									? 0.01
									: bullet.content.CustomParameters.RetroalimentType == 3
										? bullet.StrategyInfos[0].minPriceIncrement ||
                  bullet.StrategyInfos[0].Cst_MinPriceIncrement
										: 0
						}
						maxVal={
							bullet.content.CustomParameters.RetroalimentType == 1
								? 6
								: bullet.content.CustomParameters.RetroalimentType == 2 ||
                  bullet.content.CustomParameters.RetroalimentType == 4
									? 100
									: 9999999.99
						}
						minDecScale={
							bullet.content.CustomParameters.RetroalimentType == 3 ||
              bullet.content.CustomParameters.RetroalimentType == 5
								? bullet.StrategyInfos[0].tickSizeDenominator
								: 2
						} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="RetroalimentParam1" // subtype
						stepSize={
							bullet.content.CustomParameters.RetroalimentType == 3 ||
              bullet.content.CustomParameters.RetroalimentType == 5
								? bullet.StrategyInfos[0].minPriceIncrement ||
                  bullet.StrategyInfos[0].Cst_MinPriceIncrement
								: 0.01
						} // incremento
						valueToStep={bullet.content.CustomParameters.RetroalimentParam1} // valor a ser modificado
						value={bullet.content.CustomParameters.RetroalimentParam1}
						onChange={e => {
							if (e.value !== 5 && e.value !== 4) {
								handleChange('Custom', 'RetroalimentTimeWindow', 120, 0);
							} else {
								handleChange('Custom', 'RetroalimentTimeWindow', null, 0);
							}
							handleChange(
								'Custom',
								'RetroalimentParam1',
								parseFloat(e.value),
								0,
							);
						}}
					/>
				</Grid>
				{bullet.content.CustomParameters.RetroalimentType != 5 &&
        bullet.content.CustomParameters.RetroalimentType != 4 ? (
						<Grid item xs={3}>
							<DecimalInput
								disabled={
									!bullet.content.ClOrdID
										? paperValidator(bullet.StrategyInfos[0], bullet) ||
                    bullet.content.CustomParameters.UseRetroaliment === 'N' ||
                    !bullet.content.CustomParameters.UseRetroaliment
										: bullet.content.CustomParameters.UseRetroaliment === 'N'
								}
								name="timeWindow"
								label="Janela de tempo (segundos)"
								minVal={10}
								maxVal={3600}
								minDecScale={0} // casas decimais
								stepFunction={stepFunction}
								stepType="Custom" // type
								stepSubType="RetroalimentTimeWindow" // subtype
								stepSize={1} // incremento
								valueToStep={bullet.content.CustomParameters.RetroalimentTimeWindow} // valor a ser modificado
								value={bullet.content.CustomParameters.RetroalimentTimeWindow}
								onChange={e =>
									handleChange(
										'Custom',
										'RetroalimentTimeWindow',
										parseInt(e.value),
										0,
									)
								}
							/>
						</Grid>
					) : (
						''
					)}
			</StyledGrid>
			<CheckboxGrid xs={2} container direction="row" justify="center" alignItems="center">
				<MaterialCheckBox
					name="endTimeCheckBox"
					label="Zerar no final"
					checked={bullet.content.CustomParameters.SendStopLossOnEnd === 'Y'}
					onChange={e =>
						dispatch(
							updateCustomParameters({
								SendStopLossOnEnd: e.target.checked ? 'Y' : 'N',
							}),
						)
					}
					disabled={bullet.paramsView}
				/>
				<Box ml={0.5} mt={0.5}>
					<IconWithTooltip sizeFont="12px" title={t('bullets.gradient.reversion_type')}/>
				</Box>
	    </CheckboxGrid>
		</Grid>
	);
};

export default DynamicAdjust;
