// Constantes para identificar o contexto da boleta

// Nova boleta
export const NEW = 'NEW';
// Modulo externo
export const EXM = 'EXM';
// Boletas salvas
export const SVD = 'SVD';
// Boletas recentes
export const RCT = 'RCT';
// Edição
export const EDT = 'EDT';
// Exibir parãmetros
export const SWP = 'SWP';
//Clonar boleta
export const CLN = 'CLN';
// Reverter boleta
export const REV = 'REV';

// Função para validar tipo da boleta (basica ou avançada)
export const validateTypeBullet = (execStrategy, execType, context) => {
	if (context) {
		if ([NEW, EXM].includes(context)) {
			return execType;
		} else if ([SVD, RCT, EDT, SWP, CLN, REV].includes(context)) {
			if (execStrategy) {
				if (execStrategy === 1) {
					return 'B';
				}
			}
			return 'A';
		} else {
			return execType;
		}
	} else {
		return execType;
	}
};
