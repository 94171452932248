export const required = value => (value ? undefined : 'Campo obrigatório');
export const mustBeNumber = value =>
	isNaN(value) ? 'Precisa ser um número' : undefined;
export const minValue = min => value =>
	isNaN(value) || value >= min ? undefined : `Precisa ser maior que ${min}`;
export const passwordMatch = newPassword => value =>
	newPassword !== value ? 'Senhas não são iguais' : undefined;
export const composeValidators = (...validators) => value =>
	validators.reduce(
		(error, validator) => error || validator(value),
		undefined,
	);
export const isFloat = n => 
	Number(n) === n && n % 1 !== 0;

export const isEmptyObject = obj => {
	var name;
	for (name in obj) {
		return false;
	}
	return true;
};