const validatePrice = price => {
	if (
		price === 0 ||
        price === '0.00' ||
        price === '0' ||
        price === '' ||
        isNaN(price) ||
        price === undefined
	) {
		return true;
	}
};

export default validatePrice;
