import React, { useState } from 'react';

import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';

export default function BasicTable(props) {
	const [itemSelected, setItemSelected] = useState(null);

	const { callbackItemSelected, loginList } = props;

	const handleClick = userName => {
		setItemSelected(userName);
		callbackItemSelected(userName);
	};

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Login</TableCell>
						<TableCell align="left">Email</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{loginList.map(row => {
						const isItemSelected = itemSelected === row.user_name;

						return (
							<TableRow
								key={row.user_name}
								hover
								onClick={() => handleClick(row.user_name)}
								selected={isItemSelected}
							>
								<TableCell component="th" scope="row">
									{row.user_name}
								</TableCell>
								<TableCell align="left">
									{row.masked_email}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
