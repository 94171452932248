import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Checkbox, colors } from '@mui/material';

import { getStrategy } from '../utils';
import { subscribePositions } from 'store/modules/simulator/actions';

export const StrategyCheckbox = () => {
	const dispatch = useDispatch();

	const activeStrategy = useSelector(getStrategy);

	const [isChecked, setIsChecked] = useState(
		activeStrategy.positions.every(position => position.checked === true)
	);

	function handleCheckboxChange(newCheckState) {
		const positionsCopy = activeStrategy.positions.map(position => ({ ...position }));

		const updatedPositions = positionsCopy.map(position => {
			position.checked = newCheckState;
			return position;
		});

		dispatch(subscribePositions(activeStrategy.id, updatedPositions));
		setIsChecked(newCheckState);
	}

	return (
		<Checkbox
			color="primary"
			onChange={(_, newCheckState) => handleCheckboxChange(newCheckState)}
			checked={isChecked}
			indeterminate={
				!activeStrategy.positions.every(position => position.checked === true) &&
				!activeStrategy.positions.every(position => position.checked === false)
			}
			sx={{
				'&.MuiCheckbox-root': {
					color: colors.grey[500],
				},
				'&.Mui-checked': {
					color: colors.blue[500],
				},
				ml: '5px',
			}}
		/>
	);
};
