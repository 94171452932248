import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Info } from '@mui/icons-material';
import { Box, colors, Grid, Typography } from '@mui/material';

import { NumericInput } from 'components/Bullet/NumericInput';
import { DescriptionTooltip, LightHTMLTooltip } from 'components/HelperTooltip';

import paperValidator from 'utils/paperValidation';
import { updateCustomParameters } from 'store/modules/bottomBullet/actions';

export const DepletionGradientStops = ({ bullet }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { content, StrategyInfos } = bullet;

	const { StopLossFinance, StopGainGlobalFinance } = content.CustomParameters;

	const isBulletDisabled = paperValidator(StrategyInfos[0], bullet);

	function handleChangeNumericCustomParameter(
		fieldName,
		value,
		minValue = null,
		maxValue = null
	) {
		if (minValue && value < minValue) {
			return;
		}

		if (maxValue && value > maxValue) {
			return;
		}

		dispatch(updateCustomParameters({ [fieldName]: value }));
	}

	return (
		<Grid
			item
			container
			sx={{
				bgcolor: colors.grey[900],
				borderRadius: 1,
				p: 1,
			}}
		>
			<Box
				sx={{
					display: 'flex',
					gap: 1,
				}}
			>
				<Typography variant="subtitle1">Stops</Typography>

				<LightHTMLTooltip
					element={<Info fontSize="small" sx={{ mt: 0.25 }} />}
				>
					<DescriptionTooltip
						label="Stop Loss"
						description={t('bullets.gradient.loss_finance')}
					/>

					<DescriptionTooltip
						label="Stop Gain"
						description={t('bullets.gradient.gain_finance')}
					/>
				</LightHTMLTooltip>
			</Box>

			<Grid item container spacing={1}>
				<Grid item xs={6}>
					<NumericInput
						decimalScale={2}
						fixedDecimalScale
						disabled={isBulletDisabled}
						label="Stop Loss (R$)"
						value={StopLossFinance ?? ''}
						stepSize={0.01}
						minValue={0}
						onChangeCallback={value =>
							handleChangeNumericCustomParameter(
								'StopLossFinance',
								value,
								0
							)
						}
					/>
				</Grid>

				<Grid item xs={6}>
					<NumericInput
						decimalScale={2}
						fixedDecimalScale
						label="Stop Gain (R$)"
						disabled={isBulletDisabled}
						value={StopGainGlobalFinance ?? ''}
						stepSize={0.01}
						minValue={0}
						onChangeCallback={value =>
							handleChangeNumericCustomParameter(
								'StopGainGlobalFinance',
								value,
								0
							)
						}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};
