import React from 'react';

import { Box, Chip } from '@mui/material';

export const ChipCell = ({ color, label, isOutlined = false, sx = {}, ...rest }) => {
	const outlinedStyles = {
		border: `1px solid ${color}`,
		fontWeight: 'bold',
		backgroundColor: 'transparent',
	};

	const filledStyles = {
		backgroundColor: `${color}30`,
	};

	const variantStyles = isOutlined ? outlinedStyles : filledStyles;

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Chip
				label={label}
				sx={{
					color,
					height: '70%',
					fontSize: '1rem',
					maxWidth: '100px',
					maxHeight: '32px',
					...variantStyles,
					...sx
				}}
				{...rest}
			/>
		</Box>
	);
};
