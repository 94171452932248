import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import SelectInput from '../SelectInput';
import DecimalInput from '../DecimalInput';
import {
	getDecimalPlacesByTrigger,
	getStepByTrigger,
} from '../../../utils/strategies';
import { updateCustomParameters } from 'store/modules/bottomBullet/actions';
import { useInterestRate } from '../../../utils/getStateIr';
import { isDisableBySymbol } from '../../../utils/isDisableBySymbol';
import { useTranslation } from 'react-i18next';
import { SpreadMarketPrice } from '../../MarketPrice/SpreadMarketPrice';

const VolExit = ({ handleChange, stepFunction, lastVol }) => {
	const { bullet } = useSelector(state => state.bottomBullet);
	const dispatch = useDispatch();
	const { ir } = useInterestRate();
	const { t } = useTranslation();

	if (!bullet.content.CustomParameters.InterestRate) {
		dispatch(
			updateCustomParameters({
				InterestRate: ir * 100,
			})
		);
	}

	const handleStopChanges = (value, customParam) => {
		handleChange('Custom', customParam, value);
	};

	const isDisabledBySymbol = isDisableBySymbol(
		bullet,
		bullet.content.StrategyLegs.length - 1
	);

	return (
		<Grid item xs={6}>
			<Grid container spacing={1} direction="row">
				<Grid item xs={9} style={{ marginLeft: '3px' }}>
					<Typography variant="subtitle1">
						Parâmetros de execução
					</Typography>
				</Grid>

				<Grid
					container
					item
					xs={12}
					spacing={1}
					style={{ marginBottom: '-12px' }}
				>
					<Grid
						item
						xs={
							bullet.content.CustomParameters.DeltaType != 2
								? 3
								: 4
						}
					>
						<SelectInput
							name="deltaType"
							disabled={
								isDisabledBySymbol || bullet.content.ClOrdID
							}
							label="Tipo de delta"
							iconTooltip={t('bullets.volatility.delta_type')}
							defaultValue={1}
							value={
								bullet.content.CustomParameters.DeltaType ?? 1
							}
							selectOptions={[
								{
									value: 1,
									label: 'Fixo',
								},
								{
									value: 2,
									label: 'Automático',
								},
							]}
							onChange={e =>
								handleChange(
									'Custom',
									'DeltaType',
									+e.target.value
								)
							}
						/>
					</Grid>
					{bullet.content.CustomParameters.DeltaType != 2 && (
						<Grid item xs={3}>
							<DecimalInput
								name="deltaFixedValue"
								disabled={isDisabledBySymbol}
								label="Valor do Delta"
								iconTooltip={
									bullet.StrategyInfos[0].optionType === 'PUT'
										? t('bullets.volatility.delta_put')
										: t('bullets.volatility.delta_call')
								}
								minVal={
									bullet.StrategyInfos[0].optionType === 'PUT'
										? -1.0
										: 0
								}
								maxVal={
									bullet.StrategyInfos[0].optionType === 'PUT'
										? 0
										: 1.0
								}
								minDecScale={3} // casas decimais
								stepFunction={stepFunction}
								stepType="Custom" // type
								stepSubType="DeltaFixedValue" // subtype
								stepSize={0.001} // incremento
								valueToStep={
									bullet.content.CustomParameters
										.DeltaFixedValue
								} // valor a ser modificado
								value={
									bullet.content.CustomParameters
										.DeltaFixedValue
								}
								onChange={e =>
									handleStopChanges(
										parseFloat(e.value),
										'DeltaFixedValue'
									)
								}
							/>
						</Grid>
					)}
					<Grid
						item
						xs={
							bullet.content.CustomParameters.DeltaType != 2
								? 3
								: 4
						}
					>
						<DecimalInput
							name="triggerValue"
							disabled={isDisabledBySymbol}
							label="Volatilidade(%)"
							iconTooltip={t('bullets.volatility.trigger_value')}
							minVal={0.0}
							maxVal={999999.99}
							minDecScale={getDecimalPlacesByTrigger(
								bullet.content.CustomParameters.Trigger
							)} // casas decimais
							stepFunction={stepFunction}
							stepType="Custom" // type
							stepSubType="TriggerValue" // subtype
							stepSize={getStepByTrigger(
								bullet.content.CustomParameters.Trigger
							)} // incremento
							valueToStep={
								bullet.content.CustomParameters.TriggerValue
							} // valor a ser modificado
							value={bullet.content.CustomParameters.TriggerValue}
							onChange={e =>
								handleStopChanges(
									parseFloat(e.value),
									'TriggerValue'
								)
							}
						/>
					</Grid>
					<Grid
						item
						xs={
							bullet.content.CustomParameters.DeltaType != 2
								? 3
								: 4
						}
						style={{ marginTop: '-20px' }}
					>
						<SpreadMarketPrice
							title="Valor atual no mercado"
							lastVol={lastVol}
							handleChange={handleChange}
							disabled={isDisabledBySymbol}
							isCvvol
						/>
					</Grid>
				</Grid>
				<Grid item xs={4}>
					<SelectInput
						name="bookDepth"
						disabled={isDisabledBySymbol}
						label="Profundidade do Book"
						iconTooltip={t('bullets.general.book_depth')}
						defaultValue={1}
						value={bullet.content.CustomParameters.BookDepth ?? 1}
						selectOptions={[
							{
								value: 1,
								label: '1º Nível',
							},
							{
								value: 2,
								label: '2º Nível',
							},
							{
								value: 3,
								label: '3º Nível',
							},
						]}
						onChange={e =>
							handleChange('Custom', 'BookDepth', +e.target.value)
						}
					/>
				</Grid>
				<Grid item xs={4}>
					<DecimalInput
						name="DaysToExpiration"
						disabled={isDisabledBySymbol || bullet.content.ClOrdID}
						label="Dias p/ Expiração"
						iconTooltip={t('bullets.volatility.days_to_expiration')}
						minVal={0}
						maxVal={999999.99}
						minDecScale={0} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="DaysToExpiration" // subtype
						stepSize={1} // incremento
						valueToStep={
							bullet.content.CustomParameters.DaysToExpiration
						} // valor a ser modificado
						value={bullet.content.CustomParameters.DaysToExpiration}
						onChange={e =>
							handleStopChanges(
								parseInt(e.value),
								'DaysToExpiration'
							)
						}
					/>
				</Grid>
				<Grid item xs={4}>
					<DecimalInput
						name="InterestRate"
						disabled={isDisabledBySymbol}
						label="Juros(%)"
						iconTooltip={t('bullets.volatility.interest_rate')}
						minVal={0.0}
						maxVal={999999.99}
						minDecScale={2} // casas decimais
						stepFunction={stepFunction}
						stepType="Custom" // type
						stepSubType="InterestRate" // subtype
						stepSize={0.01} // incremento
						valueToStep={
							bullet.content.CustomParameters.InterestRate
						} // valor a ser modificado
						value={bullet.content.CustomParameters.InterestRate}
						onChange={e =>
							handleStopChanges(
								parseFloat(e.value),
								'InterestRate'
							)
						}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default VolExit;
