export function passwordRegex(password, secondPassword) {
	return [
		// Entre 6 e 12 caracteres
		/^[@#$&!a-zA-Z0-9]{6,12}$/.test(password),

		// Letras maiúsculas e minúsculas
		/^(?=.*[a-z])(?=.*[A-Z])[@#$&!a-zA-Z0-9]{1,}$/.test(password),

		// Conter pelo menos 1 número
		/^(?=.*[0-9])[@#$&!a-zA-Z0-9]{1,}$/.test(password),

		// Ao menos um dos caracteres: @ # $ & !
		/^(?=.*[@#$&!])[@#$&!a-zA-Z0-9]{1,}$/.test(password),

		// Entre 6 e 12 caracteres
		/^[@#$&!a-zA-Z0-9]{6,12}$/.test(secondPassword),

		// Contém emoji
		/\p{Extended_Pictographic}/u.test(password),
	];
}
