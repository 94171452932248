import { store } from 'store';

import { bulletInitialState } from './strategies';
import {
	setActiveProduct,
	setContractToAccept,
	setContractVisibility,
} from 'store/modules/products/actions';
import {
	additionalUpdate,
	resetBottomBullet,
} from 'store/modules/bottomBullet/actions';
import {
	setStrategiesTabIndex,
	updateConfig,
} from 'store/modules/panelConfig/actions';

function showPendingContract(contract) {
	store.dispatch(setContractToAccept(contract));
	store.dispatch(setContractVisibility(true));
}

export function updateStateByProduct(product, history) {
	store.dispatch(additionalUpdate('isOpen', false));
	resetBottomBullet(bulletInitialState);
	store.dispatch(updateConfig('widgetBar', false));
	store.dispatch(setStrategiesTabIndex(0));

	store.dispatch(setActiveProduct(product));

	history.push('/dashboard');
}

export function changeActiveProduct(newActiveSignature, history) {
	const userContracts = store.getState().products.userContracts.contractsData;

	if (newActiveSignature.product.family === 'Flexscan') {
		const hasFlexScanPendingContract = userContracts.find(
			contract =>
				contract.product === 'flexscan' && contract.have_pendencies
		);

		if (hasFlexScanPendingContract) {
			showPendingContract(hasFlexScanPendingContract);
			return;
		}
	}

	const pendingContract = userContracts.find(
		contract =>
			contract.product === newActiveSignature.product.code &&
			contract.have_pendencies
	);

	if (pendingContract) {
		showPendingContract(pendingContract);
		return;
	}

	updateStateByProduct(newActiveSignature.product, history);
}
