import React, { useState } from 'react';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';

import {
	Box,
	colors,
	Divider,
	FormControl,
	FormLabel,
	Grid,
	OutlinedInput,
	Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { apiExec } from 'services/api';
import { updateGradientStop } from 'store/modules/idxLiveCharts/actions';

export const GradientSettings = () => {
	const dispatch = useDispatch();

	const gradientStopReduxValues = useSelector(
		state => state.idxLiveCharts.gradientStop
	);

	const [isLoading, setIsLoading] = useState(false);
	const [globalStopValues, setGlobalStopValues] = useState({
		finLimitLoss: Number(gradientStopReduxValues.finLimitLoss),
		finLimitGain: Number(gradientStopReduxValues.finLimitGain),
	});

	function handleChangeNumericValues(field, value) {
		setGlobalStopValues({ ...globalStopValues, [field]: Number(value) });
	}

	function handleSubmit(globalStopId) {
		if (
			isNaN(Number(globalStopValues.finLimitGain)) ||
			isNaN(Number(globalStopValues.finLimitGain))
		) {
			alert('Preencha os campos corretamente.');
			return;
		}

		updateGlobalStops(globalStopId);
	}

	function updateGlobalStops(globalStopId) {
		setIsLoading(true);

		apiExec
			.put(`/globalstops/${globalStopId}`, globalStopValues)
			.then(() => {
				dispatch(
					updateGradientStop({
						...gradientStopReduxValues,
						...globalStopValues,
					})
				);
				toast.success('Stop global atualizado com sucesso!');
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => setIsLoading(false));
	}

	const isSubmitDisabled =
		!globalStopValues.finLimitLoss || !globalStopValues.finLimitGain;

	const stopLossColor =
		globalStopValues.finLimitLoss < 0 ? colors.red[300] : colors.green[400];

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
			}}
		>
			<Typography variant="h6">Stop Global Gradientes</Typography>

			<Typography variant="subtitle1">
				O stop loss limita as perdas encerrando automaticamente uma
				negociação quando o preço cai a um certo nível. Já o stop gain
				garante lucros ao fechar a posição quando o preço atinge o valor
				desejado.
			</Typography>

			<Grid gap={1} xs={12} container item flexWrap="nowrap">
				<Grid
					item
					xs={6}
					sx={{
						flexWrap: 'nowrap',
						display: 'flex',
						alignItems: 'flex-end',
					}}
				>
					<FormControl fullWidth>
						<FormLabel>Stop Loss (R$)</FormLabel>
						<NumberFormat
							customInput={OutlinedInput}
							className="hide-input-spinner"
							size="small"
							name="finLimitLoss"
							value={globalStopValues.finLimitLoss}
							defaultValue={gradientStopReduxValues.finLimitLoss}
							onValueChange={values => {
								const { floatValue } = values;
								handleChangeNumericValues(
									'finLimitLoss',
									floatValue
								);
							}}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale
							sx={{
								color: stopLossColor,
								'& .MuiOutlinedInput-input': {
									color: stopLossColor,
								},
							}}
						/>
					</FormControl>
				</Grid>

				<Grid item xs={6}>
					<FormControl fullWidth>
						<FormLabel>Stop Gain (R$)</FormLabel>
						<NumberFormat
							customInput={OutlinedInput}
							className="hide-input-spinner"
							size="small"
							name="finLimitGain"
							value={globalStopValues.finLimitGain}
							defaultValue={gradientStopReduxValues.finLimitGain}
							onValueChange={values => {
								const { floatValue } = values;
								handleChangeNumericValues(
									'finLimitGain',
									floatValue
								);
							}}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							allowNegative={false}
							fixedDecimalScale
							sx={{
								'& .MuiOutlinedInput-input': {
									color: colors.green[400],
								},
							}}
						/>
					</FormControl>
				</Grid>
			</Grid>

			<div>
				<LoadingButton
					loading={isLoading}
					variant="contained"
					size="small"
					onClick={() => handleSubmit(gradientStopReduxValues.id)}
					disabled={isSubmitDisabled}
				>
					Salvar
				</LoadingButton>
			</div>

			<Divider orientation="horizontal" />
		</Box>
	);
};
